import { Model } from '@vuex-orm/core'

export default class EventTypeDef extends Model {
  static entity = 'eventTypeDefs'
  
  static primaryKey = 'EventTypeDefId'
  
  static fields () {
    return {
      EventTypeDefId: this.uid(),
      EventTypeLabel: this.attr(null)
    }
  }

  static async fetchAll() {
    await this.api().get('/api/eventtypedefs/')
    return this.all();
  }
}

