import { Model } from '@vuex-orm/core'
/* eslint-disable */
import SubPayment from "./SubPayment"
import SubscriptionDefs from "./SubscriptionDefs"
import Card from "./Card"
import Business from "./Business"

export default class Subscription extends Model {
  static entity = 'subscription'
  
  static primaryKey = 'SubId'
  
  static fields () {
    return {
      SubId: this.uid(),
      BusId: this.attr(null),
      SubDefId: this.attr(null),
      SubStartDate: this.attr(null),
      SubEndDate: this.attr(null),
      SubActive: this.attr(null),
      SubPrice: this.attr(null),
      SubCurrentUntil: this.attr(null),
      SubNum: this.attr(null),
      SubInTrial: this.attr(null),
      SubTrialEnds: this.attr(null),
      SubStatus: this.attr(null),
      SubCancelationScheduled: this.attr(null),
      CardId: this.attr(null),

      //virtual
      Bus: this.belongsTo(Business, 'BusId'),
      SubDef: this.belongsTo(SubscriptionDefs, 'SubDefId'),
      SubPayments: this.hasMany(SubPayment, 'SubId'),
      Card: this.belongsTo(Card, 'CardId'),
    }
  }

  static async fetchCurrentByBusiness(BusId) {
    Subscription.deleteAll();
    await Subscription.api().get('/api/subscriptions/getcurrentsubscriptionbybusid/' + BusId)
    return Subscription.query()
      .withAllRecursive().first();
  }

  static async fetchByOwnerId(CustId) {
    Subscription.deleteAll();
    await Subscription.api().get('/api/subscriptions/getsubscriptionsbyownerid/' + CustId)
    return Subscription.query()
      .withAllRecursive().all();
  }

  static async updateSubCard(cardId, subId) {
    //console.log("updating subscription default card")
    var data = new FormData();
    data.append('SubId', subId);
    data.append('CardId', cardId);
     const result =  await Subscription.api().put('/api/subscriptions/UpdateSubCard/', data).then((result) => {
      if (result.response.status == 204 || result.response.status == 200 ) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
      return result;
  }

  static async cancelSubscription(subId) {
    //console.log("cancel subscription")
    var data = new FormData();
    data.append('SubId', subId);
     const result =  await Subscription.api().put('/api/subscriptions/CancelSubscription/', data).then((result) => {
      if (result.response.status == 204 || result.response.status == 200 ) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
      return result;
  }


  static async upgardeSubscription(subId, level) {
   //console.log("upgrade subscription")
    var data = new FormData();
    data.append('SubId', subId);
    data.append('SubLevelCode', level);
     const result =  await Subscription.api().put('/api/subscriptions/UpgradeSubscription/', data).then((result) => {
      if (result.response.status == 204 || result.response.status == 200 ) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
      return result;
  }

  static async restoreSubscription(subId) {
    //console.log("restore subscription")
    var data = new FormData();
    data.append('SubId', subId);
     const result =  await Subscription.api().put('/api/subscriptions/RestoreSubscription/', data).then((result) => {
      if (result.response.status == 204 || result.response.status == 200 ) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
      return result;
  }
}

