<template>
  <div>
    <div class="col-12 purple mb-5 mt-5">
      <div class="col-12 pt-5">
        <span
          class="pointer"
          style="
            color: white;
            font-family: 'Leckerli One';
            font-size: 60px;
            font-style: normal;
            font-variant: normal;
            font-weight: 100;
            line-height: 63.7px;
            text-align: center;
          "
        >
          MyReservs
        </span>
      </div>
      <span style="font-size: xx-large">{{
        $t("footer.businessfeatures")
      }}</span>
      <p style="font-size: large; padding-right: 2rem; padding-left: 2rem">
        {{ $t("listmybusiness.thankyou") }}
      </p>
    </div>
    <div class="grid align-items-center mr-5 ml-5">
      <div class="col-12 md:col-6 mb-5" style="text-align: left">
        <h1 class="pi-primary">{{ $t("businessprofile.businesspro") }}</h1>
        <span style="font-size: large">
          {{ $t("listmybusiness.profiledetail") }}</span
        >
      </div>
      <div class="col-12 md:col-6 mb-5">
        <Image
          class="grow full-width"
          :src="designImageHostingURL + 'BusinessProfile.png'"
          alt="Image"
          preview
        />
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5">
        <Image
          class="grow full-width"
          :src="designImageHostingURL + 'BusinessServices.png'"
          alt="Image"
          preview
        />
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5" style="text-align: right">
        <h1 class="pi-primary">{{ $t("listmybusiness.serviceportfolio") }}</h1>
        <span style="font-size: large">{{
          $t("listmybusiness.listdetails")
        }}</span>
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5" style="text-align: left">
        <h1 class="pi-primary">{{ $t("listmybusiness.eventcal") }}</h1>
        <span style="font-size: large">{{
          $t("listmybusiness.calendareventdetail")
        }}</span>
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5">
        <Image
          class="grow full-width"
          :src="designImageHostingURL + 'BusinessCalendar.png'"
          alt="Image"
          preview
        />
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5">
        <Image
          class="grow full-width"
          :src="designImageHostingURL + 'BusinessMessages.png'"
          alt="Image"
          preview
        />
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5" style="text-align: right">
        <h1 class="pi-primary">{{ $t("listmybusiness.directmessages") }}</h1>
        <span style="font-size: large">{{
          $t("listmybusiness.prospective")
        }}</span>
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5" style="text-align: left">
        <h1 class="pi-primary">{{ $t("listmybusiness.customerreviews") }}</h1>
        <span style="font-size: large">{{
          $t("listmybusiness.reviewsdetail")
        }}</span>
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5">
        <Image
          class="grow full-width"
          :src="designImageHostingURL + 'BusinessRating.png'"
          alt="Image"
          preview
        />
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5">
        <Image
          class="grow full-width"
          :src="designImageHostingURL + 'BusinessQuote.png'"
          alt="Image"
          preview
        />
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5" style="text-align: right">
        <h1 class="pi-primary">{{ $t("listmybusiness.directquotes") }}</h1>
        <span style="font-size: large">{{
          $t("listmybusiness.providedetail")
        }}</span>
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5" style="text-align: left">
        <h1 class="pi-primary">
          {{ $t("listmybusiness.onlinepay") }}<br /><a
            href="https://stripe.com/about"
            target="_blank"
          >
            <img
              class="grow pointer"
              src="@/assets/PoweredByStripe.png"
              style="width: 30%"
          /></a>
        </h1>
        <span style="font-size: large">{{
          $t("listmybusiness.onlinedetail")
        }}</span>
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5">
        <Image
          class="grow full-width"
          :src="designImageHostingURL + 'BusinessPayments.png'"
          alt="Image"
          preview
        />
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5">
        <Image
          class="grow full-width"
          :src="designImageHostingURL + 'BusinessClients.png'"
          alt="Image"
          preview
        />
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5" style="text-align: right">
        <h1 class="pi-primary">{{ $t("listmybusiness.clientinventory") }}</h1>
        <span style="font-size: large">{{
          $t("listmybusiness.clientsdesc")
        }}</span>
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5" style="text-align: left">
        <h1 class="pi-primary">
          {{ $t("listmybusiness.employeeinventory") }}
        </h1>
        <span style="font-size: large">{{
          $t("listmybusiness.employeesdesc")
        }}</span>
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5">
        <Image
          class="grow full-width"
          :src="designImageHostingURL + 'BusinessStaff.png'"
          alt="Image"
          preview
        />
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5">
        <Image
          class="grow full-width"
          :src="designImageHostingURL + 'BusinessPlatform.png'"
          alt="Image"
          preview
        />
      </div>
      <div class="col-12 md:col-6 mb-5 mt-5" style="text-align: right">
        <h1 class="pi-primary">
          {{ $t("listmybusiness.businessdashboard") }}
        </h1>
        <span style="font-size: large">{{
          $t("listmybusiness.busdashboard")
        }}</span>
      </div>
    </div>
  </div>
  <div class="col-12 purple">
    <h1>{{ $t("listmybusiness.join30") }}</h1>
    <Button
      :label="$t('menu.signup')"
      class="
        p-button-raised p-button-lg p-button-text p-button-light p-button-plain
        mb-5
      "
      style="background-color: white"
      @click.stop="
        $router.push({
          name: 'RequestedLogin',
          params: { requestedLoginProp: 'true' },
        })
      "
    />
  </div>
</template>
<script>
export default {
  name: "FunctionalityBusiness",
  components: {},
  data() {
    return {};
  },
  computed: {
    designImageHostingURL() {
      return process.env.VUE_APP_DESIGNIMAGES_HOSTING_URL;
    },
  },
};
</script>
