import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Customer from "./Customer"
import Business from "./Business"
import Message from "./Message"

export default class Conversation extends Model {
  static entity = 'conversations'
  
  static primaryKey = 'ConvoId'
  
  static fields () {
    return {
      ConvoId: this.uid(),
      BusId: this.attr(null),
      CustId: this.attr(null),
      ConvoLastUpdated: this.attr(null),
      Bus: this.belongsTo(Business, 'BusId'),
      Cust: this.belongsTo(Customer, 'CustId'),
      Messages: this.hasMany(Message, 'ConvoId')
    }
  }

  static async fetchByCustId(CustId) {
    //console.log("fetch convos for " + CustId)
    Conversation.deleteAll();
   var result = await Conversation.api().get('/api/conversations/GetCustomerConvos/' + CustId);
    //console.log(result)
    return  Conversation.query()
      .withAllRecursive()
      .where(convo=>convo.CustId == CustId).orderBy('ConvoLastUpdated', 'desc').all();
  }
  
  static async fetchByBusId(BusId) {
    //console.log("fetch convos for " + BusId)
    Conversation.deleteAll();
   var result = await Conversation.api().get('/api/conversations/GetBusConvos/' + BusId);
    //console.log(result)
    return  Conversation.query()
      .withAllRecursive()
      .where(convo=>convo.BusId == BusId).orderBy('ConvoLastUpdated', 'desc').all();
  }

  static async refreshByCustId(CustId) {
    //console.log("refresh convos for " + CustId)
   var result = await Conversation.api().get('/api/conversations/GetCustomerConvos/' + CustId);
    //console.log(result)
    return  Conversation.query()
      .withAllRecursive()
      .where(convo=>convo.CustId == CustId).orderBy('ConvoLastUpdated', 'desc').all();
  }
  
  static async refreshByBusId(BusId) {
    //console.log("refresh convos for " + BusId)
   var result = await Conversation.api().get('/api/conversations/GetBusConvos/' + BusId);
    //console.log(result)
    return  Conversation.query()
      .withAllRecursive()
      .where(convo=>convo.BusId == BusId).orderBy('ConvoLastUpdated', 'desc').all();
  }

  static async newConvo(convo) {
    //console.log("creating new conversation")
     const result =  await Conversation.api().post('/api/conversations/',
     convo).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }

  static markAsBusRead(convoId) {
    //console.log("marking as bus read")
    Conversation.api().post('/api/conversations/markAsBusRead/'+convoId)
  }

  static markAsCustRead(convoId) {
    //console.log("marking as cust read")
    Conversation.api().post('/api/conversations/markAsCustRead/'+convoId)
  }

}

