import { Model } from '@vuex-orm/core'

export default class ServiceImage extends Model {
  static entity = 'serviceImages'
  
  static primaryKey = 'ServImageId'
  
  static fields () {
    return {
      ServImageId: this.uid(),
      ServId: this.attr(null),
      BusImageTitle: this.attr(null),
      BusImage: this.attr(null),
      BusImageCaption: this.attr(null)
    }
  }
}

