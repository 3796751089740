import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Customer from "./Customer"
import NotificationType from "./NotificationType"

export default class Notification extends Model {
  static entity = 'notifications'

  static primaryKey = 'NoticeId'

  static fields() {
    return {
      NoticeId: this.uid(),
      CustId: this.attr(null),
      NoticeTypeId: this.attr(null),
      NoticeRead: this.attr(null),
      NoticeTitle: this.attr(null),
      NoticeBody: this.attr(null),
      NoticeLink: this.attr(null),
      NoticeDate: this.attr(null),

      Cust: this.belongsTo(Customer, 'CustId'),
      NoticeType: this.belongsTo(NotificationType, 'NoticeTypeId'),
    }
  }

  static async fetchByCustId(CustId) {
    //console.log("fetch notifications for cust" + CustId)
    Notification.deleteAll();
   var result = await Notification.api().get('/api/notifications/GetCustomerNotifications/' + CustId);
    //console.log(result)
    return  Notification.query()
      .withAllRecursive()
      .where(notice=>notice.CustId == CustId).orderBy('NoticeId', 'desc').all();
  }

  static async markAsRead(custId) {
    await Notification.api().post('/api/notifications/markAsRead/'+custId);
  }

}