import { Model } from '@vuex-orm/core'

export default class ServiceType extends Model {
  static entity = 'serviceTypes'
  
  static primaryKey = 'ServTypeId'
  
  static fields () {
    return {
      ServTypeId: this.uid(),
      ServId: this.attr(null),
      ServTypeTitle: this.attr(null)
    }
  }
}

