import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Customer from "./Customer"
import GuestList from "./GuestList"

export default class SharedGuestList extends Model {
  static entity = 'sharedGuestLists'
  
  static primaryKey = 'ShareId'
  
  static fields () {
    return {
      ShareId: this.uid(),
      ListId: this.attr(null),
      CustId: this.attr(null),
     
      Cust: this.belongsTo(Customer, 'CustId'),
      List: this.belongsTo(GuestList, 'ListId')
    }
  }

  static async share(share) {
    //console.log("creating share")
     const result =  await SharedGuestList.api().post('/api/SharedGuestLists/',
      share).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
      }
      static async remove(shareId) {
        //console.log("deleting share")
         const result =  await SharedGuestList.api().post('/api/SharedGuestLists/RemoveAccess/'+
          shareId).then((result) => {
            if (result.response.status == 201) {
              //console.log("success")
              return 1;
            }
            if(result.response.status == 200){
              //console.log("duplicate")
              return 1;
            }
            else{
              //console.log("error");
              return 2;
            } 
          }).catch(error => {
            //console.log(error.response);
            return 2;
          });
          return result;
          }
}

