import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Customer from "./Customer"

export default class QuoteComment extends Model {
  static entity = 'quoteComments'
  
  static primaryKey = 'CommentId'
  
  static fields () {
    return {
      CommentId: this.uid(),
      QuoteId: this.attr(null),
      CommentText: this.attr(null),
      CustId: this.attr(null),
      CommentDate:this.attr(null),
      Cust: this.belongsTo(Customer, 'CustId')
    }
  }

  static async addComment(comment) {
    //console.log("creating new quote comment")
     const result =  await QuoteComment.api().post('/api/quotecomments/',
      comment).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
      }
}

