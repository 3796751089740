<template>
  <div
    class="flex flex-column justify-content-between"
    style="height: var(--doc-height)"
  >
    <div
      id="main"
      v-on:scroll.passive="handleScroll()"
      class="main overflow-y-scroll flex-grow-1"
    >
      <div
        v-show="
         $route.path === '/' ||
          $route.path.toUpperCase() === '/HOME' ||
          $route.path.toUpperCase().startsWith('/SEARCH') ||
          $route.path.toUpperCase() === '/CUSTOMERDASHBOARD' ||
          $route.path.toUpperCase().startsWith('/COMING') ||
          $route.path.toUpperCase() === '/LOGIN'
            ? false
            : true
        "
        id="navbar"
      >
        <!-- <div v-if="loggedIn == true"  > -->
        <navigation></navigation>
    
      </div>
      <!-- $route.path === '/' || ADD BACK WHEN RELEASING SEARCH ENGINE-->

      <div v-if="$route.path.startsWith('/Business') ? true : false">
        <div class="flex" style="padding: 0px; height: 100%">
          <div
            class="hidden md:inline-flex justify-content-center freeze"
            style="background-color: white; height: 100%; margin-top: 3px"
          >
            <business-navigation></business-navigation>
          </div>
          <div class="flex-grow-1 content pl-2">
            <div>
              <!-- <Sidebar
                class="md:hidden"
                v-model:visible="showSideBusinessMenu"
                style="height: 38rem; text-align: center"
                position="bottom"
                :baseZIndex="1000"
              >
                <div class="grid">
                  <div class="col-12 left-text">
                    <business-navigation
                      class="mt-5"
                      v-on:hide="showSideBusinessMenu = false"
                    ></business-navigation>
                  </div>
                </div>
              </Sidebar> -->
              <div>
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <Message v-if="userMissingProfile" severity="warn"  :closable="false"
      >
      <Button
        :label="$t('profile.please')"
        class="p-button-link p-button-sm"
        @click.stop="showUserEdit=true"
      />
    </Message>
        <router-view />
      </div>

      <ConfirmDialog></ConfirmDialog>
      <!-- <ConfirmPopup></ConfirmPopup> -->
      <Toast />
      <Dialog
        v-model:visible="showUserEdit"
        :style="{ height:'100vh' }"
        :closeOnEscape="false"
        :position="'top'"
      >
      <!-- <template #container> -->
        <ScrollPanel class="p-5 " style="width:100%; height:90vh; border-radius: 5px;">
        <div class="col-12 p-2 mb-2 purple text-center" style="width: 100%;border-radius: 5px; ">
          <h2>{{ $t("listmybusiness.welcometo") }}</h2>
          <p>
            <b>{{ $t("message.completeprofile") }} </b>
          </p>
        </div>
        <complete-customer-profile @closeDialog="fetchUserData()" />
      </ScrollPanel>
      <!-- </template> -->
      </Dialog>
      <reservs-footer
        v-if="
          $route.path.startsWith('/Business') ||
          $route.path.startsWith('/Customer') ||
          $route.path.startsWith('/coming') ||
          $route.path.startsWith('/Coming') ||
          $route.path.toUpperCase() === '/LOGIN'
            ? false
            : true
        "
      />
    </div>
    <div class="bottom-0">
      <div
        v-if="
          customerBusinesses != null &&
          customerBusinesses.length > 0 &&
          currentCustomerId > 0 &&
          userLoggedIn == true
        "
        class="lg:hidden"
      >
        <div
          class="p-button-sm businessMenuButton"
          @click.stop="
            showSideBusinessMenu = showSideBusinessMenu == true ? false : true
          "
        >
          <!-- TODO: Replace with Business Name -->
          <i class="pi pi-briefcase" style="fontsize: 2rem"></i>&nbsp;
          {{ $t("menu.mybusiness") }}
        </div>
      </div>

      <Sidebar
        class="lg:hidden"
        v-model:visible="showSideBusinessMenu"
        style="height: 70vh; text-align: center"
        position="bottom"
        :baseZIndex="2000"
      >
        <div class="grid">
          <div class="col-12 left-text">
            <business-navigation
              v-show="showSideBusinessMenu"
              class="mt-5"
              v-on:hide="showSideBusinessMenu = false"
              :showSideBar="showSideBusinessMenu"
            ></business-navigation>
          </div>
        </div>
      </Sidebar>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
// import Login from "./components/Login.vue";
import Navigation from "./components/Navigation.vue";
import BusinessNavigation from "./components/BusinessNavigation.vue";
import ReservsFooter from "./components/ReservsFooter.vue";
import CompleteCustomerProfile from "./components/CompleteCustomerProfile.vue";
import Device from "././store/Models/Device";
import CustNotifiPermission from "././store/Models/CustNotifiPermission";


// import update from './mixins/update'

const documentHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
};
 window.addEventListener("resize", documentHeight);
 documentHeight();

// //autohide addressbar
// window.addEventListener("load", function () {
//   // Set a timeout...
//   setTimeout(function () {
//     // Hide the address bar!
//     window.scrollTo(0, 1);
//   }, 0);
// });

export default {
  components: {
    Navigation,
    BusinessNavigation,
    ReservsFooter,
    CompleteCustomerProfile,
  },
  data() {
    return {
      showSideBusinessMenu: false,
      langs: ["en", "es"],
      registration: null,
      isRefresh: false,
      refreshing: false,
      alreadyRefreshed: false,
      showUserEdit: false,
    };
  },

  created() {
    this.$log.info("app created");

    
    document.addEventListener(
      "serviceWorkerUpdateEvent",
      this.updateAvailable,
      { once: true }
    );

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  async mounted() {
    this.$log.info("app mounted");

    this.$log.debug(this.$route.name);
    this.setLang();
    if (this.userMissingProfile && this.userLoggedIn && !this.$route.path.startsWith("/List")) {
      setTimeout(() => {
        this.showUserEdit = true;
      }, 1000);
    }
    await this.$IsAuthenticated().then(async (response) => {
      this.$log.debug("Is authenticated response:");
      this.$log.debug(response);
      if (response != null) {
        this.$log.debug(response.idTokenClaims.newUser);
        if (
          response.idTokenClaims.newUser != undefined &&
          response.idTokenClaims.newUser == true
        ) {
         //console.log("new user")
          await this.$VerifyAccount(response.username).then(async () => {
            await this.$FetchRoles("User").then(async () => { //console.log("fetched roles")
          });
            this.initializeFCM();
          });
        } else {
          await this.$AcquireToken(false).then(async () => {
            await this.$store
              .dispatch("setUserLoggedIn", response)
              .then(async () => {
                this.timer = setInterval(this.tokenRefresh, 50000);
                 this.initializeFCM();
              });
          });
        }
      }
    });
  },
  methods: {
    handleScroll() {
      // // Any code to be executed when the window is scrolled
      ////console.log("scrolling");

      // var currentScrollPos = document.getElementById("main").scrollTop;
      ////console.log(this.prevScrollpos);
      ////console.log(currentScrollPos);
      // if (this.prevScrollpos > currentScrollPos) {
      //  //console.log("up");
      //   // document.getElementById("navbar").classList.remove("m-fadeIn");
      //   //document.getElementById("navbar").classList.remove("hidden");
      // } else {
      //   // document.getElementById("navbar").classList.add("m-fadeOut");
      //   // document.getElementById("navbar").classList.add("hidden");
      //  //console.log("down");
      // }

      // this.prevScrollpos = currentScrollPos;
    },
    ...mapActions(["refreshUserData", "setCurrentCustomer", "setToken"]), 
    tokenRefresh: async function () {
      this.$log.debug("starting token refresh " + this.userLoggedIn);
      if (this.userLoggedIn == true) {
        await this.$AcquireToken(false).then(async (response) => {
          if (response) {
            this.$log.debug(response);
            this.setToken(response);
          }
        });
      }
    },
    async fetchUserData(){
      this.showUserEdit = false
      await this.$FetchRoles("User").then(async () => {
              this.refreshUserData(this.currentCustomerId.CustId)}
                );
    },
    initializeFCM() {
     //console.log("INITIALIZE FCM")
     //console.log(Notification.permission);
      if (Notification.permission == "default") {
       //display popup for permissions
       this.showNotificationToast()
      } else if (Notification.permission == "granted") {
        this.setUserAppPermission("granted");
        this.addDevice();
      } else if (Notification.permission == "denied") {
        this.setUserAppPermission("denied");
      }
    },
    setUserAppPermission(decision) {
      var approved = decision == "granted" ? true : false;
      var permission = {
        CustId: this.currentCustomerId,
        NoticeApp: approved,
        NoticeTypeId: 7,
      };
      CustNotifiPermission.setAppPermissions(permission);
    },

    requestPermission(){
      Notification.requestPermission()
          .then(function () {
           //console.log("notification response")
           //console.log(Notification.permission)
            this.setUserAppPermission(Notification.permission);
            if (Notification.permission == "granted") {
              this.$log.debug("Have permission");
              this.addDevice();
            }
          })
          .catch(function (err) {
            this.$log.debug("Error Ocurred", err);
          });
    },
    // getFCMToken() {
    //  //console.log("GETTING FCM TOKEN")
    //   var self = this;
    //   if (this.$messaging != null) {
    //     this.$messaging
    //       .getToken({
    //         vapidKey:
    //           "BLB7jkg8FaJtmOaTFnTSXliCEu4ecf1bh2qxQbeiAOjJuzXSNSPQA5RXNNKAmMB7xcjA2GbGIOnbejIJkZl6eE0",
    //       })
    //       .then((currentToken) => {
    //        //console.log("CURRENT TOKEN:")
    //        //console.log(currentToken)
    //         if (currentToken) {
    //           this.$log.debug("client token", currentToken);
    //           var device = {
    //             ProviderToken: currentToken,
    //             CustId: self.currentCustomerId,
    //             DeviceOs: window.navigator.platform,
    //             DeviceBrowser: window.navigator.userAgent,
    //             DeviceActive: true,
    //             DeviceRegisteredDate: new Date(),
    //             DeviceLastUsed: new Date(),
    //           };
    //           Device.registerUsage(device);
    //         } else {
    //           this.$log.debug("token empty");
    //         }
    //       })
    //       .catch((err) => {
    //         this.$log.debug("An error occurred while retrieving token. ", err);
    //       });
    //   }
    // },
        addDevice() {
     //console.log("ADDING DEVICE")
      var self = this;
      var currentToken = window.sessionStorage.getItem("fcmtoken");
     //console.log(currentToken)
     if(currentToken)
     {
              this.$log.debug("client token", currentToken);
              var device = {
                ProviderToken: currentToken,
                CustId: self.currentCustomerId,
                DeviceOs: window.navigator.platform,
                DeviceBrowser: window.navigator.userAgent,
                DeviceActive: true,
                DeviceRegisteredDate: new Date(),
                DeviceLastUsed: new Date(),
              };
              Device.registerUsage(device);
            } else {
              this.$log.debug("token empty");
            }
        
        
    },
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.$log.debug("updateAvailable");
      this.registration = event.detail;
      this.isRefresh = true;
      this.showUpdateToast();
    },
    showUpdateToast() {
      this.$log.debug("showUpdateToast");
      if (!this.alreadyRefreshed) {
        this.$confirm.require({
          header: this.$t("code.version"),
          acceptClass: "p-button-primary",
          rejectClass: "p-button-secondary",
          position: "bottom",
          acceptLabel: "Reload",
          rejectLabel: "Later",
          accept: () => {
            this.refreshApp();
          },
          reject: () => {
            this.$confirm.close();
          },
        });
      }
    },
    showNotificationToast() {
      this.$log.debug("show notification permission");

        this.$confirm.require({
          header: this.$t("code.notifications"),
          acceptClass: "p-button-primary",
          rejectClass: "p-button-secondary",
          position: "bottom",
          acceptLabel: "Allow",
          rejectLabel: "Later",
          accept: () => {
            this.requestPermission();
          },
          reject: () => {
            this.$confirm.close();
          },
        });
      
    },
    // Called when the user accepts the update
    refreshApp() {
      this.$log.debug("refreshApp");
      this.isRefresh = false;
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },

    setLang() {
      this.$log.debug(this.lang);
      if (this.lang == "en") {
        this.$i18n.locale = "en";
      }
      if (this.lang == "es") {
        this.$i18n.locale = "es";
      }
      document.getElementsByTagName("html")[0].lang = this.lang;
    },
  },
  computed: mapState({
    currentCustomerId: (state) => state.currentCustomerId,
    currentADUser: (state) => state.currentADUser,
    userLoggedIn: (state) => state.userLoggedIn,
    userMissingProfile: (state) => state.userMissingProfile,
    lang: (state) => state.lang,
    customerBusinesses: (state) => state.customerBusinesses,
  }),
  watch: {
    lang() {
      this.$log.debug("lang changed");
      this.setLang();
    },
    userMissingProfile() {
      this.$log.debug("userMissingProfile changed " + this.userMissingProfile);
      this.$log.debug(this.userMissingProfile);
      this.$log.debug(this.$route.path);
      if (
        this.userMissingProfile &&
        this.userLoggedIn &&
        !this.$route.path.toUpperCase().startsWith("/LIST")
      ) {
        this.showUserEdit = true;
      }
    },
    showSideBusinessMenu() {
      this.$log.debug(this.showSideBusinessMenu);
    },
    userLoggedIn() {
      this.$log.debug("userLoggedIn changed " + this.userLoggedIn);
      if (this.userLoggedIn == true) {
        // this.timer = setInterval(this.tokenRefresh, 50000);
        
       //console.log(this.$route.path.toUpperCase())
       //console.log( this.userMissingProfile )
        if (
        this.userMissingProfile &&
        !this.$route.path.toUpperCase().startsWith("/LIST")
      ) {
       //console.log("show user edit")
        this.showUserEdit = true;
      }

      this.initializeFCM();
      }
    },
  },
};
</script>

<style>
#top-alert {
  top: 0;
  left: 0;

  width: 100%;
}
.main {
  /* position: relative;
 
  top: 0;
  bottom: 0; */
  width: 100%;
  left: 0;
  padding: 0;
  background-color: #f8f9fa;
  /* height: 100%; */
}
*:not(i):not(.pi) {
  font-family: "Prompt", sans-serif;
}

body {
  margin: 0px;
  /* min-height: -webkit-fill-available;
  min-height: 100vh; 
  min-height: var(--doc-height); */
}

#app {
  /* min-height: 100vh; */
  font-family: "Prompt", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #495057;
  text-align: center;
}

.businessMenuButton {
  /* position: fixed;
  bottom: 0; */
  left: 0px;
  padding: 0px;
  width: 100%;
  background: whitesmoke;
  cursor: pointer;
  z-index: 1005;
  vertical-align: middle;
  white-space: nowrap;
  /* color:  var(--mr-primary-color);; */
  font-size: x-large;
  font-weight: bolder;

}

:root {
  /* --mr-primary-color: #7c4b95; */
  /* --mr-primary-color: #5f0a87; */
  /* --mr-primary-color: #f03e8b; */
  --doc-height: 100%;
  --mr-primary-color: #a80598;
  --mr-danger-color: rgb(222, 49, 99);
  --mr-secondary-color: #df9eea;
  --mr-focus-ring: 0 0 0 0.2rem #c9256c85;
}
.box-shadow {
  box-shadow: var(--mr-focus-ring) !important;
}

/* .p-badge {
  background: var(--mr-primary-color) !important;
  color: white !important;
} */

/* .p-tag.p-tag-primary */
.p-button-primary {
  background: var(--mr-primary-color) !important;
  border-color: var(--mr-primary-color) !important;
  color: #fbf6fa !important;
}

.p-button.p-autocomplete-dropdown {
  background: var(--mr-primary-color) !important;
  border-color: var(--mr-primary-color) !important;
  border-radius: 0px !important;
}

.p-button-primary.p-button-text {
  color: var(--mr-primary-color) !important;
  background: none !important;
  border: none !important;
}

.p-button-danger,
.p-tag.p-tag-danger {
  background: var(--mr-danger-color) !important;
  border-color: var(--mr-danger-color) !important;
  color: #fbf6fa !important;
}

@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}
@media (max-width: 760px) {
  .show-on-desktop {
    display: none !important;
  }
}
</style>
