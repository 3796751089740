import { Model } from '@vuex-orm/core'
// import Customer from "./Customer"
/* eslint-disable */
import BusinessImage from "./BusinessImage"
import EventType from "./EventType"
import AttendeeCount from "./AttendeeCount"
import Review from "./Review"
import Service from "./Service"
import Schedule from "./Schedule"
import Conversation from "./Conversation"
import BusinessStaff from "./BusinessStaff"
import BusinessCustomer from "./BusinessCustomer"
import BusinessReservTerm from "./BusinessReservTerm"
import BusinessServiceLocation from "./BusinessServiceLocation"

import Subscription from "./Subscription"
export default class Business extends Model {
  static entity = 'businesses'

  static primaryKey = 'BusId'

  static fields() {
    return {
      BusId: this.uid(),
      BusPhone: this.attr(null),
      BusName: this.attr(null),
      BusAddress: this.attr(null),
      BusCity: this.attr(null),
      BusZip: this.attr(null),
      BusState: this.attr(null),
      BusEmail: this.attr(null),
      BusWebUrl: this.attr(null),
      BusFbUrl: this.attr(null),
      BusTwitUrl: this.attr(null),
      BusInstUrl: this.attr(null),
      BusPinUrl: this.attr(null),
      BusDesc: this.attr(null),
      BusOccupancy: this.number(),
      BusCategory: this.attr(null),
      BusOwnerCustId: this.attr(null),
      BusRating: this.attr(null),
      BusLogo: this.attr(null),
      BusActive: this.attr(null),
      BusVisible: this.attr(null),
      BusEnrolledDate: this.attr(null),
      BusSalesTaxPercent: this.attr(null),
      BusQuoteExpiration: this.attr(null),
      BusAmmenities: this.attr(null),
      BusDownPmtReq: this.attr(null),
      BusDownPmtAmt: this.attr(null),
      BusAcceptPayment: this.attr(null),
      BusProviderAcctStatus: this.attr(null),
      BusAcceptQuotes: this.attr(null),
      BusAcceptMessages: this.attr(null),
      BusCountryCode: this.attr(null),
      BusDisplayCalendar: this.attr(null),
      BusDisplayServices: this.attr(null),
      BusProviderAccount: this.attr(null),
      BusHandle: this.attr(null),
      BusInActiveSince: this.attr(null),
      BusDisplayReservTerms: this.attr(null),
      BusHideAddress: this.attr(null),
      BusOccupancyDisabled: this.attr(null),
      BusPolicy: this.attr(null),
      BusReqReservApprovals: this.attr(null),
      BusIncompleteProfileDismissed: this.attr(null),
      BusStoreEnabled: this.attr(null),
      BusTourSchdedulingEnabled: this.attr(null),
      BusApptSchedulingEnabled: this.attr(null),


      ExcellentCount: this.number(),
      GoodCount: this.attr(null),
      AverageCount: this.attr(null),
      BelowAvgCount: this.attr(null),
      PoorCount: this.attr(null),

      //Virtual
      // BusOwnerCust: this.belongsTo(Customer, 'BusOwnerCustId'),
      BusinessStaffs: this.hasMany(BusinessStaff, 'BusId'),
      BusinessCustomers: this.hasMany(BusinessCustomer, 'BusId'),
      Schedules: this.hasMany(Schedule, 'BusId'),
      Conversations: this.hasMany(Conversation, 'BusId'),
      EventTypes: this.hasMany(EventType, 'BusId'),
      AttendeeCounts: this.hasMany(AttendeeCount, 'BusId'),
      Services: this.hasMany(Service, 'BusId'),
      // Subscriptions: this.hasMany(Subscription, 'BusId'),
      BusinessImages: this.hasMany(BusinessImage, 'BusId'),
      Reviews: this.hasMany(Review, 'BusId'),
      AvailableTimeSlots: this.attr([]),
      CalendarEvents: this.attr([]),
      BusinessReservTerms: this.hasMany(BusinessReservTerm, 'BusId'),
      BusinessServiceLocations: this.hasMany(BusinessServiceLocation, 'BusId')
    }
  }
  static async fetchAll() {
    await Business.api().get('/api/businesses/')
    return this.all();
  }

  static async fetchBusinessContact() {
    Business.deleteAll();
    await Business.api().get('/api/businesses/GetBusinessContact')
    return this.all();
  }

  static async fetchById(BusId) {
    //console.log("fetch bus " + BusId)
    await Business.api().get('/api/businesses/GetBusinessDetails/' + BusId);
    //console.log(Business.find(BusId))
    return Business.query()
      .withAllRecursive()
      .find(BusId);
  }

  static async fetchByHandle(BusHandle) {
    //console.log("fetch bus " + BusHandle)
    const result = await Business.api().get('/api/businesses/GetBusinessByHandle/' + BusHandle).then((result) => {
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    if (result == 2) {
      return null;
    }
    else {
      return Business.query()
        .withAllRecursive()
        .where('BusHandle', (bushandle) => bushandle.toLowerCase() == BusHandle.toLowerCase()).first();
    }
  }

  static async fetchHandleAvailability(BusHandle) {
    //console.log("fetch handle " + BusHandle)
    let result = await Business.api().get('/api/businesses/GetHandleAvailability/' + BusHandle).then((result) => {
      //console.log(result.response.data)
      if (result.response.status == 200 || result.response.status == 201) {
        //console.log("success")
        return result.response.data;
      }
      else {
        //console.log("error");
        return -1;
      }
    }).catch(error => {
      //console.log(error.response);
      return -1;
    });
    return result

  }

  static async fetchSettings(BusId) {
    //console.log("fetch bus settings" + BusId)
    await Business.api().get('/api/businesses/GetBusinessSettings/' + BusId);
    //console.log(Business.find(BusId))
    return Business.query().find(BusId);
  }

  //function call to use after profile is updated 
  static async fetchBusinessProfileById(BusId) {
    EventType.delete((eventType) => eventType.BusId == BusId);
    AttendeeCount.delete((count) => count.BusId == BusId);
    BusinessServiceLocation.deleteAll();
    await Business.api().get('/api/businesses/getbusinessprofile/' + BusId)

    return Business.query()
      .withAllRecursive()
      .find(BusId);
  }
  static fetchBusinessName(BusId) {
    //TODO - check if business is not in store, then fetch
    var bus = Business.find(BusId);
    if (bus) {
      return Business.find(BusId).BusName;
    }
    else {
      // await Business.fetchById(BusId) TODO
      return "";
    }
  }

  static fetchBusDisplayCalendar(BusId) {
    //TODO - check if business is not in store, then fetch
    var bus = Business.find(BusId);
    if (bus) {
      return Business.find(BusId).BusDisplayCalendar == true ? true : false;
    }
    else {
      return "";
    }
  }
  static async fetchByOwnerId(OwnerCustId) {
    Business.deleteAll();
    var result = await Business.api().get('/api/businesses/getbusinessesbyowner/' + OwnerCustId)
    //console.log(result)
    return Business.query().where('BusOwnerCustId', OwnerCustId).get();
  }
  // static async fetchByLocation(LocationId) {
  //   Business.deleteAll();
  //   await Business.api().get('/api/businesses/getbusinessesbylocation/' + LocationId)
  //   // //console.log(Business.query().withAllRecursive().all())      
  //   return Business.query().withAllRecursive().all();
  // }

  static async fetchByLocation(LocationId) {
    Business.deleteAll();
    const returnvalue = await Business.api().get('/api/businesses/getbusinessesbylocation/' + LocationId).then((result) => {
      //console.log(result);
      return Business.query().withAllRecursive().all();
    }).catch(error => {
      //console.log(error.response);
      return new Array();
    });
    return returnvalue;

  }

  static async fetchByLocationWithKeywords(LocationId, SearchWords) {
    //console.log("Search with Words")
    Business.deleteAll();
    const response = await Business.api().get('/api/businesses/getbusinessesbylocation/' + LocationId)
    //console.log(response)
    // return Business.query().with('EventTypes', (query) => {query.search(SearchWords)}).all();
    var allBus = Business.query().withAllRecursive().all();

    var result = [];
    result = Business.query().with('EventTypes', (query) => { query.search(SearchWords) }).search(SearchWords, { keys: ["BusName", "BusCity", "BusCategory", "BusDesc"] }).withAllRecursive().get();

    if (result.length == 0) {
      result = allBus.filter((bus) => {

        if (

          bus.EventTypes.some(
            (eventType) =>
              SearchWords.toLowerCase().includes(eventType.EventTypeLabel.toLowerCase()) || eventType.EventTypeLabel.toLowerCase().includes(SearchWords.toLowerCase())
          )
        )
          return true;
      });
    }

    return result;
  }
  static async updateBusiness(business) {
    //console.log("at updatebusiness method")
    //console.log(business)
    var data = new FormData();
    if (business.NewBusinessImages.length > 0) {
      for (var x = 0; x < business.NewBusinessImages.length; x++) {
        data.append('galleryImages', business.NewBusinessImages[x]);
      }
      business.BusinessImages = null;
    }

    if (business.NewLogo.length > 0) {
      for (var i = 0; i < business.NewLogo.length; i++) {
        data.append('logo', business.NewLogo[i]);
      }
      business.BusLogo = null;
    }

    if (business != null) {
      data.append("businessString", JSON.stringify(business))
    }

    //console.log(JSON.stringify(business))
    //console.log("data")
    //console.log(data)


    const result = await Business.api().put('/api/businesses/putbusiness/' + business.BusId, data).then((result) => {
      if (result.response.status == 204) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
    // //console.log(Business.query().withAllRecursive().all())      
    // return Business.query().withAllRecursive().all();
  }
  static async updateSettings(business) {
    //console.log("at update business settings method")
    //console.log(business)

    const result = await Business.api().put('/api/businesses/updateSettings/' + business.BusId, business).then((result) => {
      if (result.response.status == 204) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }
  static async listNewBusiness(business, level) {
    //console.log("at list new business")
    //console.log(level)
    var data = new FormData();
    data.append("businessString", JSON.stringify(business))
    data.append('level', level);

    const result = await Business.api().post('/api/businesses/listnewbusiness/', data).then((result) => {
      //console.log(result)
      if (result.response.status == 200) {
        //console.log("success")
        return result.response.data;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }
  static  businessViewed(busview) {
    //console.log("businessViewed")
    //console.log(busview)

    const result = Business.api().post('/api/businesses/BusinessViewed/', busview).then((result) => {
      //console.log(result)
      if (result.response.status == 200) {
        //console.log("success")
        return result.response.data;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }
  static async completeSubscription(busId, level) {
    //console.log("complete subscription")
    var data = new FormData();
    data.append('busId', busId);
    data.append('subLevelCode', level)
    const result = await Business.api().post('/api/businesses/CompleteSubscription/', data).then((result) => {
      //console.log(result)
      if (result.response.status == 200) {
        //console.log("success")
        return result.response.data;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }


  static async deleteBusinessImage(imgId) {
    BusinessImage.delete(imgId);
    const result = await Business.api().delete('/api/businesses/deletebusinessimage/' + imgId).then((result) => {
      if (result.response.status == 204) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }


  static async enrollInPayments(busId) {
    //console.log("at enroll in payments business")
    //console.log(busId)

    const result = await Business.api().post('/api/businesses/enrollinpayments/' + busId).then((result) => {
      //console.log(result)
      if (result.response.status == 200) {
        //console.log("success")
        return result.response.data;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }


  static async resolveEnrollment(busId) {
    //console.log("at resolve enrollment")

    const result = await Business.api().post('/api/businesses/resolveenrollment/' + busId).then((result) => {
      //console.log(result)
      if (result.response.status == 200) {
        //console.log("success")
        return result.response.data;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }

  static async updateAccount(busId) {
    //console.log("at update enrollment")

    const result = await Business.api().post('/api/businesses/UpdateEnrollment/' + busId).then((result) => {
      //console.log(result)
      if (result.response.status == 200) {
        //console.log("success")
        return result.response.data;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }

  static async verifyProviderStatus(busId) {
    //console.log("verifyProviderStatus")

    const result = await Business.api().get('/api/businesses/verifyproviderstatus/' + busId).then((result) => {
      //console.log(result)
      if (result.response.status == 200) {
        //console.log("success")
        return result.response.data;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }

  static async createExternalAccount(busId, token) {
    //console.log("create external account")

    var bodyFormData = new FormData();
    bodyFormData.append('token', token);

    const result = await Business.api().post('/api/businesses/createexternalaccount/' + busId, bodyFormData).then((result) => {
      //console.log(result)
      if (result.response.status == 201) {
        //console.log("success")
        return result.response.data;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }
}

