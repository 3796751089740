import { Model } from '@vuex-orm/core'
/* eslint-disable */
import TimeSlot from "./TimeSlot"
import Business from "./Business"
export default class Schedule extends Model {
  static entity = 'schedules'
  
  static primaryKey = 'SchId'
  
  static fields () {
    return {
      SchId: this.uid(),
      BusId: this.attr(null),
      SchStartDate: this.attr(null),
      SchEndDate: this.attr(null),
      SchStartTime:this.attr(null),
      SchEndTime: this.attr(null),
      SchVisible: this.attr(null),
      SchMon: this.attr(null),
      SchTues: this.attr(null),
      SchWed: this.attr(null),
      SchThur: this.attr(null),
      SchFri: this.attr(null),
      SchSat: this.attr(null),
      SchSun: this.attr(null),
      TypeCode: this.attr(null),

      //virtual
      TimeSlots: this.hasMany(TimeSlot, 'SchId'),
      Business: this.belongsTo(Business, 'BusId'),  
    }
  }

  static async fetchByBusId(BusId) {
    //console.log("fetch bus " + BusId)
    Schedule.deleteAll();
    await Schedule.api().get('/api/schedules/GetBusinessSchedules/' + BusId);
    return Schedule.query()
      .withAllRecursive()
      .where(sch=>sch.BusId == BusId).all();
  }

  static async createSchedule(schedule) {
    //console.log("creating new schedule")
     const result =  await Schedule.api().post('/api/schedules/',
      schedule).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }

  static async updateSchedule(schedule) {
    const result =  await Schedule.api().put('/api/schedules/' + schedule.SchId, schedule)
    .then((result) => {
      //console.log(result);
       if (result.response.status == 204) {
         //console.log("success")
         return 1;
       }
       else{
         //console.log("error");
         return 2;
       } 
     }).catch(error => {
       //console.log(error.response);
       return 2;
     });
     return result;
 }

  static async deleteSchedule(schId) {
    const result =  await Schedule.api().delete('/api/schedules/deleteschedule/' + schId)
    .then((result) => {
      //console.log(result);
       if (result.response.status == 204) {
         //console.log("success")
         Schedule.delete(schId);
         return 1;
       }
       else{
         //console.log("error");
         return 2;
       } 
     }).catch(error => {
       //console.log(error.response);
       return 2;
     });
     return result;
 }
 
 static async deleteWithTimeslots(schId) {
  const result =  await Schedule.api().delete('/api/schedules/deleteScheduleWithTimeslots/' + schId)
  .then((result) => {
    //console.log(result);
     if (result.response.status == 204) {
       //console.log("success")
       Schedule.delete(schId);
       return 1;
     }
     else{
       //console.log("error");
       return 2;
     } 
   }).catch(error => {
     //console.log(error.response);
     return 2;
   });
   return result;
}
}

