<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content mt-3">
    <div class="grid">
      <div class="col-12 md:col-6">
        <Card style="text-align: left">
          <template #title> {{ $t("custreserv.newcard") }} </template>

          <template #content>
            <!-- <input
              id="cardholder-name"
              class="mb-2"
              type="text"
              :placeholder="$t('custreserv.card')"
            /> -->
            <stripe-card ref="stripeCard"></stripe-card>
            <!-- <div class="mb-2" ref="card"></div>
            <div id="card-result" class="mb-2"></div> -->
            <Button
              v-if="!addingCard"
              :label="$t('menu.submit')"
              class="p-button-raised p-button-success p-button-sm mt-2"
              autofocus
              @click.stop="createCard()"
            />
            <Button
              v-else
              :label="$t('menu.submit')"
              icon="pi pi-spin pi-spinner"
              class="p-button-raised p-button-success p-button-sm mt-2"
              disabled
            />
          </template>
        </Card>
      </div>
      <div class="col-12 md:col-6">
        <Card style="text-align: left">
          <template #title> {{ $t("custreserv.savecards") }} </template>
          <template #content>
            <DataView :value="cards" layout="list" class="service-grid">
              <template #empty> {{ $t("custreserv.nocards") }} </template>
              <template #list="slotProps">
                <div class="flex flex-wrap align-items-center mb-2">
                  <Card
                    class="m-2"
                    style="
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                    "
                  >
                    <template v-slot:content>
                      <div style="text-align: center">
                        <span style="font-size: x-large">
                          <b>x{{ slotProps.data.CardNumber }}</b
                          >&nbsp;
                        </span>
                        <br />
                        <small
                          
                        >
                          Exp {{ slotProps.data.CardExpMonth }}/{{
                            slotProps.data.CardExpYear
                          }}</small
                        >
                      </div>
                    </template>
                    <template v-slot:footer>
                      <div class="flex flex-wrap justify-content-center">
                        <Button
                          icon="pi pi-trash"
                          class="p-button-danger p-button-sm m-2"
                           v-tooltip="$t('code.deleteit')"
                          @click.stop="deleteCard(slotProps.data.CardId)"
                        />
                        <Button
                          v-if="
                            slotProps.data.CardDefault == false ||
                            slotProps.data.CardDefault == null
                          "
                          icon="pi pi-check"
                             v-tooltip="$t('code.setasdefault')"
                          class="p-button-success p-button-sm m-2"
                          @click.stop="setDefault(slotProps.data)"
                        />
                        <Button
                          v-if="slotProps.data.CardDefault == true"
                          icon="pi pi-check-circle"
                          class="p-button-success p-button-sm m-2"
                          disabled
                         
                        ></Button>
                      </div>
                      <div  v-if="slotProps.data.CardDefault == true" class = "text-center">
                        <Tag v-if="slotProps.data.CardDefault == true"
                            severity="success" 
                             style= "align : center"
                            >{{ $t("code.defaultcard") }}</Tag>
                      </div>
                      <!-- <div class="required-star" v-if="expiringSoon(slotProps.data.CardExpMonth, slotProps.data.CardExpYear)">
                        {{ $t("code.expiringsoon") }}
                      </div>
                       <div class="required-star" v-if="cardExpired(
                              slotProps.data.CardExpMonth,
                              slotProps.data.CardExpYear
                            )">
                        {{ $t("code.expired") }}
                      </div> -->
                    </template>
                  </Card>
                </div>
              </template>
            </DataView>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../store/Models/Card";
import { mapState } from "vuex";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import StripeCard from "../components/StripeCard.vue";
import moment from "moment";

// eslint-disable-next-line
// let stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY),
//   elements = stripe.elements(),
//   card = undefined;

export default {
  name: "CustomerPaymentCards",
  components: { LoadingOverlay, StripeCard },
  data() {
    return {
      selectedCard: {},
      editingCard: false,
      addingCard: false,
      errors: [],
      cards: [],
      newCard: {
        CardNumber: "",
        ExpMonth: 0,
        ExpYear: 0,
        CardCvv: "",
        CustId: "",
      },
      erros: [],

      loading: false,
      stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,

      stripe: "",
      elements: "",
      card: "",
    };
  },

  async mounted() {
    this.$log.debug("customer cards mounted");
    this.$log.debug(this.currentCustomerId);
    if (this.currentCustomerId > 0) {
      await this.setCustomerCards();
    }

    // card = elements.create("card", {
    //   style: {
    //     base: {
    //       iconColor: "#0d89ec",
    //       color: "#495057",
    //       fontWeight: "500",
    //       fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    //       fontSize: "16px",
    //       fontSmoothing: "antialiased",
    //       ":-webkit-autofill": {
    //         color: "#495057",
    //       },
    //     },
    //     invalid: {
    //       iconColor: "#d35278",
    //       color: "#495057",
    //     },
    //   },
    // });
    // card.mount(this.$refs.card);
    //  this.mountCard();
  },
  methods: {
    async createCard() {
      this.addingCard = true;
      await this.$refs.stripeCard.createCard().then((response) => {
        this.$log.debug(response);
        this.setCustomerCards();
        this.addingCard = false;
      });
    },

    // async createCard() {
    //   this.addingCard = true;
    //   // var cardholderName = document.getElementById("cardholder-name");
    //   // var resultContainer = document.getElementById("card-result");

    //   var paymentMethodId = "";

    //   /* eslint-disable*/
    //   stripe
    //     .createPaymentMethod({
    //       type: "card",
    //       card: card,
    //       billing_details: { name: cardholderName.value },
    //     })
    //     .then(async (result) => {
    //       if (result.error) {
    //         // Display error.message in your UI
    //         resultContainer.textContent = result.error.message;
    //       } else {
    //         // You have successfully created a new PaymentMethod
    //         // resultContainer.textContent =
    //         //   "Created payment method: " + result.paymentMethod.id;
    //         paymentMethodId = result.paymentMethod.id;
    //         await Card.createCard(paymentMethodId).then(async (response) => {
    //           if (response != null) {
    //             this.setCustomerCards();
    //             this.addingCard = false;
    //             this.$toast.add({
    //               severity: "success",
    //               summary: this.$t("custreserv.success"),
    //               detail: this.$t("code.cardcreate"),
    //               life: 3000,
    //             });
    //           } else {
    //             this.$toast.add({
    //               severity: "error",
    //               summary: this.$t("code.errors"),
    //               detail: this.$t("code.cardcretefailed"),
    //               life: 3000,
    //             });
    //           }
    //         });
    //       }
    //     });
    //   /*esling-enable*/
    // },
    async deleteCard(cardId) {
      this.$log.debug("delete card");

      this.$confirm.require({
        message: this.$t("code.undone"),
        header: this.$t("code.confirm"),
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          await Card.deleteCard(cardId).then(async (response) => {
            if (response == 1) {
              this.setCustomerCards();
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("code.carddelete"),
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("code.cardfailedtodelete"),
                life: 3000,
              });
            }
          });
        },
        reject: () => {},
      });
    },
    async setDefault(card) {
     
      this.$log.debug("make default card");
      this.$log.debug(card);
      this.$confirm.require({
        message: this.$t("code.updatingcard"),
        header: this.$t("code.defaultcard"),
        icon: "pi pi-exclamation-triangle",
        accept: async () => {  this.loading = true;
          await Card.makeDefault(card.CardId).then(async (response) => {
            if (response == 1) {
              this.setCustomerCards();
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("code.defaultupdated"),
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("code.changesfailedsave"),
                life: 3000,
              });
            }
          });
        },
        reject: () => {},
      });
    },
    async setCustomerCards() {
      this.loading = true;
      await Card.fetchByCustId(this.currentCustomerId).then((result) => {
        this.$log.debug(result);
        this.cards = result;
      });
      this.loading = false;
    },
    cardExpired(month, year) {
      var currentYear = moment().year() - 2000;
      var currentMonth = moment().month()+1;
      if(currentYear>year){
        return true;
      }
      else if (currentYear == year && currentMonth>month){
        return true;
      }
      else{
        return false;
      }
    },
    expiringSoon(month, year) {
      var currentYear = moment().year() - 2000;
      var currentMonth = moment().month()+1;
      if (currentYear == year && currentMonth==month){
        return true;
      }
      else{
        return false;
      }
    },
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCustomerCards();
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style>
.expired {
  color: red;
  font-weight: bold;
}
.p-datatable-header {
  background-color: white !important;
}

#cardholder-name {
  border: none;
  color: #495057;
  font-weight: 500;
  font-family: Roboto, Open Sans, Segoe UI, sans-serif;
  font-size: 16px;
}

#cardholder-name:focus {
  outline: none;
}
</style>
