import { Model } from '@vuex-orm/core'
/* eslint-disable */
import ServiceType from "./ServiceType"
import ServiceImage from "./ServiceImage"
import Business from "./Business"


export default class Service extends Model {
  static entity = 'services'

  static primaryKey = 'ServId'

  static fields() {
    return {
      ServId: this.uid(),
      BusId: this.attr(null),
      ServTitle: this.attr(null),
      ServShortDesc: this.attr(null),
      ServHtmldesc: this.attr(null),
      ServPrice: this.attr(null),
      ServPriceCalculated: this.attr(null), //true or false based on count
      ServImage: this.attr(null),
      ServActive: this.attr(null),
      ServDeleted: this.attr(null),
      ServPromotionExpires: this.attr(null),
      ServiceReservExtra: this.attr(null),
      ServiceForStore: this.attr(null),
      //Virtual
      Bus: this.belongsTo(Business, 'BusId'),
      ServiceTypes: this.hasMany(ServiceType, 'ServId', 'ServId'),
      ServiceImages:this.hasMany(ServiceImage, 'ServId', 'ServId'),
    }
  }

  static resetServiceStore() {
    Service.deleteAll();
  }

  static async fetchFullById(ServId) {
    //console.log("Fetching service by id")
    const result = await this.api().get('/api/services/' + ServId)
    //console.log(result)
    return this.query()
      .withAllRecursive()
      .find(ServId);
  }
  static async fetchPublicBusinessServices(BusId) {
    //console.log("fetching business services")
    await Service.api().get('/api/services/getPublicServices/' + BusId)
    return Service.query().with("ServiceTypes").with("ServiceImages").where('BusId', BusId).get();
  }
  static async fetchPublicStoreBusinessServices(BusId) {
    //console.log("fetching business services")
    await Service.api().get('/api/services/getPublicServices/' + BusId)
    return Service.query().with("ServiceTypes").with("ServiceImages").where('BusId', BusId).where('ServiceForStore',true).get();
  }
  static async fetchBusinessServices(BusId) {
    //console.log("fetching business services")
    await Service.api().get('/api/services/getServices/' + BusId)
    return Service.query().with("ServiceTypes").with("ServiceImages").where('BusId', BusId).get();
  }

  static async createService(service) {
    //console.log("creating service")
    //console.log(service)

    var data = new FormData();

    if (service.NewServiceImages!=null &&  service.NewServiceImages.length > 0) {
      for (var i = 0; i < service.NewServiceImages.length; i++) {
        data.append('serviceImages', service.NewServiceImages[i]);
      }
      service.ServImage = null;
    }

    if (service != null) {
      data.append("serviceString", JSON.stringify(service))
    }

    const result = await Service.api().post('/api/services/postservice/', data).then((result) => {
      if (result.response.status == 201) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }

  static async updateService(service) {
    //console.log("updating service")
    //console.log(service)
    var data = new FormData();

    if (service.NewServiceImages!=null &&  service.NewServiceImages.length > 0) {
      for (var i = 0; i < service.NewServiceImages.length; i++) {
        data.append('serviceImages', service.NewServiceImages[i]);
      }
      service.ServImage = null;
    }

    if (service != null) {
      data.append("serviceString", JSON.stringify(service))
    }

    ServiceType.delete((serviceType) => serviceType.ServId == service.ServId);
    const result = await Service.api().put('/api/services/putservice/' + service.ServId, data).then((result) => {
      if (result.response.status == 204) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }

  static async deleteService(servId) {
    //console.log("deleting service")
    //console.log(servId)

    const result = await Service.api().delete('/api/services/deleteservice/' + servId).then((result) => {
      if (result.response.status == 204) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }

  static async removeRecursive(servId) {
    Service.delete(servId);
    ServiceType.delete((type) => {
      type.ServId == servId
    });
  }

  static async deleteServiceImage(imgId) {
    ServiceImage.delete(imgId);
    const result = await Service.api().delete('/api/services/deleteserviceimage/' + imgId).then((result) => {
      if (result.response.status == 204) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }

}

