/* eslint-disable */
import { Model } from '@vuex-orm/core'
import Customer from "./Customer"
export default class Review extends Model {
  static entity = 'reviews'
  
  static primaryKey = 'ReviewId'
  
  static fields () {
    return {
      ReviewId: this.uid(),
      BusId: this.attr(null),
      CustId: this.attr(null),
      ReviewTitle: this.attr(null),
      ReviewText: this.attr(null),
      ReviewStars: this.attr(null),
      ReviewDate: this.attr(null),
      ReviewResponse: this.attr(null),
      ReviewResponseToId: this.attr(null),
      Cust: this.belongsTo(Customer, 'CustId')
      
    }
  }

  static async fetchByBusId(BusId) {
    //console.log("fetch reviews for " + BusId)
   var result = await Review.api().get('/api/reviews/GetBusReviews/' + BusId);
    //console.log(result)
    return  Review.query()
      .withAllRecursive()
      .where(review=>review.BusId == BusId).orderBy('ReviewId', 'desc').all();
  }

  static async newReview(review) {
    //console.log("at new review")
    //console.log(review)

    const result = await Review.api().post('/api/reviews/', review).then((result) => {
      //console.log(result)
      if (result.response.status == 200 || result.response.status == 204 ) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }
}

