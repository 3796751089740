import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Customer from "./Customer"

export default class Card extends Model {
  static entity = 'cards'

  static primaryKey = 'CardId'

  static fields() {
    return {

      CardId: this.uid(),
      CustId: this.attr(null),
      Customer: this.belongsTo(Customer, 'CustId'),
      CardNumber: this.attr(null),
      CardDefault: this.attr(null),
      CardExpMonth:  this.attr(null),
      CardExpYear:  this.attr(null)

    }
  }

  static async fetchByCustId(CustId) {
    Card.deleteAll();
    //console.log("fetch cards for " + CustId)
    var result = await Card.api().get('/api/cards/GetCustomerCards/' + CustId);
    //console.log(result)
    return Card.query()
      .withAllRecursive()
      .where(card => card.CustId == CustId).get();
  }

  static async createCard(card) {
    //console.log("creating new card")
    const result = await Card.api().post('/api/cards/postcard/' + card,
      ).then((result) => {
        //console.log(result)
        if (result.response.status == 201 || result.response.status == 200) {
          //console.log("success")
          return result.response.data.CardId;
        }
        else {
          //console.log("error");
          return null;
        }
      }).catch(error => {
        //console.log(error.response);
        return null;
      });
    return result;
  }

  static async deleteCard(cardId) {
    const result = await Card.api().delete('/api/cards/' + cardId,
      { delete: cardId }
    )
      .then((result) => {
        //console.log(result);
        if (result.response.status == 204 ||result.response.status == 200) {
          //console.log("success")
          Card.delete(cardId);
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }
  static async makeDefault(cardid) {
    const result = await Card.api().post('/api/cards/makedefault/'+cardid
    )
      .then((result) => {
        //console.log(result);
        if (result.response.status == 201 || result.response.status == 200 ) {
          //console.log("success");
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }
}

