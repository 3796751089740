import { Model } from '@vuex-orm/core'
import TimeSlot from "./TimeSlot"
import Business from "./Business"
import Customer from "./Customer"
/* eslint-disable */

export default class BusinessAppointment extends Model {
  static entity = 'businessAppointment'

  static primaryKey = 'ApptId'

  static fields() {
    return {
      ApptId: this.uid(),
      CustId: this.attr(null),
      BusId: this.attr(null),
      SlotId: this.attr(null),
      TypeCode: this.attr(null),
      ApptCustNotes: this.attr(null),
      ApptBusNotes: this.attr(null),
      ApptConfirmed: this.attr(null),
      ApptCanceled: this.attr(null),
      ApptStaff: this.attr(null),

      //virtual
      Slot: this.belongsTo(TimeSlot, 'SlotId'),
      Cust: this.belongsTo(Customer, 'CustId'),
      Bus: this.belongsTo(Business, 'BusId'),

    }
  }

  // static async fetchAll() {
  //   await this.api().get('/api/attendeecountdefs/')
  //   return this.all();
  // }

  static async createAppt(appt) {
    //console.log("creating new appt")
    const result = await BusinessAppointment.api().post('/api/BusinessAppointments/',
      appt).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if (result.response.status == 200) {
          //console.log("duplicate")
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }

  static async updateAppt(appt) {
    const result = await BusinessAppointment.api().put('/api/BusinessAppointments/' + appt.ApptId, appt)
      .then((result) => {
        //console.log(result);
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        else {
         //console.log("error");
          return 2;
        }
      }).catch(error => {
       //console.log(error.response);
        return 2;
      });
    return result;
  }
  static async fetchByCustId(CustId) {
    //console.log("fetch Appts for cust" + CustId)
    // BusinessAppointment.deleteAll();
    await BusinessAppointment.api().get('/api/BusinessAppointments/GetCustomerAppts/' + CustId);
    //console.log(result)
    return BusinessAppointment.query()
      .withAllRecursive()
      .where(appt => appt.CustId == CustId).orderBy('ApptId', 'desc').all();
  }

  static async fetchById(ApptId) {
    //console.log("fetch Appts #" + ApptId)
    await BusinessAppointment.api().get('/api/BusinessAppointments/GetBusinessAppointment/' + ApptId);
    //console.log(result)
    return BusinessAppointment.query().withAllRecursive().find(ApptId);
  }


  static async fetchBySlotId(SlotId) {
    //console.log("fetch Appt by slotid #" + SlotId)
    const appt = await BusinessAppointment.api().get('/api/BusinessAppointments/GetApptsByTimeSlot/' + SlotId).then((result) => {
     //console.log(result)
      //console.log(result.response.data.ApptId)
      if (result.response.status == 200) {
        ////console.log(result.response.data.SlotId)
        ////console.log(BusinessAppointment.query().withAllRecursive().where('SlotId',result.response.data.SlotId).first())
        return BusinessAppointment.query().withAllRecursive().where('SlotId', result.response.data.SlotId).first();
      }
      else
        return null;
    }).catch(error => {
      //console.log(error.response);
      return null;
    });
    return appt;
  }

  static async fetchByBusId(BusId) {
   //console.log("fetch Appts for bus " + BusId)
    var result = await BusinessAppointment.api().get('/api/BusinessAppointments/GetBusAppts/' + BusId);
    //console.log(result)
    return BusinessAppointment.query()
      .withAllRecursive()
      .where(appt => appt.BusId == BusId).orderBy('ApptId', 'desc').all();
  }

  static async cancel(ApptId) {
    //console.log("request cancel Appt for " + ApptId)
    var result = await BusinessAppointment.api().put('/api/BusinessAppointments/Cancel/' + ApptId).then((result) => {
      if (result.response.status == 204) {
        //console.log("success")
        return 1;
      }
      if (result.response.status == 200) {
        //console.log("duplicate")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }
}

