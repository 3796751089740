<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <div class="mt-3" v-if="currentBusiness != null && currentBusiness.BusActive == true && currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode == 'PREMIUM' ">
      <DataTable
        :value="reservs"
        :paginator="true"
        class="p-datatable-sm"
        :rows="20"
        ref="dt"
        :resizableColumns="true"
        filterDisplay="menu"
        responsiveLayout="stack"
        breakpoint="960px"
        dataKey="ReservId"
        :filters="filters"
        :loading="loading"
        sortField="Slot.SlotDate"
        :sortOrder="-1"
        :globalFilterFields="[
          'Cust.CustFirstName',
          'Cust.CustLastName',
          'ReservId',
          'Slot.SlotDate',
        ]"
      >
        <template #empty> {{ $t("calendar.noreservations") }} </template>
        <template #loading> {{ $t("calendar.loadingreservations") }} </template>
        <template #header>
          <div class="flex justify-content-between">
            <div style="text-align: left">
              <Button
                class="mr-2"
                icon="pi pi-external-link"
                :label="$t('menu.export')"
                @click="exportCSV($event)"
              />
            </div>
          </div>
        </template>
        <div class="mb-2">
          <Column field="ReservNum" header="Reserv#" :sortable="true">
            <template #body="{ data }">
              <span v-if="data.ReservNum != null">{{ data.ReservNum }}</span>
            </template>
          </Column>
          <Column
            field="Cust.CustLastName"
            :header="$t('calendar.name')"
            :sortable="true"
          >
            <template #body="{ data }">
              <span v-if="data.Cust.CustLastName != null"
                >{{ data.Cust.CustLastName }},
                {{ data.Cust.CustFirstName }}</span
              >
            </template>
          </Column>
          <Column
            field="Slot.SlotDate"
            :header="$t('calendar.date')"
            :sortable="true"
          >
            <template #body="{ data }">
              <span v-if="data.Slot != null">{{
                $formatDateTimeToDate(data.Slot.SlotDate)
              }}</span>
            </template>
          </Column>
          <Column
            field="Slot.SlotStartTime"
            :header="$t('calendar.start')"
            :sortable="true"
          >
            <template #body="{ data }">
              <span v-if="data.Slot != null">{{
                $formatTime(data.Slot.SlotStartTime)
              }}</span>
            </template>
          </Column>
          <Column
            field="ReservTotalPaid"
            :header="$t('calendar.paid')"
            :sortable="true"
          >
            <template #body="{ data }">
              <span v-if="data.ReservTotalPaid != null"
                >${{ data.ReservTotalPaid }}</span
              >
            </template>
          </Column>
          <Column
            field="ReservTotalOwed"
            :header="$t('calendar.owed')"
            :sortable="true"
          >
            <template #body="{ data }">
              <span v-if="data.ReservTotalOwed != null"
                >${{ data.ReservTotalOwed }}</span
              >
            </template>
          </Column>
        
         
          <Column field="Details" :header="$t('calendar.detail')">
            <template #body="{ data }">
              <div>
                <Button
                  icon="pi pi-file"
                  v-tooltip.bottom="$t('quotes.viewinvoice')"
                  class="p-button-info p-button-sm mb-2 mr-1"
                  @click.stop="openQuote(data.Quote)"
                />
                <Button
                  icon="pi pi-money-bill"
                  v-tooltip.bottom="$t('quotes.viewpayments')"
                  class="p-button-success p-button-sm mb-2 mr-1"
                  @click.stop="openPayments(data)"
                />
              </div>
            </template>
          </Column>
          <Column field="Contact" :header="$t('calendar.contact')">
            <template #body="{ data }">
              <div>
                <Button
                  icon="pi pi-comments"
                  v-tooltip.bottom="$t('quotes.messagecustomer')"
                  class="p-button-warning p-button-sm mb-2 mr-1"
                  @click.stop="
                    reservCust = data.CustId;
                    showNewMessage = true;
                  "
                />
                <Button
                  icon="pi pi-phone"
                  v-tooltip.bottom="$t('quotes.callcustomer')"
                  class="p-button-danger p-button-sm mb-2 mr-1"
                  @click="toggle"
                />
                <OverlayPanel ref="op">
                  <a :href="'tel:' + data.Cust.CustPhone">
                    {{ data.Cust.CustPhone }}</a
                  >
                </OverlayPanel>
              </div>
            </template>
          </Column>
        </div>
      </DataTable>
      <Dialog
        v-model:visible="showingNewPayment"
        :modal="true"
        :header="$t('code.applypayment')"
        :dismissableMask="true"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <div style="text-align: left">
          <div class="field col-12">
            <label>Payment Date: </label><br />
            <Calendar
              id="newStartDate"
              v-model="newPayment.PayDate"
              :showIcon="true"
            />
          </div>
          <div class="field col-12">
            <label>Payment Amount: </label><br />

            <InputNumber
              id="currency-us"
              placeholder="$"
              v-model="newPayment.PayAmount"
              mode="currency"
              currency="USD"
              locale="en-US"
              style="font-size: small"
            />
          </div>
          <div class="field col-12">
            <label>Payment Source </label><br />
         
      <label for="language"> </label>
      <Dropdown
        :options="paysource"
        optionLabel="desc"
        optionValue="code"
        id="source"
        v-model="newPayment.PaySource"
      />
    </div>

          <Button
            v-if="!processingPayment"
            icon="pi pi-arrow-right"
            label="Submit"
            class="p-button-primary p-button-outlined p-button-sm mt-2"
            @click.stop="startPayment()"
          />
          <Button
            v-else
            icon="pi pi-spin pi-spinner"
            disabled
            label="Submit"
            class="p-button-primary p-button-outlined p-button-sm mt-2"
          />
        </div>
      </Dialog>

      <Dialog
        v-model:visible="viewingPayments"
        :style="{ width: '90vw' }"
        :modal="true"
        :header="$t('code.paymentsfor') + ' ' + selectedReserv.ReservNum"
        :dismissableMask="true"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <Button
          icon="pi pi-dollar"
          :label="$t('code.applypayment')"
          class="p-button-success p-button-sm mb-2"
          @click.stop="showNewPayment(selectedReserv)"
        />
        <DataTable
          :value="selectedReserv.ReservPayments"
          sortField="PayDate"
          :sortOrder="-1"
          responsiveLayout="stack"
          :resizableColumns="true"
          columnResizeMode="fit"
        >
          <Column
            field="PayDate"
            :header="$t('calendar.date')"
            :sortable="true"
          >
            <template #body="slotProps">
              {{ $formatDateTime(slotProps.data.PayDate) }}
            </template>
          </Column>
           <Column
            field="PayNum"
            :header="$t('payments.payid')"
            :sortable="true"
          ></Column>
          <Column
            field="Card.CardNumber"
            :header="$t('custreserv.custcard')"
            :sortable="true"
          ></Column>
          <Column
            field="PayStatus"
            :header="$t('calendar.status')"
            :sortable="true"
          ></Column>
          <Column
            field="PayAmount"
            :header="$t('custreserv.custamount')"
            :sortable="true"
          > <template #body="slotProps">
            ${{ slotProps.data.PayAmount }}
          </template></Column>
          <Column
            field="PaySource"
            :header="$t('payments.paysource')"
            :sortable="true"
          ></Column>
        </DataTable>
      </Dialog>

      <Dialog
        v-model:visible="showNewMessage"
        :modal="true"
        :contentStyle="{ overflow: 'scroll' }"
        :header="$t('message.new')"
        :dismissableMask="true"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <div style="text-align: left">
          <Textarea
            class="mt-2"
            v-model="newMessageText"
            :placeholder="$t('message.type')"
            :autoResize="true"
            rows="5"
            cols="30"
            style="width: 100%"
          />
        </div>
        <div class="flex justify-content-between">
          <Button
            :label="$t('profile.cancel')"
            v-tooltip.right="$t('profile.cancel')"
            icon="pi pi-times"
            iconPos="left"
            @click.stop="
              showNewMessage = false;
              newMessageText = '';
            "
            class="mr-2 p-button-danger p-button-sm p-button-raised"
          />
          <Button
            :label="$t('menu.send')"
            v-tooltip.right="$t('menu.send')"
            icon="pi pi-play"
            iconPos="left"
            @click.stop="sendNewMessage"
            class="mr-2 p-button-success p-button-sm p-button-raised"
          />
        </div>
      </Dialog>

      <Dialog
        v-model:visible="viewingQuote"
        :style="{ width: '90vw' }"
        :modal="true"
        :header="selectedQuote.QuoteNum"
        :dismissableMask="true"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <div class="grid">
          <div class="col-12 grid-nogutter"></div>
          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: left">
              <div style="background-color: #e9ecef">
                <span style="font-size: x-large">{{
                  $t("custreserv.custinfo")
                }}</span>
              </div>
              <b>{{ $t("custreserv.custname") }}: </b>
              {{ selectedQuote.Cust.CustFirstName }}
              {{ selectedQuote.Cust.CustLastName }}
              <br />
              <b>{{ $t("custreserv.custphone") }}: </b
              >{{ selectedQuote.Cust.CustPhone }}
              <br />
              <b>{{ $t("profile.address") }}: </b>
              {{ selectedQuote.Cust.CustAddress }}<br />
              {{ selectedQuote.Cust.CustCity }},
              {{ selectedQuote.Cust.CustState }}
              {{ selectedQuote.Cust.CustZip }}
              <br />
              <b>{{ $t("message.email") }}: </b>
              {{ selectedQuote.Cust.CustEmail }}
            </div>
          </div>
          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: right">
              <div style="background-color: #e9ecef">
                <span style="font-size: x-large">
                  {{ $t("custreserv.custprocess") }}</span
                >
              </div>
              <b>{{ $t("custreserv.custdatere") }}: </b
              >{{ $formatDateTime(selectedQuote.QuoteRequestDate) }}
              <br />
              <b>{{ $t("custreserv.custdatepro") }}: </b
              ><span v-if="selectedQuote.QuoteProcessedDate">{{
                $formatDateTime(selectedQuote.QuoteProcessedDate)
              }}</span
              ><span v-else>{{ $t("custreserv.custdatenotpro") }}</span>
              <br />
              <b>{{ $t("custreserv.custproby") }}: </b
              ><span v-if="selectedQuote.QuoteProcessedBy">{{
                selectedQuote.QuoteProcessedBy
              }}</span
              ><span v-else>{{ $t("custreserv.custdatenotpro") }} </span><br />
              <b>{{ $t("custreserv.custresponded") }}: </b
              ><span v-if="selectedQuote.QuoteResponseDate">{{
                $formatDateTime(selectedQuote.QuoteResponseDate)
              }}</span
              ><span v-else>{{ $t("custreserv.custnotapp") }}</span>
            </div>
          </div>

          <div class="col-12" style="background-color: #e9ecef">
            <span style="font-size: x-large">{{
              $t("custreserv.custevent")
            }}</span
            ><br />
          </div>
          <div class="col-12">
            <template v-if="selectedQuote.Slot != null">
              {{ $formatDateTimeToDate(selectedQuote.Slot.SlotDate) }}<br />
              {{ $formatTime(selectedQuote.Slot.SlotStartTime) }} -
              {{ $formatTime(selectedQuote.Slot.SlotEndTime) }}
            </template>
          </div>
          <div class="col-12 mb-2" style="background-color: #e9ecef">
            <span style="font-size: x-large">{{
              $t("services.services")
            }}</span>
          </div>
          <div class="col-4" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custservice") }}</b>
          </div>
          <div class="col-3" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custprice") }}</b>
          </div>
          <div class="col-2" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custqty") }}</b>
          </div>
          <div
            class="col-3"
            style="background-color: #f8f9fa; text-align: right"
          >
            <b>{{ $t("custreserv.custamount") }}</b>
          </div>

          <template
            v-for="serv in selectedQuote.QuotedServices"
            :key="serv.QuoteServId"
          >
            <template
              v-if="
                serv.QuoteServOrigId == null && serv.QuoteServRemoved == false
              "
            >
              <div class="col-4">
                <span style="vertical-align: -webkit-baseline-middle"
                  >{{ serv.Serv.ServTitle }}
                </span>
              </div>
              <div class="col-3 flex flex-wrap align-items-center">
                <span style="vertical-align: -webkit-baseline-middle">
                  ${{ serv.ServPrice }}
                </span>
                <span
                  style="
                    vertical-align: -webkit-baseline-middle;
                    font-size: small;
                  "
                  v-if="serv.Serv.ServPriceCalculated == true"
                >
                  &nbsp;{{ $t("quotes.each") }}
                </span>
              </div>
              <div class="col-2" style="vertical-align: middle">
                <span style="vertical-align: -webkit-baseline-middle">{{
                  serv.QuoteServQty
                }}</span>
              </div>
              <div class="col-3" style="text-align: right">
                <span v-if="serv.QuoteServTotal != 0"
                  ><b style="vertical-align: -webkit-baseline-middle"
                    >${{ serv.QuoteServTotal }}</b
                  ></span
                >
              </div>
            </template>
          </template>

          <div
            class="col-12 mt-5 grid grid-nogutter justify-content-end"
            style="text-align: right"
          >
            <div class="col-9" style="background-color: #f8f9fa">
              <b>{{ $t("custreserv.custsub") }}&nbsp;&nbsp;</b>
            </div>
            <div class="col-3">
              <span v-if="selectedQuote.QuoteSubTotal != 0"
                ><b>${{ selectedQuote.QuoteSubTotal }}</b></span
              >
            </div>
            <div
              v-if="selectedQuote.QuoteDiscount != 0"
              class="col-9"
              style="background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custdis") }}&nbsp;&nbsp;</b>
            </div>
            <div v-if="selectedQuote.QuoteDiscount != 0" class="col-3">
              <span
                ><b>${{ selectedQuote.QuoteDiscount }}</b></span
              >
            </div>
            <div
              class="col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custsales") }}&nbsp;&nbsp;</b>
            </div>
            <div class="col-3">
              <span v-if="selectedQuote.QuoteSubTotal != 0">
                <b>${{ selectedQuote.QuoteTax }}</b></span
              >
            </div>
            <div
              class="col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custtotal") }}&nbsp;&nbsp;</b>
            </div>
            <div class="col-3">
              <span v-if="selectedQuote.QuoteSubTotal != 0">
                <b>${{ selectedQuote.QuoteTotal }}</b></span
              >
            </div>
          </div>

          <div class="col-12" style="background-color: #e9ecef">
            <span style="font-size: x-large"
              >{{ $t("custreserv.custcomments") }}
            </span>
          </div>
          <div class="col-12">
            <div v-if="selectedQuote.QuoteComments.length > 0">
              <template
                v-for="comment in selectedQuote.QuoteComments"
                :key="comment.CommentId"
              >
                <div class="card" style="text-align: left">
                  <div class="flex justify-content-between">
                    <span>
                      {{ comment.Cust.CustFirstName }}
                      {{ comment.Cust.CustLastName }}</span
                    >
                    <span> {{ $formatDateTime(comment.CommentDate) }}</span>
                  </div>
                  <p>
                    <em>{{ comment.CommentText }}</em>
                  </p>
                </div>
              </template>
            </div>
            <div v-else>
              {{ $t("custreserv.nocomments") }}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
    <div v-else-if="currentBusiness.BusActive == false">
      <inactive-subscription
        :busId="currentBusiness.BusId"
      ></inactive-subscription>
    </div>
    <div v-else-if="currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode != 'PREMIUM' ">
     <!-- <div v-else-if="true"> -->
      <upgrade-subscription
        :subId="currentSubscription.SubId"
      ></upgrade-subscription>
    </div>
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{ $t("payments.error") }}
      </Message>
    </div>
  </div>
</template>

<script>
import Reservation from "../store/Models/Reservation";
import Business from "../store/Models/Business";
import ReservPayment from "../store/Models/ReservPayment";
import Conversation from "../store/Models/Conversation";
import LoadingOverlay from "../components/LoadingOverlay.vue";

import { mapState } from "vuex";
import { mapActions } from "vuex";
import InactiveSubscription from "../components/InactiveSubscription.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Subscription from '../store/Models/Subscription';
import UpgradeSubscription from '../components/UpgradeSubscription.vue';

export default {
  name: "BusinessReservations",
  components: { LoadingOverlay, InactiveSubscription, UpgradeSubscription },
  props: {
    reservId: { default: 0, type: Number },
    busId: { default: 0, type: Number },
  },
  data() {
    return {
      selectedQuote: {},
      viewingQuote: false,
      viewingPayments: false,
      reservPayments: [],
      loading: false,
      reservs: [],
      currentBusiness: {},
      reservIdToFind: "",
      selectedReserv: {},
      showingNewPayment: false,
      processingPayment: false,
      currentSubscription: {},
      filters: {},
      newMessageText: "",
      reservCust: 0,
      showNewMessage: false,
      paysource: [
        { code: "cash", desc: "Cash" },
        { code: "capp", desc: "Cashapp" },
        { code: "zelle", desc: "Zelle" },
        { code: "check", desc: "Check" }
        
      ],
      newPayment: {
        ReservId: 0,
        PayAmount: null,
        PayDate: new Date(),
        PayStatus: "Manual",
        PaySource: null
      },
      waitingOnBusiness: false,
    };
  },
  async mounted() {
    this.$log.debug("business reservations mounted");
    this.$log.debug(this.$route.params.reservId);
    this.$log.debug(this.$route.params.busId);
    this.$log.debug(this.currentBusinessId);
    if (this.currentBusinessId > 0) {
      await this.setBusReservs();
    }
    if (this.$route.params.reservId > 0) {
      this.loading = true;
      if (this.currentBusinessId != this.$route.params.busId) {
        this.waitingOnBusiness = true;
        await this.setCurrentBusiness(this.$route.params.busId);
      } else {
        this.onLoadWithReserv();
      }
    }
    this.initFilters();
  },
  methods: {
    ...mapActions(["setCurrentBusiness"]),
    onLoadWithReserv() {
      let foundReserv = this.reservs.find(
        (reserv) => reserv.ReservId == this.$route.params.reservId
      );
      this.$log.debug(foundReserv);
      if (foundReserv) {
        this.openQuote(foundReserv.Quote);
        this.$router.replace("/BusinessReservations");
      }
      this.loading = false;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    showNewPayment(reserv) {
      this.showingNewPayment = true;
      this.viewingPayments = false;
      this.newPayment.ReservId = reserv.ReservId;
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    async startPayment() {
      this.processingPayment = true;

      ReservPayment.newManualPayment(this.newPayment).then(async (response) => {
        this.$log.debug(response);
        this.processingPayment = false;
        if (response != null && response.sessionId != 0) {
          this.setBusReservs();
          this.newPaymentReset();
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("payments.reservationpayment"),
            life: 3000,
          });
        }
      });
    },
    newPaymentReset() {
      this.showingNewPayment = false;
      this.newPayment = {
        ReservId: 0,
        PayAmount: null,
        PayDate: new Date(),
        PayStatus: "Manual",
        PaySource: null
      };
    },
    clearFilter() {
      this.initfilters();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
      this.$log.debug("filters");
      this.$log.debug(this.filters["global"]);
    },
    async sendNewMessage() {
      let newConvo = {
        ConvoId: 0,
        BusId: this.currentBusinessId,
        CustId: this.reservCust,
        Messages: [],
      };

      let newMessage = {
        MessageId: 0,
        ConvoId: 0,
        MessageSentBy: "bus", //"customer/business"
        MessageDate: new Date(),
        MessageText: this.newMessageText,
        MessageRead: false,
      };

      newConvo.Messages.push(newMessage);

      this.$log.debug(newConvo);
      await Conversation.newConvo(newConvo).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          this.showNewMessage = false;
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("message.messagesent"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("message.messagefailed"),
            life: 3000,
          });
        }
      });
    },
    openQuote(quote) {
      this.selectedQuote = quote;
      this.viewingQuote = true;
    },
    openPayments(reserv) {
      this.selectedReserv = reserv;
      this.viewingPayments = true;
    },
    async setBusReservs() {
      this.loading = true;
      Business.fetchBusinessProfileById(this.currentBusinessId).then(
        (result) => {
          this.currentBusiness = result;
        }
      );
      Subscription.fetchCurrentByBusiness(this.currentBusinessId).then((result) => {
          this.currentSubscription = result;
         //console.log(this.currentSubscription)
        });     
      await Reservation.fetchByBusId(this.currentBusinessId).then((result) => {
        this.$log.debug(result);
        this.reservs = result;
      });
      if (this.waitingOnBusiness) {
        this.onLoadWithReserv();
      }
      this.loading = false;
    },
  },
  watch: {
    async currentBusinessId() {
      this.setBusReservs();
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style>
.p-datatable-header {
  background-color: white !important;
}
</style>
