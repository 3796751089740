<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>

  <div v-else class="content mt-3">
    <div class="flex">
      <Button icon="pi pi-plus" style="float: right" :label="$t('custevent.newevent')"
        class="p-button-primary ml-auto mb-2" @click.stop="creatingNewCustEvent()" />
    </div>

    <DataView :value="custEvents" layout="list" class="service-grid">
      <template #empty>
        {{ $t("custevent.noevents") }}
      </template>
      <template #list="slotProps">
        <div class="col-12 md:col-6 lg:col-4">
          <Card class="m-2">
            <template #title>
              <div class="flex flex-wrap justify-content-between align-content-end">
                <div>
                  <Button v-if="slotProps.data.CustId == currentCustomerId" v-tooltip.right="$t('guestlists.delete')"
                    class="m-1" severity="secondary" outlined @click.stop="deleteCustEvent(slotProps.data.EventId)"><i
                      class="pi pi-trash"></i></Button>
                  <Button v-else v-tooltip.right="$t('guestlists.notowner')" class="m-1" severity="secondary" outlined
                    disabled><i class="pi pi-trash"></i></Button>

                </div>
                <div>

                  <Button v-tooltip.right="$t('menu.edit')" class="m-1" outlined :id="'b' + slotProps.data.EventId"
                    @click="toggle" aria-haspopup="true" aria-controls="overlay_panel">
                    <i :id="'i' + slotProps.data.EventId" class="pi pi-bars"></i>
                  </Button>
                  <OverlayPanel ref="op" appendTo="body">
                    <div class="flex flex-column">
                      <div class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center link"
                        @click.stop="startEventEdit('details')">
                        <i class="pi pi-pencil mr-2" style="font-size: x-large"></i>{{ this.$t("calendar.detail") }}
                      </div>
                      <div class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center link"
                        @click.stop="startEventEdit('budget')">
                        <i class="pi pi-calculator mr-2" style="font-size: x-large"></i>{{ this.$t("custevent.budget")
                        }}
                      </div>
                      <div class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center link"
                        @click.stop="startEventEdit('tasks')">
                        <i class="pi pi-check-square mr-2" style="font-size: x-large"></i>{{ this.$t("custevent.todo")
                        }}
                      </div>
                      <div class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center link"
                        @click.stop="startEventEdit('guests')">
                        <i class="pi pi-list mr-2" style="font-size: x-large"></i>{{ this.$t("custevent.guests") }}
                      </div>
                      <div class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center link"
                        @click.stop="startEventEdit('registries')">
                        <i class="pi pi-gift mr-2" style="font-size: x-large"></i>{{ this.$t("custevent.registries") }}
                      </div>
                      <div class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center link"
                        @click.stop="startEventEdit('share')">
                        <i class="pi pi-share-alt mr-2" style="font-size: x-large"></i>{{ this.$t("guestlists.share") }}
                      </div>
                      <!-- <div
   v-else
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"

      disabled
    >
      <i class="pi pi-share-alt mr-2" style="font-size: x-large"></i
      >{{ this.$t("guestlists.share") }}
    </div>  -->

                    </div>
                  </OverlayPanel>

                </div>

              </div>
              <div class="flex justify-content-around mt-3">
                {{ slotProps.data.EventTitle }}
              </div>
            </template>
            <template #content>
              <div>

                <badge severity="primary" size="xlarge">
                  {{ getDaysUntil(slotProps.data.EventDate) }}
                </badge><br>
                <span size="large"><b> {{ $t("custevent.daysaway") }}</b></span>
              </div>
              <div class="flex justify-content-around mt-4">
                <div class="pointer" @click.stop="startEventEdit('budget', slotProps.data.EventId)">

                  <i class="pi pi-dollar" style="color:green;font-size: 1rem"></i>
                  <span class="text-xl">{{ slotProps.data.BudgetTotal.toLocaleString('en', {useGrouping:true}) }}</span>
                  <br> <strong><span>{{ $t("custevent.ebudget") }}</span></strong>
                </div>
                <div class="pointer" @click.stop="startEventEdit('tasks', slotProps.data.EventId)">
                  <i class="pi pi-check-square" style="color: red;font-size: 1rem"></i>
                  <span class="text-xl">{{ slotProps.data.TotalTasksCompleted + '/' + slotProps.data.TotalTasks
                    }}</span>
                  <br> <strong><span size="small">{{ $t("custevent.taskscompleted") }}</span></strong>
                </div>

                <div class="pointer" @click.stop="startEventEdit('guests', slotProps.data.EventId)">
                  <i class="pi pi-users" style="color: blue;font-size: 1rem"></i>
                  <span class="text-xl">{{ slotProps.data.TotalPeopleInvited }}</span>
                  <br> <strong><span size="small">{{ $t("custevent.peopleinvited") }}</span></strong>
                </div>
              </div>

            </template>

            <template v-slot:footer>

              <Chip v-if="slotProps.data.CustId != currentCustomerId" :label="$t('custevent.eventsharedwithyou')"
                class="chip-sm success-chip mt-3" />
              <Chip v-if="
                slotProps.data.CustId == currentCustomerId &&
                slotProps.data.CustSharedEvents.length > 0
              " :label="$t('custevent.eventshared')" class="chip-sm success-chip mt-3" />
            </template>
          </Card>
        </div>
      </template>
    </DataView>
    <!-- create new event dialog -->
    <Dialog v-model:visible="creatingCustEvent" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true" maximizable>
      <template #header>
        <label> {{ $t("custevent.newevent") }}</label>
      </template>
      <div class="p-fluid formgrid grid">
        <div class="field col-12">
          <label for="name">{{ $t("searchresults.event") }} <span class="required-star">*</span></label>
          <InputText id="name" type="text" v-model="selectedEvent.EventTitle" />
        </div>

        <div class="field col-12">
          <label for="eventtypes">{{ $t("custevent.eventtype") }}</label>
          <Dropdown v-model="selectedEvent.EventType" :options="eventTypes" :placeholder="$t('services.selectone')"
            optionValue="EventTypeLabel">
            <template #value="slotProps">
              {{ getEventType(slotProps.value) }}
            </template>
            <template #option="slotProps">
              <span>{{ getEventType(slotProps.option.EventTypeLabel) }}</span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12">
          <label for="eventdate">{{ $t("calendar.date") }}</label>
          <Calendar id="selectedEvent.EventDate" v-model="selectedEvent.EventDate" :showIcon="true" />
        </div>
        <div class="field col-6">
          <label for="eventstarttime">{{ $t("calendar.start") }}</label>
          <Calendar id="newStartTime" v-model="selectedEvent.EventStartTime" :timeOnly="true" hourFormat="12"
            :stepMinute="30" :manualInput="true" />
        </div>
        <div class="field col-6">
          <label for="eventendtime">{{ $t("calendar.end") }}</label>
          <Calendar id="newEndTime" v-model="selectedEvent.EventEndTime" :timeOnly="true" hourFormat="12"
            :stepMinute="30" :manualInput="true" />
        </div>
        <div class="field col-6">
          <label for="city">{{ $t("profile.city") }}: </label>
          <InputText id="city" type="text" v-model="selectedEvent.EventCity" />
        </div>
        <div class="field col-6">
          <label for="state">{{ $t("profile.state") }}: </label>

          <Dropdown :options="compStates" optionLabel="code" optionValue="code" id="state"
            v-model="selectedEvent.EventState" />
        </div>

        <div class="field col-6">
          <label>{{ $t("custevent.adults") }} </label>
          <InputNumber v-model="selectedEvent.TotalAdultsEst" />
        </div>
        <div class="field col-6">
          <label>{{ $t("custevent.kids") }} </label>
          <InputNumber v-model="selectedEvent.TotalKidsEst" />
        </div>
      </div>
      <div class="flex justify-content-around mt-3">
        <Button :label="$t('profile.cancel')" icon="pi pi-times" @click.stop="
          setCustomerEvents();
        creatingCustEvent = false;
        " class="p-button-secondary" />
        <Button v-if="saving == false" :label="$t('profile.save')" icon="pi pi-check"
          @click.stop="newCustEventCreate()" />

        <Button v-else :label="$t('profile.saving')" icon="pi pi-spin pi-spinner " disabled />
      </div>
    </Dialog>


    <!-- EDIT EVENT DIALOG -->
    <Dialog v-model:visible="editingEvent" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true" maximizable>
      <template #header>
        <label> {{ $t("custevent.editevent") }}</label>
      </template>

      <div class="p-fluid formgrid grid">
        <div v-if="selectedEvent.CustId != currentCustomerId" class="field col-12">
          <label>{{ $t("custevent.eventowner") }}: </label>
          {{ selectedEvent.Cust.CustFirstName }}
          {{ selectedEvent.Cust.CustLastName }} ({{
            selectedEvent.Cust.CustEmail
          }})
        </div>
        <div class="field col-12">
          <label for="name">{{ $t("searchresults.event") }} <span class="required-star">*</span></label>
          <InputText id="name" type="text" v-model="selectedEvent.EventTitle" />
        </div>
        <div class="field col-12">
          <label for="eventtypes">{{ $t("custevent.eventtype") }}</label>
          <Dropdown v-model="selectedEvent.EventType" :options="eventTypes" :placeholder="$t('services.selectone')"
            optionValue="EventTypeLabel">
            <template #value="slotProps">
              <span v-if="slotProps.value == null">
                {{ slotProps.placeholder }}
              </span>
              <span v-else>{{ getEventType(slotProps.value) }}</span>
            </template>
            <template #option="slotProps">
              <span>{{ getEventType(slotProps.option.EventTypeLabel) }}</span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12">
          <label for="eventdate">{{ $t("calendar.date") }}</label>
          <Calendar id="eventdate" v-model="selectedEvent.displayDate" :showIcon="true" />
        </div>
        <div class="field col-6">
          <label for="eventstarttime">{{ $t("calendar.start") }}</label>
          <Calendar id="newStartTime" v-model="selectedEvent.displayStartTime" :timeOnly="true" hourFormat="12"
            :stepMinute="30" :manualInput="true" />
        </div>
        <div class="field col-6">
          <label for="eventendtime">{{ $t("calendar.end") }}</label>
          <Calendar id="newEndTime" v-model="selectedEvent.displayEndTime" :timeOnly="true" hourFormat="12"
            :stepMinute="30" :manualInput="true" />
        </div>
        <div class="field col-6">
          <label for="city">{{ $t("profile.city") }}: </label>
          <InputText id="city" type="text" v-model="selectedEvent.EventCity" />
        </div>
        <div class="field col-6">
          <label for="state">{{ $t("profile.state") }}: </label>

          <Dropdown :options="compStates" optionLabel="code" optionValue="code" id="state"
            v-model="selectedEvent.EventState" />
        </div>

        <div class="field col-6">
          <label>{{ $t("custevent.adults") }} </label>
          <InputNumber v-model="selectedEvent.TotalAdultsEst" />
        </div>
        <div class="field col-6">
          <label>{{ $t("custevent.kids") }} </label>
          <InputNumber v-model="selectedEvent.TotalKidsEst" />
        </div>
      </div>
      <div class="flex justify-content-around mt-3">
        <Button :label="$t('profile.cancel')" icon="pi pi-times" @click.stop="
          setCustomerEvents();
        editingEvent = false;
        " class="p-button-secondary" />
        <Button v-if="!savingEvent" :label="$t('profile.save')" icon="pi pi-check"
          @click.stop="editingSelectedEvent()" />
        <Button v-else :label="$t('profile.saving')" icon="pi pi-spin pi-spinner " disabled />
      </div>
    </Dialog>

    <!-- Budget Dialog -->
    <Dialog v-model:visible="showEventBudget" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '75vw' }" :modal="true" maximizable>
      <template #header>
        <label>{{ $t("custevent.ebudget") }}</label>
      </template>
      <div class="mb-2 text-center">
        {{ $t("custevent.expenses") }}
      </div>
      <div class="flex-grow-1">
        <div class="flex flex-wrap justify-content-center">
          <div class="m-1 text-center">
            <Badge size="large" :value="'$' + selectedEvent.CustEventServices.reduce((total, service) => {
              return total + service.ServiceBudget;
            }, 0)"></Badge>
            <br />
            <b><small>{{ $t("custevent.estimatetotal") }}
              </small></b>
          </div>
          <div class="m-1 text-center">
            <Badge size="large" :value="'$' + selectedEvent.CustEventServices.reduce((total, service) => {
              return total + service.ServiceTotal;
            }, 0)"></Badge><br />
            <b><small>{{ $t("custevent.actual") }}
              </small>
            </b>
          </div>
          <div class="m-1 text-center">
            <Badge size="large" :value="'$' + selectedEvent.CustEventServices.reduce((total, service) => {
              return total + service.ServiceTotalPaid;
            }, 0)"></Badge><br />
            <b><small>{{ $t("custreserv.custre") }}
              </small>
            </b>
          </div>
          <div class="m-1 text-center">
            <Badge size="large" :value="'$' + selectedEvent.CustEventServices.reduce((total, service) => {
              return total + service.ServiceTotalOwed;
            }, 0)"></Badge><br />
            <b><small>{{ $t("custevent.left") }}

              </small>
            </b>
          </div>
        </div>
      </div>
      <DataView :value="selectedEvent.CustEventServices" layout="list" class="">
        <template #empty>
          {{ $t("custevent.noitemadded") }}
        </template>
        <template #list="slotProps">
          <div class="col-12 md:col-6 lg:col-4 p-1">
            <div class="card m-1" style="background-color:#ffffff " >
          
                <div class="flex justify-content-between align-content-center mb-1">
                  <div style="font-size:x-large"><b>{{ slotProps.data.ServiceTitle }}</b></div>
                <div>
                  <Button v-if="slotProps.data.ServiceId > 0" class="p-button-text p-button-rounded " 
                    icon="pi pi-trash"  
                    rounded aria-label="Delete" @click="deleteBudgetItem(slotProps.data.ServiceId)"/>
          
                  <Button icon="pi pi-pencil" class="p-button-rounded p-button-text "
                    @click.stop="editBudgetItem(slotProps.data)" />
                </div>
                </div>
                <div class="flex flex-wrap justify-content-between" style="text-align: left">
                  <div class="m-1 text-center">
                    ${{ slotProps.data.ServiceBudget }}
                    <br />
                    <small>{{ $t('custevent.estimate') }}
                    </small>
                  </div>
                  <div class="m-1 text-center">
                    ${{ slotProps.data.ServiceTotal }}
                    <br />
                    <small>{{ $t('custevent.actual') }}
                    </small>
                  </div>
                  <div class="m-1 text-center">
                    ${{ slotProps.data.ServiceTotalPaid }}
                    <br />
                    <small>{{ $t('custreserv.custre') }}
                    </small>
                  </div>
                  <div class="m-1 text-center">
                    ${{ slotProps.data.ServiceTotalOwed }}
                    <br />
                    <small>{{ $t('custevent.left') }}
                    </small>
                  </div>
                </div>
              
            </div>
          </div>
        </template>
      </DataView>

      <!-- <DataTable
        :value="selectedEvent.CustEventServices"
        v-model:editingRows="editingRows"
        :size="'small'"
        responsiveLayout="scroll"
        :resizableColumns="true"
        columnResizeMode="fit"
        editMode="row"
        dataKey="ServiceId"
        scrollable
        scrollHeight="50vh"
      >
      <template #empty> {{ $t("custevent.noitemadded") }} </template>
        <Column field="ServiceTitle" :header="$t('calendar.title')" frozen alignFrozen="left" style="width: 50%" :sortable="true">

          <template #body="slotProps">
            <Button v-if="slotProps.data.ServiceId > 0" class="p-button-text" icon="pi pi-trash" size="small"   rounded aria-label="Delete" @click="deleteBudgetItem(slotProps.data.ServiceId)"/>
          <Button icon="pi pi-pencil" size="small" class="p-button-text"   rounded aria-label="Submit" @click="editBudgetItem(slotProps.data)"/>
        {{slotProps.data.ServiceTitle}}
          </template>
  </Column>
  
        <Column field="ServiceBudget" :header="$t('custevent.budget')"  style="width: 5%" :sortable="true">
</Column>
        <Column field="ServiceTotal" :header="$t('custevent.actual')"  style="width: 5%" :sortable="true">
   </Column>
        <Column field="ServiceTotalPaid" :header="$t('custreserv.custre')"  style="width: 5%" :sortable="true">
    </Column>
        <Column field="ServiceTotalOwed" :header="$t('custevent.left')"  style="width: 5%" :sortable="true">
       </Column>
              <Column field="ServiceCategory" :header="$t('custevent.category')"  style="width: 10%" :sortable="true">
        </Column> 
        <Column
          frozen alignFrozen="right"
          style="width: 5%; "
          bodyStyle="text-align:center"
        >
        <template #body="slotProps">
    
      </Column> 
      </DataTable> -->

      <template #footer>
        <div class="flex justify-content-around mt-3">
          <Button icon="pi pi-plus ml" class="p-button-success" size="small" :label="$t('custevent.newitem')"
            @click.stop="newBudgetItem()" iconPos="top"/>
          <Button :label="$t('profile.cancel')" icon="pi pi-times"
            @click.stop="setCustomerEvents(); showEventBudget = false" class="p-button-secondary" size="small" iconPos="top"/>
          <Button v-if="savingEvent == false" :label="$t('profile.save')" icon="pi pi-check"
            @click.stop="editingSelectedEvent()" size="small" iconPos="top"/>
          <Button v-else :label="$t('profile.saving')" icon="pi pi-spin pi-spinner " size="small" disabled iconPos="top"/>
        </div>
      </template>
    </Dialog>

    <!-- Registries Dialog -->
    <Dialog v-model:visible="showEventRegistries" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true" maximizable>
      <template #header>
        <label>{{ $t("custevent.gift") }}</label>
      </template>
      <div class="mb-2">
        {{ $t("custevent.saveregistries") }}
      </div>

      <template v-for="(reg, index) in selectedEvent.CustEventRegistries" :key="index">
        <Card class="mb-2">
          <template #content>
            <div class="p-fluid formgrid grid">
              <div class="field col-10">
                <span class="p-input-icon-left">
                  <i class="pi pi-gift" />
                  <InputText :id="'partyName' + index" type="text" v-model="reg.RegistryStore"
                    :placeholder="$t('custevent.store')" />
                </span>
              </div>

              <div class="field col-2">
                <Button icon="pi pi-trash ml" class="p-button-danger p-button-sm" @click.stop="deleteRegistry(index)" />
              </div>
              <div class="field col-10">
                <span class="p-input-icon-left">
                  <i class="pi pi-link" />
                  <InputText :id="'partyName' + index" type="text" v-model="reg.RegistryUrl"
                    :placeholder="$t('custevent.link')" />
                </span>
              </div>
              <div class="field col-2">
                <Button icon="pi pi-copy" class="p-button-info p-button-sm"
                  @click.stop="copyTextToClipboard(reg.RegistryUrl)" />
              </div>
            </div>
          </template>
        </Card>
      </template>
      <div class="field col-2">
       
      </div>

      <template #footer>
        <div class="flex justify-content-around mt-3">
          <Button icon="pi pi-plus ml" :label="$t('custevent.new')" class="p-button-success" @click.stop="addRegistry()" iconPos="top"/>
          <Button :label="$t('profile.cancel')" icon="pi pi-times" iconPos="top"
            @click.stop="setCustomerEvents(); showEventRegistries = false" class="p-button-secondary"  />
          <Button v-if="savingEvent == false" :label="$t('profile.save')" icon="pi pi-check" iconPos="top"
            @click.stop="editingSelectedEvent()" />
          <Button v-else :label="$t('profile.save')" icon="pi pi-spin pi-spinner " disabled iconPos="top"/>
        </div>
      </template>
    </Dialog>

    <!-- Todos Dialog -->
    <Dialog v-model:visible="showEventTodo" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true" maximizable>
      <template #header>
        <label>{{ $t("custevent.event") }} </label>
      </template>
      <div class="mb-2 text-center">
        {{ $t("custevent.upcoming") }}
      </div>
  
      <div class="flex-grow-1">
        <div class="flex flex-wrap justify-content-center">
          <div class="m-1 text-center pointer" @click.stop="showAllTodos=true">
            <Badge size="large" :value="selectedEvent.CustEventTodos.length"></Badge>
            <br />
            <b><small>
                {{ $t("custreserv.custtotal") }}
              </small></b>
          </div>
          <div class="m-1 text-center pointer" @click.stop="showAllTodos=false; showTodos=true">
            <Badge size="large" :value="selectedEvent.CustEventTodos.filter((todo) => todo.TodoCompleted == true).length
              " ></Badge><br />
            <b><small>
                {{ $t("payments.completed") }}
              </small>
            </b>
          </div>
          <div class="m-1 text-center pointer" @click.stop="showAllTodos=false; showTodos=false">
            <Badge size="large" :value="selectedEvent.CustEventTodos.filter((todo) => todo.TodoCompleted == false).length
              "></Badge><br />
            <b><small>
                {{ $t("custevent.pending") }}
              </small>
            </b>
          </div>
        </div>
      </div>
<div>  <b> &nbsp;{{ $t("custevent.showing") }}:</b>
  <span v-if="showTodos==false && showAllTodos == false">  {{ $t("custevent.pending") }}</span>
  <span v-else-if="showTodos==true && showAllTodos == false">  {{ $t("payments.completed") }}</span>
  <span v-else-if="showAllTodos == true">  {{ $t("custreserv.custtotal") }}</span>
  </div>
      <DataView :value="selectedEvent.CustEventTodos" layout="list" class="">
        <template #empty>
          {{ $t("custevent.noitemadded") }}
        </template>
        <template #list="slotProps">
          <div v-show="slotProps.data.TodoCompleted ==  showTodos || showAllTodos == true" class="col-12 p-1">
            <div  class="card m-1" style="background-color:#ffffff " >
           
                <div class="flex justify-content-between align-content-center mb-1">
                  <div style="font-size: x-large">
                  <b>{{ slotProps.data.TodoTitle }} </b>
                    </div>
                <div class="flex">
                  <Button v-if="slotProps.data.TodoId > 0" class="p-button-text" icon="pi pi-trash" size="small" text rounded
              aria-label="Delete" @click="deleteTodo(slotProps.data.TodoId)" />
            <Button icon="pi pi-pencil" class="p-button-text" size="small" text rounded aria-label="Edit"
              @click="editTodo(slotProps.data)" />

                </div>    </div>
               
                <div class="flex  justify-content-around" style="text-align: left">
                  <div class="m-1 text-center">
                    <small>{{ $t('custevent.start') }}
                    <br />
                   {{ $formatDateTime(slotProps.data.TodoStartDate) }}
                    </small>
                  </div>
                  <div class="m-1 text-center">
                    <small>  {{ $t('custevent.due') }}
                    <br />
                  {{ $formatDateTime(slotProps.data.TodoDueDate) }}
                    </small>
                  </div>
              
                </div>
                <div class="flex  justify-content-center mt-1">
                <SelectButton v-model="slotProps.data.TodoCompleted" :options="completedOptions" optionLabel="label"
                optionValue="value" aria-labelledby="basic" style="font-size: small;"/>
                </div>
            </div>
            </div>
          
        </template>
      </DataView>

     <!-- <DataTable :value="selectedEvent.CustEventTodos" responsiveLayout="scroll" :resizableColumns="true"
        columnResizeMode="fit" editMode="row" dataKey="TodoId" scrollable scrollHeight="50vh">
        <template #empty>{{ $t("custevent.noitemadded") }} </template>
        <Column field="TodoTitle" :header="$t('calendar.title')" frozen alignFrozen="left" style="width: 20%">
          <template #body="slotProps">
            <Button v-if="slotProps.data.TodoId > 0" class="p-button-text" icon="pi pi-trash" size="small" text rounded
              aria-label="Delete" @click="deleteTodo(slotProps.data.TodoId)" />
            <Button icon="pi pi-pencil" class="p-button-text" size="small" text rounded aria-label="Edit"
              @click="editTodo(slotProps.data)" />

            <i v-if="slotProps.data.TodoCompleted == true" class="pi pi-check-circle" style="color: green"></i>
            <i v-else class="pi pi-circle" style="color: red"></i>
            {{ slotProps.data.TodoTitle }}
          </template>
        </Column>

        <Column field="TodoCompleted" :header="$t('payments.completed')" style="width: 10%" :sortable="true">
          <template #body="slotProps">
            <i v-if="slotProps.data.TodoCompleted==true" class="pi pi-check-circle" style="color: green"></i>
             <i v-else class="pi pi-circle" style="color: red"></i>
          </template>
       </Column> 
        <Column field="TodoStartDate" :header="$t('custevent.start')" style="width: 10%" :sortable="true">
          <template #body="slotProps">
            {{ $formatDateTime(slotProps.data.TodoStartDate) }}
            {{ slotProps.data.TodoStartDate }} 
          </template>
        </Column>
        <Column field="TodoDueDate" :header="$t('custevent.due')" style="width: 10%" :sortable="true">
          <template #body="slotProps">
            {{ $formatDateTime(slotProps.data.TodoDueDate) }}
           {{slotProps.data.TodoDueDate}}
          </template>
        </Column>
      <Column
          frozen alignFrozen="right"
          style="width: 5%; "
          bodyStyle="text-align:right"
        >
     
      
      </Column> 
      </DataTable>-->
      <template #footer>
        <div class="flex justify-content-around mt-3">

          <Button icon="pi pi-plus ml" class="p-button-success" size="small" :label="$t('custevent.new')"
            @click.stop="newTodoItem()" iconPos="top"/>
          <Button :label="$t('profile.cancel')" icon="pi pi-times" @click.stop="setCustomerEvents(); showEventTodo = false"
            class="p-button-secondary" size="small" iconPos="top"/>
          <Button v-if="savingEvent == false" :label="$t('profile.save')" icon="pi pi-check"
            @click.stop="editingSelectedEvent()" size="small" iconPos="top" />
          <Button v-else :label="$t('profile.save')" icon="pi pi-spin pi-spinner " size="small" disabled iconPos="top"/>
        </div>
      </template>
    </Dialog>


    <!-- EDIT GUEST LIST  DIALOG -->
    <Dialog v-model:visible="editingList" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true" maximizable>
      <template #header>
        <label> {{ $t("custevent.invitees") }}</label>
      </template>

      <div class="mb-2">
        {{ $t("custevent.saveguestlist") }}
      </div>

      <div class="p-fluid formgrid grid">
        <!-- <div
          v-if="selectedEvent.CustId != currentCustomerId"
          class="field col-12"
        >
          <label>{{ $t("guestlists.listowner") }}: </label>
          {{ selectedEvent.Cust.CustFirstName }}
          {{ selectedEvent.Cust.CustLastName }} ({{
            selectedEvent.Cust.CustEmail
          }})
        </div>         -->
        <div v-if="selectedEvent.List.GuestParties.length == 1" class="field col-12">
          <Panel :header="$t('custevent.copy')">
            <div class="p-inputgroup flex-1">
              <Dropdown v-model="selectedList" :options="guestLists" :placeholder="$t('services.selectone')"
                optionValue="ListId" optionLabel="ListName">
              </Dropdown>

              <Button v-if="copyingGuests == false" icon="pi pi-check" label="Import"
                @click.stop="importGuests(selectedList)" size="small" />
              <Button v-else icon="pi pi-spin pi-spinner" label="Importing" disabled size="small" />
            </div>

          </Panel>


        </div>
        <div class="col-6 text-center">
          <label>{{ $t("guestlists.listparty") }}
            <span class="required-star">*</span></label>
        </div>

        <div class="col-2 text-center">
          <label>{{ $t("guestlists.listadults") }} </label>
        </div>
        <div class="col-2 text-center">
          <label>{{ $t("guestlists.listchildren") }} </label>
        </div>
        <template v-for="(party, index) in this.selectedEvent.List.GuestParties" :key="index">
          <div class="field col-6">
            <InputText :id="'partyName' + index" type="text" v-model="party.PartyName" />
            <!-- <InputText
              :id="'partyName' + index"
              type="text"
              v-model="party.PartyName"
              required="[a-zA-Z]"
              
            /> -->
          </div>

          <div class="field col-2 text-center">
            <InputNumber v-model="party.PartyTotalAdults" />
          </div>
          <div class="field col-2 text-center">
            <InputNumber v-model="party.PartyTotalKids" />
          </div>
          <div class="field col-2 text-center">
            <Button icon="pi pi-trash" class="p-button-danger p-button-sm p-button-text"
              @click.stop="deletePartyFromSelected(index)" />
          </div>
        </template>

        <div class="field col-2">
          <Button icon="pi pi-plus ml" class="p-button-success p-button-sm" @click.stop="addPartyToSelected()" />
        </div>
      </div>

      <template #footer>
        <div class="grid">
          <div class="col-12 text-center">
            {{ $t("custreserv.custtotal") }}
            <hr />
          </div>
          <div class="col-3 text-center">
            {{ $t("guestlists.listtotalparties") }} <br />
            <Badge :value="selectedEvent.List.GuestParties.length" severity="warning"></Badge>
          </div>
          <div class="col-3 text-center">
            {{ $t("guestlists.listtotalpeople") }}<br />
            <Badge :value="selectedEvent.List.GuestParties.reduce((total, party) => {
              return total + party.PartyTotalAdults + party.PartyTotalKids;
            }, 0)
              " severity="info"></Badge>
          </div>
          <div class="col-3 text-center">
            {{ $t("guestlists.listadults") }}<br />
            <Badge :value="selectedEvent.List.GuestParties.reduce((total, party) => {
              return total + party.PartyTotalAdults;
            }, 0)
              " severity="info"></Badge>
          </div>
          <div class="col-3 text-center">
            {{ $t("guestlists.listchildren") }}<br />
            <Badge :value="selectedEvent.List.GuestParties.reduce((total, party) => {
              return total + party.PartyTotalKids;
            }, 0)
              " severity="warning"></Badge>
          </div>

        </div>
        <div class="flex justify-content-around mt-3">
          <Button :label="$t('profile.cancel')" icon="pi pi-times" @click.stop="
            setCustomerEvents();
          editingList = false;
          " class="p-button-secondary" size="small" />
          <Button v-if="!savingEvent" :label="$t('profile.save')" icon="pi pi-check"
            @click.stop="editingSelectedEvent()" size="small" />
          <Button v-else :label="$t('profile.saving')" icon="pi pi-spin pi-spinner " disabled size="small" />
        </div>
      </template>
    </Dialog>
  </div>

  <!-- SHARE DIALOG -->
  <Dialog v-model:visible="showShareEvent" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '50vw' }" :modal="true" @hide="resetSelectedEvent">
    <template #header>
      <label>{{ $t("custevent.shareevent") }} </label>
    </template>
    <div class="p-fluid formgrid grid">
      <div class="field col-12" v-if="selectedEvent.CustId == currentCustomerId">
        <label>{{ $t("custevent.shareeventdesc") }}</label>
        <div class="p-inputgroup flex-1">
          <InputText type="text" v-model="share.EmailToShare" :placeholder="$t('custevent.something')" />
          <Button v-if="!sharing" icon="pi pi-share-alt" :label="$t('custevent.share')" @click.stop="shareEvent()" />
          <Button v-else :label="$t('custevent.share')" icon="pi pi-spin pi-spinner " disabled />
        </div>
      </div>
      <div v-else>
        <p>{{ $t("custevent.cannotshare") }}</p>
      </div>
      <div v-if="selectedEvent.CustSharedEvents.length > 0 && selectedEvent.CustId == currentCustomerId"
        class="field col-12">
        <div class="card">
          <label>{{ $t("guestlists.sharedwith") }}</label>
          <hr />
          <template v-for="share in selectedEvent.CustSharedEvents" :key="share.ShareId">
            <div v-if="share.Cust != null" class="field col-12 flex justify-content-between">
              <div class="vertical-align-middle">
                <span v-if="share.Cust.CustFirstName.length > 0">{{ share.Cust.CustFirstName }}
                  {{ share.Cust.CustLastName }} <br /></span>
                {{ share.Cust.CustEmail }}
              </div>
              <Button icon="pi pi-times" class="ml-2 p-button-text p-button-danger"
                @click.stop="removeShare(share.ShareId)" />
            </div>
            <hr />
          </template>
        </div>
      </div>
    </div>
  </Dialog>



  <!-- create new budget item -->
  <Dialog maximizable v-model:visible="showNewBudgetItem" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '50vw' }" :modal="true">
    <template #header>
      <label>{{ $t("custevent.newbudget") }}</label>
    </template>
    <div class="p-fluid formgrid grid">
      <div class="p-fluid formgrid grid">
        <div class="field col-12">
          <label for="name">{{ $t("event.title") }} <span class="required-star">*</span></label>
          <InputText type="text" v-model="tempBudgetItem.ServiceTitle" />

        </div>

        <div class="field col-12">
          <label>{{ $t("custevent.category") }}<span class="required-star">*</span></label>
          <Dropdown v-model="tempBudgetItem.ServiceCategory" :options="serviceTypes" optionValue="ServTypeTitle"
            :placeholder="$t('services.selectone')">
            <template #value="slotProps">
              <span v-if="slotProps.value == null">
                {{ slotProps.placeholder }}
              </span>
              <span v-else-if="typeof slotProps.value !== 'String'">
                {{ $t($getServiceType(slotProps.value)) }}
              </span>
              <span v-else>
                {{ $t($getServiceType(slotProps.value.ServTypeTitle)) }}
              </span>
            </template>
            <template #option="slotProps">
              <span>{{
                $t($getServiceType(slotProps.option.ServTypeTitle))
              }}</span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-4">
          <label>{{ $t("custevent.estimate") }}</label>
          <InputNumber placeholder="$" v-model="tempBudgetItem.ServiceBudget" mode="currency" currency="USD"
            locale="en-US" />
        </div>
        <div class="field col-4">
          <label>{{ $t("custevent.actual") }}</label>
          <InputNumber placeholder="$" v-model="tempBudgetItem.ServiceTotal" mode="currency" currency="USD"
            locale="en-US" />
        </div>
        <div class="field col-4">
          <label>{{ $t("calendar.totalpaid") }}</label>
          <InputNumber placeholder="$" v-model="tempBudgetItem.ServiceTotalPaid" mode="currency" currency="USD"
            locale="en-US" />
        </div>
        <Message v-if="showNewBudgetItemError" severity="error" :closable="false">
          {{ $t("code.errorsfound") }}</Message>
      </div>
    </div>
    <div class="flex justify-content-around mt-3">
      <Button :label="$t('profile.cancel')" icon="pi pi-times" @click.stop="
       
      showNewBudgetItem = false;
      " class="p-button-secondary" />
      <Button :label="$t('store.add')" icon="pi pi-check" @click.stop="addBudgetItem()" class="p-button-primary" />
    </div>
  </Dialog>
  <!-- edit budget item -->
  <Dialog maximizable v-model:visible="showEditBudgetItem" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '50vw' }" style="z-index: 1130" :modal="true">
    <template #header>
      <label> Edit Budget Item</label>
    </template>
    <div class="p-fluid formgrid grid">
      <div class="p-fluid formgrid grid">
        <div class="field col-12">
          <label>{{ $t("event.title") }} </label>
          <InputText type="text" v-model="tempBudgetItem.ServiceTitle" :placeholder="$t('custevent.budgetitem')" />

        </div>

        <div class="field col-12">
          <label>{{ $t("custevent.category") }}</label>
          <Dropdown v-model="tempBudgetItem.ServiceCategory" :options="serviceTypes" optionValue="ServTypeTitle"
            :placeholder="$t('services.selectone')">
            <template #value="slotProps">
              <span v-if="slotProps.value == null">
                {{ slotProps.placeholder }}
              </span>
              <span v-else-if="typeof slotProps.value !== 'String'">
                {{ $t($getServiceType(slotProps.value)) }}
              </span>
              <span v-else>
                {{ $t($getServiceType(slotProps.value.ServTypeTitle)) }}
              </span>
            </template>
            <template #option="slotProps">
              <span>{{
                $t($getServiceType(slotProps.option.ServTypeTitle))
              }}</span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-4">
          <label>{{ $t("custevent.estimate") }}</label>
          <InputNumber placeholder="$" v-model="tempBudgetItem.ServiceBudget" mode="currency" currency="USD"
            locale="en-US" />
        </div>
        <div class="field col-4">
          <label>{{ $t("custevent.actual") }}</label>
          <InputNumber placeholder="$" v-model="tempBudgetItem.ServiceTotal" mode="currency" currency="USD"
            locale="en-US" />
        </div>
        <div class="field col-4">
          <label>{{ $t("custreserv.custre") }}</label>
          <InputNumber placeholder="$" v-model="tempBudgetItem.ServiceTotalPaid" mode="currency" currency="USD"
            locale="en-US" />
        </div>

      </div>
    </div>
    <div class="flex justify-content-around mt-3">
      <Button :label="$t('profile.cancel')" icon="pi pi-times" @click.stop="
      showEditBudgetItem = false;
      " class="p-button-secondary" />
      <Button :label="$t('menu.done')" icon="pi pi-check"
        @click.stop="showEditBudgetItem = false; tempBudgetItem.ServiceTotalOwed = tempBudgetItem.ServiceTotal - tempBudgetItem.ServiceTotalPaid"
        class="p-button-primary" />
    </div>
  </Dialog>
  <!-- edit todo item -->
  <Dialog maximizable v-model:visible="showEditTodo" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '50vw' }" style="z-index: 1130" :modal="true">
    <template #header>
      <label> Edit Todo</label>
    </template>
    <div class="p-fluid formgrid grid">
      <div class="p-fluid formgrid grid">
        <div class="field col-12">
          <label for="eventdate">Title</label>
          <InputText type="text" v-model="tempTodoItem.TodoTitle" />

        </div>

        <div class="field col-12">
          <label for="eventdate">Notes</label>
          <Textarea class="mt-2" v-model="tempTodoItem.TodoNotes" :autoResize="true" rows="3" cols="30"
            style="width: 100%" />
        </div>
        <div class="field col-12">
          <label for="eventdate">{{ $t("custevent.start") }}</label>
          <Calendar id="todo.EventDate" v-model="tempTodoItem.TodoStartDate" showTime hourFormat="12"
            :showIcon="true" />
        </div>
        <div class="field col-12">
          <label for="eventdate">{{ $t("custevent.due") }}</label>
          <Calendar id="todo.EventDate" v-model="tempTodoItem.TodoDueDate" showTime hourFormat="12" :showIcon="true">
          </Calendar>
        </div>
        <div class="field col-12">
          <label>Completed?</label>
          <SelectButton v-model="tempTodoItem.TodoCompleted" :options="completedOptions" optionLabel="label"
            optionValue="value" aria-labelledby="basic" />
        </div>

      </div>
    </div>
    <div class="flex justify-content-around mt-3">
      <Button :label="$t('profile.cancel')" icon="pi pi-times" @click.stop="
      showEditTodo = false;
      " class="p-button-secondary" />
      <Button :label="$t('menu.done')" icon="pi pi-check" @click.stop="showEditTodo = false;" class="p-button-primary" />
    </div>
  </Dialog>

  <!-- create todo item -->
  <Dialog maximizable v-model:visible="showNewEventTodo" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '50vw' }" style="z-index: 1130" :modal="true">
    <template #header>
      <label> {{ $t("custevent.newtodo") }}</label>
    </template>
    <div class="p-fluid formgrid grid">
      <div class="p-fluid formgrid grid">
        <div class="field col-12">
          <label>{{ $t("calendar.title") }}<span class="required-star">*</span></label>
          <InputText type="text" v-model="tempTodoItem.TodoTitle" />

        </div>

        <div class="field col-12">
          <label>{{ $t("custevent.notes") }}</label>
          <Textarea class="mt-2" v-model="tempTodoItem.TodoNotes" :autoResize="true" rows="3" cols="30"
            style="width: 100%" />
        </div>
        <div class="field col-12">
          <label>{{ $t("custevent.start") }}</label>
          <Calendar id="todo.EventDate" v-model="tempTodoItem.TodoStartDate" showTime hourFormat="12"
            :showIcon="true" />
        </div>
        <div class="field col-12">
          <label>{{ $t("custevent.due") }}</label>
          <Calendar id="todo.EventDate" v-model="tempTodoItem.TodoDueDate" showTime hourFormat="12" :showIcon="true">
          </Calendar>
        </div>
        <div class="field col-12">
          <label>{{ $t("payments.completed") }}?</label>
          <SelectButton v-model="tempTodoItem.TodoCompleted" :options="completedOptions" optionLabel="label"
            optionValue="value" aria-labelledby="basic" />
        </div>

      </div>
      <Message v-if="showNewTodoItemError" severity="error" :closable="false">
        {{ $t("code.errorsfound") }}</Message>
    </div>
    <div class="flex justify-content-around mt-3">
      <Button :label="$t('profile.cancel')" icon="pi pi-times" @click.stop="
        showNewEventTodo = false;
      " class="p-button-secondary" size="small"  />
      <Button :label="$t('store.add')" icon="pi pi-check" @click.stop="addTodo()" class="p-button-primary"
        size="small" />
    </div>
  </Dialog>
</template>

<script>
import CustomerEvent from "../store/Models/CustEvent";
import CustSharedEvent from "../store/Models/CustSharedEvent";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import { mapState } from "vuex";
import CustEvent from "../store/Models/CustEvent";
import EventTypeDef from "../store/Models/EventTypeDef";
import ServiceTypeDef from "../store/Models/ServiceTypeDef";
import GuestList from "../store/Models/GuestList";

import moment from "moment";
let states = require("../data/states.js");

export default {
  name: "CustomerEvents",
  components: { LoadingOverlay },
  data() {
    return {
      showTodos: false,
      showAllTodos: true,
      selectedEventId: 0,
      saving: false,
      editing: false,
      eventTypes: [],
      loading: false,
      selectedList: {},
      showEventRegistries: false,
      editingList: false,
      showEventTodo: false,
      creatingList: false,
      showEventBudget: false,
      showGuestlists: false,
      creatingCustEvent: false,
      editingEvent: false,
      savingEvent: false,
      custEvents: [],
      serviceTypes: [],
      copyingGuests: false,
      guestLists: [],
      showNewEventTodo: false,
      showNewBudgetItem: false,
      showNewBudgetItemError: false,
      showEditBudgetItem: false,
      tempBudgetItem: {},
      tempTodoItem: {},
      showEditTodo: false,
      showNewTodoItemError: false,
      newGuestList: {
        ListId: 0,
        CustId: 0,
        ListName: "",
        ListDesc: "",
        ListTotalParties: 0,
        ListTotalPeople: 0,
        ListTotalAdults: 0,
        ListTotalKids: 0,
        GuestParties: [
          {
            PartyName: "",
            PartyTotalAdults: null,
            PartyTotalKids: null,
          },
        ],
      },
      completedOptions: [
        { label: this.$t("custevent.done"), value: true },
        { label: this.$t("custevent.pending"), value: false },
      ],
      editingRows: [],
      selectedEvent: {
        EventId: 0,
        CustId: 0,
        EventTitle: "",
        EventDate: "",
        EventStartTime: "",
        EventEndTime: "",
        EventType: null,
        EventCity: "",
        EventState: "",
        TotalPeopleEst: 0,
        TotalAdultsEst: 0,
        TotalKidsEst: 0,
        TotalPeopleInvited: 0,
        TotalAdultsInvited: 0,
        TotalKidsInvited: 0,
        TotalPeopleRsvp: 0,
        TotalAdultsRsvp: 0,
        TotalKidsRsvp: 0,
        RsvpOpenDateTime: "",
        RsvpCloseDateTime: "",
        TotalTasksCompleted: 0,
        TotalTasksPending: 0,
        TotalTasks: 0,
        BudgetTotal: 0,
        BudgetSpent: 0,
        BudgetLeft: 0,
        RegistryVisible: false,
      
        CustEventRegistries: [
          {
            EventId: 0,
            RegistryStore: "",
            RegistryUrl: "",
          },
        ],
      },

      webHost: process.env.VUE_APP_MYRESERVS_URL,

      errors: [],
      share: {
        EventId: 0,
        EmailToShare: "",
        ShareGuestList: false,
      },
      showShareEvent: false,
      sharing: false,
    };
  },

  async mounted() {
    this.$log.debug("customer events mounted");
    this.$log.debug(this.currentCustomerId);

    if (this.currentCustomerId > 0) {
      await this.setCustomerEvents();
      await this.setCustomerGuestLists();
      //console.log(GuestList);
    }

    // if (this.$route.params.EventId > 0) { todo
    //   this.onLoadWithCustEvent();
    // }

    this.eventTypes = await EventTypeDef.fetchAll();
    this.serviceTypes = await ServiceTypeDef.fetchAll();
  },
  methods: {
   
    toggle(event) {
      //console.log(event)
      //console.log(event.srcElement.id)
      this.selectedEventId = event.srcElement.id.substring(1);
      this.$refs.op.toggle(event);
    },
    getDaysUntil(date) {
      var daysAway = moment(date).diff(moment(), "days");
      if (daysAway <= 0) { daysAway = 0; }

      return daysAway;
    },
    onRowEditSave(event) {
      let { newData, index } = event;

      this.products[index] = newData;
    },
    copyTextToClipboard(copyText) {
      navigator.clipboard.writeText(copyText);
      this.$toast.add({
        severity: "info",
        summary: "Copied to Clipboard!",
        life: 3000,
      });
    },
    async importGuests(listId) {
      this.$log.debug(this.selectedList);
      this.$log.debug(listId);
      this.$log.debug(this.guestLists);

      this.copyingGuests = true;
      await GuestList.importGuests(listId).then(async (response) => {
        this.$log.debug(response);
        this.copyingGuests = false;

        if (response != null) {
          //iterate through reponse and add to guestlist
          //console.log(response);

          response.forEach((party) => {
            party.PartyId = 0;
            //console.log(this.selectedEvent.List.GuestParties);
            this.selectedEvent.List.GuestParties.push(party);
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("guestlists.createerror"),
            life: 3000,
          });
        }
        this.$log.debug("errors " + this.errors.length);
        this.$log.debug(this.errors);
        this.copyingGuests = false;
        return false;
      });
    },
    resetSelectedList() {
      this.selectedList = GuestList.query()
        .withAllRecursive()
        .find(this.selectedList.ListId);
    },
    addRegistry() {
      this.selectedEvent.CustEventRegistries.push({
        EventId: this.selectedEvent.EventId,
        RegistryStore: "",
        RegistryUrl: "",
      });
    },
    addTodo() {
      var errors = 0;
      this.showNewTodoItemError = false;
      if (!this.tempTodoItem.TodoTitle.length > 0) {
        errors++;
      }
      if (errors == 0) {
        this.selectedEvent.CustEventTodos.push(this.tempTodoItem);
        this.showNewEventTodo = false;
      }
      else {
        this.showNewTodoItemError = true;
      }
    },
    newTodoItem() {
      this.tempTodoItem = {
        EventId: this.selectedEvent.EventId,
        TodoTitle: "",
        TodoNotes: "",
        TodoStartDate: new Date(),
        TodoDueDate: new Date(),
        TodoCompleted: false
      }
      this.showNewEventTodo = true;
    },
    async setCustomerGuestLists() {
      this.loading = true;
      await GuestList.fetchByCustId(this.currentCustomerId).then((result) => {
        this.$log.debug(result);
        this.guestLists = result;

        this.$log.debug(this.selectedList);
        this.$log.debug(this.selectedList.ListId);
        if (this.selectedList != null && this.selectedList.ListId != null) {
          this.selectedList = GuestList.query()
            .withAllRecursive()
            .find(this.selectedList.ListId);
        }
        this.loading = false;
      });
    },
    addBudgetItem() {
      var errors = 0;
      this.showNewBudgetItemError = false;
      if (!this.tempBudgetItem.ServiceTitle.length > 0) {
        errors++;
      }
      if (this.tempBudgetItem.ServiceCategory == null) {
        errors++;
      }
      if (errors == 0) {
        this.tempBudgetItem.ServiceTotalOwed = this.tempBudgetItem.ServiceTotal - this.tempBudgetItem.ServiceTotalPaid;
        this.selectedEvent.CustEventServices.push(this.tempBudgetItem);
        this.showNewBudgetItem = false;
      }
      else {
        this.showNewBudgetItemError = true;
      }

    },
    newBudgetItem() {
      this.tempBudgetItem = {
        EventId: this.selectedEvent.EventId,
        ServiceCategory: null,
        ServiceTitle: "",
        ServiceBudget: 0,
        ServiceTotal: 0,
        ServiceTotalPaid: 0,
        ServiceTotalOwed: 0,
        VendorName: "",
        VendorAddress: "",
        VendorPhone: "",
      }
      this.showNewBudgetItem = true;

    },
    editBudgetItem(budgetItem) {
      this.tempBudgetItem = budgetItem;
      //console.log(this.tempBudgetItem)
      this.showEditBudgetItem = true;

    },

    editTodo(todo) {
      this.tempTodoItem = todo;
      //console.log(this.tempTodoItem)
      // this.tempTodoItem.TodoStartDate = this.tempTodoItem.TodoStartDate != null  ? new Date(
      //   Date.parse(this.tempTodoItem.TodoStartDate)
      // ): null;
      // this.tempTodoItem.TodoDueDate = this.tempTodoItem.TodoDueDate != null  ? new Date(
      //   Date.parse(this.tempTodoItem.TodoDueDate)
      // ): null;
      this.showEditTodo = true;

    },
    async newGuestListCreate() {
      this.saving = true;
      this.$log.debug("creating guestlist");

      this.errors = [];
      this.newGuestList.CustId = this.currentCustomerId;
      this.$log.debug(this.newGuestList);

      if (!this.newGuestList.ListName.length > 0) {
        document.getElementById("name").classList.add("p-invalid");
        this.errors.push("Valid Name Required");
      }
      this.newGuestList.GuestParties.forEach((party, index) => {
        //console.log(party);
        //console.log(index);
        if (!party.PartyName.length > 0) {
          document
            .getElementById("partyName" + index)
            .classList.add("p-invalid");
          this.errors.push("Valid Name Required");
        }
        party.PartyTotalAdults =
          party.PartyTotalAdults != null ? party.PartyTotalAdults : 0;
        party.PartyTotalKids =
          party.PartyTotalKids != null ? party.PartyTotalKids : 0;
      });
      if (this.errors.length == 0) {
        await GuestList.createGuestList(this.newGuestList).then(
          async (response) => {
            this.$log.debug(response);
            this.saving = false;

            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("guestlists.createsuccess"),
                life: 3000,
              });
              this.creatingList = false;
              this.resetNewGuestList();
              this.setCustomerGuestLists();
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("guestlists.createerror"),
                life: 3000,
              });
            }
            this.$log.debug("errors " + this.errors.length);
            this.$log.debug(this.errors);
            this.saving = false;
            return false;
          }
        );
      } else {
        this.saving = false;
      }
    },
    resetNewGuestList() {
      this.newGuestList = {
        ListId: 0,
        CustId: 0,
        ListName: "",
        ListDesc: "",
        ListTotalParties: 0,
        ListTotalPeople: 0,
        ListTotalAdults: 0,
        ListTotalKids: 0,
        GuestParties: [
          {
            PartyName: "",
            PartyTotalAdults: null,
            PartyTotalKids: null,
          },
        ],
      };
    },

    addParty() {
      this.newGuestList.GuestParties.push({
        PartyName: "",
        PartyTotalAdults: null,
        PartyTotalKids: null,
        PartyTotalPeople: 0,
      });
    },
    addPartyToSelected() {
      this.selectedEvent.List.GuestParties.push({
        PartyName: "",
        PartyTotalAdults: null,
        PartyTotalKids: null,
        PartyTotalPeople: 0,
      });
    },
    deleteParty(index) {
      this.newGuestList.GuestParties.splice(index, 1);
    },
    deletePartyFromSelected(index) {
      this.selectedEvent.List.GuestParties.splice(index, 1);
    },
    deleteRegistry(index) {
      this.selectedEvent.CustEventRegistries.splice(index, 1);
    },
    deleteTodo(todoId) {
      this.selectedEvent.CustEventTodos.splice(
        this.selectedEvent.CustEventTodos.findIndex((todo) => todo.TodoId == todoId),
        1
      );
    },
    deleteBudgetItem(serviceId) {
      this.selectedEvent.CustEventServices.splice(
        this.selectedEvent.CustEventServices.findIndex((service) => service.ServiceId == serviceId),
        1
      );
    },
    getEventType(eventtype) {
      this.$log.debug(eventtype);
      switch (eventtype) {
        case "Birthday Parties":
          return this.$t("eventtypes.birthdayparties");
        case "Baby Showers":
          return this.$t("eventtypes.babyshower");
        case "Bridal Showers":
          return this.$t("eventtypes.bridalshowers");
        case "Weddings":
          return this.$t("eventtypes.weddings");
        case "Kids Birthday Parties":
          return this.$t("eventtypes.kidsbirthdayparties");
        case "Quinceañeras":
          return this.$t("eventtypes.quinceaneras");
        default:
          return "";
      }
    },
    removeShare(shareId) {
      //console.log(shareId)
      CustSharedEvent.remove(shareId).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          this.setCustomerEvents();
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("guestlists.accessremoved"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("guestlists.accessfailedtoremove"),
            life: 3000,
          });
        }
      });
    },
    async shareEvent() {
      this.sharing = true;
      await CustSharedEvent.share(this.share).then(async (response) => {
        this.$log.debug(response);
        this.sharing = false;
        this.setCustomerEvents();
        if (response == 1) {
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("guestlists.sharesuccess"),
            life: 3000,
          });
          this.resetSharing();
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("guestlists.sharefailed"),
            life: 3000,
          });
        }
      });
    },
    resetSharing() {
      this.share = {
        EventId: 0,
        EmailToShare: "",
        ShareGuestList: false,
      };

      this.sharing = false;
      this.showShareEvent = false;
    },
    deleteCustEvent(EventId) {
      //console.log(EventId);
      this.$confirm.require({
        message: this.$t("custevent.deleteeventdesc"),
        header: this.$t("custevent.deleteevent"),
        icon: "pi pi-exclamation-triangle",
        acceptLabel: this.$t("code.continue"),
        rejectLabel: this.$t("code.cancel"),
        accept: async () => {
          this.loading = true;
          await CustomerEvent.deleteCustEvent(EventId).then((response) => {

            this.$log.debug(response);
            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("custevent.deleteeventsuccess"),
                life: 3000,
              });
              this.setCustomerEvents();
              this.loading = false;
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("custevent.deleteeventerror"),
                life: 3000,
              });
            }

          }); //todo ensure wait
        },
        reject: () => {
          this.$confirm.close();
          this.loading = false;
        },
      });
    },
   dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
},

    startEventEdit(sectionToEdit, eventId) {
      console.log(this.selectedEventId)
      console.log(event)
      if (!this.selectedEventId > 0) {
        this.selectedEventId = eventId;
      }

      this.selectedEvent = this.custEvents.find(x => x.EventId == this.selectedEventId);

      //console.log(this.selectedEvent)

      this.selectedEvent.displayStartTime = this.selectedEvent.EventStartTime != null ? new Date(
        Date.parse("2011-10-10T" + this.selectedEvent.EventStartTime)
      ) : null;
      this.selectedEvent.displayEndTime = this.selectedEvent.EventEndTime != null ? new Date(
        Date.parse("2011-10-10T" + this.selectedEvent.EventEndTime)
      ) : null;
      this.selectedEvent.displayDate = this.selectedEvent.EventDate != null ? new Date(
        this.$formatDateTimeToDate(this.selectedEvent.EventDate)
      ) : null;

      switch (sectionToEdit) {
        case 'details':
          this.editingEvent = true;
          break;
        case 'budget':
          this.showEventBudget = true;
          break;
        case 'tasks':
          this.selectedEvent.CustEventTodos.forEach((todo) => {
            //console.log(todo.TodoStartDate)
            //console.log(todo.TodoDueDate)

            //console.log(todo.TodoStartDate + "Z")
            //console.log(todo.TodoDueDate+"Z")

            var startStr = todo.TodoStartDate + "Z";
            var dueStr = todo.TodoDueDate + "Z";

            //console.log(startStr)
            //console.log(dueStr)

            todo.TodoStartDate = new Date(startStr);
            todo.TodoDueDate = new Date(dueStr);

            //console.log(todo.TodoStartDate)
            //console.log(todo.TodoDueDate)
          });

          this.$log.debug(this.selectedEvent.CustEventTodos);
          this.selectedEvent.CustEventTodos.sort(this.dynamicSort("TodoDueDate"))
          this.showEventTodo = true;
          break;
        case 'guests':
          if (this.selectedEvent.List == null) {
            this.selectedEvent.List = {
              ListId: 0,
              CustId: 0,
              ListName: this.selectedEvent.EventTitle,
              ListDesc: "LIST CREATED FROM EVENT",
              ListTotalParties: 0,
              ListTotalPeople: 0,
              ListTotalAdults: 0,
              ListTotalKids: 0,
              GuestParties: [
                {
                  PartyName: "",
                  PartyTotalAdults: 0,
                  PartyTotalKids: 0,
                  PartyTotalPeople: 0
                },
              ],
            };
          }
          this.editingList = true;

          break;
        case 'registries':
          if (!this.selectedEvent.CustEventRegistries.length > 0) {
            this.selectedEvent.CustEventRegistries.push({
              EventId: this.selectedEvent.EventId,
              RegistryStore: "",
              RegistryUrl: "",
            });
          }
          this.showEventRegistries = true;
          break;
        case 'share':
          this.share.EventId = this.selectedEvent.EventId;
          this.showShareEvent = true;
          break;
        default:
        // code block
      }

    },
    creatingNewCustEvent() {
      this.selectedEvent = {
        EventId: 0,
        CustId: 0,
        EventTitle: "",
        EventDate: new Date(),
        EventStartTime: new Date(2011, 0, 1, 13, 0, 0, 0),
        EventEndTime: new Date(2011, 0, 1, 23, 59, 0, 0),
        EventType: null,
        EventCity: "",
        EventState: "",
        TotalPeopleEst: 0,
        TotalAdultsEst: 0,
        TotalKidsEst: 0,
        TotalPeopleInvited: 0,
        TotalAdultsInvited: 0,
        TotalKidsInvited: 0,
        TotalPeopleRsvp: 0,
        TotalAdultsRsvp: 0,
        TotalKidsRsvp: 0,
        RsvpOpenDateTime: null,
        RsvpCloseDateTime: null,
        TotalTasksCompleted: 0,
        TotalTasksPending: 0,
        TotalTasks: 0,
        BudgetTotal: 0,
        BudgetSpent: 0,
        BudgetLeft: 0,
        RegistryVisible: false,
        CustEventRegistries: [
          {
            EventId: 0,
            RegistryStore: "",
            RegistryUrl: "",
          },
        ],
      };
      (this.creatingCustEvent = true);

      //console.log(this.selectedEvent);
    },

    resetSelectedEvent() {
      this.selectedEvent = CustomerEvent.query()
        .withAllRecursive()
        .find(this.selectedEvent.EventId);

      this.showEventBudget = false;
      this.showEventTodo = false;
      this.editingList = false;
      this.showEventRegistries = false;
    },
    resetNewCustEvent() {
      this.newCustEvent = {
        EventId: 0,
        CustId: 0,
        EventTitle: "",
        EventDate: "",
        EventStartTime: "",
        EventEndTime: "",
        EventType: null,
        EventCity: "",
        EventState: "",
        TotalPeopleEst: 0,
        TotalAdultsEst: 0,
        TotalKidsEst: 0,
        TotalPeopleInvited: 0,
        TotalAdultsInvited: 0,
        TotalKidsInvited: 0,
        TotalPeopleRsvp: 0,
        TotalAdultsRsvp: 0,
        TotalKidsRsvp: 0,
        RsvpOpenDateTime: "",
        RsvpCloseDateTime: "",
        TotalTasksCompleted: 0,
        TotalTasksPending: 0,
        TotalTasks: 0,
        BudgetTotal: 0,
        BudgetSpent: 0,
        BudgetLeft: 0,
        RegistryVisible: false,
      };
    },

    async setCustomerEvents() {
      this.loading = true;
      await CustomerEvent.fetchByCustId(this.currentCustomerId).then(
        (result) => {
          this.$log.debug(result);
          this.custEvents = result;

          this.$log.debug(this.selectedEvent);
          if (
            this.selectedEvent != null &&
            this.selectedEvent.EventId != null
          ) {
            this.selectedEvent = CustomerEvent.query()
              .withAllRecursive()
              .find(this.selectedEvent.EventId);
          }
          this.loading = false;
        }
      );
    },
    async newCustEventCreate() {
      this.saving = true;
      this.$log.debug("creating event");

      this.errors = [];
      this.selectedEvent.CustId = this.currentCustomerId;
      this.$log.debug(this.selectedEvent);

      if (!this.selectedEvent.EventTitle.length > 0) {
        document.getElementById("name").classList.add("p-invalid");
        this.errors.push("Valid Name Required");
      }

      if (this.errors.length == 0) {
        await CustEvent.createCustEvent(this.selectedEvent).then(
          async (response) => {
            this.$log.debug(response);
            this.$log.debug(this.selectedEvent);
            this.saving = false;

            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("custevent.eventcreated"),
                life: 3000,
              });
              this.creatingCustEvent = false;
              this.resetSelectedEvent();
              this.setCustomerEvents();
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("custevent.eventnotcreated"),
                life: 3000,
              });
            }
            this.$log.debug("errors " + this.errors.length);
            this.$log.debug(this.errors);
            this.saving = false;
            return false;
          }
        );
      } else {
        this.saving = false;
      }
    },
    async editingSelectedList() {
      this.editing = true;
      this.$log.debug("creating guestlist");

      this.errors = [];
      this.selectedList.CustId = this.currentCustomerId;
      this.$log.debug(this.selectedList);

      if (!this.selectedList.ListName.length > 0) {
        document.getElementById("name").classList.add("p-invalid");
        this.errors.push("Valid Name Required");
      }
      this.selectedList.GuestParties.forEach((party, index) => {
        //console.log(party);
        //console.log(index);
        if (!party.PartyName.length > 0) {
          document
            .getElementById("partyName" + index)
            .classList.add("p-invalid");
          this.errors.push("Valid Name Required");
        }
        party.PartyTotalAdults =
          party.PartyTotalAdults != null ? party.PartyTotalAdults : 0;
        party.PartyTotalKids =
          party.PartyTotalKids != null ? party.PartyTotalKids : 0;
      });
      if (this.errors.length == 0) {
        await GuestList.updateGuestList(this.selectedList).then(
          async (response) => {
            this.$log.debug(response);
            this.editing = false;

            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("guestlists.edit"),
                life: 3000,
              });
              this.editingList = false;
              this.resetSelectedList();
              this.setCustomerGuestLists();
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("guestlists.noedit"),
                life: 3000,
              });
            }
            this.$log.debug("errors " + this.errors.length);
            this.$log.debug(this.errors);
            this.editing = false;
            return false;
          }
        );
      } else {
        this.editing = false;
      }
    },

    async editingSelectedEvent() {
      this.savingEvent = true;
      this.$log.debug("editing event");

      this.errors = [];
      this.$log.debug(this.selectedEvent);

      if (!this.selectedEvent.EventTitle.length > 0) {
        document.getElementById("name").classList.add("p-invalid");
        this.errors.push("Valid Title Required");
      }

      this.selectedEvent.CustEventRegistries.forEach((reg, index) => {
        if (reg.RegistryStore.length == 0 && reg.RegistryUrl.length == 0) {
          this.selectedEvent.CustEventRegistries.splice(index, 1);
        }
      });

      //Validation for empty guestparties
      if (this.selectedEvent.List != null && this.selectedEvent.List.GuestParties != null && this.selectedEvent.List.GuestParties.length > 0) {
        this.selectedEvent.List.GuestParties.forEach((party) => {
          party.PartyTotalPeople = (!party.PartyTotalPeople > 0) ? 0 : party.PartyTotalPeople;
          party.PartyTotalKids = (!party.PartyTotalKids > 0) ? 0 : party.PartyTotalKids;
          party.PartyTotalAdults = (!party.PartyTotalAdults > 0) ? 0 : party.PartyTotalAdults;
        });
      }


      this.selectedEvent.CustEventTodos.forEach((todo) => {
        //console.log(todo.TodoStartDate)
        //console.log(todo.TodoDueDate)
        todo.TodoStartDate = new Date(todo.TodoStartDate);
        todo.TodoDueDate = new Date(todo.TodoDueDate);
      });

      this.selectedEvent.EventDate = this.selectedEvent.displayDate;
      this.selectedEvent.EventStartTime = this.selectedEvent.displayStartTime;
      this.selectedEvent.EventEndTime = this.selectedEvent.displayEndTime;

      if (this.errors.length == 0) {
        await CustEvent.updateCustEvent(this.selectedEvent).then(
          async (response) => {
            this.$log.debug(response);
            this.savingEvent = false;

            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("custevent.eventsaved"),
                life: 3000,
              });
              this.editingEvent = false;
              this.resetSelectedEvent();
              this.setCustomerEvents();
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("custevent.eventnotsaved"),
                life: 3000,
              });
            }
            this.$log.debug("errors " + this.errors.length);
            this.$log.debug(this.errors);
            this.savingEvent = false;
            return false;
          }
        );
      } else {
        this.savingEvent = false;
      }
    },
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCustomerEvents();
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
      compStates() {
        return states;
      },
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
    selectedTotalAdults() {
      return this.selectedEvent.GuestParties.reduce((total, party) => {
        return total + party.PartyTotalAdults;
      }, 0);
    },
    selectedTotalKids() {
      return this.selectedEvent.GuestParties.reduce((total, party) => {
        return total + party.PartyTotalKids;
      }, 0);
    },
  },
  selectedTotalAdults() {
    return this.selectedList.GuestParties.reduce((total, party) => {
      return total + party.PartyTotalAdults;
    }, 0);
  },
  selectedTotalKids() {
    return this.selectedList.GuestParties.reduce((total, party) => {
      return total + party.PartyTotalKids;
    }, 0);
  },
};
</script>
<style>
.p-datatable-header {
  background-color: white !important;
}

/* 
.p-datatable-scrollable th.p-frozen-column{
  z-index: 1;
}

.p-datatable-scrollable .p-frozen-column {
    position: sticky;
    background: inherit;
} */
</style>
