<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <div v-if="currentBusiness != null && currentBusiness.BusActive == true && currentSubscription != null">
      <div class="flex flex-wrap align-items-center mb-2 mt-3">
        <div class="ml-auto" v-if="!editingSettings">
          <Button
            :label="$t('message.edit')"
            icon="pi pi-pencil"
            iconPos="left"
            @click.stop="editingSettings = true"
            class="mr-2 p-button-sm p-button-raised p-button-primary"
          />
        </div>
      </div>
      <div v-if="!editingSettings">
        <Card style="margin-bottom: 2em; text-align: left">
          <template #content>
            <div>
              <div class="flex justify-content-between">
                <span
                  ><b>{{ $t("custreserv.custsales") }}</b></span
                >
                <Tag
                  severity="info"
                  style="text-align: left; font-size: x- small"
                  >{{ currentBusiness.BusSalesTaxPercent }}%</Tag
                >
              </div>
              <small>{{ $t("message.salestaxdesc") }}</small>
              <hr />
            </div>
            <div>
              <div class="flex justify-content-between">
                <b>{{ $t("message.packages") }} </b>
                <Tag
                  class="mt-2"
                  :severity="getStatusTag(currentBusiness.BusDisplayServices)"
                  style="text-align: left; font-size: x- small"
                  >{{ getStatusText(currentBusiness.BusDisplayServices) }}</Tag
                >
              </div>
              <small>{{ $t("message.packagesdesc") }}</small>
              <hr />
            </div>
            <div>
              <div class="flex justify-content-between">
                <b>{{ $t("message.acceptquotes") }} </b>
                <Tag
                  class="mt-2"
                  :severity="getStatusTag(currentBusiness.BusAcceptQuotes)"
                  style="text-align: left; font-size: x- small"
                  >{{ getStatusText(currentBusiness.BusAcceptQuotes) }}</Tag
                >
              </div>
              <small>{{ $t("message.acceptquotesdesc") }}</small>
              <hr />
            </div>

            <div v-if="currentBusiness.BusAcceptQuotes == true">
              <div class="flex justify-content-between">
                <b>{{ $t("message.quotesvalid") }} </b>
                <Tag
                  v-if="currentBusiness.BusQuoteExpiration"
                  class="mt-2"
                  severity="info"
                  style="text-align: left; font-size: x- small"
                  >{{ currentBusiness.BusQuoteExpiration }} days</Tag
                >
                <Tag
                  v-else
                  class="mt-2"
                  severity="danger"
                  style="text-align: left; font-size: x- small"
                  >Not set</Tag
                >
              </div>
              <small>{{ $t("message.quotesvaliddesc") }}</small>
              <hr />
            </div>
            <div>
              <div class="flex justify-content-between">
                <b>{{ $t("message.acceptpayments") }}</b>
                <Tag
                  class="mt-2"
                  :severity="getStatusTag(currentBusiness.BusAcceptPayment)"
                  style="text-align: left; font-size: x- small"
                  >{{ getStatusText(currentBusiness.BusAcceptPayment) }}</Tag
                >
              </div>
              <small>{{ $t("message.acceptpaymentsdesc") }}</small>
              <!-- <Button
                v-if="
                  providerStatus != null &&
                  providerStatus.Enrolled == true &&
                  providerStatus.ServiceAgreement == true &&
                  providerStatus.AccountAdded == true
                "
                :label="$t('code.continue')"
                class="p-button-success p-button-sm"
                @click.stop="
                  showNewBankAccount = true;
                  errors = [];
                "
              /> -->
              <hr />
              <div>
                <div class="flex flex-wrap justify-content-between">
                  <span
                    ><b>{{ $t("code.payoutaccount") }} </b></span
                  >
                  <Tag
                    v-if="
                      displayAccountSetup && currentBusiness.BusAcceptPayment
                    "
                    severity="error"
                    style="text-align: left; font-size: x- small"
                    >Pending</Tag
                  >
                  <Tag
                    v-if="
                      !displayAccountSetup && currentBusiness.BusAcceptPayment
                    "
                    severity="success"
                    style="text-align: left; font-size: x- small"
                    >Configured</Tag
                  >
                </div>
                <div
                  v-if="displayAccountSetup && currentBusiness.BusAcceptPayment"
                >
                  <Card style="margin-bottom: 2em; text-align: left">
                    <template #content>
                      <!-- <Message severity="warn" :closable="false">
            {{ $t("payments.enable") }}
          </Message> -->

                      <span style="vertical-align: -webkit-baseline-middle"
                        ><i
                          v-if="providerStatus.Enrolled == false"
                          class="pi pi-circle-off"
                          style="fontsize: 1rem"
                        ></i
                        ><i
                          v-if="providerStatus.Enrolled == true"
                          class="pi pi-check-circle"
                          style="fontsize: 1rem"
                        ></i
                        >{{ $t("payments.accept") }}
                        <Button
                          v-if="providerStatus.Enrolled == false"
                          :label="$t('payments.started')"
                          class="p-button-success p-button-sm"
                          @click.stop="showServAgreement = true"
                        />
                        <!-- <Button
                        v-if="providerStatus.Enrolled == true"
                        :label="$t('payments.completed')"
                        disabled="disabled"
                        class="p-button-link p-button-sm"
                      />  --> </span
                      ><br />
                      <span style="vertical-align: -webkit-baseline-middle">
                        <i
                          v-if="providerStatus.ServiceAgreement == false"
                          class="pi pi-circle-off"
                          style="fontsize: 1rem"
                        ></i
                        ><i
                          v-if="providerStatus.ServiceAgreement == true"
                          class="pi pi-check-circle"
                          style="fontsize: 1rem"
                        ></i
                        >{{ $t("payments.fillout") }}
                        <Button
                          v-if="
                            providerStatus.Enrolled == true &&
                            providerStatus.ServiceAgreement == false
                          "
                          :label="$t('code.continue')"
                          class="p-button-success p-button-sm"
                          @click.stop="startEnrollment()"
                        />
                        <!-- <Button
                        v-if="providerStatus.ServiceAgreement == true && providerStatus.DetailsPending == false"
                        :label="$t('payments.completed')"
                        disabled="disabled"
                        class="p-button-link p-button-sm"
                      />  --> </span
                      ><br />
                      <span style="vertical-align: -webkit-baseline-middle">
                        <i
                          v-if="providerStatus.AccountAdded == false"
                          class="pi pi-circle-off"
                          style="fontsize: 1rem"
                        ></i
                        ><i
                          v-else
                          class="pi pi-check-circle"
                          style="fontsize: 1rem"
                        ></i
                        >{{ $t("payments.bank") }}
                        <Button
                          v-if="
                            providerStatus.Enrolled == true &&
                            providerStatus.ServiceAgreement == true &&
                            providerStatus.AccountAdded == false
                          "
                          :label="$t('code.continue')"
                          class="p-button-success p-button-sm"
                          @click.stop="
                            showNewBankAccount = true;
                            errors = [];
                          "
                        />
                        <!-- <Button
                        v-if="providerStatus.ServiceAgreement == true && providerStatus.DetailsPending == false && providerStatus.AccountAdded == true"
                        :label="$t('payments.completed')"
                        disabled
                        class="p-button-link p-button-sm"
                      /> -->
                      </span>
                    </template>
                  </Card>
                </div>
                <small>{{ $t("code.payoutaccountdesc") }} </small>
                <hr />
              </div>
            </div>
            <div>
              <div class="flex justify-content-between">
                <span
                  ><b>{{ $t("message.downrequire") }} </b></span
                >
                <Tag
                  :severity="getStatusTag(currentBusiness.BusDownPmtReq)"
                  style="text-align: left; font-size: x- small"
                  >{{ getStatusText(currentBusiness.BusDownPmtReq) }}</Tag
                >
              </div>
              <small>{{ $t("message.downrequiredesc") }}</small>
              <hr />
            </div>
            <div v-if="currentBusiness.BusDownPmtReq == true">
              <div class="flex justify-content-between">
                <b>{{ $t("message.downamount") }} </b>
                <Tag
                  severity="info"
                  style="text-align: left; font-size: x- small"
                  >${{ currentBusiness.BusDownPmtAmt }}</Tag
                >
              </div>
              <small>{{ $t("message.downamountdesc") }}</small>
              <hr />
            </div>

            <div>
              <div class="flex justify-content-between">
                <b>{{ $t("message.messages") }} </b>
                <Tag
                  class="mt-2"
                  :severity="getStatusTag(currentBusiness.BusAcceptMessages)"
                  style="text-align: left; font-size: x- small"
                  >{{ getStatusText(currentBusiness.BusAcceptMessages) }}</Tag
                >
              </div>
              <small>{{ $t("message.messagesdesc") }}</small>
              <hr />
            </div>
            <div>
              <div class="flex justify-content-between">
                <b>{{ $t("message.calendar") }} </b>
                <Tag
                  class="mt-2"
                  :severity="getStatusTag(currentBusiness.BusDisplayCalendar)"
                  style="text-align: left; font-size: x- small"
                  >{{ getStatusText(currentBusiness.BusDisplayCalendar) }}</Tag
                >
              </div>
              <small>{{ $t("message.calendardesc") }}</small>
              <hr />
            </div>
            <div>
              <div class="flex justify-content-between">
                <b>{{ $t("code.reservterms") }} </b>
                <Tag
                  class="mt-2"
                  :severity="
                    getStatusTag(currentBusiness.BusDisplayReservTerms)
                  "
                  style="text-align: left; font-size: x- small"
                  >{{
                    getStatusText(currentBusiness.BusDisplayReservTerms)
                  }}</Tag
                >
              </div>
              <small>{{ $t("code.reservtermsdesc") }}</small>
              <div v-if="currentBusiness.BusDisplayReservTerms" class="mt-2">
                <Button
                  :label="$t('menu.edit')"
                  class="p-button-primary p-button-sm mr-2"
                  @click.stop="showReservTermsDialog = true"
                />
                <Button
                  :label="$t('code.reservtermshist')"
                  class="p-button-info p-button-sm"
                  @click.stop="showReservTermsPreviousDialog = true"
                />
              </div>
              <hr />
            </div>
            <div>
              <div class="flex justify-content-between">
                <b>{{ $t("store.storeenabled") }} </b>
                <Tag
                  class="mt-2"
                  :severity="getStatusTag(currentBusiness.BusStoreEnabled)"
                  style="text-align: left; font-size: x- small"
                  >{{ getStatusText(currentBusiness.BusStoreEnabled) }}</Tag
                >
              </div>
              <small>{{ $t("store.storeenableddesc") }}</small>
              <hr />
            </div>
            <div>
              <div class="flex justify-content-between">
                <b>{{ $t("code.tourscheduling") }} </b>
                <Tag
                  class="mt-2"
                  :severity="
                    getStatusTag(currentBusiness.BusTourSchdedulingEnabled)
                  "
                  style="text-align: left; font-size: x- small"
                  >{{
                    getStatusText(currentBusiness.BusTourSchdedulingEnabled)
                  }}</Tag
                >
              </div>
              <small>{{ $t("code.toursschedulingdesc") }}</small>
              <hr />
            </div>
            <div>
              <div class="flex justify-content-between">
                <b>{{ $t("code.apptscheduling") }} </b>
                <Tag
                  class="mt-2"
                  :severity="
                    getStatusTag(currentBusiness.BusApptSchedulingEnabled)
                  "
                  style="text-align: left; font-size: x- small"
                  >{{
                    getStatusText(currentBusiness.BusApptSchedulingEnabled)
                  }}</Tag
                >
              </div>
              <small>{{ $t("code.apptschedulingdesc") }}</small>
              <hr />
            </div>
          </template>
        </Card>
      </div>
      <div v-else>
        <Card style="margin-bottom: 2em; text-align: left">
          <template #content>
            <div>
              <div class="flex justify-content-between">
                <span>
                  <b>{{ $t("custreserv.custsales") }} </b></span
                >
                <span>
                  <InputNumber
                    id="salesTaxPercent"
                    placeholder="%"
                    v-model="currentBusiness.BusSalesTaxPercent"
                    style="font-size: small"
                    mode="decimal"
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    :min="0"
                    :max="10"
                  />
                  %</span
                >
              </div>
              <small>{{ $t("message.salestaxdesc") }}</small>
              <hr />
              <div class="flex justify-content-between">
                <span>
                 <b>{{ $t("message.packages") }} </b></span
                >
                <InputSwitch
                  id="messagesSwitch"
                  v-model="currentBusiness.BusDisplayServices"
                />
              </div>
              <small>{{ $t("message.packagesdesc") }}</small>
              <hr />
              <div class="flex justify-content-between">
                <span>
                  <b>{{ $t("message.acceptquotes") }}</b></span
                >
                <InputSwitch
                  id="quotesSwitch"
                  v-model="currentBusiness.BusAcceptQuotes"
                />
              </div>
              <small>{{ $t("message.acceptquotesdesc") }}</small>
              <hr />
              <div v-if="currentBusiness.BusAcceptQuotes == true">
                <div class="flex justify-content-between">
                  <span>
                    <b>{{ $t("message.quotesvalid") }}</b></span
                  >
                  <span>
                    <InputNumber
                      id="quoteexp"
                      placeholder="#"
                      v-model="currentBusiness.BusQuoteExpiration"
                      style="font-size: small"
                    />
                    days</span
                  >
                </div>
                <small> {{ $t("message.quotesvaliddesc") }} </small>
                <hr />
              </div>
              <div class="flex justify-content-between">
                <span>
                  <b>{{ $t("message.acceptpayments") }} </b>
                </span>
                <InputSwitch
                  id="paymentsSwitch"
                  v-model="currentBusiness.BusAcceptPayment"
                  @change="busAcceptChanged()"
                />
              </div>

              <div class="required-star" v-if="enrollmentStepsIncomplete">
                {{ $t("code.stripeverificationrequired") }}
              </div>
              <small>{{ $t("message.acceptpaymentsdesc") }}</small>

              <div
                v-if="
                  providerStatus != null &&
                  providerStatus.Enrolled == true &&
                  providerStatus.ServiceAgreement == true &&
                  providerStatus.AccountAdded == true
                "
              >
                <hr />
                <div class="flex justify-content-between align-items-start">
                  <div>
                    <span
                      ><b> {{ $t("code.payoutaccount") }} </b></span
                    >
                    <br />
                    <small
                      >{{ $t("code.payoutaccountdesc") }} <br />
                      <b>Current Account: </b>
                      <Chip
                        :label="'x' + providerStatus.BankLastFour"
                        class="m-1 primary-chip chip-sm"
                    /></small>
                  </div>
                  <Button
                    :label="$t('code.updateaccount')"
                    icon="pi pi-arrow-circle-right"
                    class="p-button-info p-button-sm"
                    @click.stop="
                      showNewBankAccount = true;
                      errors = [];
                    "
                  />
                </div>
              </div>
              <div
                v-if="displayAccountSetup && currentBusiness.BusAcceptPayment"
              >
                <Card style="margin-bottom: 2em; text-align: left">
                  <template #content>
                    <!-- <Message severity="warn" :closable="false">
            {{ $t("payments.enable") }}
          </Message> -->

                    <span style="vertical-align: -webkit-baseline-middle"
                      ><i
                        v-if="providerStatus.Enrolled == false"
                        class="pi pi-circle-off"
                        style="fontsize: 1rem"
                      ></i
                      ><i
                        v-if="providerStatus.Enrolled == true"
                        class="pi pi-check-circle"
                        style="fontsize: 1rem"
                      ></i
                      >{{ $t("payments.accept") }}
                      <Button
                        v-if="providerStatus.Enrolled == false"
                        :label="$t('payments.started')"
                        class="p-button-success p-button-sm"
                        @click.stop="showServAgreement = true"
                      />
                      <!-- <Button
                        v-if="providerStatus.Enrolled == true"
                        :label="$t('payments.completed')"
                        disabled="disabled"
                        class="p-button-link p-button-sm"
                      />  --> </span
                    ><br />
                    <span style="vertical-align: -webkit-baseline-middle">
                      <i
                        v-if="
                          providerStatus.ServiceAgreement == false ||
                          providerStatus.DetailsPending == true
                        "
                        class="pi pi-circle-off"
                        style="fontsize: 1rem"
                      ></i
                      ><i
                        v-if="
                          providerStatus.ServiceAgreement == true &&
                          providerStatus.DetailsPending == false
                        "
                        class="pi pi-check-circle"
                        style="fontsize: 1rem"
                      ></i
                      >{{ $t("payments.fillout") }}
                      <Button
                        v-if="
                          providerStatus.Enrolled == true &&
                          (providerStatus.ServiceAgreement == false ||
                            providerStatus.DetailsPending == true)
                        "
                        :label="$t('code.continue')"
                        class="p-button-success p-button-sm"
                        @click.stop="startEnrollment()"
                      />
                      <!-- <Button
                        v-if="providerStatus.ServiceAgreement == true && providerStatus.DetailsPending == false"
                        :label="$t('payments.completed')"
                        disabled="disabled"
                        class="p-button-link p-button-sm"
                      />  --> </span
                    ><br />
                    <span style="vertical-align: -webkit-baseline-middle">
                      <i
                        v-if="providerStatus.AccountAdded == false"
                        class="pi pi-circle-off"
                        style="fontsize: 1rem"
                      ></i
                      ><i
                        v-else
                        class="pi pi-check-circle"
                        style="fontsize: 1rem"
                      ></i
                      >{{ $t("payments.bank") }}
                      <Button
                        v-if="
                          providerStatus.Enrolled == true &&
                          providerStatus.ServiceAgreement == true &&
                          providerStatus.AccountAdded == false &&
                          providerStatus.DetailsPending == false
                        "
                        :label="$t('code.continue')"
                        class="p-button-success p-button-sm"
                        @click.stop="
                          showNewBankAccount = true;
                          errors = [];
                        "
                      />
                      <!-- <Button
                        v-if="providerStatus.ServiceAgreement == true && providerStatus.DetailsPending == false && providerStatus.AccountAdded == true"
                        :label="$t('payments.completed')"
                        disabled
                        class="p-button-link p-button-sm"
                      /> -->
                    </span>
                  </template>
                </Card>
              </div>
              <hr />
              <div class="flex justify-content-between">
                <span>
                  <b>{{ $t("message.downrequire") }} </b></span
                >
                <InputSwitch
                  v-if="currentBusiness.BusAcceptPayment == true"
                  id="downPaymentSwitch"
                  v-model="currentBusiness.BusDownPmtReq"
                />
              </div>
              <small>{{ $t("message.downrequiredesc") }}</small>
              <div v-if="currentBusiness.BusAcceptPayment == false">
                <small
                  ><b
                    ><i>Only avaialble when Accept Payments is enabled.</i></b
                  ></small
                >
              </div>
              <hr />

              <div v-if="currentBusiness.BusDownPmtReq == true">
                <div class="flex justify-content-between">
                  <span>
                    <b>{{ $t("message.downamount") }} </b></span
                  >
                  <InputNumber
                    v-if="currentBusiness.BusDownPmtReq == true"
                    id="downpmtamt"
                    placeholder="$"
                    v-model="currentBusiness.BusDownPmtAmt"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    style="font-size: small"
                  />

                  <InputNumber
                    v-else
                    id="downpmtamt"
                    placeholder="$"
                    v-model="currentBusiness.BusDownPmtAmt"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    style="font-size: small"
                    disabled
                  />
                </div>
                <small>{{ $t("message.downamountdesc") }}</small>
                <hr />
              </div>
              <div class="flex justify-content-between">
                <span>
                  <b>{{ $t("message.messages") }} </b></span
                >
                <InputSwitch
                  id="messagesSwitch"
                  v-model="currentBusiness.BusAcceptMessages"
                />
              </div>
              <small>{{ $t("message.messagesdesc") }}</small>
              <hr />
              <div class="flex justify-content-between">
                <span>
                  <b>{{ $t("message.calendar") }} </b></span
                >
                <InputSwitch
                  id="messagesSwitch"
                  v-model="currentBusiness.BusDisplayCalendar"
                />
              </div>
              <small>{{ $t("message.calendardesc") }}</small>

              <hr />
              <div class="flex justify-content-between">
                <span>
                  <b>{{ $t("code.reservterms") }} </b></span
                >
                <InputSwitch
                  id="messagesSwitch"
                  v-model="currentBusiness.BusDisplayReservTerms"
                  @change="reservTermsChanged()"
                />
              </div>
              <small>{{ $t("code.reservtermsdesc") }}</small>
              <br />
              <Button
                v-if="currentBusiness.BusDisplayReservTerms"
                :label="$t('menu.edit')"
                class="p-button-primary p-button-sm mr-2"
                @click.stop="showReservTermsDialog = true"
              />
              <Button
                v-if="
                  currentBusiness.BusDisplayReservTerms && allTerms.length > 1
                "
                :label="$t('code.reservtermshist')"
                class="p-button-info p-button-sm"
                @click.stop="showReservTermsPreviousDialog = true"
              />
            </div>
            <hr />
            <div class="flex justify-content-between">
              <span>
                <b>{{ $t("store.storeenabled") }} </b></span
              >
              <InputSwitch
                id="messagesSwitch"
                v-model="currentBusiness.BusStoreEnabled"
              />
            </div>
            <small>{{ $t("store.storeenableddesc") }}</small>
            <hr />
            <div class="flex justify-content-between">
              <span>
                <b>{{ $t("code.tourscheduling") }} </b></span
              >
              <InputSwitch
                id="messagesSwitch"
                v-model="currentBusiness.BusTourSchdedulingEnabled"
              />
            </div>
            <small>{{ $t("code.toursschedulingdesc") }}</small>
            <hr />
            
            <div class="flex justify-content-between">
              <span>
                <b>{{ $t("code.apptscheduling") }} </b></span
              >
              <InputSwitch
                id="messagesSwitch"
                v-model="currentBusiness.BusApptSchedulingEnabled"
              />
            </div>
            <small>{{ $t("code.apptschedulingdesc") }}</small>
            <hr />
            <Message
              v-if="errors.length > 0"
              severity="error"
              :closable="false"
            >
              {{ $t("code.errorsfound") }}</Message
            >
            <div class="col-12 flex mt-3">
              <Button
                :label="$t('profile.cancel')"
                icon="pi pi-times"
                @click.stop="resetSettings()"
                class="p-button-secondary mr-3"
              />
              <Button
                v-if="!saving"
                :label="$t('profile.save')"
                icon="pi pi-check"
                @click.stop="validateSettings(false)"
                autofocus
              />
              <Button
                v-else
                :label="$t('profile.saving')"
                icon="pi pi-spin pi-spinner"
                disabled
              />
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div v-else-if="currentBusiness.BusActive == false">
      <inactive-subscription
        :busId="currentBusiness.BusId"
      ></inactive-subscription>
    </div>
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{ $t("payments.error") }}</Message
      >
    </div>
  </div>
  <!-- Previous term versions -->
  <Dialog
    :header="$t('code.reservtermshist')"
    v-model:visible="showReservTermsPreviousDialog"
    :dismissableMask="true"
    :style="{ width: '90vh' }"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
  >
    <Dropdown
      v-model="selectedTerms"
      class="mr-2"
      :options="allTerms"
      optionLabel="TermsValidFrom"
      :placeholder="$t('services.pleaseselect')"
    >
      <template #value="slotProps">
        <div class="country-item country-item-value" v-if="slotProps.value">
          <div>{{ $formatDateTime(slotProps.value.TermsValidFrom) }}</div>
        </div>
        <span v-else>
          {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="slotProps">
        <div>{{ $formatDateTime(slotProps.option.TermsValidFrom) }}</div>
      </template>
    </Dropdown>
    <div v-if="selectedTerms" class="m-2">
      <!-- <label>{{ $t("code.current") }}: </label>{{ selectedTerms.TermsValid }}
      <br /> -->
      <label>{{ $t("code.validfrom") }}: </label
      >{{ $formatDateTime(selectedTerms.TermsValidFrom) }}<br />
      <span v-if="selectedTerms.TermsValid != true"><label>{{ $t("code.validuntil") }}: </label
      >{{ $formatDateTime(selectedTerms.TermsValidUntil) }}<br /></span>
      <label>{{ $t("code.createdby") }}: </label
      >{{ selectedTerms.Cust.CustFirstName }}
      {{ selectedTerms.Cust.CustLastName }}<br />
    </div>
    <div v-if="selectedTerms" class="card">
      <span v-html="selectedTerms.TermsText"></span>
    </div>
  </Dialog>
  <!-- Edit Terms -->
  <Dialog
    :header="$t('code.reservterms')"
    v-model:visible="showReservTermsDialog"
    :dismissableMask="true"
    :style="{ width: '90vh' }"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
  >
    <Editor
      id="longdesc"
      v-model="latestTerms.TermsText"
      editorStyle="height: 65vh"
    >
      <template #toolbar>
        <span class="ql-formats">
          <select class="ql-font">
            <option selected=""></option>
            <option value="serif"></option>
            <option value="monospace"></option>
          </select>
          <select class="ql-size">
            <option value="small"></option>
            <!-- Note a missing, thus falsy value, is used to reset to default -->
            <option selected></option>
            <option value="large"></option>
            <option value="huge"></option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>
        </span>
        <span class="ql-formats">
          <select class="ql-color">
            <option selected="selected"></option>
            <option value="#e60000"></option>
            <option value="#ff9900"></option>
            <option value="#ffff00"></option>
            <option value="#008a00"></option>
            <option value="#0066cc"></option>
            <option value="#9933ff"></option>
            <option value="#ffffff"></option>
            <option value="#facccc"></option>
            <option value="#ffebcc"></option>
            <option value="#ffffcc"></option>
            <option value="#cce8cc"></option>
            <option value="#cce0f5"></option>
            <option value="#ebd6ff"></option>
            <option value="#bbbbbb"></option>
            <option value="#f06666"></option>
            <option value="#ffc266"></option>
            <option value="#ffff66"></option>
            <option value="#66b966"></option>
            <option value="#66a3e0"></option>
            <option value="#c285ff"></option>
            <option value="#888888"></option>
            <option value="#a10000"></option>
            <option value="#b26b00"></option>
            <option value="#b2b200"></option>
            <option value="#006100"></option>
            <option value="#0047b2"></option>
            <option value="#6b24b2"></option>
            <option value="#444444"></option>
            <option value="#5c0000"></option>
            <option value="#663d00"></option>
            <option value="#666600"></option>
            <option value="#003700"></option>
            <option value="#002966"></option>
            <option value="#3d1466"></option>
          </select>
          <select class="ql-background">
            <option value="#000000"></option>
            <option value="#e60000"></option>
            <option value="#ff9900"></option>
            <option value="#ffff00"></option>
            <option value="#008a00"></option>
            <option value="#0066cc"></option>
            <option value="#9933ff"></option>
            <option selected="selected"></option>
            <option value="#facccc"></option>
            <option value="#ffebcc"></option>
            <option value="#ffffcc"></option>
            <option value="#cce8cc"></option>
            <option value="#cce0f5"></option>
            <option value="#ebd6ff"></option>
            <option value="#bbbbbb"></option>
            <option value="#f06666"></option>
            <option value="#ffc266"></option>
            <option value="#ffff66"></option>
            <option value="#66b966"></option>
            <option value="#66a3e0"></option>
            <option value="#c285ff"></option>
            <option value="#888888"></option>
            <option value="#a10000"></option>
            <option value="#b26b00"></option>
            <option value="#b2b200"></option>
            <option value="#006100"></option>
            <option value="#0047b2"></option>
            <option value="#6b24b2"></option>
            <option value="#444444"></option>
            <option value="#5c0000"></option>
            <option value="#663d00"></option>
            <option value="#666600"></option>
            <option value="#003700"></option>
            <option value="#002966"></option>
            <option value="#3d1466"></option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <select class="ql-align">
            <option selected=""></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-link" type="button"></button
        ></span>
      </template>
    </Editor>
    <div class="col-12 flex mt-3">
      <Button
        :label="$t('profile.cancel')"
        icon="pi pi-times"
        @click.stop="resetTerms()"
        class="p-button-secondary mr-3"
      />
      <Button
        :label="$t('profile.save')"
        icon="pi pi-check"
        @click.stop="saveNewTermsVersion()"
        autofocus
      />
    </div>
  </Dialog>

  <Dialog
    header="Business Country Base"
    v-model:visible="showCountryBase"
    :dismissableMask="true"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
  >
    <p>
      {{ $t("payments.myreservs") }}
    </p>
    <template #footer>
      <Button
        :label="$t('payments.noqualify')"
        icon="pi pi-times"
        @click="showCountryBase = false"
        class="p-button-secondary"
      />
      <Button
        :label="$t('payments.qualifies')"
        icon="pi pi-check"
        @click="
          showCountryBase = false;
          showServAgreement = true;
        "
        autofocus
      />
    </template>
  </Dialog>

  <Dialog
    :header="$t('payments.serviceagreement')"
    v-model:visible="showServAgreement"
    :dismissableMask="true"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
  >
    <p>
      {{ $t("payments.policy1") }}
      <a href="https://www.myreservs.com/TermsandConditions" target="_blank">{{
        $t("payments.policy2")
      }}</a>
      {{ $t("payments.policy3") }}
      <a
        href="https://stripe.com/connect-account/legal/recipient"
        target="_blank"
        >{{ $t("payments.policy4") }}</a
      >
    </p>
    <p>{{ $t("payments.policycharges") }}</p>
    <p>
      {{ $t("payments.agree") }}
    </p>
    <template #footer>
      <div class="flex justify-content-between">
        <Button
          :label="$t('payments.donot')"
          icon="pi pi-times"
          @click="
            showServAgreement = false;
            currentBusiness.BusAcceptPayment = false;
          "
          class="p-button-secondary"
        />
        <Button
          :label="$t('payments.iagree')"
          icon="pi pi-check"
          @click="
            showServAgreement = false;
            startEnrollment();
          "
          autofocus
        />
      </div>
    </template>
  </Dialog>

  <Dialog
    v-model:visible="showBankConfirm"
    :style="{ width: '50vw' }"
    :header="$t('code.confirmaccount')"
    :dismissableMask="true"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
  >
    <p>{{ $t("code.confirmaccountdesc") }}</p>
    <div>
      <label for="name">{{ $t("payments.acctname") }}: </label>
      {{ account.name }} <br />
      <label for="type">{{ $t("payments.accttype") }}: </label>{{ account.type
      }}<br />
      <label for="route">{{ $t("payments.routing") }}: </label
      >{{ account.routing }}
      <br />
      <label for="number">{{ $t("payments.number") }}: </label
      >{{ account.number }}
    </div>
    <div class="flex justify-content-between mt-2">
      <Button
        icon="pi pi-times"
        :label="$t('profile.cancel')"
        class="p-button-secondary p-button-sm m-1"
        @click.stop="resetNewAccount()"
      />
      <Button
        :label="$t('menu.back')"
        class="p-button-raised p-button-info mt-2"
        icon="pi pi-arrow-left"
        @click.stop="
          showBankConfirm = false;
          showNewBankAccount = true;
        "
      />
      <Button
        v-if="!submittingCardInfo"
        :label="$t('menu.submit')"
        class="p-button-raised p-button-success mt-2"
        icon="pi pi-check"
        autofocus
        @click.stop="retrieveToken()"
      />
      <Button
        v-else
        :label="$t('menu.submit')"
        class="p-button-raised p-button-success mt-2"
        icon="pi pi-spin pi-spinner"
        disabled
      />
    </div>
  </Dialog>

  <Dialog
    v-model:visible="showNewBankAccount"
    :style="{ width: '90vw' }"
    :header="$t('payments.payout')"
    :dismissableMask="true"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
  >
    <div class="p-fluid formgrid grid text-left">
      <div v-if="accountErrors.length > 0" class="col-12">
        <Message
          v-for="error of accountErrors"
          severity="error"
          :key="error"
          :sticky="true"
          >{{ error }}</Message
        >
      </div>
      <div class="field col-12">
        <label for="name"
          >{{ $t("payments.acctname")
          }}<span class="required-star">*</span></label
        >
        <InputText
          id="name"
          v-model="account.name"
          :placeholder="$t('payments.holdername')"
        />
      </div>
      <div class="field col-12">
        <label for="type"
          >{{ $t("payments.accttype")
          }}<span class="required-star">*</span></label
        >
        <Dropdown
          id="type"
          class="mt-2"
          v-model="account.type"
          :options="accountTypes"
          optionLabel="label"
          optionValue="value"
          :placeholder="$t('payments.accounttype')"
        />
      </div>
      <div class="field col-12">
        <label for="route"
          >{{ $t("payments.routing")
          }}<span class="required-star">*</span></label
        >
        <InputMask
          id="route"
          v-model="account.routing"
          mask="999999999"
          :placeholder="$t('payments.routingnumber')"
        />
      </div>
      <div class="field col-12">
        <label for="number"
          >{{ $t("payments.number")
          }}<span class="required-star">*</span></label
        >
        <InputText
          id="number"
          v-model="account.number"
          :placeholder="$t('payments.accountnumber')"
        />
      </div>

      <Button
        v-if="!submittingCardInfo"
        :label="$t('menu.submit')"
        class="p-button-raised p-button-success mt-2"
        icon="pi pi-check"
        autofocus
        @click.stop="validateAccountInfo()"
      />
      <Button
        v-else
        :label="$t('menu.submit')"
        class="p-button-raised p-button-success mt-2"
        icon="pi pi-spin pi-spinner"
        disabled
      />
      <div class="col-12">
        <Message
          v-for="error of errors"
          severity="error"
          :key="error"
          :sticky="true"
          >{{ error }}</Message
        >
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapState } from "vuex";
import Business from "../store/Models/Business";
import BusinessReservTerm from "../store/Models/BusinessReservTerm";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import InactiveSubscription from "../components/InactiveSubscription.vue";

export default {
  name: "BusinessSettings",
  components: { LoadingOverlay, InactiveSubscription },
  data() {
    return {
      currentBusiness: {},
      currentSubscription:{},
      editingSettings: false,
      paymentEnrollment: false,
      errors: [],
      saving: false,
      accountErrors: [],
      loading: false,
      showCountryBase: false,
      showServAgreement: false,
      providerStatus: null,
      showNewBankAccount: false,
      showBankConfirm: false,
      displayAccountSetup: false,
      enrollmentStepsIncomplete: false,
      submittingCardInfo: false,
      showReservTermsDialog: false,
      showReservTermsPreviousDialog: false,
      latestTerms: {
        TermsId: 0,
        TermsText: "",
      },
      selectedTerms: null,
      allTerms: [],
      busTerms: "",
      account: {
        name: "",
        type: null,
        routing: "",
        number: "",
      },
      accountTypes: [
        { label: "Individual", value: "individual" },
        { label: "Company", value: "company" },
      ],
    };
  },
  async mounted() {
    this.$log.info("business-settings-mounted");
    if (this.currentBusinessId > 0) {
      this.setSelectedBusiness();
      this.$log.debug(this.currentBusiness);
    }
  },
  methods: {
    reservTermsChanged() {
      if (
        this.currentBusiness.BusDisplayReservTerms == true &&
        this.allTerms.length == 0
      ) {
        this.showReservTermsDialog = true;
      }
    },
    busAcceptChanged() {
      if (this.currentBusiness.BusAcceptPayment == false) {
        this.currentBusiness.BusDownPmtReq = false;
        this.currentBusiness.BusDownPmtAmt = null;
      }

      //stripe enrollment
      if (
        this.currentBusiness.BusAcceptPayment == true &&
        this.providerStatus != null &&
        this.providerStatus.Enrolled == true &&
        (this.providerStatus.AccountAdded == false ||
          this.providerStatus.ServiceAgreement == false)
      ) {
        this.displayAccountSetup = true;
        this.startEnrollment();
      } else if (
        this.currentBusiness.BusAcceptPayment == true &&
        this.providerStatus != null &&
        (this.providerStatus.Enrolled == false ||
          this.providerStatus.AccountAdded == false ||
          this.providerStatus.ServiceAgreement == false)
      ) {
        this.displayAccountSetup = true;
        this.showServAgreement = true;
      }
    },
    getStatusTag(status) {
      switch (status) {
        case true:
          return "success";
        default:
          return "danger";
      }
    },
    getStatusText(status) {
      switch (status) {
        case true:
          return "Enabled";
        default:
          return "Disabled";
      }
    },
    async validateSettings(force) {
      this.$log.debug("validate settings");
      this.saving = true;
      this.errors = [];
      this.enrollmentStepsIncomplete = false;
      this.$log.debug(this.currentBusiness.BusAcceptPayment == true);
      this.$log.debug(
        !(
          this.providerStatus.Enrolled == false &&
          this.providerStatus.AccountAdded == false &&
          this.providerStatus.ServiceAgreement == false
        )
      );
      if (this.currentBusiness.BusAcceptPayment == true && force != true) {
        if (
          this.providerStatus != null &&
          (this.providerStatus.Enrolled == false ||
            this.providerStatus.AccountAdded == false ||
            this.providerStatus.ServiceAgreement == false)
        ) {
          this.$log.debug("error");
          this.errors.push("steps incomplete");
          this.enrollmentStepsIncomplete = true;
        }
      }

      if (this.currentBusiness.BusDownPmtReq) {
        if (!this.currentBusiness.BusDownPmtAmt > 0) {
          document.getElementById("downpmtamt").classList.add("p-invalid");
          this.errors.push("down payment amount required");
        }
      }

      if (
        this.currentBusiness.BusSalesTaxPercent == null ||
        this.currentBusiness.BusSalesTaxPercent > 10
      ) {
        this.errors.push("sales tax invalid");
        document.getElementById("salesTaxPercent").classList.add("p-invalid");
      }

      if (this.errors.length == 0) {
        var result = await this.updateSettings().then(() => {
          return true;
        });
        return result;
      } else {
        this.$log.debug("errors " + this.errors.length);
        this.$log.debug(this.errors);
        this.saving = false;
        return false;
      }
    },

    async updateSettings() {
      this.$log.info("updating business settigns");
     //console.log(this.currentBusiness);
      await Business.updateSettings(this.currentBusiness).then(
        async (response) => {
          this.$log.debug(response);
          this.saving = false;
          if (response == 1) {
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.settings"),
              life: 3000,
            });
            this.setSelectedBusiness();
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.settingsfailed"),
              life: 3000,
            });
          }
        }
      );

      this.resetSettings();
    },
    resetSettings() {
      //hide update dialog
      this.editingSettings = false;
      this.setSelectedBusiness();
      //clear errors
      this.errors = [];
      this.enrollmentStepsIncomplete = false;
    },
    async startEnrollment() {
      this.$confirm.require({
        message: this.$t("code.enrollementredirectdesc"),
        header: this.$t("code.enrollementredirect"),
        icon: "pi pi-exclamation-triangle",
        acceptLabel: this.$t("code.continue"),
        rejectLabel: this.$t("code.cancel"),
        accept: async () => {
          this.paymentEnrollment = true;
          await this.validateSettings(true).then((response) => {
            this.loading = true;
            this.$log.debug(response);
            if (response == true) {
              Business.enrollInPayments(this.currentBusiness.BusId).then(
                async (response) => {
                  if (response != null) {
                    window.location.replace(response);
                  } else {
                    this.$toast.add({
                      severity: "error",
                      summary: this.$t("code.errors"),
                      detail: this.$t("code.enrolled"),
                      life: 3000,
                    });
                    this.loading = false;
                    this.paymentEnrollment = false;
                  }
                }
              );
            } else {
              this.$confirm.close();
              this.showServAgreement = false;
              this.displayAccountSetup = false;
              this.loading = false;
            }
          }); //todo ensure wait
        },
        reject: () => {
          this.$confirm.close();
          this.showServAgreement = false;
          this.displayAccountSetup = false;
          this.currentBusiness.BusAcceptPayment = false;
        },
      });
    },

    async retrieveAccountLink() {
      Business.resolveEnrollment(this.currentBusiness.BusId).then(
        async (response) => {
          if (response != null) {
            window.location.replace(response);
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.enrolled"),
              life: 3000,
            });
          }
        }
      );
    },

    async updateAccount() {
      Business.resolveEnrollment(this.currentBusiness.BusId).then(
        async (response) => {
          if (response != null) {
            window.location.replace(response);
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.enrolled"),
              life: 3000,
            });
          }
        }
      );
    },

    validateAccountInfo() {
      this.$log.info("validating new account info");
      this.$log.debug(this.account);
      //clear out errors
      this.errors = [];

      //verify required values
      if (this.account.name.length == 0) {
        this.errors.push(this.$t("code.holdername"));
        document.getElementById("name").classList.add("p-invalid");
      }
      if (this.account.type == null) {
        this.errors.push(this.$t("code.holdertype"));
        document.getElementById("type").classList.add("p-invalid");
      }
      if (this.account.routing.length == 0) {
        this.errors.push(this.$t("code.routingnumber"));
        document.getElementById("route").classList.add("p-invalid");
      }
      if (this.account.number == null || this.account.number.length == 0) {
        this.errors.push(this.$t("code.accountnumber"));
        document.getElementById("number").classList.add("p-invalid");
      }
      if (this.errors.length == 0) {
        this.showBankConfirm = true;
        this.showNewBankAccount = false;
        //this.retrieveToken();
      } else {
        this.submittingCardInfo = false;
      }
    },
    async retrieveToken() {
      this.submittingCardInfo = true;
      var bankAccountParams = {
        country: "us",
        currency: "usd",
        account_number: this.account.number,
        routing_number: this.account.routing,
        account_holder_name: this.account.name,
        account_holder_type: this.account.type,
      };
      this.createAccount(bankAccountParams);
    },

    async createAccount(bankAccountParams) {
      // eslint-disable-next-line
      let stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: this.currentBusiness.BusProviderAccount,
      });
      this.accountErrors = [];

      stripe
        .createToken("bank_account", bankAccountParams)
        .then((result) => {
          this.submittingCardInfo = false;
          this.$log.debug(result);
          if (result.error) {
            this.submittingCardInfo = false;
            this.$log.debug("error");
            if (result.error.code == "routing_number_invalid")
              this.accountErrors.push(this.$t("code.validrouting"));
            if (result.error.code == "account_number_invalid")
              this.accountErrors.push(this.$t("code.validaccount"));
            this.showBankConfirm = false;
            this.showNewBankAccount = true;
          }
          if (result.token) {
            this.$log.debug("token");
            Business.createExternalAccount(
              this.currentBusinessId,
              result.token.id
            ).then((response) => {
              this.$log.debug(response);
              this.setSelectedBusiness();
              this.showNewBankAccount = false;
              this.showBankConfirm = false;
              this.submittingCardInfo = false;
              this.resetNewAccount();
            });
          }
        })
        .catch((e) => {
          this.submittingCardInfo = false;
          this.showBankConfirm = false;
          this.showNewBankAccount = true;
          this.accountErrors.push(e);
        });

      // this.$log.debug(response);

      // this.Business.createExternalAccount(
      //     this.currentBusinessId,
      //     token
      //   ).then((response) => {
      //     this.$log.debug(response);
      //   });
    },
    resetNewAccount() {
      this.accountErrors = [];
      this.account = {
        name: "",
        type: null,
        routing: "",
        number: "",
      };
    },
    resetTerms() {
      this.showReservTermsDialog = false;
      BusinessReservTerm.fetchAllByBusId(this.currentBusinessId).then(
        (result) => {
          this.allTerms = result;
          if (this.allTerms != null && this.allTerms.length > 0) {
            BusinessReservTerm.fetchValidByBusId(this.currentBusinessId).then(
              (result) => {
                this.latestTerms = result;
              }
            );
          }
        }
      );
    },
    saveNewTermsVersion() {
      this.latestTerms.BusId = this.currentBusinessId;
      this.latestTerms.TermsId = 0;
      BusinessReservTerm.createTerms(this.latestTerms).then(
        async (response) => {
          if (response != 2) {
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.settings"),
              life: 3000,
            });
            this.resetTerms();
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.enrolled"),
              life: 3000,
            });
          }
        }
      );
    },
    setSelectedBusiness() {
      this.loading = true;
      this.displayAccountSetup = false;
      Business.fetchSettings(this.currentBusinessId).then((result) => {
       //console.log(result);
        this.currentBusiness = result;
        if (this.paymentEnrollment == false) {
          this.loading = false;
        }
      });
      BusinessReservTerm.fetchAllByBusId(this.currentBusinessId).then(
        (result) => {
          this.allTerms = result;
          if (this.allTerms != null && this.allTerms.length > 0) {
            BusinessReservTerm.fetchValidByBusId(this.currentBusinessId).then(
              (result) => {
                this.latestTerms = result;
               //console.log(this.latestTerms);
              }
            );
          }
        }
      );

      Business.verifyProviderStatus(this.currentBusinessId).then((result) => {
        this.providerStatus = result;
        if (
          this.providerStatus != null &&
          (this.providerStatus.Enrolled == false ||
            this.providerStatus.AccountAdded == false ||
            this.providerStatus.ServiceAgreement == false)
        ) {
          this.displayAccountSetup = true;
          //this.currentBusiness.BusAcceptPayment = false;
        }
        this.$log.debug("provider status ");
        this.$log.debug(this.providerStatus);
      });
    },
  },
  watch: {
    async currentBusinessId() {
      this.editingSettings = false;
      this.setSelectedBusiness();
    },
  },
  computed: {
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
      currentCustomerId: (state) => state.currentCustomerId,
    }),
    myBusiness() {
      return true;
    },
  },
};
</script>
<style >
.p-fileupload-choose ~ .p-button {
  display: none;
}
.modifyChip.imageChip {
  border-radius: 0vw;
  font-size: medium;
}
.modifyChip.imageChip img {
  height: 3rem;
  width: auto;
}
label {
  font-weight: bold;
}
.p-fileupload .p-fileupload-row {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1px !important;
  margin: 10px;
  width: auto;
  text-align: left;
}

/* .p-message-close.p-link {
      margin-left: auto !important;
      overflow: hidden;
      position: relative;
  } */
/* button.p-button {
	margin-right: .5rem;
}

.p-inputtext {
	margin-right: .25rem;
} */
</style>
