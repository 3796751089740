import { Model } from '@vuex-orm/core'
/* eslint-disable */
import StoreOrder from "./StoreOrder"
import Card from "./Card"
export default class OrderPayment extends Model {
  static entity = 'orderPayments'
  
  static primaryKey = 'OrderPayId'
  
  static fields () {
    return {
      OrderPayId: this.uid(),
      OrderId:this.attr(null),
      Order: this.belongsTo(StoreOrder, 'OrderId'),
      PayAmount: this.attr(null),
      PayDate: this.attr(null),
      PayNum: this.attr(null),
      PayStatus:  this.attr(null),
      PaySource: this.attr(null),
      CardId: this.attr(null),
      OrderNum: this.attr(null),
      BusId: this.attr(null),
      PayInvoiceUrl: this.attr(null),
      Card: this.belongsTo(Card, 'CardId')
      
    }
  }

  static async fetchByBusId(BusId) {
    //console.log("fetch order payments for " + BusId)
    OrderPayment.deleteAll();
   var result = await OrderPayment.api().get('/api/OrderPayments/GetBusinessPayments/' + BusId);
    //console.log(result)
    return  OrderPayment.query()
      .withAllRecursive()
      .orderBy('OrderPayId', 'desc').all();
  }

  static async newPayment(payment) {
    //console.log("at new order payment")
    //console.log(payment)

    const result = await OrderPayment.api().post('/api/OrderPayments/newpayment/', payment).then((result) => {
      //console.log(result)
      if (result.response.status == 200 || result.response.status == 204 ) {
        //console.log("success")
        OrderPayment.deleteAll();
        return 1;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }

  static async newManualPayment(payment) {
    //console.log("at new manula reserv payment")
    //console.log(payment)

    const result = await OrderPayment.api().post('/api/OrderPayments/newmanualpayment/', payment).then((result) => {
      //console.log(result)
      if (result.response.status == 204) {
        //console.log("success")
        return result.response.data;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }
}

