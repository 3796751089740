<template>
<div class="col-12 searchingBackground">
<div style="position: relative; height: 100%; width: 100%;">
            <img
              src="@/assets/loading.gif"
              width="100"
              style="
                position: absolute;
                top: -25%;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              "
            />          
          </div>
</div>
</template>
<script>

export default {
  name: "LoadingOverlay",
  data() {
    return {
       };
  },
  mounted() {},
  methods: {
    
  },
};
</script>

<style>
.searchingBackground{
    opacity:0.8;
    background-color:#ccc;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
}
</style>