import { Model } from '@vuex-orm/core'
import Message from "./Message"

export default class MessageAttachment extends Model {
  static entity = 'messageAttachments'
  
  static primaryKey = 'AttachId'
  
  static fields () {
    return {
      AttachId: this.uid(),
      MessageId:  this.attr(null),
      Message: this.hasOne(Message, 'MessageId'),
      AttachmentTitle: this.attr(null),
      AttachmentFile: this.attr(null)
    }
  }
}

