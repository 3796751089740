import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Customer from "./Customer"
import Business from "./Business"

export default class SupportTicket extends Model {
  static entity = 'supporttickets'

  static primaryKey = 'TicketId'

  static fields() {
    return {

      TicketId: this.uid(),
      CustId: this.attr(null),
      BusId: this.attr(null),
      TicketFirstName: this.attr(null),
      TicketLastName: this.attr(null),
      TicketEmail: this.attr(null),
      TicketPhone: this.attr(null),
      TicketMessage: this.attr(null),
      TicketCat: this.attr(null),
      TicketStatus:  this.attr(null),
      TicketAssignee:  this.attr(null),
      TicketSubmitted: this.attr(null),
      TicketLastUpdated: this.attr(null),
      TicketClosed: this.attr(null),
      Cust: this.belongsTo(Customer, 'CustId'),
      Bus: this.belongsTo(Business, 'BusId'),

    }
  }

  // static async fetchByCustId(CustId) {
  //   Card.deleteAll();
  //   //console.log("fetch cards for " + CustId)
  //   var result = await Card.api().get('/api/cards/GetCustomerCards/' + CustId);
  //   //console.log(result)
  //   return Card.query()
  //     .withAllRecursive()
  //     .where(card => card.CustId == CustId).get();
  // }

  static async createNewTicket(supportTicket, language) {
    //console.log("creating new support ticket")

    var data = new FormData();
    data.append('lang', language);

    data.append("supportTicketString", JSON.stringify(supportTicket))

    const result = await SupportTicket.api().post('/api/SupportTickets',data
      ).then((result) => {
        //console.log(result)
        if (result.response.status == 201 || result.response.status == 200) {
          //console.log("success")
          return 1;
        }
        else {
          //console.log("error");
          return null;
        }
      }).catch(error => {
        //console.log(error.response);
        return null;
      });
    return result;
  }

  // static async deleteCard(cardId) {
  //   const result = await Card.api().delete('/api/cards/' + cardId,
  //     { delete: cardId }
  //   )
  //     .then((result) => {
  //       //console.log(result);
  //       if (result.response.status == 204 ||result.response.status == 200) {
  //         //console.log("success")
  //         Card.delete(cardId);
  //         return 1;
  //       }
  //       else {
  //         //console.log("error");
  //         return 2;
  //       }
  //     }).catch(error => {
  //       //console.log(error.response);
  //       return 2;
  //     });
  //   return result;
  // }
  // static async makeDefault(card) {
  //   const result = await Card.api().post('/api/cards/makedefault', card
  //   )
  //     .then((result) => {
  //       //console.log(result);
  //       if (result.response.status == 201) {
  //         //console.log("success");
  //         return 1;
  //       }
  //       else {
  //         //console.log("error");
  //         return 2;
  //       }
  //     }).catch(error => {
  //       //console.log(error.response);
  //       return 2;
  //     });
  //   return result;
  // }
}

