import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import MsalPlugin from './plugins/msal-plugin'
import HelperPlugin from './plugins/helper-plugin'
import Translations from './plugins/translations'
import { createI18n } from 'vue-i18n'
import es from './locales/es.json'
import en from './locales/en.json'
// import firebaseMessaging from './firebase'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';


import 'primeflex/primeflex.css';
import PrimeVue from 'primevue/config';
import Menubar from 'primevue/menubar';
import Galleria from 'primevue/galleria';
import Panel from 'primevue/panel';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Rating from 'primevue/rating';
import Card from 'primevue/card';
import InputSwitch from 'primevue/inputswitch';
import FileUpload from 'primevue/fileupload';
import Sidebar from 'primevue/sidebar';
import TabMenu from 'primevue/tabmenu';
import ProgressBar from 'primevue/progressbar';
// import FullCalendar from 'primevue/fullcalendar';
import Toolbar from 'primevue/toolbar';
import Checkbox from 'primevue/checkbox';
import SelectButton from 'primevue/selectbutton';
import Calendar from 'primevue/calendar';
import OrderList from 'primevue/orderlist';
import OverlayPanel from 'primevue/overlaypanel';
import Carousel from 'primevue/carousel';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Inplace from 'primevue/inplace';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ScrollPanel from 'primevue/scrollpanel';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Message from 'primevue/message';
import InputMask from 'primevue/inputmask';
import Tooltip from 'primevue/tooltip';
import Skeleton from 'primevue/skeleton';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmPopup from 'primevue/confirmpopup';
import Tag from 'primevue/tag';
import Editor from 'primevue/editor';
import MultiSelect from 'primevue/multiselect';
import AutoComplete from 'primevue/autocomplete';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import Divider from 'primevue/divider';
import TieredMenu from 'primevue/tieredmenu';
import Slider from 'primevue/slider';
import ProgressSpinner from 'primevue/progressspinner';
import Menu from 'primevue/menu';
import InlineMessage from 'primevue/inlinemessage';
import ConfirmDialog from 'primevue/confirmdialog';
import Steps from 'primevue/steps';
import Password from 'primevue/password';
import RadioButton from 'primevue/radiobutton';
import BadgeDirective from 'primevue/badgedirective';
import SplitButton from 'primevue/splitbutton';
import Image from 'primevue/image';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';



//  import moment from 'moment'



import 'primevue/resources/primevue.css';
// import 'primevue/resources//themes/vela-blue/theme.css';
import 'primevue/resources/themes/saga-purple/theme.css';
import 'primeicons/primeicons.css';



import VueLogger from 'vuejs3-logger';
// import { MsalPlugin } from '../msal-plugin'

const isProduction = process.env.NODE_ENV === 'production';

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

const clientId = process.env.VUE_APP_B2C_CLIENT_ID;
const authority = process.env.VUE_APP_B2C_AUTHORITY;
const knownAuth = process.env.VUE_APP_B2C_KNOWN_AUTHORITY;

const msalConfig = {
  auth: {
    clientId: clientId,
    authority: authority,
    knownAuthorities: [knownAuth],
  }
}

// const msalConfig = {
//   auth: {
//     clientId: '17109101-0313-47a2-a396-130339f49252',
//     authority: 'https://myreservstest.b2clogin.com/myreservstest.onmicrosoft.com/B2C_1_susi_email',
//     knownAuthorities: ["myreservstest.b2clogin.com/"],
//   }
// }

// const msalConfig = {
//   auth: {
//     clientId: 'd1713ade-5716-4b7c-9829-0a375cef8ac7',
//     authority: 'https://myreservs.b2clogin.com/myreservs.onmicrosoft.com/B2C_1_susi_email',
//     knownAuthorities: ["myreservs.b2clogin.com/"],
//   }
// }

// const firebaseConfig = {
//   apiKey: "AIzaSyA2YVoyzwcMFf9VkhvO-OgAjA5tMFHYV1E",
//   authDomain: "myreservs-front-end.firebaseapp.com",
//   projectId: "myreservs-front-end",
//   storageBucket: "myreservs-front-end.appspot.com",
//   messagingSenderId: "61027674391",
//   appId: "1:61027674391:web:5f7e96133d868201586f52",
//   measurementId: "G-WB1MYMHNYQ"
// };

const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages:{es,en}
});

const app = createApp(App)
app.use(i18n)
app.use(store)
app.use(router)


app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService)
app.use(VueLogger, options)
// .use(VueReCaptcha, { siteKey: '6LfbWsYdAAAAAHtU2t_OFil1wmHuBos2KuNHdbOg' })

app.use(MsalPlugin, msalConfig)
app.use(HelperPlugin)
app.use(Translations)
app.use(Cropper)


app.directive('badge', BadgeDirective);
// app.config.globalProperties.$messaging = firebaseMessaging

// app.config.globalProperties.$msalInstance = {};
// app.config.globalProperties.$emitter = new Emitter();

// app.use(i18n)
app.config.devtools = true
app.directive('tooltip', Tooltip);

// app.use(require('vue-moment'));


//PrimeVue
//app.config.globalProperties.$primevue = {ripple: true};
app.component('Menubar', Menubar);
app.component('Galleria', Galleria);
app.component('Panel', Panel);
app.component('Button', Button);
app.component('Dialog', Dialog);
app.component('Textarea', Textarea);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Dropdown', Dropdown);
app.component('DataView', DataView);
app.component('Rating', Rating);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Card', Card);
app.component('InputSwitch', InputSwitch);
app.component('FileUpload', FileUpload);
app.component('Sidebar', Sidebar);
app.component('TabMenu', TabMenu);
app.component('ProgressBar', ProgressBar);
// app.component('FullCalendar', FullCalendar);
app.component('Toolbar', Toolbar);
app.component('Checkbox', Checkbox);
app.component('SelectButton', SelectButton);
app.component('Calendar', Calendar);
app.component('OrderList', OrderList);
app.component('OverlayPanel', OverlayPanel);
app.component('Carousel', Carousel);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('Inplace', Inplace);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('ScrollPanel', ScrollPanel);
app.component('Toast', Toast);
app.component('Message', Message);
app.component('InputMask', InputMask);
app.component('Skeleton', Skeleton);
app.component('ConfirmPopup', ConfirmPopup);
app.component('Tag', Tag);
app.component('Editor', Editor);
app.component('MultiSelect', MultiSelect);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Chip', Chip);
app.component('Divider', Divider);
app.component('TieredMenu', TieredMenu);
app.component('Slider', Slider);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Menu', Menu);
app.component('InlineMessage', InlineMessage);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Steps', Steps);
app.component('Password', Password);
app.component('RadioButton', RadioButton);
app.component('SplitButton', SplitButton);
app.component('Image', Image);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Chips', Chips);

app.mount('#app')