//will remove, this will belong in quote/quoted service

import { Model } from '@vuex-orm/core'
import Reservation from "./Reservation"

export default class ReservService extends Model {
  static entity = 'reservServices'
  
  static primaryKey = 'ReservServId'
  
  static fields () {
    return {
      ReservServId: this.uid(),
      ReservId: this.attr(null),
      ServiceId: this.attr(null),
      Reservation: this.belongsTo(Reservation, 'ReservId')
      
    }
  }
}

