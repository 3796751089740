<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <div
     
      v-if="currentBusiness != null && currentBusiness.BusActive == true"
    >
      <Panel>
        <template #header>
          <div class="text-3xl">
            <span v-if="currentCustomer.CustFirstName != null">
              {{ currentDayTime }} {{ currentCustomer.CustFirstName }}!</span
            >
            <span v-else>{{ currentDayTime }}!</span>
          </div>
        </template>
        <Panel class="panel-primary mb-5">
          <template #header>
            <div class="flex-grow-1">
              <div
                class="
                  flex flex-wrap
                  align-items-center
                  justify-content-between
                "
              >
                <div class="text-xl">
                  <b> {{ $t("dashboard.todaysagenda") }}</b>
                </div>
                <div class="text-l">
                  <b>{{ $formatDateTimeToDate() }}</b>
                </div>
              </div>
            </div>
          </template>
          <div
            class="
              flex flex-wrap
              align-items-top
              justify-content-center
              align-items-stretch
            "
          >
            <Card
              v-if="
                currentBusiness.BusApptSchedulingEnabled ||
                currentBusiness.BusTourSchedulingEnabled
              "
              class="m-2 shadow-2 flex-grow-1 text-left"
            >
              <template #title>
                <i
                  class="pi pi-calendar text-primary"
                  style="font-size: x-large"
                ></i>
                {{ $t("menu.appts") }}
              </template>

              <template #content>
                <div
                  class="
                    flex flex-column
                    align-content-start
                    justify-content-left
                    pl-2
                  "
                >
                  <div
                    v-if="currentBusinessStats.TodayAppointments.length < 1"
                  >
                    {{ $t("dashboard.noappts") }}
                  </div>
                  <template
                    v-for="appts in currentBusinessStats.TodayAppointments"
                    :key="appts.ApptId"
                  >
                    <div class="flex flex-wrap align-items-start">
                      <span
                        ><b>{{ $formatTime(appts.Slot.SlotStartTime) }}</b>
                        &nbsp;</span
                      >
                      <span
                        class="pointer"
                        @click.stop="
                          $router.push({ name: 'BusinessAppointments' })
                        "
                      >
                        {{ appts.Cust.CustFirstName }}&nbsp;{{
                          appts.Cust.CustLastName
                        }}</span
                      >
                    </div>
                  </template>
                </div>
              </template>
            </Card>
            <Card class="m-2 shadow-2 flex-grow-1 text-left">
              <template #title
                ><i
                  class="pi pi-ticket text-primary"
                  style="font-size: x-large"
                ></i>
                {{ $t("menu.reservs") }}
              </template>
              <template #content>
                <div
                  class="
                    flex flex-column
                    align-content-start
                    justify-content-left
                    pl-2
                  "
                >
                  <div v-if="currentBusinessStats.TodayReservs.length< 1">
                    {{ $t("dashboard.noreservs") }}
                  </div>
                  <template
                    v-for="reserv in currentBusinessStats.TodayReservs"
                    :key="reserv.ReservId"
                  >
                    <div class="flex flex-wrap align-items-start">
                      <span
                        ><b>{{ $formatTime(reserv.Slot.SlotStartTime) }}</b>
                        &nbsp;</span
                      >
                      <span
                        class="pointer"
                        @click.stop="
                          $router.push({
                            name: 'BusinessReservation',
                            params: {
                              busId: reserv.BusId,
                              reservId: reserv.ReservId,
                            },
                          })
                        "
                      >
                        {{ reserv.ReservNum }}</span
                      >
                    </div>
                  </template>
                </div>
              </template>
            </Card>

            <Card
              v-if="currentBusiness.BusStoreEnabled == true"
              class="m-2 shadow-2 flex-grow-1 text-left"
            >
              <template #title>
                <i
                  class="pi pi-shopping-cart text-primary"
                  style="font-size: x-large"
                ></i>
                {{ $t("store.storeorders") }}
              </template>
              <template #content>
                <div
                  class="
                    flex flex-column
                    align-content-start
                    justify-content-left
                    pl-2
                  "
                >
                  <div v-if="currentBusinessStats.TodayOrders.length < 1">
                    {{ $t("dashboard.noorders") }}
                  </div>
                  <template
                    v-for="order in currentBusinessStats.TodayOrders"
                    :key="order.OrderId"
                  >
                    <div class="flex flex-wrap align-items-start">
                      <span
                        ><b>{{ $formatTime(order.Slot.SlotStartTime) }}</b>
                        &nbsp;</span
                      >
                      <span
                        class="pointer"
                        @click.stop="
                          $router.push({
                            name: 'BusinessStoreOrder',
                            params: {
                              busId: order.BusId,
                              orderId: order.OrderId,
                            },
                          })
                        "
                      >
                        {{ order.OrderNum }}</span
                      >
                    </div>
                  </template>
                </div>
              </template>
            </Card>
          </div>
        </Panel>

        <!-- <div class="p-card m-2">
      <div class="p-card-title pl-3 pt-3 text-left"></div>
      <div class="p-card-body">
       
            
    
  </div>    </div> -->
        <Panel class="panel-info mb-5">
          <template #header>
            <div class="flex-grow-1">
              <div
                class="
                  flex flex-wrap
                  align-items-center
                  justify-content-between
                "
              >
                <div class="text-xl">
                  <b> {{ $t("dashboard.followup") }}</b>
                </div>
              </div>
            </div>
          </template>
          <div class="flex flex-wrap align-items-center justify-content-center">
            <Card
            v-if="currentBusiness.BusAcceptQuotes == true"
              class="m-2 text-center shadow-2 flex-grow-1 pointer"
              @click.stop="$router.push({ name: 'BusinessQuotes' })"
            >
              <template #content>
                <div class="grid">
                  <div class="col-10 align-self-center">
                    <div>
                      <i
                        class="pi pi-dollar text-blue-700"
                        style="font-size: xx-large"
                      ></i
                      >&nbsp;<span
                        class="text-6xl"
                        v-if="currentBusinessStats != null"
                        >{{ currentBusinessStats.BusPendingQuotes }}</span
                      >
                    </div>
                    {{ $t("dashboard.quotespending") }}
                  </div>

                  <div
                    class="col-2 align-self-center"
                    style="text-align: right"
                  >
                    <Button
                      icon="pi pi-angle-right"
                      class="p-button-rounded p-button-text p-button-lg"
                      @click.stop="$router.push({ name: 'BusinessQuotes' })"
                    />
                  </div>
                </div>
              </template>
            </Card>
            <!-- <Card class="m-2 text-center " >
     
     <template #content>
      <div> <i class="pi pi-dollar text-primary" style="font-size:xx-large"></i>&nbsp;<span class="text-6xl" v-if="currentBusinessStats!= null">{{ currentBusinessStats.BusReservs }}</span></div>
     Reservs
     </template>
 </Card> -->
            <Card
            v-if="currentBusiness.BusStoreEnabled == true"
              class="m-2 text-center shadow-2 flex-grow-1 pointer"
              @click.stop="$router.push({ name: 'BusinessStoreOrders' })"
            >
              <template #content>
                <div class="grid">
                  <div class="col-10 align-self-center">
                    <div>
                      <i
                        class="pi pi-shopping-cart text-blue-700"
                        style="font-size: xx-large"
                      ></i
                      >&nbsp;<span
                        class="text-6xl"
                        v-if="currentBusinessStats != null"
                        >{{ currentBusinessStats.BusPendingOrders }}</span
                      >
                    </div>
                    {{ $t("dashboard.orderspending") }}
                  </div>
                  <div
                    class="col-2 align-self-center"
                    style="text-align: right"
                  >
                    <Button
                      icon="pi pi-angle-right"
                      class="p-button-rounded p-button-text p-button-lg"
                      @click.stop="
                        $router.push({ name: 'BusinessStoreOrders' })
                      "
                    />
                  </div>
                </div>
              </template>
            </Card>
            <Card
            v-if="currentBusiness.BusAcceptMessages == true"
              class="m-2 text-center shadow-2 flex-grow-1 pointer"
              @click.stop="$router.push({ name: 'BusinessMessages' })"
            >
              <template #content>
                <div class="grid">
                  <div class="col-10 align-self-center">
                    <div>
                      <i
                        class="pi pi-comments text-blue-700"
                        style="font-size: xx-large"
                      ></i
                      >&nbsp;<span
                        class="text-6xl"
                        v-if="currentBusinessStats != null"
                        >{{ currentBusinessStats.BusUnreadMessages }}</span
                      >
                    </div>
                    {{ $t("dashboard.unreadmessages") }}
                  </div>
                  <div
                    class="col-2 align-self-center"
                    style="text-align: right"
                  >
                    <Button
                      icon="pi pi-angle-right"
                      class="p-button-rounded p-button-text p-button-lg"
                      @click.stop="$router.push({ name: 'BusinessMessages' })"
                    />
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </Panel>
        <Panel class="panel-success mb-5">
          <template #header>
            <div class="flex-grow-1">
              <div
                class="
                  flex flex-wrap
                  align-items-center
                  justify-content-between
                "
              >
                <div class="text-xl">
                  <b> {{ $t("dashboard.analytics") }}</b>
                </div>
                <div>
                  <i v-if="softload" class="pi pi-spin pi-spinner"></i>
                  {{ $t("dashboard.last") }}
                  <Dropdown
                    v-model="days"
                    class="m-2"
                    :options="daysOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="$t('services.pleaseselect')"
                    @change="setSelectedBusiness()"
                  />
                  {{ $t("dashboard.days") }}
                </div>
              </div>
            </div>
          </template>

          <div class="flex flex-wrap align-items-center justify-content-center">
            <Card class="m-2 text-center shadow-2 flex-grow-1">
              <template #content>
                <div>
                  <i
                    class="pi pi-eye text-green-600"
                    style="font-size: xx-large"
                  ></i
                  >&nbsp;<span
                    class="text-6xl"
                    v-if="currentBusinessStats != null"
                    >{{ currentBusinessStats.BusViews }}</span
                  >
                </div>
             {{$t("dashboard.views")}}
              </template>
            </Card>
            <Card class="m-2 text-center shadow-2 flex-grow-1" v-if="currentBusiness.BusAcceptQuotes == true">
              <template #content>
                <div>
                  <i
                    class="pi pi-dollar text-green-600"
                    style="font-size: xx-large"
                  ></i
                  >&nbsp;<span
                    class="text-6xl"
                    v-if="currentBusinessStats != null"
                    >{{ currentBusinessStats.BusQuotes }}</span
                  >
                </div>
                {{$t("menu.quotes")}}
              </template>
            </Card>
            <!-- <Card class="m-2 text-center " >
           
           <template #content>
            <div> <i class="pi pi-dollar text-primary" style="font-size:xx-large"></i>&nbsp;<span class="text-6xl" v-if="currentBusinessStats!= null">{{ currentBusinessStats.BusReservs }}</span></div>
           Reservs
           </template>
       </Card> -->
            <Card class="m-2 text-center shadow-2 flex-grow-1"  v-if="currentBusiness.BusStoreEnabled == true">
              <template #content>
                <div>
                  <i
                    class="pi pi-shopping-cart text-green-600"
                    style="font-size: xx-large"
                  ></i
                  >&nbsp;<span
                    class="text-6xl"
                    v-if="currentBusinessStats != null"
                    >{{ currentBusinessStats.BusOrders }}</span
                  >
                </div>
                {{$t("store.storeorders")}}
              </template>
            </Card>
            <Card class="m-2 text-center shadow-2 flex-grow-1"  v-if="currentBusiness.BusAcceptPayment == true">
              <template #content>
                <div>
                  <i
                    class="pi pi-money-bill text-green-600"
                    style="font-size: xx-large"
                  ></i
                  >&nbsp;<span
                    class="text-6xl"
                    v-if="currentBusinessStats != null"
                    >{{ currentBusinessStats.BusPayments }}</span
                  >
                </div>
                {{$t("store.payments")}}
              </template>
            </Card>
          </div>
        </Panel>
      </Panel>
      <br />
    </div>
     <div v-else-if="currentBusiness.BusActive == false">
      <inactive-subscription
        :busId="currentBusiness.BusId"
      ></inactive-subscription>
    </div> 
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{ $t("message.error") }}</Message
      >
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
import BusStats from "../store/Models/BusStats";
import Business from "../store/Models/Business";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import InactiveSubscription from "../components/InactiveSubscription.vue";

export default {
  name: "BusinessDashboard",
  components: { LoadingOverlay,
    InactiveSubscription },
  data() {
    return {
      currentBusiness: {},
      currentBusinessStats: {},
      softload: false,
      loading: false,
      days: 7,
      daysOptions: [
        { label: "7", value: 7 },
        { label: "14", value: 14 },
        { label: "21", value: 21 },
        { label: "28", value: 28 },
        { label: "45", value: 45 },
        { label: "60", value: 60 },
        { label: "90", value: 90 },
      ],
    };
  },

  async mounted() {
    this.$log.debug("Business Dashboard Mounted");
    if (this.currentBusinessId != 0) {
      this.loading = true;
      this.setSelectedBusiness();
    }
  },
  methods: {
    ...mapActions([]),
    async setSelectedBusiness() {
      this.softload = true;
      BusStats.fetch(this.currentBusinessId, this.days).then((result) => {
        this.loading = false;
        this.softload = false;
        this.currentBusinessStats = result;
        this.$log.debug(this.currentBusinessStats);
       //console.log(this.currentBusinessStats);
      });
      this.currentBusiness = await Business.fetchBusinessProfileById(
        this.currentBusinessId
      );
    },
  },
  watch: {
    async currentBusinessId() {
      this.loading = true;
      this.setSelectedBusiness();
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
      currentCustomerId: (state) => state.currentCustomerId,
      currentCustomer: (state) => state.currentCustomer,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
    currentDayTime() {
      var today = new Date();
      var curHr = today.getHours();

      if (curHr < 12) {
        return (this.$t("dashboard.goodmorning"));
      } else if (curHr < 18) {
        return (this.$t("dashboard.goodafternoon"));
      } else {
        return (this.$t("dashboard.goodevening"));
      }
    },
  },
};
</script>
<style scoped>
.text-left {
  text-align: left;
}
</style>