
/* eslint-disable */
import { Model } from '@vuex-orm/core'
import OrderedService from "./OrderedService"
import Business from "./Business"
import Customer from "./Customer"
import TimeSlot from "./TimeSlot"
import StoreOrderComment from "./StoreOrderComment"
import OrderPayment from "./OrderPayment"

export default class StoreOrder extends Model {
  static entity = 'storeOrders'

  static primaryKey = 'OrderId'

  static fields() {
    return {
      OrderId: this.uid(),
      OrderNum: this.attr(null),
      BusId: this.attr(null),
      CustId: this.attr(null),
      SlotId: this.attr(null),
      OrderTotalCost: this.attr(null),
      OrderTotalPaid: this.attr(null),
      OrderTotalOwed: this.attr(null),
      OrderStatus: this.attr(null),
      OrderManual: this.attr(null),
      OrderSubmittedDate: this.attr(null),
      OrderApproved: this.attr(null),
      OrderApprovedBy: this.attr(null),
      OrderApprovedDate: this.attr(null),
      OrderCompleted: this.attr(null),
      OrderCompletedBy: this.attr(null),
      OrderCompletedDate: this.attr(null),
      OrderCanceled: this.attr(null),
      OrderCanceledBy: this.attr(null),
      OrderCanceledDate: this.attr(null),
      OrderRequestedCancel: this.attr(null),
      OrderRequestedCancelDate: this.attr(null),
      OrderSubTotal: this.attr(null),
      OrderDiscount: this.attr(null),
      OrderTax: this.attr(null),
      OrderLastUpdated: this.attr(null),
      OrderLastUpdatedBy: this.attr(null),

      //VIRTUAL
      Bus: this.belongsTo(Business, 'BusId'),
      Cust: this.belongsTo(Customer, 'CustId'),
      Slot: this.belongsTo(TimeSlot, 'SlotId'),
      OrderedServices: this.hasMany(OrderedService, 'OrderId'),
      StoreOrderComments: this.hasMany(StoreOrderComment, 'OrderId'),
      OrderPayments: this.hasMany(OrderPayment, 'OrderId'),

    }
  }
  static async createOrder(storeOrder, cardId) {

   //console.log("creating new purchase order")
    var data = { order: storeOrder, cardId: cardId };
    const result = await StoreOrder.api().post('/api/storeorders/',
      data).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if (result.response.status == 200) {
          //console.log("duplicate")
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }

  static async createManualStoreOrder(storeOrder) {
    //console.log("creating new manual storeorder request")
    const result = await StoreOrder.api().post('/api/storeorders/createmanualstoreorder',
      storeOrder).then((result) => {
        if (result.response.status == 200) {
          //console.log("saved")
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }

  static async processOrder(order) {
    //console.log("processing order")
     const result =  await StoreOrder.api().put('/api/storeorders/processorder/' + order.OrderId,
      order).then((result) => {
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }


  static async saveOverwrite(storeOrder) {
    //console.log("processing storeorder")
    const result = await StoreOrder.api().put('/api/storeorders/BusOverwrite/' + storeOrder.OrderId,
      storeOrder).then((result) => {
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if (result.response.status == 200) {
          //console.log("saved")
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }

  static async fetchByCustId(CustId) {
    StoreOrder.deleteAll();
   //console.log("fetch storeorders for " + CustId)
    var result = await StoreOrder.api().get('/api/storeorders/GetCustomerOrders/' + CustId);
   //console.log(result)
    return StoreOrder.query()
      .withAllRecursive()
      .where(storeorder => storeorder.CustId == CustId).orderBy('OrderId', 'desc').all();
  }

  static async fetchBySlotId(SlotId) {
    StoreOrder.deleteAll();
    //console.log("fetch storeorders for " + SlotId)
    var result = await StoreOrder.api().get('/api/storeorders/GetStoreOrdersBySlotId/' + SlotId);
    //console.log(result)
    return StoreOrder.query()
      .withAllRecursive()
      .where(storeorder => storeorder.SlotId == SlotId).orderBy('OrderId', 'desc').all();
  }

  static async fetchByBusId(BusId) {
    StoreOrder.deleteAll();
    //console.log("fetch storeorders for " + BusId)
    var result = await StoreOrder.api().get('/api/storeorders/GetBusinessStoreOrders/' + BusId);
    //console.log(result)
    return StoreOrder.query()
      .withAllRecursive()
      .where(storeorder => storeorder.BusId == BusId).orderBy('OrderId', 'desc').all();
  }

  static async pendingBusCount(busId) {
    //console.log("fetching requested count")
    const result = await StoreOrder.api().get('/api/storeorders/GetBusPendingCount/' + busId).then((result) => {
      //console.log(result);
      return result.response.data;
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }

  static async requestCancel(order) {
    //console.log("request cancel order for " + order.OrderId)
    var result = await StoreOrder.api().put('/api/storeorders/RequestCancelation/' + order.OrderId, order).then((result) => {
      if (result.response.status == 204) {
        //console.log("success")
        return 1;
      }
      if (result.response.status == 200) {
        //console.log("duplicate")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }


}

