// Component: ConvoDisplay

<template>
     <div class="grid">
       <div class="col-12 grid-nogutter">
          <div
            class="flex flex-wrap justify-content-center align-items-center text-center"
          >
            <img
              :src="imageHostingURL + data.Bus.BusLogo"
              :alt="data.Bus.BusName"
              width="100"
              class="mr-3"
            />
            <div>
              <span style="font-size: xx-large">
                {{ data.Bus.BusName }}
              </span><br>
            <span> <small> {{data.Bus.BusAddress}}
           {{data.Bus.BusCity}}, {{data.Bus.BusState}} {{data.Bus.BusZip}}</small></span><br>
              <span> <small>{{data.Bus.BusPhone}} </small></span>
             
            </div>
          </div>
          </div>
           <div class="col-12 grid-nogutter">
            </div>
       <div class="col-12 md:col-6 mb-2">
          <div style="text-align: left">
            <div style="background-color: #e9ecef">
              <span style="font-size: x-large"> $t("custreserv.custinfo")</span>
            </div>
            <b>{{ $t("custreserv.custname") }}</b> {{ $t("custreserv.custname") }}
            {{ data.Cust.CustLastName }}
            <br />
            <b>{{ $t("custreserv.custphone") }} </b>{{ data.Cust.CustPhone }}
            <br />
            <b>{{ $t("listmybusiness.address") }} </b>
            {{ data.Cust.CustAddress }}<br />
            {{ data.Cust.CustCity }},
            {{ data.Cust.CustState }}
            {{ data.Cust.CustZip }}
            <br />
            <b>{{ $t("listmybusiness.email") }}</b> {{ data.Cust.CustEmail }}
          </div>
        </div>
        <div class="col-12 md:col-6 mb-2">
          <div style="text-align: right">
            <div style="background-color: #e9ecef">
              <span style="font-size: x-large"> {{$t("custreserv.custprocess")}}</span>
            </div>
            <b>{{ $t("custreserv.custdatere") }} </b
            >{{ $formatDateTime(data.QuoteRequestDate) }}
            <br />
            <b>{{ $t("custreserv.custdatepro") }}</b
            ><span v-if="data.QuoteProcessedDate">{{
              $formatDateTime(data.QuoteProcessedDate)
            }}</span
            ><span v-else>{{ $t("custreserv.custdatenotpro") }}</span>
            <br />
            <b>{{ $t("custreserv.custproby") }} </b
            ><span v-if="data.QuoteProcessedBy">{{
              data.QuoteProcessedBy
            }}</span
            ><span v-else>{{ $t("custreserv.custdatenotpro") }}</span><br />
            <b>{{ $t("custreserv.custresponded") }} </b
            ><span v-if="data.QuoteResponseDate">{{
              $formatDateTime(data.QuoteResponseDate)
            }}</span
            ><span v-else>{{ $t("custreserv.custnotapp") }}</span>
          </div>
        </div>

      <div class="col-12" style="background-color: #e9ecef">
        <span style="font-size: x-large">{{ $t("custreserv.custevent") }}</span><br>
        
      </div>
      <div class="col-12">
      <template v-if="data.Slot != null">
         
          {{ $formatDateTimeToDate(data.Slot.SlotDate) }}<br />
          {{ $formatTime(data.Slot.SlotStartTime) }} -
          {{ $formatTime(data.Slot.SlotEndTime) }}
        </template>
      </div>
       <div class="col-12 mb-2" style="background-color: #e9ecef">
          <span style="font-size: x-large">{{ $t("services.services") }}</span>
       </div>
      <div class="col-4" style="background-color: #f8f9fa">
          <b>{{ $t("custreserv.custservice") }}</b>
        </div>
        <div class="col-3" style="background-color: #f8f9fa">
          <b>{{ $t("custreserv.custprice") }}</b>
        </div>
        <div class="col-2" style="background-color: #f8f9fa">
          <b>{{ $t("custreserv.custqty") }}</b>
        </div>
        <div class="col-3" style="background-color: #f8f9fa">
          <b>{{ $t("custreserv.custamount") }}</b>
        </div>

        <template
          v-for="serv in data.QuotedServices"
          :key="serv.QuoteServId"
        >
        
          <template v-if="serv.QuoteServOrigId == null && serv.QuoteServRemoved==false">
  
            <div class="col-4">
              <span style="vertical-align: -webkit-baseline-middle"
                >{{ serv.Serv.ServTitle }}
              </span>
            </div>
            <div class="col-3 flex flex-wrap justify-content-center align-items-center">
              <span style="vertical-align: -webkit-baseline-middle">
                ${{ serv.ServPrice }}
              </span>
              <span
                style="
                  vertical-align: -webkit-baseline-middle;
                  font-size: small;
                "
                v-if="serv.Serv.ServPriceCalculated == true"
              >
                &nbsp;each
              </span>
            </div>
            <div class="col-2"  style="vertical-align: middle">
              <span
                style="vertical-align: -webkit-baseline-middle"
                >{{ serv.QuoteServQty }}</span
              >
</div>
            <div class="col-3" >
              <span v-if="serv.QuoteServTotal != 0"
                ><b style="vertical-align: -webkit-baseline-middle"
                  >${{ serv.QuoteServTotal }}</b
                ></span
              >
            </div>
          </template>
        </template>

        <div class="col-12 mt-5 grid grid-nogutter justify-content-end">
          <div
            class="col-9"
            style="text-align: right; background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custsub") }} </b>
          </div>
          <div class="col-3">
            <span v-if="data.QuoteSubTotal != 0"
              ><b>${{ data.QuoteSubTotal }}</b></span
            >
          </div>
          <div
            v-if="data.QuoteDiscount != 0"
            class="col-9"
            style="text-align: right; background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custdis") }} </b>
          </div>
          <div v-if="data.QuoteDiscount != 0" class="col-3">
            <span
              ><b>${{ data.QuoteDiscount }}</b></span
            >
          </div>
          <div
            class="col-9"
            style="text-align: right; background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custsales") }} </b>
          </div>
          <div class="col-3">
            <span v-if="data.QuoteSubTotal != 0">
              <b>${{ data.QuoteTax }}</b></span
            >
          </div>
          <div
            class="col-9"
            style="text-align: right; background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custtotal") }} </b>
          </div>
          <div class="col-3">
            <span v-if="data.QuoteSubTotal != 0">
              <b>${{ data.QuoteTotal }}</b></span
            >
          </div>
        </div>
  

      <div class="col-12" style="background-color: #e9ecef">
        <span style="font-size: x-large">{{ $t("custreserv.custcomments") }} </span
        >
      </div>
      <div class="col-12">
        <div v-if="data.QuoteComments.length>0">
        <template
          v-for="comment in data.QuoteComments"
          :key="comment.CommentId"
        >
          <div class="card" style="text-align: left">
            <div class="flex justify-content-between">
              <span>
                {{ comment.Cust.CustFirstName }}
                {{ comment.Cust.CustLastName }}</span
              >
              <span> {{ $formatDateTime(comment.CommentDate) }}</span>
            </div>
            <p>
              <em>{{ comment.CommentText }}</em>
            </p>
          </div>
        </template>
        </div>
        <div v-else>
          {{$t('custreserv.nocomments')}}
        </div>
      </div>
    </div>
</template>

<script>
 

export default {
  name: "ReservationDetails",
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  mounted() {
    this.$log.debug("reserv details mounted");
    this.$log.debug(this.data);
      
  },
  methods: {
      
     
  },
  computed: {
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style scoped>
.p-confirm-popup {
  z-index: 2000;
}
</style>

