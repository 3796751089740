<template>
  <!-- <div class="col-12 md:col-8" style="color: white; font-size: small"> -->
  <div v-if="userLoggedIn == false">
    <Button
      :label="$t('menu.listmybusiness')"
      class="p-button-text grow"
      :style="'font-weight: bolder; color: ' + font + ' !important'"
      @click.stop="$router.push({ name: 'ListMyBusiness' })"
    />
    <Button
      :label="$t('menu.createpro')"
      class="p-button-text grow"
      :style="'font-weight: bolder; color: ' + font + ' !important'"
      @click.stop="SignIn"
    />
    <Button
      :label="$t('menu.login')"
      v-if="!loggingIn"
      class="p-button-text grow"
      :style="'font-weight: bolder; color: ' + font + ' !important'"
      @click.stop="SignIn"
    />
    <Button v-else class="p-button-text grow" icon="pi pi-spin pi-spinner" />

    <Button
      :label="otherLang"
      class="p-button-text grow"
      :style="'font-weight: bolder; color: ' + font + ' !important'"
      @click.stop="switchLanguage()"
    />
  </div>

  <div v-else :style="'font-weight: bolder; color: ' + font + ' !important'">
    <Button
      :label="$t('menu.listmybusiness')"
      class="p-button-text grow"
      :style="'font-weight: bolder; color: ' + font + ' !important'"
      @click.stop="$router.push({ name: 'ListMyBusiness' })"
    />
    <Button
      :label="otherLang"
      class="p-button-text grow"
      :style="'font-weight: bolder; color: ' + font + ' !important'"
      @click.stop="switchLanguage()"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "MRLogin",
  props: {
    font: { default: "#495057", type: String },
  },
  data() {
    return {
      otherLang: "",
      redirecturl: process.env.VUE_APP_MYRESERVS_URL,
    };
  },
  mounted() {
    this.setLang();
  },
  methods: {
    ...mapActions(["switchLang"]),
    async SignIn() {
      this.$log.debug(this.$route.path);
      var returnTo = this.$route.path
      window.sessionStorage.setItem("redirecturl",returnTo);
      window.sessionStorage.setItem("requestedlogin","true");
      this.$router.push({
        name: "Login"
      });
    },

    setLang() {
      this.$log.debug(this.lang);
      if (this.lang == "en") {
        this.otherLang = "Español";
        this.$i18n.locale = "en";
      }
      if (this.lang == "es") {
        this.otherLang = "English";
        this.$i18n.locale = "es";
      }
      document.getElementsByTagName("html")[0].lang = this.lang;
    },
    switchLanguage() {
      var newLang = "en";
      if (this.lang == "en") {
        newLang = "es";
        this.otherLang = "English";
      } else if (this.lang == "es") {
        newLang = "en";
        this.otherLang = "Español";
      }

      this.switchLang(newLang);
      this.$i18n.locale = newLang;
    },
  },
  watch: {
    lang() {
      this.$log.debug("lang changed");
      this.setLang();
    },
  },
  computed: mapState({
    userLoggedIn: (state) => state.userLoggedIn,
    lang: (state) => state.lang,
    loggingIn: (state) => state.loggingIn,
  }),
};
</script>

<style scoped>
</style>