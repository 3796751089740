import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Business from "./Business"
import Customer from "./Customer"

export default class BusinessCustomer extends Model {
  static entity = 'businessCustomers'
  
  static primaryKey = 'BusCustId'
  
  static fields () {
    return {
      BusCustId: this.uid(),
      BusId: this.attr(null),
      CustId: this.attr(null),
      BusCustSince: this.attr(null),
      BusCustQuoteCount: this.attr(null),
      BusCustReservCount: this.attr(null),

      //virtual
      Bus: this.belongsTo(Business, 'BusId'),
      Cust: this.belongsTo(Customer, 'CustId')
    }
  }

  static async fetchByBusId(BusId) {
    //console.log("fetch business customers for " + BusId)
   var result = await BusinessCustomer.api().get('/api/businesscustomers/GetBusCustomers/' + BusId);
    //console.log(result)
    return  BusinessCustomer.query()
      .withAllRecursive()
      .where(bc=>bc.BusId == BusId).orderBy('BusCustId', 'desc').all();
  }
}

