import { Model } from '@vuex-orm/core'
import Customer from "./Customer"

export default class BusinessReservTerm extends Model {
  static entity = 'businessReservTerms'
  
  static primaryKey = 'TermsId'
  
  static fields () {
    return {
      TermsId: this.uid(),
      BusId: this.attr(null),
      TermsValid: this.attr(null),
      TermsValidFrom: this.attr(null),
      TermsValidUntil: this.attr(null),
      TermsText: this.attr(null),
      CustId: this.attr(null),

      Cust: this.belongsTo(Customer, 'CustId'),  
    }
  }
  static async fetchAllByBusId(BusId) {
   //console.log("fetch  reserv terms for bus " + BusId)
    var result = await BusinessReservTerm.api().get('/api/BusinessReservTerms/GetAllBusinessReservTerm/' + BusId);
   //console.log(result)
    return BusinessReservTerm.query()
      .withAllRecursive()
      .where(terms => terms.BusId == BusId).orderBy('TermsId', 'desc').all();
  }

  static async fetchValidByBusId(BusId) {
   //console.log("fetch  valid reserv terms for bus " + BusId)
    var result = await BusinessReservTerm.api().get('/api/BusinessReservTerms/GetBusinessValidReservTerm/' + BusId);
   //console.log(result)
    return BusinessReservTerm.query()
      .withAllRecursive()
      .find(result.response.data.TermsId);
  }
  
  static async createTerms(terms) {
   //console.log("creating new terms")
     const result =  await BusinessReservTerm.api().post('/api/BusinessReservTerms/',
     terms).then((result) => {
      //console.log(result);
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
       //console.log(error.response);
        return 2;
      });
      return result;
  }

}

