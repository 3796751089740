<template>
  <div>
    <Card style="min-width: 350px">
      <template #content>
        <input
          id="cardholder-name"
          class="mb-2"
          type="text"
          :placeholder="$t('custreserv.card')"
        />
        <div class="mb-2" ref="card"></div>
        <div id="card-result" class="mb-2"></div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "../store/Models/Card";

// eslint-disable-next-line
let stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY),
  elements = stripe.elements(),
  card = undefined;
export default {
  components: {},
  data() {
    this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
    return {
      loading: false,
      newCardId: 0
    };
  },
  async mounted() {
    this.$log.debug("stripe card mounted");
    card = elements.create("card", {
      style: {
        base: {
          iconColor: "#0d89ec",
          color: "#495057",
          fontWeight: "500",
          fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#495057",
          },
        },
        invalid: {
          iconColor: "#d35278",
          color: "#495057",
        },
      },
    });
    card.mount(this.$refs.card);
    card.focus();
  },
  unmounted(){
    this.$log.debug("unmount")
    card.destroy();
  },
  methods: {
    async createCard() {
      this.$log.debug("stripe card create")
      var cardholderName = document.getElementById("cardholder-name");
      var resultContainer = document.getElementById("card-result");
      var paymentMethodId = "";

      /* eslint-disable*/
     await stripe
        .createPaymentMethod({
          type: "card",
          card: card,
          billing_details: { name: cardholderName.value },
        })
        .then(async (result) => { 
          if (result.error) {
            // Display error.message in your UI
            resultContainer.textContent = result.error.message;
          } else {
            paymentMethodId = result.paymentMethod.id;
            await Card.createCard(paymentMethodId).then(async (response) => {
              this.$log.debug(response)
              if (response != null) {
                this.addingCard = false;
                this.newCardId = response;
              } 
            });
          }
        });
      /*esling-enable*/
        this.$log.debug(this.newCardId)
            return this.newCardId;
    },
  },
};
</script>