import { Model } from '@vuex-orm/core'
/* eslint-disable */


export default class TimeSlot extends Model {
  static entity = 'timeSlots'
  
  static primaryKey = 'SlotId'
  
  static fields () {
    return {
      SlotId: this.uid(),
      SlotDate: this.attr(null),
      SlotStartTime: this.attr(null),
      SlotEndTime: this.attr(null),
      SlotVisible: this.attr(null),
      SlotAvailable: this.attr(null),
      SlotRequested: this.attr(null), 
      BusId: this.attr(null),
      SchId: this.attr(null),
      TypeCode: this.attr(null),
      
    }
  }

  static async getTimeslot(slotId) {
    //console.log("fetch slot " + slotId);
    await TimeSlot.api().get('/api/TimeSlots/' + slotId);
    return TimeSlot.find(slotId);
  }

  
  static async fetchBySchId(schId) {
    //console.log("fetch slots " + schId);
    TimeSlot.delete((ts) =>{ return ts.SchId == schId});
    var result = await TimeSlot.api().get('/api/TimeSlots/getTimeSlotsBySchId/' + schId);
   //console.log(result)
    return TimeSlot.query()
      .withAllRecursive()
      .where(ts=>ts.SchId == schId).all();
  }



  static async createTimeSlot(timeslot) {
    //console.log("creating new timeslot")
     const result =  await TimeSlot.api().post('/api/TimeSlots/',
     timeslot).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }

  static async updateTimeslot(timeslot) {
    timeslot.SlotAvailable = timeslot.NewSlotAvailable;
    const result =  await TimeSlot.api().put('/api/TimeSlots/' + timeslot.SlotId, timeslot)
    .then((result) => {
      //console.log(result);
       if (result.response.status == 204) {
         //console.log("success")
         return 1;
       }
       else{
         //console.log("error");
         return 2;
       } 
     }).catch(error => {
       //console.log(error.response);
       return 2;
     });
     return result;
 }

  static async deleteTimeslot(slotId) {
    const result =  await TimeSlot.api().delete('/api/TimeSlots/' + slotId)
    .then((result) => {
      //console.log(result);
       if (result.response.status == 204) {
         //console.log("success")
         TimeSlot.delete(slotId);
         return 1;
       }
       else{
         //console.log("error");
         return 2;
       } 
     }).catch(error => {
       //console.log(error.response);
       return 2;
     });
     return result;
 }
}

