import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import BusinessProfile from '../views/BusinessProfile.vue'
import BusinessReviews from '../views/BusinessReviews.vue'
import BusinessCalendar from '../views/BusinessCalendar.vue'
import BusinessReservations from '../views/BusinessReservations.vue'
import BusinessServices from '../views/BusinessServices.vue'
import CustomerMessages from '../views/CustomerMessages.vue'
import Subscription from '../views/Subscription.vue'
import CustomerReservations from '../views/CustomerReservations.vue'
import CustomerFavorites from '../views/CustomerFavorites.vue'
import CustomerQuotes from '../views/CustomerQuotes.vue'
//import SearchResults from '../views/SearchResults.vue'
import BusinessClients from '../views/BusinessClients.vue'
import ListMyBusiness from '../views/ListMyBusiness.vue'
import BusinessDashboard from '../views/BusinessDashboard.vue'
import BusinessQuotes from '../views/BusinessQuotes.vue'
import BusinessPayments from '../views/BusinessPayments.vue'
import ReservsClients from '../views/ReservsClients.vue'
import BusinessMessages from '../views/BusinessMessages.vue'
import BusinessStaff from '../views/BusinessStaff.vue'
import CustomerProfile from '../views/CustomerProfile.vue'
import BusinessSubscriptions from '../views/BusinessSubscriptions.vue'
import BusinessSubscription from '../views/BusinessSubscription.vue'
import CustomerPaymentCards from '../views/CustomerPaymentCards.vue'
import BusinessSettings from '../views/BusinessSettings.vue'
import BusinessPage from '../views/BusinessPage.vue'
import FunctionalityBusiness from '../views/FunctionalityBusiness.vue'
import FunctionalityCustomer from '../views/FunctionalityCustomer.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import ComingSoon from '../views/ComingSoon.vue'
import FAQ from '../views/FAQ.vue'
import ContactUs from '../views/ContactUs.vue'
import CustomerAppointments from '../views/CustomerAppointments.vue'
import BusinessAppointments from '../views/BusinessAppointments.vue'
import CustomerStoreOrders from '../views/CustomerStoreOrders.vue'
import BusinessStoreOrders from '../views/BusinessStoreOrders.vue'
import Login from '../views/Login.vue'
import LoggedIn from '../views/LoggedIn.vue'
import store from "../store";
import CustomerGuestList from '../views/CustomerGuestList.vue'
import CustomerHome from '../views/CustomerHome.vue'
import CustomerEvents from '../views/CustomerEvents.vue'
import CustomerInvitationPage from '../views/CustomerInvitationPage.vue'


const routes = [
   
  //  {
  //    path: '/',
  //    name: 'Home',
  //    component: Home,
  //    meta: { guest: true },
  //  },
  //{
   // path: '/',
   // name: 'Home',
   // component: ListMyBusiness,
   // meta: { guest: true }
  //},
    {
   path: '/',
   name: 'Home',
   component: CustomerHome,
   meta: { guest: true }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: { guest: true },
    props: true,
    children: [
      {
        path: '/Login/:redirecturl/:requestedlogin',
        name: 'Login',
        component: Login,
        meta: { guest: true },
        props: true,
      }
    ]


  },
  // {
  //   path: '/Search', //TODO FIX PROPS MAYBE USE QUERY INSTEAD
  //   name: 'Search',
  //   component: SearchResults,
  //   //props: true,
  //   meta: { guest: true },
  // },
  // {    name: 'SearchResults', path: '/SearchResults/searchPhrase/:searchPhrase?/locations/:locations+/BusCategory/:BusCategory*/EventTypes/:EventTypes*', component: SearchResults, props: true },
  
  {
    path: '/:busHandle',
    component: BusinessPage,
    meta: { guest: true },
    props: true
  },
  {
    path: '/Invitation/:eventId',
    component: CustomerInvitationPage,
    meta: { guest: true },
    props: true
  },
  {
    path: '/LoggedIn',
    name: 'LoggedIn',
    component: LoggedIn,
    meta: { guest: true }
  },
  {
    path: '/ListMyBusiness',
    name: 'ListMyBusiness',
    component: ListMyBusiness,
    meta: { guest: true },
  },
  {
    name: 'RequestedLogin',
    path: '/ListMyBusiness/:requestedLoginProp',
    component: ListMyBusiness,
    meta: { guest: true },
    props: true
  },
  {
    name: 'RequestedGetStarted',
    path: '/ListMyBusiness/:requestedGetStarted',
    component: ListMyBusiness,
    meta: { guest: true },
    props: true
  },

  {
    path: '/BusinessProfile',
    name: 'BusinessProfile',
    component: BusinessProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/BusinessReviews',
    name: 'BusinessReviews',
    component: BusinessReviews,
    meta: { requiresAuth: true },
  },
  {
    path: '/BusinessCalendar',
    name: 'BusinessCalendar',
    component: BusinessCalendar,
    meta: { requiresAuth: true },
  },
  {
    path: '/BusinessPayments',
    name: 'BusinessPayments',
    component: BusinessPayments,
    meta: { requiresAuth: true },
    props: true,
    children: [
      { path: '/BusinessPayments/bus/:busId/pay/:payId?', component: BusinessPayments, props: true },
    ]
  },
  {
    path: '/BusinessStaff',
    name: 'BusinessStaff',
    component: BusinessStaff,
    meta: { requiresAuth: true },
  },
  {
    path: '/BusinessMessages',
    name: 'BusinessMessages',
    component: BusinessMessages,
    meta: { requiresAuth: true },
  },
  {
    path: '/BusinessAppointments',
    name: 'BusinessAppointments',
    component: BusinessAppointments,
    meta: { requiresAuth: true },
  },
  {
    path: '/BusinessReservations',
    name: 'BusinessReservations',
    component: BusinessReservations,
    meta: { requiresAuth: true },
    props: true,
   
  },
  { path: '/BusinessReservations/bus/:busId/reserv/:reservId?', name:'BusinessReservation',  component: BusinessReservations, props: true },
  {
    path: '/BusinessQuotes',
    name: 'BusinessQuotes',
    component: BusinessQuotes,
    meta: { requiresAuth: true },
    props: true,
    children: [
    
    ]
  },
  { path: '/BusinessQuotes/bus/:busId/quote/:quoteId', name:'BusinessQuote', component: BusinessQuotes, props: true },
  {
    path: '/BusinessStoreOrders',
    name: 'BusinessStoreOrders',
    component: BusinessStoreOrders,
    meta: { requiresAuth: true },
    props: true,
  },
  { path: '/BusinessStoreOrders/bus/:busId/order/:orderId', name:'BusinessStoreOrder', component: BusinessStoreOrders, props: true },
  {
    path: '/BusinessServices',
    name: 'BusinessServices',
    component: BusinessServices,
    meta: { requiresAuth: true },
  },
  {
    path: '/CustomerMessages',
    name: 'CustomerMessages',
    component: CustomerMessages,
    meta: { requiresAuth: true },
  },
  {
    path: '/CustomerGuestList',
    name: 'CustomerGuestList',
    component: CustomerGuestList,
    meta: { requiresAuth: true },
  },
  {
    path: '/CustomerEvents',
    name: 'CustomerEvents',
    component: CustomerEvents,
    meta: { requiresAuth: true },
  },
  {
    path: '/Subscription',
    name: 'MRSubscription',
    component: Subscription,
    meta: { requiresAuth: true },
  },
  {
    path: '/CustomerDashboard',
    name: 'CustomerHome',
    component: CustomerHome,
    meta: { requiresAuth: false },
  },
  {
    path: '/CustomerReservations',
    name: 'CustomerReservations',
    component: CustomerReservations,
    meta: { requiresAuth: true },
  },
  {
    path: '/CustomerAppointments',
    name: 'CustomerAppointments',
    component: CustomerAppointments,
    meta: { requiresAuth: true },
  },
  {
    path: '/CustomerStoreOrders',
    name: 'CustomerStoreOrders',
    component: CustomerStoreOrders,
    meta: { requiresAuth: true },
    children: [
      { path: '/CustomerStoreOrders/:orderId', component: CustomerStoreOrders, props: true },
    ]
  },
  {
    path: '/CustomerQuotes',
    name: 'CustomerQuotes',
    component: CustomerQuotes,
    meta: { requiresAuth: true },
    children: [
      { path: '/CustomerQuotes/:quoteId', component: CustomerQuotes, props: true },
    ]
  },
  {
    path: '/CustomerFavorites',
    name: 'CustomerFavorites',
    component: CustomerFavorites,
    meta: { requiresAuth: true },
  },
  {
    path: '/FunctionalityBusiness',
    name: 'FunctionalityBusiness',
    component: FunctionalityBusiness,
    meta: { guest: true },
  },
  {
    path: '/FunctionalityCustomer',
    name: 'FunctionalityCustomer',
    component: FunctionalityCustomer,
    meta: { guest: true },
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: { guest: true },
  },
  {
    path: '/TermsAndConditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    meta: { guest: true },
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ,
    meta: { guest: true },
  },
  {
    path: '/ComingSoon',
    name: 'ComingSoon',
    component: ComingSoon,
    meta: { guest: true },
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs,
    meta: { guest: true },
  },
{
    path: '/BusinessClients',
    name: 'BusinessClients',
    component: BusinessClients,
    meta: { requiresAuth: true },
  },
  {
    path: '/BusinessDashboard',
    name: 'BusinessDashboard',
    component: BusinessDashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/BusinessSubscriptions',
    name: 'BusinessSubscriptions',
    component: BusinessSubscriptions,
    meta: { requiresAuth: true },
  },
  {
    path: '/BusinessSubscription',
    name: 'BusinessSubscription',
    component: BusinessSubscription,
    meta: { requiresAuth: true },
  },
  {
    path: '/BusinessSettings',
    name: 'BusinessSettings',
    component: BusinessSettings,
    meta: { requiresAuth: true },
  },
  {
    path: '/ReservsClients',
    name: 'ReservsClients',
    component: ReservsClients,
    meta: { requiresAuth: true },
  },
  {
    path: '/CustomerProfile',
    name: 'CustomerProfile',
    component: CustomerProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/CustomerPaymentCards',
    name: 'CustomerPaymentCards',
    component: CustomerPaymentCards,
    meta: { requiresAuth: true },
  },
  {
    path: '/about',
    name: 'MRAbout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { guest: true },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    return { left: 0, top: 0 };
    // }
  }
})



router.beforeEach(async (to, from, next) => {
 //console.log("Router Before Each")
 //console.log(to)

 //console.log(to.meta.requiresAuth)
  document.getElementById('app').scrollIntoView()
  //store || to.fullPath.toUpperCase().startsWith("/SEARCHRESULTS")
  //PROD COMING SOON
  if (to.fullPath == "/" || to.fullPath.toUpperCase().startsWith("/SEARCH")) {
    // next('/ListMyBusiness');
    next('/CustomerDashboard');
    return;
  }

  //Dev & Test

  //Remove when releasing search engine TODO  
  //  if (to.fullPath.startsWith("/#state=")) {
  //    to.params.requestedLoginProp = true;
  //    next();
  //    return;
  //    }

  //  if (to.fullPath.startsWith("/ListMyBusiness#state=")) {
  //    to.params.requestedLoginProp = true;
  //    next();
  //    return;
  //    }


  var authNeeded = to.matched.some(record => record.meta.requiresAuth)
 //console.log("Authentication Needed " + authNeeded)
  if (authNeeded) {
    //if (to.path != '/CustomerProfile') {
    var authenticated = false;
    // var missingProfile = true;
    await store.dispatch("isUserLoggedIn").then(response => {
     //console.log(response);
      authenticated = response.userLoggedIn;
      //missingProfile = response.userMissingProfile;
    });
   //console.log('logged in? ' + authenticated)
    window.sessionStorage.setItem("redirecturl", process.env.VUE_APP_MYRESERVS_URL.substring(0,process.env.VUE_APP_MYRESERVS_URL-1) + to.fullPath);
   //console.log(window.sessionStorage.getItem("redirecturl"));
    window.sessionStorage.setItem("requestedlogin", "true");
    if (authenticated == false) {
      next({

        name: "Login"
      });
      return;
    }
    // if (authenticated == true && missingProfile == false) {
    //   next()
    //   return
    // }
    // else if (authenticated == true && missingProfile == true) {
    //   next('/CustomerProfile')
    // }
    // else {
    //   store.dispatch("setUserLoggedOut")
    //   next('/')
    // }
    //}
    // else {
    //   next()
    //   return
    // }
  }
  else {
    next()
    return;
  }
  next()
  return;
  //////
})


export default router