<template>
  <div id="navigation">
    <!-- Medium/Large Screen -->
    <div class="hidden lg:inline-flex">
      <!-- Full Menu -->
      <div v-if="!collapsedMenu" class="flex flex-column align-items-center">
        <Button
          icon="pi pi-angle-double-left"
          class="p-button-rounded p-button-text p-button-plain ml-auto"
          @click.stop="collapsedMenu = true"
        />
        <div class="mb-3" v-if="selectedBusiness != null">
          <img
            :src="imageHostingURL + selectedBusiness.BusLogo"
            :alt="selectedBusiness.BusName"
            width="100"
          />
          <br />
          <Dropdown
            class="m-2"
            style="max-width: 170px; text-align: left"
            v-model="selectedBusiness.BusId"
            :options="customerBusinesses"
            optionLabel="BusName"
            optionValue="BusId"
            :placeholder="$t('businessprofile.selectbus')"
            @change="updateCurrentBusiness($event)"
          /><br />
          <Chip
            v-if="businessOwner == true"
            :label="$t('businessprofile.owner')"
            class="m-2 primary-chip chip-sm"
          />
          <Chip
            v-if="businessOwner == false"
            :label="$t('businessprofile.staffmember')"
            class="m-2 primary-chip chip-sm"
          />
        </div>

        <div class="flex flex-column p-1">
          <Button
            :label="$t('menu.dashboard')"
            icon="pi pi-briefcase"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'dash' }"
            @click.stop="navigate('BusinessDashboard', 'dash')"
          />

          <Button
            :label="$t('businessprofile.profile')"
            icon="pi pi-id-card"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'prof' }"
            @click.stop="navigate('BusinessProfile', 'prof')"
          />

          <Button
            :label="$t('businessprofile.packages')"
            icon="pi pi-table"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'serv' }"
            @click.stop="navigate('BusinessServices', 'serv')"
          />
          <Button
            :label="$t('businessprofile.calendar')"
            icon="pi pi-calendar"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'cal' }"
            @click.stop="navigate('BusinessCalendar', 'cal')"
          />
          <Button
            :label="$t('businessprofile.reservations')"
            icon="pi pi-ticket"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'reserv' }"
            @click.stop="navigate('BusinessReservations', 'reserv')"
          />
          <Button
            v-if="pendingQuoteCount > 0"
            :badge="pendingQuoteCount"
            :label="$t('businessprofile.quotes')"
            icon="pi pi-dollar"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'quotes' }"
            @click.stop="
              pendingQuoteCount = 0;
              navigate('BusinessQuotes', 'quotes');
            "
          />
          <Button
            v-else
            :label="$t('businessprofile.quotes')"
            icon="pi pi-dollar"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'quotes' }"
            @click.stop="
              pendingQuoteCount = 0;
              navigate('BusinessQuotes', 'quotes');
            "
          />
          <Button
            v-if="pendingStoreCount > 0"
            :badge="pendingStoreCount"
            :label="$t('store.storeorders')"
            icon="pi pi-shopping-cart"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'orders' }"
            @click.stop="
              pendingStoreCount = 0;
              navigate('BusinessStoreOrders', 'orders');
            "
          />
          <Button
            v-else
            :label="$t('store.storeorders')"
            icon="pi pi-shopping-cart"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'orders' }"
            @click.stop="
              pendingStoreCount = 0;
              navigate('BusinessStoreOrders', 'orders');
            "
          />
          <Button
            v-if="businessOwner == true"
            :label="$t('businessprofile.payments')"
            icon="pi pi-money-bill"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'pmt' }"
            @click.stop="navigate('BusinessPayments', 'pmt')"
          />
          <Button
            :label="$t('menu.appts')"
            icon="pi pi-calendar-plus"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'appts' }"
            @click.stop="navigate('BusinessAppointments', 'appts')"
          />
          <Button
            :label="$t('businessprofile.reviews')"
            icon="pi pi-star-fill"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'rev' }"
            @click.stop="navigate('BusinessReviews', 'rev')"
          />
          <Button
            v-if="unreadMessageCount > 0"
            :badge="unreadMessageCount"
            :label="$t('businessprofile.messages')"
            icon="pi pi-comments"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'mess' }"
            @click.stop="
              unreadMessageCount = 0;
              navigate('BusinessMessages', 'mess');
            "
          />
          <Button
            v-else
            :label="$t('businessprofile.messages')"
            icon="pi pi-comments"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'mess' }"
            @click.stop="
              unreadMessageCount = 0;
              navigate('BusinessMessages', 'mess');
            "
          />
          <Button
            :label="$t('businessprofile.clients')"
            icon="pi pi-users"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'clients' }"
            @click.stop="navigate('BusinessClients', 'clients')"
          />
          <Button
            v-if="businessOwner == true"
            :label="$t('businessprofile.staff')"
            icon="pi pi-th-large"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'staff' }"
            @click.stop="navigate('BusinessStaff', 'staff')"
          />
          <Button
            v-if="businessOwner == true"
            :label="$t('businessprofile.settings')"
            icon="pi pi-cog"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'settings' }"
            @click.stop="navigate('BusinessSettings', 'settings')"
          />
          <Button
          v-if="businessOwner == true"
            :label="$t('subscriptions.subscription')"
            icon="pi pi-info"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'subs' }"
            @click.stop="navigate('BusinessSubscription', 'subs')"
          />
        </div>
      </div>
      <!-- Collapsed Menu -->
      <div v-if="collapsedMenu" class="flex flex-column align-items-center">
        <Button
          icon="pi pi-angle-double-right"
          class="p-button-rounded p-button-text p-button-plain"
          @click.stop="collapsedMenu = false"
        />
        <div class="mb-3" style="text-align: center ; padding: 10px 10px 10px 10px;">
          <img
            v-if="selectedBusiness != null && selectedBusiness != null"
            :src="imageHostingURL + selectedBusiness.BusLogo"
            :alt="selectedBusiness.BusName"
            v-tooltip="selectedBusiness.BusName"
            width="50"
          />
          <br />
        </div>

        <div class="flex flex-column p-1 align-items-center">
          <Button
            icon="pi pi-briefcase"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('menu.dashboard')"
            :class="{ active: activeMenuId === 'dash' }"
            @click.stop="navigate('BusinessDashboard', 'dash')"
          />
          <Button
            icon="pi pi-id-card"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('businessprofile.profile')"
            :class="{ active: activeMenuId === 'prof' }"
            @click.stop="navigate('BusinessProfile', 'prof')"
          />

          <Button
            icon="pi pi-table"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'serv' }"
            v-tooltip="$t('businessprofile.packages')"
            @click.stop="navigate('BusinessServices', 'serv')"
          />
          <Button
            icon="pi pi-calendar"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('businessprofile.calendar')"
            :class="{ active: activeMenuId === 'cal' }"
            @click.stop="navigate('BusinessCalendar', 'cal')"
          />
          <Button
            icon="pi pi-ticket"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('businessprofile.reservations')"
            :class="{ active: activeMenuId === 'reserv' }"
            @click.stop="navigate('BusinessReservations', 'reserv')"
          />
          <Button
            v-if="pendingQuoteCount > 0"
            v-badge.danger
            icon="pi pi-dollar"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('businessprofile.quotes')"
            :class="{ active: activeMenuId === 'quotes' }"
            @click.stop="
              pendingQuoteCount = 0;
              navigate('BusinessQuotes', 'quotes');
            "
          />
      <Button
            v-else
            icon="pi pi-dollar"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('businessprofile.quotes')"
            :class="{ active: activeMenuId === 'quotes' }"
            @click.stop="
              pendingQuoteCount = 0;
              navigate('BusinessQuotes', 'quotes');
            "
          />
           <Button
            v-if="pendingStoreCount > 0"
            v-badge.danger
            icon="pi pi-shopping-cart"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('store.storeorders')"
            :class="{ active: activeMenuId === 'orders' }"
            @click.stop="
              pendingStoreCount = 0;
              navigate('BusinessStoreOrders', 'orders');
            "
          />
              <Button
            v-else
            icon="pi pi-shopping-cart"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('store.storeorders')"
            :class="{ active: activeMenuId === 'orders' }"
            @click.stop="
              pendingStoreCount = 0;
              navigate('BusiessStoreOrders', 'orders');
            "
          />
          
          <Button
            v-if="businessOwner == true"
            icon="pi pi-money-bill"
            v-tooltip="$t('businessprofile.payments')"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'pmt' }"
            @click.stop="navigate('BusinessPayments', 'pmt')"
          />
          <Button
            icon="pi pi-calendar-plus"
            v-tooltip="$t('menu.appts')"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'appts' }"
            @click.stop="navigate('BusinessAppointments', 'appts')"
          />
          <Button
            icon="pi pi-star-fill"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('businessprofile.reviews')"
            :class="{ active: activeMenuId === 'rev' }"
            @click.stop="navigate('BusinessReviews', 'rev')"
          />
          <Button
            v-if="unreadMessageCount > 0"
            v-badge.danger
            icon="pi pi-comments"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('businessprofile.messages')"
            :class="{ active: activeMenuId === 'mess' }"
            @click.stop="
              unreadMessageCount = 0;
              navigate('BusinessMessages', 'mess');
            "
          />
          <Button
            v-else
            icon="pi pi-comments"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('businessprofile.messages')"
            :class="{ active: activeMenuId === 'mess' }"
            @click.stop="
              unreadMessageCount = 0;
              navigate('BusinessMessages', 'mess');
            "
          />
          <Button
            icon="pi pi-users"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('businessprofile.clients')"
            :class="{ active: activeMenuId === 'clients' }"
            @click.stop="navigate('BusinessClients', 'clients')"
          />
          <Button
            v-if="businessOwner == true"
            icon="pi pi-th-large"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('businessprofile.staff')"
            :class="{ active: activeMenuId === 'staff' }"
            @click.stop="navigate('BusinessStaff', 'staff')"
          />
          <Button
            v-if="businessOwner == true"
            icon="pi pi-cog"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            v-tooltip="$t('businessprofile.settings')"
            :class="{ active: activeMenuId === 'settings' }"
            @click.stop="navigate('BusinessSettings', 'settings')"
          />
          <Button
            v-if="businessOwner == true"
            v-tooltip="$t('subscriptions.subscription')"
            icon="pi pi-info"
            class="
              p-button-text p-button-secondary
              text-left
              xlarge-icon
              menu-button
            "
            :class="{ active: activeMenuId === 'subs' }"
            @click.stop="navigate('BusinessSubscription', 'subs')"
          />
        </div>
      </div>
    </div>

    <!-- Phone Screen -->
    <div v-if="showBar">
      <!-- class="lg:hidden" -->
      <div
        class="mb-3"
        style="text-align: center"
        v-if="selectedBusiness != null"
      >
        <div class="flex justify-content-center" >
          <img
            v-if="selectedBusiness.BusLogo"
            :src="imageHostingURL + selectedBusiness.BusLogo"
            :alt="selectedBusiness.BusName"
            width="40"
            class="mr-2"
          />
          <Skeleton v-else size="2rem"></Skeleton>
          <Dropdown
         
          style="width: 100%; text-align: left"
          v-model="selectedBusiness.BusId"
          :options="customerBusinesses"
          optionLabel="BusName"
          optionValue="BusId"
          :placeholder="$t('businessprofile.selectbus')"
          @change="updateCurrentBusiness($event)"
        />
        </div>
        <Chip
          v-if="businessOwner == true"
          :label="$t('businessprofile.owner')"
          class="m-2 primary-chip chip-sm"
        />
        <Chip
          v-if="businessOwner == false"
          :label="$t('businessprofile.staffmember')"
          class="m-2 primary-chip chip-sm"
        />
      </div>
      <div
        class="flex justify-content-center flex-wrap mt-2"
        style="text-align: center"
      >
      <div
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'dash' }"
          @click.stop="navigate('BusinessDashboard', 'dash')"
        >
          <i
            class="pi pi-briefcase"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("menu.dashboard") }}
        </div>
        <div
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'prof' }"
          @click.stop="navigate('BusinessProfile', 'prof')"
        >
          <i
            class="pi pi-id-card"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("businessprofile.profile") }}
        </div>
        <div
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'serv' }"
          @click.stop="navigate('BusinessServices', 'serv')"
        >
          <i class="pi pi-table" style="font-size: x-large; display: block"></i
          >{{ $t("businessprofile.packages") }}
        </div>
        <div
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'cal' }"
          @click.stop="navigate('BusinessCalendar', 'cal')"
        >
          <i
            class="pi pi-calendar"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("businessprofile.calendar") }}
        </div>
        <div
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'reserv' }"
          @click.stop="navigate('BusinessReservations', 'reserv')"
        >
          <i class="pi pi-ticket" style="font-size: x-large; display: block"></i
          >{{ $t("businessprofile.reservations") }}
        </div>
        <div
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'quotes' }"
          @click.stop="
            pendingQuoteCount = 0;
            navigate('BusinessQuotes', 'quotes');
          "
        >
          <i
            v-if="pendingQuoteCount > 0"
            v-badge="pendingQuoteCount"
            class="pi pi-dollar"
            style="font-size: x-large; display: block"
          ></i>
          <i
            v-else
            class="pi pi-dollar"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("businessprofile.quotes") }}
        </div>
        <div
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'orders' }"
          @click.stop="
            pendingStoreCount = 0;
            navigate('BusinessStoreOrders', 'orders');
          "
        >
          <i
            v-if="pendingStoreCount > 0"
            v-badge="pendingStoreCount"
            class="pi pi-shopping-cart"
            style="font-size: x-large; display: block"
          ></i>
          <i
            v-else
            class="pi pi-shopping-cart"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("store.storeorders") }}
        </div>
        <div
          v-if="businessOwner == true"
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'pmt' }"
          @click.stop="navigate('BusinessPayments', 'pmt')"
        >
          <i
            class="pi pi-money-bill"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("businessprofile.payments") }}
        </div>
        <div
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'appts' }"
          @click.stop="navigate('BusinessAppointments', 'appts')"
        >
          <i
            class="pi pi-calendar-plus"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("menu.appts") }}
        </div>
        <div
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'rev' }"
          @click.stop="navigate('BusinessReviews', 'rev')"
        >
          <i
            class="pi pi-star-fill"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("businessprofile.reviews") }}
        </div>
        <div
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'mess' }"
          @click.stop="
            unreadMessageCount = 0;
            navigate('BusinessMessages', 'mess');
          "
        >
          <i
            class="pi pi-comments"
            v-if="unreadMessageCount > 0"
            v-badge="unreadMessageCount"
            style="font-size: x-large; display: block"
          ></i>
          <i
            v-else
            class="pi pi-comments"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("businessprofile.messages") }}
        </div>
        <div
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'clients' }"
          @click.stop="navigate('BusinessClients', 'clients')"
        >
          <i class="pi pi-users" style="font-size: x-large; display: block"></i
          >{{ $t("businessprofile.clients") }}
        </div>
        <div
          v-if="businessOwner == true"
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'staff' }"
          @click.stop="navigate('BusinessStaff', 'staff')"
        >
          <i
            class="pi pi-th-large"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("businessprofile.staff") }}
        </div>
        <div
          v-if="businessOwner == true"
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'settings' }"
          @click.stop="navigate('BusinessSettings', 'settings')"
        >
          <i class="pi pi-cog" style="font-size: x-large; display: block"></i
          >{{ $t("businessprofile.settings") }}
        </div>
        <div
        v-if="businessOwner == true"
          class="p-2 m-1 menu-button"
          :class="{ active: activeMenuId === 'subs' }"
          @click.stop="navigate('BusinessSubscription', 'subs')"
        >
          <i class="pi pi-info" style="font-size: x-large; display: block"></i
          >{{ $t("subscriptions.subscription") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
import Message from "../store/Models/Message";
import Business from "../store/Models/Business";
import Quote from "../store/Models/Quote";
import StoreOrder from "../store/Models/StoreOrder";

export default {
  name: "BusinessNavigation",
  props: { showSideBar: { default: false, type: Boolean } },
  emits: ["hide"],
  components: {},
  data() {
    return {
      selectedBusiness: {},
      selectedBusinessId: 0,
      collapsedMenu: false,
      businessOwner: false,
      unreadMessageCount: 0,
      pendingQuoteCount: 0,
      pendingStoreCount:0,
      showBar: false,
    };
  },
  mounted() {
    this.$log.debug("bus navigation mounted");
    this.$log.debug(this.showSideBar);
    this.showBar = this.showSideBar;
    this.$log.debug("custId " + this.currentCustomerId);
    this.$log.debug("busId" + this.currentBusinessId);
    this.$log.debug(this.customerBusinesses);
    //customer logged in, no businesses loaded
    if (this.currentCustomerId != 0 && this.currentBusinessId == 0) {
      this.$log.debug("refresh data");
      this.refreshUserData(this.currentCustomerId);
    }
    if (this.currentBusinessId != 0) {
      this.selectedBusinessId = this.currentBusinessId;
      this.setSelectedBusiness(this.currentBusinessId);
    }
  },
  methods: {
    ...mapActions(["setCurrentBusiness", "refreshUserData"]),
    async getUnreadMessageCount() {
      await Message.unreadBusMessageCount(this.currentBusinessId).then(
        (result) => {
          this.$log.debug("unread count");
          this.$log.debug(result);
          this.unreadMessageCount = result;
        }
      );
    },
    async getPendingQuoteCount() {
      await Quote.pendingBusCount(this.currentBusinessId).then((result) => {
        this.$log.debug("pending count");
        this.$log.debug(result);
        this.pendingQuoteCount = result;
      });
    },
        async getPendingBusCount() {
      await StoreOrder.pendingBusCount(this.currentBusinessId).then((result) => {
        this.$log.debug("pending count");
        this.$log.debug(result);
        this.pendingStoreCount = result;
      });
    },
    async setSelectedBusiness(busId) {
      this.$log.debug("setting business " + busId);
      this.selectedBusiness = {};
      this.$log.debug(this.selectedBusiness);
      this.selectedBusinessId = busId;
      this.selectedBusiness = await Business.fetchBusinessProfileById(busId);

      this.$log.debug(this.currentCustomerId);
      this.$log.debug(this.selectedBusiness);
      this.$log.debug(this.businessOwner);

      if (
        this.currentCustomerId != null &&
        this.selectedBusiness != null &&
        this.currentCustomerId == this.selectedBusiness.BusOwnerCustId
      ) {
        this.businessOwner = true;
        this.$log.debug(this.businessOwner);
      } else {
        this.businessOwner = false;
      }

      this.getUnreadMessageCount();
      this.getPendingQuoteCount();
      this.getPendingBusCount();
    },
    updateCurrentBusiness(event) {
      this.$log.debug("update current business to ");
      this.$log.debug(event);
      this.setCurrentBusiness(event.value);
      this.setSelectedBusiness(event.value);
    },
    navigate(toRoute, activeId) {
      this.$log.debug(toRoute);
      this.$log.debug(activeId);
      this.$router.push({ name: toRoute });
      this.$emit("hide");
    },
  },
  watch: {
    async currentBusinessId() {
      this.$log.debug(this.selectedBusinessId);
      this.selectedBusinessId = this.currentBusinessId;
      this.$log.debug(this.selectedBusinessId);
      this.setSelectedBusiness(this.currentBusinessId);
    },
    showBar() {
      this.$log.debug(this.showSideBar);
    },
  },

  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
      currentCustomerId: (state) => state.currentCustomerId,
      customerBusinesses: (state) => state.customerBusinesses,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },

    activeMenuId() {
      if (this.$route.path === "/BusinessProfile") {
        return "prof";
      } else if (this.$route.path === "/BusinessServices") {
        return "serv";
      } else if (this.$route.path === "/BusinessCalendar") {
        return "cal";
      } else if (this.$route.path === "/BusinessReservations") {
        return "reserv";
      } else if (this.$route.path === "/BusinessQuotes") {
        return "quotes";
      } else if (this.$route.path === "/BusinessPayments") {
        return "pmt";
      } else if (this.$route.path === "/BusinessReviews") {
        return "rev";
      } else if (this.$route.path === "/BusinessMessages") {
        return "mess";
      } else if (this.$route.path === "/BusinessClients") {
        return "clients";
      } else if (this.$route.path === "/BusinessStaff") {
        return "staff";
      } else if (this.$route.path === "/BusinessSettings") {
        return "settings";
      } else if (this.$route.path === "/BusinessStoreOrders") {
        return "orders";
      } else if (this.$route.path === "/BusinessAppointments") {
        return "appts";
      } else return "";
    },
  },
};
</script>
<style >
.text-left {
  text-align: left !important;
}
.xlarge-icon .pi {
  font-size: x-large !important;
}
.xxlarge-icon .pi {
  font-size: xx-large !important;
}

.pac-container {
  z-index: 1200 !important;
}
</style>