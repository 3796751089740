<template>
  <div><loading-overlay></loading-overlay></div>
</template>
<script>
import LoadingOverlay from "../components/LoadingOverlay.vue";
import { mapState } from "vuex";
export default {
  name: "Login",
  components: { LoadingOverlay },
  data() {
    return {
      url: process.env.VUE_APP_MYRESERVS_URL,
    };
  },
  mounted() {
    this.$log.debug("Login Mounted");
    var redirectUrl = window.sessionStorage.getItem("redirecturl");
   //console.log(redirectUrl);
    var requestedLogin = window.sessionStorage.getItem("requestedlogin");
   //console.log(requestedLogin);

    //Login Requested
    if (requestedLogin == "true") {
     //console.log(window.sessionStorage.getItem("redirecturl"));
      this.$RedirectSignIn(this.url + "Login");
    } else {
      //Login Completed
      var redirect = window.sessionStorage.getItem("redirecturl");
     //console.log(redirect);
      if (redirect == "ListMyBusiness/true") {
        this.$router.push({
          name: "RequestedLogin",
          params: { requestedLoginProp: 'true' },
        });
      } else {
        this.$router.push({
          path: this.url + redirect,
        });
      }
    }
  },
  watch: {
    async userLoggedIn() {
      var redirect = window.sessionStorage.getItem("redirecturl");
     //console.log(redirect);
      if (redirect == "ListMyBusiness/true") {
        this.$router.push({
          name: "RequestedLogin",
          params: { requestedLoginProp: 'true' },
        });
      } else {
        this.$router.push({
          path: redirect,
        });
      }
    },
  },
  computed: {
    ...mapState({
      userLoggedIn: (state) => state.userLoggedIn,
    }),
  },
};
</script>
