<template>
  <div style="background-color: rgba(102, 0, 115, .5); color:white"
    >
  <h1>{{ $t("footer.customerfeatures") }}</h1>
    
    <span class="pr-5 pl-5 text-xl" > {{ $t("customerfeatures.join") }}</span> <br>

    <Button
        :label="$t('menu.createpro')"
        class="
          p-button-raised
          p-button-lg
          p-button-text
          p-button-light
          p-button-plain
          m-5
        "
        style="background-color: white"
        @click.stop="SignIn"
      />
  </div>
      <div       style=" background-color: #d9d9d9; text-align:center; background-image: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%); z-index: 300; opacity: .9;"
    >
 <div
      class="col-12 flex flex-wrap justify-content-center p-5">

    <!-- background-color: #f0f0f0; -->
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-heart" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("menu.favorites") }}</template>
        <template #content>
          {{ $t("customerfeatures.favoritesdetail") }}
        </template>
      </Card>
      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-dollar" style="font-size: 60px"></i>
        </template>
        <template #title>{{ $t("menu.quotes") }} </template>
        <template #content>
          {{ $t("customerfeatures.quotesdetails") }}
        </template>
      </Card>
      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-calendar" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("menu.reservs") }}</template>

        <template #content>
          {{ $t("customerfeatures.reservsdetails") }}
        </template>
      </Card>

      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-money-bill" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("listmybusiness.onlinepay") }}</template>

        <template #content>
          {{ $t("customerfeatures.paymentdetails") }}
        </template>
      </Card>
      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-star-fill" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("menu.notifications") }} </template>

        <template #content> {{ $t("customerfeatures.notificationsdetail") }} </template>
      </Card>
      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-comments" style="font-size: 60px"></i>
        </template>
        <template #title>{{ $t("listmybusiness.directmessages") }} </template>

        <template #content>
          {{ $t("customerfeatures.messagesdetail") }}
        </template>
      </Card>
      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-star-fill" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("listmybusiness.customerreviews") }} </template>

        <template #content>
          {{ $t("customerfeatures.reviewsdetail") }}
        </template>
      </Card>

       
    </div>
     <!-- <Button
        :label="$t('menu.viewdetails')"
        class="
          p-button-raised
          p-button-lg
          p-button-primary
          p-button-plain
          mb-5
        "
        @click.stop="$router.push({ name: 'FunctionalityBusiness' })"
        
      /> -->
</div>
</template>
<script>

export default {
  name: "BusinessFeatures",
  data() {
    return {
       };
  },
  mounted() {},
  methods: {
    
  },
};
</script>

<style>
.pi-primary{
  color: var(--mr-primary-color);
}
</style>