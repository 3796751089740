import { Model } from '@vuex-orm/core'

/* eslint-disable */
export default class CalendarEvent extends Model {
  static entity = 'calendarEvents'
  
  static primaryKey = 'slotId'
  
  static fields () {
    return {
      slotId: this.uid(),
      busId: this.attr(null),
      schId: this.attr(null),
      start: this.attr(null),
      end: this.attr(null),
      title: this.attr(null),
      visible: this.attr(null),
      available: this.attr(null),
      quoted: this.attr(null),
      typeCode : this.attr(null),
      class: this.attr(null),
    
    }
  }

  static async fetchAllCalendarEvents(BusId) {
    CalendarEvent.deleteAll();
    await CalendarEvent.api().get('/api/calendarevents/GetAllBusCalendarEvents/' + BusId).then((result) => {
      //console.log(result.response.data);
    });
    return CalendarEvent.query()
    .withAllRecursive()
    .where(event=>event.busId == BusId).all();
  }

  static async fetchAvailable(BusId) {
    CalendarEvent.deleteAll();
    await CalendarEvent.api().get('/api/calendarevents/GetAvailableBusCalendarEvents/' + BusId).then((result) => {
      //console.log(result.response.data);
    });
    return CalendarEvent.query()
    .withAllRecursive()
    .where(event=>event.busId == BusId && event.available == true).all();
  }

  static async fetchAvailableByType(BusId, TypeCode) {
    CalendarEvent.deleteAll();
    await CalendarEvent.api().get('/api/calendarevents/GetAvailableSlotsByType?busId=' + BusId+"&typeCode="+TypeCode).then((result) => {
      //console.log(result.response.data);
    });
    return CalendarEvent.query()
    .withAllRecursive()
    .where(event=>event.busId == BusId && event.available == true).all();
  }

  static async fetchAllByType(BusId, TypeCode) {
    CalendarEvent.deleteAll();
    await CalendarEvent.api().get('/api/calendarevents/GetAvailableSlotsByType?busId=' + BusId+"&typeCode="+TypeCode).then((result) => {
      //console.log(result.response.data);
    });
    return CalendarEvent.query()
    .withAllRecursive()
    .where(event=>event.busId == BusId).all();
  }

}

