<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <div class="mt-3" v-if="currentBusiness != null && currentBusiness.BusActive == true && currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode == 'PREMIUM'">
    
      <div v-if="currentBusiness.BusStoreEnabled != true">
        <Message severity="warn" :closable="false">
          <b> {{ $t("store.storedisabled") }} </b></Message
        >
      </div>
      <div class="flex justify-content-end m-2">
        <Button
          :label="$t('store.new')"
          icon="pi pi-plus"
          @click.stop="
            () => {
              createNewOrder = true;
            }
          "
          class="mr-2 p-button-sm p-button-primary p-button-raised"
        />
      </div>
      <DataView :value="orders" layout="list" class="">
        <template #empty>
          {{ $t("store.noorders") }}
        </template>
        <template #list="slotProps">
          <div class="col-12 md:col-6">
            <Card
              class="mb-2 mr-1 ml-1"
              @click.stop="openOrderDetails(slotProps.data)"
            >
              <template v-slot:content>
                <div class="grid">
                  <div class="col-10 align-self-center">
                    <div class="box">
                      <div
                        class="
                          flex flex-wrap
                          justify-content-around
                          align-items-center
                        "
                      >
                        <div>
                          <span
                            >#{{ slotProps.data.OrderNum }}<br />
                            <span style="font-size: x-large; text-align: left"
                              ><b>
                                {{ slotProps.data.Cust.CustLastName }},
                                {{ slotProps.data.Cust.CustFirstName }}
                              </b></span
                            ><span> </span><br />
                            <em
                              >{{ $t("message.requested") }}
                              {{
                                $formatDateTimeToDate(
                                  slotProps.data.OrderRequestDate
                                )
                              }}</em
                            ><br />
                            <Tag
                              class="m-2"
                              :severity="
                                getStatusTag(slotProps.data.OrderStatus)
                              "
                              style="text-align: left; font-size: x- small"
                            >
                              {{ slotProps.data.OrderStatus }}
                            </Tag></span
                          >
                        </div>
                        <div class="">
                          <div class="flex flex-wrap justify-content-center">
                            <span class="mr-2">
                              <b>{{ $t("menu.reservationtotal") }}: </b>
                            </span>
                            ${{ slotProps.data.OrderTotalCost }}
                          </div>
                          <div class="flex flex-wrap justify-content-center">
                            <span class="mr-2"
                              ><b>{{ $t("custreserv.custre") }}: </b>
                            </span>
                            ${{ slotProps.data.OrderTotalPaid }}
                          </div>
                          <div class="flex flex-wrap justify-content-center">
                            <span class="mr-2"
                              ><b>{{ $t("custreserv.custowe") }}: </b>
                            </span>
                            ${{ slotProps.data.OrderTotalOwed }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-2 align-self-center"
                    style="text-align: right"
                  >
                    <Button
                      icon="pi pi-angle-right"
                      class="p-button-rounded p-button-text p-button-lg"
                      @click.stop="openOrderDetails(slotProps.data)"
                    />
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </template>
      </DataView>
      <!-- PROCESS ORDER -->
      <Sidebar
        v-model:visible="showOrderDetails"
        :baseZIndex="999"
        position="full"
        style="overflow: scroll; z-index: 1001"
        @hide="resetNewOrder()"
      >
        <div class="grid">
          <div class="col-12">
            <div class="text-center">
              <img
                :src="imageHostingURL + selectedOrder.Bus.BusLogo"
                :alt="selectedOrder.Bus.BusName"
                width="100"
                class="mr-3"
              />
              <div>
                <span style="font-size: xx-large">
                  {{ selectedOrder.Bus.BusName }}
                </span>
              </div>
            </div>
            <div
              class="col-12 mb-2"
              style="background-color: #e9ecef; text-align: center"
            >
              <span style="font-size: x-large"
                >#{{ selectedOrder.OrderNum }}</span
              >
            </div>
            <div class="flex flex-wrap justify-content-center freeze">
              <Button
                :label="$t('menu.restorequote')"
                v-tooltip.right="$t('menu.restorequote')"
                icon="pi pi-undo"
                iconPos="left"
                @click.stop="restoreOrder()"
                class="m-2 p-button-primary p-button-sm p-button-raised"
                v-if="
                  selectedOrder.OrderStatus == 'Canceled' &&
                  !processingOrder &&
                  !revisingOrder
                "
              />
              <Button
                :label="$t('store.cancelorder')"
                icon="pi pi-trash"
                iconPos="left"
                @click.stop="cancelOrder()"
                class="m-2 p-button-danger p-button-sm p-button-raised"
                v-if="
                  selectedOrder.OrderStatus != 'Canceled' &&
                  !processingOrder &&
                  !revisingOrder
                "
              />
              <Button
                v-if="
                  !processingOrder &&
                  !revisingOrder &&
                  selectedOrder.OrderStatus != 'Canceled'
                "
                :label="$t('store.processorder')"
                icon="pi pi-caret-right"
                iconPos="left"
                @click.stop="processOrder()"
                class="m-2 p-button-sm p-button-raised p-button-info"
              />
              <Button
                v-if="
                  !processingOrder &&
                  !revisingOrder &&
                  selectedOrder.OrderStatus != 'Canceled'
                "
                :label="$t('store.overwrite')"
                icon="pi pi-pencil"
                iconPos="left"
                @click.stop="overwriteServices"
                class="m-2 p-button-sm p-button-warning p-button-raised"
              />
              <Button
                v-if="revisingOrder && !savingOverwrite"
                :label="$t('store.canceloverwrite')"
                icon="pi pi-times"
                iconPos="left"
                @click.stop="cancelOverwrite"
                class="m-2 p-button-sm p-button-danger p-button-raised"
              />
              <Button
                v-if="revisingOrder && !savingOverwrite"
                :label="$t('menu.saveOver')"
                v-tooltip.right="$t('menu.saveOver')"
                icon="pi pi-save"
                iconPos="left"
                @click.stop="saveOverwrite"
                class="m-2 p-button-sm p-button-success p-button-raised"
              />
              <Button
                v-if="revisingOrder && savingOverwrite"
                :label="$t('profile.saving')"
                v-tooltip.right="$t('profile.saving')"
                icon="pi pi-spin pi-spinner"
                iconPos="left"
                disabled
                class="m-2 p-button-sm p-button-success p-button-raised"
              />
              <Button
                v-if="processingOrder && !savingProcessedOrder"
                :label="$t('profile.cancel')"
                v-tooltip.right="$t('profile.cancel')"
                icon="pi pi-times"
                iconPos="left"
                @click.stop="cancelProcessing"
                class="m-2 p-button-danger p-button-sm p-button-raised"
              />

              <Button
                v-if="processingOrder && !savingProcessedOrder"
                :label="$t('profile.save')"
                v-tooltip.right="$t('profile.save')"
                icon="pi pi-save"
                iconPos="left"
                @click.stop="doneProcessing"
                class="m-2 p-button-success p-button-sm p-button-raised"
              />
              <Button
                v-if="processingOrder && savingProcessedOrder"
                :label="$t('profile.saving')"
                v-tooltip.right="$t('profile.saving')"
                icon="pi pi-spin pi-spinner"
                iconPos="left"
                disabled
                @click.stop="doneProcessing"
                class="m-2 p-button-success p-button-sm p-button-raised"
              />

              <Button
                icon="pi pi-money-bill"
                :label="$t('store.payments')"
                class="p-button-primary p-button-sm m-2"
                @click.stop="openPayments()"
              />
            </div>
          </div>

          <div class="col-12" style="background-color: #e9ecef">
            <span style="font-size: x-large">{{
              $t("store.date")
            }}</span>
            <Button
              id="reviseDate"
              v-if="revisingOrder"
              icon="pi pi-pencil"
              class="
                ml-2
                p-button-rounded p-button-info p-button-sm p-button-raised
              "
              @click.stop="showBusCalendar = true"
            />
          </div>
          <Sidebar
            v-model:visible="showBusCalendar"
            position="full"
            style="overflow: scroll; z-index: 1006"
          >
            <p style="font-size: x-large; text-align: center">
              {{ $t("quotes.availableslots") }}
            </p>
            <vue-cal
              :events="availableSlots"
              small
              :locale="lang"
              style="height: 500px"
              active-view="month"
              :disable-views="['years', 'week', 'day']"
              events-count-on-year-view
              events-on-month-view="true"
              :twelve-hour="twelveHr"
              @ready="fetchEvents(selectedOrder.BusId)"
              @view-change="fetchEvents(selectedOrder.BusId)"
              :on-event-click="onEventClick"
            >
              <template v-slot:event="{ event, view }">
                <span class="vuecal__event-time" v-if="view == 'month'">
                  <span class="show-on-desktop">
                    {{ event.start.formatTime("h:mm am") }} -
                    {{ event.end.formatTime("h:mm am") }}</span
                  >
                  <small class="show-on-mobile">
                    {{ event.start.formatTime("h:mm am") }}
                  </small>
                </span>
                <span class="vuecal__event-time" v-if="view == 'day'">
                  <small class="">
                    {{ event.title }}<br />
                    {{ event.start.formatTime("h:mm am") }} -
                    {{ event.end.formatTime("h:mm am") }}</small
                  >
                </span>
              </template>
            </vue-cal>
          </Sidebar>
          <div class="col-12" style="text-align: center">
            <template
              v-if="selectedOrder.Slot != null && showOriginalDate == true"
            >
              {{ $formatDateTimeToDate(selectedOrder.Slot.SlotDate) }}<br />
              {{ $formatTime(selectedOrder.Slot.SlotStartTime) }} -
              {{ $formatTime(selectedOrder.Slot.SlotEndTime) }}
            </template>
            <template
              v-if="selectedOrder.Slot == null && showOriginalDate == true"
            >
              {{ $t("quotes.notselected") }}
            </template>
            <template v-if="showOriginalDate == false && showTempDate == true">
              {{ $formatDateTimeToDate(selectedSlot.start) }}<br />
              {{ $formatDateTimeToTime(selectedSlot.start) }} -
              {{ $formatDateTimeToTime(selectedSlot.end) }}
            </template>
          </div>

          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: left">
              <div style="background-color: #e9ecef">
                <span style="font-size: x-large">{{
                  $t("custreserv.custinfo")
                }}</span>
              </div>
              <div v-if="selectedOrder.Cust != null">
                <b>{{ $t("custreserv.custname") }}: </b>
                {{ selectedOrder.Cust.CustFirstName }}
                {{ selectedOrder.Cust.CustLastName }}
                <br />
                <b>{{ $t("custreserv.custphone") }}: </b
                >{{ selectedOrder.Cust.CustPhone }}
                <br />
                <b>{{ $t("listmybusiness.address") }}: </b>
                {{ selectedOrder.Cust.CustAddress }}<br />
                {{ selectedOrder.Cust.CustCity }},
                {{ selectedOrder.Cust.CustState }}
                {{ selectedOrder.Cust.CustZip }}
                <br />
                <b>{{ $t("listmybusiness.email") }}: </b>
                {{ selectedOrder.Cust.CustEmail }}
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: right">
              <div style="background-color: #e9ecef">
                <span style="font-size: x-large">{{
                  $t("custreserv.custprocess")
                }}</span>
              </div>
              <!-- <b>Status: </b> -->
              <Tag
                class="mt-2"
                :severity="getStatusTag(selectedOrder.OrderStatus)"
                style="text-align: left; font-size: x- small"
              >
                {{ selectedOrder.OrderStatus }} </Tag
              ><br />
              <b>{{ $t("custreserv.custdatere") }}: </b
              >{{ $formatDateTime(selectedOrder.OrderRequestDate) }}
              <br />
              <b>{{ $t("custreserv.custdatepro") }}: </b
              ><span v-if="selectedOrder.OrderApprovedDate">{{
                $formatDateTime(selectedOrder.OrderApprovedDate)
              }}</span
              ><span v-else>{{ $t("custreserv.custdatenotpro") }}</span>
              <br />
              <b>{{ $t("custreserv.custproby") }}: </b
              ><span v-if="selectedOrder.OrderProcessedBy">{{
                selectedOrder.OrderProcessedBy
              }}</span
              ><span v-else>{{ $t("custreserv.custdatenotpro") }}</span
              ><br />
              <!-- <b>{{ $t("custreserv.custresponded") }}: </b
              ><span v-if="selectedOrder.QuoteResponseDate">{{
                $formatDateTime(selectedOrder.QuoteResponseDate)
              }}</span
              ><span v-else>{{ $t("custreserv.custnotapp") }}</span> -->
            </div>
          </div>

          <div class="col-12" style="background-color: #e9ecef">
            <span style="font-size: x-large">{{
              $t("services.services")
            }}</span>
            <Button
              v-if="revisingOrder"
              icon="pi pi-plus"
              class="
                ml-2
                p-button-rounded p-button-info p-button-sm p-button-raised
              "
              @click.stop="showBusServices()"
            />
            <Sidebar
              v-model:visible="showServices"
              :baseZIndex="1001"
              position="full"
              style="overflow: scroll"
            >
              <p style="font-size: x-large; text-align: center">
                {{ $t("services.availablepackages") }}<br />
                <Button
                  icon="pi pi-check"
                  :label="$t('menu.done')"
                  class="mt-2 p-button-info p-button-raised p-button-sm"
                  @click.stop="
                    showServices = false;
                    calculateServiceTotals();
                  "
                />
              </p>

              <DataView :value="busServices" layout="grid" class="service-grid">
                <template #empty>{{ $t("services.nopackages") }} </template>
                <template #grid="slotProps">
                  <div
                    v-if="
                      selectedOrder.OrderedServices.filter(
                        (val) =>
                          val.ServId == slotProps.data.ServId &&
                          val.OrderServId != 0 &&
                          val.OrderServRemoved != true
                      ).length == 0
                    "
                    class="col-12 md:col-4 center-text"
                  >
                    <div
                      v-if="slotProps.data != null"
                      class="product-grid-item card"
                      style="background-color: white !important"
                    >
                      <div class="product-grid-item-top flex"></div>
                      <div class="product-grid-item-content">
                        <img
                          v-if="slotProps.data.ServImage != null"
                          :src="imageHostingURL + slotProps.data.ServImage"
                          :alt="slotProps.data.ServTitle"
                          width="25vh"
                          class="mr-3"
                        />
                        <div class="product-name">
                          {{ slotProps.data.ServTitle }}
                        </div>
                        <div class="product-description">
                          {{ slotProps.data.ServShortDesc }} <br />
                          <div>
                            <i class="pi pi-tag product-category-icon"></i>
                            <template
                              v-for="type in slotProps.data.ServiceTypes"
                              :key="type.ServTypeTitle"
                            >
                              <Chip class="m-1">
                                <service-type-label
                                  :serviceType="type.ServTypeTitle"
                              /></Chip>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          product-grid-item-bottom
                          flex flex-wrap
                          justify-content-evenly
                        "
                      >
                        <span class="product-price mb-2 mr-2"
                          >${{ slotProps.data.ServPrice }}
                          <span
                            v-if="slotProps.data.ServPriceCalculated == true"
                            style="font-size: small"
                          >
                            {{ $t("quotes.each") }} &nbsp;
                          </span>
                        </span>

                        <Tag
                          class="mb-2 mr-2"
                          v-if="
                            slotProps.data != null &&
                            slotProps.data.ServPromotionExpires != null &&
                            promotionValid(slotProps.data.ServPromotionExpires)
                          "
                          severity="danger"
                          value="Danger"
                          style="margin-top: 5px"
                        >
                          <em>Special offer!</em>
                        </Tag>
                      </div>

                      <div v-if="slotProps.data.ServPriceCalculated">
                        <label>{{ $t("menu.qty") }} </label>
                        <InputNumber
                          v-if="
                            selectedOrder.OrderedServices.filter(
                              (val) => val.ServId == slotProps.data.ServId
                            ).length == 0
                          "
                          class="mb-3"
                          v-model="
                            servicesForPruchaseQty[
                              getIndex(slotProps.data.ServId)
                            ].Qty
                          "
                          inputStyle="width:50px"
                        />
                        <InputNumber
                          v-else
                          disabled
                          class="mb-3"
                          v-model="
                            servicesForPruchaseQty[
                              getIndex(slotProps.data.ServId)
                            ].Qty
                          "
                          inputStyle="width:50px"
                        />
                      </div>
                      <Button
                        v-if="
                          selectedOrder.OrderedServices.filter(
                            (val) =>
                              val.ServId == slotProps.data.ServId &&
                              val.OrderServRemoved != true
                          ).length == 0
                        "
                        icon="pi pi-plus"
                        :label="$t('menu.addquote')"
                        class="
                          p-button-raised
                          p-button-success
                          p-button-text
                          p-button-sm
                        "
                        @click.stop="addToOrder(slotProps.data)"
                      />
                      <Button
                        v-else
                        icon="pi pi-plus"
                        :label="$t('menu.removequote')"
                        class="
                          p-button-raised
                          p-button-danger
                          p-button-text
                          p-buton-sm
                        "
                        @click.stop="removeFromOrder(slotProps.data)"
                      />
                    </div>
                    <div
                      v-else
                      class="product-grid-item card"
                      style="background-color: white !important"
                    >
                      {{ $t("services.fail") }}
                    </div>
                  </div></template
                >
              </DataView>
            </Sidebar>
          </div>
          <div class="col-4" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custser") }}</b>
          </div>
          <div class="col-3" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custprice") }}</b>
          </div>
          <div class="col-2" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custqty") }}</b>
          </div>
          <div
            class="col-3 pr-5"
            style="background-color: #f8f9fa; text-align: right"
          >
            <b>{{ $t("custreserv.custamount") }}</b>
          </div>

          <template
            v-for="(serv, index) in selectedOrder.OrderedServices"
            :key="serv.OrderServId"
          >
            <template v-if="serv.OrderServOrigId == null">
              <div class="col-4" :class="{ removed: serv.OrderServRemoved }">
                <span
                  v-if="revisingOrder && serv.OrderServRemoved != true"
                  style="float: left"
                >
                  <Button
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger"
                    @click.stop="removeService(index)"
                  />
                </span>
                {{ serv.Serv.ServTitle }}
              </div>
              <div class="col-3" :class="{ removed: serv.OrderServRemoved }">
                ${{ serv.ServPrice }}
                <span
                  v-if="serv.Serv.ServPriceCalculated == true"
                  style="font-size: small"
                >
                  {{ $t("quotes.each") }}
                </span>
              </div>
              <div class="col-2" :class="{ removed: serv.OrderServRemoved }">
                <span v-if="revisingOrder == false">
                  {{ serv.OrderServQty }} &nbsp;</span
                >
                <span
                  v-else-if="
                    serv.Serv.ServPriceCalculated == true &&
                    serv.OrderServRemoved != true
                  "
                >
                  <InputNumber
                    v-model="serv.OrderServQty"
                    inputStyle="width:100%"
                  />
                </span>
                <span v-else> {{ serv.OrderServQty }} &nbsp;</span>
              </div>
              <div
                class="col-3 pr-5"
                style="text-align: right"
                :class="{ removed: serv.OrderServRemoved }"
              >
                <span v-if="serv.OrderServTotal != 0 && revisingOrder == false"
                  ><b>${{ serv.OrderServTotal }}</b></span
                >
              </div>
              <div
                class="col-12"
                style="margin-top: 0; padding-top: 0; color: darkgray"
                v-if="
                  serv.OrderServRemoved == true && serv.OrderServEdited == null
                "
              >
                <small
                  ><em> {{ $t("quotes.removed") }} </em></small
                >
              </div>
              <div
                class="col-12"
                style="margin-top: 0; padding-top: 0; color: darkgray"
                v-if="
                  serv.OrderServRemoved == false && serv.OrderServEdited != null
                "
              >
                <small
                  ><em
                    >{{
                      serv.OrderServEditedBy === "cust"
                        ? $t("quotes.editedbycustomer")
                        : $t("quotes.editedbybusiness")
                    }}
                    {{ $formatDateTime(serv.OrderServEdited) }}
                  </em></small
                >
              </div>
            </template>
          </template>

          <div class="col-12 mt-5 grid justify-content-end">
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custsub") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <span
                v-if="
                  selectedOrder.OrderSubTotal != 0 && revisingOrder == false
                "
                ><b>${{ selectedOrder.OrderSubTotal }}</b></span
              >
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custdis") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <span
                v-if="
                  selectedOrder.OrderDiscount != 0 &&
                  revisingOrder == false &&
                  processingOrder == false
                "
                ><b>${{ selectedOrder.OrderDiscount }}</b></span
              >
              <span v-if="processingOrder == true">
                <div class="p-inputgroup">
                  <InputNumber
                    v-model="selectedOrder.OrderDiscount"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    inputStyle="width:100%"
                  />
                  <Button
                    icon="pi pi-check"
                    iconPos="left"
                    @click.stop="calculateTotals"
                    class="p-button-success"
                  />
                </div>
              </span>
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custsales") }}</b
              ><br />
              {{ this.currentBusiness.BusSalesTaxPercent }}%
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <span
                v-if="selectedOrder.OrderTax != 0 && revisingOrder == false"
              >
                <b>${{ selectedOrder.OrderTax }}</b></span
              >
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custtotal") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <span
                v-if="
                  selectedOrder.OrderTotalCost != 0 && revisingOrder == false
                "
              >
                <b>${{ selectedOrder.OrderTotalCost }}</b></span
              >
            </div>
          </div>
          <div class="col-12" style="background-color: #e9ecef">
            <div class="flex justify-content-between">
              <span style="font-size: x-large"
                >{{ $t("custreserv.custcomments") }} </span
              ><Button
                v-if="!processingOrder && !revisingOrder"
                icon="pi pi-plus"
                class="p-button-rounded p-button-sm p-button-info"
                @click.stop="addingComment = true"
              />
              <Button
                v-else
                v-tooltip.bottom="$t('store.savechanges')"
                icon="pi pi-plus"
                class="p-button-rounded p-button-sm p-button-info"
                disabled
              />
            </div>
          </div>
          <div v-if="addingComment" class="col-12" style="text-align: left">
            <Textarea
              v-model="newComment.CommentText"
              :placeholder="$t('quotes.enter')"
              :autoResize="true"
              rows="5"
              cols="30"
              style="width: 100%"
            />
            <div class="flex justify-content-between">
              <Button
                :label="$t('profile.cancel')"
                v-tooltip.right="$t('profile.cancel')"
                icon="pi pi-times"
                iconPos="left"
                @click.stop="cancelComment"
                class="mr-2 p-button-danger p-button-sm p-button-raised"
              />
              <Button
                :label="$t('profile.save')"
                v-tooltip.right="$t('profile.save')"
                icon="pi pi-save"
                iconPos="left"
                @click.stop="saveComment"
                class="mr-2 p-button-success p-button-sm p-button-raised"
              />
            </div>
          </div>
          <div class="col-12">
            <template
              v-for="comment in selectedOrder.StoreOrderComments.sort((a, b) =>
                a.CommentDate > b.CommentDate ? -1 : 1
              )"
              :key="comment.CommentId"
            >
              <div class="card" style="text-align: left">
                <div class="flex justify-content-between">
                  <span>
                    {{ comment.Cust.CustFirstName }}
                    {{ comment.Cust.CustLastName }}</span
                  >
                  <span> {{ $formatDateTime(comment.CommentDate) }}</span>
                </div>
                <p>
                  <em>{{ comment.CommentText }}</em>
                </p>
              </div>
            </template>
          </div>
        </div>
      </Sidebar>

      <!-- NEW ORDER -->
      <Sidebar
        v-model:visible="createNewOrder"
        :baseZIndex="1000"
        position="full"
        style="overflow: scroll"
        @hide="resetNewOrder()"
      >
        <div class="grid">
          <div class="col-12">
            <div class="col-12 mb-2" style="background-color: #e9ecef">
              <span style="font-size: x-large">{{ $t("store.new") }}</span>
            </div>
            <div class="flex justify-content-center">
              <Button
                :label="$t('profile.cancel')"
                v-tooltip.right="$t('profile.cancel')"
                icon="pi pi-times"
                iconPos="left"
                @click.stop="resetNewOrder()"
                class="mr-2 p-button-danger p-button-sm"
              />

              <Button
                :label="$t('profile.save')"
                v-tooltip.right="$t('profile.save')"
                icon="pi pi-save"
                iconPos="left"
                @click.stop="saveNewOrder()"
                class="mr-2 p-button-success p-button-sm"
              />
            </div>
          </div>

          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: left">
              <div style="background-color: #e9ecef" class="p-1">
                <span style="font-size: x-large"
                  >1.{{ $t("custreserv.custinfo") }}</span
                >
              </div>
              <div v-if="!showCustDetails" class="field m-1">
                <div class="p-inputgroup">
                  <InputText
                    id="custEmail"
                    type="email"
                    v-model="orderCustEmail"
                    :placeholder="$t('message.customeremail')"
                    class="p-inputtext-sm"
                  />
                  <span class="p-inputgroup-addon">
                    <Button
                      icon="pi pi-search"
                      label="Look Up"
                      class="p-button-text p-button-primary"
                      @click.stop="searchCustomer()"
                    />
                  </span>
                </div>
                <span v-if="showInvalidEmail" class="mt-1">
                  <InlineMessage>{{ $t("message.invalid") }}</InlineMessage>
                </span>

                <Dialog
                  v-model:visible="showNewCustomer"
                  :modal="true"
                  :contentStyle="{ overflow: 'scroll' }"
                  :dismissableMask="true"
                  :style="{ width: '50vw' }"
                  :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
                >
                  <p style="font-size: x-large; text-align: center">
                    {{ $t("message.nocustomerinfo") }}
                  </p>
                  <div>
                    <div class="p-fluid formgrid grid text-left">
                      <div class="field col-12 md:col-4">
                        <label for="first">{{ $t("message.firstname") }}</label>
                        <InputText
                          id="first"
                          type="text"
                          v-model="selectedOrder.Cust.CustFirstName"
                        />
                      </div>
                      <div class="field col-12 md:col-4">
                        <label for="last">{{ $t("message.lastname") }}</label>
                        <InputText
                          id="last"
                          type="text"
                          v-model="selectedOrder.Cust.CustLastName"
                        />
                      </div>
                      <div class="field col-12 md:col-4">
                        <label for="phone">{{
                          $t("listmybusiness.phone")
                        }}</label>
                        <InputMask
                          id="phone"
                          mask="(999) 999-9999"
                          v-model="selectedOrder.Cust.CustPhone"
                          placeholder="(999) 999-9999"
                        />
                      </div>
                      <div class="field col-12">
                        <label for="address">{{
                          $t("listmybusiness.address")
                        }}</label>
                        <InputText
                          id="address"
                          v-model="selectedOrder.Cust.CustAddress"
                        />
                      </div>
                      <div class="field col-4">
                        <label for="city">{{ $t("message.city") }}</label>
                        <InputText
                          id="city"
                          type="text"
                          v-model="selectedOrder.Cust.CustCity"
                        />
                      </div>
                      <div class="field col-4">
                        <label for="state">{{ $t("message.state") }}</label>
                        <InputText
                          id="state"
                          type="text"
                          v-model="selectedOrder.Cust.CustState"
                        />
                      </div>
                      <div class="field col-4">
                        <label for="zip">{{ $t("message.zip") }}</label>
                        <InputText
                          id="zip"
                          type="text"
                          v-model="selectedOrder.Cust.CustZip"
                        />
                      </div>
                      <div class="col-12 flex mt-3">
                        <Button
                          label="Done"
                          icon="pi pi-check"
                          @click.stop="
                            showNewCustomer = false;
                            showCustDetails = true;
                          "
                          autofocus
                        />
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
              <div v-if="showCustDetails">
                <Message severity="info" :closable="false">
                  <div class="mb-2">
                    <span>
                      {{ selectedOrder.Cust.CustFirstName }}
                      {{ selectedOrder.Cust.CustLastName }} <b> | </b></span
                    >
                    <span
                      v-if="
                        selectedOrder.Cust.CustPhone != null &&
                        selectedOrder.Cust.CustPhone.length > 0
                      "
                    >
                      {{ selectedOrder.Cust.CustPhone }}<b> | </b> </span
                    ><span
                      v-if="
                        selectedOrder.Cust.CustEmail != null &&
                        selectedOrder.Cust.CustEmail.length > 0
                      "
                    >
                      {{ selectedOrder.Cust.CustEmail }}<b> | </b>
                    </span>
                    <span
                      v-if="
                        selectedOrder.Cust.CustAddress != null &&
                        selectedOrder.Cust.CustAddress.length > 0
                      "
                    >
                      {{ selectedOrder.Cust.CustAddress }}
                    </span>
                    <span
                      v-if="
                        selectedOrder.Cust.CustCity != null &&
                        selectedOrder.Cust.CustCity.length > 0
                      "
                    >
                      {{ selectedOrder.Cust.CustCity }},
                    </span>
                    <span
                      v-if="
                        selectedOrder.Cust.CustState != null &&
                        selectedOrder.Cust.CustState.length > 0
                      "
                      >{{ selectedOrder.Cust.CustState }}
                    </span>
                    <span
                      v-if="
                        selectedOrder.Cust.CustZip != null &&
                        selectedOrder.Cust.CustZip.length > 0
                      "
                    >
                      {{ selectedOrder.Cust.CustZip }}
                    </span>
                  </div>
                  <Button
                    :label="$t('menu.edit')"
                    v-if="selectedOrder.CustId == 0"
                    class="p-button-info p-button-sm mr-2"
                    @click.stop="showNewCustomer = true"
                  />
                  <Button
                    :label="$t('menu.reset')"
                    class="p-button-secondary p-button-sm"
                    @click.stop="resetOrderCust()"
                  />
                </Message>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: left">
              <div style="background-color: #e9ecef" class="p-1">
                <span style="font-size: x-large"
                  >2.{{ $t("store.date") }}</span
                >
                <Button
                  id="selectDateBtn"
                  icon="pi pi-calendar"
                  class="ml-2 p-button-rounded p-button-info p-button-sm"
                  aria:haspopup="true"
                  aria-controls="overlay_panel"
                  @click.stop="toggle2($event)"
                />
              </div>
            </div>
            <OverlayPanel
              ref="op2"
              appendTo="body"
              :showCloseIcon="true"
              id="overlay_panel"
              style="z-index: 4000; margin: 2vh; width: 90%; height: 90%"
            >
              <p style="font-size: x-large; text-align: center">
                {{ $t("quotes.availableslots") }}
              </p>
              <vue-cal
                :events="availableSlots"
                small
                :locale="lang"
                style="height: 500px"
                active-view="month"
                :disable-views="['years', 'week', 'day']"
                events-count-on-year-view
                events-on-month-view="true"
                :twelve-hour="twelveHr"
                @ready="fetchEvents(currentBusinessId)"
                @view-change="fetchEvents(currentBusinessId)"
                :on-event-click="onNewOrderEventClick"
              >
                <template v-slot:event="{ event, view }">
                  <span class="vuecal__event-time" v-if="view == 'month'">
                    <span class="show-on-desktop">
                      {{ event.start.formatTime("h:mm am") }} -
                      {{ event.end.formatTime("h:mm am") }}</span
                    >
                    <small class="show-on-mobile">
                      {{ event.start.formatTime("h:mm am") }}
                    </small>
                  </span>
                  <span class="vuecal__event-time" v-if="view == 'day'">
                    <small class="">
                      {{ event.title }}<br />
                      {{ event.start.formatTime("h:mm am") }} -
                      {{ event.end.formatTime("h:mm am") }}</small
                    >
                  </span>
                </template>
              </vue-cal>
            </OverlayPanel>
            <div class="col-12" style="text-align: center">
              <template v-if="selectedOrder.SlotId == null">
                {{ $t("quotes.notselected") }}
              </template>
              <template v-else>
                <Message severity="info" :closable="false">
                  {{ $formatDateTimeToDate(selectedSlot.start) }}<br />
                  {{ $formatDateTimeToTime(selectedSlot.start) }} -
                  {{ $formatDateTimeToTime(selectedSlot.end) }}
                </Message>
              </template>
            </div>
          </div>

          <div class="col-12" style="background-color: #e9ecef">
            <span style="font-size: x-large"
              >3.{{ $t("services.services") }}</span
            >
            <Button
              icon="pi pi-plus"
              class="ml-2 p-button-rounded p-button-info p-button-sm"
              @click.stop="showBusServices()"
            />
            <Sidebar
              v-model:visible="showServices"
              :baseZIndex="1001"
              position="full"
              style="overflow: scroll"
            >
              <p style="font-size: x-large; text-align: center">
                {{ $t("services.availablepackages") }}<br />
                <Button
                  icon="pi pi-check"
                  label="Done"
                  class="mt-2 p-button-info p-button-raised p-button-sm"
                  @click.stop="
                    showServices = false;
                    calculateServiceTotals();
                  "
                />
              </p>

              <DataView :value="busServices" layout="grid" class="service-grid">
                <template #empty>{{ $t("services.nopackages") }} </template>
                <template #grid="slotProps">
                  <div
                    v-if="
                      selectedOrder.OrderedServices.filter(
                        (val) =>
                          val.ServId == slotProps.data.ServId &&
                          val.OrderServId != 0 &&
                          val.OrderServRemoved != true
                      ).length == 0 &&
                      slotProps.data.ServActive != false &&
                      slotProps.data.ServDeleted != true
                    "
                    class="col-12 md:col-4 center-text"
                  >
                    <div
                      v-if="slotProps.data != null"
                      class="product-grid-item card"
                      style="background-color: white !important"
                    >
                      <div class="product-grid-item-top flex"></div>
                      <div class="product-grid-item-content">
                        <img
                          v-if="slotProps.data.ServImage != null"
                          :src="imageHostingURL + slotProps.data.ServImage"
                          :alt="slotProps.data.ServTitle"
                          width="25vh"
                          class="mr-3"
                        />
                        <div class="product-name">
                          {{ slotProps.data.ServTitle }}
                        </div>
                        <div class="product-description">
                          {{ slotProps.data.ServShortDesc }} <br />
                          <div>
                            <i class="pi pi-tag product-category-icon"></i>
                            <template
                              v-for="type in slotProps.data.ServiceTypes"
                              :key="type.ServTypeTitle"
                            >
                              <Chip class="m-1">
                                <service-type-label
                                  :serviceType="type.ServTypeTitle"
                              /></Chip>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          product-grid-item-bottom
                          flex flex-wrap
                          justify-content-evenly
                        "
                      >
                        <span class="product-price mb-2 mr-2"
                          >${{ slotProps.data.ServPrice
                          }}<span
                            v-if="slotProps.data.ServPriceCalculated == true"
                            style="font-size: small"
                          >
                            {{ $t("quotes.each") }} &nbsp;
                          </span></span
                        >

                        <Tag
                          class="mb-2 mr-2"
                          v-if="
                            slotProps.data.ServPromotionExpires != null &&
                            promotionValid(slotProps.data.ServPromotionExpires)
                          "
                          severity="danger"
                          value="Danger"
                          style="margin-top: 5px"
                        >
                          <em>{{ $t("services.special") }}</em>
                        </Tag>
                      </div>

                      <div v-if="slotProps.data.ServPriceCalculated">
                        <label>{{ $t("menu.qty") }}:&nbsp; </label>
                        <InputNumber
                          v-if="
                            selectedOrder.OrderedServices.filter(
                              (val) => val.ServId == slotProps.data.ServId
                            ).length == 0
                          "
                          class="mb-3"
                          v-model="
                            servicesForPruchaseQty[
                              getIndex(slotProps.data.ServId)
                            ].Qty
                          "
                          inputStyle="width:50px"
                        />
                        <InputNumber
                          v-else
                          disabled
                          class="mb-3"
                          v-model="
                            servicesForPruchaseQty[
                              getIndex(slotProps.data.ServId)
                            ].Qty
                          "
                          inputStyle="width:50px"
                        />
                      </div>
                      <Button
                        v-if="
                          selectedOrder.OrderedServices.filter(
                            (val) =>
                              val.ServId == slotProps.data.ServId &&
                              val.OrderServRemoved != true
                          ).length == 0
                        "
                        icon="pi pi-plus"
                        :label="$t('menu.addquote')"
                        class="
                          p-button-raised
                          p-button-success
                          p-button-text
                          p-button-sm
                        "
                        @click.stop="addToOrder(slotProps.data)"
                      />
                      <Button
                        v-else
                        icon="pi pi-plus"
                        :label="$t('menu.removequote')"
                        class="
                          p-button-raised
                          p-button-danger
                          p-button-text
                          p-buton-sm
                        "
                        @click.stop="removeFromOrder(slotProps.data)"
                      />
                    </div>
                    <div
                      v-else
                      class="product-grid-item card"
                      style="background-color: white !important"
                    >
                      {{ $t("services.fail") }}
                    </div>
                  </div></template
                >
              </DataView>
            </Sidebar>
          </div>
          <div class="col-4" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custser") }}</b>
          </div>
          <div class="col-2" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custprice") }}</b>
          </div>
          <div class="col-1" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custqty") }}</b>
          </div>
          <div class="col-5" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custamount") }}</b>
          </div>

          <template
            v-for="(serv, index) in selectedOrder.OrderedServices"
            :key="serv.OrderServId"
          >
            <template v-if="serv.OrderServOrigId == null">
              <div class="col-4" :class="{ removed: serv.OrderServRemoved }">
                <span
                  v-if="revisingOrder && serv.OrderServRemoved != true"
                  style="float: left"
                >
                  <Button
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger"
                    @click.stop="removeService(index)"
                  />
                </span>
                {{ serv.Serv.ServTitle }}
              </div>
              <div class="col-2" :class="{ removed: serv.OrderServRemoved }">
                ${{ serv.ServPrice }}
                <span
                  v-if="serv.Serv.ServPriceCalculated == true"
                  style="font-size: small"
                >
                  {{ $t("quotes.each") }}
                </span>
              </div>

              <div class="col-1" :class="{ removed: serv.OrderServRemoved }">
                <span v-if="revisingOrder == false">
                  {{ serv.OrderServQty }} &nbsp;</span
                >
                <span
                  v-else-if="
                    serv.Serv.ServPriceCalculated == true &&
                    serv.OrderServRemoved == false
                  "
                >
                  <InputNumber
                    v-model="serv.OrderServQty"
                    inputStyle="width:100%"
                  />
                </span>
                <span v-else> {{ serv.OrderServQty }} &nbsp;</span>
              </div>
              <div class="col-5" :class="{ removed: serv.OrderServRemoved }">
                <div class="p-inputgroup">
                  <InputNumber
                    v-model="serv.OrderServTotal"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    inputStyle="width:100%"
                  />
                  <Button
                    icon="pi pi-check"
                    iconPos="left"
                    @click.stop="calculateTotals"
                    class="mr-2 p-button-success"
                  />
                </div>
              </div>
              <div
                class="col-12"
                style="margin-top: 0; padding-top: 0; color: darkgray"
                v-if="
                  serv.OrderServRemoved == true && serv.OrderServEdited == null
                "
              >
                <small><em> {$t("message.remove")}} </em></small>
              </div>
              <div
                class="col-12"
                style="margin-top: 0; padding-top: 0; color: darkgray"
                v-if="
                  serv.OrderServRemoved == false && serv.OrderServEdited != null
                "
              >
                <small
                  ><em
                    >{{
                      serv.OrderServEditedBy === "cust"
                        ? $t("quotes.editedbycustomer")
                        : $t("quotes.editedbybusiness")
                    }}
                    {{ $formatDateTime(serv.OrderServEdited) }}
                  </em></small
                >
              </div>
            </template>
          </template>

          <div class="col-12 mt-5 grid justify-content-end">
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custsub") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <span
                v-if="
                  selectedOrder.OrderSubTotal != 0 && createNewOrder == false
                "
                ><b>${{ selectedOrder.OrderSubTotal }}</b></span
              >
              <span v-if="createNewOrder == true">
                <div class="p-inputgroup">
                  <InputNumber
                    v-model="selectedOrder.OrderSubTotal"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    inputStyle="width:100%"
                  />
                  <Button
                    icon="pi pi-replay"
                    iconPos="left"
                    @click.stop="calculateTotals"
                    class="mr-2 p-button-info"
                  />
                </div>
              </span>
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custdis") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <div class="p-inputgroup">
                <InputNumber
                  v-model="selectedOrder.OrderDiscount"
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  inputStyle="width:100%"
                />
                <Button
                  icon="pi pi-check"
                  iconPos="left"
                  @click.stop="calculateTotals"
                  class="p-button-success"
                />
              </div>
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custsales") }}</b
              ><br />
              {{ this.currentBusiness.BusSalesTaxPercent }}%
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <InputNumber
                v-model="selectedOrder.OrderTax"
                mode="currency"
                currency="USD"
                locale="en-US"
                inputStyle="width:100%"
              />
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custtotal") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <InputNumber
                v-model="selectedOrder.OrderTotalCost"
                mode="currency"
                currency="USD"
                locale="en-US"
                inputStyle="width:100%"
              />
            </div>
          </div>
          <div class="col-12" style="background-color: #e9ecef">
            <div class="flex justify-content-between">
              <span style="font-size: x-large"
                >{{ $t("custreserv.custcomments") }}
              </span>
            </div>
          </div>
          <div class="col-12" style="text-align: left">
            <Textarea
              v-model="newComment.CommentText"
              :placeholder="$t('custreserv.optional')"
              :autoResize="true"
              rows="5"
              cols="30"
              style="width: 100%"
            />
          </div>
        </div>
      </Sidebar>
      <!-- dialogs -->
      <Dialog
        v-model:visible="showingNewPayment"
        :modal="true"
        :header="$t('code.applypayment')"
        :dismissableMask="true"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <div style="text-align: left">
          <div class="field col-12">
            <label>Payment Date: </label><br />
            <Calendar
              id="newStartDate"
              v-model="newPayment.PayDate"
              :showIcon="true"
            />
          </div>
          <div class="field col-12">
            <label>Payment Amount: </label><br />

            <InputNumber
              id="currency-us"
              placeholder="$"
              v-model="newPayment.PayAmount"
              mode="currency"
              currency="USD"
              locale="en-US"
              style="font-size: small"
            />
          </div>
        <div class="field col-12">
      <label>Payment Source </label><br />
         
      <label for="language"> </label>
      <Dropdown
        :options="paysource"
        optionLabel="desc"
        optionValue="code"
        id="source"
        v-model="newPayment.PaySource"
      />
      </div>

          <Button
            v-if="!processingPayment"
            icon="pi pi-arrow-right"
            label="Submit"
            class="p-button-primary p-button-outlined p-button-sm mt-2"
            @click.stop="startPayment()"
          />
          <Button
            v-else
            icon="pi pi-spin pi-spinner"
            disabled
            label="Submit"
            class="p-button-primary p-button-outlined p-button-sm mt-2"
          />
        </div>
      </Dialog>

      <Dialog
        v-model:visible="viewingPayments"
        :style="{ width: '90vw' }"
        :modal="true"
        :header="$t('code.paymentsfor') + ' ' + selectedOrder.OrderNum"
        :dismissableMask="true"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
      <div
        v-if="selectedOrder != null"
        class="flex flex-wrap justify-content-around align-items-center mb-3"
        style="
          padding: 1px;
          border-color: #ced4da;
          border-top: 1px solid;
          border-bottom: 1px solid;
        "
      >
        <div class="mb-1 mr-1">
          <b>{{ $t("calendar.reservation") }}: </b> ${{
            selectedOrder.OrderTotalCost
          }}
        </div>

        <div class="mb-1 mr-1">
          <b>{{ $t("calendar.totalpaid") }}: </b> ${{
            selectedOrder.OrderTotalPaid
          }}
        </div>
        <div class="mb-1 mr-1">
          <b>{{ $t("calendar.totalowed") }}: </b> ${{
            selectedOrder.OrderTotalOwed
          }}
        </div>
         <Button
          icon="pi pi-dollar"
          :label="$t('code.applypayment')"
          class="p-button-success p-button-sm"
          @click.stop="showNewPayment(selectedOrder)"
        />
      </div>
        <DataTable
          :value="selectedOrder.OrderPayments"
          sortField="PayDate"
          :sortOrder="-1"
          responsiveLayout="stack"
          :resizableColumns="true"
          columnResizeMode="fit"
        >
          <Column
            field="PayDate"
            :header="$t('calendar.date')"
            :sortable="true"
          >
            <template #body="slotProps">
              {{ $formatDateTime(slotProps.data.PayDate) }}
            </template>
          </Column>
          <Column
            field="PayNum"
            :header="$t('payments.payid')"
            :sortable="true"
          ></Column>
          <Column
            field="Card.CardNumber"
            :header="$t('custreserv.custcard')"
            :sortable="true"
          ></Column>
          <Column
            field="PayStatus"
            :header="$t('calendar.status')"
            :sortable="true"
          ></Column>
          <Column
            field="PayAmount"
            :header="$t('custreserv.custamount')"
            :sortable="true"
          >
          
            <template #body="slotProps">
              ${{ slotProps.data.PayAmount }}
            </template></Column
          >
          <Column
            field="PaySource"
            :header="$t('payments.paysource')"
            :sortable="true"
          ></Column>
        </DataTable>
      </Dialog>
    </div>
    <div v-else-if="currentBusiness.BusActive == false">
      <inactive-subscription
        :busId="currentBusiness.BusId"
      ></inactive-subscription>
    </div>
    <div v-else-if="currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode != 'PREMIUM'">
      <upgrade-subscription
        :subId="currentSubscription.SubId"
      ></upgrade-subscription>
    </div>
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{ $t("message.error") }}</Message
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
import StoreOrder from "../store/Models/StoreOrder";
import StoreOrderComment from "../store/Models/StoreOrderComment";
import Customer from "../store/Models/Customer";
import CalendarEvent from "../store/Models/CalendarEvent";
import OrderedService from "../store/Models/OrderedService";
import Service from "../store/Models/Service";
import Business from "../store/Models/Business";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import InactiveSubscription from "../components/InactiveSubscription.vue";
import ServiceTypeLabel from "../components/ServiceTypeLabel.vue";
import OrderPayment from "../store/Models/OrderPayment";
import moment from "moment";
import UpgradeSubscription from '../components/UpgradeSubscription.vue';
import Subscription from '../store/Models/Subscription';

export default {
  name: "BusinessStoreOrders",
  components: {
    VueCal,
    LoadingOverlay,
    InactiveSubscription,
    ServiceTypeLabel,
    UpgradeSubscription
  },
  props: {
    orderId: { default: 0, type: Number },
    busId: { default: 0, type: Number },
  },
  data() {
    return {
      selectedOrder: {
        BusId: 0,
        OrderDiscount: 0,
        OrderTotalCost: 0,
        OrderTax: 0,
        OrderSubTotal: 0,
        OrderStatus: "Processed",
        Cust: {},
        CustId: 0,
        OrderedServices: [],
        currentSubscription:{},
        Slot: {},
        StoreOrderComments: [],
      },
      statusTagStyle: "",
      showOrderDetails: null,
      filters: {},
      loading: false,
      orders: [],
      processingOrder: false,
      currentCustomer: {},
      addingComment: false,
      newComment: {},
      revisingOrder: false,
      showOriginalDate: true,
      showTempDate: false,
      availableSlots: [],
      showBusCalendar: false,
      paysource: [
        { code: "cash", desc: "Cash" },
        { code: "capp", desc: "Cashapp" },
        { code: "zelle", desc: "Zelle" },
        { code: "check", desc: "Check" }
        
      ],
      selectedSlot: {},
      twelveHr: true,
      showServices: false,
      busServices: [],
      servicesForPruchaseQty: [],
      currentBusiness: {},
      createNewOrder: false,
      orderCustEmail: "",
      showNewCustomer: false,
      showCustDetails: false,
      showInvalidEmail: false,
      savingOverwrite: false,
      savingProcessedOrder: false,
      waitingOnBusiness: false,
      showingNewPayment: false,
      viewingPayments: false,
      processingPayment: false,
      newPayment: {
        OrderId: 0,
        PayAmount: null,
        PayDate: new Date(),
        PaySource: null,
        PayStatus: "",
        CardId: null,
        Card: {
          CardId: 0,
          CardCvv: "",
          CardNumber: "",
          CardExpMonth: "",
          CardExpYear: "",
        },
      },
    };
  },
  async mounted() {
    this.$log.debug("business orders mounted");
    this.$log.debug(this.$route.params.orderId);
    this.$log.debug(this.$route.params.busId);
    this.$log.debug(this.currentBusinessId);
    this.loading = true;
    if (this.currentBusinessId > 0) {
      await this.setBusinessOrders();
    }
    if (this.$route.params.orderId > 0) {
      this.loading = true;
      if (this.currentBusinessId != this.$route.params.busId) {
        this.waitingOnBusiness = true;
        await this.setCurrentBusiness(this.$route.params.busId);
      } else {
        this.onLoadWithOrder();
      }
    }
    if (this.currentCustomerId > 0) {
      await this.setCurrentCustomer();
    }
    this.loading = false;
  },
  methods: {
    ...mapActions(["setCurrentBusiness"]),
    onLoadWithOrder() {
      let foundOrder = this.orders.find(
        (order) => order.OrderId == this.$route.params.orderId
      );
      this.$log.debug(foundOrder);
      if (foundOrder) {
        this.selectedOrder = foundOrder;
        this.openOrderDetails(this.selectedOrder);
        this.$router.replace("/BusinessStoreOrders");
      }
      this.loading = false;
    },
    promotionValid(promotionExpirationDate) {
      return moment().isSameOrBefore(promotionExpirationDate, "day");
    },
    toggle1(event) {
      //revise date
      this.$log.debug(event);
      this.$refs.op1.toggle(event);
    },
    toggle2(event) {
      // new order
      this.$log.debug(event);
      this.$refs.op2.toggle(event);
    },
    fetchEvents(busId) {
      this.$log.debug("fetching events for: " + busId);
      this.$log.debug(busId);
      CalendarEvent.fetchAvailableByType(busId, "DELIVERY").then((result) => {
        this.$log.debug(result);
        this.availableSlots = result;
      });
    },
    async showBusServices() {
      this.busServices = await Service.fetchPublicStoreBusinessServices(
        this.currentBusinessId
      );
      this.busServices.forEach((serv) => {
        this.servicesForPruchaseQty.push({ ServId: serv.ServId, Qty: 1 });
      });
      this.$log.debug(this.busServices);
      this.showServices = true;
    },
    openPayments() {
      this.viewingPayments = true;
    },
    showNewPayment(order) {
      this.showingNewPayment = true;
      
      this.newPayment.OrderId = order.OrderId;
    },
    async startPayment() {
      this.processingPayment = true;

      OrderPayment.newManualPayment(this.newPayment).then(async (response) => {
        this.$log.debug(response);
        this.processingPayment = false;
        if (response != null && response.sessionId != 0) {
          this.setBusinessOrders();
          this.newPaymentReset();
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            life: 3000,
          });
        }
      });
    },
    newPaymentReset() {
      this.showingNewPayment = false;
      this.newPayment = {
        ReservId: 0,
        PayAmount: null,
        PayDate: new Date(),
        PayStatus: "Manual",
        PaySource: null
      };
    },
    async addToOrder(service) {
      this.$log.debug("adding new service to order");
      this.selectedOrder.OrderedServices.push(
        new OrderedService({
          OrderServId: 0,
          OrderId: this.selectedOrder.OrderId,
          ServId: service.ServId,
          ServPrice: service.ServPrice,
          OrderServQty:
            this.servicesForPruchaseQty[this.getIndex(service.ServId)].Qty,
          OrderServTotal: 0,
          Serv: service,
        })
      );
      this.$log.debug(this.selectedOrder.OrderedServices);
    },
    removeFromOrder(service) {
      this.$log.debug("removing new service");
      this.selectedOrder.OrderedServices.splice(
        this.selectedOrder.OrderedServices.findIndex(
          (val) => val == service.ServId
        ),
        1
      );
    },
    getIndex(servId) {
      return this.servicesForPruchaseQty.findIndex((i) => i.ServId == servId);
    },
    onEventClick(event) {
      this.availableSlots = [];
      this.selectedEvent = event;
      this.$log.debug(event);
      this.selectedOrder.SlotId = event.slotId;
      this.selectedSlot = event;
      this.showOriginalDate = false;
      this.showTempDate = true;
      this.showBusCalendar = false;
    },
    onNewOrderEventClick(event) {
      this.availableSlots = [];
      this.selectedEvent = event;
      this.$log.debug(event);
      this.selectedOrder.SlotId = event.slotId;
      this.selectedSlot = event;
      this.showTempDate = true;
      this.$refs.op2.hide();
    },
    openOrderDetails(data) {
      this.$log.debug("opening order");
      this.$log.debug(data);
      this.revisingOrder = false;
      this.selectedOrder = data;
      // this.selectedOrder.OrderedServices.forEach((serv) => {
      //   serv.OrderServTotal = serv.ServPrice * serv.OrderServQty;
      // });
      this.selectedOrder.StoreOrderComments.sort(function (a, b) {
        return b.CommentId - a.CommentId;
      });
      this.showOrderDetails = true;
    },
    async setBusinessOrders() {
      Business.fetchBusinessProfileById(this.currentBusinessId).then(
        (result) => {
          this.currentBusiness = result;
        }
      );
      Subscription.fetchCurrentByBusiness(this.currentBusinessId).then((result) => {
          this.currentSubscription = result;
         //console.log(this.currentSubscription)
        });
      await StoreOrder.fetchByBusId(this.currentBusinessId).then((result) => {
        this.$log.debug(result);
        this.orders = result;
        if (this.selectedOrder != null && this.selectedOrder.OrderId != null) {
          this.selectedOrder = StoreOrder.query()
            .withAllRecursive()
            .find(this.selectedOrder.OrderId);
        }
        if (this.waitingOnBusiness) {
          this.onLoadWithOrder();
        }
        this.loading = false;
      });
    },
    getStatusTag(status) {
      switch (status) {
        case "Submitted":
          return "primary";
        case "Approved":
          return "success";
        case "Canceled":
          return "danger";
        case "RequestedCancel":
          return "warning";
        case "Completed":
          return "info";
        default:
          return "info";
      }
    },
    getStatusDesc(status) {
      switch (status) {
        case "Submitted":
          return this.$t("store.statusrequested");
        case "Approved":
          return this.$t("store.statusaccepted");
        case "Completed":
          return this.$t("store.statuscompleted");
        case "RequestedCancel":
          return this.$t("store.statusrequestedcancel");
        case "Canceled":
          return this.$t("store.statuscanceled");
        default:
          return "";
      }
    },
    processOrder() {
      this.$log.debug("process order");
      if (this.selectedOrder.OrderStatus == "Submitted") {
        this.setOrderProcessing();
      } else {
        this.processingOrder = true;
      }
    },
    setOrderProcessing() {
      this.$log.debug(this.currentCustomer);
      this.calculateServiceTotals();
      this.processingOrder = true;
    },
    calculateServiceTotals() {
      let self = this;
      this.selectedOrder.OrderedServices.forEach(function (serv, index) {
        if (serv.OrderServRemoved != true)
          self.selectedOrder.OrderedServices[index].OrderServTotal =
            serv.ServPrice * serv.OrderServQty;
      });
      this.calculateTotals();
    },
    calculateTotals() {
      let sum = 0;
      this.selectedOrder.OrderedServices.forEach((serv) => {
        if (serv.OrderServRemoved != true) sum += serv.OrderServTotal;
      });
      this.selectedOrder.OrderSubTotal = sum;
      this.$log.debug(this.selectedOrder.OrderSubTotal);
      this.selectedOrder.OrderTax = (
        (this.selectedOrder.OrderSubTotal - this.selectedOrder.OrderDiscount) *
        (this.currentBusiness.BusSalesTaxPercent / 100)
      ).toFixed(2);
      this.$log.debug(this.selectedOrder.OrderTax);
      this.selectedOrder.OrderTotalCost =
        this.selectedOrder.OrderSubTotal -
        this.selectedOrder.OrderDiscount +
        parseFloat(this.selectedOrder.OrderTax);
      this.$log.debug(this.selectedOrder.OrderTotalCost);
    },
    cancelProcessing() {
      this.processingOrder = false;
      this.selectedOrder = StoreOrder.query()
        .withAllRecursive()
        .find(this.selectedOrder.OrderId);
    },
    resetOrder() {
      this.selectedOrder = StoreOrder.query()
        .withAllRecursive()
        .find(this.selectedOrder.OrderId);
    },
    async doneProcessing() {
      this.savingProcessedOrder = true;
      this.selectedOrder.OrderApprovedDate = new Date();
      this.selectedOrder.OrderStatus = "Approved";
      this.selectedOrder.OrderProcessedBy =
        this.currentCustomer.CustFirstName +
        " " +
        this.currentCustomer.CustLastName;

      await StoreOrder.processOrder(this.selectedOrder).then(
        async (response) => {
          this.$log.debug(response);
          this.processingOrder = false;
          this.savingProcessedOrder = false;
          this.setBusinessOrders();
          if (response == 1) {
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              life: 3000,
            });
          }
        }
      );
    },
    resetOrderCust() {
      this.selectedOrder.CustId = 0;
      this.selectedOrder.Cust = null;
      this.showCustDetails = false;
      this.orderCustEmail = "";
    },
    async searchCustomer() {
      this.showInvalidEmail = false;
      this.selectedOrder.CustId = 0;
      this.selectedOrder.Cust = null;
      this.showCustDetails = false;
      //eslint-disable-next-line
      var regex = /\S+@\S+\.\S+/;
      var validEmail = regex.test(this.orderCustEmail);
      this.$log.debug(regex.test(this.orderCustEmail));
      if (validEmail) {
        Customer.fetchFullByEmail(this.orderCustEmail).then((result) => {
          this.$log.debug(result);
          if (result == null) {
            this.$log.debug("not found");
            this.selectedOrder.CustId = 0;
            this.selectedOrder.Cust = {};
            this.selectedOrder.Cust.CustEmail = this.orderCustEmail;
            this.showNewCustomer = true;
          } else {
            this.selectedOrder.CustId = result.CustId;
            this.selectedOrder.Cust = result;
            this.showCustDetails = true;
          }
        });
      } else {
        this.showInvalidEmail = true;
      }
    },
    resetNewOrder() {
      this.selectedOrder = {
        OrderDiscount: 0,
        OrderTotalCost: 0,
        OrderTax: 0,
        OrderSubTotal: 0,
        OrderStatus: "Processed",
        CustId: 0,
        Cust: {},
        OrderedServices: [],
        Slot: {},
        StoreOrderComments: [],
      };
      this.resetOrderCust();
    },
    async saveNewOrder() {
      if (
        this.selectedOrder.Cust.CustEmail != null &&
        this.selectedOrder.Cust.CustEmail.length > 0
      ) {
        if (
          this.newComment.CommentText != null &&
          this.newComment.CommentText.length > 0
        ) {
          this.newComment.OrderId = this.selectedOrder.OrderId;
          this.newComment.CustId = this.currentCustomerId;
          this.newComment.CommentDate = new Date();
          this.selectedOrder.StoreOrderComments.push(this.newComment);
        }

        this.selectedOrder.BusId = this.currentBusinessId;
        this.loading = true;
        await StoreOrder.createManualStoreOrder(this.selectedOrder).then(
          async (response) => {
            this.$log.debug(response);
            this.setBusinessOrders();
            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                life: 3000,
              });
            }
            this.loading = false;
            this.resetNewOrder();
            this.createNewOrder = false;
          }
        );

        this.$log.debug(this.selectedOrder);
      }
    },
    async setCurrentCustomer() {
      await Customer.getCurrentCustomer().then((result) => {
        this.$log.debug(result);
        this.currentCustomer = result;
      });
    },
    restoreOrder() {
      this.$confirm.require({
        message: this.$t("quotes.restoreconfirm"),
        header: this.$t("menu.restorequote"),
        icon: "pi pi-calendar-check",
        accept: async () => {
          //callback to execute when user confirms the action
          this.selectedOrder.OrderApprovedDate = new Date();
          this.selectedOrder.OrderStatus = "Submitted";
          this.selectedOrder.OrderProcessedBy =
            this.currentCustomer.CustFirstName +
            " " +
            this.currentCustomer.CustLastName;

          await StoreOrder.processOrder(this.selectedOrder).then(
            async (response) => {
              this.$log.debug(response);
              this.setBusinessOrders();
              this.processingOrder = false;
              this.revisingOrder = true;
              if (response == 1) {
                this.over = true;
              } else {
                this.resetOrder();
              }
            }
          );
        },
        reject: () => {
          this.resetOrder();
        },
      });
    },
    cancelOrder() {
      this.$confirm.require({
        message: this.$t("store.cancelorderdesc"),
        header: this.$t("store.cancelorderheader"),
        icon: "pi pi-calendar-check",
        accept: async () => {
          //callback to execute when user confirms the action
          this.selectedOrder.OrderApprovedDate = new Date();
          this.selectedOrder.OrderStatus = "Canceled";
          this.selectedOrder.OrderProcessedBy =
            this.currentCustomer.CustFirstName +
            " " +
            this.currentCustomer.CustLastName;

          await StoreOrder.processOrder(this.selectedOrder).then(
            async (response) => {
              this.$log.debug(response);
              this.setBusinessOrders();
              this.processingOrder = false;
              if (response == 1) {
                this.$toast.add({
                  severity: "success",
                  summary: this.$t("custreserv.success"),
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: this.$t("code.errors"),
                  life: 3000,
                });
                this.resetOrder();
              }
            }
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    async saveComment() {
      this.newComment.OrderId = this.selectedOrder.OrderId;
      this.newComment.CustId = this.currentCustomerId;
      this.newComment.CommentDate = new Date();
      await StoreOrderComment.addComment(this.newComment).then(
        async (response) => {
          this.$log.debug(response);
          this.setBusinessOrders();
          this.newComment = {};
          this.addingComment = false;
          // this.resetOrder();
        }
      );
    },
    cancelComment() {
      this.addingComment = false;
      this.newComment = {};
    },
    removeService(servIndex) {
      this.$log.debug(servIndex);
      this.selectedOrder.OrderedServices[servIndex].OrderServRemoved = true;
    },
    overwriteServices() {
      this.revisingOrder = true;
    },
    cancelOverwrite() {
      this.selectedOrder = StoreOrder.query()
        .withAllRecursive()
        .find(this.selectedOrder.OrderId);
      this.revisingOrder = false;
    },
    saveOverwrite() {
      this.$confirm.require({
        message: this.$t("store.editedorderdesc"),
        header: this.$t("store.editedorder"),
        icon: "pi pi-calendar-check",
        accept: async () => {
          this.savingOverwrite = true;
          await StoreOrder.saveOverwrite(this.selectedOrder).then(
            async (response) => {
              this.setBusinessOrders();
              this.$log.debug("saved overwrite, reset order");
              this.$log.debug(response);
              // this.resetOrder();
              this.$log.debug("order reset");
              this.revisingOrder = false;
              this.savingOverwrite = false;
              // this.setOrderProcessing();
              // this.calculateServiceTotals();
            }
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
  },
  watch: {
    async currentBusinessId() {
      this.loading = true;
      this.setBusinessOrders();
    },
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCurrentCustomer();
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
      currentCustomerId: (state) => state.currentCustomerId,
      lang: (state) => state.lang,
    }),

    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style>
.p-datatable-header {
  background-color: white !important;
}
.p-dataview .p-dataview-content {
  background-color: #f8f9fa !important;
}
.removed {
  text-decoration: line-through;
  color: darkgray;
  padding-bottom: 0 !important;
}
</style>
