<template>
  {{ getEventType(eventType) }}
</template>
<script>
export default {
  props: {
    eventType: String,
  },
  data() {
    return {};
  },
  methods: {
    getEventType(eventtype) {
      switch (eventtype) {
        case "Birthday Parties":
          return this.$t("eventtypes.birthdayparties");
        case "Baby Showers":
          return this.$t("eventtypes.babyshower");
        case "Bridal Showers":
          return this.$t("eventtypes.bridalshowers");
        case "Weddings":
          return this.$t("eventtypes.weddings");
        case "Kids Birthday Parties":
          return this.$t("eventtypes.kidsbirthdayparties");
        case "Quinceañeras":
          return this.$t("eventtypes.quinceaneras");
        default:
          return "";
      }
    },
  },
};
</script>