import { Model } from '@vuex-orm/core'

export default class Business extends Model {
  static entity = 'businessImages'
  
  static primaryKey = 'BusImageId'
  
  static fields () {
    return {
      BusImageId: this.uid(),
      BusId: this.attr(null),
      BusImageTitle: this.attr(null),
      BusImage: this.attr(null),
      BusImageCaption: this.attr(null)
    }
  }
}

