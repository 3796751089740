<template>
  <div class="content">
    <Card class="m-5">
      <template #content>
        <h1>
          <strong>{{ $t("footer.contactus") }}</strong>
        </h1>
        <h4>{{ $t("contactus.header") }}</h4>

        <div class="grid rounded-box">
          <div class="col-12 md:col-6 p-3 right-border">
            <h2>{{ $t("contactus.sendusmessage") }}</h2>
            <div class="p-fluid formgrid grid text-left">
              <div class="field col-12 md:col-6">
                <label for="first">{{ $t("message.firstname") }}</label>
                <InputText
                  id="first"
                  type="text"
                  v-model="feedback.TicketFirstName"
                />
              </div>
              <div class="field col-12 md:col-6">
                <label for="last">{{ $t("message.lastname") }}</label>
                <InputText
                  id="last"
                  type="text"
                  v-model="feedback.TicketLastName"
                />
              </div>
              <div class="field col-12 md:col-6">
                <label for="email">{{ $t("message.email") }}</label>
                <InputText
                  id="email"
                  type="text"
                  v-model="feedback.TicketEmail"
                />
              </div>
              <div class="field col-12 md:col-6">
                <label for="phone">{{ $t("message.phone") }}</label>
                <InputText
                  id="phone"
                  type="text"
                  v-model="feedback.TicketPhone"
                />
              </div>
              <div class="field col-12">
                <Dropdown
                  id="cat"
                  v-model="feedback.TicketCat"
                  class="mr-2"
                  :options="feedbackTopics"
                  optionLabel="label"
                  optionValue="value"
                  :placeholder="$t('contactus.topics')"
                />
              </div>
              <div class="field col-12">
                <Textarea
                  id="msg"
                  rows="4"
                  v-model="feedback.TicketMessage"
                  :placeholder="$t('message.type')"
                />
                <input class="none" name="fkpassword" type="text" v-model="fkpassword">
              </div>
            </div>
            <div class="col-12">
              <div class="flex justify-content-between">
                <!-- <button @click="recaptcha">Execute recaptcha</button> -->
                <Button
                  icon="pi pi-times"
                  :label="$t('menu.reset')"
                  class="p-button-secondary p-button-sm m-1"
                  @click.stop="resetFeedback()"
                />
                <Button
                  v-if="!submitting"
                  icon="pi pi-check"
                  :label="$t('menu.submit')"
                  class="p-button-primary p-button-sm m-1"
                  @click.stop="validateFeedback()"
                />
                <Button
                  v-else
                  icon="pi pi-spin pi-spinner"
                  :label="$t('menu.submit')"
                  class="p-button-primary p-button-sm m-1"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <h2>{{ $t("contactus.othercontact") }}</h2>

            <h4>
              <i class="pi pi-mobile"></i>
              <a :href="'tel: 9569999999'"> (956) 897-2613 </a>
            </h4>
            <h4>
              <i class="pi pi-envelope"></i>
              <a :href="'mailto:support@myreservs.com'"
                >support@myreservs.com</a
              >
            </h4>
            <div class="flex justify-content-center" >
              <span class="mr-3">
                <a
                  :href="'https://www.facebook.com/MyReservs'"
                  target="_blank"
                  v-tooltip.bottom="'Visit us on FB!'"
                  ><i class="pi pi-facebook primary" style="font-size:1.5em"></i
                ></a>
              </span>

              <span class="mr-3">
                <a
                  :href="'https://twitter.com/MyReservs'"
                  target="_blank"
                  v-tooltip.bottom="'Visit us on Twitter!'"
                  ><i class="pi pi-twitter primary" style="font-size:1.5em" ></i
                ></a>
              </span>

              <span class="mr-3">
                <a
                  :href="'https://www.instagram.com/myreservsllc/'"
                  target="_blank"
                  v-tooltip.bottom="'Visit us on Instagram!'"
                  ><i class="pi pi-instagram primary" style="font-size:1.5em"></i
                ></a>
              </span>
              <span>
                <a
                  :href="'https://www.youtube.com/channel/UC0m6lRsUpkU58Oxpu2o6tuA'"
                  target="_blank"
                  v-tooltip.bottom="'Visit us on YouTube!'"
                  ><i class="pi pi-youtube video primary" style="font-size:1.5em"></i
                ></a>
              </span>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SupportTicket from "../store/Models/SupportTicket";
import Customer from "../store/Models/Customer";
// import { useReCaptcha } from 'vue-recaptcha-v3'

export default {
  name: "ContactUs",
  props: {},
  data() {
    return {
      submitting: false,
      fkpassword: null,
      feedback: {},
      feedbackTopics: [
        {
          label: this.$t("contactus.technicalassistance"),
          value: "Technical Assistance",
        },
        { label: this.$t("contactus.featurerequest"), value: "Feature Request" },
        { label: this.$t("contactus.busdemo"), value: "Demo Request" },
        {
          label: this.$t("contactus.accountassistance"),
          value: "Account Assistance",
        },
        { label: this.$t("contactus.reportbusiness"), value: "Business Report" },
        { label: this.$t("contactus.reportcustomer"), value: "Customer Report" },
        { label: this.$t("contactus.other"), value: "Other" },
      ],
    };
  },
  // setup() {
  //   const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

  //   const recaptcha = async () => {
  //     await recaptchaLoaded()
  //     const token = await executeRecaptcha('login')
  //     this.$log.debug({ token })
  //   }

  //   return {
  //     recaptcha
  //   }
  // },
  mounted() {
    this.$log.debug("contactus mounted");
    this.$log.debug(this.currentCustomerId);
    this.setCurrentCustomer();
  },
  methods: {
    setCurrentCustomer() {
      if (this.currentCustomerId > 0) {
        Customer.getCurrentCustomer().then(async (response) => {
          this.$log.debug(response);
          this.feedback.TicketFirstName = response.CustFirstName;
          this.feedback.TicketLastName = response.CustLastName;
          this.feedback.TicketEmail = response.CustEmail;
          this.feedback.TicketPhone = response.CustPhone;
          this.feedback.CustId = this.currentCustomerId;
        });
      }
    },
    validateFeedback() {
      this.$log.debug("validate customer feedback");
      this.submitting = true;
      let errors = 0;
      //verify required values
      if (
        this.feedback.TicketFirstName == null ||
        this.feedback.TicketFirstName.length == 0
      ) {
        document.getElementById("first").classList.add("p-invalid");
        errors++;
      }
      if (
        this.feedback.TicketLastName == null ||
        this.feedback.TicketLastName.length == 0
      ) {
        document.getElementById("last").classList.add("p-invalid");
        errors++;
      }
      if (
        (this.feedback.TicketEmail == null ||
          this.feedback.TicketEmail.length == 0) &&
        (this.feedback.TicketPhone == null ||
          this.feedback.TicketPhone.length == 0)
      ) {
        document.getElementById("email").classList.add("p-invalid");
        document.getElementById("phone").classList.add("p-invalid");
        errors++;
      }
      if (
        this.feedback.TicketCat == null ||
        this.feedback.TicketCat.length == 0
      ) {
        document.getElementById("cat").classList.add("p-invalid");
        errors++;
      }
      if (
        this.feedback.TicketMessage == null ||
        this.feedback.TicketMessage.length == 0
      ) {
        document.getElementById("msg").classList.add("p-invalid");
        errors++;
      }
      if(this.fkpassword != null){
        this.$log.debug("bot");
        errors++;
      }

      if (errors == 0) {
        this.submitFeedback();
      } else {
        this.submitting = false;
      }
    },
    async submitFeedback() {
      this.$log.debug("submit feedback");
      this.$log.debug(this.feedback);
      await SupportTicket.createNewTicket(this.feedback, this.lang).then(
        async (response) => {
          this.$log.debug(response);
          if (response) {
            this.resetFeedback();
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.messagesubmitted"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error!",
              detail: this.$t("code.messagefailed"),
              life: 3000,
            });
          }
          this.submitting = false;
        }
      );
    },
    resetFeedback() {
      this.feedback = {};
    },
  },
  watch: {
    currentCustomerId() {
      this.setCurrentCustomer();
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
      userLoggedIn: (state) => state.userLoggedIn,
      lang: (state) => state.lang,
    }),
  },
};
</script>

<style >
.rounded-box {
  border: 1px solid #ccc;
  border-radius: 8px;
}
.right-border {
  border-right: 1px solid #ccc;
}
.primary {
  color: var(--mr-primary-color) !important; 
}
.none {
  display: none;
}
</style>
