import { Model } from '@vuex-orm/core'

export default class BusinessTypeDef extends Model {
  static entity = 'businessTypeDefs'
  
  static primaryKey = 'BusTypeId'
  
  static fields () {
    return {
      BusTypeDefId: this.uid(),
      BusTypeTitle: this.attr(null),
      BusTypeDesc: this.attr(null)
    }
  }
}

