<template>
  <div class="content">
    <Card class="m-5">
      <template #content>
        <h1>
          <strong>{{ $t("footer.faqlong") }}</strong>
        </h1>
        <h3>{{ $t("footer.businessfeatures") }}</h3>
        <Accordion :multiple="true" style="text-align: left">
          <AccordionTab :header="$t('faq.bussubs.howtosubscribe')">
            <p>{{ $t("faq.bussubs.howtosubscribea") }}</p>
            <p>{{ $t("faq.bussubs.howtosubscribeb") }}</p>
            <p>{{ $t("faq.bussubs.howtosubscribec") }}</p>
            <Button
              :label="$t('menu.listmybusiness')"
              class="p-button-raised p-button-primary"
              @click.stop="$router.push({ name: 'ListMyBusiness' })"
            />
          </AccordionTab>
          <AccordionTab :header="$t('faq.bussubs.listnosubscription')">
            <p>{{ $t("faq.bussubs.listnosubscriptiona") }}</p>
            <p>{{ $t("faq.bussubs.listnosubscriptionb") }}</p>
            <Button
              :label="$t('menu.listmybusiness')"
              class="p-button-raised p-button-primary"
              @click.stop="$router.push({ name: 'ListMyBusiness' })"
            />
          </AccordionTab>
          <AccordionTab :header="$t('faq.bussubs.trialsubscription')">
            <p>{{ $t("faq.bussubs.trialsubscriptiona") }}</p>
            <Button
              :label="$t('footer.businessfeatures')"
              class="p-button-raised p-button-primary"
              @click.stop="$router.push({ name: 'FunctionalityBusiness' })"
            />
          </AccordionTab>
          <AccordionTab :header="$t('faq.bussubs.featuredbus')">
            <p>{{ $t("faq.bussubs.featuredbusa") }}</p>
          </AccordionTab>
          <AccordionTab :header="$t('faq.bussubs.cancelsubsc')">
            <p>{{ $t("faq.bussubs.cancelsubsca") }}</p>
            <p>{{ $t("faq.bussubs.cancelsubscb") }}</p>
          </AccordionTab>
        </Accordion>
                <h3>{{ $t("footer.customerfeatures") }}</h3>
        <Accordion :multiple="true" style="text-align: left">
          <AccordionTab :header="$t('faq.custacct.customersubscription')">
            <p>{{ $t("faq.custacct.customersubscriptiona") }}</p>
            <p>{{ $t("faq.custacct.customersubscriptionb") }}</p>
            <p>{{ $t("faq.custacct.customersubscriptionc") }}</p>
          </AccordionTab>
          <AccordionTab :header="$t('faq.custacct.customersignup')">
            <p>{{ $t("faq.custacct.customersignupa") }}</p>
          </AccordionTab>
          <AccordionTab :header="$t('faq.custacct.personalinfoshared')">
            <p>{{ $t("faq.custacct.personalinfoshareda") }}</p>
          </AccordionTab>
        </Accordion>
      </template>
    </Card>
  </div>
</template>
