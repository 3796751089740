
/* eslint-disable */

import { Model } from '@vuex-orm/core'
import CustEvent from "./CustEvent"


export default class CustEventTodo extends Model {
  static entity = 'custeventtodo'

  static primaryKey = 'TodoId'

  static fields() {
    return {
      TodoId: this.uid(),
      EventId: this.attr(null),
      TodoTitle: this.attr(null),
      TodoNotes: this.attr(null),
      TodoStartDate: this.attr(null),
      TodoDueDate: this.attr(null),
      TodoCompleted: this.attr(null),
 
      //VIRTUAL
      Event: this.belongsTo(CustEvent, 'EventId'),
    }
  }


  // static async fetchByCustId(CustId) {
  //   //CustEvent.deleteAll();
  //   //GuestParty.deleteAll();
  //  //SharedGuestList.deleteAll();
  //  //console.log("fetch guest lists for " + CustId)
  //   var result = await CustEvent.api().get('/api/CustEvent/GetCustomerCustEvents/' + CustId);
  //  //console.log(result)
  //   return CustEvent.query()
  //     .withAllRecursive()
  //     .orderBy('EventId', 'desc').all();
  // }

  // static async deleteCustEvent(id) {
  //   //console.log("delete guest list " + id)
  //   var result = await CustEvent.api().delete('/api/CustomerEvent/' + id).then((result) => {
  //     if (result.response.status == 204) {
  //       //console.log("success")
  //       return 1;
  //     }
  //     if (result.response.status == 200) {
  //       //console.log("duplicate")
  //       return 1;
  //     }
  //     else {
  //       //console.log("error");
  //       return 2;
  //     }
  //   }).catch(error => {
  //     //console.log(error.response);
  //     return 2;
  //   });
  //   return result;
  // }


}

