<template>
  <div class="content">
    <DataTable
      :value="customers"
      class="p-datatable-responsive-demo"
      :paginator="true"
      :rows="10"
      ref="dt"
      :resizableColumns="true"
       dataKey="CustId" :filters="filters" :loading="loading"
    >
    <template #empty>
        {{$t('message.customer')}}
    </template>
    <template #loading>
        {{$t('message.loading')}}
    </template>
      <template #header> 
        <div style="text-align: left">
            <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
        </div>
      </template>
      <Column field="Cust.CustLastName" header="Last" :sortable=true>
        <template #body="slotProps">
          <span class="p-column-title">Last</span>
           <span v-if="slotProps.data.Cust.CustLastName != null">{{ slotProps.data.Cust.CustLastName }}</span>
        </template>
        <template #filter>
            <InputText type="text" v-model="filters['Cust.CustLastName']" class="p-column-filter" placeholder=""/>
        </template>
      </Column>
      <Column field="Cust.CustFirstName" header="First" :sortable=true>
        <template #body="slotProps">
          <span class="p-column-title" >First</span>
          <span class="pointer" @click.stop="showCustomerDetails(slotProps.data.Cust.CustFirstName)">{{ slotProps.data.Cust.CustFirstName }}</span>
        </template>
          <template #filter>
            <InputText type="text" v-model="filters['Cust.CustFirstName']" class="p-column-filter" placeholder=""/>
        </template>
      </Column>
      <Column  field="Cust.CustCity" header="City" :sortable=true>
        <template #body="slotProps">
          <span class="p-column-title">City</span>
          <span v-if="slotProps.data.Cust != null">{{slotProps.data.Cust.CustCity }}</span>
        </template>
           <template #filter>
            <InputText type="text" v-model="filters['Cust.CustCity']" class="p-column-filter" placeholder=""/>
        </template>
      </Column>
            <Column  field="Cust.CustState" header="State" :sortable=true>
        <template #body="slotProps">
          <span class="p-column-title">State</span>
          <span v-if="slotProps.data.Cust != null">{{slotProps.data.Cust.CustState }}</span>
        </template>
           <template #filter>
            <InputText type="text" v-model="filters['Cust.CustState']" class="p-column-filter" placeholder=""/>
        </template>
      </Column>
             <Column  field="Cust.CustPhone" header="Phone" :sortable=true>
        <template #body="slotProps">
          <span class="p-column-title">Phone</span>
          <span v-if="slotProps.data.Cust != null">{{slotProps.data.Cust.CustPhone }}</span>
        </template>
           <template #filter>
            <InputText type="text" v-model="filters['Cust.CustPhone']" class="p-column-filter" placeholder=""/>
        </template>
      </Column>
             <Column  field="Cust.CustEmail" header="Email" :sortable=true>
        <template #body="slotProps">
          <span class="p-column-title">Email</span>
          <span v-if="slotProps.data.Cust != null">{{slotProps.data.Cust.CustEmail }}</span>
        </template>
           <template #filter>
            <InputText type="text" v-model="filters['Cust.CustEmail']" class="p-column-filter" placeholder=""/>
        </template>
      </Column>
    </DataTable>
        <Dialog
      v-model:visible="showCustDialog"
      :style="{ width: '90vw' }"
      :modal="true"
      :dismissableMask="true"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      {{selectedCust.CustId}}
    </Dialog>
  </div>
</template>

<script>
import BusinessCustomer from '../store/Models/BusinessCustomer';
import { mapState } from "vuex";

export default {
  name: "BusinessClients",
  components: {},
   data() {
    return {
      showCustDialog: false,
      selectedCust: {},
      customers: [],
      filters: {},
      loading:false,
       statuses: ['Processed', 'Pending']
    }
   },
     async mounted() {
    this.$log.debug("business-customers-mounted");
    this.$log.debug(this.currentBusinessId);
    if (this.currentBusinessId > 0) {
      await this.setBusinessCustomers();
    }
  },
  methods: {
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showCustDetails(cust){
      this.selectedCust = cust;
      this.showCustDialog = true;
    },
 async setBusinessCustomers() {
     await BusinessCustomer.fetchByBusId(this.currentBusinessId).then((result) => {
        this.$log.debug(result);
        this.customers = result;
      });
    },

  },
    watch: {
    async currentBusinessId() {
      this.setBusinessCustomers();
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId
    }),
  },
};
</script>
<style scoped>
.p-column-filter {
    width: 100%;
    z-index:1000;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 60em) {
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-thead
    > tr
    > th,
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}
</style>