<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
    <div v-else class="content mt-3">
  <Dialog
    v-model:visible="creatingCustEvent"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '50vw' }"
    :modal="true"
    maximizable
  >
    <template #header>
        <label> {{  $t("custevent.newevent") }}</label>
      </template>
    <div class="p-fluid formgrid grid">
      <div class="field col-12">
        <label for="name"
          >{{ $t("searchresults.event") }} <span class="required-star">*</span></label
        >
        <InputText id="name" type="text" v-model="selectedEvent.EventTitle" />
      </div>

      <div class="field col-12">
        <label for="eventtypes">{{ $t("custevent.eventtype") }}</label>
        <Dropdown
          v-model="selectedEvent.EventType"
          :options="eventTypes"
          :placeholder="$t('services.selectone')"
          optionValue="EventTypeLabel"
        >
          <template #value="slotProps">
            {{ getEventType(slotProps.value) }}
          </template>
          <template #option="slotProps">
            <span>{{ getEventType(slotProps.option.EventTypeLabel) }}</span>
          </template>
        </Dropdown>
      </div>
      <div class="field col-12">
        <label for="eventdate">{{ $t("calendar.date") }}</label>
        <Calendar
          id="selectedEvent.EventDate"
          v-model="selectedEvent.EventDate"
          :showIcon="true"
        />
      </div>
      <div class="field col-6">
        <label for="eventstarttime">{{ $t("calendar.start") }}</label>
        <Calendar
          id="newStartTime"
          v-model="selectedEvent.EventStartTime"
          :timeOnly="true"
          hourFormat="12"
          :stepMinute="30"
          :manualInput="true"
        />
      </div>
      <div class="field col-6">
        <label for="eventendtime">{{ $t("calendar.end") }}</label>
        <Calendar
          id="newEndTime"
          v-model="selectedEvent.EventEndTime"
          :timeOnly="true"
          hourFormat="12"
          :stepMinute="30"
          :manualInput="true"
        />
      </div>
      <div class="field col-6">
        <label for="city">{{ $t("profile.city") }}: </label>
        <InputText id="city" type="text" v-model="selectedEvent.EventCity" />
      </div>
      <div class="field col-6">
        <label for="state">{{ $t("profile.state") }}: </label>
      </div>
    </div>
  </Dialog>
</div>
</template>

<script>
 /* eslint-disable */ 

import LoadingOverlay from "../components/LoadingOverlay.vue";
import Business from "../store/Models/Business";
import Customer from "../store/Models/Customer";
import { mapState } from "vuex";

import CustomerEvent from "../store/Models/CustEvent";
import CustEvent from '../store/Models/CustEvent';
export default {
  name: "CustomerInvitationPage",
  components: {  LoadingOverlay },
  props: ["eventId"],
  data() {
    return {
      currentBusiness: {},
      currentCustomer: {},
      loading: true,
      busFound: false,
    };
  },
  async mounted() {
    this.$log.debug("business-page-mounted");
    this.$log.debug(this.$route.params.eventId);
    await CustEvent.fetchPublicEvent(this.$route.params.eventId).then(
      (result) => {
        this.$log.debug(result);
       //console.log(result);
        if (result != null) {
          this.currentBusiness = result;
          document.title = this.currentBusiness.BusName;
          this.busFound = true;
          this.loading = false;
        } else {
          this.busFound = false;
          this.loading = false;
        }
        
      }
    );

    
  },
  methods: {
    
  },
  watch: {},
  computed: {
    ...mapState({
     
      userLoggedIn: (state) => state.userLoggedIn,
    }),
  },
};
</script>
<style >
.p-fileupload-choose ~ .p-button {
  display: none;
}
.modifyChip.imageChip {
  border-radius: 0vw;
  font-size: medium;
}
.modifyChip.imageChip img {
  height: 3rem;
  width: auto;
}
label {
  font-weight: bold;
}
.p-fileupload .p-fileupload-row {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1px !important;
  margin: 10px;
  width: auto;
  text-align: left;
}

/* .p-message-close.p-link {
      margin-left: auto !important;
      overflow: hidden;
      position: relative;
  } */
/* button.p-button {
	margin-right: .5rem;
}

.p-inputtext {
	margin-right: .25rem;
} */
</style>
