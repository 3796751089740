<template>
  <div v-if = "loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <div class="mt-3" v-if="currentBusiness != null && currentBusiness.BusActive == true && currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode == 'PREMIUM'">
      <Card v-if="currentBusiness.Reviews.length>0" class="text-center mb-5">
        <template #content>
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-3" style="font-size: large">
              <label class="mb-3" style="text">{{$t('message.overall')}}</label><br />
              <span style="font-size: 150%"
                ><strong>{{ currentBusiness.BusRating }}</strong></span
              ><br />
              <Rating
                :modelValue="currentBusiness.BusRating"
                :readonly="true"
                :cancel="false"
              ></Rating>
            </div>
               <div class="col-2 md:col-1 lg:col-1 text-right">
                <div class="mb-1">5 <i class="pi pi-star-fill"></i></div>
                <div class="mb-1">4 <i class="pi pi-star-fill"></i></div>
                <div class="mb-1">3 <i class="pi pi-star-fill"></i></div>
                <div class="mb-1">2 <i class="pi pi-star-fill"></i></div>
                <div class="mb-1">1 <i class="pi pi-star-fill"></i></div>
              </div>
              <div class="col-10 md:col-5 lg:col-8">
               <ProgressBar
                  class="mb-1 excellent"
                  :value="currentBusiness.ExcellentCount"
                >
                 {{ currentBusiness.ExcellentCount }}%
                </ProgressBar>
                <ProgressBar
                  class="mb-1 good"
                  :value="currentBusiness.GoodCount"
                  >{{ currentBusiness.GoodCount }}%</ProgressBar
                >
                <ProgressBar
                  class="mb-1 average"
                  :value="currentBusiness.AverageCount"
                  >{{ currentBusiness.AverageCount }}%</ProgressBar
                >
                <ProgressBar
                  class="mb-1 below"
                  :value="currentBusiness.BelowAvgCount"
                  >{{
                    currentBusiness.BelowAvgCount
                  }}%</ProgressBar
                >
                <ProgressBar
                  class="mb-1 poor"
                  :value="currentBusiness.PoorCount"
                  >{{ currentBusiness.PoorCount }}%</ProgressBar
                >
              
            </div>
          </div>
        </template>
      </Card>
      <div v-else>
        {{$t('businessprofile.noreviews')}}
      </div>
      <div v-for="review in reviews" :key="review.ReviewId">
        <Card class="text-center mb-5">
          <template #title>
            <div class="flex flex-column p-flex-md-row justify-content-between">
              <div class="">
                <Avatar icon="pi pi-user" class="mr-2" size="large" />
                <small
                   v-if="review.Cust!=null">{{ review.Cust.CustFirstName }}
                  {{ review.Cust.CustLastName }}</small
                >
              </div>
              <div class="">
                <Rating
                  style="display: inline"
                  :modelValue="review.ReviewStars"
                  :readonly="true"
                  :cancel="false"
                ></Rating>
              </div>
            </div>
          </template>
          <template #subtitle>
            <div class="flex flex-column p-flex-md-row justify-content-between">
              <div>
                <span>{{ review.ReviewTitle }}</span>
              </div>
              <div class="">
                <em>{{ $formatDateTime(review.ReviewDate) }}</em>
              </div>
            </div>
          </template>
          <template #content>
            {{ review.ReviewText }}
          </template>
        </Card>
      </div>
    </div>
    <div v-else-if="currentBusiness.BusActive == false">
       <inactive-subscription :busId="currentBusiness.BusId"></inactive-subscription>
    </div>
    <div v-else-if="currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode != 'PREMIUM'">
     <!-- <div v-else-if="true"> -->
      <upgrade-subscription
        :subId="currentSubscription.SubId"
      ></upgrade-subscription>
    </div>
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{$t("message.error")}}</Message
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Review from "../store/Models/Review";
import Business from "../store/Models/Business";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import InactiveSubscription from '../components/InactiveSubscription.vue';
import Subscription from '../store/Models/Subscription';
import UpgradeSubscription from '../components/UpgradeSubscription.vue';
// 

export default {
  name: "BusinessReviews",
  components: {LoadingOverlay, InactiveSubscription, UpgradeSubscription},
  data() {
    return {
      reviews: [],
      currentBusiness: {},
      currentSubscriptions:{},
      loading: false
    };
  },
  async mounted() {
    this.$log.debug("reviews-mounted");
    this.$log.debug(this.currentBusinessId);
    if (this.currentBusinessId > 0) {
      await this.setBusReviews();
      await this.setCurrentBusiness();
    }
  },
  methods: {
    
    async setBusReviews() {
      this.loading = true;
      Business.fetchBusinessProfileById(this.currentBusinessId).then((result) => {
        this.currentBusiness = result;
      });
      Subscription.fetchCurrentByBusiness(this.currentBusinessId).then((result) => {
          this.currentSubscription = result;
         //console.log(this.currentSubscription)
        });
      await Review.fetchByBusId(this.currentBusinessId).then((result) => {
        this.$log.debug(result);
        this.reviews = result;
      });
      this.loading = false;
    },
    async setCurrentBusiness() {
      this.currentBusiness = await Business.fetchBusinessProfileById(
        this.currentBusinessId
      );
      this.$log.debug(this.currentBusiness);
    },
  },
  watch: {
    async currentBusinessId() {
      this.setBusReviews();
      this.setCurrentBusiness();
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
