<template>
<div       style=" background-color: #d9d9d9; text-align:center;
background-image: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%); z-index: 300"
    >
 <div
      class="col-12 flex flex-wrap justify-content-center p-5">

    <!-- background-color: #f0f0f0; -->
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-id-card" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("businessprofile.businesspro") }}</template>
        <template #content>
          {{ $t("listmybusiness.profile") }}
        </template>
      </Card>
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-list" style="font-size: 60px"></i>
        </template>
        <template #title>{{ $t("listmybusiness.serviceportfolio") }} </template>
        <template #content>
          {{ $t("listmybusiness.list") }}
        </template>
      </Card>
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-calendar" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("listmybusiness.eventcal") }}</template>

        <template #content>
          {{ $t("listmybusiness.calendarevent") }}
        </template>
      </Card>

      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-comments" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("listmybusiness.directmessages") }}</template>

        <template #content>
          {{ $t("listmybusiness.prospective") }}
        </template>
      </Card>
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-star-fill" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("listmybusiness.customerreviews") }} </template>

        <template #content> {{ $t("listmybusiness.reviews") }} </template>
      </Card>
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-dollar" style="font-size: 60px"></i>
        </template>
        <template #title>{{ $t("listmybusiness.directquotes") }} </template>

        <template #content>
          {{ $t("listmybusiness.provide") }}
        </template>
      </Card>
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-money-bill" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("listmybusiness.onlinepay") }} </template>

        <template #content>
          {{ $t("listmybusiness.online") }}
        </template>
      </Card>

       
    </div>
     <!-- <Button
        :label="$t('menu.viewdetails')"
        class="
          p-button-raised
          p-button-lg
          p-button-primary
          p-button-plain
          mb-5
        "
        @click.stop="$router.push({ name: 'FunctionalityBusiness' })"
        
      /> -->
</div>
</template>
<script>

export default {
  name: "BusinessFeatures",
  data() {
    return {
       };
  },
  mounted() {},
  methods: {
    
  },
};
</script>

<style>
.pi-primary{
  color: var(--mr-primary-color);
}
</style>