<template>
  <div class="home">
  </div>
</template>

<script>



export default {
  name: 'MRSubscription',
  components: {

  }
  
}
</script>
