
/* eslint-disable */
import { Model } from '@vuex-orm/core'
import QuotedService from "./QuotedService"
import Business from "./Business"
import Customer from "./Customer"
import TimeSlot from "./TimeSlot"
import QuoteComment from "./QuoteComment"

//Quote Expired   = "Expired"
//Quote Canceled by client  = "Canceled"
//Quote Requested by  = "Requested"
//Quote Processed by the business = "Processed"
//Quote Accepted by Client = "Accepted" -> Quote become invoice and new reservation created
//Quote Rejected by Client = "Declined" 
export default class Quote extends Model {
  static entity = 'quotes'
  
  static primaryKey = 'QuoteId'
  
  static fields () {
    return {
      QuoteId: this.uid(),
      BusId: this.attr(null),
      CustId:this.attr(null),
      QuoteSubTotal: this.attr(null),
      QuoteDiscountLabel: this.attr(null),
      QuoteDiscount: this.attr(null),
      QuoteTax: this.attr(null),
      QuoteTotal:  this.attr(null), 
      QuoteStatus: this.attr(null),
      QuoteRequestDate: this.attr(null),
      QuoteProcessedDate: this.attr(null),
      QuoteResponseDate:this.attr(null),
      QuoteProcessedBy:this.attr(null),
      QuoteLastUpdated: this.attr(null),
      QuoteNum: this.attr(null),
      ReservId:this.attr(null),
      SlotId: this.attr(null),

      //VIRTUAL
      Bus: this.belongsTo(Business, 'BusId'),
      Cust: this.belongsTo(Customer, 'CustId'),
      Slot: this.belongsTo(TimeSlot, 'SlotId'),
      QuotedServices: this.hasMany(QuotedService, 'QuoteId'),
      QuoteComments: this.hasMany(QuoteComment, 'QuoteId'),

    }
  }
  static async createQuote(quote) {
    //console.log("creating new quote request")
     const result =  await Quote.api().post('/api/quotes/',
      quote).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }

  static async createManualQuote(quote) {
    //console.log("creating new manual quote request")
     const result =  await Quote.api().post('/api/quotes/createmanualquote',
      quote).then((result) => {
        if(result.response.status == 200){
          //console.log("saved")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }

  static async processQuote(quote) {
    //console.log("processing quote")
     const result =  await Quote.api().put('/api/quotes/processquote/' + quote.QuoteId,
      quote).then((result) => {
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }

  static async saveOverwrite(quote) {
    //console.log("processing quote")
     const result =  await Quote.api().put('/api/quotes/BusOverwrite/' + quote.QuoteId,
      quote).then((result) => {
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("saved")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }
  static async respondToQuote(quote, cardId, agreementSignature) {
    //console.log("responding to quote")
     var data ={quote: quote, cardId: cardId, signature: agreementSignature}
     const result =  await Quote.api().put('/api/quotes/respondToQuote/' + quote.QuoteId,
     data).then((result) => {
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }
  static async fetchByCustId(CustId) {
    Quote.deleteAll();
   //console.log("fetch quotes for " + CustId)
   var result = await Quote.api().get('/api/quotes/GetCustomerQuotes/' + CustId);
   //console.log(result)
    return  Quote.query()
      .withAllRecursive()
      .where(quote=>quote.CustId == CustId).orderBy('QuoteId', 'desc').all();
  }

  static async fetchBySlotId(SlotId) {
    Quote.deleteAll();
    //console.log("fetch quotes for " + SlotId)
   var result = await Quote.api().get('/api/quotes/GetQuotesBySlotId/' + SlotId);
    //console.log(result)
    return  Quote.query()
      .withAllRecursive()
      .where(quote=>quote.SlotId == SlotId).orderBy('QuoteId', 'desc').all();
  }

  static async fetchByBusId(BusId) {
    Quote.deleteAll();
    //console.log("fetch quotes for " + BusId)
   var result = await Quote.api().get('/api/quotes/GetBusinessQuotes/' + BusId);
    //console.log(result)
    return  Quote.query()
      .withAllRecursive()
      .where(quote=>quote.BusId == BusId).orderBy('QuoteId', 'desc').all();
  }

  static async pendingBusCount(busId) {
    //console.log("fetching requested count")
     const result =  await Quote.api().get('/api/quotes/GetBusPendingCount/' + busId).then((result) => {
       //console.log(result);
       return result.response.data;
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }
  

}

