import { Model } from '@vuex-orm/core'
/* eslint-disable */
import MessageAttachment from "./MessageAttachment"

export default class Message extends Model {
  static entity = 'messages'
  
  static primaryKey = 'MessageId'
  
  static fields () {
    return {
      MessageId: this.uid(),
      ConvoId: this.attr(null),
      MessageSentBy:this.attr(null), //"customer/business"
      MessageDate: this.attr(null),
      MessageText: this.attr(null),
      MessageRead: this.attr(null),
      MessageAttachments: this.hasMany(MessageAttachment, "MessageId")
    }
  }

  static async newMessage(message) {
    //console.log("sending new message")
     const result =  await Message.api().post('/api/messages/',
     message).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }

  static async unreadMessageCount(custId) {
    //console.log("fetching unread count")
     const result =  await Message.api().get('/api/messages/getunreadcount/' + custId).then((result) => {
       //console.log(result);
       return result.response.data;
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }

  static async unreadBusMessageCount(busId) {
    //console.log("fetching unread count")
     const result =  await Message.api().get('/api/messages/getbusunreadcount/' + busId).then((result) => {
       //console.log(result);
       return result.response.data;
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }

}

