
/* eslint-disable */

import { Model } from '@vuex-orm/core'
import SharedGuestList from "./SharedGuestList"
import GuestParty from "./GuestParty"
import Customer from "./Customer"


export default class GuestList extends Model {
  static entity = 'guestLists'

  static primaryKey = 'ListId'

  static fields() {
    return {
      ListId: this.uid(),
      CustId: this.attr(null),
      ListName: this.attr(null),
      ListDesc: this.attr(null),
      ListTotalParties: this.attr(null),
      ListTotalPeople: this.attr(null),
      ListTotalAdults: this.attr(null),
      ListTotalKids: this.attr(null),
      ListCreated: this.attr(null),
      ListLastModified: this.attr(null),
      ListShared: this.attr(null),


      //VIRTUAL
      Cust: this.belongsTo(Customer, 'CustId'),
      GuestParties: this.hasMany(GuestParty, 'ListId'),
      SharedGuestLists: this.hasMany(SharedGuestList, 'ListId')

    }
  }
  static async createGuestList(guestList) {

   //console.log("creating new guest list")
    const result = await GuestList.api().post('/api/GuestLists/',
      guestList).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if (result.response.status == 200) {
          //console.log("duplicate")
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }
  static async importGuests(listId) {
   //console.log("import guests")
   //console.log(listId)
    const result = await GuestList.api().get('/api/GuestLists/ImportGuests/'+
    listId).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return result.response.data;
        }
        if (result.response.status == 200) {
          //console.log("duplicate")
          return result.response.data;;
        }
        else {
          //console.log("error");
          return null;
        }
      }).catch(error => {
        //console.log(error.response);
        return null;
      });
    return result;
  }

  static async updateGuestList(guestList) {
    //console.log("updating guest list")
    const result = await GuestList.api().put('/api/GuestLists/' + guestList.ListId,
    guestList).then((result) => {
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if (result.response.status == 200) {
          //console.log("saved")
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }

  static async fetchByCustId(CustId) {
    GuestList.deleteAll();
    GuestParty.deleteAll();
    SharedGuestList.deleteAll();
   //console.log("fetch guest lists for " + CustId)
    var result = await GuestList.api().get('/api/GuestLists/GetCustomerGuestLists/' + CustId);
   //console.log(result)
    return GuestList.query()
      .withAllRecursive()
      .orderBy('ListId', 'desc').all();
  }

  static async deleteGuestList(id) {
    //console.log("delete guest list " + id)
    var result = await GuestList.api().delete('/api/GuestLists/' + id).then((result) => {
      if (result.response.status == 204) {
        //console.log("success")
        return 1;
      }
      if (result.response.status == 200) {
        //console.log("duplicate")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }


}

