import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Service from "./Service"

export default class ServiceFavorite extends Model {
  static entity = 'serviceFavorite'

  static primaryKey = 'FavId'

  static fields() {
    return {
      FavId: this.uid(),
      ServId: this.attr(null),
      CustId: this.attr(null),
      Serv: this.belongsTo(Service, 'ServId')
    }
  }
  static async fetchByCustId(CustId) {
    //console.log("fetch serv favorites for " + CustId)
    var result = await ServiceFavorite.api().get('/api/servicefavorites/GetCustomerFavorites/' + CustId);
    //console.log(result)
    return ServiceFavorite.query()
      .withAllRecursive()
      .where(fav => fav.CustId == CustId).orderBy('FavId', 'desc').all();
  }
  
  static async addFavorite(custId, servId) {
    let fav = { FavId: 0, CustId: custId, ServId: servId };
    const result = await ServiceFavorite.api().post('/api/servicefavorites/',
      fav).then((result) => {
        //console.log(result);
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if (result.response.status == 200) {
          //console.log("duplicate")
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }
  static async deleteFavorite(favId) {
    const result = await ServiceFavorite.api().delete('/api/servicefavorites/' + favId,
      { delete: favId }
    )
      .then((result) => {
        //console.log(result);
        if (result.response.status == 200) {
          //console.log("success")
          ServiceFavorite.delete(favId)
          //console.log(ServiceFavorite.all())
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }
  //delete service favorites that have a null serv id
  static async cleanServiceFavorites(custId) {
    //console.log("clean cust service favorites")
    //console.log(custId)

    const result = await ServiceFavorite.api().delete('/api/servicefavorites/cleanCustomerServFavorites/' + custId).then((result) => {
      if (result.response.status == 200) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }

}

