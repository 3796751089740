<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else-if="!userMissingProfile" class="content">
    <div v-if="!showEditProfile" class="text-left">
      <div class="flex flex-wrap align-items-center mb-2">
        <h3>{{ $t("profile.yourprofile") }}</h3>
        <div class="ml-auto" v-if="!showEditProfile">
          <Button
            :label="$t('profile.update')"
            icon="pi pi-pencil"
            iconPos="left"
            @click.stop="showEditProfile = true"
            class="mr-2 p-button-Fsm p-button-outlined p-button-primary"
          />
        </div>
      </div>
      <Card class="mt-3">
        <template #content>
          <div style="text-align: center">
            <img
              v-if="tempCustomer.CustProfImage != null"
              :src="imageHostingURL + tempCustomer.CustProfImage"
              :alt="tempCustomer.CustProfImage"
              width="100"
              class="mr-3"
            />
            <Avatar v-else icon="pi pi-user" class="mr-2" size="xlarge" />
            <div style="font-size: large">
              <b>
                {{ tempCustomer.CustFirstName }}
                {{ tempCustomer.CustLastName }}</b
              >
            </div>
            <div
              class="
                flex flex-wrap
                align-items-center align-content-center
                justify-content-around
                mb-2
              "
            >
              <span class="m-2" style="font-size: small">
                <i class="pi pi-mobile"></i> {{ tempCustomer.CustPhone }}</span
              >
              <span class="m-2">
                <i class="pi pi-envelope"></i> {{ tempCustomer.CustEmail }}
              </span>
              <span class="m-2">
                <i class="pi pi-map-marker"></i>
                {{ tempCustomer.CustAddress }}<br />
                {{ tempCustomer.CustCity }} {{ tempCustomer.CustState }},
                {{ tempCustomer.CustZip }}<br />
              </span>
              <span class="m-2">
                <i class="pi pi-globe"></i>
                <span v-if="tempCustomer.CustLang == 'es'">Español</span>
                <span v-if="tempCustomer.CustLang == 'en'">English</span>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div v-else>
      <Card class="mt-3 mb-3">
        <template #content>
          <div class="p-fluid formgrid grid text-left">
            <div class="field col-12">
              <label>{{ $t("profile.picture") }}</label>
            </div>
            <div
              v-if="hideProfImageUpload && tempCustomer.CustProfImage != null"
            >
              <img
                v-if="tempCustomer.CustProfImage != null && cropimage == null"
                :src="imageHostingURL + tempCustomer.CustProfImage"
                :alt="tempCustomer.CustEmail"
                width="100"
              />
              <img
                v-if="cropimage != null"
                :src="cropimage"
                :alt="tempCustomer.CustEmail"
                width="100"
              />
              <span class="p-buttonset">
                <Button
                  class="p-button-info p-button-sm"
                  :label="$t('profile.replacepicture')"
                  @click.stop="replaceProfImage()"
                  icon="pi pi-image"
                />
                <Button
                  v-if="cropimage != null"
                  class="p-button-info p-button-sm"
                  :label="$t('profile.adjust')"
                  @click.stop="showCropper = true"
                  icon="pi pi-window-minimize"
                />
              </span>
            </div>
            <div class="field col-12">
              <FileUpload
                v-if="!hideProfImageUpload"
                name="logo"
                :maxFileSize="9000000"
                :fileLimit="1"
                accept="image/*"
                :multiple="false"
                :auto="true"
                :customUpload="true"
                @uploader="uploadProfImage"
              >
                <template #empty>
                  <p>{{ $t("profile.draganddrop") }}</p>
                </template>
              </FileUpload>
              <Dialog
                v-model:visible="showCropper"
                align="center"
                :contentStyle="{ overflow: 'scroll' }"
                style="text-align: left"
                :header="$t('businessprofile.cropper')"
                :dismissableMask="true"
                :style="{ width: '50vw' }"
                :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
              >
                <cropper
                  v-if="
                    tempCustomer.NewProfPicture != null &&
                    tempCustomer.NewProfPicture.length > 0 &&
                    hideProfImageUpload == true
                  "
                  class="cropper"
                  :src="tempCustomer.NewProfPicture[0].objectURL"
                  :stencil-props="{
                    aspectRatio: 1 / 1,
                  }"
                  @change="change"
                />
                <Button
                  :label="$t('menu.done')"
                  icon="pi pi-check"
                  iconPos="right"
                  @click.stop="showCropper = false"
                  class="mr-2 mt-2 p-button-success p-button-sm p-button-raised"
                />
              </Dialog>
              <!-- <img v-if="cropimage != null" :src="cropimage"> -->
            </div>
            <div class="field col-12 md:col-6">
              <label for="first"
                >{{ $t("profile.firstname") }}:
                <span class="required-star">*</span>
              </label>
              <InputText
                id="first"
                type="text"
                v-model="tempCustomer.CustFirstName"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label for="last"
                >{{ $t("profile.lastname") }}:
                <span class="required-star">*</span>
              </label>
              <InputText
                id="last"
                type="text"
                v-model="tempCustomer.CustLastName"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label for="phone"
                >{{ $t("profile.phonenumber") }}:
                <span class="required-star">*</span>
              </label>
              <InputMask
                id="phone"
                mask="(999) 999-9999"
                v-model="tempCustomer.CustPhone"
                placeholder="(999) 999-9999"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label for="language">{{ $t("profile.language") }}: </label>
              <Dropdown
                :options="languages"
                optionLabel="desc"
                optionValue="code"
                id="state"
                v-model="tempCustomer.CustLang"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label for="address"
                >{{ $t("profile.address") }}:
                <span class="required-star">*</span></label
              >
              <vue-google-autocomplete
                id="address"
                classname="p-inputtext"
                :placeholder="tempCustomer.CustAddress"
                v-on:placechanged="setCustomerAddress"
                :country="['us']"
              >
              </vue-google-autocomplete>
            </div>
            <div class="field col-12 md:col-6">
              <label for="city"
                >{{ $t("profile.city") }}:
                <span class="required-star">*</span></label
              >
              <InputText
                id="city"
                type="text"
                v-model="tempCustomer.CustCity"
              />
            </div>
            <div class="field col-6">
              <label for="state"
                >{{ $t("profile.state") }}:
                <span class="required-star">*</span></label
              >

              <Dropdown
                :options="compStates"
                optionLabel="code"
                optionValue="code"
                id="state"
                v-model="tempCustomer.CustState"
              />
            </div>
            <div class="field col-6">
              <label for="zip"
                >{{ $t("profile.zip") }}: <span class="required-star">*</span>
              </label>
              <InputText id="zip" type="text" v-model="tempCustomer.CustZip" />
            </div>
            <!-- <div class="col-12" v-if="errors.length > 0">
              <Message severity="error" style="text-align: left">
                <ul>
                  <li v-for="error of errors" :key="error">{{ error }}</li>
                </ul></Message
              >
            </div> -->
            <div class="col-12 flex mt-3">
              <Button
                :label="$t('profile.cancel')"
                icon="pi pi-times"
                @click.stop="resetCustomerUpdate"
                class="p-button-secondary mr-3"
              />
              <Button
                :label="$t('profile.save')"
                icon="pi pi-check"
                class="p-button-primary"
                @click.stop="validateCustomerSave"
                autofocus
              />
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import Customer from "../store/Models/Customer";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import { Cropper } from "vue-advanced-cropper";

import VueGoogleAutocomplete from "vue-google-autocomplete";
let states = require("../data/states.js");
export default {
  name: "CustomerProfile",
  components: { VueGoogleAutocomplete, LoadingOverlay, Cropper },
  data() {
    return {
      showCropper: false,
      cropimage: null,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      hideProfImageUpload: false,
      errors: [],
      showEditProfile: false,
      custAddrValidated: false,
      tempCustomer: {
        CustId: 0,
        CustFirstName: "",
        CustLastName: "",
        CustAddress: "",
        CustCity: "",
        CustState: "",
        CustZip: "",
        CustPhone: "",
        CustEmail: "",
        NewProfPicture: [],
      },
      loading: false,
      languages: [
        { code: "en", desc: "English" },
        { code: "es", desc: "Español" },
      ],
    };
  },
  async mounted() {
    this.$log.debug("customer profile mounted");
    this.$log.debug(this.currentCustomerId);
    if (this.userMissingProfile) {
      this.tempCustomer.CustEmail = this.getCurrentADUser.username;
    } else {
      if (this.currentCustomerId != 0) this.setCurrentCustomer();
    }
  },
  methods: {
    change({ coordinates, canvas }) {
      this.$log.debug(coordinates, canvas);
      this.coordinates = coordinates;
      this.cropimage = canvas.toDataURL();
    },
    ...mapActions(["refreshUserData"]),
    setCustomerAddress: function (addressData) {
      this.$log.debug(addressData);
      this.tempCustomer.CustAddress =
        addressData.street_number + " " + addressData.route;
      this.tempCustomer.CustCity = addressData.locality;
      this.tempCustomer.CustState = addressData.administrative_area_level_1;
      this.tempCustomer.CustZip = addressData.postal_code;
      this.custAddrValidated = true;
    },
    replaceProfImage() {
      this.hideProfImageUpload = false;
    },
    uploadProfImage(event) {
      this.$log.debug("upload profile image event");
      this.$log.debug(event);
      if (event.files != null) {
        this.tempCustomer.NewProfPicture = event.files;
        this.hideProfImageUpload = true;
        this.showCropper = true;
      }
    },
    /* Utility function to convert a canvas to a BLOB */
    dataURLToBlob(dataURL) {
      var BASE64_MARKER = ";base64,";
      var parts = "";
      var contentType = "";
      var raw = "";
      if (dataURL != null && dataURL.indexOf(BASE64_MARKER) == -1) {
        parts = dataURL.split(",");
        contentType = parts[0].split(":")[1];
        raw = parts[1];

        return new Blob([raw], { type: contentType });
      }

      parts = dataURL.split(BASE64_MARKER);
      contentType = parts[0].split(":")[1];
      raw = window.atob(parts[1]);
      var rawLength = raw.length;

      var uInt8Array = new Uint8Array(rawLength);

      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },
    /* End Utility function to convert a canvas to a BLOB      */
    validateCustomerSave() {
      this.$log.debug("validate customer save");
      this.$log.debug(this.tempCustomer);
      this.errors = [];
      if (this.cropimage != null) {
        this.tempCustomer.NewProfPicture[0] = this.dataURLToBlob(
          this.cropimage
        );
      }
      this.$log.debug(this.custAddrValidated);

      if (this.custAddrValidated == true) {
        this.tempCustomer.CustAddress =
          document.getElementById("address").value;
      }
      else
      //verify required values
      if (this.tempCustomer.CustFirstName.length == 0) {
        this.errors.push(this.$t("listmybusiness.rebusname"));
        document.getElementById("first").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustLastName.length == 0) {
        this.errors.push(this.$t("listmybusiness.relname"));
        document.getElementById("last").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustPhone.length == 0) {
        this.errors.push(this.$t("listmybusiness.rephone"));
        document.getElementById("phone").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustAddress.length == 0) {
        this.errors.push(this.$t("listmybusiness.readdress"));
        document.getElementById("address").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustCity.length == 0) {
        this.errors.push(this.$t("listmybusiness.recity"));
        document.getElementById("city").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustState.length == 0) {
        this.errors.push(this.$t("listmybusiness.readdress"));
        document.getElementById("state").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustZip.length == 0) {
        this.errors.push(this.$t("listmybusiness.zip"));
        document.getElementById("zip").classList.add("p-invalid");
      }
      this.$log.debug(this.errors);

      if (this.errors.length == 0) {
        if (this.tempCustomer.CustId > 0) {
          this.updateCustomer();
        } else {
          this.createCustomer();
        }
      }
    },
    async updateCustomer() {
      this.$log.debug("update customer");
      this.loading = true;
      await Customer.updateCustomer(this.tempCustomer).then(
        async (response) => {
          if (response == 1) {
            this.refreshUserData(this.tempCustomer.CustId);
            await this.setCurrentCustomer();
            this.showEditProfile = false;
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.profileupdate"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.profilefailedsave"),
              life: 3000,
            });
          }
          this.loading = false;
        }
      );
    },
    async createCustomer() {
      this.$log.debug("create customer");
      this.loading = true;
      await Customer.createCustomer(this.tempCustomer).then(
        async (response) => {
          if (response == 1) {
            this.tempCustomer = await Customer.fetchFullByEmail(
              this.tempCustomer.CustEmail
            );
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.profileupdate"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.profilefailedsave"),
              life: 3000,
            });
          }
          this.loading = false;
        }
      );
    },
    resetCustomerUpdate() {
      this.setCurrentCustomer();
      this.errors = [];
      this.showEditProfile = false;
    },
    async setCurrentCustomer() {
      this.loading = true;
      await Customer.getCurrentCustomer().then(async (response) => {
        this.$log.debug("Get current customer");
       //console.log(response);
        this.tempCustomer = response;
        if (this.tempCustomer.CustProfImage != null) {
          this.hideProfImageUpload = true;
        } else {
          this.hideProfImageUpload = false;
        }
        this.loading = false;
      });
    },
    setLang() {
      this.$log.debug(this.lang);
      if (this.lang == "en") {
        this.$i18n.locale = "en";
      }
      if (this.lang == "es") {
        this.$i18n.locale = "es";
      }
      document.getElementsByTagName("html")[0].lang = this.lang;
    },
  },
  computed: {
    ...mapState({
      userMissingProfile: (state) => state.userMissingProfile,
      currentCustomerId: (state) => state.currentCustomerId,
      lang: (state) => state.lang,
    }),
    ...mapGetters(["getCurrentADUser"]),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
    compStates() {
      return states;
    },
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCurrentCustomer();
    },
    lang() {
      this.$log.debug("lang changed");
      this.setLang();
    },
    // userMissingProfile(newValue) {
    //   this.$log.debug("user missing profile loaded: " + newValue);
    //   if(newValue){
    //       this.tempCustomer.CustEmail = this.getCurrentADUser.username;
    //   }
    //   else{
    //       this.tempCustomer = Customer.getCurrentCustomer();
    //   }
    // },
  },
};
</script>
<style>
.cropper {
  height: 45vh;
  width: 45vh;
  background: #ddd;
  position: relative;
  overflow: auto;
}
</style>