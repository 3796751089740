/* eslint-disable */
import { Model } from '@vuex-orm/core'
import Business from "./Business"
import Customer from "./Customer"

export default class BusinessStaff extends Model {
  static entity = 'businessStaff'
  
  static primaryKey = 'BusStaffId'
  
  static fields () {
    return {
      BusStaffId: this.uid(),
      BusId: this.attr(null),
      CustId: this.attr(null),
      BusStaffActive: this.attr(null),
      BusStaffActiveSince: this.attr(null),
      BusStaffInActiveSince: this.attr(null),

      //virtual
      Bus: this.belongsTo(Business, 'BusId'),
      Cust: this.belongsTo(Customer, 'CustId')
    }
  }

  static async fetchByBusId(BusId) {
    //console.log("fetch business staff for " + BusId)
   var result = await BusinessStaff.api().get('/api/BusinessStaffs/GetBusStaff/' + BusId);
    //console.log(result)
    return  BusinessStaff.query()
      .withAllRecursive()
      .where(bc=>bc.BusId == BusId).orderBy('BusStaffId', 'desc').all();
  }

  static async fetchByCustId(CustId) {
    //console.log("fetch cust business staff roles for " + CustId)
    BusinessStaff.deleteAll();
   var result = await BusinessStaff.api().get('/api/BusinessStaffs/GetCustBusStaff/' + CustId);
    //console.log(result)
    return  BusinessStaff.query()
      .withAllRecursive()
      .where(bc=>bc.CustId == CustId).orderBy('BusStaffId', 'desc').all();
  }
  
  static async addStaff(busId, custEmail) {
    var data = new FormData();
    data.append('custEmail', custEmail);
    const result = await BusinessStaff.api().post('/api/businessstaffs/PostBusinessStaff/'+busId,data).then((result) => {
      //console.log(result);
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if (result.response.status == 200) {
          //console.log("duplicate")
          return 3;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }

  static async deleteBusStaff(busStaffId) {
    const result = await BusinessStaff.api().delete('/api/businessstaffs/' + busStaffId
    )
      .then((result) => {
        //console.log(result);
        if (result.response.status == 200 || result.response.status == 204) {
          //console.log("success")
          BusinessStaff.delete(busStaffId)
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }
}

