<template>
  <div id="navigation">
    <div
      class="flex p-3 shadow-2 align-items-center justify-content-between mb-1"
      style="background: white"
    >
      <!-- Replace with MRHome when releasing search engine -->
      <div
        class="hidden md:inline-flex pointer"
        @click.stop="this.$router.push({ name: 'Home' })"
      >
        <img alt="logo" src="@/assets/TextNoBack.png" height="40" />
      </div>
      <div
        class="md:hidden pointer"
        @click.stop="this.$router.push({ name: 'Home' })"
      >
        <img
          class="mr-2"
          alt="logo"
          src="@/assets/LogoNoBack.png"
          height="50"
        />
      </div>
      <div v-if="userLoggedIn" class="hidden md:col-none lg:inline-flex">
        <div class="flex justify-content-center align-items-center">
          <Button
            id="home"
            :label="$t('menu.home')"
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            :class="{ active: activeMenuId === 'home' }"
            icon="pi pi-home"
            @click.stop="navigate('CustomerHome', 'home')"
          />
          <Button
            id="reserv"
            :label="$t('businessprofile.reservations')"
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            :class="{ active: activeMenuId === 'reserv' }"
            icon="pi pi-ticket"
            @click.stop="navigate('CustomerReservations', 'reserv')"
          />
          <Button
            id="quotes"
            :label="$t('businessprofile.quotes')"
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            :class="{ active: activeMenuId === 'quotes' }"
            icon="pi pi-dollar"
            @click.stop="navigate('CustomerQuotes', 'quotes')"
          />
              <Button
            id="orders"
            :label="$t('store.storeorders')"
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            :class="{ active: activeMenuId === 'orders' }"
            icon="pi pi-shopping-cart"
            @click.stop="navigate('CustomerStoreOrders', 'orders')"
          />
          <Button
            id="fav"
            :label="$t('menu.favorites')"
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            :class="{ active: activeMenuId === 'fav' }"
            icon="pi pi-heart"
            @click.stop="navigate('CustomerFavorites', 'fav')"
          />
          <Button
            id="mess"
            v-if="unreadMessageCount > 0"
            :label="$t('businessprofile.messages')"
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            icon="pi pi-comments"
            :badge="unreadMessageCount"
            badgeClass="p-badge p-badge-primary"
            @click.stop="
              unreadMessageCount = 0;
              navigate('CustomerMessages', 'mess');
            "
          />
          <Button
            v-else
            id="mess"
            :label="$t('businessprofile.messages')"
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            :class="{ active: activeMenuId === 'mess' }"
            icon="pi pi-comments"
            @click.stop="
              unreadMessageCount = 0;
              navigate('CustomerMessages', 'mess');
            "
          />

          <Button
            id="notifications"
            v-if="unreadNotifications > 0"
            :label="$t('menu.notifications')"
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            icon="pi pi-bell"
            :badge="unreadNotifications"
            badgeClass="p-badge p-badge-primary"
            @click.stop="showingNotifications = true"
          />
          <Button
            v-else
            id="notifications2"
            :label="$t('menu.notifications')"
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            icon="pi pi-bell"
            @click.stop="showingNotifications = true"
          />
          <Button
            id="mess"
            :label="$t('menu.appts')"
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            icon="pi pi-calendar-plus"
            badgeClass="p-badge p-badge-primary"
            @click.stop="
              navigate('CustomerAppointments', 'appts');
            "
          />
          <Button
            id="bus"
            v-if="customerBusinesses != null && customerBusinesses.length > 0"
            :label="$t('menu.business')"
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            :class="{ active: activeMenuId === 'bus' }"
            icon="pi pi-briefcase"
            @click.stop="navigate('BusinessDashboard', 'bus')"
          />
          <Button
            id="acct1"
            :label="
              currentCustomer != null && currentCustomer.CustFirstName != null
                ? currentCustomer.CustFirstName
                : $t('menu.account')
            "
            class="
              menu-button
              p-button-text p-button-secondary p-button-sm
              xlarge-icon
            "
            :class="{ active: activeMenuId === 'acct' }"
            icon="pi pi-user"
            @click.stop="showAccountMenu"
            aria-haspopup="true"
            aria-controls="overlay_menu"
          />
        </div>
        <div
          v-if="!userLoggedIn"
          class="hidden md:col-none lg:inline-flex"
          style="color: black"
        >
          <login></login>
          <!-- <Button
            :label="$t('menu.createacc')"
            class="menu-button p-button-text p-button-sm"
            @click.stop="SignIn"
          />
          <Button
            label="Log In"
            class="menu-button p-button-text p-button-sm"
            @click.stop="SignIn"
          /> -->
        </div>
      </div>
      <div v-else class="hidden md:col-none lg:inline-flex">
        <login></login>
      </div>
      <!-- UNCOMMENT WHEN RELEASING SEARCH  -->
      <!-- <div>
        <div class="p-inputgroup" style="height: 40px">
          <span class="p-float-label">
            <InputText
              type="text"
              class="small-text-placeholder"
              :placeholder="$t('searchresults.planning')"
              v-model="searchWords"
              @keyup.enter="
                this.$router.push({
                  name: 'SearchResults',
                  params: { searchPhrase: searchWords },
                })
              "
            />
          </span>
          <span class="p-inputgroup-addon">
            <Button
              icon="pi pi-search"
              class="p-button-text p-button-primary"
              @click.stop="
                this.$router.push({
                  name: 'SearchResults',
                  params: { searchPhrase: searchWords },
                })
              "
            />
          </span>
        </div>
      </div> -->
    </div>
    <div v-if="!userLoggedIn" class="lg:hidden shadow-2">
      <login></login>
      <!-- <Button
        label="Create Account"
        class="menu-button p-button-text p-button-sm"
        @click.stop="SignIn"
      />
      <Button
        label="Log In"
        class="menu-button p-button-text p-button-sm"
        @click.stop="SignIn"
      /> -->
    </div>

    <div
      class="lg:hidden freeze pt-1 shadow-2"
      style="font-size: x-small; background-color: #f8f9fa"
      v-if="userLoggedIn"
    >
      <div class="flex flex-wrap justify-content-around">
        <div
          class="m-1 menu-button"
          @click.stop="navigate('CustomerHome', 'home')"
          :class="{ active: activeMenuId === 'home' }"
        >
          <i class="pi pi-home" style="font-size: x-large; display: block"></i
          >{{ $t("menu.home") }}
        </div>
        <div
          class="m-1 menu-button"
          @click.stop="navigate('CustomerReservations', 'reserv')"
          :class="{ active: activeMenuId === 'reserv' }"
        >
          <i class="pi pi-ticket" style="font-size: x-large; display: block"></i
          >{{ $t("menu.reservs") }}
        </div>

        <div
          class="m-1 menu-button"
          @click.stop="navigate('CustomerQuotes', 'quotes')"
          :class="{ active: activeMenuId === 'quotes' }"
        >
          <i class="pi pi-dollar" style="font-size: x-large; display: block"></i
          >{{ $t("menu.quotes") }}
        </div>
            <!-- <div
          class="m-1 menu-button"
          @click.stop="navigate('CustomerStoreOrders', 'orders')"
          :class="{ active: activeMenuId === 'orders' }"
        >
          <i class="pi pi-shopping-cart" style="font-size: x-large; display: block"></i
          >{{ $t("store.storeorders") }}
        </div> -->
        <div
          class="m-1 menu-button"
          @click.stop="navigate('CustomerFavorites', 'fav')"
          :class="{ active: activeMenuId === 'fav' }"
        >
          <i class="pi pi-heart" style="font-size: x-large; display: block"></i
          >{{ $t("menu.favorites") }}
        </div>
        <div
          class="m-1 menu-button"
          @click.stop="
            unreadMessageCount = 0;
            navigate('CustomerMessages', 'mess');
          "
          :class="{ active: activeMenuId === 'mess' }"
        >
          <i
            class="pi pi-comments"
            v-if="unreadMessageCount > 0"
            v-badge="unreadMessageCount"
            style="font-size: x-large; display: block"
          ></i>
          <i
            v-else
            class="pi pi-comments"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("menu.message") }}
        </div>
        <div class="m-1 menu-button" @click.stop="showingNotifications = true">
          <i
            class="pi pi-bell"
            v-if="unreadNotifications > 0"
            v-badge="unreadNotifications"
            style="font-size: x-large; display: block"
          ></i
          ><i
            v-else
            class="pi pi-bell"
            style="font-size: x-large; display: block"
          ></i
          >{{ $t("menu.notifications") }}
        </div>

        <div
          class="m-1 menu-button"
          @click.stop="showMoreMenu"
          aria-haspopup="true"
          aria-controls="more_menu"
        >
          <i
            class="pi pi-ellipsis-h"
            style="font-size: x-large; display: block"
          ></i>
          {{ $t("menu.more") }}
        </div>
      </div>
    </div>
  </div>
  <Sidebar
    :header="'Notifications'"
    v-model:visible="showingNotifications"
    :baseZIndex="1000"
    position="right"
    @hide="markAsRead"
  >
    <notifications
      :data="notifications"
      v-on:hide="showingNotifications = false"
    >
    </notifications>
  </Sidebar>
  <OverlayPanel ref="mnuOverlay">
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerProfile', '')"
    >
      <i class="pi pi-id-card mr-2" style="font-size: x-large"></i
      >{{ this.$t("businessprofile.profile") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerGuestList', '')"
    >
      <i class="pi pi-list mr-2" style="font-size: x-large"></i
      >{{ this.$t("guestlists.guestlists") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerPaymentCards', '')"
    >
      <i class="pi pi-money-bill mr-2" style="font-size: x-large"></i
      >{{ this.$t("payments.paymentcard") }}
    </div>
    <div
      v-if="customerBusinesses.length > 0"
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('BusinessSubscriptions', '')"
    >
      <i class="pi pi-shield mr-2" style="font-size: x-large"></i
      >{{ this.$t("subscriptions.businesssubscriptions") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="switchLanguage()"
    >
      <i class="pi pi-sync mr-2" style="font-size: x-large"></i
      >{{ this.$t("profile.otherlanguage") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="$SignOutRedirect()"
    >
      <i class="pi pi-sign-out mr-2" style="font-size: x-large"></i
      >{{ this.$t("menu.signout") }}
    </div>
  </OverlayPanel>
  <OverlayPanel ref="mnuMoreOverlay">
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerStoreOrders', '')"
    >
      <i class="pi pi-shopping-cart mr-2" style="font-size: x-large"></i
      >{{ this.$t("store.storeorders") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerAppointments', '')"
    >
      <i class="pi pi-calendar-plus mr-2" style="font-size: x-large"></i
      >{{ this.$t("menu.appts") }}
    </div>
    <div
      v-if="
        this.customerBusinesses != null && this.customerBusinesses.length > 0
      "
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('BusinessDashboard', '')"
    >
      <i class="pi pi-briefcase mr-2" style="font-size: x-large"></i
      >{{ this.$t("menu.mybusiness") }}
    </div>

    <div style="font-size: larger">
      <b>{{ $t("menu.account") }}</b>
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerProfile', '')"
    >
      <i class="pi pi-id-card mr-2" style="font-size: x-large"></i
      >{{ this.$t("businessprofile.profile") }}
    </div>
    <!-- <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerGuestList', '')"
    >
      <i class="pi pi-list mr-2" style="font-size: x-large"></i
      >{{ this.$t("guestlists.guestlists") }}
    </div> -->
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerPaymentCards', '')"
    >
      <i class="pi pi-money-bill mr-2" style="font-size: x-large"></i
      >{{ this.$t("payments.paymentcard") }}
    </div>
    <div
      v-if="customerBusinesses.length > 0"
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('BusinessSubscriptions', '')"
    >
      <i class="pi pi-shield mr-2" style="font-size: x-large"></i
      >{{ this.$t("subscriptions.businesssubscriptions") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="switchLanguage()"
    >
      <i class="pi pi-sync mr-2" style="font-size: x-large"></i
      >{{ this.$t("profile.otherlanguage") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="
        $refs.mnuMoreOverlay.hide();
        $refs.mnuOverlay.hide();
        $SignOutRedirect();
      "
    >
      <i class="pi pi-sign-out mr-2" style="font-size: x-large"></i
      >{{ this.$t("menu.signout") }}
    </div>
  </OverlayPanel>
  <Menu id="overlay_menu" ref="acctMenu" :model="accountMenu" :popup="true" />
  <Menu id="more_menu" ref="mreMenu" :model="moreMenu" :popup="true" />
</template>
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
import Login from "../components/Login.vue";
import Notifications from "../components/Notifications.vue";
import Notification from "../store/Models/Notification";
import Customer from "../store/Models/Customer";
import Message from "../store/Models/Message";
export default {
  name: "MRNavigation",
  emits: [],
  components: { Notifications, Login },
  data() {
    return {
      notifications: [],
      showLoginModal: false,
      searchWords: "",
      otherLang: "",
      showingNotifications: false,
      unreadMessageCount: 0,

      accountMenu: [
        {
          label: this.$t("businessprofile.profile"),
          icon: "pi pi-id-card",
          to: "/CustomerProfile",
        },
        {
          label: this.$t("guestlists.guestlists"),
          icon: "pi pi-list",
          to: "/CustomerGuestList",
        },
        {
          label: this.$t("payments.paymentcard"),
          icon: "pi pi-money-bill",
          to: "/CustomerPaymentCards",
        },
        {
          label: this.$t("subscriptions.businesssubscriptions"),
          icon: "pi pi-shield",
          to: "/BusinessSubscriptions",
        },
        {
          label: this.$t("profile.otherlanguage"),
          icon: "pi pi-sync",
          command: () => {
            this.switchLanguage();
          },
        },
        {
          label: this.$t("menu.signout"),
          icon: "pi pi-sign-out",
          command: () => {
            this.$SignOutRedirect();
          },
        },
      ],
      moreMenu: [
        {
          label: this.$t("menu.appts"),
          icon: "pi pi-calendar-plus",
          to: "/CustomerAppointments",
        },
        {
          label: this.$t("menu.mybusiness"),
          icon: "pi pi-briefcase",
          to: "/BusinessDashboard",
          visible: () =>
            this.customerBusinesses != null &&
            this.customerBusinesses.length > 0,
        },

        {
          label: this.$t("menu.account"),
          items: [
            {
              label: this.$t("businessprofile.profile"),
              icon: "pi pi-id-card",
              to: "/CustomerProfile",
            },
            {
              label: this.$t("payments.paymentcard"),
              icon: "pi pi-money-bill",
              to: "/CustomerPaymentCards",
            },
            {
              label: this.$t("subscriptions.businesssubscriptions"),
              icon: "pi pi-shield",
              to: "/BusinessSubscriptions",
            },
            {
              label: this.$t("profile.otherlanguage"),
              icon: "pi pi-sync",
              command: () => {
                this.switchLanguage();
              },
            },
            {
              label: this.$t("menu.signout"),
              icon: "pi pi-sign-out",
              command: () => {
                this.$SignOutRedirect();
              },
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.$log.debug("navigation mounted");
    this.setLang();
    if (this.currentCustomerId > 0 && this.userLoggedIn == true) {
      this.setNotifications();
      this.getUnreadMessageCount();
    }
  },
  methods: {
    ...mapActions(["switchLang"]),
    async getUnreadMessageCount() {
      await Message.unreadMessageCount(this.currentCustomerId).then(
        (result) => {
          this.$log.debug("unread count");
          this.$log.debug(result);
          this.unreadMessageCount = result;
        }
      );
    },
    async updateCustomer(newLang) {
      this.$log.debug("update customer");
      await Customer.getCurrentCustomer().then(async (response) => {
        this.$log.debug("Get current customer");
        var tempCustomer = response;
        tempCustomer.CustLang = newLang;
        Customer.updateCustomer(tempCustomer).then(async (response) => {
          if (response == 1) {
            this.refreshUserData(this.tempCustomer.CustId);
            await this.setCurrentCustomer();
            this.showEditProfile = false;
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.profileupdate"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.profilefailedsave"),
              life: 3000,
            });
          }
          this.loading = false;
        });
      });
    },
    showAccountMenu(event) {
      this.$refs.mnuOverlay.toggle(event);
    },
    showMoreMenu(event) {
      this.$refs.mnuMoreOverlay.toggle(event);
    },
    async SignIn() {
      window.sessionStorage.setItem("redirecturl",this.$route.path);
      window.sessionStorage.setItem("requestedlogin","false");
      this.$router.push({
        name: "Login"
      });
      // this.$RedirectSignIn();
    },
    async SignOut() {
      this.$SignOutRedirect();
    },
    navigate(toRoute, activeId) {
      this.$log.debug(toRoute);
      this.$log.debug(activeId);
      // this.activeMenuId = activeId;
      this.$refs.mnuMoreOverlay.hide();
      this.$refs.mnuOverlay.hide();
      this.$router.push({ name: toRoute });
    },
    signInEvent: function () {
      this.$log.debug("sign in");
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    async markAsRead() {
      this.$log.debug("marking as read");
      await Notification.markAsRead(this.currentCustomerId).then(() => {
        this.setNotifications();
      });
    },
    setNotifications() {
      this.notifications = [];
      if (this.currentCustomerId > 0) {
        Notification.fetchByCustId(this.currentCustomerId).then((result) => {
          this.notifications = result;
        });
      }
    },
    setLang() {
      this.$log.debug(this.lang);
      if (this.lang == "en") {
        this.otherLang = "Español";
        // this.$i18n.locale = "en"; done on app
      }
      if (this.lang == "es") {
        this.otherLang = "English";
        // this.$i18n.locale = "es"; done on app
      }
      document.getElementsByTagName("html")[0].lang = this.lang;
    },
    switchLanguage() {
      this.$refs.mnuMoreOverlay.hide();
      var newLang = "en";
      if (this.lang == "en") {
        newLang = "es";
        this.otherLang = "English";
      } else if (this.lang == "es") {
        newLang = "en";
        this.otherLang = "Español";
      }

      this.switchLang(newLang);
      this.updateCustomer(newLang);
      this.$i18n.locale = newLang;
    },
  },
  watch: {
    async currentCustomerId() {
      this.$log.debug("cust id changed");
      if (this.currentCustomerId > 0) {
        this.setNotifications();
      } else {
        this.notifications = [];
      }
    },
    lang() {
      this.$log.debug("lang changed");
      this.setLang();
    },
  },
  computed: {
    ...mapState({
      userLoggedIn: (state) => state.userLoggedIn,
      customerBusinesses: (state) => state.customerBusinesses,
      currentCustomerId: (state) => state.currentCustomerId,
      currentCustomer: (state) => state.currentCustomer,
      lang: (state) => state.lang,
    }),
    activeMenuId() {
      if (this.$route.path === "/CustomerReservations") {
        return "reserv";
      } else if (this.$route.path === "/CustomerQuotes") {
        return "quotes";
      } else if (this.$route.path === "/CustomerFavorites") {
        return "fav";
      } else if (this.$route.path === "/CustomerMessages") {
        return "mess";
      } else if (this.$route.path === "/BusinessDashboard") {
        return "bus";
      } else if (this.$route.path === "/CustomerProfile") {
        return "acct";
      } else if (this.$route.path === "/CustomerAppointments") {
        return "appts";
      } else return "";
    },
    unreadNotifications() {
      return this.notifications.filter((notice) => notice.NoticeRead == false)
        .length;
    },
  },
};
</script>
<style>
.block-button {
  display: block !important;
  text-align: center;
}
p-tieredmenu {
  text-align: right;
}
.small-text-placeholder::placeholder {
  font-size: small;
}
#navigation .menu-button.active,
#navigation button:hover,
#navigation .menu-button:hover {
  color: var(--mr-primary-color);
  font-weight: bold;
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  border-bottom-style: solid;
  border-color: var(--mr-primary-color);
  border-width: 2px;
  border-radius: 0vw;
  background-color: transparent;
}

#navigation button,
#navigation .menu-button {
  color: #495057;
  cursor: pointer;
  width: max-content;
}
#navigation button:focus,
#navigation .menu-button:focus {
  box-shadow: none;
}
/* .menu-button:hover {
  color: var(--mr-primary-color);
    border-bottom-style: solid;
    border-color: var(--mr-primary-color);
    border-width: 2px;
} */
#navigation .white-menu-button {
  color: rgb(73, 80, 87);
  cursor: pointer;
  font-weight: bolder;
  /* transition: all 0.2s ease-in-out; */
}

#navigation .white-menu-button:hover,
.link:hover {
  font-weight: bolder;
  transform: scale(1.05);
  cursor: pointer;
}
/* .p-badge-primary {
  background: var(--mr-primary-color) !important;
  color: #ffffff !important;
  font-size: 0.75rem !important;
  font-weight: 700 !important;
  min-width: 1.5rem !important;
  height: 1.5rem !important;
  line-height: 1.5rem !important;
} */
</style>S