<template>
  {{ $t($getServiceType(serviceType)) }}
</template>
<script>
export default {
  props: {
    serviceType: String,
  },
  data() {
    return {};
  },
  methods: {
   
  },
};
</script>