<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <div v-if="currentBusiness != null && currentBusiness.BusActive == true && currentSubscription != null && currentSubscription.SubDef != null && (currentSubscription.SubDef.SubscriptionLevelCode == 'BASIC' ||currentSubscription.SubDef.SubscriptionLevelCode == 'PREMIUM')">
      <div v-if="currentBusiness.BusDisplayCalendar != true">
        <Message severity="warn" :closable="false">{{
          $t("calendar.enablecalendar")
        }}</Message>
      </div>
      <TabView class="tabview-custom" v-model:activeIndex="activeIndex">
        <TabPanel>
          <template #header>
            <i class="pi pi-calendar"></i>
            <span>{{ $t("calendar.calendar") }}</span>
          </template>
          <div
            class="
              flex
              justify-content-between
              align-items-start
              flex-wrap-reverse
              m-2
            "
          >
            <fieldset>
              <legend>KEY</legend>
              <Chip
                :label="$t('calendar.reserved')"
                class="m-1 primary-chip chip-sm"
              />
              <Chip
                :label="$t('quotes.requestedLabel')"
                class="m-1 warning-chip chip-sm"
              />
              <Chip
                :label="$t('calendar.available')"
                class="m-1 success-chip chip-sm"
              />
              <Chip
                :label="$t('calendar.hidden')"
                class="m-1 dark-chip chip-sm"
              />
              <div class="flex flex-wrap justify-content-center m-2">
                <span class="mr-2"
                  ><Badge value="D"></Badge> {{ getTimeSlotType("DATE") }}
                </span>
                <span class="mr-2"
                  ><Badge value="T"></Badge> {{ getTimeSlotType("TOUR") }}
                </span>
                <!-- <span class="mr-2"
                  ><Badge value="E"></Badge> {{ getTimeSlotType("EVENT") }}
                </span> -->
                <span class="mr-2"
                  ><Badge value="S"></Badge> {{ getTimeSlotType("DELIVERY") }}
                </span>
                <span class="mr-2"
                  ><Badge value="M"></Badge> {{ getTimeSlotType("MEETING") }}
                </span>
              </div>
            </fieldset>
            <div>
              <Button
                :label="$t('menu.newschedule')"
                icon="pi pi-plus"
                @click.stop="
                  () => {
                    addingSchedule = true;
                  }
                "
                class="mr-2 mb-2 p-button-sm p-button-raised p-button-primary"
              />
              <Button
                :label="$t('calendar.newslot')"
                icon="pi pi-plus"
                @click.stop="
                  () => {
                    addingSlot = true;
                  }
                "
                class="mr-2 mb-2 p-button-sm p-button-raised p-button-primary"
              />
            </div>
          </div>

          <vue-cal
            :events="calendarEvents"
            small
            style="height: 500px"
            :locale="lang"
            active-view="month"
            :disable-views="['years', 'week']"
            events-count-on-year-view
            events-on-month-view="true"
            :on-event-click="onEventClick"
            :twelve-hour="twelveHr"
            @ready="fetchEvents"
            @view-change="fetchEvents"
          >
            <!-- @cell-click="dateClick($event)" -->
            <template v-slot:event="{ event, view }">
              <!-- <v-icon>{{ event.icon }}</v-icon> -->

              <!-- <div class="vuecal__event-title"> -->
              <!-- <span
                  v-if="event.title != 'Available' && event.title != 'Hidden'"
                  >{{ event.title }}</span
                > -->
              <!-- </div> -->
              <!-- v-html="event.title" -->
              <!-- Or if your events are editable: -->
              <!-- <div
                class="vuecal__event-title vuecal__event-title--edit"
                contenteditable
                @blur="event.title = $event.target.innerHTML"
                v-html="event.title"
              /> -->
              <span class="mr-1">
                <Badge v-if="event.typeCode == 'DATE'" value="D"></Badge>
                <Badge v-else-if="event.typeCode == 'TOUR'" value="T"></Badge>
                <!-- <Badge v-else-if="event.typeCode == 'EVENT'" value="E"></Badge> -->
                <Badge v-else-if="event.typeCode == 'DELIVERY'" value="S"></Badge>
                <Badge
                  v-else-if="event.typeCode == 'MEETING'"
                  value="M"
                ></Badge>
              </span>
              <span class="vuecal__event-time" v-if="view == 'month'">
                <span class="show-on-desktop">
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</span
                >
                <small class="show-on-mobile">
                  {{ event.start.formatTime("h:mm am") }}
                </small>
              </span>
              <span class="vuecal__event-time" v-if="view == 'day'">
                <small class="">
                  {{ event.title }}<br />
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</small
                >
              </span>
            </template>
            <template v-slot:events-count="{ events }">
              <span v-if="customEventsCount(events)">
                {{ customEventsCount(events) }}
              </span>
            </template>
          </vue-cal>
        </TabPanel>
        <TabPanel>
          <template #header>
            <i class="pi pi-calendar"></i>
            <span>{{ $t("calendar.schedule") }}</span>
          </template>

          <DataView
            class="mt-2 service-grid"
            :value="schedules"
            :layout="'list'"
            :paginator="true"
            :rows="9"
            :sortOrder="scheduleSortOrder"
            :sortField="scheduleSortField"
          >
            <template #header>
              <div class="flex flex-wrap-reverse justify-content-between">
                <div class="mr-2" style="text-align: left">
                  <Dropdown
                    v-model="scheduleSortKey"
                    class="mr-2"
                    :options="scheduleSortOptions"
                    optionLabel="label"
                    :placeholder="$t('services.sort')"
                    @change="scheduleSortChange($event)"
                    style="font-size: small"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                  />
                </div>
                <div class="mb-2" style="text-align: right">
                  <Button
                    :label="$t('menu.newschedule')"
                    icon="pi pi-plus"
                    @click.stop="
                      () => {
                        addingSchedule = true;
                      }
                    "
                    class="mr-2 p-button-sm p-button-raised p-button-primary"
                  />
                </div>
              </div>
            </template>
            <template #list="slotProps">
              <div class="col-12 mb-2">
                <Card>
                  <template v-slot:content>
                    <div
                      class="
                        flex flex-wrap
                        justify-content-around
                        align-items-center
                      "
                    >
                      <div class="" style="text-align: center">
                        <span
                          ><b
                            ><i class="pi pi-check-square"></i>
                            {{ slotProps.data.SchMon ? $t("days.m") : "" }}
                            {{ slotProps.data.SchTues ? $t("days.t") : "" }}
                            {{ slotProps.data.SchWed ? $t("days.w") : "" }}
                            {{ slotProps.data.SchThur ? $t("days.th") : "" }}
                            {{ slotProps.data.SchFri ? $t("days.f") : "" }}
                            {{ slotProps.data.SchSat ? $t("days.sa") : "" }}
                            {{ slotProps.data.SchSun ? $t("days.su") : "" }}</b
                          ></span
                        ><br />
                        <span
                          ><b
                            ><i class="pi pi-clock"></i
                            >{{ $formatTime(slotProps.data.SchStartTime) }} -
                            {{ $formatTime(slotProps.data.SchEndTime) }}</b
                          ></span
                        ><br />
                        <span
                          ><b
                            ><i class="pi pi-calendar"></i
                            >{{
                              $formatDateTimeToDate(slotProps.data.SchStartDate)
                            }}
                            -
                            {{
                              $formatDateTimeToDate(slotProps.data.SchEndDate)
                            }}
                          </b></span
                        ><br />
                        <Chip
                          :label="getTimeSlotType(slotProps.data.TypeCode)"
                          class="mr-2 mb-2 chip-sm primary-chip"
                        />
                      </div>
                      <div
                        class="
                          flex flex-wrap
                          justify-content-end
                          align-items-center
                          m-1
                        "
                      >
                        <Chip
                          v-if="slotProps.data.SchVisible"
                          :label="$t('calendar.timeslotsvisible')"
                          class="mr-2 mb-2 success-chip chip-sm"
                        />
                        <Chip
                          v-else
                          :label="$t('calendar.timeslotshidden')"
                          class="mr-2 mb-2 dark-chip chip-sm"
                        />
                      </div>

                      <div class="flex flex-wrap justify-content-end m-1">
                        <Button
                          icon="pi pi-trash"
                          class="p-button-danger p-button-sm mr-1 mb-1"
                          v-tooltip.left="$t('calendar.deletesch')"
                          @click.stop="showDeleteConfirm(slotProps.data.SchId)"
                        />
                        <Button
                          icon="pi pi-list"
                          v-tooltip.right="$t('calendar.viewschdates')"
                          class="p-button-success p-button-sm mr-1 mb-1"
                          @click.stop="viewSchTimeSlots(slotProps.data)"
                        />
                        <Button
                          icon="pi pi-pencil"
                          v-tooltip.right="$t('calendar.editschedule')"
                          class="p-button-info p-button-sm mr-1 mb-1"
                          @click.stop="setEditing(slotProps.data)"
                        />
                      </div>
                    </div>
                  </template>
                  <template #footer> </template>
                </Card>
              </div>
            </template>
          </DataView>
        </TabPanel>
        <TabPanel>
          <template #header>
            <i class="pi pi-calendar"></i>
            <span>{{ $t("calendar.dates") }}</span>
          </template>

          <DataView
            class="mt-2 service-grid"
            :value="calendarEvents.filter((event) => event.schId == 0)"
            :layout="'list'"
            :paginator="true"
            :rows="9"
            :sortOrder="sortOrder"
            :sortField="timeSlotsSortField"
          >
            <template #header>
              <div class="flex flex-wrap-reverse justify-content-between">
                <div class="mb-2" style="text-align: left">
                  <Dropdown
                    v-model="sortKey"
                    class="mr-2"
                    :options="timeSlotSortOptions"
                    optionLabel="label"
                    :placeholder="$t('services.sort')"
                    @change="timeSlotSortChange($event)"
                    style="font-size: small"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                  />
                </div>
                <div class="mb-2" style="text-align: right">
                  <Button
                    :label="$t('calendar.newslot')"
                    icon="pi pi-plus"
                    @click.stop="
                      () => {
                        addingSlot = true;
                      }
                    "
                    class="mr-2 p-button-sm p-button-raised p-button-primary"
                  />
                </div>
              </div>
            </template>
            <template #list="slotProps">
              <div class="col-12 mb-2">
                <Card>
                  <template v-slot:content>
                    <div
                      class="
                        flex flex-wrap
                        justify-content-around
                        align-items-center
                      "
                    >
                      <div class="m-1" style="text-align: center">
                        <span
                          ><b>
                            <i class="pi pi-calendar"></i>
                            {{ $formatDateTimeToDate(slotProps.data.start) }}
                            <br />
                            <i class="pi pi-clock"></i>
                            {{ $formatDateTimeToTime(slotProps.data.start) }}
                            -
                            {{ $formatDateTimeToTime(slotProps.data.end) }}</b
                          ></span
                        >
                        <br />
                        <Chip
                          :label="getTimeSlotType(slotProps.data.typeCode)"
                          class="m-2 chip-sm primary-chip"
                        />
                      </div>
                      <div
                        class="
                          flex flex-wrap
                          justify-content-end
                          align-items-center
                          m-1
                        "
                      >
                        <Chip
                          v-if="slotProps.data.visible"
                          :label="$t('calendar.visible')"
                          class="m-2 success-chip chip-sm"
                        />
                        <Chip
                          v-else
                          :label="$t('calendar.hidden')"
                          class="m-2 dark-chip chip-sm"
                        />
                        <Chip
                          v-if="
                            slotProps.data.available && !slotProps.data.quoted
                          "
                          :label="$t('calendar.available')"
                          class="m-2 success-chip chip-sm"
                        />
                        <Chip
                          v-if="
                            slotProps.data.available && slotProps.data.quoted
                          "
                          :label="$t('quotes.requestedLabel')"
                          class="m-2 warning-chip chip-sm"
                        />
                        <Chip
                          v-if="!slotProps.data.available"
                          :label="$t('calendar.reserved')"
                          class="m-2 primary-chip chip-sm"
                        />
                      </div>

                      <div class="flex flex-wrap justify-content-end m-1">
                        <Button
                          v-if="slotProps.data.available"
                          icon="pi pi-trash"
                          class="p-button-danger p-button-sm m-2"
                          v-tooltip.left="$t('code.deletetimeslot')"
                          @click.stop="
                            showTimeSlotDeleteConfirm(slotProps.data.slotId)
                          "
                        />
                        <Button
                          v-else
                          icon="pi pi-trash"
                          class="p-button-danger p-button-sm m-2"
                          v-tooltip.left="$t('code.deletetimeslot')"
                          disabled
                        />

                        <Button
                          icon="pi pi-pencil"
                          v-tooltip.right="$t('code.edittimeslot')"
                          class="p-button-info p-button-sm m-2"
                          @click.stop="setEditTimeSlot(slotProps.data.slotId)"
                        />
                      </div>
                    </div>
                    <div v-if="!slotProps.data.available" class="required-star">
                      <small>{{ $t("calendar.cannotdelete") }}</small>
                    </div>
                  </template>
                  <template #footer> </template>
                </Card>
              </div>
            </template>
          </DataView>
        </TabPanel>
      </TabView>

      <!-- ConfirmDelete -->
      <Dialog
        header="Confirm Schedule and Time Slots Delete"
        v-model:visible="showDeleteAllConfirm"
        :style="{ width: '50vw' }"
        :modal="true"
        :dismissableMask="true"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <p>
          {{ $t("menu.deleteschedule") }}
        </p>
        <template #footer>
          <Button
            :label="$t('menu.no')"
            icon="pi pi-times"
            @click.stop="showDeleteAllConfirm = false"
            class="p-button-secondary"
          />
          <Button
            :label="$t('menu.yes')"
            icon="pi pi-check"
            @click.stop="deleteSchAndTimeSlots()"
            autofocus
          />
        </template>
      </Dialog>
      <Dialog
        header="Confirm Schedule Delete"
        v-model:visible="showDeleteSchConfirm"
        :style="{ width: '50vw' }"
        :modal="true"
        :dismissableMask="true"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <p>
          {{ $t("menu.confirmdelete") }}
        </p>
        <template #footer>
          <Button
            :label="$t('menu.no')"
            icon="pi pi-times"
            @click.stop="showDeleteAllConfirm = false"
            class="p-button-secondary"
          />
          <Button
            :label="$t('menu.yes')"
            icon="pi pi-check"
            @click.stop="deleteSchOnly()"
            autofocus
          />
        </template>
      </Dialog>
      <!-- New Schedule Dialog -->
      <Dialog
        v-model:visible="addingSchedule"
        :modal="true"
        :contentStyle="{ overflow: 'scroll' }"
        :dismissableMask="true"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <template #header>
          <h3>{{ $t("calendar.addschedule") }}</h3>
        </template>

        <div class="p-fluid formgrid grid text-left">
          <div class="field col-12">
            <label>{{ $t("calendar.scheduletypecode") }}</label>
            <Dropdown
              v-model="newSchedule.TypeCode"
              :options="timeSlotTypes"
              optionValue="TypeCode"
              :placeholder="$t('services.pleaseselect')"
            >
              <template #value="slotProps">
                <span v-if="slotProps.value == null">
                  {{ slotProps.placeholder }}
                </span>
                <span v-else-if="typeof slotProps.value !== 'String'">
                  {{ getTimeSlotType(slotProps.value) }}
                </span>
                <span v-else>
                  {{ getTimeSlotType(slotProps.value.TypeCode) }}
                </span>
              </template>
              <template #option="slotProps">
                <span>{{ getTimeSlotType(slotProps.option.TypeCode) }}</span>
              </template>
            </Dropdown>
          </div>

          <div class="field col-12">
            <label for="scheduleDays">{{ $t("calendar.day") }}</label>
            <SelectButton
              id="newDays"
              v-model="newSchedule.days"
              :options="days"
              optionLabel="name"
              valueLabel="value"
              :multiple="true"
            />
          </div>

          <div class="field col-6">
            <label for="label">{{ $t("calendar.start") }}</label>
            <Calendar
              id="newStartTime"
              v-model="newSchedule.SchStartTime"
              :timeOnly="true"
              hourFormat="12"
              :stepMinute="30"
              :manualInput="true"
            />
          </div>
          <div class="field col-6">
            <label for="description">{{ $t("calendar.end") }}</label>
            <Calendar
              id="newEndTime"
              v-model="newSchedule.SchEndTime"
              :timeOnly="true"
              hourFormat="12"
              :stepMinute="30"
              :manualInput="true"
            />
          </div>
          <div class="field col-6">
            <label for="price">{{ $t("calendar.startdate") }}</label>
            <Calendar
              id="newStartDate"
              v-model="newSchedule.SchStartDate"
              :showIcon="true"
            />
          </div>
          <div class="field col-6">
            <label for="visible">{{ $t("calendar.endate") }}</label
            ><br />
            <Calendar
              id="newEndDate"
              v-model="newSchedule.SchEndDate"
              :showIcon="true"
            />
          </div>
          <div class="field col-12">
            <label for="image">{{ $t("calendar.visibility") }}</label>

            <SelectButton
              id="newVisible"
              v-model="newSchedule.SchVisible"
              :options="visibilityOptions"
              optionLabel="name"
              valueLabel="value"
            />
          </div>
        </div>
        <div class="col-12">
          <Message
            v-for="error of errors"
            severity="error"
            :key="error"
            :sticky="true"
            >{{ error }}</Message
          >
        </div>

        <template #footer>
          <Button
            :label="$t('profile.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            @click.stop="resetCreateSchedule()"
          />
          <Button
            v-if="!creatingSchedule"
            :label="$t('profile.save')"
            icon="pi pi-check"
            autofocus
            @click.stop="validateCreateSchedule()"
          />
          <Button
            v-else
            :label="$t('profile.saving')"
            icon="pi pi-spin pi-spinner"
            disabled
          />
        </template>
      </Dialog>

      <!-- Edit Schedule Dialog -->
      <Dialog
        v-model:visible="editingSchedule"
        :modal="true"
        :contentStyle="{ overflow: 'scroll' }"
        :dismissableMask="true"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <template #header>
          <h3>{{ $t("calendar.editschedule") }}</h3>
        </template>

        <div class="p-fluid formgrid grid text-left">
          <div class="p-fluid formgrid grid text-left">
            
            <div class="field col-12">
              <label>{{ $t("calendar.scheduletypecode") }}</label>
              <Dropdown
                v-model="schToUpdate.TypeCode"
                :options="timeSlotTypes"
                optionValue="TypeCode"
                :placeholder="$t('services.pleaseselect')"
              >
                <template #value="slotProps">
                  <span v-if="slotProps.value == null">
                    {{ slotProps.placeholder }}
                  </span>
                  <span v-else-if="typeof slotProps.value !== 'String'">
                    {{ getTimeSlotType(slotProps.value) }}
                  </span>
                  <span v-else>
                    {{ getTimeSlotType(slotProps.value.TypeCode) }}
                  </span>
                </template>
                <template #option="slotProps">
                  <span>{{ getTimeSlotType(slotProps.option.TypeCode) }}</span>
                </template>
              </Dropdown>
            </div>
            <div class="field col-12">
              <label for="editScheduleDays">{{ $t("calendar.day") }}</label>
              <SelectButton
                id="newDays"
                v-model="schToUpdate.days"
                :options="days"
                optionLabel="name"
                valueLabel="value"
                :multiple="true"
              />
            </div>

            <div class="field col-6">
              <label for="label">{{ $t("calendar.start") }}</label>
              <Calendar
                id="newStartTime"
                v-model="schToUpdate.displayStartTime"
                :timeOnly="true"
                hourFormat="12"
                :stepMinute="30"
                :manualInput="true"
              />
            </div>
            <div class="field col-6">
              <label for="description">{{ $t("calendar.end") }}</label>
              <Calendar
                id="newEndTime"
                v-model="schToUpdate.displayEndTime"
                :timeOnly="true"
                hourFormat="12"
                :stepMinute="30"
                :manualInput="true"
              />
            </div>
            <div class="field col-6">
              <label for="price">{{ $t("calendar.startdate") }}</label>
              <Calendar
                id="newStartDate"
                v-model="schToUpdate.displayStartDate"
                :showIcon="true"
              />
            </div>
            <div class="field col-6">
              <label for="visible">{{ $t("calendar.endate") }}</label
              ><br />
              <Calendar
                id="newEndDate"
                v-model="schToUpdate.displayEndDate"
                :showIcon="true"
              />
            </div>
            <div class="field col-12">
              <label for="image">{{ $t("calendar.visibility") }}</label>
              <SelectButton
                id="newVisible"
                v-model="schToUpdate.SchVisible"
                :options="visibilityOptions"
                optionLabel="name"
                optionValue="value"
              />
            </div>
          </div>
          <div class="col-12">
            <Message
              v-for="error of errors"
              severity="error"
              :key="error"
              :sticky="true"
              >{{ error }}</Message
            >
          </div>
        </div>

        <template #footer>
          <Button
            :label="$t('profile.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            @click.stop="resetEditSchedule()"
          />
          <Button
            v-if="!savingEditSchedule"
            :label="$t('profile.save')"
            icon="pi pi-check"
            autofocus
            @click.stop="validateEditSchedule()"
          />
          <Button
            v-else
            :label="$t('profile.saving')"
            icon="pi pi-spin pi-spinner"
            iconPos="left"
            disabled
          />
        </template>
      </Dialog>

      <!-- New Time Slot Dialog -->
      <Dialog
        v-model:visible="addingSlot"
        :modal="true"
        :contentStyle="{ overflow: 'scroll' }"
        :dismissableMask="true"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <template #header>
          <h3>{{ $t("calendar.newslot") }}</h3>
        </template>

        <div class="p-fluid formgrid grid text-left">
          <div class="field col-12">
              <label>{{ $t("calendar.scheduletypecode") }}</label>
              <Dropdown
                v-model="newSlot.TypeCode"
                :options="timeSlotTypes"
                optionValue="TypeCode"
                :placeholder="$t('services.pleaseselect')"
              >
                <template #value="slotProps">
                  <span v-if="slotProps.value == null">
                    {{ slotProps.placeholder }}
                  </span>
                  <span v-else-if="typeof slotProps.value !== 'String'">
                    {{ getTimeSlotType(slotProps.value) }}
                  </span>
                  <span v-else>
                    {{ getTimeSlotType(slotProps.value.TypeCode) }}
                  </span>
                </template>
                <template #option="slotProps">
                  <span>{{ getTimeSlotType(slotProps.option.TypeCode) }}</span>
                </template>
              </Dropdown>
            </div>
          <div class="field col-12">
            <label for="editScheduleDays">{{ $t("calendar.date") }}</label>
            <Calendar
              id="newSlotDate"
              v-model="newSlot.SlotDate"
              :showIcon="true"
            />
          </div>

          <div class="field col-6">
            <label for="label">{{ $t("calendar.start") }}</label>
            <Calendar
              id="newSlotStartTime"
              v-model="newSlot.SlotStartTime"
              :timeOnly="true"
              hourFormat="12"
              :stepMinute="30"
              :manualInput="true"
            />
          </div>
          <div class="field col-6">
            <label for="description">{{ $t("calendar.end") }}</label>
            <Calendar
              id="newSlotEndTime"
              v-model="newSlot.SlotEndTime"
              :timeOnly="true"
              hourFormat="12"
              :stepMinute="30"
              :manualInput="true"
            />
          </div>
          <div class="field col-12">
            <label for="image">{{ $t("calendar.visibility") }}</label>
            <SelectButton
              id="newVisible"
              v-model="newSlot.SlotVisible"
              :options="individualVisibility"
              optionLabel="name"
              valueLabel="value"
            />
          </div>
          <div class="field col-12">
            <label for="image">{{ $t("calendar.availability") }}</label>
            <SelectButton
              id="newAvailable"
              v-model="newSlot.SlotAvailable"
              :options="availabilityOptions"
              optionLabel="name"
              valueLabel="value"
            />
          </div>
        </div>

        <div class="col-12">
          <Message
            v-for="error of errors"
            severity="error"
            :key="error"
            :sticky="true"
            >{{ error }}</Message
          >
        </div>
        <template #footer>
          <Button
            :label="$t('profile.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            @click.stop="resetCreateTimeslot()"
          />
          <Button
            v-if="!creatingTimeslot"
            :label="$t('profile.save')"
            icon="pi pi-check"
            autofocus
            @click.stop="validateCreateTimeslot()"
          />
          <Button
            v-else
            :label="$t('profile.saving')"
            icon="pi pi-spin pi-spinner"
            iconPos="left"
            disabled
          />
        </template>
      </Dialog>
      <!-- Edit Timeslot Dialog -->
      <Dialog
        v-model:visible="editingSlot"
        :modal="true"
        :contentStyle="{ overflow: 'scroll' }"
        :dismissableMask="true"
        @hide="resetEditSlot()"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
        class="text-center"
      >
        <template #header>
          <div class="mb-5" style="font-size: large">
            <b> {{ $t("calendar.timeslot") }} </b>
          </div>
        </template>
        <div v-if="loadingSlot" class="text-center">
          <i class="pi pi-spin pi-spinner"></i>
        </div>
        <div v-else>
          <Card class="mb-3">
            <template #title>{{ $t("calendar.datetime") }}</template>
            <template #content
              ><b> <i class="pi pi-calendar"></i></b
              >{{ $formatDateTimeToDate(slotToUpdate.SlotDate) }}<br />

              <b> <i class="pi pi-clock"></i></b>
              {{ $formatTime(slotToUpdate.SlotStartTime) }} -
              {{ $formatTime(slotToUpdate.SlotEndTime) }}</template
            >
          </Card>
          <template
            v-if="slotToUpdate.SlotAvailable == false && editConfirmed == false"
          >
            <template v-if="slotToUpdate.TypeCode == 'DATE'">
              <Card >
                <template #title>
                  {{ $t("calendar.reservword") }}
                </template>

                <template #content>
                  <div v-if="relatedReserv != null">
                  <div
                    class="flex flex-wrap justify-content-evenly"
                    style="text-align: center"
                  >
                    <div class="mr-4">
                      <b>Reserv#: </b>{{ relatedReserv.ReservNum }}
                    </div>
                    <div
                      v-if="relatedReserv.Cust.CustLastName != null"
                      class="mr-4"
                    >
                      <b> Name: </b>{{ relatedReserv.Cust.CustLastName }},
                      {{ relatedReserv.Cust.CustFirstName }}
                    </div>

                    <div
                      v-if="relatedReserv.ReservTotalPaid != null"
                      class="mr-4"
                    >
                      <b>{{ $t("calendar.totalpaid") }}: </b>${{
                        relatedReserv.ReservTotalPaid
                      }}
                    </div>

                    <div
                      v-if="relatedReserv.ReservTotalOwed != null"
                      class="mr-4"
                    >
                      <b>{{ $t("calendar.totalowed") }}: </b> ${{
                        relatedReserv.ReservTotalOwed
                      }}
                    </div>
                  </div>

                  <div class="flex flex-wrap justify-content-center mt-3">
                    <Button
                      icon="pi pi-file"
                      v-tooltip.bottom="$t('quotes.viewinvoice')"
                      class="p-button-info p-button-sm mb-2 mr-1"
                      @click.stop="openQuote(relatedReserv.Quote)"
                    />
                    <Button
                      icon="pi pi-money-bill"
                      v-tooltip.bottom="$t('quotes.viewpayments')"
                      class="p-button-success p-button-sm mb-2 mr-1"
                      @click.stop="openPayments(relatedReserv)"
                    />

                    <Button
                      icon="pi pi-comments"
                      v-tooltip.bottom="$t('quotes.messagecustomer')"
                      class="p-button-warning p-button-sm mb-2 mr-1"
                      @click.stop="
                        reservCust = relatedReserv.CustId;
                        showNewMessage = true;
                      "
                    />
                    <Button
                      icon="pi pi-phone"
                      v-tooltip.bottom="$t('quotes.callcustomer')"
                      class="p-button-danger p-button-sm mb-2 mr-1"
                      @click="toggle"
                    />
                    <OverlayPanel ref="op">
                      <a :href="'tel:' + relatedReserv.Cust.CustPhone">
                        {{ relatedReserv.Cust.CustPhone }}</a
                      >
                    </OverlayPanel>
                  </div>
                  </div>
                  <span v-else>{{ $t("code.notfound") }}</span>
                </template>
              </Card>
              <!--  -->
            </template>
            <template v-else>
              <Card>
                <template #title>
                  {{ $t("code.apptfound") }}
                </template>
                <template #content>
                  <div v-if="relatedAppt != null">
                  <div
                    class="flex flex-wrap justify-content-evenly"
                    style="text-align: center"
                  >
                    <div
                      v-if="relatedAppt.Cust.CustLastName != null"
                      class="mr-4"
                    >
                      <b> Name: </b>{{ relatedAppt.Cust.CustLastName }},
                      {{ relatedAppt.Cust.CustFirstName }}
                    </div>
                  </div>
                  <div class="flex flex-wrap justify-content-center mt-3">
                    <Button
                      icon="pi pi-eye"
                      v-tooltip.bottom="$t('code.notes')"
                      class="p-button-success p-button-sm mb-2 mr-1"
                      @click.stop="viewNotes(relatedAppt)"
                    />
                    <Button
                      icon="pi pi-phone"
                      v-tooltip.bottom="$t('quotes.callcustomer')"
                      class="p-button-primary p-button-sm mb-2 mr-1"
                      @click="toggle"
                    />
                    <OverlayPanel ref="op">
                      <a :href="'tel:' + relatedAppt.Cust.CustPhone">
                        {{ relatedAppt.Cust.CustPhone }}</a
                      >
                    </OverlayPanel>
                  </div>
                </div>
                <span v-else>{{ $t("code.notfound") }}</span>
                </template>
              </Card>
              <!--  -->
            </template>
          </template>
          <template
            v-else-if="
              slotToUpdate.SlotRequested &&
              slotToUpdate.SlotAvailable &&
              !editConfirmed
            "
          >
            <div class="mb-2" style="font-size: large">
              <b>Quote(s) Found </b>
            </div>
            <DataView :value="relatedQuotes" layout="list" class="">
              <template #empty>
                {{ $t("quotes.receive") }}
              </template>
              <template #list="slotProps">
                <div class="col-12">
                  <Card class="m-2" @click.stop="openQuote(slotProps.data)">
                    <template v-slot:content>
                      <div class="grid">
                        <div class="col-10 align-self-center">
                          <div
                            class="
                              flex flex-wrap
                              justify-content-evenly
                              align-items-center align-content-center
                            "
                            style="text-align: center"
                          >
                            <div class="mr-4">
                              <b> Quote#: </b>{{ slotProps.data.QuoteNum }}
                            </div>
                            <div class="mr-4">
                              <b>Customer: </b>
                              {{ slotProps.data.Cust.CustLastName }},
                              {{ slotProps.data.Cust.CustFirstName }}
                            </div>
                            <div class="mr-4">
                              <b>{{ $t("message.requested") }}: </b>
                              {{
                                $formatDateTime(slotProps.data.QuoteRequestDate)
                              }}
                            </div>
                            <div class="mr-4">
                              <Tag
                                class="mt-2"
                                :severity="
                                  getStatusTag(slotProps.data.QuoteStatus)
                                "
                                style="text-align: left; font-size: x- small"
                              >
                                {{ slotProps.data.QuoteStatus }}
                              </Tag>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-2 align-self-center"
                          style="text-align: right"
                        >
                          <Button
                            icon="pi pi-angle-right"
                            class="p-button-rounded p-button-text p-button-lg"
                            @click.stop="openQuote(slotProps.data)"
                          />
                        </div>
                      </div>
                    </template>
                  </Card>
                </div>
              </template>
            </DataView>
          </template>
          <template
            v-else-if="
              slotToUpdate.SlotAvailable == true || editConfirmed == true
            "
          >
            <div class="p-fluid formgrid grid text-left">
              <div class="field col-12">
              <label>{{ $t("calendar.scheduletypecode") }}</label>
              <Dropdown
                v-model="slotToUpdate.TypeCode"
                :options="timeSlotTypes"
                optionValue="TypeCode"
                :placeholder="$t('services.pleaseselect')"
              >
                <template #value="slotProps">
                  <span v-if="slotProps.value == null">
                    {{ slotProps.placeholder }}
                  </span>
                  <span v-else-if="typeof slotProps.value !== 'String'">
                    {{ getTimeSlotType(slotProps.value) }}
                  </span>
                  <span v-else>
                    {{ getTimeSlotType(slotProps.value.TypeCode) }}
                  </span>
                </template>
                <template #option="slotProps">
                  <span>{{ getTimeSlotType(slotProps.option.TypeCode) }}</span>
                </template>
              </Dropdown>
            </div>
              <div class="field col-12">
                <label for="editScheduleDays">{{ $t("calendar.date") }}</label>
                <Calendar
                  id="editSlotDate"
                  v-model="slotToUpdate.displayDate"
                  :showIcon="true"
                />
              </div>

              <div class="field col-6">
                <label for="label">{{ $t("calendar.start") }}</label>
                <Calendar
                  id="editSlotStartTime"
                  v-model="slotToUpdate.displayStartTime"
                  :timeOnly="true"
                  hourFormat="12"
                  :stepMinute="30"
                  :manualInput="true"
                />
              </div>
              <div class="field col-6">
                <label for="description">{{ $t("calendar.end") }}</label>
                <Calendar
                  id="editSlotEndTime"
                  v-model="slotToUpdate.displayEndTime"
                  :timeOnly="true"
                  hourFormat="12"
                  :stepMinute="30"
                  :manualInput="true"
                />
              </div>
              <div class="field col-12">
                <label for="image">{{ $t("calendar.visibility") }}</label>
                <SelectButton
                  id="editVisible"
                  v-model="slotToUpdate.SlotVisible"
                  :options="individualVisibility"
                  optionLabel="name"
                  optionValue="value"
                />
              </div>
              <div class="field col-12">
                <label for="image">{{ $t("calendar.availability") }}</label>
                <SelectButton
                  id="editAvailable"
                  v-model="slotToUpdate.NewSlotAvailable"
                  :options="availabilityOptions"
                  optionLabel="name"
                  optionValue="value"
                />
              </div>
            </div>
            <div class="col-12">
              <Message
                v-for="error of errors"
                severity="error"
                :key="error"
                :sticky="true"
                >{{ error }}</Message
              >
            </div>
          </template>
        </div>
        <p
          v-if="
            slotToUpdate != null &&
            slotToUpdate.SchId != 0 &&
            slotToUpdate.SchId != null &&
            editConfirmed == true
          "
          class="required-star"
        >
          {{ $t("calendar.partofschedule") }}
        </p>
        <template #footer>
          <div
            v-if="!loadingSlot"
            class="flex flex-wrap justify-content-evenly"
          >
            <Button
              :label="$t('profile.cancel')"
              icon="pi pi-times"
              class="p-button-secondary"
              @click.stop="resetEditSlot()"
            />
            <Button
              v-if="
                (slotToUpdate.SlotAvailable == false ||
                  slotToUpdate.SlotRequested == true) &&
                editConfirmed == false
              "
              :label="$t('code.edittimeslot')"
              icon="pi pi-pencil"
              class="p-button-primary"
              @click.stop="editConfirmed = true"
            />
            <Button
              v-if="
                ((slotToUpdate.SlotAvailable == true &&
                  slotToUpdate.SlotRequested == false) ||
                  editConfirmed == true) &&
                !savingEditTimeSlot
              "
              :label="$t('profile.save')"
              icon="pi pi-check"
              @click.stop="validateEditTimeSlot()"
            />
            <Button
              v-if="savingEditTimeSlot"
              :label="$t('profile.saving')"
              icon="pi pi-spin pi-spinner"
              disabled
            />
          </div>
        </template>
      </Dialog>
      <!--  popup for timeslot or schedule -->
      <Dialog
        v-model:visible="editingTimeSlotSchedule"
        :modal="true"
        :contentStyle="{ overflow: 'scroll' }"
        :dismissableMask="true"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <template #header>
          <b>{{ $t("calendar.header") }}</b>
        </template>
        <p>
          {{ $t("calendar.edit") }}
        </p>
        <template #footer>
          <div class="flex justify-content-center">
            <Button
              :label="$t('code.opentimeslot')"
              icon="pi pi-calendar"
              class="p-button-primary p-button-sm"
              @click.stop="
                setEditTimeSlot(clickedTimeSlot.slotId);
                editingTimeSlotSchedule = false;
              "
            />

            <Button
              :label="$t('calendar.opensch')"
              icon="pi pi-calendar"
              autofocus
              class="p-button-success p-button-sm"
              @click.stop="
                setEditingSchedule(clickedTimeSlot.schId);
                editingTimeSlotSchedule = false;
              "
            />
          </div>
        </template>
      </Dialog>
      <!-- Time Slot Details -->
      <Dialog
        v-model:visible="viewingTimeSlot"
        :modal="true"
        :contentStyle="{ overflow: 'scroll' }"
        :dismissableMask="true"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <template #header>
          <b>{{ $t("calendar.time") }}</b>
        </template>
        <div class="p-fluid formgrid grid text-left">
          <div class="field col-12">
            <b>{{ $t("calendar.status") }}</b> {{ timeSlotToView.title }} <br />
            <b>{{ $t("calendar.startdate") }} </b>{{ timeSlotToView.start
            }}<br />
            <b>{{ $t("calendar.end") }}</b
            >{{ timeSlotToView.end }}<br />
            <div v-if="timeSlotToView.title != 'Available'">
              <h4>{{ $t("calendar.reserv") }}</h4>
              <b>{{ $t("calendar.customer") }}</b
              >{{ reserv.customerName }}<br />
              <b>{{ $t("calendar.contact") }}</b
              >{{ reserv.customerPhone }}<br />
              <b>{{ $t("calendar.totalprice") }} </b>{{ reserv.reservTotalPrice
              }}<br />
              <b>{{ $t("calendar.totalpaid") }} </b>{{ reserv.reservPaidPrice
              }}<br />
              <b>{{ $t("calendar.owed") }} </b>{{ reserv.reservOwed }}<br />
              <b>{{ $t("calendar.paidinfull") }} </b>{{ reserv.reservPaidFull }}
            </div>
          </div>
        </div>
        <template #footer>
          <Button
            :label="$t('profile.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            @click.stop="resetEditSchedule"
          />

          <Button
            :label="$t('menu.edit')"
            icon="pi pi-pencil"
            autofocus
            class="p-button-primary"
            @click.stop="validateEditSchedule"
          />
        </template>
      </Dialog>
    </div>
    <div v-else-if="currentBusiness.BusActive == false">
      <inactive-subscription
        :busId="currentBusiness.BusId"
      ></inactive-subscription>
    </div>
    <div v-else-if="currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode == 'FREE'">
     <!-- <div v-else-if="true"> -->
      <upgrade-subscription
        :subId="currentSubscription.SubId"
      ></upgrade-subscription>
    </div>
 
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{ $t("message.error") }}</Message
      >
    </div>
 
    
  </div>
  <Dialog
    v-model:visible="viewingQuote"
    :style="{ width: '80vw' }"
    :modal="true"
    :maximizable="true"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    :header="$t('menu.invoice') + ' # ' + selectedQuote.QuoteId"
    :dismissableMask="true"
  >
    <quote-display :selectedQuote="selectedQuote"></quote-display>
  </Dialog>

  <Dialog
    v-model:visible="viewingPayments"
    :style="{ width: '80vw' }"
    :modal="true"
    :header="$t('code.paymentsfor') + ' ' + selectedReserv.ReservNum"
    :dismissableMask="true"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
  >
    <Button
      icon="pi pi-dollar"
      :label="$t('code.applypayment')"
      class="p-button-success p-button-sm mb-2 m-mr-1"
      @click.stop="showNewPayment(selectedReserv)"
    />
    <DataTable
      :value="selectedReserv.ReservPayments"
      sortField="PayDate"
      :sortOrder="-1"
      responsiveLayout="scroll"
      :resizableColumns="true"
      columnResizeMode="fit"
    >
      <template #empty> {{ $t("code.norecords") }} </template>
      <Column field="PayDate" :header="$t('calendar.date')" :sortable="true">
        <template #body="slotProps">
          {{ $formatDateTime(slotProps.data.PayDate) }}
        </template>
      </Column>
      <Column
        field="Card.CardNumber"
        :header="$t('custreserv.custcard')"
        :sortable="true"
      ></Column>
      <Column
        field="PayStatus"
        :header="$t('calendar.status')"
        :sortable="true"
      ></Column>
      <Column
        field="PayAmount"
        :header="$t('custreserv.custamount')"
        :sortable="true"
      ></Column>
    </DataTable>
  </Dialog>
  <Dialog
    v-model:visible="showNewMessage"
    :modal="true"
    :contentStyle="{ overflow: 'scroll' }"
    :header="$t('message.new')"
    :dismissableMask="true"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
  >
    <div style="text-align: left">
      <Textarea
        class="mt-2"
        v-model="newMessageText"
        :placeholder="$t('message.type')"
        :autoResize="true"
        rows="5"
        cols="30"
        style="width: 100%"
      />
    </div>
    <div class="flex justify-content-between">
      <Button
        :label="$t('profile.cancel')"
        v-tooltip.right="$t('profile.cancel')"
        icon="pi pi-times"
        iconPos="left"
        @click.stop="
          showNewMessage = false;
          newMessageText = '';
        "
        class="mr-2 p-button-danger p-button-sm p-button-raised"
      />
      <Button
        :label="$t('menu.send')"
        v-tooltip.right="$t('menu.send')"
        icon="pi pi-play"
        iconPos="left"
        @click.stop="sendNewMessage"
        class="mr-2 p-button-success p-button-sm p-button-raised"
      />
    </div>
  </Dialog>
  <Dialog
    v-model:visible="showSchDates"
    :style="{ width: '80vw' }"
    :modal="true"
    header="Schedule Dates"
    :dismissableMask="true"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
  >
    <Card class="m-3">
      <template v-slot:content>
        <div class="flex flex-wrap justify-content-around align-items-center">
          <span
            ><b
              ><i class="pi pi-check-square"></i>
              {{ selectedSchedule.SchMon ? $t("days.m") : "" }}
              {{ selectedSchedule.SchTues ? $t("days.t") : "" }}
              {{ selectedSchedule.SchWed ? $t("days.w") : "" }}
              {{ selectedSchedule.SchThur ? $t("days.th") : "" }}
              {{ selectedSchedule.SchFri ? $t("days.f") : "" }}
              {{ selectedSchedule.SchSat ? $t("days.sa") : "" }}
              {{ selectedSchedule.SchSun ? $t("days.su") : "" }}</b
            ></span
          >
          <span
            ><b
              ><i class="pi pi-clock"></i
              >{{ $formatTime(selectedSchedule.SchStartTime) }} -
              {{ $formatTime(selectedSchedule.SchEndTime) }}</b
            ></span
          ><br />
          <span
            ><b
              ><i class="pi pi-calendar"></i
              >{{ $formatDateTimeToDate(selectedSchedule.SchStartDate) }}
              -
              {{ $formatDateTimeToDate(selectedSchedule.SchEndDate) }}
            </b></span
          >
          <Chip
            :label="getTimeSlotType(selectedSchedule.TypeCode)"
            class="mr-2 mb-2 chip-sm primary-chip"
          />
          <Chip
            v-if="selectedSchedule.SchVisible"
            :label="$t('calendar.timeslotsvisible')"
            class="mr-2 mb-2 success-chip chip-sm"
          />
          <Chip
            v-else
            :label="$t('calendar.timeslotshidden')"
            class="mr-2 mb-2 dark-chip chip-sm"
          />
        </div>
      </template>
    </Card>
    <DataTable
      :value="schDates"
      sortField="SlotDate"
      :sortOrder="-1"
      responsiveLayout="stack"
      :resizableColumns="true"
      columnResizeMode="fit"
    >
      <template #empty> {{ $t("code.norecords") }} </template>
      <Column field="SlotDate" :header="$t('calendar.date')" :sortable="true">
        <template #body="slotProps">
          {{ $formatDateTimeToDate(slotProps.data.SlotDate) }}
        </template>
      </Column>
      <Column
        field="SlotStartTime"
        :header="$t('calendar.start')"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ $formatTime(slotProps.data.SlotStartTime) }}
        </template></Column
      >
      <Column field="SlotEndTime" :header="$t('calendar.end')" :sortable="true">
        <template #body="slotProps">
          {{ $formatTime(slotProps.data.SlotEndTime) }}
        </template></Column
      >
      <Column field="SlotAvailable" :header="$t('calendar.availability')">
        <template #body="slotProps">
          <Chip
            v-if="slotProps.data.SlotAvailable && !slotProps.data.SlotRequested"
            :label="$t('calendar.available')"
            class="m-2 success-chip chip-sm"
          />
          <Chip
            v-if="slotProps.data.SlotAvailable && slotProps.data.SlotRequested"
            :label="$t('quotes.requestedLabel')"
            class="m-2 warning-chip chip-sm"
          />
          <Chip
            v-if="!slotProps.data.SlotAvailable"
            :label="$t('calendar.reserved')"
            class="m-2 primary-chip chip-sm"
          />
        </template>
      </Column>

      <Column field="SlotVisible" :header="$t('calendar.visibility')">
        <template #body="slotProps">
          <Chip
            v-if="slotProps.data.SlotVisible"
            :label="$t('calendar.visible')"
            class="mr-2 mb-2 success-chip chip-sm"
          />
          <Chip
            v-else
            :label="$t('calendar.hidden')"
            class="mr-2 mb-2 dark-chip chip-sm"
          />
        </template>
      </Column>
    </DataTable>
  </Dialog>
  <!-- View Notes Dialog -->
  <Dialog
    v-model:visible="showViewNotes"
    :modal="true"
    :contentStyle="{ overflow: 'scroll' }"
    :header="$t('code.notes')"
    :dismissableMask="true"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
  >
    <div class="p-fluid formgrid grid">
      <div class="field col-12 text-left">
        <label>{{ $t("code.customernotes") }}:</label><br />
        <span
          v-if="
            relatedAppt.ApptCustNotes != null &&
            relatedAppt.ApptCustNotes.length > 0
          "
          >{{ relatedAppt.ApptCustNotes }}</span
        >
        <span v-else
          ><i
            ><small>{{ $t("code.nonotes") }}</small></i
          ></span
        >
      </div>

      <div class="field col-12 text-left">
        <label>{{ $t("code.businesnotes") }}:</label><br />
        <span
          v-if="
            relatedAppt.ApptBusNotes != null &&
            relatedAppt.ApptBusNotes.length > 0
          "
        >
          {{ relatedAppt.ApptBusNotes }}</span
        >
        <span v-else
          ><i
            ><small>{{ $t("code.nonotes") }}</small></i
          ></span
        >
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapState } from "vuex";
import Schedule from "../store/Models/Schedule";
import CalendarEvent from "../store/Models/CalendarEvent";
import Reservation from "../store/Models/Reservation";
import Quote from "../store/Models/Quote";
import Business from "../store/Models/Business";
import TimeSlot from "../store/Models/TimeSlot";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import QuoteDisplay from "../components/QuoteDisplay.vue";
import Conversation from "../store/Models/Conversation";
import TimeSlotType from "../store/Models/TimeSlotType";
import InactiveSubscription from "../components/InactiveSubscription.vue";
import UpgradeSubscription from "../components/UpgradeSubscription.vue";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/es.es.js";

import BusinessAppointment from "../store/Models/BusinessAppointment";
import Subscription from '../store/Models/Subscription';

export default {
  components: {
    VueCal,
    LoadingOverlay,
    InactiveSubscription,
    QuoteDisplay,
    UpgradeSubscription
  },
  data() {
    return {
      twelveHr: true,
      activeIndex: 0,
      empty: null,
      calendarEvents: [],
      schIdToDelete: 0,
      sortOrder: 1,
      editingTimeSlotSchedule: false,
      clickedTimeSlot: null,
      loading: false,
      savingEditSchedule: false,
      savingEditTimeSlot: false,
      showDeleteSchConfirm: false,
      showDeleteAllConfirm: false,
      currentBusiness: {},
      currentSubscription:{},
      viewingTimeSlot: false,
      loadingSlot: false,
      timeSlotToView: null,
      relatedReserv: null,
      relatedAppt: null,
      showSchDates: false,
      showViewNotes: false,
      relatedQuotes: [],
      visibilityOptions: [
        { name: this.$t("calendar.allvisible"), value: true },
        { name: this.$t("calendar.allhidden"), value: false },
      ],
      individualVisibility: [
        { name: this.$t("calendar.visible"), value: true },
        { name: this.$t("calendar.hidden"), value: false },
      ],
      availabilityOptions: [
        { name: this.$t("calendar.available"), value: true },
        { name: this.$t("calendar.reserved"), value: false },
      ],
      newSchedule: {
        BusId: 0,
        SchStartDate: "",
        SchEndDate: "",
        SchStartTime: new Date(2011, 0, 1, 13, 0, 0, 0),
        SchEndTime: new Date(2011, 0, 1, 23, 59, 0, 0),
        SchVisible: { name: this.$t("calendar.allvisible"), value: true },
        SchMon: false,
        SchTues: false,
        SchWed: false,
        SchThur: false,
        SchFri: false,
        SchSat: false,
        SchSun: false,
        TypeCode: "DATE",
        days: [],
      },
      schToUpdate: {
        BusId: 0,
        SchId: 0,
        SchStartDate: "",
        SchEndDate: "",
        SchStartTime: "",
        SchEndTime: "",
        SchVisible: "",
        SchMon: false,
        SchTues: false,
        SchWed: false,
        SchThur: false,
        SchFri: false,
        SchSat: false,
        SchSun: false,
        TypeCode: "",
        days: [],
        visibility: {},
        displayStartTime: "",
        displayEndTime: "",
        displayStartDate: "",
        displayEndDate: "",
      },
      addingSchedule: false,
      editingSchedule: false,
      editConfirmed: false,
      addingSlot: false,
      newSlot: {
        SlotId: 0,
        SlotDate: "",
        SlotStartTime: new Date(2011, 0, 1, 13, 0, 0, 0),
        SlotEndTime: new Date(2011, 0, 1, 23, 59, 0, 0),
        SlotVisible: { name: this.$t("calendar.visible"), value: true },
        SlotAvailable: { name: this.$t("calendar.available"), value: true },
        SchId: null,
        TypeCode:"DATE",
        SlotRequested: false,
        BusId: "",
      },
      editingSlot: false,
      slotToUpdate: {
        SlotId: 0,
        SlotDate: "",
        SlotStartTime: "",
        SlotEndTime: "",
        SlotVisible: "",
        SlotAvailable: "",
        SchId: 0,
        SlotRequested: "",
        BusId: "",
        visibility: {},
        displayDate: "",
        displayStartTime: "",
        displayEndTime: "",
        availability: "",
        TypeCode:"DATE"
      },
      schedules: [],
      errors: [],
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],

      timeSlotSortOptions: [
        {
          label: "Date",
          code: "start",
          items: [
            { label: this.$t("calendar.dateascending"), value: "start" },
            { label: this.$t("calendar.datedescending"), value: "!start" },
          ],
        },
        {
          label: "Availability",
          code: "title",
          items: [
            { label: "Available", value: "title" },
            { label: "Not Available", value: "!title" },
          ],
        },
        {
          label: "Visibility",
          code: "visible",
          items: [
            { label: "Hidden", value: "visible" },
            { label: "Visible", value: "!visible" },
          ],
        },
      ],
      sortKey: { label: this.$t("calendar.dateascending"), value: "start" },
      scheduleSortKey: {
        label: this.$t("calendar.dateascending"),
        value: "SchStartDate",
      },
      timeSlotsSortField: "start",
      scheduleSortOrder: 1,
      scheduleSortField: "start",
      selectedQuote: {},
      timeSlotTypes: [],
      viewingQuote: false,
      selectedReserv: {},
      showNewMessage: false,
      creatingSchedule: false,
      creatingTimeslot: false,
      newMessageText: "",
      webHost: process.env.VUE_APP_MYRESERVS_URL,
      schDates: [],
      selectedSchedule: {},
      viewingPayments: false,
      scheduleSortOptions: [
        {
          label: "Start Date",
          code: "SchStartDate",
          items: [
            { label: this.$t("calendar.dateascending"), value: "SchStartDate" },
            {
              label: this.$t("calendar.datedescending"),
              value: "!SchStartDate",
            },
          ],
        },
        {
          label: "Visibility",
          code: "SchVisible",
          items: [
            { label: "Hidden", value: "SchVisible" },
            { label: "Visible", value: "!SchVisible" },
          ],
        },
      ],
    };
  },
  async mounted() {
    this.$log.info("business calendar mounted");
    this.timeSlotTypes = await TimeSlotType.fetchAll();
    if (this.currentBusinessId > 0) {
      this.setSelectedBusiness();
    }
  },
  methods: {
    viewNotes(appt) {
     //console.log(appt);
      this.selectedAppt = appt;
      this.showViewNotes = true;
    },
    getTimeSlotType(serviceType) {
      this.$log.debug(serviceType);
      switch (serviceType) {
        case "DATE":
          return this.$t("timeslottypes.date");
        case "EVENT":
          return this.$t("timeslottypes.event");
        case "TOUR":
          return this.$t("timeslottypes.tour");
        case "MEETING":
          return this.$t("timeslottypes.meeting");
          case "DELIVERY":
          return this.$t("timeslottypes.delivery");
        default:
          return "";
      }
    },
    viewSchTimeSlots(schedule) {
      TimeSlot.fetchBySchId(schedule.SchId).then((response) => {
        this.$log.debug(response);
        if (response != null) {
          this.schDates = response;
        }
        this.selectedSchedule = schedule;
        this.showSchDates = true;
      });
    },

    getStatusTag(status) {
      switch (status) {
        case "Requested":
          return "primary";
        case "Processed":
          return "info";
        case "Accepted":
          return "success";
        case "Declined":
          return "danger";
        case "Canceled":
          return "danger";
        case "Expired":
          return "danger";
        default:
          return "info";
      }
    },
    getStatusDesc(status) {
      switch (status) {
        case "Requested":
          return "Pending your review.";
        case "Processed":
          return "Pending client review. ";
        case "Accepted":
          return "Client accepted. Reservation processed.";
        case "Declined":
          return "Client declined.";
        case "Expired":
          return "Client did not respond on time.";
        case "Canceled":
          return "Quote request has been canceled.";
        default:
          return "";
      }
    },
    async sendNewMessage() {
      let newConvo = {
        ConvoId: 0,
        BusId: this.currentBusinessId,
        CustId: this.reservCust,
        Messages: [],
      };

      let newMessage = {
        MessageId: 0,
        ConvoId: 0,
        MessageSentBy: "bus", //"customer/business"
        MessageDate: new Date(),
        MessageText: this.newMessageText,
        MessageRead: false,
      };

      newConvo.Messages.push(newMessage);

      this.$log.debug(newConvo);
      await Conversation.newConvo(newConvo).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          this.showNewMessage = false;
          this.newMessageText = "";
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("message.messagesent"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("message.messagefailed"),
            life: 3000,
          });
        }
      });
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    openQuote(quote) {
      this.selectedQuote = quote;
      this.viewingQuote = true;
    },
    openPayments(reserv) {
      this.selectedReserv = reserv;
      this.viewingPayments = true;
    },
    timeSlotSortChange(event) {
      const value = event.value.value;
      const sortValue = event.value;
      this.$log.debug(sortValue);
      this.$log.debug(this.timeSlotsSortField);

      if (value.indexOf("!") === 0) {
        this.sortOrder = -1;
        this.timeSlotsSortField = value.substring(1, value.length);
        this.sortKey = sortValue;
      } else {
        this.sortOrder = 1;
        this.timeSlotsSortField = value;
        this.sortKey = sortValue;
      }
    },
    scheduleSortChange(event) {
      const value = event.value.value;
      const sortValue = event.value;
      this.$log.debug(sortValue);
      this.$log.debug(this.scheduleSortField);

      if (value.indexOf("!") === 0) {
        this.scheduleSortOrder = -1;
        this.scheduleSortField = value.substring(1, value.length);
        this.scheduleSortKey = sortValue;
      } else {
        this.sortOrder = 1;
        this.timeSlotsSortField = value;
        this.scheduleSortKey = sortValue;
      }
    },
    customEventsCount(events) {
      return events ? events.filter((e) => e.class === "leisure").length : 0;
    },

    validateCreateSchedule() {
      this.$log.info("validating new schedule");
      this.creatingSchedule = true;
      this.$log.debug(this.newSchedule);
      //clear out errors
      this.errors = [];

      //verify required values
      if (this.newSchedule.SchStartTime.length == 0) {
        this.errors.push(this.$t("code.starttime"));
        document.getElementById("newStartTime").classList.add("p-invalid");
      }
      if (this.newSchedule.SchEndTime.length == 0) {
        this.errors.push(this.$t("code.endtime"));
        document.getElementById("newEndTime").classList.add("p-invalid");
      }
      if (this.newSchedule.SchStartDate.length == 0) {
        this.errors.push(this.$t("code.startdate"));
        document.getElementById("newStartDate").classList.add("p-invalid");
      }
      if (this.newSchedule.SchEndDate.length == 0) {
        this.errors.push(this.$t("code.enddate"));
        document.getElementById("newEndDate").classList.add("p-invalid");
      }
      if (this.newSchedule.SchVisible.length == 0) {
        this.errors.push(this.$t("code.visibility"));
        document.getElementById("newVisible").classList.add("p-invalid");
      }

      if (this.newSchedule.days.length > 0) {
        this.newSchedule.days.forEach((day) => {
          if (day.value == "MON") {
            this.newSchedule.SchMon = true;
          } else if (day.value == "TUES") {
            this.newSchedule.SchTues = true;
          } else if (day.value == "WED") {
            this.newSchedule.SchWed = true;
          } else if (day.value == "THUR") {
            this.newSchedule.SchThur = true;
          } else if (day.value == "FRI") {
            this.newSchedule.SchFri = true;
          } else if (day.value == "SAT") {
            this.newSchedule.SchSat = true;
          } else if (day.value == "SUN") {
            this.newSchedule.SchSun = true;
          }
        });
      } else {
        this.errors.push(this.$t("code.requiredschedule"));
        document.getElementById("newDays").classList.add("p-invalid");
      }

      if (this.errors.length == 0) {
        var visible = this.newSchedule.SchVisible.value;
        this.newSchedule.SchVisible = visible;
        this.newSchedule.BusId = this.currentBusinessId;
        this.createSchedule();
      } else {
        this.creatingSchedule = false;
      }
    },
    async createSchedule() {
      this.$log.info("create new schedule");
      this.$log.debug(this.newSchedule);
      await Schedule.createSchedule(this.newSchedule).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          this.setSelectedBusiness();
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.scheduleupdated"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.schedulefailedupdate"),
            life: 3000,
          });
        }
      });
      this.creatingSchedule = false;
      this.resetCreateSchedule();
    },
    resetCreateSchedule() {
      this.addingSchedule = false;
      this.newSchedule = {
        BusId: 0,
        SchStartDate: "",
        SchEndDate: "",
        SchStartTime: new Date(2011, 0, 1, 13, 0, 0, 0),
        SchEndTime: new Date(2011, 0, 1, 23, 59, 0, 0),
        SchVisible: { name: this.$t("calendar.allvisible"), value: true },
        SchMon: false,
        SchTues: false,
        SchWed: false,
        SchThur: false,
        SchFri: false,
        SchSat: false,
        TypeCode: "DATE",
      };
      this.errors = [];
    },
    setEditingSchedule(schId) {
     //console.log(this.schedules);
     //console.log(schId);
      var schedule = this.schedules.find((sch) => sch.SchId == schId);
     //console.log(schedule);
      this.setEditing(schedule);
    },
    setEditing(schedule) {
      this.schToUpdate = schedule;
      this.schToUpdate.days = [];
      if (this.schToUpdate.SchMon == true)
        this.schToUpdate.days.push({ name: this.$t("days.m"), value: "MON" });
      if (this.schToUpdate.SchTues == true)
        this.schToUpdate.days.push({ name: this.$t("days.t"), value: "TUES" });
      if (this.schToUpdate.SchWed == true)
        this.schToUpdate.days.push({ name: this.$t("days.w"), value: "WED" });
      if (this.schToUpdate.SchThur == true)
        this.schToUpdate.days.push({ name: this.$t("days.th"), value: "THUR" });
      if (this.schToUpdate.SchFri == true)
        this.schToUpdate.days.push({ name: this.$t("days.f"), value: "FRI" });
      if (this.schToUpdate.SchSat == true)
        this.schToUpdate.days.push({ name: this.$t("days.sa"), value: "SAT" });
      if (this.schToUpdate.SchSun == true)
        this.schToUpdate.days.push({ name: this.$t("days.su"), value: "SUN" });

      if (this.schToUpdate.SchVisible == true) {
        this.schToUpdate.visibility = { name: "All Visible", value: true };
      } else {
        this.schToUpdate.visibility = { name: "All Hidden", value: false };
      }

      this.schToUpdate.displayStartTime = new Date(
        Date.parse("2011-10-10T" + this.schToUpdate.SchStartTime)
      );
      this.schToUpdate.displayEndTime = new Date(
        Date.parse("2011-10-10T" + this.schToUpdate.SchEndTime)
      );
      this.schToUpdate.displayStartDate = new Date(
        Date.parse(this.schToUpdate.SchStartDate)
      );
      this.schToUpdate.displayEndDate = new Date(
        Date.parse(this.schToUpdate.SchEndDate)
      );

      this.$log.debug(this.schToUpdate);
      this.editingSchedule = true;
    },
    validateEditSchedule() {
      this.$log.info("validating edit schedule");
      this.$log.debug(this.schToUpdate);
      //clear out errors
      this.errors = [];

      this.savingEditSchedule = true;

      //verify required values
      if (this.schToUpdate.SchStartTime.length == 0) {
        this.errors.push(this.$t("code.starttime"));
        document.getElementById("newStartTime").classList.add("p-invalid");
      }
      if (this.schToUpdate.SchEndTime.length == 0) {
        this.errors.push(this.$t("code.endtime"));
        document.getElementById("newEndTime").classList.add("p-invalid");
      }
      if (this.schToUpdate.SchStartDate.length == 0) {
        this.errors.push(this.$t("code.enddate"));
        document.getElementById("newStartDate").classList.add("p-invalid");
      }
      if (this.schToUpdate.SchEndDate.length == 0) {
        this.errors.push(this.$t("code.enddate"));
        document.getElementById("newEndDate").classList.add("p-invalid");
      }
      if (this.schToUpdate.SchVisible.length == 0) {
        this.errors.push(this.$t("code.visibility"));
        document.getElementById("newVisible").classList.add("p-invalid");
      }

      this.schToUpdate.SchMon = false;
      this.schToUpdate.SchTues = false;
      this.schToUpdate.SchWed = false;
      this.schToUpdate.SchThur = false;
      this.schToUpdate.SchFri = false;
      this.schToUpdate.SchSat = false;
      this.schToUpdate.SchSun = false;

      if (this.schToUpdate.days.length > 0) {
        this.schToUpdate.days.forEach((day) => {
          if (day.value == "MON") {
            this.schToUpdate.SchMon = true;
          } else if (day.value == "TUES") {
            this.schToUpdate.SchTues = true;
          } else if (day.value == "WED") {
            this.schToUpdate.SchWed = true;
          } else if (day.value == "THUR") {
            this.schToUpdate.SchThur = true;
          } else if (day.value == "FRI") {
            this.schToUpdate.SchFri = true;
          } else if (day.value == "SAT") {
            this.schToUpdate.SchSat = true;
          } else if (day.value == "SUN") {
            this.schToUpdate.SchSun = true;
          }
        });
      } else {
        this.errors.push(this.$t("code.requiredschedule"));
        document.getElementById("newDays").classList.add("p-invalid");
      }

      if (this.errors.length == 0) {
        // this.schToUpdate.SchVisible = this.schToUpdate.visibility.value;
        this.schToUpdate.SchStartDate = this.schToUpdate.displayStartDate;
        this.schToUpdate.SchEndDate = this.schToUpdate.displayEndDate;
        this.schToUpdate.SchStartTime = this.schToUpdate.displayStartTime;
        this.schToUpdate.SchEndTime = this.schToUpdate.displayEndTime;
        this.editSchedule();
      } else {
        this.savingEditSchedule = false;
      }
    },
    async editSchedule() {
      this.$log.info("edit schedule");
      this.$log.debug(this.schToUpdate);
      await Schedule.updateSchedule(this.schToUpdate).then(async (response) => {
        this.$log.debug(response);
        this.savingEditSchedule = false;
        if (response == 1) {
          this.setSelectedBusiness();
        }
      });
      this.resetEditSchedule();
    },
    resetEditSchedule() {
      this.editingSchedule = false;

      this.schToUpdate = {
        BusId: 0,
        SchId: 0,
        SchStartDate: "",
        SchEndDate: "",
        SchStartTime: "",
        SchEndTime: "",
        SchVisible: false,
        SchMon: false,
        SchTues: false,
        SchWed: false,
        SchThur: false,
        SchFri: false,
        SchSat: false,
        TypeCode: "",
        days: [],
      };
      this.errors = [];
    },
    showDeleteConfirm(schId) {
      this.schIdToDelete = schId;
      this.$log.debug("delete confirm");
      this.$confirm.require({
        message: this.$t("code.deletetimeslots"),
        header: this.$t("code.deleted"),
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.showDeleteAllConfirm = true;
        },
        reject: () => {
          this.showDeleteSchConfirm = true;
        },
      });
    },
    async deleteSchAndTimeSlots() {
      await Schedule.deleteWithTimeslots(this.schIdToDelete).then((result) => {
        if (result == 1) {
          this.setSelectedBusiness();
          this.showDeleteAllConfirm = false;
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.scheduledeleted"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.scheduledeleted"),
            life: 3000,
          });
        }
      });
    },
    async deleteSchOnly() {
      await Schedule.deleteSchedule(this.schIdToDelete).then((result) => {
        if (result == 1) {
          this.setSelectedBusiness();
          this.showDeleteSchConfirm = false;
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.scheduledeleted"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.schedulefailed"),
            life: 3000,
          });
        }
      });
    },
    dateClick(event) {
      this.$log.debug(event);
      this.newSlot.SlotDate = event;
      this.addingSlot = true;
    },
    onEventClick(event) {
      this.$log.debug(event.slotId);
      this.clickedTimeSlot = event;
      this.editingTimeSlotSchedule = true;
      //this.setEditTimeSlot(event.slotId);
    },
    async fetchEvents() {
      await CalendarEvent.fetchAllCalendarEvents(this.currentBusinessId).then(
        (response) => {
          this.calendarEvents = [];
          this.calendarEvents = response;
          this.$log.debug("calendar events");
          this.$log.debug(this.calendarEvents);
        }
      );
    },
    validateCreateTimeslot() {
      this.$log.info("validating new timeslot");
      this.$log.debug(this.newSlot);
      //clear out errors
      this.errors = [];
      this.creatingTimeslot = true;

      var visible = this.newSlot.SlotVisible.value;
      this.newSlot.SlotVisible = visible;
      var available = this.newSlot.SlotAvailable.value;
      this.newSlot.SlotAvailable = available;
      this.newSlot.BusId = this.currentBusinessId;
      this.newSlot.SchId = null;

      //verify required values
      if (this.newSlot.SlotDate.length == 0) {
        this.errors.push(this.$t("code.date"));
        document.getElementById("newSlotDate").classList.add("p-invalid");
      }
      if (this.newSlot.SlotStartTime.length == 0) {
        this.errors.push(this.$t("code.starttime"));
        document.getElementById("newSlotStartTime").classList.add("p-invalid");
      }
      if (this.newSlot.SlotEndTime.length == 0) {
        this.errors.push(this.$t("code.enddate"));
        document.getElementById("newSlotEndTime").classList.add("p-invalid");
      }
      if (this.newSlot.SlotVisible.length == 0) {
        this.errors.push(this.$t("code.visibility"));
        document.getElementById("newVisible").classList.add("p-invalid");
      }
      if (this.newSlot.SlotAvailable.length == 0) {
        this.errors.push(this.$t("code.availability"));
        document.getElementById("newAvailable").classList.add("p-invalid");
      }

      if (this.errors.length == 0) {
        this.createTimeSlot();
      } else {
        this.creatingTimeslot = false;
      }
    },
    async createTimeSlot() {
      this.$log.info("create new time slot");
      this.$log.debug(this.newSlot);
      await TimeSlot.createTimeSlot(this.newSlot).then(async (response) => {
        this.$log.debug(response);
        this.creatingTimeslot = false;
        this.setSelectedBusiness();
        if (response == 1) {
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.timeslotcreated"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.timeslotcreatefailed"),
            life: 3000,
          });
        }
      });

      this.resetCreateTimeslot();
    },
    resetCreateTimeslot() {
      this.addingSlot = false;
      this.creatingTimeslot = false;
      this.newSlot = {
        SlotId: 0,
        SlotDate: "",
        SlotStartTime: new Date(2011, 0, 1, 13, 0, 0, 0),
        SlotEndTime: new Date(2011, 0, 1, 23, 59, 0, 0),
        SlotVisible: { name: this.$t("calendar.visible"), value: true },
        SlotAvailable: { name: this.$t("calendar.available"), value: true },
        SchId: null,
        TypeCode:"DATE",
        SlotRequested: false,
        BusId: "",
      };
      this.errors = [];
    },
    async setEditTimeSlot(slotId) {
      this.editingSlot = true;
      this.loadingSlot = true;
      await TimeSlot.getTimeslot(slotId).then(async (result) => {
        this.$log.debug(result);
        this.slotToUpdate = result;
        this.slotToUpdate.NewSlotAvailable = this.slotToUpdate.SlotAvailable;
        if (this.slotToUpdate.SlotAvailable == false) {
         //console.log(this.slotToUpdate.TypeCode);
          if (this.slotToUpdate.TypeCode == "DATE") {
            await Reservation.fetchBySlotId(this.slotToUpdate.SlotId).then(
              (result) => {
                this.$log.debug(result);
                this.relatedReserv = result;
               //console.log(this.relatedReserv);
              }
            );
          } else {
            await BusinessAppointment.fetchBySlotId(
              this.slotToUpdate.SlotId
            ).then((result) => {
             //console.log(result);
              this.relatedAppt = result;
            });
          }
        } else if (this.slotToUpdate.SlotRequested == true) {
          if (this.slotToUpdate.TypeCode == "DATE") {
            await Quote.fetchBySlotId(this.slotToUpdate.SlotId).then(
              (result) => {
                this.$log.debug(result);
                this.relatedQuotes = result;
              }
            );
          }
        }
        this.loadingSlot = false;
      });
      if (this.slotToUpdate.SlotVisible == true) {
        this.slotToUpdate.visibility = { name: "Visible", value: true };
      } else {
        this.slotToUpdate.visibility = { name: "Hidden", value: false };
      }

      if (this.slotToUpdate.SlotAvailable == true) {
        this.slotToUpdate.availability = { name: "Available", value: true };
      } else {
        this.slotToUpdate.availability = { name: "Reserved", value: false };
      }

      this.slotToUpdate.displayStartTime = new Date(
        Date.parse("2011-10-10T" + this.slotToUpdate.SlotStartTime)
      );
      this.slotToUpdate.displayEndTime = new Date(
        Date.parse("2011-10-10T" + this.slotToUpdate.SlotEndTime)
      );
      this.slotToUpdate.displayDate = new Date(
        this.$formatDateTimeToDate(this.slotToUpdate.SlotDate)
      );

      var calendarEvent = this.calendarEvents.find(
        (event) => event.slotId == this.slotToUpdate.SlotId
      );
      if (calendarEvent != null) {
        this.slotToUpdate.title = calendarEvent.title;
      } else {
        this.slotToUpdate.title == null;
      }

      this.$log.debug(this.slotToUpdate);
      // this.editingSlot = true;
    },
    async fetchSchedules() {
      await Schedule.fetchByBusId(this.currentBusinessId).then((response) => {
        this.schedules = response;
        this.$log.debug(response);
      });
    },

    validateEditTimeSlot() {
      this.$log.info("validating edit time slot");
      this.$log.debug(this.slotToUpdate);
      this.savingEditTimeSlot = true;
      //clear out errors
      this.errors = [];

      //verify required values
      if (this.slotToUpdate.SlotDate.length == 0) {
        this.errors.push(this.$t("code.date"));
        document.getElementById("editSlotDate").classList.add("p-invalid");
      }
      if (this.slotToUpdate.SlotStartTime.length == 0) {
        this.errors.push(this.$t("code.starttime"));
        document.getElementById("editSlotStartTime").classList.add("p-invalid");
      }
      if (this.slotToUpdate.SlotEndTime.length == 0) {
        this.errors.push(this.$t("code.enddate"));
        document.getElementById("editSlotEndTime").classList.add("p-invalid");
      }
      if (this.slotToUpdate.SlotVisible.length == 0) {
        this.errors.push(this.$t("code.visibility"));
        document.getElementById("editVisible").classList.add("p-invalid");
      }
      if (this.slotToUpdate.SlotAvailable.length == 0) {
        this.errors.push(this.$t("code.availability"));
        document.getElementById("editAvailable").classList.add("p-invalid");
      }
      if (this.errors.length == 0) {
        // this.slotToUpdate.SlotVisible = this.slotToUpdate.visibility.value;
        this.slotToUpdate.SlotDate = this.slotToUpdate.displayDate;
        this.slotToUpdate.SlotStartTime = this.slotToUpdate.displayStartTime;
        this.slotToUpdate.SlotEndTime = this.slotToUpdate.displayEndTime;
        // this.slotToUpdate.SlotAvailable = this.slotToUpdate.availability.value;

        this.editTimeSlot();
      } else {
        this.savingEditTimeSlot = false;
      }
    },
    async editTimeSlot() {
      this.$log.info("edit timeslot");
      this.$log.debug(this.slotToUpdate);

      await TimeSlot.updateTimeslot(this.slotToUpdate).then(
        async (response) => {
          await this.setSelectedBusiness();
          this.savingEditTimeSlot = false;
          this.$log.debug(response);
          if (response == 1) {
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.timeslotedited"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.timesloteditfailed"),
              life: 3000,
            });
          }
        }
      );
      this.resetEditSlot();
    },
    resetEditSlot() {
      this.relatedReserv = {};
      this.selectedQuote = {};
      this.viewingQuote = false;
      this.editingSlot = false;
      this.editConfirmed = false;
      this.slotToUpdate = {
        SlotId: 0,
        SlotDate: "",
        SlotStartTime: "",
        SlotEndTime: "",
        SlotVisible: "",
        SlotAvailable: "",
        SchId: 0,
        SlotRequested: "",
        BusId: "",
        visibility: {},
        displayDate: "",
        displayStartTime: "",
        displayEndTime: "",
        availability: "",
        TypeCode:"DATE"
      };
      this.errors = [];
    },
    showTimeSlotDeleteConfirm(slotId) {
      this.$log.debug("delete confirm");
      this.$confirm.require({
        message: this.$t("code.timeslotconfirm"),
        header: "Delete Timeslots?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteTimeslot(slotId);
        },
        reject: () => {},
      });
    },
    async deleteTimeslot(slotId) {
      await TimeSlot.deleteTimeslot(slotId).then((result) => {
        if (result == 1) {
          this.setSelectedBusiness();
          // this.showTimeSlotDeleteConfirm = false;
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.timeslotdeleted"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.timeslotfailed"),
            life: 3000,
          });
        }
      });
    },
    async setSelectedBusiness() {
      this.loading = true;
      Business.fetchBusinessProfileById(this.currentBusinessId).then(
        (result) => {
          this.currentBusiness = result;
        }
      );
      Subscription.fetchCurrentByBusiness(this.currentBusinessId).then((result) => {
          this.currentSubscription = result;
         //console.log(this.currentSubscription)
        });
      await this.fetchSchedules();
      await this.fetchEvents();
      this.loading = false;
     //console.log("loading done")
    },
  },
  watch: {
    async currentBusinessId() {
      this.setSelectedBusiness();
    },
    lang() {
      this.$log.debug("lang changed");
      //window.location.reload();
      // this.$router.go(0);
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
      lang: (state) => state.lang,
    }),
    days() {
      return [
        { name: this.$t("days.m"), value: "MON" },
        { name: this.$t("days.t"), value: "TUES" },
        { name: this.$t("days.w"), value: "WED" },
        { name: this.$t("days.th"), value: "THUR" },
        { name: this.$t("days.f"), value: "FRI" },
        { name: this.$t("days.sa"), value: "SAT" },
        { name: this.$t("days.su"), value: "SUN" },
      ];
    },
  },
};
</script>
<style >
.p-tabview-nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fc-header-toolbar {
  display: flex !important;
  flex-direction: column !important;
}
.fc-toolbar-chunk {
  margin-bottom: 3px !important;
}
.p-chip.chip-sm {
  font-size: small;
}
.p-chip.success-chip {
  background: #689f38;
  color: #ffffff;
}
.p-chip.error-chip {
  background: #d32f2f;
  color: #ffffff;
}
.p-chip.dark-chip {
  background: #dee2e6;
  color: #212529;
}
.p-chip.warning-chip {
  background: #ffc107;
  color: #212529;
}
.p-chip.primary-chip {
  background: var(--mr-primary-color);
  color: #ffffff;
}
.vuecal__header button {
  color: #212529 !important;
}
.vuecal__header button:hover {
  background-color: rgba(0, 0, 0, 0.02) !important;
}
.vuecal__event:hover {
  cursor: pointer !important;
}
@media screen and (min-width: 768px) {
  .fc-header-toolbar {
    flex-direction: row !important;
  }
}
</style>
