<template>
  <div class="col-12 purple mb-5 mt-5">
    <div class="col-12 pt-5">
      <span
        class="pointer"
        style="
          color: white;
          font-family: 'Leckerli One';
          font-size: 60px;
          font-style: normal;
          font-variant: normal;
          font-weight: 100;
          line-height: 63.7px;
          text-align: center;
        "
      >
        MyReservs
      </span>
    </div>
    <span style="font-size: xx-large">{{ $t("footer.customerfeatures") }}</span>
    <p style="font-size: large; padding-right: 2rem; padding-left: 2rem">
      {{ $t("customerfeatures.joinfree") }}
    </p>
  </div>

  <div class="grid mr-5 ml-5 align-items-center">
    <div class="col-12 md:col-6">
      <h1 class="pi-primary">{{ $t("menu.favorites") }}</h1>
      <span style="font-size: large">
        {{ $t("customerfeatures.favoritesdetail") }}</span
      >
    </div>
    <div class="col-12 md:col-6 mb-5">
      <Image
        class="grow full-width"
        :src="designImageHostingURL + 'CustomerFavorites.png'"
        alt="Image"
        preview
      />
    </div>
       <div class="col-12 md:col-6 mb-5 mt-5">
      <Image
        class="grow full-width"
        :src="designImageHostingURL + 'CustomerQuotes.png'"
        alt="Image"
        preview
      />
    </div>
    <div class="col-12 md:col-6 mb-5 mt-5">
      <h1 class="pi-primary">{{ $t("menu.quotes") }}</h1>
      <span style="font-size: large">{{
        $t("customerfeatures.quotesdetails")
      }}</span>
    </div>
 
    <div class="col-12 md:col-6 mb-5 mt-5">
      <h1 class="pi-primary">{{ $t("menu.reservs") }}</h1>
      <span style="font-size: large">{{
        $t("customerfeatures.reservsdetails")
      }}</span>
    </div>
    <div class="col-12 md:col-6 mb-5 mt-5">
      <Image
        class="grow full-width"
        :src="designImageHostingURL + 'CustomerReservations.png'"
        alt="Image"
        preview
      />
    </div>
    <div class="col-12 md:col-6 mb-5 mt-5">
      <Image
        class="grow full-width"
        :src="designImageHostingURL + 'CustomerPayments.png'"
        alt="Image"
        preview
      />
    </div>
    <div class="col-12 md:col-6 mb-5 mt-5">
      <h1 class="pi-primary">
        {{ $t("listmybusiness.onlinepay") }}<br /><a
          href="https://stripe.com/about"
          target="_blank"
        >
          <img
            class="grow pointer"
            src="@/assets/PoweredByStripe.png"
            style="width: 30%"
        /></a>
      </h1>
      <span style="font-size: large">{{
        $t("customerfeatures.paymentdetails")
      }}</span>
    </div>

    <div class="col-12 md:col-6 mb-5 mt-5">
      <h1 class="pi-primary">{{ $t("menu.notifications") }}</h1>
      <span style="font-size: large">{{
        $t("customerfeatures.notificationsdetail")
      }}</span>
    </div>
    <div class="col-12 md:col-6 mb-5 mt-5">
      <Image
        class="grow full-width"
        :src="designImageHostingURL + 'CustomerNotifications.png'"
        alt="Image"
        preview
      />
    </div>
 <div class="col-12 md:col-6 mb-5 mt-5">
      <Image
        class="grow full-width"
        :src="designImageHostingURL + 'BusinessMessages.png'"
        alt="Image"
        preview
      />
    </div>
    <div class="col-12 md:col-6 mb-5 mt-5">
      <h1 class="pi-primary">{{ $t("listmybusiness.directmessages") }}</h1>
      <span style="font-size: large">{{
        $t("customerfeatures.messagesdetail")
      }}</span>
    </div>
   
    <div class="col-12 md:col-6 mb-5 mt-5">
      <h1 class="pi-primary">{{ $t("listmybusiness.customerreviews") }}</h1>
      <span style="font-size: large">{{
        $t("customerfeatures.reviewsdetail")
      }}</span>
    </div>
    <div class="col-12 md:col-6 mb-5 mt-5">
      <Image
        class="grow full-width"
        :src="designImageHostingURL + 'BusinessRating.png'"
        alt="Image"
        preview
      />
    </div>

 
  </div>
     <div class="col-12 mb-5 purple">
      <h1>{{ $t("customerfeatures.joinfree") }}</h1>
      <Button
        :label="$t('menu.createpro')"
        class="
          p-button-raised
          p-button-lg
          p-button-text
          p-button-light
          p-button-plain
          mb-5
        "
        style="background-color: white"
        @click.stop="SignIn"
      />
    </div>
</template>
<script>
export default {
  name: "FunctionalityCustomer",
  components: {  },
  data() {
    return {};
  },
  methods: {
    async SignIn() {
      window.sessionStorage.setItem("redirecturl", this.$route.path);
        window.sessionStorage.setItem("requestedlogin", "true");
      this.$router.push({
        name: "Login"
      });
    },
  },
  computed: {
    designImageHostingURL() {
      return process.env.VUE_APP_DESIGNIMAGES_HOSTING_URL;
    },
  },
};
</script>
<style>
.full-width img {
  width: 100%;
}
</style>
