import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Customer from "./Customer"
import NotificationType from "./NotificationType"

export default class CustNotifiPermission extends Model {
  static entity = 'custNotifiPermissions'

  static primaryKey = 'PermId'

  static fields() {
    return {

      PermId: this.uid(),
      CustId: this.attr(null),
      NoticeTypeId: this.attr(null),
      NoticeEmail: this.attr(null),
      NoticeSms:  this.attr(null),
      NoticeApp:  this.attr(null),

      Cust: this.belongsTo(Customer, 'CustId'),
      NoticeType: this.belongsTo(NotificationType, 'NoticeTypeId'),

    }
  }

  static async setAppPermissions(custNotifiPermission) {
    //console.log("at set app permissions")
    //console.log(custNotifiPermission)
    custNotifiPermission.NoticeEmail=false;
    custNotifiPermission.NoticeSms = false;
    custNotifiPermission.PermId=0;

    const result = await CustNotifiPermission.api().post('/api/CustNotifiPermissions', custNotifiPermission).then((result) => {
      //console.log(result)
      if (result.response.status == 200 || result.response.status == 204 || result.response.status == 201) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }

}