<template>
  <div class="grid">
          <div class="col-12 grid-nogutter"></div>
          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: left">
              <div style="background-color: #e9ecef">
                <span style="font-size: x-large">{{
                  $t("custreserv.custinfo")
                }}</span>
              </div>
              <b>{{ $t("custreserv.custname") }}: </b>
              {{ selectedQuote.Cust.CustFirstName }}
              {{ selectedQuote.Cust.CustLastName }}
              <br />
              <b>{{ $t("custreserv.custphone") }}: </b
              >{{ selectedQuote.Cust.CustPhone }}
              <br />
              <b>{{ $t("profile.address") }}: </b>
              {{ selectedQuote.Cust.CustAddress }}<br />
              {{ selectedQuote.Cust.CustCity }},
              {{ selectedQuote.Cust.CustState }}
              {{ selectedQuote.Cust.CustZip }}
              <br />
              <b>{{ $t("message.email") }}: </b>
              {{ selectedQuote.Cust.CustEmail }}
            </div>
          </div>
          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: right">
              <div style="background-color: #e9ecef">
                <span style="font-size: x-large">
                  {{ $t("custreserv.custprocess") }}</span
                >
              </div>
              <b>{{ $t("custreserv.custdatere") }}: </b
              >{{ $formatDateTime(selectedQuote.QuoteRequestDate) }}
              <br />
              <b>{{ $t("custreserv.custdatepro") }}: </b
              ><span v-if="selectedQuote.QuoteProcessedDate">{{
                $formatDateTime(selectedQuote.QuoteProcessedDate)
              }}</span
              ><span v-else>{{ $t("custreserv.custdatenotpro") }}</span>
              <br />
              <b>{{ $t("custreserv.custproby") }}: </b
              ><span v-if="selectedQuote.QuoteProcessedBy">{{
                selectedQuote.QuoteProcessedBy
              }}</span
              ><span v-else>{{ $t("custreserv.custdatenotpro") }} </span
              ><br />
              <b>{{ $t("custreserv.custresponded") }}: </b
              ><span v-if="selectedQuote.QuoteResponseDate">{{
                $formatDateTime(selectedQuote.QuoteResponseDate)
              }}</span
              ><span v-else>{{ $t("custreserv.custnotapp") }}</span>
            </div>
          </div>

          <div class="col-12" style="background-color: #e9ecef">
            <span style="font-size: x-large">{{
              $t("custreserv.custevent")
            }}</span
            ><br />
          </div>
          <div class="col-12 center-text">
            <template v-if="selectedQuote.Slot != null">
              {{ $formatDateTimeToDate(selectedQuote.Slot.SlotDate) }}<br />
              {{ $formatTime(selectedQuote.Slot.SlotStartTime) }} -
              {{ $formatTime(selectedQuote.Slot.SlotEndTime) }}
            </template>
          </div>
          <div class="col-12 mb-2" style="background-color: #e9ecef">
            <span style="font-size: x-large">{{
              $t("services.services")
            }}</span>
          </div>
          <div class="col-4" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custservice") }}</b>
          </div>
          <div class="col-3" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custprice") }}</b>
          </div>
          <div class="col-2" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custqty") }}</b>
          </div>
          <div
            class="col-3"
            style="background-color: #f8f9fa; text-align: right"
          >
            <b>{{ $t("custreserv.custamount") }}</b>
          </div>

          <template
            v-for="serv in selectedQuote.QuotedServices"
            :key="serv.QuoteServId"
          >
            <template
              v-if="
                serv.QuoteServOrigId == null && serv.QuoteServRemoved == false
              "
            >
              <div class="col-4">
                <span style="vertical-align: -webkit-baseline-middle"
                  >{{ serv.Serv.ServTitle }}
                </span>
              </div>
              <div class="col-3 flex flex-wrap align-items-center">
                <span style="vertical-align: -webkit-baseline-middle">
                  ${{ serv.ServPrice }}
                </span>
                <span
                  style="
                    vertical-align: -webkit-baseline-middle;
                    font-size: small;
                  "
                  v-if="serv.Serv.ServPriceCalculated == true"
                >
                  &nbsp;{{ $t("quotes.each") }}
                </span>
              </div>
              <div class="col-2" style="vertical-align: middle">
                <span style="vertical-align: -webkit-baseline-middle">{{
                  serv.QuoteServQty
                }}</span>
              </div>
              <div class="col-3" style="text-align: right">
                <span v-if="serv.QuoteServTotal != 0"
                  ><b style="vertical-align: -webkit-baseline-middle"
                    >${{ serv.QuoteServTotal }}</b
                  ></span
                >
              </div>
            </template>
          </template>

          <div
            class="col-12 mt-5 grid grid-nogutter justify-content-end"
            style="text-align: right"
          >
            <div class="col-9" style="background-color: #f8f9fa">
              <b>{{ $t("custreserv.custsub") }}&nbsp;&nbsp;</b>
            </div>
            <div class="col-3">
              <span v-if="selectedQuote.QuoteSubTotal != 0"
                ><b>${{ selectedQuote.QuoteSubTotal }}</b></span
              >
            </div>
            <div
              v-if="selectedQuote.QuoteDiscount != 0"
              class="col-9"
              style="background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custdis") }}&nbsp;&nbsp;</b>
            </div>
            <div v-if="selectedQuote.QuoteDiscount != 0" class="col-3">
              <span
                ><b>${{ selectedQuote.QuoteDiscount }}</b></span
              >
            </div>
            <div
              class="col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custsales") }}&nbsp;&nbsp;</b>
            </div>
            <div class="col-3">
              <span v-if="selectedQuote.QuoteSubTotal != 0">
                <b>${{ selectedQuote.QuoteTax }}</b></span
              >
            </div>
            <div
              class="col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custtotal") }}&nbsp;&nbsp;</b>
            </div>
            <div class="col-3">
              <span v-if="selectedQuote.QuoteSubTotal != 0">
                <b>${{ selectedQuote.QuoteTotal }}</b></span
              >
            </div>
          </div>

          <div class="col-12" style="background-color: #e9ecef">
            <span style="font-size: x-large"
              >{{ $t("custreserv.custcomments") }}
            </span>
          </div>
          <div class="col-12">
            <div v-if="selectedQuote.QuoteComments.length > 0">
              <template
                v-for="comment in selectedQuote.QuoteComments"
                :key="comment.CommentId"
              >
                <div class="card" style="text-align: left">
                  <div class="flex justify-content-between">
                    <span>
                      {{ comment.Cust.CustFirstName }}
                      {{ comment.Cust.CustLastName }}</span
                    >
                    <span> {{ $formatDateTime(comment.CommentDate) }}</span>
                  </div>
                  <p>
                    <em>{{ comment.CommentText }}</em>
                  </p>
                </div>
              </template>
            </div>
            <div v-else>
              {{ $t("custreserv.nocomments") }}
            </div>
          </div>
        </div>
</template>

<script>
 
export default {
  name: "QuoteDisplay",
  props: {
    selectedQuote: Object
  },
 
  methods: {
 
  },
};
</script>
<style scoped>

</style>

