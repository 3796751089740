import { Model } from '@vuex-orm/core'

export default class NotificationType extends Model {
  static entity = 'notificationTypes'

  static primaryKey = 'NoticeTypeId'

  static fields() {
    return {
      NoticeTypeId: this.uid(),
      NoticeTypeTitle: this.attr(null),
      NoticeTypeDesc: this.attr(null),
      NoticeTypeIcon: this.attr(null)

    }
  }

}