// Component: Notification Cards

<template>
  <div class="text-center" v-if="loading">
   <i class="pi pi-spin pi-spinner"></i>
  </div>
  <div v-else>
    <div v-if="!any">{{ $t("services.nonotifications") }}</div>
    <div v-for="notification in notifications" :key="notification.NoticeId">
      <Card @click="$router.push(notification.NoticeLink);$emit('hide');"  class="mb-1 pointer">
        <template #content>
          <div
            class="grid align-items-center"
            :class="{ newNotice: notification.NoticeRead === false }"
          >
            <div class="col-2">
              <Button
                v-if="notification.NoticeRead == false"
                :icon="'pi ' + notification.NoticeType.NoticeTypeIcon"
                class="p-button-rounded p-button-primary"
              />
              <Button
                v-else
                :icon="'pi ' + notification.NoticeType.NoticeTypeIcon"
                class="p-button-rounded p-button-secondary"
              />
            </div>
            <div class="col-10">
              {{ notification.NoticeTitle }}<br />

              <small>{{ notification.NoticeBody }}</small
              ><br />
              <span style="font-size: x-small">
                {{ $formatDateTime(notification.NoticeDate) }}</span
              >
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Notification from "../store/Models/Notification";
export default {
  name: "MRNotifications",
emits: ["hide"],
  data() {
    return {
      notifications: [],
      any: false,
      loading: false,
    };
  },
  mounted() {
    this.$log.debug("notifications mounted");

    if (this.currentCustomerId > 0 && this.userLoggedIn == true) {
      this.loading = true;
      this.setNotifications();
    }
  },
  // unmounted() {
  //   Notification.markAsRead(this.currentCustomerId);
  // },
  methods: {
    setNotifications() {
      this.notifications = [];
      if (this.currentCustomerId > 0) {
        Notification.fetchByCustId(this.currentCustomerId).then((result) => {
          this.notifications = result;
          if (this.notifications.length > 0) this.any = true;
          this.loading = false;
        });
      }
    },
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) {
        this.setNotifications();
      } else {
        this.notifications = [];
      }
    },
  },
  computed: {
    ...mapState({
      userLoggedIn: (state) => state.userLoggedIn,
      currentCustomerId: (state) => state.currentCustomerId,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
    unreadNotifications() {
      return this.notifications.filter((notice) => notice.NoticeRead == false)
        .length;
    },
  },
};
</script>
<style scoped>
.newNotice {
  font-weight: bold;
  border-left: 3px solid var(--primary-color) !important;
}
</style>

