// Component: ConvoDisplay

<template>
  <div id="msgDiv">
    <div v-for="message in data.Messages" :key="message.MessageId">
      <div v-if="message.MessageSentBy == 'cust'" style="text-align: right">
        <div class="grid">
          <div class="col-2">
            <img
            v-if="data.Cust.CustProfImage != null"
              :src="imageHostingURL + data.Cust.CustProfImage"
              :alt="data.Cust.CustProfImage"
              width="45"
              style="object-fit: cover"
            />
            <Avatar v-else icon="pi pi-user" class="mr-2"  width="45"
             />
          </div>
          <div class="col-10">
            <Card style="background-color: #f6d5d6"
              ><template #content>{{ message.MessageText }}</template></Card
            >
            <div style="text-align: right" class="pr-2">
               <!-- v-if="index == Object.keys(data.Messages).length - 1" -->
              <span  style="font-size:x-small"
                ><em>{{ $formatDateTimeToCalendar(message.MessageDate) }}</em>
              </span>
              <!-- <span v-else>
                <i v-tooltip="formatDateTime(message.MessageDate)" class="pi pi-clock" style="fontSize: x-small"></i>
              </span> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else style="text-align: left">
        <div class="grid">
          <div class="col-10">
            <Card style="background-color: #dacae5"
              ><template #content>{{ message.MessageText }} </template>
            </Card>
            <div style="text-align: left" class="pl-2">
              <span style="font-size:x-small"
                ><em>{{ $formatDateTimeToCalendar(message.MessageDate) }}</em>
              </span>
              <!-- <span v-else>
                <i aria-haspopup="true" v-tooltip.bottom.focus="formatDateTime(message.MessageDate)" class="pi pi-clock" style="fontSize: x-small"></i>
              </span> -->
            </div>
          </div>
          <div class="col-2">
            <img
            v-if="data.Bus.BusLogo != null"
              :src="imageHostingURL + data.Bus.BusLogo"
              :alt="data.Bus.BusName"
              width="45"
              style="object-fit: cover"
            />
            <Avatar v-else icon="pi pi-user" class="mr-2"  width="45"
               />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 
export default {
  name: "ConvoDisplay",
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  mounted() {
    this.$log.debug("convo display mounted - ");
    this.$log.debug(this.data);

    
 
  },
  computed: {
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style >
/* #msgDiv {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
} */
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.p-confirm-popup {
  z-index: 2000;
}
</style>

