<template>
  <div >
    <!-- <div
      class="grid justify-content-center m-5 align-items-center"
      style="background-color: rgba(255, 255, 255, 0.1)"
    > -->
    <div class="bg-table">
    <!-- <div
          class="col-12 text-7xl pt-5"
            style="color: white;
              font-family: 'Leckerli One';
              background-color: rgba(0, 0, 0, 0);
              text-align: center;
            "
          >
            MyReservs
          </div> -->
          <img
            alt="logo"
            src="@/assets/TextNoBack.png"
            height="70"
            class="mt-4"
          /><br />
      <div class="col-12 center-text"  style="background-color: rgba(0, 0, 0, .0);">
        <div>
    
          <block-menu-bar></block-menu-bar>
          <div v-if="!userLoggedIn" id="navigation" class="col-12">
          <login :font="'rgb(73, 80, 87)'"></login>
        </div>
        
        </div>
      </div>
    </div>
      <!-- user logged in -->
      <div v-if="userLoggedIn" style=" background-color: white; text-align:center; "> 
        <div class="text-3xl pt-3 font-bold">
            <span v-if="currentCustomer.CustFirstName != null">
              {{ currentDayTime }} {{ currentCustomer.CustFirstName }}!</span
            >
            <span v-else>{{ currentDayTime }}!</span>
          </div>
          <div
      class=" col-12 flex flex-wrap justify-content-center p-5" style="bottom:0">
      <Card class=" shadow-7 ml-3 mr-3 mb-5 pt-5 link" style="width: 16em" @click.stop="$router.push({ name: 'CustomerEvents' })">
        <template #header>
          <i class="pi pi-calendar-plus text-primary" style="font-size: 2.5rem"></i>
        </template>
        <template #title>{{ $t("code.myevents") }} </template>
    
      </Card>
      <Card class=" shadow-5 ml-3 mr-3 mb-5 pt-5 link" style="width: 16em" @click.stop="$router.push({ name: 'CustomerGuestList' })">
        <template #header>
          <i class="pi pi-bookmark text-primary" style="font-size: 2.5rem"></i>
        </template>
        <template #title> {{ $t("code.myguestlists") }}</template>
        <template #content>
        
        </template>
      </Card>
   
      <Card class="  shadow-7 ml-3 mr-3 mb-5 pt-5 link" style="width: 16em"  @click.stop="$router.push({ name: 'SearchResults' })">
        <template #header>
          <i class="pi pi-search text-primary" style="font-size: 2.5rem"></i>
        </template>
        <template #title>{{ $t("code.findavendor") }} </template>
        <template #content>
          <span class="text-primary" >{{ $t("code.comingsoon") }}</span>
        </template>
      </Card>
    
    
    
      </div>
      </div>




      <!-- USER NOT LOGGED IN -->
      <div v-if="!userLoggedIn" class="pt-5" style="background-color: rgba(168, 5, 152, .8); color:white"
    >

 <span class="text-2xl"> {{ $t("footer.customerfeatures") }}</span><br>
    
    <span class="pr-5 pl-5 text-xl" > {{ $t("customerfeatures.join") }}</span> <br>

    <Button
        :label="$t('menu.createacc')"
        class="
          p-button-raised
          p-button-lg
          p-button-text
          p-button-light
          p-button-plain
          m-5
        "
        style="background-color: white"
        @click.stop="SignIn"
      />
    </div>
      <div v-if="!userLoggedIn"  class="pt-5"      style=" background-color: white; text-align:center;"
    >
    <span class="text-3xl"> {{ $t("customerfeatures.eventplanning") }}</span>
 <div
      class=" col-12 flex flex-wrap justify-content-center p-5" style="bottom:0">

    <!-- background-color: #f0f0f0; -->
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-calculator mr-2"  style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("custevent.budget") }}</template>
        <template #content>
          {{ $t("customerfeatures.eventtool") }}
        </template>
      </Card>
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-check-square mr-2" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("custevent.todo") }}</template>
        <template #content>
          {{ $t("customerfeatures.eventtooltodo") }}
        </template>
      </Card>
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-list mr-2" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("custevent.guests") }}</template>
        <template #content>
          {{ $t("customerfeatures.eventtoolguests") }}
        </template>
      </Card>
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-gift mr-2" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("custevent.registries") }}</template>
        <template #content>
          {{ $t("customerfeatures.eventtoolregistries") }}
        </template>
      </Card>
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-share-alt mr-2" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("customerfeatures.eventtoolshared") }}</template>
        <template #content>
          {{ $t("customerfeatures.eventtoolsharedevent") }}
        </template>
      </Card>
 
 </div>
  </div>
      <div v-if="!userLoggedIn"  class="pt-5"      style=" background-color: white; text-align:center;"
    >
    <span class="text-3xl"> {{ $t("customerfeatures.eventreservationtools") }}</span>
 <div
      class=" col-12 flex flex-wrap justify-content-center p-5" style="bottom:0">

    <!-- background-color: #f0f0f0; -->
      <Card class="shadow-5 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-heart" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("menu.favorites") }}</template>
        <template #content>
          {{ $t("customerfeatures.favoritesdetail") }}
        </template>
      </Card>
      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-dollar" style="font-size: 60px"></i>
        </template>
        <template #title>{{ $t("menu.quotes") }} </template>
        <template #content>
          {{ $t("customerfeatures.quotesdetails") }}
        </template>
      </Card>
      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-calendar" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("menu.reservs") }}</template>

        <template #content>
          {{ $t("customerfeatures.reservsdetails") }}
        </template>
      </Card>

      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-money-bill" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("listmybusiness.onlinepay") }}</template>

        <template #content>
          {{ $t("customerfeatures.paymentdetails") }}
        </template>
      </Card>
      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-star-fill" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("menu.notifications") }} </template>

        <template #content> {{ $t("customerfeatures.notificationsdetail") }} </template>
      </Card>
      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-comments" style="font-size: 60px"></i>
        </template>
        <template #title>{{ $t("listmybusiness.directmessages") }} </template>

        <template #content>
          {{ $t("customerfeatures.messagesdetail") }}
        </template>
      </Card>
      <Card class="shadow-7 ml-3 mr-3 mb-5 pt-5 grow" style="width: 16em">
        <template #header>
          <i class="pi pi-star-fill" style="font-size: 60px"></i>
        </template>
        <template #title> {{ $t("listmybusiness.customerreviews") }} </template>

        <template #content>
          {{ $t("customerfeatures.reviewsdetail") }}
        </template>
      </Card>

       
    </div>
     <!-- <Button
        :label="$t('menu.viewdetails')"
        class="
          p-button-raised
          p-button-lg
          p-button-primary
          p-button-plain
          mb-5
        "
        @click.stop="$router.push({ name: 'FunctionalityBusiness' })"
        
      /> -->
</div>

<reservs-footer></reservs-footer>


  </div>

</template>
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";

import Login from "../components/Login.vue";
import BlockMenuBar from "../components/BlockMenuBar.vue";
import SupportTicket from "../store/Models/SupportTicket";
import ReservsFooter from '../components/ReservsFooter.vue';

export default {
  name: "ComingSoon",
  components: { Login, BlockMenuBar, ReservsFooter },
  props: {},
  data() {
    return {
      businessFeatures: false,
      customerFeatures: false,
      feedback: {},
      fkpassword: null,
      submitting: false,
      options: ["notified"],
      otherLang: "",
    };
  },
  mounted() {
    this.setLang();
  },
  methods: {

     async SignIn() {
      window.sessionStorage.setItem("redirecturl", this.$route.path);
        window.sessionStorage.setItem("requestedlogin", "true");
      this.$router.push({
        name: "Login"
      });
    },
    ...mapActions(["switchLang"]),

    validateFeedback() {
      this.$log.debug("validate customer feedback");
      this.submitting = true;
      let errors = 0;
      //verify required values
      if (this.options.length > 0) {
        this.feedback.TicketMessage = this.options.join(", ");
      }
      if (
        this.feedback.TicketEmail == null ||
        this.feedback.TicketEmail.length == 0
      ) {
        document.getElementById("email").classList.add("p-invalid");
        errors++;
      }
      if (this.fkpassword != null) {
        this.$log.debug("bot");
        errors++;
      }

      if (errors == 0) {
        this.feedback.TicketFirstName = "";
        this.feedback.TicketLastName = "";
        this.feedback.TicketCat = "Coming Soon";
        this.submitFeedback();
      } else {
        this.submitting = false;
      }
    },
    async submitFeedback() {
      this.$log.debug("submit feedback");
      this.$log.debug(this.feedback);
      await SupportTicket.createNewTicket(this.feedback, this.lang).then(
        async (response) => {
          this.$log.debug(response);
          if (response) {
            this.resetFeedback();
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("contactus.signupSuccess"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error!",
              detail: this.$t("contactus.signupFailed"),
              life: 3000,
            });
          }
          this.submitting = false;
        }
      );
    },
    resetFeedback() {
      this.feedback = {};
      this.options = ["notified"];
    },
    setLang() {
      this.$log.debug(this.lang);
      if (this.lang == "en") {
        this.otherLang = "Español";
        this.$i18n.locale = "en";
      }
      if (this.lang == "es") {
        this.otherLang = "English";
        this.$i18n.locale = "es";
      }
      document.getElementsByTagName("html")[0].lang = this.lang;
    },
    switchLanguage() {
      var newLang = "en";
      if (this.lang == "en") {
        newLang = "es";
        this.otherLang = "English";
      } else if (this.lang == "es") {
        newLang = "en";
        this.otherLang = "Español";
      }

      this.switchLang(newLang);
      this.$i18n.locale = newLang;
    },
  },
  watch: {
    lang() {
      this.$log.debug("lang changed");
      this.setLang();
    },
  },
  computed: {
    
    ...mapState({
    lang: (state) => state.lang,
    currentCustomerId: (state) => state.currentCustomerId,
    currentCustomer: (state) => state.currentCustomer,
      userLoggedIn: (state) => state.userLoggedIn,
      userMissingProfile: (state) => state.userMissingProfile
  }),
  currentDayTime() {
      var today = new Date();
      var curHr = today.getHours();

      if (curHr < 12) {
        return (this.$t("dashboard.goodmorning"));
      } else if (curHr < 18) {
        return (this.$t("dashboard.goodafternoon"));
      } else {
        return (this.$t("dashboard.goodevening"));
      }
    }
  }
};

</script>

<style scoped>
.bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("~@/assets/flowers.jpg");

  background-position: left;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  top: 0;
  left: 0;

  height: 100vh;

  overflow: auto;
}

.bg-table{

  background-image:   linear-gradient(rgba(250, 249, 246, 0.3), rgba(255, 255, 255, 1)),
    url("~@/assets/tableflower.png");
  background-position: left;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  background-attachment: scroll;
  height: 100%;

}

</style>