<template>
  <!-- <Card class="mt-3 mb-3">
        <template #content> -->
          <!-- <ScrollPanel
    style="width: 100%; height: 100vw"> -->
  <div class="p-fluid formgrid grid text-left">
    <div class="field col-12">
      <label>{{ $t("profile.picture") }}</label
      ><br />
      <div
        v-if="hideProfImageUpload && tempCustomer.CustProfImage != null"
        class="inline-flex"
      >
        <img
          :src="imageHostingURL + tempCustomer.CustProfImage"
          :alt="tempCustomer.CustEmail"
          width="100"
        />
        <Button
          class="p-button-text p-button-primary"
          :label="$t('profile.replacepicture')"
          @click.stop="replaceProfImage()"
        />
      </div>

      <FileUpload
        v-if="!hideProfImageUpload"
        name="logo"
        :maxFileSize="9000000"
        :fileLimit="1"
        accept="image/*"
        :multiple="false"
        :auto="true"
        :customUpload="true"
        @uploader="uploadProfImage"
        mode="basic"
      >
      </FileUpload>
    </div>
    <div class="field col-12 md:col-6">
      <label for="first">{{ $t("profile.firstname") }}: <span class="required-star">*</span></label>
      <InputText id="first" type="text" v-model="tempCustomer.CustFirstName" />
    </div>
    <div class="field col-12 md:col-6">
      <label for="last">{{ $t("profile.lastname") }}:  <span class="required-star">*</span></label>
      <InputText id="last" type="text" v-model="tempCustomer.CustLastName" />
    </div>
    <div class="field col-12 md:col-6">
      <label for="phone">{{ $t("profile.phonenumber") }}:  <span class="required-star">*</span></label>
      <InputMask
        id="phone"
        mask="(999) 999-9999"
        v-model="tempCustomer.CustPhone"
        placeholder="(999) 999-9999"
      />
    </div>
    <div class="field col-12 md:col-6">
      <label for="language">{{ $t("profile.language") }}: </label>
      <Dropdown
        :options="languages"
        optionLabel="desc"
        optionValue="code"
        id="lang"
        v-model="tempCustomer.CustLang"
      />
    </div>
    <div class="field col-12 md:col-6">
      <label for="address">{{ $t("profile.address") }}:  <span class="required-star">*</span></label>
      <vue-google-autocomplete
        id="address"
        classname="p-inputtext"
        :placeholder="tempCustomer.CustAddress"
        v-on:placechanged="setCustomerAddress"
        :country="['us']"
      >
      </vue-google-autocomplete>
    </div>
    <div class="field col-12 md:col-6">
      <label for="city">{{ $t("profile.city") }}:  <span class="required-star">*</span></label>
      <InputText id="city" type="text" v-model="tempCustomer.CustCity" />
    </div>
    <div class="field col-6">
      <label for="state">{{ $t("profile.state") }}:  <span class="required-star">*</span></label>

      <Dropdown
        :options="compStates"
        optionLabel="code"
        optionValue="code"
        id="state"
        v-model="tempCustomer.CustState"
      />
    </div>
    <div class="field col-6">
      <label for="zip">{{ $t("profile.zip") }}: <span class="required-star">*</span></label>
      <InputText id="zip" type="text" v-model="tempCustomer.CustZip" />
    </div>
    <!-- <div class="col-12" v-if="errors.length > 0">
              <Message severity="error" style="text-align: left">
                <ul>
                  <li v-for="error of errors" :key="error">{{ error }}</li>
                </ul></Message
              >
            </div> -->
    <div class="col-12 flex mt-3">
      <!-- <Button
        :label="$t('profile.later')"
        icon="pi pi-times"
        @click.stop="resetCustomerUpdate"
        class="p-button-secondary mr-3"
      /> -->
      <Button
      v-if="!saving"
        :label="$t('profile.save')"
        icon="pi pi-check"
        class="p-button-primary"
        @click.stop="validateCustomerSave"
      />
         <Button
              v-else
              class="p-button-primary"
              :label="$t('profile.saving')"
              icon="pi pi-spin pi-spinner"
              disabled
            />
    </div>
  </div>
          <!-- </ScrollPanel> -->
  <!-- </template>
      </Card> -->
</template>
<script>
import { mapActions } from "vuex";
import Customer from "../store/Models/Customer";
import VueGoogleAutocomplete from "vue-google-autocomplete";
let states = require("../data/states.js");
import { mapGetters } from "vuex";

export default {
  name: "CompleteCustomerProfile",
  components: { VueGoogleAutocomplete },
  data() {
    return {
      hideProfImageUpload: false,
      errors: [],
      saving:false,
      languages: [
        { code: "en", desc: "English" },
        { code: "es", desc: "Español" },
      ],
      tempCustomer: {
        CustId: 0,
        CustFirstName: "",
        CustLastName: "",
        CustAddress: "",
        CustCity: "",
        CustState: "",
        CustZip: "",
        CustPhone: "",
        CustEmail: "",
        NewProfPicture: [],
        CustLang:"en"
      },
    };
  },
  mounted() {
    this.$log.debug("customer profile edit componenet mounted");
    if (this.userMissingProfile) {
      this.tempCustomer.CustEmail = this.getCurrentADUser.username;
    } else {
      if (this.currentCustomerId != 0) this.setCurrentCustomer();
    }
  },
  methods: {
    ...mapActions(["refreshUserData", "setUserMissingProfile"]),
    setCustomerAddress: function (addressData) {
      this.$log.debug(addressData);
      this.tempCustomer.CustAddress =
        addressData.street_number + " " + addressData.route;
      this.tempCustomer.CustCity = addressData.locality;
      this.tempCustomer.CustState = addressData.administrative_area_level_1;
      this.tempCustomer.CustZip = addressData.postal_code;
      this.custAddrValidated = true;
    },
    replaceProfImage() {
      this.hideProfImageUpload = false;
    },
    uploadProfImage(event) {
      this.$log.debug("upload profile image event");
      this.$log.debug(event);
      if (event.files != null) {
        this.tempCustomer.NewProfPicture = event.files;
      }
    },
    validateCustomerSave() {
      this.$log.debug("validate customer save");
      this.saving=true;
      this.$log.debug(this.tempCustomer);
      this.errors = [];
     //console.log(document.getElementById("address").value);
      this.tempCustomer.CustAddress = document.getElementById("address").value;

     //console.log(this.tempCustomer)

      //verify required values
      if (this.tempCustomer.CustFirstName.length == 0) {
        this.errors.push(this.$t("listmybusiness.rebusname"));
        document.getElementById("first").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustLastName.length == 0) {
        this.errors.push(this.$t("listmybusiness.relname"));
        document.getElementById("last").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustPhone.length == 0) {
        this.errors.push(this.$t("listmybusiness.rephone"));
        document.getElementById("phone").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustAddress.length == 0) {
        this.errors.push(this.$t("listmybusiness.readdress"));
        document.getElementById("address").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustCity.length == 0) {
        this.errors.push(this.$t("listmybusiness.recity"));
        document.getElementById("city").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustState.length == 0) {
        this.errors.push(this.$t("listmybusiness.readdress"));
        document.getElementById("state").classList.add("p-invalid");
      }
      if (this.tempCustomer.CustZip.length == 0) {
        this.errors.push(this.$t("listmybusiness.zip"));
        document.getElementById("zip").classList.add("p-invalid");
      }

      this.$log.debug(this.errors);

      if (this.errors.length == 0) {
        if (this.tempCustomer.CustId != 0) {
          this.updateCustomer();
        } else {
          this.createCustomer();
        }
      }
      else{
        this.saving=false;
      }
    },
    async updateCustomer() {
      this.$log.debug("update customer");
      this.loading = true;
      await Customer.updateCustomer(this.tempCustomer).then(
        async (response) => {
            this.saving=false;
          if (response == 1) {
            this.refreshUserData(this.tempCustomer.CustId);
            await this.setCurrentCustomer();
            this.showEditProfile = false;
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.profileupdate"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.profilefailedsave"),
              life: 3000,
            });
          }
          this.loading = false;
        }
      );
    },
    async createCustomer() {
      this.$log.debug("create customer");
      this.loading = true;
   
      this.tempCustomer.CustEmail = this.getCurrentADUser.username;
   
    await Customer.createCustomer(this.tempCustomer).then(
        async (response) => {
            this.saving=false;
          if (response == 1) { 
            this.setUserMissingProfile(false)
            this.$log.debug(this.tempCustomer);
           this.setNewCustomer();
                
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.profileupdate"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.profilefailedsave"),
              life: 3000,
            });
          }
          this.loading = false;
          this.resetCustomerUpdate();
        }
      );
    },
    async setNewCustomer() {
      await this.$SignOut().then(async () => {
        window.sessionStorage.setItem("redirecturl", "CustomerDashboard");
        this.$router.push({
          name: "Login",
        });
      });
    },
    resetCustomerUpdate() {
      this.$emit("closeDialog");
      this.setCurrentCustomer();
      this.errors = [];
      this.saving = false;
      this.showEditProfile = false;
    },
    async setCurrentCustomer() {
      this.loading = true;
      await Customer.getCurrentCustomer().then(async (response) => {
        this.$log.debug("Get current customer");
        this.$log.debug(response);
        if(response != null && response.CustId>0)
        {
        this.tempCustomer = response;
        if (this.tempCustomer.CustProfImage != null) {
          this.hideProfImageUpload = true;
        } else {
          this.hideProfImageUpload = false;
        }
        }
        this.loading = false;
      });
    },
    setLang() {
      this.$log.debug(this.lang);
      if (this.lang == "en") {
        this.$i18n.locale = "en";
      }
      if (this.lang == "es") {
        this.$i18n.locale = "es";
      }
      document.getElementsByTagName("html")[0].lang = this.$i18n.locale;
    },
  },
  computed: 
  {...mapGetters(["getCurrentADUser"]),
    compStates() {
      return states;

    },
  },
};
</script>

<style>
.searchingBackground {
  opacity: 0.8;
  background-color: #ccc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
}
</style>