<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <div v-if="currentBusiness != null && currentBusiness.BusActive == true && currentSubscription != null && currentSubscription.SubDef != null && (currentSubscription.SubDef.SubscriptionLevelCode == 'BASIC' ||currentSubscription.SubDef.SubscriptionLevelCode == 'PREMIUM')">
      <div v-if="currentBusiness.BusDisplayServices != true">
        <Message severity="warn" :closable="false">{{
          $t("services.disable")
        }}</Message>
      </div>
      <DataView
        class="mt-2 service-grid"
        :value="filteredServices"
        :layout="layout"
        :paginator="true"
        :rows="9"
        :sortOrder="sortOrder"
        :sortField="sortField"
      >
        <template #empty> {{ $t("services.nopackagesyet") }} </template>
        <template #loading>{{ $t("message.loading") }} </template>
        <template #header>
          <div class="flex flex-wrap justify-content-between">
            <div class="m-1">
              <SelectButton
                v-model="selectedFilter"
                :options="filterOptions"
                optionLabel="name"
                optionValue="value"
              />
              <!-- <Dropdown
                v-model="sortKey"
                class="mr-2"
                :options="sortOptions"
                optionLabel="label"
                :placeholder="$t('services.sort')"
                @change="onSortChange($event)"
                style="font-size: small"
              /> -->
            </div>
            <div class="m-1">
              <Button
                v-if="myBusiness"
                :label="$t('menu.newpackage')"
                icon="pi pi-plus"
                iconPos="left"
                @click.stop="startServiceCreate"
                class="p-button-raised p-button-sm p-button-primary"
              />
            </div>
          </div>
        </template>
        <template #grid="slotProps">
          <div
            v-if="slotProps.data.ServDeleted != true"
            class="col-12 md:col-6 lg:col-4 mb-3"
          >
            <!-- <div class="product-grid-item card" > -->
            <service-grid-layout
              :data="slotProps.data"
              :mode="mode"
              :view="'short'"
              :hideFav="'true'"
              v-on:favorite="addToFavorites"
              v-on:view="viewDetails(slotProps.data)"
              v-on:delete="deleteService(slotProps.data)"
              v-on:edit="startServiceEdit(slotProps.data)"
            />
            <!-- </div> -->
          </div>
        </template>
      </DataView>

      <!-- SERVICE DETAILS DIALOG -->
      <Dialog
        v-model:visible="viewingService"
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        :style="{ width: '50vw' }"
        :modal="true"
        :dismissableMask="true"
      >
        <service-grid-layout
          :data="tempService"
          :mode="mode"
          :view="'full'"
          v-on:favorite="addToFavorites"
          v-on:view="viewDetails(tempService)"
          v-on:delete="deleteService(tempService)"
          v-on:edit="startServiceEdit(tempService)"
        />
      </Dialog>

      <!-- ADDING SERVICE DIALOG -->
      <Dialog
        v-model:visible="addingService"
        :dismissableMask="true"
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        :style="{ width: '50vw' }"
        @hide="resetServiceCreate()"
      >
        <template #header>
          <h3>{{ $t("menu.newpackage") }}</h3>
        </template>

        <div class="p-fluid formgrid grid text-left">
          <div class="field col-12">
            <label for="active">{{ $t("services.active") }}&nbsp;</label>
            <div class="flex flex-wrap align-items-center">
              <InputSwitch id="active" v-model="tempService.ServActive" />&nbsp;
              <small v-if="tempService.ServActive"
                ><em>{{ $t("services.visible") }}</em></small
              >
              <small v-else
                ><em>{{ $t("services.notvisible") }}</em></small
              >
            </div>
          </div>
          <div
            class="field col-12"
            v-if="currentBusiness.BusStoreEnabled == true"
          >
            <label for="active">{{ $t("store.serviceforstore") }}</label
            ><br />
            <div class="flex flex-wrap align-items-center">
              <InputSwitch
                id="active"
                v-model="tempService.ServiceForStore"
              />&nbsp;
              <small v-if="tempService.ServiceForStore"
                ><em>{{ $t("store.serviceforstore") }}</em></small
              >
              <small v-else
                ><em>{{ $t("store.notserviceforstore") }}</em></small
              >
            </div>
          </div>
          <div class="field col-12">
            <label for="serviceTypes"
              >{{ $t("services.service") }}
              <span class="required-star">*</span></label
            ><br />
            <MultiSelect
              id="servtypes"
              v-model="tempService.StringServiceTypes"
              :options="serviceTypes"
              optionLabel="ServTypeTitle"
              optionValue="ServTypeTitle"
              :placeholder="$t('services.selectall')"
            >
              <template #value="slotProps">
                <template v-for="option of slotProps.value" :key="option">
                  <span>
                    <Chip class="mr-1"> {{ $t($getServiceType(option)) }}</Chip>
                  </span>
                </template>
              </template>
              <template #option="slotProps">
                <span>{{
                  $t($getServiceType(slotProps.option.ServTypeTitle))
                }}</span>
              </template>
            </MultiSelect>
          </div>
          <div class="field col-12">
            <label for="title"
              >{{ $t("services.title") }}
              <span class="required-star">*</span></label
            >
            <InputText id="title" type="text" v-model="tempService.ServTitle" />
          </div>
          <div class="field col-12">
            <label for="label"
              >{{ $t("services.description") }}
              <span class="required-star">*</span></label
            >
            <InputText
              id="shortDesc"
              type="text"
              v-model="tempService.ServShortDesc"
            />
          </div>
          <div class="field col-12">
            <label for="description"
              >{{ $t("services.full") }}
              <span class="required-star">*</span></label
            ><span class="required-star" v-if="longdescreq"
              ><em>&nbsp;Required</em></span
            >
            <Editor
              id="longdesc"
              v-model="tempService.ServHtmldesc"
              editorStyle="height: 200px"
            >
              <template #toolbar>
                <span class="ql-formats">
                  <select class="ql-font">
                    <option selected=""></option>
                    <option value="serif"></option>
                    <option value="monospace"></option>
                  </select>
                  <select class="ql-size">
                    <option value="small"></option>
                    <!-- Note a missing, thus falsy value, is used to reset to default -->
                    <option selected></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>
                <span class="ql-formats">
                  <select class="ql-color">
                    <option selected="selected"></option>
                    <option value="#e60000"></option>
                    <option value="#ff9900"></option>
                    <option value="#ffff00"></option>
                    <option value="#008a00"></option>
                    <option value="#0066cc"></option>
                    <option value="#9933ff"></option>
                    <option value="#ffffff"></option>
                    <option value="#facccc"></option>
                    <option value="#ffebcc"></option>
                    <option value="#ffffcc"></option>
                    <option value="#cce8cc"></option>
                    <option value="#cce0f5"></option>
                    <option value="#ebd6ff"></option>
                    <option value="#bbbbbb"></option>
                    <option value="#f06666"></option>
                    <option value="#ffc266"></option>
                    <option value="#ffff66"></option>
                    <option value="#66b966"></option>
                    <option value="#66a3e0"></option>
                    <option value="#c285ff"></option>
                    <option value="#888888"></option>
                    <option value="#a10000"></option>
                    <option value="#b26b00"></option>
                    <option value="#b2b200"></option>
                    <option value="#006100"></option>
                    <option value="#0047b2"></option>
                    <option value="#6b24b2"></option>
                    <option value="#444444"></option>
                    <option value="#5c0000"></option>
                    <option value="#663d00"></option>
                    <option value="#666600"></option>
                    <option value="#003700"></option>
                    <option value="#002966"></option>
                    <option value="#3d1466"></option>
                  </select>
                  <select class="ql-background">
                    <option value="#000000"></option>
                    <option value="#e60000"></option>
                    <option value="#ff9900"></option>
                    <option value="#ffff00"></option>
                    <option value="#008a00"></option>
                    <option value="#0066cc"></option>
                    <option value="#9933ff"></option>
                    <option selected="selected"></option>
                    <option value="#facccc"></option>
                    <option value="#ffebcc"></option>
                    <option value="#ffffcc"></option>
                    <option value="#cce8cc"></option>
                    <option value="#cce0f5"></option>
                    <option value="#ebd6ff"></option>
                    <option value="#bbbbbb"></option>
                    <option value="#f06666"></option>
                    <option value="#ffc266"></option>
                    <option value="#ffff66"></option>
                    <option value="#66b966"></option>
                    <option value="#66a3e0"></option>
                    <option value="#c285ff"></option>
                    <option value="#888888"></option>
                    <option value="#a10000"></option>
                    <option value="#b26b00"></option>
                    <option value="#b2b200"></option>
                    <option value="#006100"></option>
                    <option value="#0047b2"></option>
                    <option value="#6b24b2"></option>
                    <option value="#444444"></option>
                    <option value="#5c0000"></option>
                    <option value="#663d00"></option>
                    <option value="#666600"></option>
                    <option value="#003700"></option>
                    <option value="#002966"></option>
                    <option value="#3d1466"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <select class="ql-align">
                    <option selected=""></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-link" type="button"></button
                ></span>
              </template>
            </Editor>
          </div>

          <div class="field col-8">
            <label for="price"
              >{{ $t("services.price") }}
              <span class="required-star">*</span></label
            >
            <InputText
              id="price"
              type="number"
              v-model.number="tempService.ServPrice"
              mode="currency"
              currency="USD"
            />
          </div>
          <div class="field col-4">
            <label for="priceCalculated">{{ $t("services.quantity") }}:</label
            ><br />
            <InputSwitch
              id="priceCalculated"
              v-model="tempService.ServPriceCalculated"
            />
          </div>
          <div v-if="showPromotionDate == true" class="field col-8">
            <label for="promotionDate">{{ $t("services.date") }}</label
            ><br />
            <Calendar
              id="promotionDate"
              v-model="tempService.displayPromotionExpires"
              :showIcon="true"
            />
          </div>
          <div class="field col-4">
            <label for="promotion">{{ $t("services.promotion") }}</label
            ><br />
            <InputSwitch id="promotion" v-model="showPromotionDate" />
          </div>
          <div class="field col-12">
            <!-- <label>{{ $t("services.images") }}</label>
          </div>
          <div v-if="hideImageUpload" class="col-12 text-center">
            <img
              v-if="tempService.ServImage != null && cropimage == null"
              :src="imageHostingURL + tempService.ServImage"
              :alt="tempService.ServTitle"
              width="100"
            />
            <img
              v-if="cropimage != null"
              :src="cropimage"
              :alt="tempService.ServTitle"
              width="100"
            />
            <span class="p-buttonset">
              <Button
                class="p-button-info p-button-sm"
                :label="$t('profile.replacepicture')"
                @click.stop="replaceImage()"
                icon="pi pi-image"
              />
              <Button
                v-if="cropimage != null"
                class="p-button-info p-button-sm"
                :label="$t('profile.adjust')"
                @click.stop="showCropper = true"
                icon="pi pi-window-minimize"
              />
            </span> -->
          </div>
          <div class="field col-12">
            <!--   <FileUpload
              v-if="!hideImageUpload"
              name="image"
              :maxFileSize="9000000"
              :fileLimit="1"
              accept="image/*"
              :multiple="false"
              :chooseLabel="$t('profile.choose')"
              :auto="true"
              :customUpload="true"
              @uploader="uploadImage"
            >
              <template #empty>
                <p>{{ $t("details.dragdrop") }}</p>
              </template>
            </FileUpload> -->
            <Galleria
              v-if="tempService.ServiceImages != null"
              :value="tempService.ServiceImages"
              :fullScreen="isFullScreen"
              :circular="true"
              :autoPlay="true"
              :transitionInterval="3000"
              :responsiveOptions="responsiveOptions"
              :numVisible="5"
              containerStyle="max-width: 100%;"
              :showItemNavigators="true"
              :showThumbnails="false"
              :showIndicators="true"
            >
              <template #item="slotProps">
                <img
                  v-if="slotProps.item"
                  :src="imageHostingURL + slotProps.item.BusImage"
                  :alt="slotProps.item.BusImageTitle"
                  style="width: 100%; display: block"
                  class="shadow-8"
                />
              </template>
              <template #thumbnail="slotProps">
                <img
                  v-if="slotProps.item"
                  :src="imageHostingURL + slotProps.item.BusImage"
                  :alt="slotProps.item.BusImageTitle"
                  style="width: 50px; heigth: 50px; margin: 5px"
                />
              </template>
            </Galleria>
          </div>
          <div class="field col-12">
            <label>{{ $t("details.gallery") }}</label
            >&nbsp;
            <em>{{ galleryCountLeft }} {{ $t("details.images") }} 5</em>
            <FileUpload
              v-if="galleryCountLeft > 0"
              name="gallery[]"
              :maxFileSize="9000000"
              accept="image/*"
              mode="basic"
              :multiple="true"
              :customUpload="true"
              :chooseLabel="$t('profile.choose')"
              :showUploadButton="true"
              @uploader="uploadGallery"
              :auto="true"
            >
              <!-- <template #empty>
                <p>{{ $t("details.dragdropgallery") }}</p>
              </template> -->
            </FileUpload>
            <Message
              v-if="showGalleryErrorMessage == true"
              severity="error"
              class="mt-2"
              >{{ galleryErrorMessage }}</Message
            >
            <div class="flex flex-wrap pt-3">
              <span v-for="img in tempService.NewServiceImages" :key="img.name">
                <Chip
                  class="mr-2 mb-2 modifyChip imageChip"
                  style="font-size: small"
                  :image="img.objectURL"
                  removable
                  v-on:remove="removeNewImage(img.name)"
                />
              </span>
              <br />
              <span v-for="img in tempService.ServiceImages" :key="img.name">
                <Chip
                  class="mr-2 mb-2 modifyChip imageChip"
                  style="font-size: small"
                  :image="imageHostingURL + img.BusImage"
                  removable
                  v-on:remove="removeGalleryImage(img.ServImageId)"
                />
              </span>
            </div>
          </div>
          <Dialog
            v-model:visible="showCropper"
            align="center"
            :contentStyle="{ overflow: 'scroll' }"
            style="text-align: left"
            :header="$t('businessprofile.cropper')"
            :dismissableMask="true"
            :style="{ width: '50vw' }"
            :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
          >
            <cropper
              class="cropper"
              :src="tempService.NewImage[0].objectURL"
              :stencil-props="{
                aspectRatio: 1 / 1,
              }"
              @change="change"
            />
            <Button
              :label="$t('menu.done')"
              icon="pi pi-check"
              iconPos="right"
              @click.stop="showCropper = false"
              class="mr-2 mt-2 p-button-success p-button-sm p-button-raised"
            />
          </Dialog>
        </div>

        <template #footer>
          <div class="flex justify-content-around mt-3">
            <Button
              :label="$t('profile.cancel')"
              icon="pi pi-times"
              class="p-button-raised p-button-secondary"
              @click.stop="resetServiceCreate()"
            />
            <Button
              v-if="!saving"
              :label="$t('profile.save')"
              icon="pi pi-check"
              @click.stop="validateServiceCreate()"
            />
            <Button
              v-else
              :label="$t('profile.saving')"
              icon="pi pi-spin pi-spinner"
              disabled
            />
          </div>
          <div class="col-12 text-left">
            <Message
              v-if="errors.length > 0"
              severity="error"
              :closable="false"
              >{{ $t("message.review") }}</Message
            >
          </div>
        </template>
      </Dialog>

      <!-- UPDATING SERVICE DIALOG -->
      <Dialog
        v-model:visible="editingService"
        :dismissableMask="true"
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        :style="{ width: '50vw' }"
        @hide="resetServiceEdit()"
      >
        <template #header>
          <h3>{{ $t("services.updating") }}</h3>
        </template>

        <div class="p-fluid formgrid grid text-left">
          <div class="field col-12">
            <label for="active">{{ $t("services.active") }}</label
            ><br />
            <div class="flex flex-wrap align-items-center">
              <InputSwitch id="active" v-model="tempService.ServActive" />&nbsp;
              <small v-if="tempService.ServActive"
                ><em>{{ $t("services.visible") }}</em></small
              >
              <small v-else
                ><em>{{ $t("services.notvisible") }}</em></small
              >
            </div>
          </div>
          <div
            class="field col-12"
            v-if="currentBusiness.BusStoreEnabled == true"
          >
            <label for="active">{{ $t("store.serviceforstore") }}</label
            ><br />
            <div class="flex flex-wrap align-items-center">
              <InputSwitch
                id="active"
                v-model="tempService.ServiceForStore"
              />&nbsp;
              <small v-if="tempService.ServiceForStore"
                ><em>{{ $t("store.serviceforstore") }}</em></small
              >
              <small v-else
                ><em>{{ $t("store.notserviceforstore") }}</em></small
              >
            </div>
          </div>
          <div class="field col-12">
            <label for="serviceTypes">{{ $t("services.service") }}</label
            ><br />
            <MultiSelect
              id="servtypes"
              v-model="tempService.StringServiceTypes"
              :options="serviceTypes"
              optionLabel="ServTypeTitle"
              optionValue="ServTypeTitle"
              :placeholder="$t('services.selectall')"
            >
              <template #value="slotProps">
                <template v-for="option of slotProps.value" :key="option">
                  <span>
                    <Chip class="mr-1"> {{ $t($getServiceType(option)) }}</Chip>
                  </span>
                </template>
              </template>
              <template #option="slotProps">
                <span>{{
                  $t($getServiceType(slotProps.option.ServTypeTitle))
                }}</span>
              </template>
            </MultiSelect>
          </div>
          <div class="field col-12">
            <label for="title"
              >{{ $t("services.title") }}
              <span class="required-star">*</span></label
            >
            <InputText id="title" type="text" v-model="tempService.ServTitle" />
          </div>
          <div class="field col-12">
            <label for="label"
              >{{ $t("services.short") }}
              <span class="required-star">*</span></label
            >
            <InputText
              id="shortDesc"
              type="text"
              v-model="tempService.ServShortDesc"
            />
          </div>
          <div class="field col-12">
            <label for="description"
              >{{ $t("services.full") }}
              <span class="required-star">*</span></label
            >
            <span class="required-star" v-if="longdescreq"
              ><em>&nbsp;Required</em></span
            >
            <Editor
              id="longdesc"
              v-model="tempService.ServHtmldesc"
              editorStyle="height: 200px"
            >
              <template #toolbar>
                <span class="ql-formats">
                  <select class="ql-font">
                    <option selected=""></option>
                    <option value="serif"></option>
                    <option value="monospace"></option>
                  </select>
                  <select class="ql-size">
                    <option value="small"></option>
                    <!-- Note a missing, thus falsy value, is used to reset to default -->

                    <option selected></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>
                <span class="ql-formats">
                  <select class="ql-color">
                    <option selected="selected"></option>
                    <option value="#e60000"></option>
                    <option value="#ff9900"></option>
                    <option value="#ffff00"></option>
                    <option value="#008a00"></option>
                    <option value="#0066cc"></option>
                    <option value="#9933ff"></option>
                    <option value="#ffffff"></option>
                    <option value="#facccc"></option>
                    <option value="#ffebcc"></option>
                    <option value="#ffffcc"></option>
                    <option value="#cce8cc"></option>
                    <option value="#cce0f5"></option>
                    <option value="#ebd6ff"></option>
                    <option value="#bbbbbb"></option>
                    <option value="#f06666"></option>
                    <option value="#ffc266"></option>
                    <option value="#ffff66"></option>
                    <option value="#66b966"></option>
                    <option value="#66a3e0"></option>
                    <option value="#c285ff"></option>
                    <option value="#888888"></option>
                    <option value="#a10000"></option>
                    <option value="#b26b00"></option>
                    <option value="#b2b200"></option>
                    <option value="#006100"></option>
                    <option value="#0047b2"></option>
                    <option value="#6b24b2"></option>
                    <option value="#444444"></option>
                    <option value="#5c0000"></option>
                    <option value="#663d00"></option>
                    <option value="#666600"></option>
                    <option value="#003700"></option>
                    <option value="#002966"></option>
                    <option value="#3d1466"></option>
                  </select>
                  <select class="ql-background">
                    <option value="#000000"></option>
                    <option value="#e60000"></option>
                    <option value="#ff9900"></option>
                    <option value="#ffff00"></option>
                    <option value="#008a00"></option>
                    <option value="#0066cc"></option>
                    <option value="#9933ff"></option>
                    <option selected="selected"></option>
                    <option value="#facccc"></option>
                    <option value="#ffebcc"></option>
                    <option value="#ffffcc"></option>
                    <option value="#cce8cc"></option>
                    <option value="#cce0f5"></option>
                    <option value="#ebd6ff"></option>
                    <option value="#bbbbbb"></option>
                    <option value="#f06666"></option>
                    <option value="#ffc266"></option>
                    <option value="#ffff66"></option>
                    <option value="#66b966"></option>
                    <option value="#66a3e0"></option>
                    <option value="#c285ff"></option>
                    <option value="#888888"></option>
                    <option value="#a10000"></option>
                    <option value="#b26b00"></option>
                    <option value="#b2b200"></option>
                    <option value="#006100"></option>
                    <option value="#0047b2"></option>
                    <option value="#6b24b2"></option>
                    <option value="#444444"></option>
                    <option value="#5c0000"></option>
                    <option value="#663d00"></option>
                    <option value="#666600"></option>
                    <option value="#003700"></option>
                    <option value="#002966"></option>
                    <option value="#3d1466"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <select class="ql-align">
                    <option selected=""></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-link" type="button"></button
                ></span>
              </template>
            </Editor>
          </div>
          <div class="field col-8">
            <label for="price"
              >{{ $t("services.price") }}
              <span class="required-star">*</span></label
            >
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">$</span>

              <InputText
                id="price"
                type="number"
                v-model.number="tempService.ServPrice"
                mode="currency"
                currency="USD"
              />
            </div>
          </div>
          <div class="field col-4">
            <label for="priceCalculated">{{ $t("services.quantity") }}</label
            ><br />
            <InputSwitch
              id="priceCalculated"
              v-model="tempService.ServPriceCalculated"
            />
          </div>
          <div v-if="showPromotionDate == true" class="field col-8">
            <label for="promotionDate">{{ $t("services.date") }}</label
            ><br />
            <Calendar
              id="promotionDate"
              v-model="tempService.displayPromotionExpires"
              :showIcon="true"
            />
          </div>
          <div class="field col-4">
            <label for="promotion">{{ $t("services.promotion") }}</label
            ><br />
            <InputSwitch id="promotion" v-model="showPromotionDate" />
          </div>

          <div class="field col-12">
            <label>{{ $t("services.images") }}</label>
          </div>
          <Galleria
            v-if="tempService.ServiceImages != null"
            :value="tempService.ServiceImages"
            :fullScreen="isFullScreen"
            :circular="true"
            :autoPlay="true"
            :transitionInterval="3000"
            :responsiveOptions="responsiveOptions"
            :numVisible="5"
            containerStyle="max-width: 100%;"
            :showItemNavigators="true"
            :showThumbnails="false"
            :showIndicators="true"
          >
            <template #item="slotProps">
              <img
                v-if="slotProps.item"
                :src="imageHostingURL + slotProps.item.BusImage"
                :alt="slotProps.item.BusImageTitle"
                style="width: 100%; display: block"
                class="shadow-8"
              />
            </template>
            <template #thumbnail="slotProps">
              <img
                v-if="slotProps.item"
                :src="imageHostingURL + slotProps.item.BusImage"
                :alt="slotProps.item.BusImageTitle"
                style="width: 50px; heigth: 50px; margin: 5px"
              />
            </template>
          </Galleria>
          <!-- <div id="image" v-if="hideImageUpload" class="col-12 text-center">
            <img
              v-if="tempService.ServImage != null && cropimage == null"
              :src="imageHostingURL + tempService.ServImage"
              :alt="tempService.ServTitle"
              width="100"
            />
            <img
              v-if="cropimage != null"
              :src="cropimage"
              :alt="tempService.ServTitle"
              width="100"
            />
            <span class="p-buttonset">
              <Button
                class="p-button-info p-button-sm"
                :label="$t('profile.replacepicture')"
                @click.stop="replaceImage()"
                icon="pi pi-image"
              />
              <Button
                v-if="cropimage != null"
                class="p-button-info p-button-sm"
                :label="$t('profile.adjust')"
                @click.stop="showCropper = true"
                icon="pi pi-window-minimize"
              />
            </span>
          </div> -->
          <!-- <div class="field col-12">
            <FileUpload
              v-if="!hideImageUpload"
              name="image"
              :maxFileSize="9000000"
              :fileLimit="1"
              accept="image/*"
              :multiple="false"
              :auto="true"
              :chooseLabel="$t('profile.choose')"
              :customUpload="true"
              @uploader="uploadImage"
            >
              <template #empty>
                <p>{{ $t("details.dragdrop") }}.</p>
              </template>
            </FileUpload> 
          </div>-->
          <div class="field col-12">
            <label>{{ $t("details.gallery") }}</label
            >&nbsp;
            <em>{{ galleryCountLeft }} {{ $t("details.images") }} 5</em>
            <FileUpload
              v-if="galleryCountLeft > 0"
              name="gallery[]"
              :maxFileSize="9000000"
              accept="image/*"
              mode="basic"
              :multiple="true"
              :customUpload="true"
              :chooseLabel="$t('profile.choose')"
              :showUploadButton="true"
              @uploader="uploadGallery"
              :auto="true"
            >
              <!-- <template #empty>
                <p>{{ $t("details.dragdropgallery") }}</p>
              </template> -->
            </FileUpload>
            <Message
              v-if="showGalleryErrorMessage == true"
              severity="error"
              class="mt-2"
              >{{ galleryErrorMessage }}</Message
            >
            <div class="flex flex-wrap pt-3">
              <span v-for="img in tempService.NewServiceImages" :key="img.name">
                <Chip
                  class="mr-2 mb-2 modifyChip imageChip"
                  style="font-size: small"
                  :image="img.objectURL"
                  removable
                  v-on:remove="removeNewImage(img.name)"
                />
              </span>
              <br />
              <span v-for="img in tempService.ServiceImages" :key="img.name">
                <Chip
                  class="mr-2 mb-2 modifyChip imageChip"
                  style="font-size: small"
                  :image="imageHostingURL + img.BusImage"
                  removable
                  v-on:remove="removeGalleryImage(img.ServImageId)"
                />
              </span>
            </div>
          </div>
          <Dialog
            v-model:visible="showCropper"
            align="center"
            :contentStyle="{ overflow: 'scroll' }"
            style="text-align: left"
            :header="$t('businessprofile.cropper')"
            :dismissableMask="true"
            :style="{ width: '50vw' }"
            :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
          >
            <cropper
              class="cropper"
              :src="tempService.NewImage[0].objectURL"
              :stencil-props="{
                aspectRatio: 1 / 1,
              }"
              @change="change"
            />
            <Button
              :label="$t('menu.done')"
              icon="pi pi-check"
              iconPos="right"
              @click.stop="showCropper = false"
              class="mr-2 mt-2 p-button-success p-button-sm p-button-raised"
            />
          </Dialog>

          <div class="col-12">
            <Message
              v-for="error of errors"
              severity="error"
              :key="error"
              :sticky="true"
              >{{ error }}</Message
            >
          </div>
        </div>

        <template #footer>
          <div class="flex justify-content-around mt-3">
            <Button
              :label="$t('profile.cancel')"
              icon="pi pi-times"
              class="p-button-raised p-button-secondary"
              @click.stop="resetServiceEdit"
            />
            <Button
              v-if="!saving"
              :label="$t('profile.save')"
              icon="pi pi-check"
              class="p-button-raised p-button-primary"
              @click.stop="validateServiceEdit"
            />
            <Button
              v-else
              :label="$t('profile.saving')"
              icon="pi pi-spin pi-spinner"
              disabled
            />
          </div>
        </template>
      </Dialog>
    </div>
    <div v-else-if="currentBusiness.BusActive == false">
      <inactive-subscription
        :busId="currentBusiness.BusId"
      ></inactive-subscription>
    </div>
    <div v-else-if="currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode == 'FREE'">
     <!-- <div v-else-if="true"> -->
      <upgrade-subscription
        :subId="currentSubscription.SubId"
      ></upgrade-subscription>
    </div>
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{ $t("message.error") }}</Message
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ServiceGridLayout from "../components/ServiceGridLayout.vue";
import Service from "../store/Models/Service";
import Business from "../store/Models/Business";
import ServiceTypeDef from "../store/Models/ServiceTypeDef";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import { Cropper } from "vue-advanced-cropper";
import InactiveSubscription from "../components/InactiveSubscription.vue";
import { isNull } from "lodash";
import UpgradeSubscription from "../components/UpgradeSubscription.vue";
import Subscription from '../store/Models/Subscription';

export default {
  name: "BuesinesServices",
  components: {
    ServiceGridLayout,
    LoadingOverlay,
    InactiveSubscription,
    Cropper,
    UpgradeSubscription
  },
  data() {
    return {
      loading: false,
      currentBusiness: {},
      hideImageUpload: true,
      services: [],
      filteredServices: [],
      serviceTypes: [],
      errors: [],
      showPromotionDate: false,
      selectedServiceTypes: [],
      viewingService: false,
      editingService: false,
      addingService: false,
      currentSubscription:{},
      deletingService: false,
      saving: false,
      isFullScreen: false,
      galleryErrorMessage: "",
      showGalleryErrorMessage: false,
      tempService: {
        ServId: null,
        BusId: null,
        ServTitle: null,
        ServShortDesc: null,
        ServHtmldesc: null,
        ServPrice: null,
        ServPriceCalculated: false,
        ServImage: null,
        ServActive: false,
        ServDeleted: false,
        ServPromotionExpires: null,
        ServiceTypes: [],
        StringServiceTypes: [],
        displayPromotionExpires: null,
        NewServiceImages: [],
        ServiceImages: [],
        ServiceReservExtra: false,
        ServiceForStore: false,
      },
      layout: "grid",
      longdescreq: false,
      sortKey: null,
      sortOrder: null,
      sortField: null,
      showCropper: false,
      galleryCountLeft: 5,
      cropimage: null,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      sortOptions: [
        { label: "Price High to Low", value: "!ServPrice" },
        { label: "Price Low to High", value: "ServPrice" },
      ],
      filterOptions: [
        { name: "All", value: "all" },
        { name: "Active", value: "active" },
        { name: "Inactive", value: "inactive" },
      ],
      selectedFilter: "all",
      selectedServiceType: null,

      options: ["Visible", "Hidden"],
      checked: true,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 4,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
    };
  },
  async mounted() {
    this.$log.info("business-services-mounted");
    this.serviceTypes = await ServiceTypeDef.fetchAll();
    this.$log.debug(this.serviceTypes);
    this.setSelectedBusiness();

    // this.eventTypes = await EventTypeDef.fetchAll();
    // this.attendeeCounts = await AttendeeCountDef.fetchAll();
    // this.serviceTypes = await ServiceTypeDef.fetchAll();
    // this.$log.debug(this.eventTypes);
    // this.$log.debug(this.attendeeCounts);
  },
  methods: {
    change({ coordinates, canvas }) {
      this.$log.debug(coordinates, canvas);
      this.coordinates = coordinates;
      this.cropimage = canvas.toDataURL();
    },
    /* Utility function to convert a canvas to a BLOB */
    dataURLToBlob(dataURL) {
      var BASE64_MARKER = ";base64,";
      var parts = "";
      var contentType = "";
      var raw = "";
      if (dataURL != null && dataURL.indexOf(BASE64_MARKER) == -1) {
        parts = dataURL.split(",");
        contentType = parts[0].split(":")[1];
        raw = parts[1];

        return new Blob([raw], { type: contentType });
      }

      parts = dataURL.split(BASE64_MARKER);
      contentType = parts[0].split(":")[1];
      raw = window.atob(parts[1]);
      var rawLength = raw.length;

      var uInt8Array = new Uint8Array(rawLength);

      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },
    uploadGallery(event) {
      this.$log.debug("services uploadGallery event");
      this.$log.debug(event);
      this.galleryCountLeft = this.galleryCountLeft - event.files.length;
      event.files.forEach((file) => {
        this.tempService.NewServiceImages.push(file);
      });

      this.$log.debug(event.files.length);
      this.$log.debug(this.galleryCountLeft);
      this.$log.debug(this.tempService.NewServiceImages);
      if (event.files.length > this.galleryCountLeft) {
        this.galleryErrorMessage =
          "You have exceeded the number of allowed images. Only the first " +
          this.galleryCountLeft +
          " images will be uploaded.";
        this.showGalleryErrorMessage = true;
      } else {
        this.showGalleryErrorMessage = false;
      }
    },
    /* End Utility function to convert a canvas to a BLOB      */
    async removeNewImage(newImgName) {
      this.galleryCountLeft += 1;
      this.$log.debug("remove image" + newImgName);
      var imgToRemove = this.tempService.NewServiceImages.find(
        (img) => img.name == newImgName
      );
      this.tempService.NewServiceImages.splice(
        this.tempService.NewServiceImages.indexOf(imgToRemove),
        1
      );
    },
    async removeGalleryImage(serviceImgId) {
      this.galleryCountLeft += 1;
      this.$log.debug("remove image" + serviceImgId);
      await Service.deleteServiceImage(serviceImgId).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          this.tempService = await this.services.find(this.tempService.ServId);
          this.$log.debug(this.tempService);
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.image"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.imagefailed"),
            life: 3000,
          });
        }
      });
    },
    replaceImage() {
      this.hideImageUpload = false;
    },
    uploadImage(event) {
      this.$log.debug("uploadImage event");
      this.$log.debug(event);
      if (event.files != null) {
        this.tempService.NewImage = event.files;
        this.hideImageUpload = true;
        this.showCropper = true;

        this.$log.debug(this.tempService);
      }
    },
    startServiceEdit: function (data) {
      this.$log.debug("at edit service");
      this.setTempService(data);
      this.$log.debug("promotion " + this.tempService.ServPromotionExpires);
      if (this.tempService.ServPromotionExpires != null) {
        this.showPromotionDate = true;
        this.tempService.displayPromotionExpires = new Date(
          this.$formatDateTimeToDate(this.tempService.ServPromotionExpires)
        );
      } else {
        this.showPromotionDate = false;
      }
      this.$log.debug(this.tempService);
      if (this.tempService.ServImage == null) {
        this.hideImageUpload = false;
      }
      this.galleryCountLeft =
        this.galleryCountLeft - this.tempService.ServiceImages.length;
      this.editingService = true;
    },
    validateServiceEdit() {
      this.$log.info("validate edit service");

      //clear out errors
      this.errors = [];
      this.saving = true;

      //apply cropped image if uploaded
      if (this.cropimage != null) {
        this.tempService.NewImage[0] = this.dataURLToBlob(this.cropimage);
      }

      //verify required values
      if (this.tempService.ServTitle.length == 0) {
        this.errors.push(this.$t("code.servicetitle"));
        document.getElementById("title").classList.add("p-invalid");
      }
      if (this.tempService.ServShortDesc.length == 0) {
        this.errors.push(this.$t("code.shortdesc"));
        document.getElementById("shortDesc").classList.add("p-invalid");
      }
      if (
        this.tempService.ServPrice === null ||
        this.tempService.ServPrice === ""
      ) {
        this.errors.push("Price required");
        document.getElementById("price").classList.add("p-invalid");
      }
      //TODO image validation

      this.$log.debug("Show promotion", this.showPromotionDate);
      //check if promotion needs to be cleared
      if (this.showPromotionDate == false) {
        this.tempService.ServPromotionExpires = null;
      } else {
        this.tempService.ServPromotionExpires =
          this.tempService.displayPromotionExpires;
        this.$log.debug(this.tempService.ServPromotionExpires);
      }

      //reset service types to newly selected
      this.tempService.ServiceTypes = [];
      this.$log.debug(this.tempService.StringServiceTypes);
      this.tempService.StringServiceTypes.forEach((type) => {
        this.tempService.ServiceTypes.push({
          ServTypeId: 0,
          ServId: this.tempService.ServId,
          ServTypeTitle: type,
        });
      });
      this.$log.debug(this.tempService);

      if (this.errors.length == 0) {
        this.editService();
      } else {
        this.saving = false;
      }
    },
    async editService() {
      this.$log.info("editing service");
      this.$log.debug("service types before update");
      this.$log.debug(this.tempService);

      await Service.updateService(this.tempService).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          // await Service.removeRecursive(this.tempService.ServId).then(async()=>{
          await Service.fetchBusinessServices(this.currentBusinessId).then(
            (response) => {
             //console.log(response);
              this.services = response;
              this.checkFilter();
              this.saving = false;
            }
          );

          // });
          this.$log.debug(this.services);
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.serviceupdated"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.serviceupdate"),
            life: 3000,
          });
        }
      });
      this.resetServiceEdit();
    },
    resetServiceEdit() {
      this.$log.debug("reset edit service");
      this.longdescreq = false;
      this.saving = false;
      this.cropimage = null;
      // if edit started from viewing service, keep tempservice with data
      //otherwise, reset
      if (this.viewingService == false) {
        this.tempService = {
          ServId: null,
          ServTitle: null,
          ServShortDesc: null,
          ServHtmldesc: null,
          ServPrice: null,
          ServImage: null,
          ServActive: false,
          ServDeleted: false,
          ServPromotionExpires: null,
          ServPriceCalculated: false,
          ServiceTypes: [],
          StringServiceTypes: [],
          NewImage: [],
          displayPromotionExpires: null,
          NewServiceImages: [],
        };
        this.hideImageUpload = true;
        this.showPromotionDate = false;
      }
      this.errors = [];
      this.editingService = false;
    },
    async deleteService(data) {
      this.deletingService = true;
      this.$toast.add({
        severity: "info",
        summary: this.$t("code.deletingservice"),
        life: 2000,
      });
      this.$log.debug("delete service");

      this.tempService.ServDeleted = true;

      await Service.deleteService(data.ServId).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          Service.delete(data.ServId);
          await Service.fetchBusinessServices(this.currentBusinessId).then(
            (response) => {
              this.services = response;
              this.checkFilter();
              this.deletingService = false;
            }
          );
          this.$log.debug(this.services);
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.delete"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.service"),
            life: 3000,
          });
        }
      });
    },

    async startServiceDelete(data) {
      this.setTempService(data);
      this.$confirm.require({
        message: this.$t("code.deleteservice"),
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          //callback to execute when user confirms the action
          this.deleteService(data.ServId);
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    startServiceCreate() {
      this.$log.info("at start service create");
      this.tempService.ServId = 0;
      this.tempService.BusId = this.currentBusinessId;
      this.tempService.ServDeleted = false;
      this.hideImageUpload = false;
      this.addingService = true;
    },
    validateServiceCreate() {
      try {
        this.saving = true;
        this.longdescreq = false;
        this.$log.info("at validate service create");
        //clear out errors
        this.errors = [];
        this.$log.debug(this.tempService);

        //apply cropped image if uploaded
        if (this.cropimage != null) {
          this.tempService.NewImage[0] = this.dataURLToBlob(this.cropimage);
        }

        //verify required values
        if (
          this.tempService.ServTitle == null ||
          this.tempService.ServTitle.length == 0
        ) {
          this.errors.push(this.$t("code.servicetitle"));
          document.getElementById("title").classList.add("p-invalid");
        }
        if (
          this.tempService.ServShortDesc == null ||
          this.tempService.ServShortDesc.length == 0
        ) {
          this.errors.push(this.$t("code.shortdesc"));
          document.getElementById("shortDesc").classList.add("p-invalid");
        }
        if (
          this.tempService.ServPrice === null ||
          this.tempService.ServPrice === ""
        ) {
         //console.log(this.tempService.ServPrice);
          this.errors.push("Price required");
          document.getElementById("price").classList.add("p-invalid");
        }

        if (
          this.tempService.StringServiceTypes == null ||
          this.tempService.StringServiceTypes.length == 0
        ) {
          this.errors.push("service types");
          document.getElementById("servtypes").classList.add("p-invalid");
        }

        if (
          this.tempService.ServHtmldesc == null ||
          this.tempService.ServHtmldesc.length == 0
        ) {
          this.errors.push("long desc");
          this.longdescreq = true;
        }

        //
        //TODO image validation
        if (this.tempService.ServImage == isNull) {
          this.errors.push(this.$t("code.imagerequired"));
          document.getElementById("image").classList.add("p-invalid");
        }
        this.tempService.ServiceTypes = [];
        this.$log.debug(this.tempService.StringServiceTypes);
        this.tempService.StringServiceTypes.forEach((type) => {
          this.tempService.ServiceTypes.push({
            ServTypeId: 0,
            ServId: this.tempService.ServId,
            ServTypeTitle: type,
          });
        });

        if (this.errors.length == 0) {
          this.tempService.ServPromotionExpires =
            this.tempService.displayPromotionExpires;

          this.createService();
        } else {
          this.$log.debug("errors");
          this.saving = false;
        }
      } catch (error) {
        this.$log.debug(error);
        this.saving = false;
      }
    },
    async createService() {
      this.$log.info("at service create");
      this.$log.debug(this.tempService);

      await Service.createService(this.tempService).then(async (response) => {
        this.$log.debug(response);
        await Service.fetchBusinessServices(this.currentBusinessId).then(
          (response) => {
            this.services = response;
            this.checkFilter();
            this.saving = false;
          }
        );
        if (response == 1) {
          this.$log.debug(this.services);
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.servicecreated"),
            life: 3000,
          });
          this.resetServiceCreate();
        } else {
          this.$toast.add({
            sseverity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.servicefailed"),
            life: 3000,
          });
        }
      });

    
    },
    resetServiceCreate() {
      this.saving = false;
      this.longdescreq = false;
      this.tempService = {
        ServId: null,
        ServTitle: null,
        ServShortDesc: null,
        ServHtmldesc: null,
        ServPrice: null,
        ServImage: null,
        ServActive: false,
        ServDeleted: false,
        ServPriceCalculated: false,
        ServPromotionExpires: null,
        ServiceTypes: [],
        NewImage: [],
        displayPromotionExpires: null,
        ServiceImages: [],
        ServiceReservExtra: false,
        ServiceForStore: false,
      };
      this.selectedServiceTypes = [];
      this.showPromotionDate = false;
      this.errors = [];
      this.addingService = false;
      this.cropimage = null;
    },
    addToFavorites: function () {
      //TODO
      this.$log.debug("add favorite");
    },
    viewDetails: function (data) {
      this.setTempService(data);
      this.viewingService = true;
    },
    setTempService(data) {
     //console.log(data);
      this.tempService = {
        ServId: data.ServId,
        BusId: data.BusId,
        ServTitle: data.ServTitle,
        ServShortDesc: data.ServShortDesc,
        ServHtmldesc: data.ServHtmldesc,
        ServPrice: data.ServPrice,
        ServPriceCalculated: data.ServPriceCalculated,
        ServImage: data.ServImage,
        ServActive: data.ServActive,
        ServDeleted: data.ServDeleted,
        ServPromotionExpires: data.ServPromotionExpires,
        ServiceTypes: data.ServiceTypes,
        ServiceReservExtra: data.ServiceReservExtra,
        ServiceForStore: data.ServiceForStore,
        StringServiceTypes: [],
        ServiceImages: data.ServiceImages,
        NewImage: [],
        NewServiceImages: [],
      };

      // if(tempService.ServPromotionExpires != null){
      //  this.tempService = moment(this.tempService.ServPromotionExpires).format("MM/DD/YY");
      // }

      if (this.tempService.ServiceTypes != null) {
        let self = this;
        this.tempService.ServiceTypes.forEach((type) =>
          self.tempService.StringServiceTypes.push(type.ServTypeTitle)
        );
      }
     //console.log(this.tempService);
    },
    onSortChange(event) {
      const value = event.value.value;
      const sortValue = event.value;

      if (value.indexOf("!") === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
        this.sortKey = sortValue;
      } else {
        this.sortOrder = 1;
        this.sortField = value;
        this.sortKey = sortValue;
      }
    },
    async setSelectedBusiness() {
      this.loading = true;
      Business.fetchBusinessProfileById(this.currentBusinessId).then(
        (result) => {
          this.$log.debug(result);
          this.currentBusiness = result;
        }
      );
      Subscription.fetchCurrentByBusiness(this.currentBusinessId).then((result) => {
          this.currentSubscription = result;
         //console.log(this.currentSubscription)
        });
      await Service.fetchBusinessServices(this.currentBusinessId).then(
        (response) => {
          this.$log.debug(response);
          this.services = response;
          this.checkFilter();
        }
      );
      this.loading = false;
    },
    checkFilter() {
      if (this.selectedFilter == "all") {
        this.filteredServices = this.services;
      } else if (this.selectedFilter == "active") {
        this.filteredServices = this.services.filter((serv) => {
          if (serv.ServActive == true) return true;
        });
      } else if (this.selectedFilter == "inactive") {
        this.filteredServices = this.services.filter((serv) => {
          if (serv.ServActive == false) return true;
        });
      }
    },
  },
  watch: {
    async currentBusinessId() {
      this.setSelectedBusiness();
    },
    selectedFilter() {
      this.$log.debug("updated");
      this.checkFilter();
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
    myBusiness() {
      return true;
    },
    mode() {
      return "edit";
    },
  },
};
</script>
<style>
.p-fileupload-choose ~ .p-button {
  display: none;
}
.p-scrollpanel-bar {
  background-color: var(--primary-color);
  opacity: 1;
  transition: background-color 0.2s;
}
.product-grid-item {
  margin: 0.5rem;
  border: 1px solid #dee2e6;
  flex-direction: column;
}

.product-name {
  font-size: 1.5rem;
  font-weight: 700;
}
.product-price {
  font-size: 1.5rem;
  font-weight: 600;
}
.product-grid-item,
.product-grid-item-bottom,
.product-grid-item,
.product-grid-item-top {
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.product-grid-item-content{
 text-align: center;
}
.product-description {
  margin: 0 0 1rem 0;
}
.product-grid-item-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.product-grid-item img,
.p-skeleton {
  width: 75%;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 1rem 1rem;
}

.product-category {
  font-size: 0.8rem;
}
.product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}
.card {
  padding: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 1rem;
}
</style>