import { Model } from '@vuex-orm/core'


export default class TimeSlotType extends Model {
  static entity = 'timeSlotType'
  
  static primaryKey = 'TypeId'
  
  static fields () {
    return {
      TypeId: this.uid(),
      TypeCode: this.attr(null),
      TypeDesc: this.attr(null),
      TypeActive: this.attr(null)
    }
  }

  static async fetchAll() {
    await this.api().get('/api/timeSlotTypes/')
    return this.all();
  }
}

