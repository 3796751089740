<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <div class="mt-3" v-if="currentBusiness != null && currentBusiness.BusActive == true && currentSubscription != null && currentSubscription.SubDef != null  && currentSubscription.SubDef.SubscriptionLevelCode == 'PREMIUM' ">
      <div class="flex justify-content-end m-2">
        <Button
          :label="$t('quotes.new')"
          icon="pi pi-plus"
          @click.stop="
            () => {
              createNewQuote = true;
            }
          "
          class="mr-2 p-button-sm p-button-primary p-button-raised"
        />
      </div>
      <div v-if="currentBusiness.BusAcceptQuotes != true">
        <Message severity="warn" :closable="false"
          >{{ $t("quotes.disable") }}
          <b> {{ $t("quotes.enable") }} </b></Message
        >
      </div>
    
      <DataView :value="quotes" layout="list" class="">
        <template #empty>
          {{ $t("quotes.receive") }}
        </template>
        <template #list="slotProps">
          <div class="col-12 md:col-6 lg:col-4 ">
            <Card
              class="mb-2 mr-1 ml-1 "
              @click.stop="openQuoteDetails(slotProps.data)"
            >
              <template v-slot:content>
                <div class="grid">
                  <div class="col-10 align-self-center">
                    <div class="box">
                      <div
                        class="flex flex-wrap justify-content-between"
                        style="text-align: left"
                      >
                        <span
                          >#{{ slotProps.data.QuoteNum }}<br />
                          <span style="font-size: x-large; text-align: left"
                            ><b>
                              {{ slotProps.data.Cust.CustLastName }},
                              {{ slotProps.data.Cust.CustFirstName }}
                            </b></span
                          ><span> </span><br />
                          <em
                            >{{ $t("message.requested") }}
                            {{
                              $formatDateTimeToDate(
                                slotProps.data.QuoteRequestDate
                              )
                            }}</em
                          ><br />
                          <span v-if="slotProps.data.QuoteStatus=='Processed'"> <em
                          >{{ $t("code.validuntil") }}:
                          {{
                        $getQuoteExpirationDate( slotProps.data.QuoteRequestDate,  slotProps.data.Bus.BusQuoteExpiration)
                          }}</em
                        ><br /></span>
                          <Tag
                            class="mt-2"
                            :severity="getStatusTag(slotProps.data.QuoteStatus)"
                            style="text-align: left; font-size: x- small"
                          >
                            {{ slotProps.data.QuoteStatus }}
                          </Tag></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-2 align-self-center"
                    style="text-align: right"
                  >
                    <Button
                      icon="pi pi-angle-right"
                      class="p-button-rounded p-button-text p-button-lg"
                      @click.stop="openQuoteDetails(slotProps.data)"
                    />
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </template>
      </DataView>

      <!-- PROCESS QUOTE -->
      <Sidebar
        v-model:visible="showQuoteDetails"
        :baseZIndex="999"
        position="full"
        style="overflow: scroll; z-index: 1001"
        @hide="resetNewQuote()"
      >
        <div class="grid">
          <div class="col-12">
            <div class="text-center">
              <img
                :src="imageHostingURL + selectedQuote.Bus.BusLogo"
                :alt="selectedQuote.Bus.BusName"
                width="100"
                class="mr-3"
              />
              <div>
                <span style="font-size: xx-large">
                  {{ selectedQuote.Bus.BusName }}
                </span>
                <!-- <br>
              <span style="font-size: xx-small; line-height:.5em">{{selectedQuote.Business.BusPhone}}<br>
              {{selectedQuote.Business.BusAddress}}<br>
              {{selectedQuote.Business.BusCity}}, {{selectedQuote.Business.BusState}} {{selectedQuote.Business.BusZip}}
              </span> -->
              </div>
            </div>
            <div class="col-12 mb-2" style="background-color: #e9ecef; text-align:center">
              <span style="font-size: x-large"
                >#{{ selectedQuote.QuoteNum }}</span
              >
            </div>
            <div class="flex flex-wrap justify-content-center freeze  ">
              <Button
                :label="$t('menu.restorequote')"
                v-tooltip.right="$t('menu.restorequote')"
                icon="pi pi-undo"
                iconPos="left"
                @click.stop="restoreQuote()"
                class="m-2 p-button-primary p-button-sm p-button-raised"
                v-if="
                  selectedQuote.QuoteStatus == 'Canceled' &&
                  !processingQuote &&
                  !revisingQuote
                "
              />
              <Button
                :label="$t('menu.cancelquote')"
                v-tooltip.right="$t('menu.cancelquote')"
                icon="pi pi-trash"
                iconPos="left"
                @click.stop="cancelQuote()"
                class="m-2 p-button-danger p-button-sm p-button-raised"
                v-if="
                  selectedQuote.QuoteStatus != 'Canceled' &&
                  !processingQuote &&
                  !revisingQuote
                "
              />
              <Button
                v-if="
                  !processingQuote &&
                  !revisingQuote &&
                  selectedQuote.QuoteStatus != 'Canceled'
                "
                :label="$t('menu.processquote')"
                v-tooltip.right="$t('menu.processquote')"
                icon="pi pi-caret-right"
                iconPos="left"
                @click.stop="processQuote()"
                class="m-2 p-button-sm p-button-raised p-button-info"
              />
              <Button
                v-if="
                  !processingQuote &&
                  !revisingQuote &&
                  selectedQuote.QuoteStatus != 'Canceled'
                "
                :label="$t('menu.overwrite')"
                v-tooltip.right="$t('menu.overwrite')"
                icon="pi pi-pencil"
                iconPos="left"
                @click.stop="overwriteServices"
                class="m-2 p-button-sm p-button-warning p-button-raised"
              />
              <Button
                v-if="revisingQuote && !savingOverwrite"
                :label="$t('menu.canceloverwrite')"
                v-tooltip.right="$t('menu.canceloverwrite')"
                icon="pi pi-times"
                iconPos="left"
                @click.stop="cancelOverwrite"
                class="m-2 p-button-sm p-button-danger p-button-raised"
              />
              <Button
                v-if="revisingQuote && !savingOverwrite"
                :label="$t('menu.saveOver')"
                v-tooltip.right="$t('menu.saveOver')"
                icon="pi pi-save"
                iconPos="left"
                @click.stop="saveOverwrite"
                class="m-2 p-button-sm p-button-success p-button-raised"
              />
              <Button
                v-if="revisingQuote && savingOverwrite"
                :label="$t('profile.saving')"
                v-tooltip.right="$t('profile.saving')"
                icon="pi pi-spin pi-spinner"
                iconPos="left"
                disabled
                class="m-2 p-button-sm p-button-success p-button-raised"
              />
              <Button
                v-if="processingQuote && !savingProcessedQuote"
                :label="$t('profile.cancel')"
                v-tooltip.right="$t('profile.cancel')"
                icon="pi pi-times"
                iconPos="left"
                @click.stop="cancelProcessing"
                class="m-2 p-button-danger p-button-sm p-button-raised"
              />

              <!-- <Button
                v-if="processingQuote"
                label="Reset"
                v-tooltip.right="'Reset'"
                icon="pi pi-replay"
                iconPos="left"
                @click.stop="resetQuote"
                class="mr-2 p-button-secondary p-button-sm p-button-raised"
              /> -->
              <!-- <Button
                v-if="processingQuote"
                label="Autocalculate"
                v-tooltip.right="'Autocalculate'"
                icon="pi pi-plus-circle"
                iconPos="left"
                @click.stop="calculateServiceTotals()"
                class="mr-2 p-button-info p-button-sm p-button-raised"
              /> -->
              <Button
                v-if="processingQuote && !savingProcessedQuote"
                :label="$t('profile.save')"
                v-tooltip.right="$t('profile.save')"
                icon="pi pi-save"
                iconPos="left"
                @click.stop="doneProcessing"
                class="m-2 p-button-success p-button-sm p-button-raised"
              />
              <Button
                v-if="processingQuote && savingProcessedQuote"
                :label="$t('profile.saving')"
                v-tooltip.right="$t('profile.saving')"
                icon="pi pi-spin pi-spinner"
                iconPos="left"
                disabled
                @click.stop="doneProcessing"
                class="m-2 p-button-success p-button-sm p-button-raised"
              />
            </div>
          </div>

          <div class="col-12" style="background-color: #e9ecef">
            <span style="font-size: x-large">{{
              $t("custreserv.custevent")
            }}</span>
            <Button
              id="reviseDate"
              v-if="revisingQuote"
              icon="pi pi-pencil"
              class="
                ml-2
                p-button-rounded p-button-info p-button-sm p-button-raised
              "
              @click.stop="showBusCalendar = true"
            />
          </div>
          <Sidebar
            v-model:visible="showBusCalendar"
            position="full"
            style="overflow: scroll; z-index: 1006"
          >
            <p style="font-size: x-large; text-align: center">
              {{ $t("quotes.availableslots") }}
            </p>
            <vue-cal
              :events="availableSlots"
              small
              :locale="lang"
              style="height: 500px"
              active-view="month"
              :disable-views="['years', 'week', 'day']"
              events-count-on-year-view
              events-on-month-view="true"
              :twelve-hour="twelveHr"
              @ready="fetchEvents(selectedQuote.BusId)"
              @view-change="fetchEvents(selectedQuote.BusId)"
              :on-event-click="onEventClick"
            >
              <template v-slot:event="{ event, view }">
                <span class="vuecal__event-time" v-if="view == 'month'">
                  <span class="show-on-desktop">
                    {{ event.start.formatTime("h:mm am") }} -
                    {{ event.end.formatTime("h:mm am") }}</span
                  >
                  <small class="show-on-mobile">
                    {{ event.start.formatTime("h:mm am") }}
                  </small>
                </span>
                <span class="vuecal__event-time" v-if="view == 'day'">
                  <small class="">
                    {{ event.title }}<br />
                    {{ event.start.formatTime("h:mm am") }} -
                    {{ event.end.formatTime("h:mm am") }}</small
                  >
                </span>
              </template>
            </vue-cal>
          </Sidebar>
          <div class="col-12" style="text-align: center">
            <template
              v-if="selectedQuote.Slot != null && showOriginalDate == true"
            >
              {{ $formatDateTimeToDate(selectedQuote.Slot.SlotDate) }}<br />
              {{ $formatTime(selectedQuote.Slot.SlotStartTime) }} -
              {{ $formatTime(selectedQuote.Slot.SlotEndTime) }}
            </template>
            <template
              v-if="selectedQuote.Slot == null && showOriginalDate == true"
            >
              {{ $t("quotes.notselected") }}
            </template>
            <template v-if="showOriginalDate == false && showTempDate == true">
              {{ $formatDateTimeToDate(selectedSlot.start) }}<br />
              {{ $formatDateTimeToTime(selectedSlot.start) }} -
              {{ $formatDateTimeToTime(selectedSlot.end) }}
            </template>
          </div>

          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: left">
              <div style="background-color: #e9ecef">
                <span style="font-size: x-large">{{
                  $t("custreserv.custinfo")
                }}</span>
              </div>
              <div v-if="selectedQuote.Cust != null">
                <b>{{ $t("custreserv.custname") }}: </b>
                {{ selectedQuote.Cust.CustFirstName }}
                {{ selectedQuote.Cust.CustLastName }}
                <br />
                <b>{{ $t("custreserv.custphone") }}: </b
                >{{ selectedQuote.Cust.CustPhone }}
                <br />
                <b>{{ $t("listmybusiness.address") }}: </b>
                {{ selectedQuote.Cust.CustAddress }}<br />
                {{ selectedQuote.Cust.CustCity }},
                {{ selectedQuote.Cust.CustState }}
                {{ selectedQuote.Cust.CustZip }}
                <br />
                <b>{{ $t("listmybusiness.email") }}: </b>
                {{ selectedQuote.Cust.CustEmail }}
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: right">
              <div style="background-color: #e9ecef">
                <span style="font-size: x-large">{{
                  $t("custreserv.custprocess")
                }}</span>
              </div>
              <!-- <b>Status: </b> -->
              <Tag
                class="mt-2"
                :severity="getStatusTag(selectedQuote.QuoteStatus)"
                style="text-align: left; font-size: x- small"
              >
                {{ selectedQuote.QuoteStatus }}:
                {{ getStatusDesc(selectedQuote.QuoteStatus) }} </Tag
              ><br />
              <b>{{ $t("custreserv.custdatere") }}: </b
              >{{ $formatDateTime(selectedQuote.QuoteRequestDate) }}
              <br />
              <b>{{ $t("custreserv.custdatepro") }}: </b
              ><span v-if="selectedQuote.QuoteProcessedDate">{{
                $formatDateTime(selectedQuote.QuoteProcessedDate)
              }}</span
              ><span v-else>{{ $t("custreserv.custdatenotpro") }}</span>
              <br />
              <b>{{ $t("custreserv.custproby") }}: </b
              ><span v-if="selectedQuote.QuoteProcessedBy">{{
                selectedQuote.QuoteProcessedBy
              }}</span
              ><span v-else>{{ $t("custreserv.custdatenotpro") }}</span
              ><br />
              <b>{{ $t("custreserv.custresponded") }}: </b
              ><span v-if="selectedQuote.QuoteResponseDate">{{
                $formatDateTime(selectedQuote.QuoteResponseDate)
              }}</span
              ><span v-else>{{ $t("custreserv.custnotapp") }}</span>
            </div>
          </div>

          <div class="col-12" style="background-color: #e9ecef">
            <span style="font-size: x-large">{{
              $t("services.services")
            }}</span>
            <Button
              v-if="revisingQuote"
              icon="pi pi-plus"
              class="
                ml-2
                p-button-rounded p-button-info p-button-sm p-button-raised
              "
              @click.stop="showBusServices()"
            />
            <Sidebar
              v-model:visible="showServices"
              :baseZIndex="1001"
              position="full"
              style="overflow: scroll"
            >
              <p style="font-size: x-large; text-align: center">
                {{ $t("services.availablepackages") }}<br />
                <Button
                  icon="pi pi-check"
                  :label="$t('menu.done')"
                  class="mt-2 p-button-info p-button-raised p-button-sm"
                  @click.stop="
                    showServices = false;
                    calculateServiceTotals();
                  "
                />
              </p>

              <DataView :value="busServices" layout="grid" class="service-grid">
                <template #empty>{{ $t("services.nopackages") }} </template>
                <template #grid="slotProps">
                  <div
                    v-if="
                      selectedQuote.QuotedServices.filter(
                        (val) =>
                          val.ServId == slotProps.data.ServId &&
                          val.QuoteServId != 0 &&
                          val.QuoteServRemoved != true
                      ).length == 0
                    "
                    class="col-12 md:col-4 center-text"
                  >
                    <div
                      v-if="slotProps.data != null"
                      class="product-grid-item card"
                      style="background-color: white !important"
                    >
                      <div class="product-grid-item-top flex"></div>
                      <div class="product-grid-item-content">
                        <img
                          v-if="slotProps.data.ServImage != null"
                          :src="imageHostingURL + slotProps.data.ServImage"
                          :alt="slotProps.data.ServTitle"
                          width="25vh"
                          class="mr-3"
                        />
                        <div class="product-name">
                          {{ slotProps.data.ServTitle }}
                        </div>
                        <div class="product-description">
                          {{ slotProps.data.ServShortDesc }} <br />
                          <div>
                            <i class="pi pi-tag product-category-icon"></i>
                            <template
                              v-for="type in slotProps.data.ServiceTypes"
                              :key="type.ServTypeTitle"
                            >
                              <Chip class="m-1">
                                <service-type-label
                                  :serviceType="type.ServTypeTitle"
                              /></Chip>
                            </template>
                            <!-- <span
                              v-if="slotProps.data.ServiceTypes != null"
                              class="product-category"
                            >
                              {{
                                slotProps.data.ServiceTypes.map(
                                  (s) => s.ServTypeTitle
                                ).join(", ")
                              }}
                            </span> -->
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          product-grid-item-bottom
                          flex flex-wrap
                          justify-content-evenly
                        "
                      >
                        <span class="product-price mb-2 mr-2"
                          >${{ slotProps.data.ServPrice }}
                          <span
                            v-if="slotProps.data.ServPriceCalculated == true"
                            style="font-size: small"
                          >
                            {{ $t("quotes.each") }} &nbsp;
                          </span>
                        </span>

                        <Tag
                          class="mb-2 mr-2"
                          v-if="
                            slotProps.data != null &&
                            slotProps.data.ServPromotionExpires != null &&
                            promotionValid(slotProps.data.ServPromotionExpires)
                          "
                          severity="danger"
                          value="Danger"
                          style="margin-top: 5px"
                        >
                          <em>Special offer!</em>
                        </Tag>
                      </div>

                      <div v-if="slotProps.data.ServPriceCalculated">
                        <label>{{ $t("menu.qty')") }} </label>
                        <InputNumber
                          v-if="
                            selectedQuote.QuotedServices.filter(
                              (val) => val.ServId == slotProps.data.ServId
                            ).length == 0
                          "
                          class="mb-3"
                          v-model="
                            servicesForQuoteQty[getIndex(slotProps.data.ServId)]
                              .Qty
                          "
                          inputStyle="width:50px"
                        />
                        <InputNumber
                          v-else
                          disabled
                          class="mb-3"
                          v-model="
                            servicesForQuoteQty[getIndex(slotProps.data.ServId)]
                              .Qty
                          "
                          inputStyle="width:50px"
                        />
                      </div>
                      <Button
                        v-if="
                          selectedQuote.QuotedServices.filter(
                            (val) =>
                              val.ServId == slotProps.data.ServId &&
                              val.QuoteServRemoved != true
                          ).length == 0
                        "
                        icon="pi pi-plus"
                        :label="$t('menu.addquote')"
                        class="
                          p-button-raised
                          p-button-success
                          p-button-text
                          p-button-sm
                        "
                        @click.stop="addToQuote(slotProps.data)"
                      />
                      <Button
                        v-else
                        icon="pi pi-plus"
                        :label="$t('menu.removequote')"
                        class="
                          p-button-raised
                          p-button-danger
                          p-button-text
                          p-buton-sm
                        "
                        @click.stop="removeFromQuote(slotProps.data)"
                      />
                    </div>
                    <div
                      v-else
                      class="product-grid-item card"
                      style="background-color: white !important"
                    >
                      {{ $t("services.fail") }}
                    </div>
                  </div></template
                >
              </DataView>
            </Sidebar>
          </div>
          <div class="col-4" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custser") }}</b>
          </div>
          <div class="col-3" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custprice") }}</b>
          </div>
          <div class="col-2" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custqty") }}</b>
          </div>
          <div
            class="col-3 pr-5"
            style="background-color: #f8f9fa; text-align: right"
          >
            <b>{{ $t("custreserv.custamount") }}</b>
          </div>

          <template
            v-for="(serv, index) in selectedQuote.QuotedServices"
            :key="serv.QuoteServId"
          >
            <template v-if="serv.QuoteServOrigId == null">
              <div class="col-4" :class="{ removed: serv.QuoteServRemoved }">
                <span
                  v-if="revisingQuote && serv.QuoteServRemoved != true"
                  style="float: left"
                >
                  <Button
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger"
                    @click.stop="removeService(index)"
                  />
                </span>
                {{ serv.Serv.ServTitle }}
              </div>
              <div class="col-3" :class="{ removed: serv.QuoteServRemoved }">
                ${{ serv.ServPrice }}
                <span
                  v-if="serv.Serv.ServPriceCalculated == true"
                  style="font-size: small"
                >
                  {{ $t("quotes.each") }}
                </span>
              </div>
              <div class="col-2" :class="{ removed: serv.QuoteServRemoved }">
                <span v-if="revisingQuote == false">
                  {{ serv.QuoteServQty }} &nbsp;</span
                >
                <span
                  v-else-if="
                    serv.Serv.ServPriceCalculated == true &&
                    serv.QuoteServRemoved == false
                  "
                >
                  <InputNumber
                    v-model="serv.QuoteServQty"
                    inputStyle="width:100%"
                  />
                </span>
                <span v-else> {{ serv.QuoteServQty }} &nbsp;</span>

                <!-- <span v-if="serv.QuoteServEdited != null" class="removed">
               {{selectedQuote.QuotedServices.find(s=>s.QuoteServOrigId == serv.QuoteServId ).QuoteServQty}}
                </span> -->
              </div>
              <div
                class="col-3 pr-5"
                style="text-align: right"
                :class="{ removed: serv.QuoteServRemoved }"
              >
                <span v-if="serv.QuoteServTotal != 0 && revisingQuote == false"
                  ><b>${{ serv.QuoteServTotal }}</b></span
                >
                <!-- <span
                  v-if="
                    processingQuote == true && serv.QuoteServRemoved != true
                  "
                >
                  <div class="p-inputgroup">
                    <InputNumber
                      v-model="serv.QuoteServTotal"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      inputStyle="width:100%"
                    />
                    <Button
                      icon="pi pi-check"
                      iconPos="left"
                      @click.stop="calculateTotals"
                      class="mr-2 p-button-success"
                    />
                  </div>
                </span> -->
              </div>
              <div
                class="col-12"
                style="margin-top: 0; padding-top: 0; color: darkgray"
                v-if="
                  serv.QuoteServRemoved == true && serv.QuoteServEdited == null
                "
              >
                <small
                  ><em> {{ $t("quotes.removed") }} </em></small
                >
              </div>
              <div
                class="col-12"
                style="margin-top: 0; padding-top: 0; color: darkgray"
                v-if="
                  serv.QuoteServRemoved == false && serv.QuoteServEdited != null
                "
              >
                <small
                  ><em
                    >{{
                      serv.QuoteServEditedBy === "cust"
                        ? $t("quotes.editedbycustomer")
                        : $t("quotes.editedbybusiness")
                    }}
                    {{ $formatDateTime(serv.QuoteServEdited) }}
                  </em></small
                >
              </div>
            </template>
          </template>

          <div class="col-12 mt-5 grid justify-content-end">
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custsub") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <span
                v-if="
                  selectedQuote.QuoteSubTotal != 0 && revisingQuote == false
                "
                ><b>${{ selectedQuote.QuoteSubTotal }}</b></span
              >
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custdis") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <span
                v-if="
                  selectedQuote.QuoteDiscount != 0 &&
                  revisingQuote == false &&
                  processingQuote == false
                "
                ><b>${{ selectedQuote.QuoteDiscount }}</b></span
              >
              <span v-if="processingQuote == true">
                <div class="p-inputgroup">
                  <InputNumber
                    v-model="selectedQuote.QuoteDiscount"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    inputStyle="width:100%"
                  />
                  <Button
                    icon="pi pi-check"
                    iconPos="left"
                    @click.stop="calculateTotals"
                    class="p-button-success"
                  />
                </div>
              </span>
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custsales") }}</b
              ><br />
              {{ this.currentBusiness.BusSalesTaxPercent }}%
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <span
                v-if="selectedQuote.QuoteTax != 0 && revisingQuote == false"
              >
                <b>${{ selectedQuote.QuoteTax }}</b></span
              >
              <!-- <span v-if="processingQuote == true">
                <InputNumber
                  v-model="selectedQuote.QuoteTax"
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  inputStyle="width:100%"
                />
              </span> -->
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custtotal") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <span
                v-if="selectedQuote.QuoteTotal != 0 && revisingQuote == false"
              >
                <b>${{ selectedQuote.QuoteTotal }}</b></span
              >
              <!-- <span v-if="processingQuote == true">
                <InputNumber
                  v-model="selectedQuote.QuoteTotal"
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  inputStyle="width:100%"
                />
              </span> -->
            </div>
          </div>
          <div class="col-12" style="background-color: #e9ecef">
            <div class="flex justify-content-between">
              <span style="font-size: x-large"
                >{{ $t("custreserv.custcomments") }} </span
              ><Button
              v-if="!processingQuote && !revisingQuote"
                icon="pi pi-plus"
                class="p-button-rounded p-button-sm p-button-info"
                @click.stop="addingComment = true"
              />
              <Button
              v-else
               v-tooltip.bottom="'Save changes on Quote before adding comments.'"
                icon="pi pi-plus"
                class="p-button-rounded p-button-sm p-button-info"
                disabled
              />
            </div>
          </div>
          <div v-if="addingComment" class="col-12" style="text-align: left">
            <Textarea
              v-model="newComment.CommentText"
              :placeholder="$t('quotes.enter')"
              :autoResize="true"
              rows="5"
              cols="30"
              style="width: 100%"
            />
            <div class="flex justify-content-between">
              <Button
                :label="$t('profile.cancel')"
                v-tooltip.right="$t('profile.cancel')"
                icon="pi pi-times"
                iconPos="left"
                @click.stop="cancelComment"
                class="mr-2 p-button-danger p-button-sm p-button-raised"
              />
              <Button
                :label="$t('profile.save')"
                v-tooltip.right="$t('profile.save')"
                icon="pi pi-save"
                iconPos="left"
                @click.stop="saveComment"
                class="mr-2 p-button-success p-button-sm p-button-raised"
              />
            </div>
          </div>
          <div class="col-12">
            <template
              v-for="comment in selectedQuote.QuoteComments.sort((a, b) =>
            a.CommentDate > b.CommentDate ? -1 : 1
          )"
              :key="comment.CommentId"
            >
              <div class="card" style="text-align: left">
                <div class="flex justify-content-between">
                  <span>
                    {{ comment.Cust.CustFirstName }}
                    {{ comment.Cust.CustLastName }}</span
                  >
                  <span> {{ $formatDateTime(comment.CommentDate) }}</span>
                </div>
                <p>
                  <em>{{ comment.CommentText }}</em>
                </p>
              </div>
            </template>
          </div>
        </div>
      </Sidebar>

      <!-- NEW QUOTE -->
      <Sidebar
        v-model:visible="createNewQuote"
        :baseZIndex="1000"
        position="full"
        style="overflow: scroll"
        @hide="resetNewQuote()"
      >
        <div class="grid">
          <div class="col-12">
            <div class="col-12 mb-2" style="background-color: #e9ecef">
              <span style="font-size: x-large">{{ $t("quotes.new") }}</span>
            </div>
            <div class="flex justify-content-center">
              <Button
                :label="$t('profile.cancel')"
                v-tooltip.right="$t('profile.cancel')"
                icon="pi pi-times"
                iconPos="left"
                @click.stop="resetNewQuote()"
                class="mr-2 p-button-danger p-button-sm"
              />

              <Button
                :label="$t('profile.save')"
                v-tooltip.right="$t('profile.save')"
                icon="pi pi-save"
                iconPos="left"
                @click.stop="saveNewQuote()"
                class="mr-2 p-button-success p-button-sm"
              />
            </div>
          </div>

          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: left">
              <div style="background-color: #e9ecef" class="p-1">
                <span style="font-size: x-large"
                  >1.{{ $t("custreserv.custinfo") }}</span
                >
              </div>
              <div v-if="!showCustDetails" class="field m-1">
                <div class="p-inputgroup">
                  <InputText
                    id="custEmail"
                    type="email"
                    v-model="quoteCustEmail"
                    :placeholder="$t('message.customeremail')"
                    class="p-inputtext-sm"
                  />
                  <span class="p-inputgroup-addon">
                    <Button
                      icon="pi pi-search"
                      label="Look Up"
                      class="p-button-text p-button-primary"
                      @click.stop="searchCustomer()"
                    />
                  </span>
                </div>
                <span v-if="showInvalidEmail" class="mt-1">
                  <InlineMessage>{{ $t("message.invalid") }}</InlineMessage>
                </span>

                <Dialog
                  v-model:visible="showNewCustomer"
                  :modal="true"
                  :contentStyle="{ overflow: 'scroll' }"
                  :dismissableMask="true"
                  :style="{ width: '50vw' }"
                  :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
                >
                  <p style="font-size: x-large; text-align: center">
                    {{ $t("message.nocustomerinfo") }}
                  </p>
                  <div>
                    <div class="p-fluid formgrid grid text-left">
                      <div class="field col-12 md:col-4">
                        <label for="first">{{ $t("message.firstname") }}</label>
                        <InputText
                          id="first"
                          type="text"
                          v-model="selectedQuote.Cust.CustFirstName"
                        />
                      </div>
                      <div class="field col-12 md:col-4">
                        <label for="last">{{ $t("message.lastname") }}</label>
                        <InputText
                          id="last"
                          type="text"
                          v-model="selectedQuote.Cust.CustLastName"
                        />
                      </div>
                      <div class="field col-12 md:col-4">
                        <label for="phone">{{
                          $t("listmybusiness.phone")
                        }}</label>
                        <InputMask
                          id="phone"
                          mask="(999) 999-9999"
                          v-model="selectedQuote.Cust.CustPhone"
                          placeholder="(999) 999-9999"
                        />
                      </div>
                      <div class="field col-12">
                        <label for="address">{{
                          $t("listmybusiness.address")
                        }}</label>
                        <InputText
                          id="address"
                          v-model="selectedQuote.Cust.CustAddress"
                        />
                      </div>
                      <div class="field col-4">
                        <label for="city">{{ $t("message.city") }}</label>
                        <InputText
                          id="city"
                          type="text"
                          v-model="selectedQuote.Cust.CustCity"
                        />
                      </div>
                      <div class="field col-4">
                        <label for="state">{{ $t("message.state") }}</label>
                        <InputText
                          id="state"
                          type="text"
                          v-model="selectedQuote.Cust.CustState"
                        />
                      </div>
                      <div class="field col-4">
                        <label for="zip">{{ $t("message.zip") }}</label>
                        <InputText
                          id="zip"
                          type="text"
                          v-model="selectedQuote.Cust.CustZip"
                        />
                      </div>
                      <div class="col-12 flex mt-3">
                        <Button
                          label="Done"
                          icon="pi pi-check"
                          @click.stop="
                            showNewCustomer = false;
                            showCustDetails = true;
                          "
                          autofocus
                        />
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
              <div v-if="showCustDetails">
                <Message severity="info" :closable="false">
                  <div class="mb-2">
                    <span>
                      {{ selectedQuote.Cust.CustFirstName }}
                      {{ selectedQuote.Cust.CustLastName }} <b> | </b></span
                    >
                    <span
                      v-if="
                        selectedQuote.Cust.CustPhone != null &&
                        selectedQuote.Cust.CustPhone.length > 0
                      "
                    >
                      {{ selectedQuote.Cust.CustPhone }}<b> | </b> </span
                    ><span
                      v-if="
                        selectedQuote.Cust.CustEmail != null &&
                        selectedQuote.Cust.CustEmail.length > 0
                      "
                    >
                      {{ selectedQuote.Cust.CustEmail }}<b> | </b>
                    </span>
                    <span
                      v-if="
                        selectedQuote.Cust.CustAddress != null &&
                        selectedQuote.Cust.CustAddress.length > 0
                      "
                    >
                      {{ selectedQuote.Cust.CustAddress }}
                    </span>
                    <span
                      v-if="
                        selectedQuote.Cust.CustCity != null &&
                        selectedQuote.Cust.CustCity.length > 0
                      "
                    >
                      {{ selectedQuote.Cust.CustCity }},
                    </span>
                    <span
                      v-if="
                        selectedQuote.Cust.CustState != null &&
                        selectedQuote.Cust.CustState.length > 0
                      "
                      >{{ selectedQuote.Cust.CustState }}
                    </span>
                    <span
                      v-if="
                        selectedQuote.Cust.CustZip != null &&
                        selectedQuote.Cust.CustZip.length > 0
                      "
                    >
                      {{ selectedQuote.Cust.CustZip }}
                    </span>
                  </div>
                  <Button
                    :label="$t('menu.edit')"
                    v-if="selectedQuote.CustId == 0"
                    class="p-button-info p-button-sm mr-2"
                    @click.stop="showNewCustomer = true"
                  />
                  <Button
                    :label="$t('menu.reset')"
                    class="p-button-secondary p-button-sm"
                    @click.stop="resetQuoteCust()"
                  />
                </Message>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 mb-2">
            <div style="text-align: left">
              <div style="background-color: #e9ecef" class="p-1">
                <span style="font-size: x-large"
                  >2.{{ $t("custreserv.custevent") }}</span
                >
                <Button
                  id="selectDateBtn"
                  icon="pi pi-calendar"
                  class="ml-2 p-button-rounded p-button-info p-button-sm"
                  aria:haspopup="true"
                  aria-controls="overlay_panel"
                  @click.stop="toggle2($event)"
                />
              </div>
            </div>
            <OverlayPanel
              ref="op2"
              appendTo="body"
              :showCloseIcon="true"
              id="overlay_panel"
              style="z-index: 4000; margin: 2vh; width: 90%; height: 90%"
            >
              <p style="font-size: x-large; text-align: center">
                {{ $t("quotes.availableslots") }}
              </p>
              <vue-cal
                :events="availableSlots"
                small
                :locale="lang"
                style="height: 500px"
                active-view="month"
                :disable-views="['years', 'week', 'day']"
                events-count-on-year-view
                events-on-month-view="true"
                :twelve-hour="twelveHr"
                @ready="fetchEvents(currentBusinessId)"
                @view-change="fetchEvents(currentBusinessId)"
                :on-event-click="onNewQuoteEventClick"
              >
                <template v-slot:event="{ event, view }">
                  <span class="vuecal__event-time" v-if="view == 'month'">
                    <span class="show-on-desktop">
                      {{ event.start.formatTime("h:mm am") }} -
                      {{ event.end.formatTime("h:mm am") }}</span
                    >
                    <small class="show-on-mobile">
                      {{ event.start.formatTime("h:mm am") }}
                    </small>
                  </span>
                  <span class="vuecal__event-time" v-if="view == 'day'">
                    <small class="">
                      {{ event.title }}<br />
                      {{ event.start.formatTime("h:mm am") }} -
                      {{ event.end.formatTime("h:mm am") }}</small
                    >
                  </span>
                </template>
              </vue-cal>
            </OverlayPanel>
            <div class="col-12" style="text-align: center">
              <template v-if="selectedQuote.SlotId == null">
                {{ $t("quotes.notselected") }}
              </template>
              <template v-else>
                <Message severity="info" :closable="false">
                  {{ $formatDateTimeToDate(selectedSlot.start) }}<br />
                  {{ $formatDateTimeToTime(selectedSlot.start) }} -
                  {{ $formatDateTimeToTime(selectedSlot.end) }}
                </Message>
              </template>
            </div>
          </div>

          <div class="col-12" style="background-color: #e9ecef">
            <span style="font-size: x-large"
              >3.{{ $t("services.services") }}</span
            >
            <Button
              icon="pi pi-plus"
              class="ml-2 p-button-rounded p-button-info p-button-sm"
              @click.stop="showBusServices()"
            />
            <Sidebar
              v-model:visible="showServices"
              :baseZIndex="1001"
              position="full"
              style="overflow: scroll"
            >
              <p style="font-size: x-large; text-align: center">
                {{ $t("services.availablepackages") }}<br />
                <Button
                  icon="pi pi-check"
                  label="Done"
                  class="mt-2 p-button-info p-button-raised p-button-sm"
                  @click.stop="
                    showServices = false;
                    calculateServiceTotals();
                  "
                />
              </p>

              <DataView :value="busServices" layout="grid" class="service-grid">
                <template #empty>{{ $t("services.nopackages") }} </template>
                <template #grid="slotProps">
                  <div
                    v-if="
                      selectedQuote.QuotedServices.filter(
                        (val) =>
                          val.ServId == slotProps.data.ServId &&
                          val.QuoteServId != 0 &&
                          val.QuoteServRemoved != true
                      ).length == 0 &&
                      slotProps.data.ServActive != false &&
                      slotProps.data.ServDeleted != true
                    "
                    class="col-12 md:col-4 center-text"
                  >
                    <div
                      v-if="slotProps.data != null"
                      class="product-grid-item card"
                      style="background-color: white !important"
                    >
                      <div class="product-grid-item-top flex"></div>
                      <div class="product-grid-item-content">
                        <img
                          v-if="slotProps.data.ServImage != null"
                          :src="imageHostingURL + slotProps.data.ServImage"
                          :alt="slotProps.data.ServTitle"
                          width="25vh"
                          class="mr-3"
                        />
                        <div class="product-name">
                          {{ slotProps.data.ServTitle }}
                        </div>
                        <div class="product-description">
                          {{ slotProps.data.ServShortDesc }} <br />
                          <div>
                            <i class="pi pi-tag product-category-icon"></i>
                            <template
                              v-for="type in slotProps.data.ServiceTypes"
                              :key="type.ServTypeTitle"
                            >
                              <Chip class="m-1">
                                <service-type-label
                                  :serviceType="type.ServTypeTitle"
                              /></Chip>
                            </template>
                            <!-- <span
                              v-if="slotProps.data.ServiceTypes != null"
                              class="product-category"
                            >
                              {{
                                slotProps.data.ServiceTypes.map(
                                  (s) => s.ServTypeTitle
                                ).join(", ")
                              }}
                            </span> -->
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          product-grid-item-bottom
                          flex flex-wrap
                          justify-content-evenly
                        "
                      >
                        <span class="product-price mb-2 mr-2"
                          >${{ slotProps.data.ServPrice
                          }}<span
                            v-if="slotProps.data.ServPriceCalculated == true"
                            style="font-size: small"
                          >
                            {{ $t("quotes.each") }} &nbsp;
                          </span></span
                        >

                        <Tag
                          class="mb-2 mr-2"
                          v-if="
                            slotProps.data.ServPromotionExpires != null &&
                            promotionValid(slotProps.data.ServPromotionExpires)
                          "
                          severity="danger"
                          value="Danger"
                          style="margin-top: 5px"
                        >
                          <em>{{ $t("services.special") }}</em>
                        </Tag>
                      </div>

                      <div v-if="slotProps.data.ServPriceCalculated">
                        <label>{{ $t("menu.qty") }}:&nbsp; </label>
                        <InputNumber
                          v-if="
                            selectedQuote.QuotedServices.filter(
                              (val) => val.ServId == slotProps.data.ServId
                            ).length == 0
                          "
                          class="mb-3"
                          v-model="
                            servicesForQuoteQty[getIndex(slotProps.data.ServId)]
                              .Qty
                          "
                          inputStyle="width:50px"
                        />
                        <InputNumber
                          v-else
                          disabled
                          class="mb-3"
                          v-model="
                            servicesForQuoteQty[getIndex(slotProps.data.ServId)]
                              .Qty
                          "
                          inputStyle="width:50px"
                        />
                      </div>
                      <Button
                        v-if="
                          selectedQuote.QuotedServices.filter(
                            (val) =>
                              val.ServId == slotProps.data.ServId &&
                              val.QuoteServRemoved != true
                          ).length == 0
                        "
                        icon="pi pi-plus"
                        :label="$t('menu.addquote')"
                        class="
                          p-button-raised
                          p-button-success
                          p-button-text
                          p-button-sm
                        "
                        @click.stop="addToQuote(slotProps.data)"
                      />
                      <Button
                        v-else
                        icon="pi pi-plus"
                        :label="$t('menu.removequote')"
                        class="
                          p-button-raised
                          p-button-danger
                          p-button-text
                          p-buton-sm
                        "
                        @click.stop="removeFromQuote(slotProps.data)"
                      />
                    </div>
                    <div
                      v-else
                      class="product-grid-item card"
                      style="background-color: white !important"
                    >
                      {{ $t("services.fail") }}
                    </div>
                  </div></template
                >
              </DataView>
            </Sidebar>
          </div>
          <div class="col-4" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custser") }}</b>
          </div>
          <div class="col-2" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custprice") }}</b>
          </div>
          <div class="col-1" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custqty") }}</b>
          </div>
          <div class="col-5" style="background-color: #f8f9fa">
            <b>{{ $t("custreserv.custamount") }}</b>
          </div>

          <template
            v-for="(serv, index) in selectedQuote.QuotedServices"
            :key="serv.QuoteServId"
          >
            <template v-if="serv.QuoteServOrigId == null">
              <div class="col-4" :class="{ removed: serv.QuoteServRemoved }">
                <span
                  v-if="revisingQuote && serv.QuoteServRemoved != true"
                  style="float: left"
                >
                  <Button
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger"
                    @click.stop="removeService(index)"
                  />
                </span>
                {{ serv.Serv.ServTitle }}
              </div>
              <div class="col-2" :class="{ removed: serv.QuoteServRemoved }">
                ${{ serv.ServPrice }}
                <span
                  v-if="serv.Serv.ServPriceCalculated == true"
                  style="font-size: small"
                >
                  {{ $t("quotes.each") }}
                </span>
              </div>

              <div class="col-1" :class="{ removed: serv.QuoteServRemoved }">
                <span v-if="revisingQuote == false">
                  {{ serv.QuoteServQty }} &nbsp;</span
                >
                <span
                  v-else-if="
                    serv.Serv.ServPriceCalculated == true &&
                    serv.QuoteServRemoved == false
                  "
                >
                  <InputNumber
                    v-model="serv.QuoteServQty"
                    inputStyle="width:100%"
                  />
                </span>
                <span v-else> {{ serv.QuoteServQty }} &nbsp;</span>
              </div>
              <div class="col-5" :class="{ removed: serv.QuoteServRemoved }">
                <div class="p-inputgroup">
                  <InputNumber
                    v-model="serv.QuoteServTotal"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    inputStyle="width:100%"
                  />
                  <Button
                    icon="pi pi-check"
                    iconPos="left"
                    @click.stop="calculateTotals"
                    class="mr-2 p-button-success"
                  />
                </div>
              </div>
              <div
                class="col-12"
                style="margin-top: 0; padding-top: 0; color: darkgray"
                v-if="
                  serv.QuoteServRemoved == true && serv.QuoteServEdited == null
                "
              >
                <small><em> {$t("message.remove")}} </em></small>
              </div>
              <div
                class="col-12"
                style="margin-top: 0; padding-top: 0; color: darkgray"
                v-if="
                  serv.QuoteServRemoved == false && serv.QuoteServEdited != null
                "
              >
                <small
                  ><em
                    >{{
                      serv.QuoteServEditedBy === "cust"
                        ? $t("quotes.editedbycustomer")
                        : $t("quotes.editedbybusiness")
                    }}
                    {{ $formatDateTime(serv.QuoteServEdited) }}
                  </em></small
                >
              </div>
            </template>
          </template>

          <div class="col-12 mt-5 grid justify-content-end">
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custsub") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <span
                v-if="
                  selectedQuote.QuoteSubTotal != 0 && createNewQuote == false
                "
                ><b>${{ selectedQuote.QuoteSubTotal }}</b></span
              >
              <span v-if="createNewQuote == true">
                <div class="p-inputgroup">
                  <InputNumber
                    v-model="selectedQuote.QuoteSubTotal"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    inputStyle="width:100%"
                  />
                  <Button
                    icon="pi pi-replay"
                    iconPos="left"
                    @click.stop="calculateTotals"
                    class="mr-2 p-button-info"
                  />
                </div>
              </span>
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custdis") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <div class="p-inputgroup">
                <InputNumber
                  v-model="selectedQuote.QuoteDiscount"
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  inputStyle="width:100%"
                />
                <Button
                  icon="pi pi-check"
                  iconPos="left"
                  @click.stop="calculateTotals"
                  class="p-button-success"
                />
              </div>
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custsales") }}</b
              ><br />
              {{ this.currentBusiness.BusSalesTaxPercent }}%
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <InputNumber
                v-model="selectedQuote.QuoteTax"
                mode="currency"
                currency="USD"
                locale="en-US"
                inputStyle="width:100%"
              />
            </div>
            <div
              class="col-6 md:col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custtotal") }}</b>
            </div>
            <div class="col-6 md:col-3" style="text-align: right">
              <InputNumber
                v-model="selectedQuote.QuoteTotal"
                mode="currency"
                currency="USD"
                locale="en-US"
                inputStyle="width:100%"
              />
            </div>
          </div>
          <div class="col-12" style="background-color: #e9ecef">
            <div class="flex justify-content-between">
              <span style="font-size: x-large"
                >{{ $t("custreserv.custcomments") }}
              </span>
            </div>
          </div>
          <div class="col-12" style="text-align: left">
            <Textarea
              v-model="newComment.CommentText"
              :placeholder="$t('custreserv.optional')"
              :autoResize="true"
              rows="5"
              cols="30"
              style="width: 100%"
            />
          </div>
        </div>
      </Sidebar>
    </div>
    <div v-else-if="currentBusiness.BusActive == false">
      <inactive-subscription
        :busId="currentBusiness.BusId"
      ></inactive-subscription>
    </div>
    <div v-else-if="currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode != 'PREMIUM'">
     <!-- <div v-else-if="true"> -->
      <upgrade-subscription
        :subId="currentSubscription.SubId"
      ></upgrade-subscription>
    </div>
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{ $t("message.error") }}</Message
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
import Quote from "../store/Models/Quote";
import QuoteComment from "../store/Models/QuoteComment";
import Customer from "../store/Models/Customer";
import CalendarEvent from "../store/Models/CalendarEvent";
import QuotedService from "../store/Models/QuotedService";
import Service from "../store/Models/Service";
import Business from "../store/Models/Business";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import InactiveSubscription from "../components/InactiveSubscription.vue";
import ServiceTypeLabel from "../components/ServiceTypeLabel.vue";
import moment from "moment";
import Subscription from '../store/Models/Subscription';
import UpgradeSubscription from '../components/UpgradeSubscription.vue';

export default {
  name: "BusinessQuotes",
  components: {
    VueCal,
    LoadingOverlay,
    InactiveSubscription,
    ServiceTypeLabel,
    UpgradeSubscription
  },
  props: {
    quoteId: { default: 0, type: Number },
    busId: { default: 0, type: Number },
  },
  data() {
    return {
      selectedQuote: {
        BusId: 0,
        QuoteDiscount: 0,
        QuoteTotal: 0,
        QuoteTax: 0,
        QuoteSubTotal: 0,
        QuoteStatus: "Processed",
        Cust: {},
        CustId: 0,
        currentSubscription: {},
        QuotedServices: [],
        Slot: {},
        QuoteComments: [],
      },
      statusTagStyle: "",
      showQuoteDetails: null,
      filters: {},
      loading: false,
      quotes: [],
      processingQuote: false,
      currentCustomer: {},
      addingComment: false,
      newComment: {},
      revisingQuote: false,
      showOriginalDate: true,
      showTempDate: false,
      availableSlots: [],
      showBusCalendar: false,
      selectedSlot: {},
      twelveHr: true,
      showServices: false,
      busServices: [],
      servicesForQuoteQty: [],
      currentBusiness: {},
      createNewQuote: false,
      quoteCustEmail: "",
      showNewCustomer: false,
      showCustDetails: false,
      showInvalidEmail: false,
      savingOverwrite: false,
      savingProcessedQuote: false,
      waitingOnBusiness: false,
    };
  },
  async mounted() {
    this.$log.debug("business quotes mounted");
    this.$log.debug(this.$route.params.quoteId);
    this.$log.debug(this.$route.params.busId);
    this.$log.debug(this.currentBusinessId);
    this.loading = true;
    if (this.currentBusinessId > 0) {
      await this.setBusinessQuotes();
    }
    if (this.$route.params.quoteId > 0) {
      this.loading = true;
      if (this.currentBusinessId != this.$route.params.busId) {
        this.waitingOnBusiness = true;
        await this.setCurrentBusiness(this.$route.params.busId);
      } else {
        this.onLoadWithQuote();
      }
    }
    if (this.currentCustomerId > 0) {
      await this.setCurrentCustomer();
    }
    this.loading = false;
  },
  methods: {
    ...mapActions(["setCurrentBusiness"]),
    onLoadWithQuote() {
      let foundQuote = this.quotes.find(
        (quote) => quote.QuoteId == this.$route.params.quoteId
      );
      this.$log.debug(foundQuote);
      if (foundQuote) {
        this.selectedQuote = foundQuote;
        this.openQuoteDetails(this.selectedQuote);
        this.$router.replace("/BusinessQuotes");
      }
      this.loading = false;
    },
    promotionValid(promotionExpirationDate) {
      return moment().isSameOrBefore(promotionExpirationDate,'day');
    },
    toggle1(event) {
      //revise date
      this.$log.debug(event);
      this.$refs.op1.toggle(event);
    },
    toggle2(event) {
      // new quote
     //console.log("toggle2")
      this.$log.debug(event);
      this.$refs.op2.show(event);
    },
    fetchEvents(busId) {
      this.$log.debug("fetching events for: " + busId);
      this.$log.debug(busId);
      CalendarEvent.fetchAvailableByType(busId,"DATE").then((result) => {
        this.$log.debug(result);
        this.availableSlots = result;
      });
    },
    async showBusServices() {
      this.busServices = await Service.fetchBusinessServices(
        this.currentBusinessId
      );
      this.busServices.forEach((serv) => {
        this.servicesForQuoteQty.push({ ServId: serv.ServId, Qty: 1 });
      });
      this.$log.debug(this.busServices);
      this.showServices = true;
    },
    async addToQuote(service) {
      this.$log.debug("adding new service to quote");
      this.selectedQuote.QuotedServices.push(
        new QuotedService({
          QuoteServId: 0,
          QuoteId: this.selectedQuote.QuoteId,
          ServId: service.ServId,
          ServPrice: service.ServPrice,
          QuoteServQty:
            this.servicesForQuoteQty[this.getIndex(service.ServId)].Qty,
          QuoteServTotal: 0,
          Serv: service,
        })
      );
      this.$log.debug(this.selectedQuote.QuotedServices);
    },
    removeFromQuote(service) {
      this.$log.debug("removing new service");
      this.selectedQuote.QuotedServices.splice(
        this.selectedQuote.QuotedServices.findIndex(
          (val) => val == service.ServId
        ),
        1
      );
    },
    getIndex(servId) {
      return this.servicesForQuoteQty.findIndex((i) => i.ServId == servId);
    },
    onEventClick(event) {
      this.availableSlots = [];
      this.selectedEvent = event;
      this.$log.debug(event);
      this.selectedQuote.SlotId = event.slotId;
      this.selectedSlot = event;
      this.showOriginalDate = false;
      this.showTempDate = true;
      this.showBusCalendar = false;
    },
    onNewQuoteEventClick(event) {
      this.availableSlots = [];
      this.selectedEvent = event;
      this.$log.debug(event);
      this.selectedQuote.SlotId = event.slotId;
      this.selectedSlot = event;
      this.showTempDate = true;
      this.$refs.op2.hide();
    },
    openQuoteDetails(data) {
      this.$log.debug("opening quote");
      this.$log.debug(data);
      this.revisingQuote = false;
      this.selectedQuote = data;
      // this.selectedQuote.QuotedServices.forEach((serv) => {
      //   serv.QuoteServTotal = serv.ServPrice * serv.QuoteServQty;
      // });
      this.selectedQuote.QuoteComments.sort(function (a, b) {
        return b.CommentId - a.CommentId;
      });
      this.showQuoteDetails = true;
    },
    async setBusinessQuotes() {
      Business.fetchBusinessProfileById(this.currentBusinessId).then(
        (result) => {
          this.currentBusiness = result;
        
        }
      );
      Subscription.fetchCurrentByBusiness(this.currentBusinessId).then((result) => {
          this.currentSubscription = result;
         //console.log(this.currentSubscription)
        });
      await Quote.fetchByBusId(this.currentBusinessId).then((result) => {
        this.$log.debug(result);
        this.quotes = result;
        if (this.selectedQuote != null && this.selectedQuote.QuoteId != null) {
          this.selectedQuote = Quote.query()
            .withAllRecursive()
            .find(this.selectedQuote.QuoteId);
        }
        if (this.waitingOnBusiness) {
          this.onLoadWithQuote();
        }
        this.loading = false;
      });
    },
    getStatusTag(status) {
      switch (status) {
        case "Requested":
          return "primary";
        case "Processed":
          return "info";
        case "Accepted":
          return "success";
        case "Declined":
          return "danger";
        case "Canceled":
          return "danger";
        case "Expired":
          return "danger";
        default:
          return "info";
      }
    },
    getStatusDesc(status) {
      switch (status) {
        case "Requested":
          return "Pending your review.";
        case "Processed":
          return "Pending client review. ";
        case "Accepted":
          return "Client accepted. Reservation processed.";
        case "Declined":
          return "Client declined.";
        case "Expired":
          return "Client did not respond on time.";
        case "Canceled":
          return "Quote request has been canceled.";
        default:
          return "";
      }
    },
    processQuote() {
      this.$log.debug("process quote");
      if (this.selectedQuote.QuoteStatus == "Requested") {
        this.setQuoteProcessing();
      } else {
        this.processingQuote = true;
      }
    },
    setQuoteProcessing() {
      this.$log.debug(this.currentCustomer);
      this.calculateServiceTotals();
      this.processingQuote = true;
    },
    calculateServiceTotals() {
      let self = this;
      this.selectedQuote.QuotedServices.forEach(function (serv, index) {
        if (serv.QuoteServRemoved != true)
          self.selectedQuote.QuotedServices[index].QuoteServTotal =
            serv.ServPrice * serv.QuoteServQty;
      });
      this.calculateTotals();
    },
    calculateTotals() {
      let sum = 0;
      this.selectedQuote.QuotedServices.forEach((serv) => {
        if (serv.QuoteServRemoved != true) sum += serv.QuoteServTotal;
      });
      this.selectedQuote.QuoteSubTotal = sum;
      this.$log.debug(this.selectedQuote.QuoteSubTotal);
      this.selectedQuote.QuoteTax = (
        (this.selectedQuote.QuoteSubTotal - this.selectedQuote.QuoteDiscount) *
        (this.currentBusiness.BusSalesTaxPercent / 100)
      ).toFixed(2);
      this.$log.debug(this.selectedQuote.QuoteTax);
      this.selectedQuote.QuoteTotal =
        this.selectedQuote.QuoteSubTotal -
        this.selectedQuote.QuoteDiscount +
        parseFloat(this.selectedQuote.QuoteTax);
      this.$log.debug(this.selectedQuote.QuoteTotal);
    },
    cancelProcessing() {
      this.processingQuote = false;
      this.selectedQuote = Quote.query()
        .withAllRecursive()
        .find(this.selectedQuote.QuoteId);
    },
    resetQuote() {
      this.selectedQuote = Quote.query()
        .withAllRecursive()
        .find(this.selectedQuote.QuoteId);
    },
    async doneProcessing() {
      this.savingProcessedQuote = true;
      this.selectedQuote.QuoteProcessedDate = new Date();
      this.selectedQuote.QuoteStatus = "Processed";
      this.selectedQuote.QuoteProcessedBy =
        this.currentCustomer.CustFirstName +
        " " +
        this.currentCustomer.CustLastName;

      await Quote.processQuote(this.selectedQuote).then(async (response) => {
        this.$log.debug(response);
        this.processingQuote = false;
        this.savingProcessedQuote = false;
        this.setBusinessQuotes();
        if (response == 1) {
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.quote"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.quotefailed"),
            life: 3000,
          });
        }
      });
    },
    resetQuoteCust() {
      this.selectedQuote.CustId = 0;
      this.selectedQuote.Cust = null;
      this.showCustDetails = false;
      this.quoteCustEmail = "";
    },
    async searchCustomer() {
      this.showInvalidEmail = false;
      this.selectedQuote.CustId = 0;
      this.selectedQuote.Cust = null;
      this.showCustDetails = false;
      //eslint-disable-next-line
      var regex = /\S+@\S+\.\S+/;
      var validEmail = regex.test(this.quoteCustEmail);
      this.$log.debug(regex.test(this.quoteCustEmail));
      if (validEmail) {
        Customer.fetchFullByEmail(this.quoteCustEmail).then((result) => {
          this.$log.debug(result);
          if (result == null) {
            this.$log.debug("not found");
            this.selectedQuote.CustId = 0;
            this.selectedQuote.Cust = {};
            this.selectedQuote.Cust.CustEmail = this.quoteCustEmail;
            this.showNewCustomer = true;
          } else {
            this.selectedQuote.CustId = result.CustId;
            this.selectedQuote.Cust = result;
            this.showCustDetails = true;
          }
        });
      } else {
        this.showInvalidEmail = true;
      }
    },
    resetNewQuote() {
      this.selectedQuote = {
        QuoteDiscount: 0,
        QuoteTotal: 0,
        QuoteTax: 0,
        QuoteSubTotal: 0,
        QuoteStatus: "Processed",
        CustId: 0,
        Cust: {},
        QuotedServices: [],
        Slot: {},
        QuoteComments: [],
      };
      this.resetQuoteCust();
    },
    async saveNewQuote() {
      if (
        this.selectedQuote.Cust.CustEmail != null &&
        this.selectedQuote.Cust.CustEmail.length > 0
      ) {
        if (
          this.newComment.CommentText != null &&
          this.newComment.CommentText.length > 0
        ) {
          this.newComment.QuoteId = this.selectedQuote.QuoteId;
          this.newComment.CustId = this.currentCustomerId;
          this.newComment.CommentDate = new Date();
          this.selectedQuote.QuoteComments.push(this.newComment);
        }

        this.selectedQuote.BusId = this.currentBusinessId;
        this.loading = true;
        await Quote.createManualQuote(this.selectedQuote).then(
          async (response) => {
            this.$log.debug(response);
            this.setBusinessQuotes();
            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("code.quotesent"),
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("code.quotefailedcancel"),
                life: 3000,
              });
            
            }
            this.loading = false;
              this.resetNewQuote();
              this.createNewQuote = false;
          }
        );

        this.$log.debug(this.selectedQuote);
      }
    },
    async setCurrentCustomer() {
      await Customer.getCurrentCustomer().then((result) => {
        this.$log.debug(result);
        this.currentCustomer = result;
      });
    },
    restoreQuote() {
      this.$confirm.require({
        message: this.$t("quotes.restoreconfirm"),
        header: this.$t("menu.restorequote"),
        icon: "pi pi-calendar-check",
        accept: async () => {
          //callback to execute when user confirms the action
          this.selectedQuote.QuoteProcessedDate = new Date();
          this.selectedQuote.QuoteStatus = "Requested";
          this.selectedQuote.QuoteProcessedBy =
            this.currentCustomer.CustFirstName +
            " " +
            this.currentCustomer.CustLastName;

          await Quote.processQuote(this.selectedQuote).then(
            async (response) => {
              this.$log.debug(response);
              this.setBusinessQuotes();
              this.processingQuote = false;
              this.revisingQuote = true;
              if (response == 1) {
                this.over = true;
              } else {
                this.resetQuote();
              }
            }
          );
        },
        reject: () => {
          this.resetQuote();
        },
      });
    },
    cancelQuote() {
      this.$confirm.require({
        message: this.$t("quotes.cancelconfirm"),
        header: this.$t("menu.cancelquote"),
        icon: "pi pi-calendar-check",
        accept: async () => {
          //callback to execute when user confirms the action
          this.selectedQuote.QuoteProcessedDate = new Date();
          this.selectedQuote.QuoteStatus = "Canceled";
          this.selectedQuote.QuoteProcessedBy =
            this.currentCustomer.CustFirstName +
            " " +
            this.currentCustomer.CustLastName;

          await Quote.processQuote(this.selectedQuote).then(
            async (response) => {
              this.$log.debug(response);
              this.setBusinessQuotes();
              this.processingQuote = false;
              if (response == 1) {
                this.$toast.add({
                  severity: "success",
                  summary: this.$t("custreserv.success"),
                  detail: this.$t("code.quotecanceled"),
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: this.$t("code.errors"),
                  detail: this.$t("code.quotefailedcancel"),
                  life: 3000,
                });
                this.resetQuote();
              }
            }
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    async saveComment() {
      this.newComment.QuoteId = this.selectedQuote.QuoteId;
      this.newComment.CustId = this.currentCustomerId;
      this.newComment.CommentDate = new Date();
      await QuoteComment.addComment(this.newComment).then(async (response) => {
        this.$log.debug(response);
        this.setBusinessQuotes();
        this.newComment = {};
        this.addingComment = false;
       // this.resetQuote();
        
      });
    },
    cancelComment() {
      this.addingComment = false;
      this.newComment = {};
    },
    removeService(servIndex) {
      this.$log.debug(servIndex);
      this.selectedQuote.QuotedServices[servIndex].QuoteServRemoved = true;
    },
    overwriteServices() {
      this.revisingQuote = true;
    },
    cancelOverwrite() {
      this.selectedQuote = Quote.query()
        .withAllRecursive()
        .find(this.selectedQuote.QuoteId);
      this.revisingQuote = false;
    },
    saveOverwrite() {
      this.$confirm.require({
        message: this.$t("code.quoteconfirm"),
        header: this.$t("quotes.revisequote"),
        icon: "pi pi-calendar-check",
        accept: async () => {
          this.savingOverwrite = true;
          await Quote.saveOverwrite(this.selectedQuote).then(
            async (response) => {
              this.setBusinessQuotes();
              this.$log.debug("saved overwrite, reset quote");
              this.$log.debug(response);
              // this.resetQuote();
              this.$log.debug("quote reset");
              this.revisingQuote = false;
              this.savingOverwrite = false;
              // this.setQuoteProcessing();
              // this.calculateServiceTotals();
            }
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
  },
  watch: {
    async currentBusinessId() {
      this.loading = true;
      this.setBusinessQuotes();
    },
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCurrentCustomer();
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
      currentCustomerId: (state) => state.currentCustomerId,
      lang: (state) => state.lang,
    }),

    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style>
.p-datatable-header {
  background-color: white !important;
}
.p-dataview .p-dataview-content {
  background-color: #f8f9fa !important;
}
.removed {
  text-decoration: line-through;
  color: darkgray;
  padding-bottom: 0 !important;
}
</style>
