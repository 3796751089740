// Component: ServiceGridLayout
// Props:
//     - data = service object data
//     - mode = view (removed edit and delete buttons, meant for customer view), edit (adds edit and delete buttons, meant for business admins)
//     - view = full (displays long description, removed More details button). short (hides longs description, shows more details button)

<template>
  <Card
    class="center-text service-card"
    :style="view == 'short' ? 'height: 100%; width: 95%':'width:95%'"
    @click="$emit('view')"
  >
    <template #content>
      <div
        v-if="mode == 'edit' && view == 'short'"
        class="product-grid-item-top flex justify-content-between p-2"
      >
      <div>
        <span
          v-if="data.ServActive == true"
          class=".product-status p-tag p-tag-success  mr-1"
          v-tooltip.right="$t('services.active')"
        >
         <i class="pi pi-eye m-1"></i>
        </span>
        <span
          v-else
          v-tooltip.right="$t('services.inactive')"
          class=".product-status p-tag p-tag-danger mr-1"
        >
         <i class="pi pi-eye-slash m-1"></i>
        </span>
            <span
          v-if="data.ServiceForStore == true"
          class=".product-status p-tag p-tag-info"
         v-tooltip.right="$t('store.serviceforstore')"
        >
          <i class="pi pi-shopping-cart m-1"></i>
        </span>
      </div>
        <div>
          <Button
            icon="pi pi-trash"
            v-tooltip.right="$t('services.deleteservice')"
            class="p-button-raised p-button-danger mr-2"
            @click.stop="deleteService($event)"
          />
          <Button
            icon="pi pi-pencil"
            v-tooltip.right="$t('services.editservice')"
            class="p-button-raised p-button-info ml-auto"
            @click.stop="$emit('edit')"
          />
        </div>
      </div>

      <div v-if="view == 'short'" class="product-grid-item-top flex mb-2">
        <Button
          v-if="
            (!hideFav && !addedFav && !justFav) || (!justFav && justRemoved)
          "
          class="
            ml-auto
            p-button-rounded p-button-sm p-button-text p-button-primary
          "
          icon="pi pi-heart"
          v-tooltip.right="$t('menu.addfav')"
          @click.stop="addToFavorites()"
        ></Button>
        <Button
          v-else-if="(!hideFav && addedFav) || justFav"
          class="ml-auto p-button-success p-button-rounded"
          icon="pi pi-heart"
          v-tooltip.right="$t('menu.removfav')"
          @click.stop="removeFavorite()"
        ></Button>
      </div>
      <!-- -->
      <div class="product-grid-item-content">
        <Galleria
          v-if="
            data.ServiceImages != null &&
            data.ServiceImages.length > 0 &&
            view != 'short'
          "
          :value="data.ServiceImages"
          :fullScreen="isFullScreen"
          :circular="true"
          :autoPlay="true"
          :transitionInterval="3000"
          :responsiveOptions="responsiveOptions"
          :numVisible="5"
          containerStyle="max-width: 100%;"
          :showItemNavigators="true"
           :showThumbnails="false" :showIndicators="true"
        >
          <template #item="slotProps">
            <img
              v-if="slotProps.item"
              :src="imageHostingURL + slotProps.item.BusImage"
              :alt="slotProps.item.BusImageTitle"
              style="width: 100%; display: block"
              class="shadow-8"
            />
          </template>
          <template #thumbnail="slotProps">
            <img
              v-if="slotProps.item"
              :src="imageHostingURL + slotProps.item.BusImage"
              :alt="slotProps.item.BusImageTitle"
              style="width: 50px; heigth: 50px; margin: 5px"
            />
          </template>
        </Galleria>
        <img
          v-else-if="
            data.ServiceImages != null &&
            data.ServiceImages.length > 0 
          "
          :src="imageHostingURL + data.ServiceImages[0].BusImage"
          :alt="data.ServTitle"
          width="100"
        />
        <img
          v-else-if="data.ServImage != null"
          :src="imageHostingURL + data.ServImage"
          :alt="data.ServTitle"
          width="100"
        />

        <div class="product-name">{{ data.ServTitle }}</div>
        <div class="product-description">
          <span v-if="view == 'short'"> {{ data.ServShortDesc }} </span>
          <span
            class="ql-editor"
            v-if="view == 'full'"
            v-html="data.ServHtmldesc"
          ></span>
        </div>
      </div>
    </template>
    <template #footer>
      <Button
        v-if="view == 'short'"
        :label="$t('menu.viewdetails')"
        class="p-button-rounded p-button-primary p-button-sm mb-3"
        @click="$emit('view')"
      />
      <div
        class="product-grid-item-bottom flex flex-column justify-content-center"
      >
        <span class="product-price mb-2 mr-2"
          >${{ data.ServPrice
          }} <span
            v-if="data.ServPriceCalculated == true"
            style="font-size: small"
          >
            {{ $t("quotes.each") }}
          </span></span
        >
        <div class="mb-2">
          <i class="pi pi-tag product-category-icon"></i>
          <span v-if="data.ServiceTypes != null" class="product-category">
            <b>
              <!-- {{ data.ServiceTypes.map((s) => s.ServTypeTitle).join(", ") }} -->
              <template
                v-for="type in data.ServiceTypes"
                :key="type.ServTypeTitle"
              >
                <Chip class="mr-1 mb-1">
                  <service-type-label :serviceType="type.ServTypeTitle"
                /></Chip>
              </template>
            </b>
          </span>
        </div>
        <div
          v-if="
            data.ServPromotionExpires != null &&
            mode == 'view' &&
            view == 'full'
          "
          style="background-color: #d32f2f; color: #ffffff; width: 100%"
        >
          <em
            >{{ $t("services.offer") }}
            {{ $formatDateTimeToDate(data.ServPromotionExpires) }}</em
          >
        </div>
        <div
          v-if="
            data.ServPromotionExpires != null &&
            mode == 'view' &&
            view == 'short'
          "
          style="background-color: #d32f2f; color: #ffffff; width: 100%"
        >
          <em>{{ $t("services.special") }}</em>
        </div>

        <div
          v-if="mode == 'edit' && data.ServPromotionExpires != null"
          style="background-color: #d32f2f; color: #ffffff; width: 100%"
        >
          <em
            ><small
              >{{ $t("services.offer") }}
              {{ $formatDateTimeToDate(data.ServPromotionExpires) }}</small
            ></em
          >
        </div>

        <div
          v-if="
            mode == 'edit' &&
            data.ServPromotionExpires != null &&
            !promotionValid(data.ServPromotionExpires)
          "
        >
          <Message severity="warn" :closable="false">
            <em>{{ $t("services.expiredpromotion") }}</em></Message
          >
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import ServiceTypeLabel from "./ServiceTypeLabel.vue";

export default {
  components: { ServiceTypeLabel },
  name: "ServiceGridLayout",
  props: {
    data: Object,
    mode: { default: "view", type: String },
    view: String,
    hideFav: { default: false, type: Boolean },
    addedFav: { default: false, type: Boolean },
  },
  emits: ["favorite", "view", "delete", "edit", "removefavorite"],
  data() {
    return {
      justFav: false,
      justRemoved: false,
    };
  },
  mounted() {
    this.$log.debug("service-grid-layout mounted");
    this.$log.debug(this.data);
  },
  methods: {
    promotionValid(promotionExpirationDate) {
      if (promotionExpirationDate == null) {
        return true;
      } else {
        return moment().isSameOrBefore(promotionExpirationDate, "day");
      }
    },

    addToFavorites() {
      if (this.currentCustomerId > 0) {
        this.$emit("favorite");
        this.justFav = true;
      } else {
        //this.$RedirectSignIn();
        window.sessionStorage.setItem("redirecturl", this.$route.path);
        window.sessionStorage.setItem("requestedlogin", "true");
        this.$router.push({
          name: "Login",
        });
      }
    },
    removeFavorite() {
      if (this.currentCustomerId > 0) {
        this.$emit("removefavorite");
        this.justFav = false;
        this.justRemoved = true;
      } else {
        window.sessionStorage.setItem("redirecturl", this.$route.path);
        window.sessionStorage.setItem("requestedlogin", "true");
        this.$router.push({
          name: "Login",
        });
      }
    },
    deleteService(event) {
      this.$log.debug("at child delete");
      let self = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t("code.deleteservice"),
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          //callback to execute when user confirms the action
          self.$emit("delete");
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
  },
  computed: {
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
    }),
  },
};
</script>
<style >
.p-confirm-popup {
  z-index: 2000;
}

.service-card .p-card-body {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.service-card .p-card-body .p-card-content {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}
</style>

