<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <div v-if="currentBusiness != null && currentBusiness.BusActive == true && currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode == 'PREMIUM'">
      <DataTable
        :value="staff"
        class="p-datatable-responsive-demo"
        :paginator="true"
        :rows="10"
        ref="dt"
        :resizableColumns="true"
        dataKey="BusStaffId"
        :filters="filters"
        :loading="loading"
      >
        <template #empty>{{ $t("message.staff") }}</template>
        <template #loading> {{ $t("message.loading") }} </template>
        <template #header>
          <div class="grid grid-nogutter">
            <div class="col-6" style="text-align: left">
              <Button
                icon="pi pi-external-link"
                :label="$t('menu.export')"
                @click="exportCSV($event)"
              />
            </div>
            <div class="col-6" style="text-align: right">
              <Button
                :label="$t('message.newstaff')"
                icon="pi pi-plus"
                iconPos="left"
                @click.stop="startStaffCreate"
                class="ml-2 p-button-raised p-button-sm p-button-primary"
                style="margin-bottom: 5px"
              />
            </div>
          </div>
        </template>
        <Column
          field="Cust.CustLastName"
          :header="$t('message.last')"
          :sortable="true"
        >
          <template #body="slotProps">
            <span class="p-column-title">{{ $t("message.last") }}</span>
            <span
              v-if="
                slotProps.data.Cust != null &&
                slotProps.data.Cust.CustLastName != null
              "
              >{{ slotProps.data.Cust.CustLastName }}</span
            >
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustLastName']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column
          field="Cust.CustFirstName"
          :header="$t('message.first')"
          :sortable="true"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.Cust != null &&
                slotProps.data.Cust.CustFirstName != null
              "
              class="pointer"
              @click.stop="
                showCustomerDetails(slotProps.data.Cust.CustFirstName)
              "
              >{{ slotProps.data.Cust.CustFirstName }}</span
            >
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustFirstName']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column
          field="Cust.CustPhone"
          :header="$t('message.phone')"
          :sortable="true"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.Cust != null &&
                slotProps.data.Cust.CustPhone != null
              "
              >{{ slotProps.data.Cust.CustPhone }}</span
            >
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustPhone']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column
          field="Cust.CustActive"
          :header="$t('services.active')"
          :sortable="true"
        >
          <template #body="slotProps">
            <div style="text-align:center">
            <span
              v-if="
                slotProps.data.Cust != null &&
                slotProps.data.Cust.CustActive != null &&
                slotProps.data.Cust.CustActive == true
              "
              >{{ $t("services.active") }}<br>{{formatDateTime(slotProps.data.BusStaffActiveSince)}}</span
            >
            <span
              v-else-if="
                slotProps.data.Cust != null &&
                slotProps.data.Cust.CustActive != null &&
                slotProps.data.Cust.CustActive == false
              "
              >{{ $t("services.inactive") }}<br>{{formatDateTime(slotProps.data.BusStaffInActiveSince)}}</span
            >
            </div>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustActive']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column
          field="Cust.CustEmail"
          :header="$t('message.email')"
          :sortable="true"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.Cust != null &&
                slotProps.data.Cust.CustEmail != null
              "
              >{{ slotProps.data.Cust.CustEmail }}</span
            >
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustEmail']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column field="BusStaffId">
          <template #body="slotProps">
            <Button
              icon="pi pi-trash"
              v-tooltip.right="'Delete Staff'"
              class="p-button-raised p-button-danger mr-2"
              @click.stop="deleteBusStaff($event, slotProps.data.BusStaffId)"
            />
          </template>
        </Column>
      </DataTable>
      <Dialog
        v-model:visible="showNewStaff"
        :style="{ width: '90vw' }"
        :modal="true"
        :header="$t('message.newstaff')"
        :dismissableMask="true"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <p>
          {{ $t("message.staffmessage") }}
        </p>
        <div class="p-fluid formgrid grid text-left">
          <div class="field col-12">
            <label for="email">{{ $t("message.email") }}</label>
            <InputText
              id="email"
              type="text"
              v-model="newStaffEmail"
              placeholder="something@something.com"
            />
          </div>
        </div>
        <template #footer>
          <Button
            :label="$t('profile.cancel')"
            icon="pi pi-times"
            @click.stop="resetNewStaffEmail"
            class="p-button-secondary"
          />
          <Button
            :label="$t('profile.save')"
            icon="pi pi-check"
            @click.stop="submitNewStaffEmail"
            autofocus
          />
        </template>
      </Dialog>
    </div>
    <div v-else-if="currentBusiness.BusActive == false">
      <inactive-subscription :busId="currentBusiness.BusId"></inactive-subscription>
    </div>
    <div v-else-if="currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode != 'PREMIUM'">
     <!-- <div v-else-if="true"> -->
      <upgrade-subscription
        :subId="currentSubscription.SubId"
      ></upgrade-subscription>
    </div>
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{ $t("payments.error") }}</Message
      >
    </div>
  </div>
</template>

<script>
import BusinessStaff from "../store/Models/BusinessStaff";
import Business from "../store/Models/Business";
import moment from "moment";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import InactiveSubscription from '../components/InactiveSubscription.vue'; 
import { mapState } from "vuex";
import UpgradeSubscription from '../components/UpgradeSubscription.vue';
import Subscription from '../store/Models/Subscription';

export default {
  name: "BusinessClients",
  components: { LoadingOverlay, InactiveSubscription, UpgradeSubscription},
  data() {
    return {
      currentBusiness: {},
      showCustDialog: false,
      showNewStaff: false,
      newStaffEmail: "",
      selectedCust: {},
      currentSubscription:{},
      customers: [],
      filters: {},
      loading: false,
      statuses: ["Processed", "Pending"],
    };
  },
  async mounted() {
    this.$log.debug("business-customers-mounted");
    this.$log.debug(this.currentBusinessId);
    if (this.currentBusinessId > 0) {
      await this.setBusinessStaff();
    }
  },
  methods: {
    formatDateTime(datetime) {
      return moment(datetime).format("MM/DD/YY hh:mm A");
    },
    startStaffCreate() {
      this.showNewStaff = true;
    },
    resetNewStaffEmail() {
      this.newStaffEmail = "";
      this.showNewStaff = false;
    },
    submitNewStaffEmail() {
      BusinessStaff.addStaff(this.currentBusinessId, this.newStaffEmail).then(
        (response) => {
          this.$log.debug(response);
          this.setBusinessStaff();
          if (response == 1) {
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("message.addedstaff"),
              life: 3000,
            });
          } else if (response == 3) {
            this.$toast.add({
              severity: "warn",
              summary: "Warning!",
              detail: this.$t("message.staffexist"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
            summary: this.$t("code.errors"),
              detail: this.$t("message.stafffailedadd"),
              life: 3000,
            });
          }
        }
      );

      this.resetNewStaffEmail();
    },
    deleteBusStaff(event, busStaffId) {
      this.$log.debug("at delete bus staff");
      this.$log.debug(busStaffId);
      this.$confirm.require({
        header: this.$t("code.confirm"),
        message: this.$t("message.staffremove"),
        icon: "pi pi-exclamation-triangle",
        target: event.currentTarget,
        accept: () => {
          //callback to execute when user confirms the action
          BusinessStaff.deleteBusStaff(busStaffId).then((response) => {
            this.$log.debug(response);
            this.setBusinessStaff();
            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("message.staffremoved"),
                life: 3000,
              });
            
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error!",
                detail: this.$t("message.stafffailed"),
                life: 3000,
              });
            }
          });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showCustDetails(cust) {
      this.selectedCust = cust;
      this.showCustDialog = true;
    },
    async setBusinessStaff() {
      this.loading = true;
      Business.fetchBusinessProfileById(this.currentBusinessId).then(
        (result) => {
          this.currentBusiness = result;
        }
      );
      Subscription.fetchCurrentByBusiness(this.currentBusinessId).then((result) => {
          this.currentSubscription = result;
         //console.log(this.currentSubscription)
        });
      await BusinessStaff.fetchByBusId(this.currentBusinessId).then(
        (result) => {
          this.$log.debug(result);
          this.staff = result;
        }
      );
      this.loading = false;
    },
  },
  watch: {
    async currentBusinessId() {
      this.setBusinessStaff();
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
    }),
  },
};
</script>
<style scoped>
.p-column-filter {
  width: 100%;
  z-index: 1000;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 60em) {
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-thead
    > tr
    > th,
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}
</style>