
/* eslint-disable */

import { Model } from '@vuex-orm/core'
import Reservation from './Reservation'
import Quote from './Quote'
import CustEvent from './CustEvent'


export default class CustEventService extends Model {
  static entity = 'custeventservice'

  static primaryKey = 'ServiceId'

  static fields() {
    return {
      ServiceId: this.uid(),
      EventId: this.attr(null),
      ReservId: this.attr(null),
      QuoteId: this.attr(null),
      ServiceCategory: this.attr(null),
      ServiceTitle: this.attr(null),
      ServiceBudget: this.attr(null),
      ServiceTotal: this.attr(null),
      ServiceTotalPaid: this.attr(null),
      ServiceTotalOwed: this.attr(null),
      VendorName: this.attr(null),
      VendorAddress: this.attr(null),
      VendorPhone: this.attr(null),
      //VIRTUAL
      
      Event: this.belongsTo(CustEvent, 'EventId'),
      Quote: this.belongsTo(Quote, 'QuoteId'),
      Reserv: this.belongsTo(Reservation,"ReservId"),
      

    }
  }
  // static async createCustEvent(custEvent) {

  //  //console.log("creating new cust event")
  //   const result = await CustEvent.api().post('/api/CustEvents/',
  //     custEvent).then((result) => {
  //       if (result.response.status == 201) {
  //         //console.log("success")
  //         return 1;
  //       }
  //       if (result.response.status == 200) {
  //         //console.log("duplicate")
  //         return 1;
  //       }
  //       else {
  //         //console.log("error");
  //         return 2;
  //       }
  //     }).catch(error => {
  //       //console.log(error.response);
  //       return 2;
  //     });
  //   return result;
  // }

  // static async updateCustEvent(custevent) {
  //   //console.log("updating customer event)
  //   const result = await CustEvent.api().put('/api/CustEvents/' + custevent.ListId,
  //   custevent).then((result) => {
  //       if (result.response.status == 204) {
  //         //console.log("success")
  //         return 1;
  //       }
  //       if (result.response.status == 200) {
  //         //console.log("saved")
  //         return 1;
  //       }
  //       else {
  //         //console.log("error");
  //         return 2;
  //       }
  //     }).catch(error => {
  //       //console.log(error.response);
  //       return 2;
  //     });
  //   return result;
  // }

  // static async fetchByCustId(CustId) {
  //   //CustEvent.deleteAll();
  //   //GuestParty.deleteAll();
  //  //SharedGuestList.deleteAll();
  //  //console.log("fetch guest lists for " + CustId)
  //   var result = await CustEvent.api().get('/api/CustEvent/GetCustomerCustEvents/' + CustId);
  //  //console.log(result)
  //   return CustEvent.query()
  //     .withAllRecursive()
  //     .orderBy('EventId', 'desc').all();
  // }

  // static async deleteCustEvent(id) {
  //   //console.log("delete guest list " + id)
  //   var result = await CustEvent.api().delete('/api/CustomerEvent/' + id).then((result) => {
  //     if (result.response.status == 204) {
  //       //console.log("success")
  //       return 1;
  //     }
  //     if (result.response.status == 200) {
  //       //console.log("duplicate")
  //       return 1;
  //     }
  //     else {
  //       //console.log("error");
  //       return 2;
  //     }
  //   }).catch(error => {
  //     //console.log(error.response);
  //     return 2;
  //   });
  //   return result;
  // }


}

