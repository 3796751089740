// Component: Business Details
// Props:
//     - currentBusiness = business object

<template>
  <div
    v-if="
      currentBusiness != null &&
      currentBusiness.BusActive == true &&
      currentBusiness.BusVisible == true
    "
  >
    <div class="freeze" style="background-color: #f8f9fa">
      <div
        class="
          flex flex-wrap
          justify-content-center
          align-items-center
          text-center
          pt-2
        "
      >
        <img
          :src="imageHostingURL + currentBusiness.BusLogo"
          :alt="currentBusiness.BusName"
          style="max-height: 100px; max-width: 100px"
          class="mr-3"
        />

        <div
          class="flex flex-wrap align-items-center justify-content-center"
          style="font-size: xx-large"
        >
          <span>{{ currentBusiness.BusName }}</span>
        </div>
      </div>
      <!-- MD LG SCREEN -->
      <div class="hidden md:flex flex-wrap align-items-center justify-content-center">
        <!-- <div class="flex flex-wrap align-items-center justify-content-center"> -->
          <Button
            v-if="!hideFav && !busFav && !justFav"
            icon="pi pi-heart"
            :label="$t('code.saveasfavorite')"
            class="p-button-primary p-button-text p-button-sm m-2"
            @click.stop="addBusFavorite(currentBusiness.BusId)"
          ></Button>
          <Button
            v-else-if="(!hideFav && busFav) || justFav"
            icon="pi pi-heart"
            label="Remove Favorite"
            class="p-button-primary p-button-text p-button-sm m-2"
            @click.stop="removeFavorite(currentBusiness.BusId)"
          ></Button>
          <Button
            v-if="!showAddToQuote && currentBusiness.BusAcceptQuotes == true"
            icon="pi pi-dollar"
            :label="$t('quotes.startquote')"
            class="p-button-primary p-button-text p-button-sm m-2"
            @click.stop="startQuote()"
          />
          <Button
            v-if="showAddToQuote"
            icon="pi pi-dollar"
            :label="$t('quotes.startquote')"
            class="p-button-primary p-button-text p-button-sm m-2"
            disabled
          />
          <Button
          v-if="currentBusiness.BusTourSchdedulingEnabled == true"
            icon="pi pi-map-marker"
            :label="$t('code.bookTour')"
            class="p-button-primary p-button-text p-button-sm m-2"
            @click.stop="bookTour()"
          />
          <Button
          v-if="currentBusiness.BusApptSchedulingEnabled == true"
            icon="pi pi-calendar-plus"
            :label="$t('code.schappt')"
            class="p-button-primary p-button-text p-button-sm m-2"
            @click.stop="schCustAppt()"
          />
        <!-- </div> -->
      </div>
      <div class="md:hidden flex flex-wrap align-items-center justify-content-center">
        <Button
          icon="pi pi-bars"
          class="p-button-primary p-button-text p-button-sm m-2"
          @click.stop="showActionsMenu"
          label="MENU"
          aria-haspopup="true"
          aria-controls="overlay_menu"
        ></Button>
        <OverlayPanel ref="mnuOverlay">
          <div class="flex flex-column justify-content-start">
            <Button
              v-if="!hideFav && !busFav && !justFav"
              icon="pi pi-heart"
              :label="$t('code.saveasfavorite')"
              class="p-button-primary p-button-text p-button-sm text-left"
              @click.stop="
                addBusFavorite(currentBusiness.BusId);
                showActionsMenu();
              "
            ></Button>
            <Button
              v-else-if="(!hideFav && busFav) || justFav"
              icon="pi pi-heart"
              :label="$t('menu.removefav')"
              class="p-button-primary p-button-text p-button-sm text-left"
              @click.stop="
                removeFavorite(currentBusiness.BusId);
                showActionsMenu();
              "
            ></Button>
            <Button
              v-if="!showAddToQuote && currentBusiness.BusAcceptQuotes == true"
              icon="pi pi-shopping-cart"
              :label="$t('quotes.startquote')"
              class="p-button-primary p-button-text p-button-sm text-left"
              @click.stop="
                startQuote();
                showActionsMenu();
              "
            />
            <Button
              v-if="showAddToQuote"
              icon="pi pi-shopping-cart"
              :label="$t('quotes.startquote')"
              class="p-button-primary p-button-text p-button-sm text-left"
              disabled
            />
            <Button
            v-if="currentBusiness.BusTourSchdedulingEnabled == true"
              icon="pi pi-map-marker"
              :label="$t('code.bookTour')"
              class="p-button-primary p-button-text p-button-sm text-left"
              @click.stop="
                bookTour();
                showActionsMenu();
              "
            />
            <Button
             v-if="currentBusiness.BusApptSchedulingEnabled == true"
              icon="pi pi-calendar-plus"
              :label="$t('code.schappt')"
              class="p-button-primary p-button-text p-button-sm text-left"
              @click.stop="
                schCustAppt();
                showActionsMenu();
              "
            />
          </div>
        </OverlayPanel>
      </div>
    </div>
    <TabView v-model:activeIndex="active">
      <TabPanel>
        <template #header>
          <i class="pi pi-bookmark"></i>
          <span> {{ $t("details.overview") }}</span>
        </template>

        <div
          class="
            flex
            justify-content-center
            align-items-center
            flex-wrap
            md:hidden
            p-2
          "
          style="background-color: #f8f9fa"
        >
          <a class="mr-3" href="#about">{{ $t("footer.about") }}</a>
          <a class="mr-3" href="#contact">{{ $t("footer.contact") }}</a>
          <a class="" href="#gallery">{{ $t("footer.gallery") }}</a>
        </div>
        <div class="grid mt-2">
          <div class="col-12 md:col-6 lg:col-6 text-left">
            <Panel id="about" :header="$t('footer.about')" class="shadow-7">
              <p>{{ currentBusiness.BusDesc }}</p>
              <p>
                <em
                  ><i class="pi pi-tag"></i>
                  <b>{{ $t("details.bustype") }}: </b>
                  <service-type-label
                    :serviceType="currentBusiness.BusCategory"
                  />
                  <!-- {{ currentBusiness.BusCategory }} -->
                </em>
              </p>
              <p>
                <em
                  >
                  <div v-if="currentBusiness.BusCategory !='Venue'">

                  <i class="pi pi-tag"></i>
                  <b> {{ $t("services.location") }}: </b>
                  <span v-if="currentBusiness.BusinessServiceLocations != null && BusCategory !='Venue'" >
                    <!-- {{
                      currentBusiness.EventTypes.map(
                        (s) => s.EventTypeLabel
                      ).join(", ")
                    }} -->
                   
                    
                  
                    <template
                    v-for="loc in currentBusiness.BusinessServiceLocations"
                      :key="loc.BusServLocId"
                    >
                      <Chip class="m-1"
                      
                      >
                        {{ loc.Location.LocationCity}},{{ loc.Location.LocationStateCode }}
                        
                        
                        </Chip>
                    </template> 
                    </span
                >
              </div>
                </em>
              </p>
              <p>
                <em
                  ><i class="pi pi-tags"></i
                  ><b>{{ $t("details.eventtype") }}: </b>
                  <span v-if="currentBusiness.EventTypes != null">
                    <!-- {{
                      currentBusiness.EventTypes.map(
                        (s) => s.EventTypeLabel
                      ).join(", ")
                    }} -->
                    <template
                      v-for="type in currentBusiness.EventTypes"
                      :key="type.EventTypeLabel"
                    >
                      <Chip class="m-1">
                        <event-type-label :eventType="type.EventTypeLabel"
                      /></Chip>
                    </template> </span
                ></em>
              </p>
              <p v-if="currentBusiness.BusOccupancyDisabled != true">
                <em
                  ><i class="pi pi-users"></i> <b>{{ $t("details.max") }}: </b>
                  {{ currentBusiness.BusOccupancy }}</em
                >
              </p>
              <p>
                <em
                  ><i class="pi pi-users"></i><b>{{ $t("details.party") }}: </b>

                  <span v-if="currentBusiness.AttendeeCounts != null">
                    {{
                      currentBusiness.AttendeeCounts.map(
                        (s) => s.AttendeeCountLabel
                      ).join(", ")
                    }}</span
                  ></em
                >
              </p>
              <p>
                <label
                  class="pointer button-text"
                  @click.stop="showServicePolicy = true"
                >
                  {{ $t("businessprofile.viewsla") }}</label
                >
              </p>
            </Panel>
            <br />
            <Panel
              id="contact"
              :header="$t('footer.contactus')"
              class="shadow-8"
            >
              <h4 v-if="currentBusiness.BusPhone != null">
                <i class="pi pi-mobile"></i>
                <a :href="'tel: ' + currentBusiness.BusPhone">{{
                  currentBusiness.BusPhone
                }}</a>
              </h4>
              <h4 v-if="currentBusiness.BusEmail != null">
                <i class="pi pi-envelope"></i>
                <a :href="'mailto:' + currentBusiness.BusEmail">{{
                  currentBusiness.BusEmail
                }}</a>
              </h4>
              <h4
                v-if="
                  currentBusiness.BusAddress != null &&
                  currentBusiness.BusCity != null &&
                  currentBusiness.BusZip != null &&
                  currentBusiness.BusState != null
                "
              >
                <i class="pi pi-map-marker"></i>
                <a
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    currentBusiness.BusAddress +
                    '+' +
                    currentBusiness.BusCity +
                    '+' +
                    currentBusiness.BusState +
                    '+' +
                    currentBusiness.BusZip
                  "
                  target="_blank"
                  >{{ currentBusiness.BusAddress }},
                  {{ currentBusiness.BusCity }}, {{ currentBusiness.BusState }},
                  {{ currentBusiness.BusZip }}</a
                >
              </h4>

              <h4>
                <span v-if="currentBusiness.BusWebUrl != null">
                  <a
                    :href="currentBusiness.BusWebUrl"
                    target="_blank"
                    v-tooltip.bottom="'Visit us on the web!'"
                    ><i
                      class="pi pi-globe"
                      style="font-size: x-large; color: var(--mr-primary-color)"
                    ></i
                  ></a>
                </span>
                &nbsp;
                <span
                  v-if="
                    currentBusiness.BusFbUrl != null &&
                    currentBusiness.BusFbUrl.length > 0
                  "
                >
                  <a
                    :href="
                      'https://www.facebook.com/' + currentBusiness.BusFbUrl
                    "
                    target="_blank"
                    v-tooltip.bottom="'Visit us on FB!'"
                    ><i
                      class="pi pi-facebook"
                      style="font-size: x-large; color: var(--mr-primary-color)"
                    ></i
                  ></a>
                </span>
                &nbsp;
                <span
                  v-if="
                    currentBusiness.BusTwitUrl != null &&
                    currentBusiness.BusTwitUrl.length > 0
                  "
                >
                  <a
                    :href="'https://twitter.com/' + currentBusiness.BusTwitUrl"
                    target="_blank"
                    v-tooltip.bottom="'Visit us on Twitter!'"
                    ><i
                      class="pi pi-twitter"
                      style="font-size: x-large; color: var(--mr-primary-color)"
                    ></i
                  ></a>
                </span>
                &nbsp;
                <span
                  v-if="
                    currentBusiness.BusInstUrl != null &&
                    currentBusiness.BusInstUrl.length > 0
                  "
                >
                  <a
                    :href="
                      'https://www.instagram.com/' + currentBusiness.BusInstUrl
                    "
                    target="_blank"
                    v-tooltip.bottom="'Visit us on Instagram!'"
                    ><i
                      class="pi pi-instagram"
                      style="font-size: x-large; color: var(--mr-primary-color)"
                    ></i
                  ></a>
                </span>
                &nbsp;
                <span
                  v-if="
                    currentBusiness.BusPinUrl != null &&
                    currentBusiness.BusPinUrl.length > 0
                  "
                >
                  <a
                    :href="
                      'https://www.pinterest.com/' + currentBusiness.BusPinUrl
                    "
                    target="_blank"
                    v-tooltip.bottom="'Visit us on Pinterest!'"
                    ><i
                      class="pi pi-images"
                      style="font-size: x-large; color: var(--mr-primary-color)"
                    ></i
                  ></a>
                </span>
              </h4>
            </Panel>
          </div>
          <div class="col-12 md:col-6 lg:col-6">
            <Panel id="gallery" :header="$t('footer.gallery')" class="shadow-7">
              <template #icons>
                <button
                  v-if="!isFullScreen"
                  v-tooltip.bottom="'Full Screen'"
                  class="p-panel-header-icon p-link mr-2"
                  @click="isFullScreen = true"
                >
                  <span class="pi pi-window-maximize"></span>
                </button>
                <button
                  v-if="isFullScreen"
                  class="p-panel-header-icon p-link mr-2"
                  @click="isFullScreen = false"
                >
                  <span class="pi pi-window-minimize"></span>
                </button>
              </template>
              <Galleria
                :value="currentBusiness.BusinessImages"
                :fullScreen="isFullScreen"
                :circular="true"
                :autoPlay="true"
                :transitionInterval="3000"
                :responsiveOptions="responsiveOptions"
                :numVisible="5"
                containerStyle="max-width: 100%;"
                :showItemNavigators="true"
              >
                <template #item="slotProps">
                  <img
                    :src="imageHostingURL + slotProps.item.BusImage"
                    :alt="slotProps.item.BusImageCaption"
                    style="width: 100%"
                    class="shadow-8"
                  />
                </template>
                <template #thumbnail="slotProps">
                  <img
                    :src="imageHostingURL + slotProps.item.BusImage"
                    :alt="slotProps.item.BusImageCaption"
                    style="width: 50px; heigth: 50px; margin: 5px"
                  />
                </template>
              </Galleria>
            </Panel>
          </div>
        </div>
        <Galleria
          :value="currentBusiness.BusinessImages"
          :responsiveOptions="responsiveOptions2"
          :numVisible="9"
          containerStyle="max-width: 50%"
          :circular="true"
          :fullScreen="true"
          :showItemNavigators="true"
          v-model:visible="isFullScreen"
        >
          <template #item="slotProps">
            <img
              :src="imageHostingURL + slotProps.item.BusImage"
              :alt="slotProps.item.alt"
              style="width: 100%; display: block"
            />
          </template>
          <template #thumbnail="slotProps">
            <img
              :src="imageHostingURL + slotProps.item.BusImage"
              :alt="slotProps.item.alt"
              style="width: 50px; heigth: 50px; margin: 5px"
            />
          </template>
        </Galleria>
      </TabPanel>
      <TabPanel v-if="currentBusiness.BusDisplayCalendar == true">
        <template #header>
          <i class="pi pi-calendar"></i>
          <span> {{ $t("details.available") }}</span>
        </template>
        <!-- <FullCalendar
          :events="currentBusiness.CalendarEvents"
          :options="options"
        /> -->
        <div v-if="currentBusiness.BusDisplayCalendar == true">
          <fieldset class="mb-2 text-center">
            <legend>KEY</legend>
            <Chip
              :label="$t('calendar.reserved')"
              class="m-1 primary-chip chip-sm"
            />
            <Chip
              :label="$t('quotes.requestedLabel')"
              class="m-1 warning-chip chip-sm"
            />
            <Chip
              :label="$t('calendar.available')"
              class="m-1 success-chip chip-sm"
            />
          </fieldset>
          <vue-cal
            :events="allSlots"
            small
            style="height: 500px"
            :locale="lang"
            active-view="month"
            :twelve-hour="twelveHr"
            :disable-views="['years', 'week', 'day']"
            @ready="fetchEvents()"
            @view-change="fetchEvents()"
            events-count-on-year-view
            events-on-month-view="true"
            @event-click="onSelectedDate"
          >
            <!-- @cell-click="dateClick($event)" -->
            <template v-slot:event="{ event, view }">
              <!-- <v-icon>{{ event.icon }}</v-icon> -->

              <!-- <div class="vuecal__event-title"> -->
              <!-- <span
                  v-if="event.title != 'Available' && event.title != 'Hidden'"
                  >{{ event.title }}</span
                > -->
              <!-- </div> -->
              <!-- v-html="event.title" -->
              <!-- Or if your events are editable: -->
              <!-- <div
                class="vuecal__event-title vuecal__event-title--edit"
                contenteditable
                @blur="event.title = $event.target.innerHTML"
                v-html="event.title"
              /> -->

              <span class="vuecal__event-time" v-if="view == 'month'">
                <span class="show-on-desktop">
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</span
                >
                <small class="show-on-mobile">
                  {{ event.start.formatTime("h:mm am") }}
                </small>
              </span>
              <span class="vuecal__event-time" v-if="view == 'day'">
                <small class="">
                  {{ event.title }}<br />
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</small
                >
              </span>
            </template>
            <!-- <template v-slot:events-count="{ events }">
              <span v-if="customEventsCount(events)">
                {{ customEventsCount(events) }}
              </span>
            </template> -->
          </vue-cal>
        </div>
        <div v-else>
          <Message severity="warn" :closable="false">{{
            $t("calendar.public")
          }}</Message>
        </div>
      </TabPanel>
      <TabPanel v-if="currentBusiness.BusDisplayServices == true">
        <template #header>
          <i class="pi pi-th-large"></i>
          <span> {{ $t("businessprofile.packages") }}</span>
        </template>
        <div v-if="currentBusiness.BusDisplayServices != true">
          <Message severity="warn" :closable="false">{{
            $t("calendar.packages")
          }}</Message>
        </div>
        <div v-else>
          <div
            class="
              flex flex-column
              p-flex-lg-row
              align-items-center
              justify-content-evenly
            "
          >
            <div v-if="showAddToQuote" class="">
              <b>{{ $t("quotes.startedrequest") }}</b
              ><br /><em>
                {{ servicesForQuote.length }}
                {{ $t("quotes.added") }} &nbsp;</em
              >
            </div>
            <div v-if="showAddToQuote" class="flex justify-content-between">
              <Button
                icon="pi pi-times"
                :label="$t('profile.cancel')"
                class="p-button-danger p-button-sm m-1"
                @click.stop="
                  resetQuote();
                  showAddToQuote = false;
                "
              />
              <Button
                icon="pi pi-refresh"
                :label="$t('menu.reset')"
                class="p-button-secondary p-button-sm m-1"
                @click.stop="resetQuote()"
              />
              <Button
                v-if="!servicesForQuote.length > 0"
                icon="pi pi-check"
                :label="$t('menu.submit')"
                disabled
                class="p-button-success p-button-sm m-1"
              />
              <Button
                v-if="servicesForQuote.length > 0"
                icon="pi pi-check"
                :label="$t('menu.submit')"
                class="p-button-success p-button-sm m-1"
                @click.stop="showDateDialog = true"
              />
            </div>
          </div>
          <Message v-if="showAddToQuote == true" severity="info" :sticky="true"
            ><small>{{ $t("quotes.addpackages") }}</small></Message
          >
          <DataView
            :value="currentBusiness.Services"
            :layout="layout"
            :paginator="true"
            :rows="9"
            :sortOrder="sortOrder"
            :sortField="sortField"
            class="service-grid"
          >
            <template #header>
              <div class="grid grid-nogutter">
                <div class="col-12 md:col-3" style="text-align: left">
                  <Dropdown
                    v-model="sortKey"
                    class="mr-2"
                    :options="sortOptions"
                    optionLabel="label"
                    :placeholder="$t('services.sort')"
                    @change="onSortChange($event)"
                  />
                </div>

                <div class="col-12 md:col-9" style="text-align: right">
                  <Button
                    v-if="
                      !showAddToQuote && currentBusiness.BusAcceptQuotes == true
                    "
                    icon="pi pi-dollar"
                    :label="$t('quotes.startquote')"
                    class="p-button-primary p-button-raised p-button-sm m-2"
                    @click.stop="startQuote()"
                  />
                  <Button
                    v-if="showAddToQuote"
                    icon="pi pi-dollar"
                    :label="$t('quotes.startquote')"
                    class="p-button-primary p-button-raised p-button-sm m-2"
                    disabled
                  />
                </div>
              </div>
            </template>
            <template #grid="slotProps">
              <div
                v-if="
                  slotProps.data.ServActive == true &&
                  slotProps.data.ServDeleted == false &&
                  promotionValid(slotProps.data.ServPromotionExpires)
                "
                class="col-12 md:col-4 lg:col-3 m-2"
              >
                <!-- <service-grid-layout
                  :data="slotProps.data"
                  :addedFav="
                    currentCustomer.ServiceFavorites != null &&
                    currentCustomer.ServiceFavorites.filter(
                      (fav) => fav.ServId == slotProps.data.ServId
                    ).length > 0
                  "
                  :mode="'view'"
                  :view="'short'"
                  v-on:view="viewDetails(slotProps.data)"
                  v-on:favorite="addServFavorite(slotProps.data.ServId)"
                  v-on:removefavorite="
                    removeServFavorite(slotProps.data.ServId)
                  "
                /> -->
                <Card
                  class="mb-2 mr-2"
                  v-if="slotProps.data != null"
                  style="
                    background-color: white !important;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                  "
                >
                  <template v-slot:content>
                    <div class="product-grid-item-top text-right">
                      <Button
                        v-if="
                          currentCustomer != null &&
                          currentCustomer.ServiceFavorites != null &&
                          currentCustomer.ServiceFavorites.filter(
                            (fav) => fav.ServId == slotProps.data.ServId
                          ).length > 0
                        "
                        v-tooltip.right="$t('menu.removfav')"
                        icon="pi pi-check"
                        class="p-button-success p-button-lg p-button-text"
                        @click.stop="removeServFavorite(slotProps.data.ServId)"
                      ></Button>
                      <Button
                        v-else
                        class="p-button-sm p-button-primary"
                        icon="pi pi-heart"
                        v-tooltip.right="$t('menu.removfav')"
                        @click.stop="addServFavorite(slotProps.data.ServId)"
                      ></Button>
                    </div>
                    <div class="product-grid-item-content mt-2">
                      <!-- <img
                        v-if="slotProps.data.ServImage != null"
                        :src="imageHostingURL + slotProps.data.ServImage"
                        :alt="slotProps.data.ServTitle"
                        width="120"
                        length="120"
                        class="mr-3"
                      /> -->
                      <Galleria
                        v-if="
                          slotProps.data.ServiceImages != null &&
                          slotProps.data.ServiceImages.length > 0
                        "
                        :value="slotProps.data.ServiceImages"
                        :fullScreen="isFullScreen"
                        :circular="true"
                        :transitionInterval="3000"
                        :responsiveOptions="responsiveOptions"
                        :numVisible="5"
                        containerStyle="max-width: 100%;"
                        :showItemNavigators="true"
                        :showThumbnails="false"
                        :showIndicators="true"
                      >
                        <template #item="slotprops">
                          <img
                            v-if="slotprops.item"
                            :src="imageHostingURL + slotprops.item.BusImage"
                            :alt="slotprops.item.BusImageTitle"
                            style="width: 100%; display: block"
                            class="shadow-8"
                          />
                        </template>
                        <template #thumbnail="slotprops">
                          <img
                            v-if="slotprops.item"
                            :src="imageHostingURL + slotprops.item.BusImage"
                            :alt="slotprops.item.BusImageTitle"
                            style="width: 50px; heigth: 50px; margin: 5px"
                          />
                        </template>
                      </Galleria>
                      <img
                        v-else-if="slotProps.data.ServImage != null"
                        :src="imageHostingURL + slotProps.data.ServImage"
                        :alt="slotProps.data.ServTitle"
                        width="120"
                        length="120"
                      />

                      <div class="product-name">
                        {{ slotProps.data.ServTitle }}
                      </div>
                      <div class="product-description">
                        {{ slotProps.data.ServShortDesc }} <br />

                        <div class="mt-2 mb-2">
                          <i class="pi pi-tag product-category-icon"></i>
                          <span
                            v-if="slotProps.data.ServiceTypes != null"
                            class="product-category"
                          >
                            <!-- {{
                            slotProps.data.Serv.ServiceTypes.map(
                              (s) => s.ServTypeTitle
                            ).join(", ")
                          }}     -->
                            <template
                              v-for="type in slotProps.data.ServiceTypes"
                              :key="type.ServTypeTitle"
                            >
                              <Chip class="m-1">
                                <service-type-label
                                  :serviceType="type.ServTypeTitle"
                              /></Chip>
                            </template>
                          </span>
                        </div>
                        <Button
                          :label="$t('menu.moredetails')"
                          class="
                            p-button-raised
                            p-button-rounded
                            p-button-primary
                            p-button-sm
                            m-2
                          "
                          style="margin-top: 2px"
                          @click="viewDetails(slotProps.data)"
                        />
                      </div>
                    </div>
                    <div
                      class="
                        product-grid-item-bottom
                        flex flex-wrap
                        justify-content-evenly
                      "
                    >
                      <span class="product-price mb-2 mr-2"
                        >${{ slotProps.data.ServPrice
                        }}<span
                          v-if="slotProps.data.ServPriceCalculated == true"
                          style="font-size: small"
                        >
                          each &nbsp;
                        </span></span
                      >

                      <Tag
                        class="mb-2 mr-2"
                        v-if="slotProps.data.ServPromotionExpires != null"
                        severity="danger"
                        value="Danger"
                        style="margin-top: 5px"
                      >
                        <em>{{ $t("services.special") }}</em>
                      </Tag>
                    </div>

                    <div class="flex justify-content-center">
                      <div
                        v-if="
                          showAddToQuote &&
                          currentBusiness.BusAcceptQuotes == true
                        "
                      >
                        <div v-if="slotProps.data.ServPriceCalculated">
                          <label>{{ $t("menu.qty") }}:&nbsp;</label>
                          <InputNumber
                            v-if="
                              servicesForQuote.filter(
                                (val) => val == slotProps.data.ServId
                              ).length == 0
                            "
                            class="mb-3"
                            v-model="
                              servicesForQuoteQty[
                                getIndex(slotProps.data.ServId)
                              ].Qty
                            "
                            inputStyle="width:50px"
                          />
                          <InputNumber
                            v-else
                            disabled
                            class="mb-3"
                            v-model="
                              servicesForQuoteQty[
                                getIndex(slotProps.data.ServId)
                              ].Qty
                            "
                            inputStyle="width:50px"
                          />
                        </div>
                        <Button
                          v-if="
                            servicesForQuote.filter(
                              (val) => val == slotProps.data.ServId
                            ).length == 0
                          "
                          icon="pi pi-plus"
                          :label="$t('menu.addquote')"
                          class="p-button-raised p-button-info p-button-sm"
                          @click.stop="addToQuote(slotProps.data)"
                        />
                        <Button
                          v-if="
                            servicesForQuote.filter(
                              (val) => val == slotProps.data.ServId
                            ).length > 0
                          "
                          icon="pi pi-minus"
                          :label="$t('quotes.remove')"
                          class="
                            p-button-raised
                            p-button-danger
                            p-button-text
                            p-button-sm
                          "
                          @click.stop="removeFromQuote(slotProps.data)"
                        />
                      </div>
                      <!--  <InlineMessage
                        v-if="currentBusiness.BusAcceptQuotes != true"
                        severity="warn"
                        ><small
                          ><b>{{ $t("quotes.sorry") }}</b></small
                        ></InlineMessage> -->
                    </div>
                  </template>
                </Card>
              </div>
            </template>
          </DataView>
  
          <Dialog
            v-model:visible="showSelectedDate"
            :modal="true"
            :contentStyle="{ overflow: 'scroll' }"
            style="text-align: center"
            :dismissableMask="true"
            :style="{ width: '50vw' }"
            :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
          >
          <div v-if="selectedCalEvent != null">
            <div v-if="selectedCalEvent.available == true">
              {{ $t('code.dateavailable') }}
            <div><label>{{ $formatDateTime(selectedCalEvent.start) }}</label></div>
              <div class="flex flex-wrap justify-content-around mt-3">
            <Button
              icon="pi pi-times"
              class="p-button-secondary  p-button-sm "
              :label="$t('menu.notyet')"
              @click.stop="showSelectedDate=false"
              
            ></Button>
            <Button
              icon="pi pi-check"
              class="p-button-primary  p-button-sm "
              :label="$t('menu.yes')"
              @click.stop="showSelectedDate=false;startQuote()"
            ></Button>
            </div>

              </div>
            <div v-else>   {{ $t("code.datereserved") }}</div>
            </div>
          </Dialog>
          <!-- show business policies -->
          <Dialog
            v-model:visible="showServicePolicy"
            :modal="true"
            :contentStyle="{ overflow: 'scroll' }"
            :header="$t('businessprofile.sla')"
            style="text-align: center"
            :dismissableMask="true"
            :style="{ width: '50vw' }"
            :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
          >
            <span class="ql-editor" v-html="currentBusiness.BusPolicy"></span>
          </Dialog>
          <!-- show calendar dialog -->
          <Dialog
            v-model:visible="showDateDialog"
            :modal="true"
            :contentStyle="{ overflow: 'scroll' }"
            :header="$t('quotes.eventdatetime')"
            style="text-align: center"
            :dismissableMask="true"
            :style="{ width: '50vw' }"
            :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
          >
            <p>
              {{ $t("quotes.includebusdate") }}
            </p>
            <br />
            <div class="grid justify-content-center">
              <template v-for="quote in quotes" :key="quote.BusId">
                <div
                  class="col-12 md:col-4"
                  v-if="getBusinessDisplayCalendar(quote.BusId)"
                >
                  <label>{{ currentBusiness.BusName }}</label
                  ><br />
                  <div class="p-inputgroup justify-content-center">
                    <Button
                      type="button"
                      icon="pi pi-calendar-plus"
                      :label="
                        quote.SlotId
                          ? getVendorTime(quote.BusId)
                          : $t('quotes.timeslot')
                      "
                      @click.stop="
                        tempBusId = quote.BusId;
                        toggle($event);
                      "
                      aria:haspopup="true"
                      aria-controls="overlay_panel"
                    />
                    <Button
                      class="p-button-danger"
                      type="button"
                      icon="pi pi-times"
                      @click.stop="clearVendorTime(quote.BusId)"
                    />
                  </div>
                </div>
                <div v-else>
                  <Button
                    type="button"
                    icon="pi pi-calendar-plus"
                    :label="$t('quotes.calendarnotavailable')"
                    @click.stop="toggle($event)"
                    aria:haspopup="true"
                    aria-controls="overlay_panel"
                    disabled
                  /><br />
                  <InlineMessage class="mt-1" severity="warn"
                    ><small>{{
                      $t("quotes.submitquote")
                    }}</small></InlineMessage
                  >
                </div>
                <br />

                <OverlayPanel
                  ref="op1"
                  appendTo="body"
                  :showCloseIcon="true"
                  id="overlay_panel"
                  style="margin: 2vh; width: 90%"
                >
                  <div style="font-size: large; text-align: center">
                    {{ $t("quotes.availableslots") }}
                  </div>
                  <div style="font-size: x-large; text-align: center">
                    <b>{{ currentBusiness.BusName }}</b>
                  </div>

                  <vue-cal
                    :events="availableSlots"
                    small
                    style="height: 500px"
                    active-view="month"
                    :disable-views="['years', 'week', 'day']"
                    events-count-on-year-view
                    events-on-month-view="true"
                    @cell-click="dateClick($event)"
                    :twelve-hour="twelveHr"
                    @ready="fetchAvailableEvents()"
                    @view-change="fetchAvailableEvents()"
                    :on-event-click="onEventClick"
                  >
                    <template v-slot:event="{ event, view }">
                      <!-- <v-icon>{{ event.icon }}</v-icon> -->

                      <!-- <div class="vuecal__event-title"> -->
                      <!-- <span
                  v-if="event.title != 'Available' && event.title != 'Hidden'"
                  >{{ event.title }}</span
                > -->
                      <!-- </div> -->
                      <!-- v-html="event.title" -->
                      <!-- Or if your events are editable: -->
                      <!-- <div
                class="vuecal__event-title vuecal__event-title--edit"
                contenteditable
                @blur="event.title = $event.target.innerHTML"
                v-html="event.title"
              /> -->

                      <span class="vuecal__event-time" v-if="view == 'month'">
                        <span class="show-on-desktop">
                          {{ event.start.formatTime("h:mm am") }} -
                          {{ event.end.formatTime("h:mm am") }}</span
                        >
                        <small class="show-on-mobile">
                          {{ event.start.formatTime("h:mm am") }}
                        </small>
                      </span>
                      <span class="vuecal__event-time" v-if="view == 'day'">
                        <small class="">
                          {{ event.title }}<br />
                          {{ event.start.formatTime("h:mm am") }} -
                          {{ event.end.formatTime("h:mm am") }}</small
                        >
                      </span>
                    </template>
                    <!-- <template v-slot:events-count="{ events }">
              <span v-if="customEventsCount(events)">
                {{ customEventsCount(events) }}
              </span>
            </template> -->
                  </vue-cal>
                </OverlayPanel>
              </template>
            </div>
            <div class="flex justify-content-between">
              <Button
                :label="$t('profile.cancel')"
                icon="pi pi-times"
                class="p-button-raised p-button-danger mt-2"
                @click.stop="showDateDialog = false"
              />
              <!-- <Button
              :label="$t('menu.skip')"
              class="p-button-raised p-button-info mt-2"
              @click.stop="reviewQuoteSelection()"
            /> -->
              <Button
                :label="$t('menu.next')"
                icon="pi pi-arrow-right"
                class="p-button-raised p-button-success mt-2"
                @click.stop="reviewQuoteSelection()"
              />
            </div>
          </Dialog>

          <!-- show review quote  dialog -->
          <Dialog
            v-model:visible="showQuoteReviewDialog"
            :contentStyle="{ overflow: 'scroll' }"
            :modal="true"
            style="text-align: center"
            :dismissableMask="true"
            :style="{ width: '50vw' }"
            :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
          >
            <template #header>
              <b> {{ $t("businessprofile.reviewquote") }}</b>
            </template>

            <p>{{ $t("quotes.review") }}</p>

            <template v-for="quote in quotes" :key="quote.BusId">
              <div class="grid mb-3">
                <div class="col-12" style="background-color: #e9ecef">
                  <b> {{ currentBusiness.BusName }}</b
                  ><br />
                  {{ getVendorTime(quote.BusId) }}
                </div>

                <div class="col-4" style="background-color: #f8f9fa">
                  <b>Service</b>
                </div>
                <div class="col-4" style="background-color: #f8f9fa">
                  <b>Price</b>
                </div>
                <div class="col-4" style="background-color: #f8f9fa">
                  <b>Qty</b>
                </div>

                <template
                  v-for="serv in quote.QuotedServices"
                  :key="serv.ServId"
                >
                  <div class="col-4">{{ serv.Serv.ServTitle }}</div>
                  <div class="col-4">
                    ${{ serv.ServPrice }}
                    <span
                      v-if="serv.Serv.ServPriceCalculated == true"
                      style="font-size: small"
                    >
                      each
                    </span>
                  </div>
                  <div class="col-4">
                    <span v-if="!serv.Serv.ServPriceCalculated">1</span>
                    <span v-else>{{
                      servicesForQuoteQty[getIndex(serv.ServId)].Qty
                    }}</span>
                  </div>
                </template>
              </div>
            </template>
            <p>
              <em>{{ $t("quotes.quotereview") }}</em>
            </p>

            <template #footer>
              <div class="flex justify-content-between">
                <Button
                  :label="$t('profile.cancel')"
                  class="p-button-raised p-button-danger mt-2"
                  @click.stop="showQuoteReviewDialog = false"
                />
                <Button
                  :label="$t('menu.back')"
                  class="p-button-raised p-button-info mt-2"
                  @click.stop="
                    showQuoteReviewDialog = false;
                    showDateDialog = true;
                  "
                />
                <Button
                  v-if="!submittingQuote"
                  :label="$t('menu.submit')"
                  class="p-button-raised p-button-success mt-2"
                  autofocus
                  @click.stop="submitQuote"
                />
                <Button
                  v-else
                  icon="pi pi-spin pi-spinner"
                  :label="$t('menu.submit')"
                  class="p-button-raised p-button-success mt-2"
                  disabled
                />
              </div>
            </template>
          </Dialog>
        </div>
      </TabPanel>
      <TabPanel v-if="currentBusiness.BusStoreEnabled == true">
        <template #header>
          <i class="pi pi-shopping-cart"></i>
          <span> {{ $t("store.store") }}</span>
        </template>
        <div
          class="
            flex flex-column
            p-flex-lg-row
            align-items-center
            justify-content-evenly
          "
        >
          <div v-if="showAddToPurchase" class="">
            <b>{{ $t("store.purchaseorderstarted") }}</b
            ><br /><em>
              {{ servicesForPurchase.length }}
              {{ $t("quotes.added") }} &nbsp;</em
            >
          </div>
          <div v-if="showAddToPurchase" class="flex justify-content-between">
            <Button
              icon="pi pi-times"
              :label="$t('profile.cancel')"
              class="p-button-danger p-button-sm m-1"
              @click.stop="
                resetPurchase();
                showAddToPurchase = false;
              "
            />
            <Button
              icon="pi pi-refresh"
              :label="$t('menu.reset')"
              class="p-button-secondary p-button-sm m-1"
              @click.stop="resetPurchase()"
            />
            <Button
              v-if="!servicesForPurchase.length > 0"
              icon="pi pi-check"
              :label="$t('menu.submit')"
              disabled
              class="p-button-success p-button-sm m-1"
            />
            <Button
              v-if="servicesForPurchase.length > 0"
              icon="pi pi-check"
              :label="$t('menu.submit')"
              class="p-button-success p-button-sm m-1"
              @click.stop="displayCalendar()"
            />
          </div>
        </div>
        <Message v-if="showAddToPurchase == true" severity="info" :sticky="true"
          ><small>{{ $t("store.purchasemessage") }}</small></Message
        >
        <DataView
          :value="
            currentBusiness.Services.filter(
              (serv) => serv.ServiceForStore == true
            )
          "
          :layout="layout"
          :paginator="true"
          :rows="9"
          :sortOrder="sortOrder"
          :sortField="sortField"
          class="service-grid"
        >
          <template #header>
            <div class="grid grid-nogutter">
              <div class="col-12 md:col-3" style="text-align: left">
                <Dropdown
                  v-model="sortKey"
                  class="mr-2"
                  :options="sortOptions"
                  optionLabel="label"
                  :placeholder="$t('services.sort')"
                  @change="onSortChange($event)"
                />
              </div>
              <div class="col-12 md:col-9" style="text-align: right">
                <Button
                  v-if="
                    !showAddToPurchase &&
                    currentBusiness.BusStoreEnabled == true
                  "
                  icon="pi pi-dollar"
                  :label="$t('store.startpurchase')"
                  class="p-button-primary p-button-raised p-button-sm m-2"
                  @click.stop="startPurchase()"
                />
              </div>
            </div>
          </template>
          <template #grid="slotProps">
            <div
              v-if="
                slotProps.data.ServActive == true &&
                slotProps.data.ServDeleted == false &&
                promotionValid(slotProps.data.ServPromotionExpires)
              "
              class="col-12 md:col-4 lg:col-3 m-2"
            >
              <Card
                class="mb-2 mr-2"
                v-if="slotProps.data != null"
                style="
                  background-color: white !important;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                "
              >
                <template v-slot:content>
                  <div class="product-grid-item-top text-right">
                    <Button
                      v-if="
                        currentCustomer != null &&
                        currentCustomer.ServiceFavorites != null &&
                        currentCustomer.ServiceFavorites.filter(
                          (fav) => fav.ServId == slotProps.data.ServId
                        ).length > 0
                      "
                      v-tooltip.right="$t('menu.removfav')"
                      icon="pi pi-check"
                      class="p-button-success p-button-lg p-button-text"
                      @click.stop="removeServFavorite(slotProps.data.ServId)"
                    ></Button>
                    <Button
                      v-else
                      class="p-button-sm p-button-primary"
                      icon="pi pi-heart"
                      v-tooltip.right="$t('menu.removfav')"
                      @click.stop="addServFavorite(slotProps.data.ServId)"
                    ></Button>
                  </div>
                  <div class="product-grid-item-content mt-2">
                    <Galleria
                      v-if="
                        slotProps.data.ServiceImages != null &&
                        slotProps.data.ServiceImages.length > 0
                      "
                      :value="slotProps.data.ServiceImages"
                      :fullScreen="isFullScreen"
                      :circular="true"
                      :transitionInterval="3000"
                      :responsiveOptions="responsiveOptions"
                      :numVisible="5"
                      containerStyle="max-width: 100%;"
                      :showItemNavigators="true"
                      :showThumbnails="false"
                      :showIndicators="true"
                    >
                      <template #item="slotprops">
                        <img
                          v-if="slotprops.item"
                          :src="imageHostingURL + slotprops.item.BusImage"
                          :alt="slotprops.item.BusImageTitle"
                          style="width: 100%; display: block"
                          class="shadow-8"
                        />
                      </template>
                      <template #thumbnail="slotprops">
                        <img
                          v-if="slotprops.item"
                          :src="imageHostingURL + slotprops.item.BusImage"
                          :alt="slotprops.item.BusImageTitle"
                          style="width: 50px; heigth: 50px; margin: 5px"
                        />
                      </template>
                    </Galleria>
                    <img
                      v-else-if="slotProps.data.ServImage != null"
                      :src="imageHostingURL + slotProps.data.ServImage"
                      :alt="slotProps.data.ServTitle"
                      width="120"
                      length="120"
                    />

                    <div class="product-name">
                      {{ slotProps.data.ServTitle }}
                    </div>
                    <div class="product-description">
                      {{ slotProps.data.ServShortDesc }} <br />

                      <div class="mt-2 mb-2">
                        <i class="pi pi-tag product-category-icon"></i>
                        <span
                          v-if="slotProps.data.ServiceTypes != null"
                          class="product-category"
                        >
                          <template
                            v-for="type in slotProps.data.ServiceTypes"
                            :key="type.ServTypeTitle"
                          >
                            <Chip class="m-1">
                              <service-type-label
                                :serviceType="type.ServTypeTitle"
                            /></Chip>
                          </template>
                        </span>
                      </div>
                      <Button
                        :label="$t('menu.moredetails')"
                        class="
                          p-button-raised
                          p-button-rounded
                          p-button-primary
                          p-button-sm
                          m-2
                        "
                        style="margin-top: 2px"
                        @click="viewDetails(slotProps.data)"
                      />
                    </div>
                  </div>
                  <div
                    class="
                      product-grid-item-bottom
                      flex flex-wrap
                      justify-content-evenly
                    "
                  >
                    <span class="product-price mb-2 mr-2"
                      >${{ slotProps.data.ServPrice
                      }}<span
                        v-if="slotProps.data.ServPriceCalculated == true"
                        style="font-size: small"
                      >
                        each &nbsp;
                      </span></span
                    >

                    <Tag
                      class="mb-2 mr-2"
                      v-if="slotProps.data.ServPromotionExpires != null"
                      severity="danger"
                      value="Danger"
                      style="margin-top: 5px"
                    >
                      <em>{{ $t("services.special") }}</em>
                    </Tag>
                  </div>

                  <div class="flex justify-content-center">
                    <div
                      v-if="
                        showAddToPurchase &&
                        currentBusiness.BusStoreEnabled == true
                      "
                    >
                      <div v-if="slotProps.data.ServPriceCalculated">
                        <label>{{ $t("menu.qty") }}:&nbsp;</label>
                        <InputNumber
                          v-if="
                            servicesForPurchase.filter(
                              (val) => val.ServId == slotProps.data.ServId
                            ).length == 0
                          "
                          class="mb-3"
                          v-model="
                            servicesForPurchaseQty[
                              getPurchaseIndex(slotProps.data.ServId)
                            ].Qty
                          "
                          inputStyle="width:50px"
                        />
                        <span v-else>
                          {{
                            servicesForPurchaseQty[
                              getPurchaseIndex(slotProps.data.ServId)
                            ].Qty
                          }}
                        </span>
                        <!-- <InputNumber
                          v-else
                          disabled
                          class="mb-3"
                          v-model="
                            servicesForPurchaseQty[
                              getPurchaseIndex(slotProps.data.ServId)
                            ].Qty
                          "
                          inputStyle="width:50px"
                        /> -->
                      </div>
                      <Button
                        v-if="
                          servicesForPurchase.filter(
                            (val) => val.ServId == slotProps.data.ServId
                          ).length == 0
                        "
                        icon="pi pi-plus"
                        :label="$t('store.add')"
                        class="p-button-raised p-button-info p-button-sm"
                        @click.stop="addToPurchase(slotProps.data)"
                      />
                      <Button
                        v-if="
                          servicesForPurchase.filter(
                            (val) => val.ServId == slotProps.data.ServId
                          ).length > 0
                        "
                        icon="pi pi-minus"
                        :label="$t('quotes.remove')"
                        class="
                          p-button-raised
                          p-button-danger
                          p-button-text
                          p-button-sm
                          mt-2
                        "
                        @click.stop="removeFromPurchase(slotProps.data)"
                      />
                    </div>
                    <InlineMessage
                      v-if="currentBusiness.BusStoreEnabled != true"
                      severity="warn"
                      ><small
                        ><b>{{ $t("quotes.sorry") }}</b></small
                      ></InlineMessage
                    >
                  </div>
                </template>
              </Card>
            </div>
          </template>
        </DataView>
        <!-- show delivery calendar dialog -->
        <Dialog
          v-model:visible="showDeliveryDateDialog"
          :modal="true"
          :contentStyle="{ overflow: 'scroll' }"
          :header="$t('store.deliverydate')"
          style="text-align: center"
          :dismissableMask="true"
          :style="{ width: '50vw' }"
          :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
        >
          <p>
            {{ $t("store.deliverydatedesc") }}
          </p>
          <br />

          <vue-cal
            :events="availableSlotsForDelivery"
            small
            style="height: 500px"
            active-view="month"
            :disable-views="['years', 'week', 'day']"
            events-count-on-year-view
            events-on-month-view="true"
            @cell-click="dateClick($event)"
            :twelve-hour="twelveHr"
            @ready="fetchDeliveryEvents()"
            @view-change="fetchDeliveryEvents()"
            :on-event-click="onDeliveryDateSelected"
          >
            <template v-slot:event="{ event, view }">
              <span class="vuecal__event-time" v-if="view == 'month'">
                <span class="show-on-desktop">
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</span
                >
                <small class="show-on-mobile">
                  {{ event.start.formatTime("h:mm am") }}
                </small>
              </span>
              <span class="vuecal__event-time" v-if="view == 'day'">
                <small class="">
                  {{ event.title }}<br />
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</small
                >
              </span>
            </template> </vue-cal
          ><br />
          <div v-if="purchase != null && purchase.Slot != null">
            <h3>{{ $t("store.selecteddate") }}</h3>
            
             <div class="p-inputgroup justify-content-center">
                  <Button
                    type="button"
                    icon="pi pi-calendar-plus"
                    class="p-button-text p-button-secondary p-button-raised"
                    :label="
                     $formatDateTime(purchase.Slot.start)
                    "
                    disabled
                  />
                  <Button
                    class="p-button-danger"
                    type="button"
                    icon="pi pi-times"
                   @click.stop="purchase.Slot=null; purchase.SlotId=null"
                  />
                </div>
          </div>

          <div class="flex justify-content-between">
            <Button
              :label="$t('profile.cancel')"
              icon="pi pi-times"
              class="p-button-raised p-button-danger mt-2"
              @click.stop="showDeliveryDateDialog = false"
            />

            <Button
              :label="$t('menu.next')"
              icon="pi pi-arrow-right"
              class="p-button-raised p-button-success mt-2"
              @click.stop="reviewPurchaseSelection()"
            />
          </div>
        </Dialog>
        <!-- purchase review dialog -->
        <Dialog
          v-model:visible="showPurchaseReviewDialog"
          :contentStyle="{ overflow: 'scroll' }"
          :modal="true"
          style="text-align: center"
          :dismissableMask="true"
          :style="{ width: '50vw' }"
          :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
        >
          <template #header>
            <b> {{ $t("store.reviewdetails") }}</b>
          </template>

          <p>{{ $t("store.reviewdetailsdesc") }}</p>

          <div class="grid mb-3">
            <div class="col-12" style="background-color: #e9ecef">
              <h3>{{ currentBusiness.BusName }}</h3>
              <template v-if="purchase != null && purchase.Slot != null">
                <label>{{ $t("store.selecteddate") }}: </label>
                {{ $formatDateTime(purchase.Slot.start) }}
              </template>
            </div>

            <div class="col-4" style="background-color: #f8f9fa">
              <b>Service</b>
            </div>
            <div class="col-4" style="background-color: #f8f9fa">
              <b>Price</b>
            </div>
            <div class="col-4" style="background-color: #f8f9fa">
              <b>Qty</b>
            </div>

            <template
              v-for="serv in purchase.OrderedServices"
              :key="serv.ServId"
            >
              <div class="col-4">{{ serv.Serv.ServTitle }}</div>
              <div class="col-4">
                ${{ serv.ServPrice }}
                <span
                  v-if="serv.Serv.ServPriceCalculated == true"
                  style="font-size: small"
                >
                  each
                </span>
              </div>
              <div class="col-4">
                <span v-if="!serv.Serv.ServPriceCalculated">1</span>
                <span v-else>{{
                  servicesForPurchaseQty[getPurchaseIndex(serv.ServId)].Qty
                }}</span>
              </div>
            </template>
          </div>

          <div
            class="col-12 mt-5 grid grid-nogutter justify-content-end"
            style="text-align: right"
          >
            <div class="col-9" style="background-color: #f8f9fa">
              <b>{{ $t("custreserv.custsub") }}&nbsp;&nbsp;</b>
            </div>
            <div class="col-3">
              <span v-if="purchase.OrderSubTotal != 0"
                ><b>${{ purchase.OrderSubTotal }}</b></span
              >
            </div>
            <div
              v-if="purchase.OrderDiscount != 0"
              class="col-9"
              style="background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custdis") }}&nbsp;&nbsp;</b>
            </div>
            <div v-if="purchase.OrderDiscount != 0" class="col-3">
              <span
                ><b>${{ purchase.OrderDiscount }}</b></span
              >
            </div>
            <div
              class="col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custsales") }}&nbsp;&nbsp;</b>
            </div>
            <div class="col-3">
              <span
                v-if="purchase.OrderSubTotal != 0 && purchase.OrderTax != 0"
              >
                <b>${{ purchase.OrderTax }}</b></span
              >
            </div>
            <div
              class="col-9"
              style="text-align: right; background-color: #f8f9fa"
            >
              <b>{{ $t("custreserv.custtotal") }}&nbsp;&nbsp;</b>
            </div>
            <div class="col-3">
              <span v-if="purchase.OrderSubTotal != 0">
                <b>${{ purchase.OrderTotalCost }}</b></span
              >
            </div>
          </div>

          <p>
            <span class="ql-editor" v-html="currentBusiness.BusPolicy"></span>
          </p>

          <p>
            <em
              ><b>{{ $t("store.revieworder") }}</b></em
            >
          </p>
          <template #footer>
            <div class="flex justify-content-between">
              <Button
                :label="$t('profile.cancel')"
                icon="pi pi-times"
                class="p-button-raised p-button-danger mt-2"
                @click.stop="
                  showPurchaseReviewDialog = false;
                  resetPurchase();
                "
              />
              <Button
                :label="$t('menu.back')"
                v-if="availableSlotsForDelivery.length > 0"
                class="p-button-raised p-button-info mt-2"
                icon="pi pi-arrow-left"
                @click.stop="
                  showPurchaseReviewDialog = false;
                  showDeliveryDateDialog = true;
                "
              />
              <Button
                v-if="!submittingPurchase"
                :label="$t('menu.submit')"
                icon="pi pi-check"
                class="p-button-raised p-button-success mt-2"
                autofocus
                @click.stop="
                  submittingPurchase = true;
                  currentBusiness.BusDownPmtReq == true
                    ? (downPmtRequired = true)
                    : purchaseApproved();
                "
              />
              <Button
                v-else
                icon="pi pi-spin pi-spinner"
                :label="$t('menu.submit')"
                class="p-button-raised p-button-success mt-2"
                disabled
              />
            </div>
          </template>
        </Dialog>
        <!-- downpayment for purchase -->
        <Dialog
          v-model:visible="downPmtRequired"
          :modal="true"
          :contentStyle="{ overflow: 'scroll' }"
          :header="$t('store.downpmtrequired')"
          :dismissableMask="true"
          :style="{ width: '50vw' }"
          :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
        >
          <div style="text-align: left">
            <div v-if="currentBusiness.BusDownPmtReq">
              {{ currentBusiness.BusName }}
              {{ $t("store.downpmtrequireddesca") }}
              <b
                >${{
                  currentBusiness.BusDownPmtAmt > purchase.OrderTotalCost
                    ? purchase.OrderTotalCost
                    : currentBusiness.BusDownPmtAmt
                }}</b
              >
              {{ $t("store.downpmtrequireddescb") }}

              <div
                v-if="customerCards != null && customerCards.length > 0"
                class="field col-12"
              >
                <label>{{ $t("custreserv.custcard") }} </label>
                <div v-if="showSavedCards" class="field-radiobutton">
                  <RadioButton
                    id="cardOption1"
                    name="cardOption"
                    value="savedCard"
                    v-model="cardOption"
                  />&nbsp;
                  <Dropdown
                    id="paymentCards"
                    class="mt-2 mr-2"
                    v-model="selectedCard"
                    :options="customerCards"
                    :placeholder="$t('custreserv.custsecard')"
                    @change="cardOption = 'savedCard'"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>
                          x{{ slotProps.value.CardNumber }},
                          <small>
                            Exp. {{ slotProps.value.CardExpMonth }}/{{
                              slotProps.value.CardExpYear
                            }}</small
                          >
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        x{{ slotProps.option.CardNumber }},
                        <small>
                          Exp. {{ slotProps.option.CardExpMonth }}/{{
                            slotProps.option.CardExpYear
                          }}</small
                        >
                      </div>
                    </template>
                  </Dropdown>
                  <div v-if="displayCardRequired && selectedCard == null">
                    <Message severity="error" :key="error" :sticky="true"
                      >Card Required</Message
                    >
                  </div>
                </div>
              </div>
              <div class="field-radiobutton">
                <RadioButton
                  id="cardOption2"
                  name="cardOption"
                  value="newCard"
                  v-model="cardOption"
                />
                <span>&nbsp;{{ $t("code.newcard") }}</span>
              </div>
              <div v-if="cardOption == 'newCard'" class="text-left">
                <stripe-card ref="stripeCard"></stripe-card>
              </div>
              <div class="col-12">
                <Message
                  v-for="error of errors"
                  severity="error"
                  :key="error"
                  :sticky="true"
                  >{{ error }}</Message
                >
              </div>
            </div>
          </div>

          <div v-if="currentBusiness.BusDownPmtReq">
            <p>
              {{ $t("store.submitpaymenta")
              }}<b>
                ${{
                  currentBusiness.BusDownPmtAmt > purchase.OrderTotalCost
                    ? purchase.OrderTotalCost
                    : currentBusiness.BusDownPmtAmt
                }}</b
              >
              {{ $t("store.submitpaymentb") }}
            </p>
          </div>
          <div class="flex justify-content-between">
            <Button
              :label="$t('profile.cancel')"
              icon="pi pi-times"
              iconPos="left"
              @click.stop="
                submittingPurchase = false;
                resetPurchase();
              "
              class="mr-2 p-button-danger p-button-sm p-button-outlined"
            />
            <Button
              v-if="!acceptedPmt"
              :label="$t('menu.submit')"
              icon="pi pi-check"
              iconPos="left"
              @click.stop="purchaseApproved()"
              class="mr-2 p-button-success p-button-sm p-button-raised"
            />
            <Button
              v-else
              :label="$t('profile.saving')"
              icon="pi pi-spin pi-spinner"
              iconPos="left"
              disabled
              class="mr-2 p-button-success p-button-sm p-button-raised"
            />
          </div>
        </Dialog>
      </TabPanel>
      <TabPanel>
        <template #header>
          <i class="pi pi-star-fill"></i>
          <span> {{ $t("businessprofile.reviews") }}</span>
        </template>
        <Card class="text-center mb-5">
          <template #content>
            <div
              v-if="
                displayedBus != null &&
                displayedBus.Reviews != null &&
                displayedBus.Reviews.length > 0
              "
              class="grid"
            >
              <div class="col-12 md:col-6 lg:col-3" style="font-size: large">
                <label class="mb-3" style="text">{{
                  $t("message.overall")
                }}</label
                ><br />
                <span style="font-size: 150%"
                  ><strong>{{ displayedBus.BusRating }}</strong></span
                ><br />
                <Rating
                  :modelValue="displayedBus.BusRating"
                  :readonly="true"
                  :cancel="false"
                  class="large-stars"
                ></Rating>
                <small
                  >{{ displayedBus.Reviews.length }}
                  {{ $t("businessprofile.reviews") }}</small
                ><br />
                <Button
                  :label="$t('menu.addreview')"
                  class="p-button-rounded p-button-primary p-button-sm m-2"
                  @click="displayReview()"
                />
              </div>
              <div class="col-2 md:col-1 lg:col-1 text-right">
                <div class="mb-1">5 <i class="pi pi-star-fill"></i></div>
                <div class="mb-1">4 <i class="pi pi-star-fill"></i></div>
                <div class="mb-1">3 <i class="pi pi-star-fill"></i></div>
                <div class="mb-1">2 <i class="pi pi-star-fill"></i></div>
                <div class="mb-1">1 <i class="pi pi-star-fill"></i></div>
              </div>
              <div class="col-10 md:col-5 lg:col-8">
                <ProgressBar
                  class="mb-1 excellent"
                  :value="displayedBus.ExcellentCount"
                >
                  {{ displayedBus.ExcellentCount }}%
                </ProgressBar>

                <ProgressBar class="mb-1 good" :value="displayedBus.GoodCount"
                  >{{ displayedBus.GoodCount }}%</ProgressBar
                >
                <ProgressBar
                  class="mb-1 average"
                  :value="displayedBus.AverageCount"
                  >{{ displayedBus.AverageCount }}%</ProgressBar
                >
                <ProgressBar
                  class="mb-1 below"
                  :value="displayedBus.BelowAvgCount"
                  >{{ displayedBus.BelowAvgCount }}%</ProgressBar
                >
                <ProgressBar class="mb-1 poor" :value="displayedBus.PoorCount"
                  >{{ displayedBus.PoorCount }}%</ProgressBar
                >
              </div>
            </div>
            <div v-else>
              <p>{{ $t("businessprofile.norated") }}</p>
              <Button
                :label="$t('menu.addreview')"
                class="p-button-rounded p-button-primary p-button-sm m-2"
                @click="displayReview()"
              />
            </div>
          </template>
        </Card>
        <div v-if="displayedBus != null && displayedBus.Reviews != null">
          <div
            v-for="review in displayedBus.Reviews.slice().reverse()"
            :key="review.ReviewId"
          >
            <Card class="text-center mb-5">
              <template #title>
                <div
                  class="flex flex-column p-flex-md-row justify-content-between"
                >
                  <div class="">
                    <Avatar icon="pi pi-user" class="mr-2" size="large" />
                    <small
                      >{{ review.Cust.CustFirstName }}
                      {{ review.Cust.CustLastName }}</small
                    >
                  </div>
                  <div class="">
                    <Rating
                      style="display: inline"
                      :modelValue="review.ReviewStars"
                      :readonly="true"
                      :cancel="false"
                    ></Rating>
                  </div>
                </div>
              </template>
              <template #subtitle>
                <div
                  class="flex flex-column p-flex-md-row justify-content-between"
                >
                  <div>
                    <span
                      ><b>{{ review.ReviewTitle }}</b></span
                    >
                  </div>
                  <div>
                    <em>{{ $formatDateTimeToDate(review.ReviewDate) }}</em>
                  </div>
                </div>
              </template>
              <template #content>
                <div style="text-aligned: left">{{ review.ReviewText }}</div>
              </template>
            </Card>
          </div>
        </div>
      </TabPanel>
    </TabView>

    <!-- Time Slot Details -->
    <Sidebar
      v-model:visible="viewingTimeSlot"
      position="full"
      style="overflow: scroll"
    >
      <!-- <ScrollPanel style="width: 95%; height: 95vh; vertical-align: middle"> -->
      <div class="shadow-4 p-3 mt-6 mr-1 ml-1">
        <h4>{{ $t("details.availabledetails") }}</h4>

        <!-- <div class="p-fluid formgrid grid text-center">
        <div class="field col-12"> -->
        <b>{{ $t("calendar.date") }} </b> {{ timeSlotToView.date }} <br />
        <b>{{ $t("calendar.start") }}</b
        >{{ timeSlotToView.start }}<br />
        <b>{{ $t("calendar.end") }} </b>{{ timeSlotToView.end }}<br />
        <p>
          <em
            >{{ $t("businessprofile.reservationrequest") }}
            {{ currentBusiness.BusName }}
            {{ $t("businessprofile.agreereservation") }}</em
          >
        </p>

        <div class="flex justify-content-center">
          <Button
            :label="$t('menu.reservation')"
            icon="pi pi-calendar-plus"
            autofocus
          />
        </div>
      </div>
      <!-- </ScrollPanel
    > -->
    </Sidebar>

    <!-- SERVICE DETAILS DIALOG -->
    <Sidebar
      class="pt-3 full-height-side-bar"
      v-model:visible="viewingService"
      position="full"
      style="overflow: scroll"
    >
      <service-grid-layout
        :data="tempService"
        :mode="mode"
        :view="'full'"
        v-on:favorite="addServFavorite(tempService.ServId)"
        v-on:view="viewDetails(tempService)"
      />
    </Sidebar>
    <!-- ADD REVIEW DIALOG -->
    <Dialog
      v-model:visible="showAddReview"
      :modal="true"
      :contentStyle="{ overflow: 'scroll' }"
      :header="$t('businessprofile.click')"
      :dismissableMask="true"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <div style="text-align: center">
        <img
          :src="imageHostingURL + currentBusiness.BusLogo"
          :alt="currentBusiness.BusName"
          style="max-height: 100px; max-width: 100px"
          class="mr-3"
        /><br />
        <span style="font-size: xx-large">{{ currentBusiness.BusName }}</span
        ><br />
        <span v-if="showReviewReq" class="required-star"
          >* {{ $t("services.pleaseselect") }}</span
        >

        <Rating
          v-model="newReview.ReviewStars"
          :cancel="false"
          class="large-stars"
        />

        <InputText
          type="text"
          class="small-text-placeholder"
          :placeholder="$t('businessprofile.overall')"
          v-model="newReview.ReviewTitle"
          style="width: 100%"
        />
        <Textarea
          class="mt-2"
          v-model="newReview.ReviewText"
          :placeholder="$t('businessprofile.share')"
          :autoResize="true"
          rows="5"
          cols="30"
          style="width: 100%"
        />
        <p>
          <em>{{ $t("businessprofile.feedback") }}</em>
        </p>
      </div>
      <div class="flex justify-content-between">
        <Button
          :label="$t('profile.cancel')"
          v-tooltip.right="$t('profile.cancel')"
          icon="pi pi-times"
          iconPos="left"
          @click.stop="resetNewReview"
          class="mr-2 p-button-secondary p-button-sm p-button-raised"
        />
        <Button
          v-if="!savingReview"
          :label="$t('profile.save')"
          icon="pi pi-check"
          iconPos="left"
          @click.stop="saveReview"
          class="mr-2 p-button-primary p-button-sm p-button-raised"
        />
        <Button
          v-else
          :label="$t('profile.saving')"
          icon="pi pi-spin pi-spinner"
          iconPos="left"
          disabled
          class="mr-2 p-button-primary p-button-sm p-button-raised"
        />
      </div>
    </Dialog>
    <!-- BOOK A TOUR -->
    <Dialog
      v-model:visible="showBookTourDialog"
      :modal="true"
      :contentStyle="{ overflow: 'scroll' }"
      :header="$t('calendar.bookingtour')"
      :dismissableMask="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      @hide="resetAppt()"
    >

    <div v-if="loadingDates">
      Loading
    </div>
    <div v-if="!loadingDates && showNoAppts">
      {{ $t('code.noavailableappts')}} {{ currentBusiness.BusPhone }}.
      </div>
    <div v-show="!loadingDates && !showNoAppts">
      <p>
        {{ currentCustomer.CustFirstName }},
        {{ $t("calendar.bookingtourdesc") }}
      </p>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 text-left">
          <Textarea
            class="mt-2"
            v-model="newAppt.ApptCustNotes"
            :placeholder="$t('calendar.eventdetails')"
            :autoResize="true"
            rows="5"
            cols="30"
            style="width: 100%"
          />
        </div>
        <div class="field col-12" style="">
          <vue-cal
            :events="availableApptSlots"
            style="height: 500px"
            active-view="month"
            :disable-views="['years', 'week', 'day']"
            events-count-on-year-view
            events-on-month-view="true"
            :twelve-hour="twelveHr"
            @ready="fetchTourEvents()"
            @view-change="fetchTourEvents()"
            :on-event-click="onApptEventClick"
          >
            <template v-slot:event="{ event, view }">
              <span class="vuecal__event-time" v-if="view == 'month'">
                <span class="show-on-desktop">
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</span
                >
                <small class="show-on-mobile">
                  {{ event.start.formatTime("h:mm am") }}
                </small>
              </span>
              <span class="vuecal__event-time" v-if="view == 'day'">
                <small class="">
                  {{ event.title }}<br />
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</small
                >
              </span>
            </template>
          </vue-cal>
          <div class="field col-12 m-2" v-if="selectedApptSlot != null">
            <b>{{$t('code.selected')}}: </b>
            {{ $formatDateTime(selectedApptSlot.start) }}
          </div>
        </div>
      </div>
      </div>
      <div class="flex justify-content-between">
        
        <Button
          :label="$t('profile.cancel')"
          icon="pi pi-times"
          class="p-button-raised p-button-danger mt-2"
          @click.stop="resetAppt()"
        />
        <Button
          v-if="!submittingAppt && selectedApptSlot != null"
          icon="pi pi-check"
          :label="$t('menu.submit')"
          class="p-button-raised p-button-success mt-2"
          @click.stop="submitAppt()"
        />
        <Button
          v-if="!submittingAppt && selectedApptSlot == null"
          icon="pi pi-check"
          :label="$t('menu.submit')"
          class="p-button-raised p-button-success mt-2"
         disabled
        />
        <Button
        v-if="submittingAppt"
          icon="pi pi-spin pi-spinner"
          :label="$t('menu.submit')"
          class="p-button-raised p-button-success mt-2"
          disabled
        />
      </div>
    </Dialog>

    <!-- BOOK AN APPT -->
    <Dialog
      v-model:visible="showBookApptDialog"
      :modal="true"
      :contentStyle="{ overflow: 'scroll' }"
      :header="$t('calendar.bookingappt')"
      :dismissableMask="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      @hide="resetAppt()"
    >
    <div v-if="loadingDates" class="text-center">
      <img
              src="@/assets/loading.gif"
              width="100"
              style="
               
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              "
            />          
    </div>
    <div v-if="!loadingDates && showNoAppts">
     {{ $t('code.noavailableappts')}} {{ currentBusiness.BusPhone }}.
      </div>
      <div v-show="!loadingDates && !showNoAppts">
      <p>
        {{ currentCustomer.CustFirstName }},
        {{ $t("calendar.bookingapptdesc") }}
      </p>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 text-left">
          <Textarea
            class="mt-2"
            v-model="newAppt.ApptCustNotes"
            :placeholder="$t('calendar.appptdetails')"
            :autoResize="true"
            rows="5"
            cols="30"
            style="width: 100%"
          />
        </div>
        <div class="field col-12" style="">
          <vue-cal
            :events="availableApptSlots"
            style="height: 500px"
            active-view="month"
            :disable-views="['years', 'week', 'day']"
            events-count-on-year-view
            events-on-month-view="true"
            :twelve-hour="twelveHr"
            @ready="fetchMeetingEvents()"
            @view-change="fetchMeetingEvents()"
            :on-event-click="onApptEventClick"
          >
            <template v-slot:event="{ event, view }">
              <span class="vuecal__event-time" v-if="view == 'month'">
                <span class="show-on-desktop">
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</span
                >
                <small class="show-on-mobile">
                  {{ event.start.formatTime("h:mm am") }}
                </small>
              </span>
              <span class="vuecal__event-time" v-if="view == 'day'">
                <small class="">
                  {{ event.title }}<br />
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</small
                >
              </span>
            </template>
          </vue-cal>
          <div class="field col-12 m-2" v-if="selectedApptSlot != null">
            <b>{{$t('code.selected')}}: </b>
            {{ $formatDateTime(selectedApptSlot.start) }}
          </div>
        </div>
      </div>
      </div>
      <div class="flex justify-content-between">
        <Button
        icon="pi pi-times"
          :label="$t('profile.cancel')"
          class="p-button-raised p-button-danger mt-2"
          @click.stop="resetAppt()"
        />
        <Button
          v-if="!submittingAppt && selectedApptSlot != null"
          icon="pi pi-check"
          :label="$t('menu.submit')"
          class="p-button-raised p-button-success mt-2"
          @click.stop="submitAppt()"
        />
        <Button
          v-if="!submittingAppt && selectedApptSlot == null"
          icon="pi pi-check"
          :label="$t('menu.submit')"
          class="p-button-raised p-button-success mt-2"
         disabled
        />
        <Button
          v-if="submittingAppt"
          icon="pi pi-spin pi-spinner"
          :label="$t('menu.submit')"
          class="p-button-raised p-button-success mt-2"
          disabled
        />
      </div>
    </Dialog>
  </div>
  <div
    v-else-if="
      currentBusiness != null &&
      currentBusiness.BusId > 0 &&
      (currentBusiness.BusActive == false ||
        currentBusiness.BusVisible == false)
    "
  >
    <Message severity="warn">This business is currently not active.</Message>
  </div>
  <!-- <div v-else>
    <Message severity="warn">This business is currently not active.</Message>
  </div> -->
</template>

<script>
import { mapState } from "vuex";
import ServiceFavorite from "../store/Models/ServiceFavorite";
import BusinessFavorite from "../store/Models/BusinessFavorite";
import BusinessAppointment from "../store/Models/BusinessAppointment";
import Customer from "../store/Models/Customer";
import Business from "../store/Models/Business";
import Review from "../store/Models/Review";
import ServiceTypeLabel from "../components/ServiceTypeLabel.vue";
import EventTypeLabel from "../components/EventTypeLabel.vue";
import moment from "moment";
import ServiceGridLayout from "../components/ServiceGridLayout.vue";
import CalendarEvent from "../store/Models/CalendarEvent";
import VueCal from "vue-cal";
import Quote from "../store/Models/Quote";
import QuotedService from "../store/Models/QuotedService";
// import OrderedService from "../store/Models/OrderedService";
import StoreOrder from "../store/Models/StoreOrder";
import StripeCard from "../components/StripeCard.vue";


import Card from "../store/Models/Card";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/es.es.js";

export default {
  name: "BusinessDetails",
  props: {
    currentBusiness: Object,
    hideFav: { default: false, type: Boolean },
    busFav: { default: false, type: Boolean },
  },
  emits: [],
  components: {
    VueCal,
    ServiceGridLayout,
    ServiceTypeLabel,
    EventTypeLabel,
    StripeCard,
  },
  data() {
    return {
      showAddReview: false,
      currentCustomer: null,
      justFav: false,
      value: 0,
      active: 1,
      tempEvents: [],
      savingReview: false,
      viewingService: false,
      showAddToQuote: false,
      servicesForQuote: [],
      availableSlots: [],
      allSlots:[],
      vendorsForQuote: [],
      submittingQuote: false,
      showDateDialog: false,
      layout: "grid",
      showQuoteReviewDialog: false,
      showServicePolicy: false,
      tempService: {},
      dateForQuote: null,
      timeForQuote: null,
      BusinessServiceLocations:[],
      quotes: [],
      sortKey: null,
      sortOrder: null,
      twelveHr: true,
      sortField: null,
      sortOptions: [
        { label: "Price High to Low", value: "!ServPrice" },
        { label: "Price Low to High", value: "ServPrice" },
      ],
      responsiveOptions2: [
        {
          breakpoint: "1500px",
          numVisible: 5,
        },
        {
          breakpoint: "1024px",
          numVisible: 3,
        },
        {
          breakpoint: "768px",
          numVisible: 2,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 4,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      isFullScreen: false,
      images: [],
      viewingTimeSlot: false,
      timeSlotToView: null,
      showReviewReq: false,
      newReview: {
        BusId: 0,
        CustId: 0,
        ReviewTitle: "",
        ReviewText: "",
        ReviewStars: 0,
        ReviewDate: new Date(),
      },
      displayedBus: {},
      showAddReviewWhenLoggedIn: false,
      showBookTourDialog: false,
      newAppt: {
        ApptId: 0,
        BusId: 0,
        CustId: 0,
        SlotId: 0,
        TypeCode: "",
        ApptCustNotes: "",
      },
      showBookApptDialog: false,
      availableApptSlots: [],
      selectedApptSlot: null,
      submittingAppt: false,
      showAddToPurchase: false,
      servicesForPurchase: [],
      servicesForPurchaseQty: [],
      showPurchaseReviewDialog: false,
      showDeliveryDateDialog: false,
      purchase: {},
      availableSlotsForDelivery: [],
      servicesForQuoteQty: [],
      submittingPurchase: false,
      downPmtRequired: false,
      card: "",
      customerCards: [],
      
      errors: [],
      cardOption: null,
      selectedCard: null,
      showSavedCards: true,
      acceptedPmt: false,
      displayCardRequired: false,
      selectedCalEvent: null,
      showSelectedDate:false,
      showNoAppts:false,
      loadingDates:false
    };
  },
  mounted() {
    this.displayedBus = this.currentBusiness;
    this.active = 0;
    this.$log.debug(this.currentBusiness);
    this.$log.debug(this.currentCustomerId);
    if (this.currentCustomerId > 0) {
      this.setCurrentCustomer();
      this.showAddToPurchase = true;
    }

    if (
      this.currentBusiness.Services != null &&
      this.currentBusiness.Services.length > 0
    ) {
      this.currentBusiness.Services.forEach((serv) => {
        if (serv.ServiceForStore == true)
          this.servicesForPurchaseQty.push({ ServId: serv.ServId, Qty: 1 });
      });
      this.fetchDeliveryEvents();

     //console.log(this.servicesForPurchaseQty);
    }
    
  },

  methods: {
    async busView(){
     //console.log("record bus view")
      var busview = {};
      busview.BusId = this.currentBusiness.BusId;


      await fetch("https://ipwhois.app/json/", {
        method: "get",
      })
        .then((response) => response.json())
        .then((jsonData) => {
          this.$log.debug(jsonData.city + " " + jsonData.region);
          if (jsonData){
          this.$log.debug(jsonData);
                this.busView.ViewCity = jsonData.city 
                this.busView.ViewState = jsonData.region
                this.busView.ViewCountry = jsonData.country
                this.busView.ViewIp = jsonData.ip
            
          
        }
        else {
          this.busView.ViewCity = ""; 
          this.busView.ViewState = "";
          this.busView.ViewCountry = "";
          this.busView.ViewIp = "";

        }
        })
        .catch((err) => {
          this.$log.debug(err);
          return false;
        });
    
     
      Business.businessViewed(busview);
    },
    startPurchase() {
      this.currentBusiness.Services.forEach((serv) => {
        if (serv.ServiceForStore == true)
          this.servicesForPurchaseQty.push({ ServId: serv.ServId, Qty: 1 });
      });
     //console.log(this.servicesForPurchaseQty);
      if(this.currentCustomerId>0){
      this.showAddToPurchase = true;
      }
      else {
        window.sessionStorage.setItem("redirecturl", this.$route.path);
        window.sessionStorage.setItem("requestedlogin", "true");
        this.$router.push({
          name: "Login",
        });
      }
    },
    onApptEventClick(event) {
      this.$log.debug("onApptEventClick");
      this.selectedEvent = event;
      this.$log.debug(event);
      this.newAppt.SlotId = event.slotId;
      this.selectedApptSlot = event;
      ////console.log(this.selectedApptSlot.start)
    },
    async submitAppt() {
      this.submittingAppt = true;
      this.$log.debug("saving appointment for " + this.currentBusiness.BusName);
      await BusinessAppointment.createAppt(this.newAppt).then(
        async (response) => {
          this.submittingAppt = false;
          this.$log.debug(response);
          if (response == 1) {
            this.resetAppt();
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("subscriptions.requestsuccess"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("subscriptions.requestfailed"),
              life: 3000,
            });
          }
        }
      );
    },
    resetAppt() {
      this.showBookTourDialog = false;
      this.showBookApptDialog = false;
      this.selectedApptSlot = null;
      this.availableApptSlots = [];
      this.showNoAppts = false;
      this.loadingDates = false;
      this.newAppt = {
        ApptId: 0,
        BusId: 0,
        CustId: 0,
        SlotId: 0,
        TypeCode: "",
        ApptCustNotes: "",
      };
    },
    showActionsMenu(event) {
      this.$refs.mnuOverlay.toggle(event);
    },
    bookTour() {
      if (this.currentCustomerId > 0) {
        this.newAppt = {
          ApptId: 0,
          BusId: this.currentBusiness.BusId,
          CustId: this.currentCustomerId,
          SlotId: 0,
          TypeCode: "TOUR",
          ApptCustNotes: "",
        };
        this.showBookTourDialog = true;
      } else {
        window.sessionStorage.setItem("redirecturl", this.$route.path);
        window.sessionStorage.setItem("requestedlogin", "true");
        this.$router.push({
          name: "Login",
        });
      }
    },
    schCustAppt() {
      if (this.currentCustomerId > 0) {
        this.newAppt = {
          ApptId: 0,
          BusId: this.currentBusiness.BusId,
          CustId: this.currentCustomerId,
          SlotId: 0,
          TypeCode: "MEETING",
          ApptCustNotes: "",
        };
        this.showBookApptDialog = true;
      } else {
        window.sessionStorage.setItem("redirecturl", this.$route.path);
        window.sessionStorage.setItem("requestedlogin", "true");
        this.$router.push({
          name: "Login",
        });
      }
    },
    clearVendorTime(busId) {
      this.$log.debug("clear vendor time");
      var vendorIndex = this.vendorsForQuote.findIndex(
        (vendor) => vendor.BusId == busId
      );
      this.vendorsForQuote[vendorIndex].Slot = "";
      var busIndex = this.quotes.findIndex((quote) => quote.BusId == busId);
      var quote = this.quotes[busIndex];
      quote.SlotId = null;
      this.quotes[busIndex] = quote;
      this.$log.debug(this.quotes);
    },
    fetchEvents() {
      this.$log.debug("fetching events for: " + this.currentBusiness.BusId);
      CalendarEvent.fetchAllByType(
        this.currentBusiness.BusId,
        "DATE"
      ).then((result) => {
       //console.log("Events")
       //console.log(result)
        this.allSlots = result;
        this.$log.debug(this.allSlots);
      });
    },
    fetchAvailableEvents() {
      this.$log.debug("fetching events for: " + this.currentBusiness.BusId);
      CalendarEvent.fetchAvailableByType(
        this.currentBusiness.BusId,
        "DATE"
      ).then((result) => {
       //console.log("Events")
       //console.log(result)
        this.availableSlots = result;
        this.$log.debug(this.availableSlots);
      });
    },
    fetchTourEvents() {
      this.$log.debug(
        "fetching tour events for: " + this.currentBusiness.BusId
      );
      this.loadingDates = true;
      CalendarEvent.fetchAvailableByType(
        this.currentBusiness.BusId,
        "TOUR"
      ).then((result) => {
        this.availableApptSlots = result;
        this.$log.debug(this.availableApptSlots);
        this.loadingDates = false;
        if(!this.availableApptSlots.length>0){
          this.showNoAppts=true;
        }
      });
    },
    displayCalendar() {
      if (this.availableSlotsForDelivery.length > 0) {
        this.showDeliveryDateDialog = true;
      } else {
        this.reviewPurchaseSelection();
      }
    },
    fetchDeliveryEvents() {
      this.$log.debug(
        "fetching delivery events for: " + this.currentBusiness.BusId
      );
      CalendarEvent.fetchAvailableByType(
        this.currentBusiness.BusId,
        "DELIVERY"
      ).then((result) => {
        this.availableSlotsForDelivery = result;
        this.$log.debug(this.availableSlotsForDelivery);
      });
    },
    fetchMeetingEvents() {
      this.$log.debug(
        "fetching meeting events for: " + this.currentBusiness.BusId
      );
       this.loadingDates = true;
     //console.log(this.loadingDates)
      CalendarEvent.fetchAvailableByType(
        this.currentBusiness.BusId,
        "MEETING"
      ).then((result) => {
        this.loadingDates=false;
        this.availableApptSlots = result;
        
       //console.log(this.loadingDates)
        this.$log.debug(this.availableApptSlots);
        if(!this.availableApptSlots.length>0){
          this.showNoAppts=true;
          
        }
      });
    },
    getVendorTime(busId) {
      this.$log.debug("get vendor time");
      var vendorIndex = this.vendorsForQuote.findIndex(
        (vendor) => vendor.BusId == busId
      );
      this.$log.debug(this.vendorsForQuote[vendorIndex]);
      if (this.vendorsForQuote[vendorIndex].Slot != "") {
        return this.$formatDateTime(
          this.vendorsForQuote[vendorIndex].Slot.start
        );
      } else {
        return "";
      }
    },
    onSelectedDate(event){
     //console.log(event)
      this.selectedCalEvent = event;
      this.showSelectedDate = true;
     //console.log(this.selectedCalEvent)
    },
    onDeliveryDateSelected(event) {
      this.$log.debug("onEventClick");
      this.$log.debug(event);

      this.selectedEvent = event;
      this.$log.debug(event);
      this.purchase.SlotId = event.slotId;
      this.purchase.Slot = event;
     //console.log(this.purchase);
    },
    onEventClick(event) {
      this.$log.debug("onEventClick");

      this.availableSlots = [];
      this.selectedEvent = event;
      this.$log.debug(event);
      var busIndex = this.quotes.findIndex(
        (quote) => quote.BusId == event.busId
      );
      var quote = this.quotes[busIndex];
      this.$log.debug(quote);
      quote.SlotId = event.slotId;
      this.quotes[busIndex] = quote;
      var vendIndex = this.vendorsForQuote.findIndex(
        (vendor) => vendor.BusId == event.busId
      );
      var vendor = this.vendorsForQuote[vendIndex];
      vendor.Slot = event;
      this.vendorsForQuote[vendIndex] = vendor;
      this.$log.debug(this.vendorsForQuote[vendIndex]);
      this.$refs.op1[0].hide();
    },
    toggle(event) {
      //this.$log.debug(event);
      //this.$log.debug(this.$refs.op1);

      this.$refs.op1[0].show(event);
    },
    getBusinessDisplayCalendar(busId) {
      this.$log.debug("get business display calendar for " + busId);
      return Business.fetchBusDisplayCalendar(busId);
    },
    startQuote() {
      if (this.currentCustomerId > 0) {
        this.active = 2;
        CalendarEvent.deleteAll();
        this.availableSlots = [];
        this.vendorsForQuote = [];
        this.servicesForQuoteQty = [];
        this.quotes = [];
        this.currentBusiness.Services.forEach((serv) => {
          this.servicesForQuoteQty.push({ ServId: serv.ServId, Qty: 1 });
        });
        this.showAddToQuote = true;
      } else {
        window.sessionStorage.setItem("redirecturl", this.$route.path);
        window.sessionStorage.setItem("requestedlogin", "true");
        this.$router.push({
          name: "Login",
        });
      }
    },
    resetQuote() {
      CalendarEvent.deleteAll();
      this.availableSlots = [];
      this.vendorsForQuote = [];
      this.servicesForQuoteQty = [];
      this.quotes = [];
      this.servicesForQuote = [];
      this.currentBusiness.Services.forEach((serv) => {
        this.servicesForQuoteQty.push({ ServId: serv.ServId, Qty: 1 });
      });
    },

    resetPurchase() {
      CalendarEvent.deleteAll();
      this.availableSlotsForDelivery = [];
      this.servicesForPurchaseQty = [];
      this.servicesForPurchase = [];
      this.currentBusiness.Services.forEach((serv) => {
        if (serv.ServiceForStore == true)
          this.servicesForPurchaseQty.push({ ServId: serv.ServId, Qty: 1 });
      });
      this.purchase = {};
      this.submittingPurchase = false;
      this.acceptedPmt = false;
    },

    getIndex(servId) {
      return this.servicesForQuoteQty.findIndex((i) => i.ServId == servId);
    },
    getPurchaseIndex(servId) {
     //console.log(
       // this.servicesForPurchaseQty.findIndex((i) => i.ServId == servId)
     // );
      return this.servicesForPurchaseQty.findIndex((i) => i.ServId == servId);
    },
    async addToQuote(service) {
      this.$log.debug("before adding service");
      this.$log.debug(this.quotes);
      this.$log.debug(this.availableSlots);
      this.servicesForQuote.push(service.ServId);
      // 1 quote per business
      if (
        this.quotes.filter((quote) => quote.BusId == service.BusId).length == 0
      ) {
        //start new business quote
        var busQuote = new Quote();
        busQuote.QuoteId = 0;
        busQuote.BusId = service.BusId;
        busQuote.CustId = this.currentCustomerId;
        busQuote.QuoteStatus = "Requested";
        busQuote.QuoteRequestedDate = new Date();
        if(this.selectedCalEvent != null){
         busQuote.SlotId = this.selectedCalEvent.slotId; 
        }
        busQuote.QuotedServices = [];
        busQuote.QuotedServices.push(
          new QuotedService({
            QuoteServId: 0,
            QuoteId: 0,
            ServId: service.ServId,
            ServPrice: service.ServPrice,
            QuoteServQty:
              this.servicesForQuoteQty[this.getIndex(service.ServId)].Qty,
            QuoteServTotal: 0,
            Serv: service,
          })
        );
        this.quotes.push(busQuote);
       //console.log(busQuote)
        this.vendorsForQuote.push({ BusId: service.BusId, Slot: this.selectedCalEvent !=null?this.selectedCalEvent:"" });
      } else {
        var busIndex = this.quotes.findIndex(
          (quote) => quote.BusId == service.BusId
        );
        var quote = this.quotes[busIndex];
        quote.QuotedServices.push(
          new QuotedService({
            QuoteServId: 0,
            QuoteId: 0,
            ServId: service.ServId,
            ServPrice: service.ServPrice,
            QuoteServQty:
              this.servicesForQuoteQty[this.getIndex(service.ServId)].Qty,
            QuoteServTotal: 0,
            Serv: service,
          })
        );
        this.quotes[busIndex] = quote;
        
      }
      this.$log.debug("after adding service");
      this.$log.debug(this.quotes);
      this.$log.debug(this.availableSlots);
    },
    removeFromQuote(service) {
      this.$log.debug("before removing service");
      this.$log.debug(this.quotes);
      this.$log.debug(this.availableSlots);
      var busIndex = this.quotes.findIndex(
        (quote) => quote.BusId == service.BusId
      );
      var quote = this.quotes[busIndex];

      var deleteSlots = quote.QuotedServices.length > 1;

      const servIndex = (serv) => serv.ServId == service.ServId;
      quote.QuotedServices.splice(quote.QuotedServices.findIndex(servIndex), 1);
      this.$log.debug(this.servicesForQuote);
      this.servicesForQuote.splice(
        this.servicesForQuote.findIndex((val) => val == service.ServId),
        1
      );
      this.quotes[busIndex] = quote;

      if (deleteSlots) {
        this.vendorsForQuote = this.vendorsForQuote.filter(
          (vendor) => vendor.BusId != quote.BusId
        );
        this.availableSlots = this.availableSlots.filter(
          (slot) => slot.BusId != quote.BusId
        );
      }
      this.$log.debug("after removing service");
      this.$log.debug(this.quotes);
      this.$log.debug(this.availableSlots);
    },
    async addToPurchase(service) {
      this.$log.debug("before adding service");
      this.$log.debug(this.servicesForPurchase);
      // this.$log.debug(this.availableSlots);
      this.servicesForPurchase.push(service);

      this.$log.debug("after adding service");
      this.$log.debug(this.servicesForPurchase);
      // this.$log.debug(this.availableSlots);
    },
    removeFromPurchase(service) {
      this.$log.debug("before removing service from purchase");
      this.$log.debug(this.purchase);
      this.$log.debug(this.servicesForPurchase);
      this.servicesForPurchase.splice(
        this.servicesForPurchase.findIndex((val) => val == service.ServId),
        1
      );
      this.$log.debug("after removing service");
      this.$log.debug(this.purchase);
    },
    showIncludeDateConfirm(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t("code.dateforquote"),
        icon: "pi pi-calendar",
        accept: () => {
          this.showDateDialog = true;
        },
        reject: () => {
          this.showQuoteReviewDialog = true;
        },
      });
    },

    showQuoteDateSelection() {
      this.showDateDialog = true;
    },
    reviewQuoteSelection() {
      this.showDateDialog = false;
      this.showQuoteReviewDialog = true;
    },

    reviewPurchaseSelection() {
      this.showDeliveryDateDialog = false;
      this.purchase.OrderedServices = [];

      this.purchase.BusId = this.currentBusiness.BusId;
      this.purchase.CustId = this.currentCustomerId;
      this.purchase.OrderSubTotal = 0;
      this.purchase.OrderDiscount = 0;
      this.purchase.OrderTax = 0;
      this.purchase.OrderTotalCost = 0;

      this.servicesForPurchase.forEach((serv) => {
       //console.log(serv);
       //console.log(this.servicesForPurchaseQty);
        var servQty =
          this.servicesForPurchaseQty[this.getPurchaseIndex(serv.ServId)].Qty;
        this.purchase.OrderedServices.push({
          OrderedServId: 0,
          OrderId: 0,
          ServId: serv.ServId,
          ServPrice: serv.ServPrice,
          OrderServQty: servQty,
          OrderServTotal: 0,
          Serv: serv,
        });
        this.purchase.OrderSubTotal += serv.ServPrice * servQty;
      });
      this.purchase.OrderSubTotal = Number(this.purchase.OrderSubTotal).toFixed(
        2
      );
      this.purchase.OrderTax = Number(
        this.purchase.OrderSubTotal *
          (this.currentBusiness.BusSalesTaxPercent / 100)
      ).toFixed(2);
      this.purchase.OrderTotalCost = (
        Number(this.purchase.OrderSubTotal) + Number(this.purchase.OrderTax)
      ).toFixed(2);
     //console.log(this.purchase);

      //check for downpmtrequired

      if (this.currentBusiness.BusDownPmtReq == true) {
        this.$log.debug("Down Pmt Req");
        Card.fetchByCustId(this.currentCustomerId).then((result) => {
          this.$log.debug(result);
          this.customerCards = result;
          this.$log.debug("cards: " + this.customerCards.length);
        });
        if (this.customerCards.length > 0) {
          this.cardOption = "savedCard";
        } else {
          this.cardOption = "newCard";
        }
      }
      //
      this.showPurchaseReviewDialog = true;
    },
    async createCard() {
      let cardId = 0;
      await this.$refs.stripeCard.createCard().then((response) => {
        this.$log.debug("New Card");
        this.$log.debug(response);
        if (response != null) {
          this.addingCard = false;
          cardId = response;
          this.$log.debug("card attached");
        } else {
          this.$log.debug("card failed to attach");
        }
      });

      return cardId;
    },
    async submitPurchase(cardId) {
      this.$log.debug("create purchase", cardId);
      await StoreOrder.createOrder(this.purchase, cardId).then(
        async (response) => {
          this.$log.debug(response);
          this.submittingPurchase = false;
          this.acceptedPmt = false;
          this.resetPurchase();
          this.showPurchaseReviewDialog = false;
          this.downPmtRequired = false;
          this.submittingPurchase = false;
          if (response == 1) {
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("store.submittedsuccessfully"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("store.failedtosubmit"),
              life: 3000,
            });
          }
        }
      );
    },

    async purchaseApproved() {
     //console.log("Purchase Approved");
      this.submittingPurchase = true;
      if (this.currentBusiness.BusDownPmtReq != true) {
        this.submitPurchase(0);
      } else {
        this.acceptedPmt = true;
        if (this.cardOption == "newCard") {
          await this.createCard().then(async (response) => {
            if (response != null) {
              //card created and attached succesfull
              this.submitPurchase(response);
            } else {
              //card did not save
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("code.errorcard"),
                life: 3000,
              });
              this.submittingPurchase = false;
              this.acceptedPmt = false;
            }
          });
        } else if (this.selectedCard != null) {
          this.submitPurchase(this.selectedCard.CardId);
        } else {
          this.displayCardRequired = true;
        }
      }
    },
    async submitQuote() {
      this.$log.debug("submitting quote");
      this.submittingQuote = true;
      this.quotes.forEach(async (quote) => {
        this.$log.debug(quote);
        quote.QuoteSubTotal = 0;
        quote.QuoteDiscount = 0;
        quote.QuoteTax = 0;
        quote.QuoteTotal = 0;
        quote.QuoteRequestDate = new Date();
        quote.QuoteLastUpdated = new Date();
        await Quote.createQuote(quote).then(async (response) => {
          this.$log.debug(response);
          if (response == 1) {
            this.resetQuote();
            this.showAddToQuote = false;
            this.showQuoteReviewDialog = false;
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.quotesent"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.quotefailed"),
              life: 3000,
            });
          }
          this.submittingQuote = false;
          this.fetchEvents();
        });
      });
    },
    promotionValid(promotionExpirationDate) {
      if (promotionExpirationDate == null) {
        return true;
      } else {
        return moment().isSameOrBefore(promotionExpirationDate, "day");
      }
    },
    async setCurrentCustomer() {
      await Customer.getCurrentCustomer().then((result) => {
        this.$log.debug(result);
        this.currentCustomer = result;
        if (this.showAddReviewWhenLoggedIn) {
          this.$log.debug("showing add  review");
          this.showAddReview = true;
          this.showAddReviewWhenLoggedIn = false;
        }
      });
    },
    displayReview() {
      if (this.currentCustomerId > 0) {
        this.showAddReview = true;
      } else {
        this.$log.debug("setting showaddreview later to true");
        this.showAddReviewWhenLoggedIn = true;

        window.sessionStorage.setItem("redirecturl", this.$route.path);
        window.sessionStorage.setItem("requestedlogin", "true");
        this.$router.push({
          name: "Login",
        });
      }
    },
    async saveReview() {
      this.$log.debug("Adding review for " + this.currentBusiness.BusName);
      this.showReviewReq = false;
      if (this.newReview.ReviewStars > 0) {
        this.savingReview = true;
        this.newReview.BusId = this.currentBusiness.BusId;
        this.newReview.CustId = this.currentCustomer.CustId;
        await Review.newReview(this.newReview).then(async (response) => {
          this.$log.debug(response);
          if (response == 1) {
            this.savingReview = false;
            this.displayedBus = await Business.fetchById(
              this.currentBusiness.BusId
            );
            this.showAddReview = false;
            this.resetNewReview();
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.posted"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.post"),
              life: 3000,
            });
          }
        });
      } else {
        this.showReviewReq = true;
      }
    },
    resetNewReview() {
      this.showAddReview = false;
      this.newReview = {
        BusId: 0,
        CustId: 0,
        ReviewTitle: "",
        ReviewText: "",
        ReviewStars: 0,
        ReviewDate: new Date(),
      };
    },
    async removeFavorite(busId) {
      this.$log.debug(
        "removing bus favorite " +
          busId +
          " for cust id " +
          this.currentCustomer.CustId
      );
      let favId = this.currentCustomer.BusinessFavorites.find(
        (fav) => fav.BusId == busId
      ).FavId;
      this.$log.debug("fav to remove " + favId);
      await BusinessFavorite.deleteFavorite(favId).then((response) => {
        this.$log.debug(response);
        //   await BusinessFavorites.fetchByCustId(this.currentCustomerId).then((response) => {
        //   this.$log.debug(response);
        //   };
        if (response == 1) {
          this.setCurrentCustomer();
          this.justFav = false;
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.removefailed"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.favoritefailed"),
            life: 3000,
          });
        }
      });
    },
    addServFavorite(servId) {
      this.$log.debug("adding service favorite " + servId);
      if (this.currentCustomerId > 0) {
        ServiceFavorite.addFavorite(this.currentCustomer.CustId, servId).then(
          (response) => {
            this.$log.debug(response);
            if (response == 1) {
              this.setCurrentCustomer();
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("code.favoritedadded"),
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("code.failedtoadd"),
                life: 3000,
              });
            }
          }
        );
      } else {
        window.sessionStorage.setItem("redirecturl", this.$route.path);
        window.sessionStorage.setItem("requestedlogin", "true");
        this.$router.push({
          name: "Login",
        });
      }
    },
    async removeServFavorite(servId) {
      var favId = this.currentCustomer.ServiceFavorites.find(
        (fav) => fav.ServId == servId
      ).FavId;
      this.$log.debug("remove serv favorite " + favId);
      await ServiceFavorite.deleteFavorite(favId).then((response) => {
        this.$log.debug(response);
        if (response == 1) {
          this.setCurrentCustomer();
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.favoritedremove"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.favoritefailed"),
            life: 3000,
          });
        }
      });
    },
    async addBusFavorite(busId) {
      this.$log.debug("adding bus favorite " + busId);
      if (this.currentCustomerId > 0) {
        this.justFav = true;
        await BusinessFavorite.addFavorite(
          this.currentCustomer.CustId,
          busId
        ).then((response) => {
          this.$log.debug(response);
          this.setCurrentCustomer();
          if (response == 1) {
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.favoritedadded"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.failedtoadd"),
              life: 3000,
            });
          }
        });
      } else {
        window.sessionStorage.setItem("redirecturl", this.$route.path);
        window.sessionStorage.setItem("requestedlogin", "true");
        this.$router.push({
          name: "Login",
        });
      }
    },
    handleEventClick: function (args) {
      this.timeSlotToView = {
        title: args.event.title,
        start: moment(args.event.start).format("h:mm a"),
        end: moment(args.event.end).format("h:mm a"),
        date: moment(args.event.start).format("MMMM Do YYYY"),
      };
      this.viewingTimeSlot = true;
    },
    viewDetails: function (data) {
      this.setTempService(data);
      this.viewingService = true;
    },
    setTempService(data) {
      this.tempService = {
        ServId: data.ServId,
        ServTitle: data.ServTitle,
        ServShortDesc: data.ServShortDesc,
        ServHtmldesc: data.ServHtmldesc,
        ServPrice: data.ServPrice,
        ServPriceCalculated: data.ServPriceCalculated,
        ServImage: data.ServImage,
        ServActive: data.ServActive,
        ServPromotionExpires: data.ServPromotionExpires,
        ServiceTypes: data.ServiceTypes,
        ServiceImages: data.ServiceImages,
      };
    },
    onSortChange(event) {
      const value = event.value.value;
      const sortValue = event.value;

      if (value.indexOf("!") === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
        this.sortKey = sortValue;
      } else {
        this.sortOrder = 1;
        this.sortField = value;
        this.sortKey = sortValue;
      }
    },
    starsColor(rating) {
      if (rating > 0 && rating <= 1) {
        return "#ff4545";
      } else if (rating > 1 && rating <= 2) {
        return "#ffa534";
      } else if (rating > 2 && rating <= 3) {
        return "#ffe234";
      } else if (rating > 3 && rating <= 4) {
        return "#b7dd29";
      } else return "#689F38";
    },
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) {
        this.setCurrentCustomer();
        this.showAddToPurchase = true;
      }
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
      lang: (state) => state.lang,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style >
.p-confirm-popup {
  z-index: 2000;
}
i {
  margin-right: 3px;
}
ul.p-tabview-nav {
  justify-content: center;
}
.fc-list-event-time,
fc-list-event-graphic,
fc-list-event-title,
fc-list-event-dot {
  border-right: none !important;
  border-left: none !important;
}
.fc-event-main {
  border-color: #dee2e6 !important;
}
.fc .fc-toolbar .fc-button {
  background: #ffffff !important;
  border: 1px solid #ced4da !important;
  color: #495057 !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s !important;
}
/* .fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background: var(--mr-primary-color) !important;
  border-color: var(--mr-primary-color) !important;
  color: #ffffff !important;
} */

.fc-header-toolbar {
  flex-wrap: wrap;
}
.fc-toolbar-chunk {
  margin-right: 1vh;
}
.excellent .p-progressbar-value {
  background-color: var(--mr-primary-color) !important;
}
.good .p-progressbar-value {
  background-color: #b052c0 !important;
}
.average .p-progressbar-value {
  background-color: #c075cd !important;
}
.below .p-progressbar-value {
  background-color: #cd91d7 !important;
}
.poor .p-progressbar-value {
  background-color: #d7a7df !important;
}
.full-height-side-bar .p-sidebar-content {
  height: 100%;
}
.service-grid .p-dataview-content .grid {
  padding: 1rem !important;
  padding-bottom: 3rem !important;
}

.large-stars .p-rating-icon {
  font-size: xx-large !important;
}
.p-tabview-nav {
  display: flex !important;
  flex-wrap: wrap !important;
}

/* ul.p-tabview-nav {
      position: -webkit-sticky;
  position: sticky; 
  top:0;
} */
</style>

