<template>
  <div>LoggedIn</div>
</template>
<script>
export default {
  name: "LoggedIn",
  props: {},
  data() {
    return {
      url: process.env.VUE_APP_MYRESERVS_URL,
    };
  },
  mounted() {
    this.$log.debug("Logged In Mounted");

    var redirect = window.sessionStorage.getItem("returnUrl");
   //console.log(redirect);
    this.$router.push({
      path: redirect,
    });
  },
};
</script>
