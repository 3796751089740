
/* eslint-disable */

import { Model } from '@vuex-orm/core'
import CustEvent from "./CustEvent"
import GuestParty from './GuestParty'


export default class CustEventRsvp extends Model {
  static entity = 'custeventrsvp'

  static primaryKey = 'RsvpId'

  static fields() {
    return {
      RsvpId: this.uid(),
      EventId: this.attr(null),
      PartyId: this.attr(null),
      RsvpTotalAdults: this.attr(null),
      RsvpTotalKids: this.attr(null),
      RsvpTotalPeople: this.attr(null),
      RsvpAttending: this.attr(null),
      RsvpGuid: this.attr(null),
      
          
      //VIRTUAL
      Event: this.belongsTo(CustEvent, 'EventId'),
      Party: this.belongsTo(GuestParty, 'PartyId'),
    }
  }
  // static async createCustEventRsvp(custeventrsvp) {

  //  //console.log("creating new cust event")
  //   const result = await CustEventRsvp.api().post('/api/CustEventRsvp/',
  //   custeventrsvp).then((result) => {
  //       if (result.response.status == 201) {
  //         //console.log("success")
  //         return 1;
  //       }
  //       if (result.response.status == 200) {
  //         //console.log("duplicate")
  //         return 1;
  //       }
  //       else {
  //         //console.log("error");
  //         return 2;
  //       }
  //     }).catch(error => {
  //       //console.log(error.response);
  //       return 2;
  //     });
  //   return result;
  // }

  // static async updateCustEventRsvp(custeventrsvp) {
  //   //console.log("updating customer event)
  //   const result = await CustEventRsvp.api().put('/api/CustEventRsvp/' + custeventrsvp.EventId,
  //   custeventrsvp).then((result) => {
  //       if (result.response.status == 204) {
  //         //console.log("success")
  //         return 1;
  //       }
  //       if (result.response.status == 200) {
  //         //console.log("saved")
  //         return 1;
  //       }
  //       else {
  //         //console.log("error");
  //         return 2;
  //       }
  //     }).catch(error => {
  //       //console.log(error.response);
  //       return 2;
  //     });
  //   return result;
  // }

  // static async fetchByCustId(CustId) {
  //   //CustEvent.deleteAll();
  //   //GuestParty.deleteAll();
  //  //SharedGuestList.deleteAll();
  //  //console.log("fetch guest lists for " + CustId)
  //   var result = await CustEvent.api().get('/api/CustEvent/GetCustomerCustEvents/' + CustId);
  //  //console.log(result)
  //   return CustEvent.query()
  //     .withAllRecursive()
  //     .orderBy('EventId', 'desc').all();
  // }

  // static async deleteCustEvent(id) {
  //   //console.log("delete guest list " + id)
  //   var result = await CustEvent.api().delete('/api/CustomerEvent/' + id).then((result) => {
  //     if (result.response.status == 204) {
  //       //console.log("success")
  //       return 1;
  //     }
  //     if (result.response.status == 200) {
  //       //console.log("duplicate")
  //       return 1;
  //     }
  //     else {
  //       //console.log("error");
  //       return 2;
  //     }
  //   }).catch(error => {
  //     //console.log(error.response);
  //     return 2;
  //   });
  //   return result;
  // }


}

