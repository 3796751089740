import { Model } from '@vuex-orm/core'
/* eslint-disable */
import GuestList from "./GuestList"
import CustEventRsvp from './CustEventRsvp'

export default class GuestParty extends Model {
  static entity = 'guestPartys'
  
  static primaryKey = 'PartyId'
  
  static fields () {
    return {
      PartyId: this.uid(),
      ListId: this.attr(null),
      PartyName: this.attr(null),
      PartyTotalPeople: this.attr(null),
      PartyTotalAdults:this.attr(null),
      PartyTotalKids:this.attr(null),
      //virtual
      List: this.belongsTo(GuestList, 'ListId'),
      CustEventRsvps: this.hasMany(CustEventRsvp,"PartyId")
    }
  }

  // static async addComment(comment) {
  //   //console.log("creating new quote comment")
  //    const result =  await QuoteComment.api().post('/api/quotecomments/',
  //     comment).then((result) => {
  //       if (result.response.status == 201) {
  //         //console.log("success")
  //         return 1;
  //       }
  //       if(result.response.status == 200){
  //         //console.log("duplicate")
  //         return 1;
  //       }
  //       else{
  //         //console.log("error");
  //         return 2;
  //       } 
  //     }).catch(error => {
  //       //console.log(error.response);
  //       return 2;
  //     });
  //     return result;
  //     }
}

