<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content mt-3">
    <DataView :value="appts" layout="list" class="service-grid">
      <template #empty>
        {{ $t("quotes.noreservations") }}
      </template>
      <template #list="slotProps">
        <div class="col-12 md:col-6 lg:col-4">
          <Card class="m-2">
            <template #title>
              <div class="flex flex-wrap justify-content-between">
                <Chip
                  :label="getTimeSlotType(slotProps.data.TypeCode)"
                  class="mr-2 mb-2 chip-sm primary-chip"
                />
                <Chip
                  v-if="slotProps.data.ApptCanceled == true"
                  :label="$t('quotes.canceledLabel')"
                  class="mr-2 mb-2 chip-sm error-chip"
                />
              </div>
            </template>
            <template v-slot:content>
              <div class="flex flex-wrap justify-content-around">
                <div class="mb-2" style="text-align: center">
                  <b
                    ><i class="pi pi-map-marker"></i
                    ><a
                      v-if="slotProps.data.Bus != null"
                      :href="webHost + slotProps.data.Bus.BusHandle"
                      target="_blank"
                      >{{ slotProps.data.Bus.BusName }}</a
                    ></b
                  ><br />

                  <span v-if="slotProps.data.Slot">
                    <b>
                      <i class="pi pi-calendar"></i>
                      {{ $formatDateTimeToDate(slotProps.data.Slot.SlotDate) }}
                      <br />
                      <i class="pi pi-clock"></i>
                      {{ $formatTime(slotProps.data.Slot.SlotStartTime) }}

                      -
                      {{ $formatTime(slotProps.data.Slot.SlotEndTime) }}
                    </b>
                  </span>
                  <span v-else>
                    <i class="pi pi-calendar"></i>{{ $t("calendar.dat") }}
                  </span>
                </div>
              </div>
            </template>
            <template #footer>
              <div class="flex flex-wrap justify-content-evenly">
                <Button
                  v-if="slotProps.data.ApptCanceled != true"
                  icon="pi pi-pencil"
                  :label="$t('menu.edit')"
                  class="p-button-info p-button-sm mb-2"
                  @click.stop="setApptUpdate(slotProps.data)"
                />
                <Button
                  v-if="slotProps.data.ApptCanceled != true"
                  icon="pi pi-calendar-times"
                  :label="$t('profile.cancel')"
                  class="p-button-danger p-button-sm mb-2"
                  @click.stop="requestCancel(slotProps.data)"
                />
                
              </div>
            </template>
          </Card>
        </div>
      </template>
    </DataView>
    <Dialog
      v-model:visible="showEditApptDialog"
      :modal="true"
      :contentStyle="{ overflow: 'scroll' }"
      :header="$t('calendar.updateappt')"
      :dismissableMask="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <div class="p-fluid formgrid grid">
        <div class="field col-12 text-left">
          <Textarea
            class="mt-2"
            v-model="selectedAppt.ApptCustNotes"
            :placeholder="$t('calendar.appptdetails')"
            :autoResize="true"
            rows="5"
            cols="30"
            style="width: 100%"
          />
        </div>
        <div class="field col-12 m-2" v-if="selectedAppt.Slot != null">
          <b>Currently Selected: </b>
          {{ $formatDateTime(selectedAppt.Slot.start) }}
        </div>
        <div class="field col-12" style="">
          <vue-cal
            :events="availableApptSlots"
            style="height: 500px"
            active-view="month"
            :disable-views="['years', 'week', 'day']"
            events-count-on-year-view
            events-on-month-view="true"
            :twelve-hour="twelveHr"
            @ready="fetchMeetingEvents()"
            @view-change="fetchMeetingEvents()"
            :on-event-click="onApptEventClick"
          >
            <template v-slot:event="{ event, view }">
              <span class="vuecal__event-time" v-if="view == 'month'">
                <span class="show-on-desktop">
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</span
                >
                <small class="show-on-mobile">
                  {{ event.start.formatTime("h:mm am") }}
                </small>
              </span>
              <span class="vuecal__event-time" v-if="view == 'day'">
                <small class="">
                  {{ event.title }}<br />
                  {{ event.start.formatTime("h:mm am") }} -
                  {{ event.end.formatTime("h:mm am") }}</small
                >
              </span>
            </template>
          </vue-cal>
        </div>
      </div>
      <div class="flex justify-content-between">
        <Button
          :label="$t('profile.cancel')"
          class="p-button-raised p-button-danger mt-2"
          @click.stop="resetAppt()"
        />
        <Button
          v-if="!editingAppt"
          icon="pi pi-check"
          :label="$t('menu.submit')"
          class="p-button-raised p-button-success mt-2"
          @click.stop="editAppt()"
        />
        <Button
          v-else
          icon="pi pi-spin pi-spinner"
          :label="$t('menu.submit')"
          class="p-button-raised p-button-success mt-2"
          disabled
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import BusinessAppointment from "../store/Models/BusinessAppointment";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import { mapState } from "vuex";
import CalendarEvent from "../store/Models/CalendarEvent";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/es.es.js";

export default {
  name: "CusomerAppointments",
  components: { LoadingOverlay, VueCal },
  data() {
    return {
      selectedAppt: {},
      loading: false,
      showEditApptDialog: false,
      webHost: process.env.VUE_APP_MYRESERVS_URL,
      filters: {},
      appts: [],
      errors: [],
      availableApptSlots: [],
      selectedApptSlot: null,
      editingAppt: false,
      twelveHr: true,
    };
  },

  async mounted() {
    this.$log.debug("customer appointments mounted");
   // this.$log.debug(this.currentCustomerId);

    if (this.currentCustomerId > 0) {
      await this.setCurrentCustomer(this.currentCustomerId);
    }
  },
  methods: {
    resetAppt(){
      this.selectedAppt = {};
      this.selectedApptSlot = null;
      this.editingAppt = false;
      this.showEditApptDialog = false;
      this.availableApptSlots = [];
    },
    onApptEventClick(event) {
      this.$log.debug("onApptEventClick");
      this.selectedEvent = event;
      this.$log.debug(event);
      this.selectedAppt.SlotId = event.slotId;
      this.selectedAppt.Slot = event;
      ////console.log(this.selectedApptSlot.start)
    },
    fetchMeetingEvents() {
      this.$log.debug("fetching meeting events for: ");
      CalendarEvent.fetchAvailableByType(
        this.selectedAppt.BusId,
        this.selectedAppt.TypeCode
      ).then((result) => {
        this.availableApptSlots = result;
        this.$log.debug(this.availableApptSlots);
      });
    },
    getTimeSlotType(serviceType) {
      this.$log.debug(serviceType);
      switch (serviceType) {
        case "DATE":
          return this.$t("timeslottypes.date");
        case "EVENT":
          return this.$t("timeslottypes.event");
        case "TOUR":
          return this.$t("timeslottypes.tour");
        case "MEETING":
          return this.$t("timeslottypes.meeting");
        default:
          return "";
      }
    },
    getStatusDesc(status) {
      switch (status) {
        case "Set":
          return this.$t("custreserv.allset");
        case "PendingDownpayment":
          return this.$t("custreserv.pendingdownpayment");
        case "RequestCancel":
          return this.$t("custreserv.cust");
        default:
          return "";
      }
    },
    getStatusTag(status) {
      switch (status) {
        case "Set":
          return "success";
        case "RequestCancel":
          return "danger";
        case "PendingDownpayment":
          return "warning";
        default:
          return "info";
      }
    },
    openAppt(appt) {
      this.$log.debug(appt);
      this.selectedAppt = appt;
      this.viewingAppt = true;
    },
    async setCurrentCustomer() {
      this.loading = true;
      await BusinessAppointment.fetchByCustId(this.currentCustomerId).then(
        (result) => {
          this.$log.debug(result);
          this.appts = result;

          this.$log.debug(this.selectedAppt);
          if (this.selectedAppt != null && this.selectedAppt.ApptId != null) {
            this.selectedAppt = BusinessAppointment.query()
              .withAllRecursive()
              .find(this.selectedAppt.ApptId);
          }
        }
      );
      this.loading = false;
    },
    async requestCancel(appt) {
      this.$confirm.require({
        message: this.$t("calendar.cancelapptdesc"),
        header: this.$t("calendar.cancelappt"),
        icon: "pi pi-calendar-plus",
        acceptLabel: this.$t("menu.yes"),
        accept: async () => {
          await BusinessAppointment.cancel(appt.ApptId).then(
            async (response) => {
              this.$log.debug(response);
              this.setCurrentCustomer();
              if (response == 1) {
                this.$toast.add({
                  severity: "success",
                  summary: this.$t("custreserv.success"),
                  detail: this.$t("calendar.apptcanceled"),
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: this.$t("code.errors"),
                  detail: this.$t("calendar.apptfailedcancel"),
                  life: 3000,
                });
              }
            }
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    setApptUpdate(appt) {
     //console.log(appt);
      this.selectedAppt = appt;
      this.showEditApptDialog = true;
    },
    async editAppt() {
      this.editingAppt = true;
      await BusinessAppointment.updateAppt(this.selectedAppt).then(
        async (response) => {
          this.editingAppt=false;
          this.$log.debug(response);
          this.setCurrentCustomer();
          this.availableApptSlots = [];
          this.selectedAppt = {};
          this.showEditApptDialog=false;
          if (response == 1) {
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("calendar.apptupdated"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("calendar.apptfailedupdate"),
              life: 3000,
            });
          }
        }
      );
    },
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCustomerReservs();
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style>
.p-datatable-header {
  background-color: white !important;
}
</style>
