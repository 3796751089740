import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Customer from "./Customer"

export default class Device extends Model {
  static entity = 'devices'

  static primaryKey = 'DeviceId'

  static fields() {
    return {
      DeviceId: this.uid(),
      CustId: this.attr(null),
      ProviderToken: this.attr(null),
      DeviceOs: this.attr(null),
      DeviceBrowser: this.attr(null),
      DeviceRegisteredDate: this.attr(null),    
      DeviceLastUsed: this.attr(null),
      DeviceActive: this.attr(null),

      Cust: this.belongsTo(Customer, 'CustId')
    }
  }

  static async registerUsage(device) {
    //console.log("at device register usage")
    //console.log(device)

    const result = await Device.api().post('/api/devices/', device).then((result) => {
      //console.log(result)
      if (result.response.status == 200 || result.response.status == 204 || result.response.status == 201) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }

}