
/* eslint-disable */

import { Model } from '@vuex-orm/core'
import Customer from "./Customer"
import CustEventTodo from "./CustEventTodo"
import CustSharedEvent from "./CustSharedEvent"
import CustEventService from "./CustEventService"
import CustEventRsvp from "./CustEventRsvp"
import CustEventRegistry from "./CustEventRegistry"
import GuestParty from "./GuestParty"
import GuestList from './GuestList'


export default class CustEvent extends Model {
  static entity = 'custEvent'

  static primaryKey = 'EventId'

  static fields() {
    return {
      EventId: this.uid(),
      CustId: this.attr(null),
      EventTitle: this.attr(null),
      EventDate: this.attr(null),
      EventStartTime: this.attr(null),
      EventEndTime: this.attr(null),
      EventType: this.attr(null),
      EventCity: this.attr(null),
      EventState: this.attr(null),
      TotalPeopleEst: this.attr(null),
      TotalAdultsEst: this.attr(null),
      TotalKidsEst: this.attr(null),
      TotalPeopleInvited: this.attr(null),
      TotalAdultsInvited: this.attr(null),
      TotalKidsInvited: this.attr(null),
      ListId: this.attr(null),
      TotalPeopleRsvp: this.attr(null),
      TotalAdultsRsvp: this.attr(null),
      TotalKidsRsvp: this.attr(null),
      RsvpOpenDateTime: this.attr(null),
      RsvpCloseDateTime: this.attr(null),
      TotalTasksCompleted: this.attr(null),
      TotalTasksPending: this.attr(null),
      TotalTasks: this.attr(null),
      BudgetTotal: this.attr(null),
      BudgetSpent: this.attr(null),
      BudgetLeft: this.attr(null),
      RegistryVisible: this.attr(null),
      


      //VIRTUAL
      Cust: this.belongsTo(Customer, 'CustId'),
      CustEventTodos: this.hasMany(CustEventTodo, 'EventId'),
      CustSharedEvents: this.hasMany(CustSharedEvent, 'EventId'),
      CustEventServices: this.hasMany(CustEventService,"EventId"),
      CustEventRsvps: this.hasMany(CustEventRsvp,"EventId"),
      CustEventRegistries: this.hasMany(CustEventRegistry,"EventId"),
      List: this.belongsTo(GuestList,"ListId")

    }
  }
  static async createCustEvent(custEvent) {

   //console.log("creating new cust event")
    const result = await CustEvent.api().post('/api/CustEvents/',
      custEvent).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if (result.response.status == 200) {
          //console.log("duplicate")
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }

  static async updateCustEvent(custevent) {
    //console.log("updating customer event)
    const result = await CustEvent.api().put('/api/CustEvents/' + custevent.EventId,
    custevent).then((result) => {
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if (result.response.status == 200) {
          //console.log("saved")
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    return result;
  }

  static async fetchByCustId(CustId) {
    CustEvent.deleteAll();
    GuestParty.deleteAll();
    CustSharedEvent.deleteAll();
    CustEventRegistry.deleteAll();
    CustEventTodo.deleteAll();
    CustEventService.deleteAll();
    CustEventRsvp.deleteAll();
   //console.log("fetch cust events for " + CustId)
    var result = await CustEvent.api().get('/api/CustEvents/GetCustomerCustEvents/' + CustId);
   //console.log(result)
    return CustEvent.query()
      .withAllRecursive()
      .orderBy('EventId', 'desc').all();
  }

  static async fetchPublicEvent(EventId) {
    CustEvent.deleteAll();
    GuestParty.deleteAll();
    CustSharedEvent.deleteAll();
    CustEventRegistry.deleteAll();
    CustEventTodo.deleteAll();
    CustEventService.deleteAll();
    CustEventRsvp.deleteAll();
    //console.log("fetch cust events for " + CustId)
    var result = await CustEvent.api().get('/api/CustEvents/GetPublicEvent/' + EventId);
   //console.log(result)
    return CustEvent.query()
      .withAllRecursive()
      .orderBy('EventId', 'desc').all();
  }
  static async deleteCustEvent(id) {
    //console.log("delete guest list " + id)
    var result = await CustEvent.api().delete('/api/CustEvents/' + id).then((result) => {
      if (result.response.status == 204) {
        //console.log("success")
        return 1;
      }
      if (result.response.status == 200) {
        //console.log("duplicate")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }


}

