import { Model } from '@vuex-orm/core'
// import Customer from "./Customer"
// import Business from "./Business"

export default class SubscriptionDefs extends Model {
  static entity = 'subscriptionDefs'
  
  static primaryKey = 'SubDefId'
  
  static fields () {
    return {
      SubDefId: this.uid(),
      SubDefStartDate: this.attr(null),
      SubDefEndDate: this.attr(null),
      SubDefCurrent: this.attr(null),
      SubDefPriceMo: this.attr(null),
      SubDefPriceYr: this.attr(null),
      SubTitle: this.attr(null),
      SubSubTitle: this.attr(null),
      SubDesc: this.attr(null),
      SubDefTrialLength: this.attr(null),
      SubscriptionLevelCode: this.attr(null),


      
    }
  }

  static async fetchCurrentSubDef() {
    SubscriptionDefs.deleteAll();
    await SubscriptionDefs.api().get('/api/SubscriptionDefs/GetCurrentSubscriptionDefs/')
    return SubscriptionDefs.query().withAllRecursive().first();
  }
  static async fetchCurrentFree() {
    SubscriptionDefs.deleteAll();
    await SubscriptionDefs.api().get('/api/SubscriptionDefs/GetCurrentFree/')
    return SubscriptionDefs.query().withAllRecursive().first();
  }
  static async fetchCurrentBasic() {
    SubscriptionDefs.deleteAll();
    await SubscriptionDefs.api().get('/api/SubscriptionDefs/GetCurrentBasic/')
    return SubscriptionDefs.query().withAllRecursive().first();
  }
  static async fetchCurrentPremium() {
    SubscriptionDefs.deleteAll();
    await SubscriptionDefs.api().get('/api/SubscriptionDefs/GetCurrentPremium/')
    return SubscriptionDefs.query().withAllRecursive().first();
  }
}

