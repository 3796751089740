<template>
  <div
    class="grid grid-nogutter  justify-content-center footer purple pb-5"
    style="text-align: center; font-size: small"
  >
    <div class="col-12 p-2">
      
      <span
        class="pointer"
        style="
          color: white;
          font-family: 'Leckerli One';
          font-size: 30px;
          font-style: normal;
          font-variant: normal;
          font-weight: 100;
          line-height: 63.7px;
          text-align: center;
          
        "
        @click.stop="
          this.$router.push({
            name: 'MRHome',
          })
        "
      >
        MyReservs
      </span>
    </div>
    <div class="col-6 md:col-3 lg:col-3 pl-5">
      <strong>{{ $t("footer.business") }}</strong
      ><br />
      <span class="pointer" @click.stop="$router.push({  name: 'MRAbout'})">{{
        $t("footer.about")
      }}</span
      ><br />
      <span
        class="pointer"
        
        @click.stop="$router.push({ name: 'FunctionalityBusiness' })"
        > {{ $t("footer.businessfeatures") }}</span
      ><br/>
      <span
        class="pointer"
        @click.stop="$router.push({ name: 'FunctionalityCustomer' })"
        >{{ $t("footer.customerfeatures") }}</span
      ><br />
      <span
        class="pointer"
        @click.stop="$router.push({ name: 'PrivacyPolicy' })"
        >{{ $t("footer.privacy") }}</span
      ><br />
      <span
        class="pointer"
        @click.stop="$router.push({ name: 'TermsAndConditions' })"
        >{{ $t("footer.terms&con") }}</span
      ><br />
    </div>
    <div class="col-6 md:col-3 lg:col-3">
      <strong> {{ $t("footer.menu") }}</strong
      ><br />
      <span v-if="userLoggedIn == false">
        <span class="pointer" @click.stop="SignIn">{{ $t("menu.login") }}</span
        ><br />
        <span class="pointer" @click.stop="SignIn">{{
          $t("menu.createacc")
        }}</span
        ><br />
      </span>
      <span v-else>
         <span class="pointer" @click.stop="SignOut">{{
          $t("menu.signout")
        }}</span
        ><br />
      </span>
      <span
        class="pointer"
        @click.stop="$router.push({ name: 'ListMyBusiness' })"
        >{{ $t("menu.listmybusiness") }}</span
      ><br />
      <!-- <span
        class="pointer"
        @click.stop="$router.push({ name: 'SearchResults' })"
        >{{ $t("footer.directory") }}</span
      ><br /> -->
    </div>
    <div class="col-6 md:col-3 lg:col-3">
      <strong> {{ $t("footer.support") }}</strong
      ><br />
  
      <span class="pointer" @click.stop="$router.push({ name: 'FAQ' })">{{
        $t("footer.faq")
      }}</span
      ><br />
          <!-- {{ $t("footer.handbook") }} <br /> -->
      <!-- {{ $t("footer.chatbot") }}<br /> -->
      <span class="pointer" @click.stop="$router.push({ name: 'ContactUs' })">{{
        $t("footer.contactus")
      }}</span
      ><br />
    </div>
    <div class="col-12">
      <div class="p-2" style="text-align: center">
        <b>{{ $t("footer.rgv") }}</b>
        <p class="mr-5 ml-5">
          {{ $t("footer.myreservs") }}
        </p>
      </div>
    </div>
    <div class="col-12" style="text-align: center">
      <div class="flex justify-content-center">
        <span class="mr-3">
          <a
            :href="'https://www.facebook.com/MyReservs'"
            target="_blank"
            v-tooltip.bottom="'Visit us on FB!'"
            ><i
              class="pi pi-facebook"
              style="font-size: x-large; color: white"
            ></i
          ></a>
        </span>

        <span class="mr-3">
          <a
            :href="'https://twitter.com/MyReservs'"
            target="_blank"
            v-tooltip.bottom="'Visit us on Twitter!'"
            ><i
              class="pi pi-twitter"
              style="font-size: x-large; color: white"
            ></i
          ></a>
        </span>

        <span class="mr-3">
          <a
            :href="'https://www.instagram.com/myreservsllc/'"
            target="_blank"
            v-tooltip.bottom="'Visit us on Instagram!'"
            ><i
              class="pi pi-instagram"
              style="font-size: x-large; color: white"
            ></i
          ></a>
        </span>
        <span>
          <a
            :href="'https://www.youtube.com/channel/UC0m6lRsUpkU58Oxpu2o6tuA'"
            target="_blank"
            v-tooltip.bottom="'Visit us on YouTube!'"
            ><i class="pi pi-youtube" style="font-size: x-large; color: white"></i
          ></a>
        </span>
      </div>
    </div>

    <div class="col-12 mt-2" style="text-align: center">
      <span> &copy; {{ $currentYear() }} MyReservs. All Rights Reserved.</span>
    </div>
  </div>
</template>
<script>
 
import { mapState } from "vuex";

export default {
  name: "ReservsFooter",
  components: {},
  methods: {
        async SignIn() {
      window.sessionStorage.setItem("redirecturl",this.$route.path);
      window.sessionStorage.setItem("requestedlogin","true");
      this.$router.push({
        name: "Login"
      });
    },
    
    async SignOut() {
      this.$SignOutRedirect();
    },
  },
  computed: mapState({
    userLoggedIn: (state) => state.userLoggedIn,
    lang: (state) => state.lang,
    loggingIn: (state) => state.loggingIn,
  }),
};






</script>