<template>
  <div class="bg">
    <div class="flex justify-content-end">
      <Button
        :label="otherLang"
        class="p-button-text p-button-primary"
        style="color: white !important"
        @click.stop="switchLanguage()"
      />
    </div>
    <div
      class="grid justify-content-center m-5 align-items-center"
      style="background-color: rgba(255, 255, 255, 0.1)"
    >
      <div class="col-12 center-text">
        <div>
          <div
            style="
              color: white;
              font-family: 'Leckerli One';
              font-size: 3em;
              text-align: center;
            "
          >
            MyReservs
          </div>

          <!-- 
          <span style="
              color: white;
              font-size: 5em;
              font-style: normal;
              font-variant: normal;
              font-weight: 100;
              
              text-align: center;
            ">COMING SOON</span>  -->
        </div>
      </div>
      <div class="col-12" style="color: white">
        <div class="col-12">
          <img
            src="@/assets/comingsoon.gif"
            style="width: 100%; max-width: 500px; height: auto"
          />
          <div
            class="flex flex-wrap justify-content-center"
            style="color: white"
          >
            <div
              class="link pointer p-5 m-5"
              @click.stop="businessFeatures = true"
              style="background-color: rgba(255, 255, 255, 0.1)"
            >
              <i class="pi pi-briefcase" style="font-size: 50px"></i><br />
              <span style="font-size: xx-large">{{
                $t("footer.businessfeatures")
              }}</span><br>
              <Button
                :label="$t('menu.moredetails')"
                class="p-button-text grow"
                :style="'font-weight: bolder; color: white !important'"
                 @click.stop="businessFeatures = true"
              />
            </div>
            <div
              class="link pointer p-5 m-5"
              @click.stop="customerFeatures = true"
              style="background-color: rgba(255, 255, 255, 0.1)"
            >
              <i class="pi pi-users" style="font-size: 50px"></i><br />
              <span style="font-size: xx-large">{{
                $t("footer.customerfeatures")
              }}</span><br>
              <Button
                :label="$t('menu.moredetails')"
                class="p-button-text grow"
                :style="'font-weight: bolder; color: white !important'"
                @click.stop="customerFeatures = true"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div class="mb-2" style="color: white">
          <b>{{ $t("contactus.launchsoon") }}</b>
        </div>
        <div class="flex flex-wrap justify-content-around" style="color: white">
          <div>
            <Checkbox value="notified" v-model="options" />&nbsp;{{
              $t("contactus.notified")
            }}
          </div>
          <div>
            <Checkbox value="busOwner" v-model="options" />&nbsp;{{
              $t("contactus.busOwner")
            }}
          </div>
          <div>
            <Checkbox value="betaTesting" v-model="options" />&nbsp;{{
              $t("contactus.betaTesting")
            }}
          </div>
        </div>
      </div>
      <div class="col-12 mb-5">
        <div class="p-inputgroup pl-2 pr-2">
          <InputText
            type="text"
            class="p-inputtext-md"
            :placeholder="$t('message.email')"
            v-model="feedback.TicketEmail"
            autofocus
          />

          <!-- <Dropdown
              v-model="feedback.TicketCat"
              :options="feedbackTopics"
              optionLabel="label"
              :placeholder="$t('contactus.interestedin')"
              style="width: 40%; border-radius: 0;text-align:left"
            /> -->

          <Button
            v-if="!submitting"
            :label="$t('menu.signup')"
            class="p-button-secondary"
            @click.stop="validateFeedback()"
          />

          <Button
            v-else
            icon="pi pi-spin pi-spinner"
            class="p-button-secondary"
            disabled
          />
        </div>
      </div>

      <div class="col-12 mt-5" style="left: 0; bottom: 0; width: 100%">
        <div class="p-2" style="text-align: center; color: white">
          <b>{{ $t("footer.rgv") }}</b>
          <p class="mr-5 ml-5">
            {{ $t("footer.myreservs") }}
          </p>
        </div>

        <div class="col-12" style="text-align: center">
          <div class="flex justify-content-center">
            <span class="mr-3">
              <a
                :href="'https://www.facebook.com/MyReservs'"
                target="_blank"
                v-tooltip.bottom="'Visit us on FB!'"
                ><i
                  class="pi pi-facebook"
                  style="font-size: x-large; color: white"
                ></i
              ></a>
            </span>

            <span class="mr-3">
              <a
                :href="'https://twitter.com/MyReservs'"
                target="_blank"
                v-tooltip.bottom="'Visit us on Twitter!'"
                ><i
                  class="pi pi-twitter"
                  style="font-size: x-large; color: white"
                ></i
              ></a>
            </span>

            <span class="mr-3">
              <a
                :href="'https://www.youtube.com/channel/UC0m6lRsUpkU58Oxpu2o6tuA'"
                target="_blank"
                v-tooltip.bottom="'Visit us on YouTube!'"
                ><i
                  class="pi pi-youtube"
                  style="font-size: x-large; color: white"
                ></i
              ></a>
            </span>
            <span>
              <a
                :href="'https://www.instagram.com/myreservsllc/'"
                target="_blank"
                v-tooltip.bottom="'Visit us on Instagram!'"
                ><i
                  class="pi pi-instagram"
                  style="font-size: x-large; color: white"
                ></i
              ></a>
            </span>
          </div>
        </div>
        <div class="col-12" style="text-align: center; color: white">
          <span>
            &copy; {{ $currentYear() }} MyReservs. All Rights Reserved.</span
          >
        </div>
      </div>
    </div>
  </div>
  <Sidebar
    v-model:visible="businessFeatures"
    :baseZIndex="1000"
    position="full"
  >
    <div class="purple p-5" style="text-align: center">
      <div
        class="pb-1"
        style="
          color: white;
          font-family: 'Leckerli One';
          font-size: 4em;
          text-align: center;
        "
      >
        MyReservs
      </div>
      <span style="font-size: x-large">
        {{ $t("listmybusiness.thankyou") }}</span
      >
    </div>

    <business-features></business-features>
  </Sidebar>
  <Sidebar
    v-model:visible="customerFeatures"
    :baseZIndex="1000"
    position="full"
  >
    <div class="purple p-5" style="text-align: center">
      <div
        class="pb-1"
        style="
          color: white;
          font-family: 'Leckerli One';
          font-size: 4em;
          text-align: center;
        "
      >
        MyReservs
      </div>
      <span style="font-size: x-large"> {{ $t("customerfeatures.join") }}</span>
    </div>
    <customer-features></customer-features>
  </Sidebar>
</template>
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
import BusinessFeatures from "../components/BusinessFeatures.vue";
import CustomerFeatures from "../components/CustomerFeatures.vue";
import SupportTicket from "../store/Models/SupportTicket";

export default {
  name: "ComingSoon",
  components: { BusinessFeatures, CustomerFeatures },
  props: {},
  data() {
    return {
      businessFeatures: false,
      customerFeatures: false,
      feedback: {},
      fkpassword: null,
      submitting: false,
      options: ["notified"],
      otherLang: "",
    };
  },
  mounted() {
    this.setLang();
  },
  methods: {
    ...mapActions(["switchLang"]),

    validateFeedback() {
      this.$log.debug("validate customer feedback");
      this.submitting = true;
      let errors = 0;
      //verify required values
      if (this.options.length > 0) {
        this.feedback.TicketMessage = this.options.join(", ");
      }
      if (
        this.feedback.TicketEmail == null ||
        this.feedback.TicketEmail.length == 0
      ) {
        document.getElementById("email").classList.add("p-invalid");
        errors++;
      }
      if (this.fkpassword != null) {
        this.$log.debug("bot");
        errors++;
      }

      if (errors == 0) {
        this.feedback.TicketFirstName = "";
        this.feedback.TicketLastName = "";
        this.feedback.TicketCat = "Coming Soon";
        this.submitFeedback();
      } else {
        this.submitting = false;
      }
    },
    async submitFeedback() {
      this.$log.debug("submit feedback");
      this.$log.debug(this.feedback);
      await SupportTicket.createNewTicket(this.feedback, this.lang).then(
        async (response) => {
          this.$log.debug(response);
          if (response) {
            this.resetFeedback();
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("contactus.signupSuccess"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error!",
              detail: this.$t("contactus.signupFailed"),
              life: 3000,
            });
          }
          this.submitting = false;
        }
      );
    },
    resetFeedback() {
      this.feedback = {};
      this.options = ["notified"];
    },
    setLang() {
      this.$log.debug(this.lang);
      if (this.lang == "en") {
        this.otherLang = "Español";
        this.$i18n.locale = "en";
      }
      if (this.lang == "es") {
        this.otherLang = "English";
        this.$i18n.locale = "es";
      }
      document.getElementsByTagName("html")[0].lang = this.lang;
    },
    switchLanguage() {
      var newLang = "en";
      if (this.lang == "en") {
        newLang = "es";
        this.otherLang = "English";
      } else if (this.lang == "es") {
        newLang = "en";
        this.otherLang = "Español";
      }

      this.switchLang(newLang);
      this.$i18n.locale = newLang;
    },
  },
  watch: {
    lang() {
      this.$log.debug("lang changed");
      this.setLang();
    },
  },
  computed: mapState({
    lang: (state) => state.lang,
  }),
};
</script>

<style scoped>
.bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("~@/assets/flowers.jpg");

  background-position: left;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  top: 0;
  left: 0;

  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;

  overflow: auto;
}
</style>