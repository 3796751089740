import { Model } from '@vuex-orm/core'


export default class AttendeeCountDef extends Model {
  static entity = 'attendeeCountsDef'
  
  static primaryKey = 'AttendeeCountDefId'
  
  static fields () {
    return {
      AttendeeCountDefId: this.uid(),
      AttendeeCountDefLabel: this.attr(null)
    }
  }

  static async fetchAll() {
    await this.api().get('/api/attendeecountdefs/')
    return this.all();
  }
}

