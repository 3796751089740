import { Model } from '@vuex-orm/core'

export default class ServiceTypeDef extends Model {
  static entity = 'serviceTypeDefs'
  
  static primaryKey = 'ServTypeDefId'
  
  static fields () {
    return {
      ServTypeDefId: this.uid(),
      ServTypeTitle: this.attr(null)
    }
  }

  static async fetchAll() {
    await this.api().get('/api/servicetypedefs/')
    return this.all();
  }
}

