import axios from 'axios'
import router from '../router'
import VuexORM from "@vuex-orm/core"
import VuexORMSearch from '@vuex-orm/plugin-search'
import datePlugin from 'vuex-orm-plugin-date-attribute'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import createPersistedState from "vuex-persistedstate";

import Business from "./Models/Business"
import BusinessTypeDef from "./Models/BusinessTypeDef"
import Message from "./Models/Message"
import MessageAttachment from "./Models/MessageAttachment"
import ReservPayment from "./Models/ReservPayment"
import Review from "./Models/Review"
import Schedule from "./Models/Schedule"
import Service from "./Models/Service"
import ServiceType from "./Models/ServiceType"
import SubPayment from "./Models/SubPayment"
import Subscription from "./Models/Subscription"
import SubscriptionDefs from "./Models/SubscriptionDefs"
import TimeSlot from "./Models/TimeSlot"
import BusinessImage from "./Models/BusinessImage"
import ServiceTypeDef from "./Models/ServiceTypeDef"
import EventType from "./Models/EventType"
import AttendeeCount from "./Models/AttendeeCount"
import ActiveLocation from "./Models/ActiveLocation"
import BusinessServiceLocation from "./Models/BusinessServiceLocation"
import EventTypeDef from "./Models/EventTypeDef"
import AttendeeCountDef from "./Models/AttendeeCountDef"
import ServiceFavorite from "./Models/ServiceFavorite"
import BusinessFavorite from "./Models/BusinessFavorite"
import Reservation from "./Models/Reservation"
import ReservService from "./Models/ReservService"
import Quote from "./Models/Quote"
import QuotedService from "./Models/QuotedService"
import BusinessStaff from "./Models/BusinessStaff"
import BusinessCustomer from "./Models/BusinessCustomer"
import Conversation from "./Models/Conversation"
import Customer from "./Models/Customer"
import CalendarEvent from "./Models/CalendarEvent"
import QuoteComment from "./Models/QuoteComment"
import Card from "./Models/Card"
import Device from "./Models/Device"
import Notification from "./Models/Notification"
import NotificationType from "./Models/NotificationType"
import CustNotifiPermission from "./Models/CustNotifiPermission"
import SupportTicket from "./Models/SupportTicket"
import TimeSlotType from "./Models/TimeSlotType"
import BusinessAppointment from "./Models/BusinessAppointment"
import BusinessReservTerm from "./Models/BusinessReservTerm"
import ServiceImage from "./Models/ServiceImage"
import OrderedService from "./Models/OrderedService"
import StoreOrder from "./Models/StoreOrder"
import OrderPayment from "./Models/OrderPayment"
import StoreOrderComment from "./Models/StoreOrderComment"
import BusStats from "./Models/BusStats"
import GuestList from "./Models/GuestList"
import SharedGuestList from "./Models/SharedGuestList"
import GuestParty from "./Models/GuestParty"
import CustEvent from "./Models/CustEvent"
import CustEventRsvp from "./Models/CustEventRsvp"
import CustEventTodo from "./Models/CustEventTodo"
import CustSharedEvent from "./Models/CustSharedEvent"
import CustEventRegistry from "./Models/CustEventRegistry"
import CustEventService from "./Models/CustEventService"





const database = new VuexORM.Database()



database.register(Business, {})
database.register(BusinessTypeDef, {})

database.register(Message, {})
database.register(MessageAttachment, {})
database.register(ReservPayment, {})
database.register(Review, {})
database.register(Schedule, {})
database.register(Service, {})
database.register(ServiceType, {})
database.register(SubPayment, {})
database.register(Subscription, {})
database.register(TimeSlot, {})
database.register(BusinessImage, {})
database.register(ServiceTypeDef, {})
database.register(EventType, {})
database.register(AttendeeCount, {})
database.register(ActiveLocation, {})
database.register(EventTypeDef, {})
database.register(AttendeeCountDef, {})
database.register(ServiceFavorite, {})
database.register(BusinessFavorite, {})
database.register(Reservation, {})
database.register(ReservService, {})
database.register(Quote, {})
database.register(QuotedService, {})
database.register(BusinessStaff, {})
database.register(BusinessCustomer, {})
database.register(Conversation, {})
database.register(Customer, {})
database.register(CalendarEvent, {})
database.register(QuoteComment, {})
database.register(SubscriptionDefs, {})
database.register(Card, {})
database.register(Notification, {})
database.register(Device, {})
database.register(NotificationType, {})
database.register(CustNotifiPermission, {})
database.register(SupportTicket, {})
database.register(TimeSlotType, {})
database.register(BusinessAppointment, {})
database.register(BusinessReservTerm, {})
database.register(ServiceImage, {})
database.register(OrderedService, {})
database.register(StoreOrder, {})
database.register(StoreOrderComment, {})
database.register(OrderPayment, {})
database.register(BusStats, {})
database.register(GuestList, {})
database.register(SharedGuestList, {})
database.register(GuestParty , {})
database.register(BusinessServiceLocation, {})
database.register(CustEvent, {})
database.register(CustEventRegistry, {})
database.register(CustEventRsvp, {})
database.register(CustEventTodo , {})
database.register(CustEventService, {})
database.register(CustSharedEvent, {})



// import Vue from 'vue'
import Vuex from 'vuex'

// Vue.use(Vuex)
VuexORM.use(datePlugin)

VuexORM.use(VuexORMSearch, {
  caseSensitive: false,
  threshold: 0.1,
  verbose: false,
  ignoreLocation: true,
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true

})

VuexORM.use(VuexORMAxios, {
  axios,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  baseURL: process.env.VUE_APP_MYRESERVS_API_URL

})

// 
const store = new Vuex.Store({
  plugins: [VuexORM.install(database), createPersistedState({
    storage: window.sessionStorage,
  })],
  state() {
    return {
      currentADUser: {
        username: "",
        uniqueId: "",
        customerId: "",
      },
      currentCustomer: {},
      currentCustomerId: 0,
      currentBusinessId: 0,
      customerBusinesses: [],
      userLoggedIn: false,
      userMissingProfile: false,
      accessToken: null,
      expiration: null,
      lang: "en",
      loggingIn: false,
      subscriptionsInProgress: []

    }
  },
  getters: {
    getCurrentCustomerId: (state) => state.currentCustomerId,
    getCurrentADUser: (state) => state.currentADUser,
    getToken: (state) => state.accessToken
  },
  mutations:
  {
    SET_LOGGING_IN: (state, status) => {
     //console.log("SET_LOGGING_IN", status)
      state.loggingIn = status
    },
    SET_CURRENT_USER: (state, user) => {
     //console.log(" SET_CURRENT_USER", user)
      state.currentADUser = user
    },
    SET_CURRENT_CUST: (state, customer) => {
     //console.log("SET_CURRENT_CUST", customer)
      state.currentCustomer = customer
    },
    SET_CURRENT_CUST_ID: (state, custId) => {
     //console.log("SET_CURRENT_CUST_ID", custId)
      state.currentCustomerId = custId
    },
    SET_CUST_BUS: (state, buss) => {
     //console.log("SET_CUST_BUS", buss)
      state.customerBusinesses = buss
    },
    SET_CURRENT_BUS_ID: (state, busId) => {
     //console.log("SET_CURRENT_BUS_ID", busId)
      state.currentBusinessId = busId
    },
    SET_LOGGED_IN: (state, status) => {
     //console.log("SET_LOGGED_IN", status)
      state.userLoggedIn = status
    },
    SET_MISSING_PROFILE: (state, status) => {
     //console.log("SET_MISSING_PROFILE", status)
      state.userMissingProfile = status
    },
    SET_ACCESS_TOKEN(state, token) {
     ////console.log("set token" + token)
      state.accessToken = token;
    },
    SET_EXPIRATION(state, expires) {
      state.expiration = expires;
    },
    SET_CURRENT_LANG(state, lang) {
     //console.log("SET_CURRENT_LANG", lang)
      state.lang = lang;
    },
    ADD_SUBS_IN_PROG(state, busId) {
     //console.log("ADD_SUBS_IN_PROG", busId)
      state.subscriptionsInProgress.push(busId)
    },
    REM_SUBS_IN_PROG(state, busId) {
     //console.log("REM_SUBS_IN_PROG", busId)
     //console.log(state.subscriptionsInProgress)
      const index = state.subscriptionsInProgress.findIndex(sid => sid == busId);
      state.subscriptionsInProgress.splice(index, 1)
     //console.log(state.subscriptionsInProgress)
    },
    CLR_SUBS_IN_PROG(state) {
     //console.log("CLR_SUBS_IN_PROG")
     //console.log(state.subscriptionsInProgress)
      state.subscriptionsInProgress = []
     //console.log(state.subscriptionsInProgress)
    },


  },
  actions: {
    loggingIn: async ({ commit }, status) => {
      
      // commit("SET_CURRENT_USER", {})
      // commit("SET_CURRENT_CUST", {})
      // commit("SET_CURRENT_CUST_ID", 0)
      // commit("SET_CUST_BUS", [])
      // commit("SET_CURRENT_BUS_ID", 0)
      // commit("SET_LOGGED_IN", false)
      // commit("SET_MISSING_PROFILE", false)
      // commit("SET_ACCESS_TOKEN", null)
      // commit("SET_EXPIRATION", null)
      // commit("SET_CURRENT_LANG", 'en')
      // commit("CLR_SUBS_IN_PROG")
      // localStorage.setItem("currentCustomerId", 0)

      commit("SET_LOGGING_IN", status)
    },
    switchLang: async ({ commit }, lang) => {
      commit("SET_CURRENT_LANG", lang)
    },
    isUserLoggedIn: async ({ state }) => {
     //console.log("checking if user logged in: ")
      var response = {
        userLoggedIn: false,
        userMissingProfile: state.userMissingProfile
      }
      if (state.userLoggedIn && state.accessToken != null) {
        setAuthHeader(state.accessToken)
        response.userLoggedIn = true;
      }

      return response;
    },
    verifyUserAcct: async (context, username) => {
     //console.log("checking if user is set: ", username)
      let result = await Customer.verifyAcct(username).then(
        async (response) => {
         //console.log("response")
         //console.log(response);
          return response;
        }).catch(error => {
         //console.log(error.response)
          if (error.response.status == 404 || error.response.status == 401) {
           //console.log("user profile missing")

            return false;
          }
        });
      return result;
    },
    setUserLoggedIn: async ({ commit, state }, account) => {
     //console.log("setUserLoggedIn: ")
     //console.log(account);
      const status = (account != null) ? true : false
      if (status == true) {
        let user = {
          username: account.username,
          uniqueId: account.localAccountId
        }
       //console.log(user)
        commit("SET_CURRENT_USER", user)
        var custId = null;
       //console.log("Fetching user:" + account.username)
        setTimeout(async () => {
          await Customer.fetchFullByEmail(account.username).then(
            async (cust) => {
             //console.log("response")
             //console.log(cust);
              if (cust) {
               //console.log("customer found");
                commit("SET_CURRENT_CUST_ID", cust.CustId)
                commit("SET_CURRENT_CUST", cust)
                localStorage.currentCustomerId = cust.CustId
                commit("SET_MISSING_PROFILE", false)
                custId = cust.CustId;

                if (cust.CustLang != null) {
                  commit("SET_CURRENT_LANG", cust.CustLang)
                }
              } else {
               //console.log("new customer, create profile");
                commit("SET_MISSING_PROFILE", true)
              }
              commit("SET_LOGGED_IN", status)
              localStorage.setItem("userLoggedIn", status)
             //console.log(custId)
              if (custId != null) {
                let staff = []
                let owned = []
                await BusinessStaff.fetchByCustId(custId).then(
                  (BusStaff) => {
                   //console.log(BusStaff)
                    if (BusStaff.length > 0) {
                      staff = BusStaff.map(a => a.Bus);
                    }
                  })
                await Business.fetchByOwnerId(custId).then(
                  (busses) => {
                   //console.log(busses)
                    if (busses.length > 0) {
                      owned = busses;
                    }
                  });
                let all = owned.concat(staff);
               //console.log(all)
                commit("SET_CUST_BUS", all);

                if (all.length > 0 && state.currentBusinessId == 0) {
                  commit("SET_CURRENT_BUS_ID", all[all.length - 1].BusId);
                }
                store.dispatch("loggingIn", status);
              }
            });

        }, 2000);
      }
      else {
        commit("SET_LOGGED_IN", status)
        localStorage.setItem("userLoggedIn", status)
      }
    },

    refreshUserData: async ({ commit, state }, custId) => {
     //console.log("refreshUserData" + custId);
      await Customer.fetchFullById(custId).then(
        async (cust) => {
         //console.log("response")
         //console.log(cust);
          if (cust) {
            commit("SET_CURRENT_CUST", cust)
            commit("SET_CURRENT_CUST_ID", cust.CustId)
            localStorage.currentCustomerId = cust.CustId
            custId = cust.CustId;
            if (cust.CustLang != null) {
              commit("SET_CURRENT_LANG", cust.CustLang)
            }
          } else {
           //console.log("new customer, create profile");
            commit("SET_MISSING_PROFILE", true)
          }
        });
      let staff = []
      let owned = []
      await BusinessStaff.fetchByCustId(custId).then(
        (BusStaff) => {
         //console.log(BusStaff)
          if (BusStaff.length > 0) {
            staff = BusStaff.map(a => a.Bus);
          }
        })
      await Business.fetchByOwnerId(custId).then(
        (busses) => {
         //console.log(busses)
          if (busses.length > 0) {
            owned = busses;
          }
        });
      let all = owned.concat(staff);
     //console.log(all)
      commit("SET_CUST_BUS", all);

      if (all.length > 0 && state.currentBusinessId == 0) {
        commit("SET_CURRENT_BUS_ID", all[all.length - 1].BusId);
      }
    },
    setUserLoggedOut: (context) => {
      store.dispatch("loggingIn", false);
      let user = {
        firstName: "",
        lastName: "",
        username: "",
        uniqueId: ""
      }
     //console.log("setUserLoggedOut: ")
     //console.log(user)

      context.commit("SET_CURRENT_USER", user)
      context.commit("SET_CURRENT_CUST", {})
      context.commit("SET_CURRENT_CUST_ID", 0)
      context.commit("SET_CUST_BUS", [])
      context.commit("SET_CURRENT_BUS_ID", 0)
      context.commit("SET_LOGGED_IN", false)
      context.commit("SET_MISSING_PROFILE", false)
      context.commit("SET_ACCESS_TOKEN", null)
      context.commit("SET_EXPIRATION", null)
      context.commit("SET_CURRENT_LANG", 'en')
      context.commit("CLR_SUBS_IN_PROG")
      localStorage.setItem("currentCustomerId", 0)
      
    },

    setToken: (context, token) => {
     //console.log("set token: ")
      context.commit("SET_ACCESS_TOKEN", token)
      setAuthHeader(token)
    },

    setUserMissingProfile: (context, status) => {
     //console.log("setUsermMISSINGProfile: ")
      context.commit("SET_MISSING_PROFILE", status)
    },


    setExpiration: (context, expires) => {
     //console.log("set expiration: ")
     //console.log(expires)

      context.commit("SET_EXPIRATION", expires)
    },
    setCurrentBusiness: (context, busId) => {
      context.commit("SET_CURRENT_BUS_ID", busId);
    },

    addSubInProgress: async ({ commit }, busId) => {
      commit("ADD_SUBS_IN_PROG", busId)
    },
    clearSubsInProgress: async ({ commit } ) => {
      commit("CLR_SUBS_IN_PROG")
    },

  }

})

function setAuthHeader(token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
 //console.log(axios.defaults.headers.common['Authorization'])
}

axios.interceptors.request.use(
  request => {
    if (store.state.accessToken) {
      request.headers = {
        'Authorization': 'Bearer ' + store.state.accessToken
      }
    }
    return request;
  },
  error => {
    Promise.reject(error)
  });

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
 //console.log(error.response.data)
  if (error.response.status === 401) {
    store.dispatch("setUserLoggedOut");
    router.push('/')
  }
  return Promise.reject(error)
})

export default store


