<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <div v-if="currentBusiness != null && currentBusiness.BusActive == true">
      <Panel class="mt-3">
        <template #header>
          <div class="grid flex-column" style="width: 100%">
            <div class="col-12">
              <div
                class="
                  flex
                  justify-content-center
                  flex-wrap
                  align-items-center
                  mb-1
                "
                style="width: 100%"
              >
                <img
                  :src="imageHostingURL + currentBusiness.BusLogo"
                  :alt="currentBusiness.BusName"
                  width="100"
                  class="mr-3"
                />
                <span class="" style="font-size: xx-large">{{
                  currentBusiness.BusName
                }}</span>
              </div>
              <a :href="webHost + currentBusiness.BusHandle" target="_blank">
                <small>@{{ currentBusiness.BusHandle }}</small></a
              >
            </div>
          </div>
        </template>

        <!-- PROFILE PANELS -->
        <Panel
          v-if="currentBusiness != null && percentComplete != 100"
          :toggleable="true"
          class="mb-3 shadow-7 panel-info"
        >
          <template #header>
            <span>
              <i class="pi pi-exclamation-triangle"></i>&nbsp;{{
                $t("businessprofile.incompleteprofile")
              }}</span
            >
          </template>
          <template #icons>
            <button
              class="p-panel-header-icon p-link mr-2"
              @click="toggle"
            ></button>
          </template>

          <b
            >{{ currentBusiness.BusName }},
            {{ $t("businessprofile.notyetcomplete") }}</b
          ><br />
          {{ $t("businessprofile.completefor") }}
          <div class="p-3">
            <ProgressBar class="info" :value="percentComplete" />
          </div>
          <div class="flex justify-content-around mt-3">
            <Button
              :label="$t('profile.dismiss')"
              icon="pi pi-times"
              @click.stop="
                currentBusiness.BusIncompleteProfileDismissed = true;
                validateUpdateBusiness();
              "
              class="p-button-sm p-button-raised p-button-secondary mr-2"
            />
            <Button
              :label="$t('businessprofile.incompleteprofile')"
              icon="pi pi-arrow-circle-right"
              iconPos="left"
              @click.stop="verifyProfileCompletness(true)"
              class="ml-auto p-button-sm p-button-raised p-button-info"
            />
          </div>
        </Panel>
        <Panel
          v-if="percentComplete == 100 && justCompleted == true"
          :toggleable="true"
          class="mb-3 shadow-7 panel-success"
          @click="toggle"
        >
          <template #header>
            <span>
              <i class="pi pi-exclamation-triangle"></i>&nbsp;{{
                $t("businessprofile.completeprofile")
              }}</span
            >
          </template>
          <template #icons>
            <button
              class="p-panel-header-icon p-link mr-2"
              @click="toggle"
            ></button>
          </template>

          <span>{{ $t("businessprofile.nowcomplete") }} </span>
          <div class="p-3">
            <ProgressBar class="success" :value="percentComplete" />
          </div>
        </Panel>
        <!-- OFFLINE PANEL -->
        <Panel
          v-if="currentBusiness.BusVisible == false"
          :toggleable="true"
          class="mb-3 shadow-7 panel-danger"
        >
          <template #header>
            <span>
              <i class="pi pi-exclamation-triangle"></i>&nbsp;{{
                $t("businessprofile.profileoffline")
              }}</span
            >
          </template>
          <template #icons>
            <button
              class="p-panel-header-icon p-link mr-2"
              @click="toggle"
            ></button>
          </template>
          <div>
            <b>{{ $t("businessprofile.currentlyoffline") }}</b
            ><br />
            {{ $t("businessprofile.profileofflinedesc") }}
          </div>
          <Button
            v-if="businessOwner == true"
            :label="$t('businessprofile.golive')"
            icon="pi pi-power-off"
            iconPos="left"
            @click.stop="enableVisibility"
            class="ml-auto p-button-sm p-button-raised p-button-success"
          />
          <div v-else>
            {{ $t("businessprofile.publish") }}
          </div>
        </Panel>
        <div class="col-12">
          <div
            class="flex justify-content-between align-items-start mb-1"
            style="width: 100%"
          >
            <Button
              v-if="currentBusiness.BusVisible == true && businessOwner == true"
              :label="$t('businessprofile.gooffline')"
              icon="pi pi-power-off"
              iconPos="left"
              @click.stop="disableVisibility"
              class="mr-1 p-button-sm p-button-raised p-button-danger"
            />

            <Button
              v-if="
                currentBusiness.BusVisible == false && businessOwner == true
              "
              :label="$t('businessprofile.golive')"
              icon="pi pi-power-off"
              iconPos="left"
              @click.stop="enableVisibility"
              class="mr-1 p-button-sm p-button-raised p-button-success"
            />
            <Button
              :label="$t('businessprofile.editprofile')"
              icon="pi pi-pencil"
              iconPos="left"
              @click.stop="
                setBusinessUpdate();
                editing = true;
              "
              class="p-button-sm p-button-raised p-button-primary"
            />
          </div>
        </div>
        <div
          class="flex justify-content-center md:hidden p-2 freeze"
          style="background-color: #f8f9fa"
        >
          <a class="mr-3" href="#about">{{ $t("footer.about") }}</a>
          <a class="mr-3" href="#contact">{{ $t("footer.contact") }}</a>
          <a class="" href="#gallery">{{ $t("footer.gallery") }}</a>
        </div>

        <div class="grid">
          <div class="col-12 md:col-6 lg:col-6 text-left">
            <Panel id="aboutus" :header="$t('footer.aboutus')" class="shadow-7">
              <p>{{ currentBusiness.BusDesc }}</p>

              <p>
                <em
                  ><i class="pi pi-tag"></i>
                  <b> {{ $t("details.bustype") }}: </b>
                  <Chip
                    ><service-type-label
                      :serviceType="currentBusiness.BusCategory"
                  /></Chip>
                </em>
              </p>
              <p>
                <em
                  >
                  <div v-if="currentBusiness.BusCategory !='Venue'">

                  <i class="pi pi-tag"></i>
                  <b> {{ $t("services.location") }}: </b>
                  <span v-if="currentBusiness.BusinessServiceLocations != null && BusC !='Venue'" >
                    <!-- {{
                      currentBusiness.EventTypes.map(
                        (s) => s.EventTypeLabel
                      ).join(", ")
                    }} -->
                   
                    
                  
                    <template
                    v-for="loc in currentBusiness.BusinessServiceLocations"
                      :key="loc.BusServLocId"
                    >
                      <Chip class="m-1"
                      
                      >
                        {{ loc.Location.LocationCity}},{{ loc.Location.LocationStateCode }}
                        
                        
                        </Chip>
                    </template> 
                    </span
                >
              </div>
                </em>
              </p>
              <p>
                <em
                  ><i class="pi pi-tags"></i
                  ><b>{{ $t("details.eventtype") }}: </b>
                  <span v-if="currentBusiness.EventTypes != null">
                    <!-- {{
                      currentBusiness.EventTypes.map(
                        (s) => s.EventTypeLabel
                      ).join(", ")
                    }} -->
                    <template
                      v-for="type in currentBusiness.EventTypes"
                      :key="type.EventTypeLabel"
                    >
                      <Chip class="m-1">
                        <event-type-label :eventType="type.EventTypeLabel"
                      /></Chip>
                    </template> </span
                ></em>
              </p>
              
              <p v-if="currentBusiness.BusOccupancyDisabled != true">
                <em
                  ><i class="pi pi-users"></i>
                  <b> {{ $t("details.max") }}: </b>
                  <Chip> {{ currentBusiness.BusOccupancy }}</Chip></em
                >
              </p>
              <p>
                <em
                  ><i class="pi pi-users"></i><b>{{ $t("details.party") }}: </b>

                  <span v-if="currentBusiness.AttendeeCounts != null">
                    <!-- {{
                      currentBusiness.AttendeeCounts.map(
                        (s) => s.AttendeeCountLabel
                      ).join(", ")
                    }} -->
                    <template
                      v-for="count in currentBusiness.AttendeeCounts"
                      :key="count.AttendeeCountLabel"
                    >
                      <Chip class="m-1">{{ count.AttendeeCountLabel }}</Chip>
                    </template>
                  </span></em
                >
              </p>
              <p>
                <label
                  class="pointer button-text"
                  @click.stop="showServicePolicy = true"
                >
                  {{ $t("businessprofile.viewsla") }}</label
                >
              </p>
            </Panel>

            <br />
            <Panel
              id="contact"
              :header="$t('footer.contactus')"
              class="shadow-8"
            >
              <h4 v-if="currentBusiness.BusPhone != null">
                <i class="pi pi-mobile"></i>
                <a :href="'tel: ' + currentBusiness.BusPhone">{{
                  currentBusiness.BusPhone
                }}</a>
              </h4>
              <h4 v-if="currentBusiness.BusEmail != null">
                <i class="pi pi-envelope"></i>
                <a :href="'mailto:' + currentBusiness.BusEmail">{{
                  currentBusiness.BusEmail
                }}</a>
              </h4>
              <h4
                v-if="
                  currentBusiness.BusAddress != null &&
                  currentBusiness.BusCity != null &&
                  currentBusiness.BusZip != null &&
                  currentBusiness.BusState != null
                "
              >
                <i class="pi pi-map-marker"></i>
                <a
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    currentBusiness.BusAddress +
                    '+' +
                    currentBusiness.BusCity +
                    '+' +
                    currentBusiness.BusState +
                    '+' +
                    currentBusiness.BusZip
                  "
                  target="_blank"
                  >{{ currentBusiness.BusAddress }},
                  {{ currentBusiness.BusCity }}, {{ currentBusiness.BusState }},
                  {{ currentBusiness.BusZip }}</a
                >
              </h4>

              <h4>
                <span
                  v-if="
                    currentBusiness.BusWebUrl != null &&
                    currentBusiness.BusWebUrl.length > 0
                  "
                >
                  <a
                    :href="'' + currentBusiness.BusWebUrl + ''"
                    target="_blank"
                    v-tooltip.bottom="'Visit us on the web!'"
                    ><i
                      class="pi pi-globe"
                      style="font-size: x-large; color: var(--mr-primary-color)"
                    ></i
                  ></a>
                </span>
                &nbsp;
                <span
                  v-if="
                    currentBusiness.BusFbUrl != null &&
                    currentBusiness.BusFbUrl.length > 0
                  "
                >
                  <a
                    :href="
                      'https://www.facebook.com/' + currentBusiness.BusFbUrl
                    "
                    target="_blank"
                    v-tooltip.bottom="'Visit us on FB!'"
                    ><i
                      class="pi pi-facebook"
                      style="font-size: x-large; color: var(--mr-primary-color)"
                    ></i
                  ></a>
                </span>
                &nbsp;
                <span
                  v-if="
                    currentBusiness.BusTwitUrl != null &&
                    currentBusiness.BusTwitUrl.length > 0
                  "
                >
                  <a
                    :href="'https://twitter.com/' + currentBusiness.BusTwitUrl"
                    target="_blank"
                    v-tooltip.bottom="'Visit us on Twitter!'"
                    ><i
                      class="pi pi-twitter"
                      style="font-size: x-large; color: var(--mr-primary-color)"
                    ></i
                  ></a>
                </span>
                &nbsp;
                <span
                  v-if="
                    currentBusiness.BusInstUrl != null &&
                    currentBusiness.BusInstUrl.length > 0
                  "
                >
                  <a
                    :href="
                      'https://www.instagram.com/' + currentBusiness.BusInstUrl
                    "
                    target="_blank"
                    v-tooltip.bottom="'Visit us on Instagram!'"
                    ><i
                      class="pi pi-instagram"
                      style="font-size: x-large; color: var(--mr-primary-color)"
                    ></i
                  ></a>
                </span>
                &nbsp;
                <span
                  v-if="
                    currentBusiness.BusPinUrl != null &&
                    currentBusiness.BusPinUrl.length > 0
                  "
                >
                  <a
                    :href="
                      'https://www.pinterest.com/' + currentBusiness.BusPinUrl
                    "
                    target="_blank"
                    v-tooltip.bottom="'Visit us on Pinterest!'"
                    ><i
                      class="pi pi-images"
                      style="font-size: x-large; color: var(--mr-primary-color)"
                    ></i
                  ></a>
                </span>
              </h4>
            </Panel>
          </div>
          <div class="col-12 md:col-6 lg:col-6">
            <Panel id="gallery" :header="$t('footer.gallery')" class="shadow-7">
              <template #icons>
                <button
                  v-if="!isFullScreen"
                  v-tooltip.bottom="'Full Screen'"
                  class="p-panel-header-icon p-link mr-2"
                  @click="isFullScreen = true"
                >
                  <span class="pi pi-window-maximize"></span>
                </button>
                <button
                  v-if="isFullScreen"
                  class="p-panel-header-icon p-link mr-2"
                  @click="isFullScreen = false"
                >
                  <span class="pi pi-window-minimize"></span>
                </button>
              </template>
              <span
                v-if="
                  currentBusiness.BusinessImages != null &&
                  currentBusiness.BusinessImages.length == 0
                "
              >
                Empty
              </span>

              <Galleria
                v-if="currentBusiness.BusinessImages != null"
                :value="currentBusiness.BusinessImages"
                :fullScreen="isFullScreen"
                :circular="true"
                :autoPlay="true"
                :transitionInterval="3000"
                :responsiveOptions="responsiveOptions"
                :numVisible="5"
                containerStyle="max-width: 100%;"
                :showItemNavigators="true"
              >
                <template #item="slotProps">
                  <img
                    v-if="slotProps.item"
                    :src="imageHostingURL + slotProps.item.BusImage"
                    :alt="slotProps.item.BusImageTitle"
                    style="width: 100%;  display: block"
                    class="shadow-8"
                  />
                </template>
                <template #thumbnail="slotProps">
                  <img
                    v-if="slotProps.item"
                    :src="imageHostingURL + slotProps.item.BusImage"
                    :alt="slotProps.item.BusImageTitle"
                    style="width: 50px; heigth: 50px; margin: 5px"
                  />
                </template>
              </Galleria>
            </Panel>
          </div>
        </div>
      </Panel>
      <!-- Edit Profile Dialog -->
      <Dialog
        v-model:visible="editing"
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        :style="{ width: '50vw' }"
        :modal="true"
        @hide="resetBusinessUpdate"
      >
        <template #header>
          <label>
            <i class="pi pi-id-pencil"></i>{{ $t("details.edit") }}</label
          >
        </template>

        <div class="p-fluid formgrid grid text-left">
          <!-- <div class="field col-12">
             <h3><b>{{ $t("details.edit") }}</b></h3>
          </div> -->
          <div class="field col-12">
            <div class="mb-2" style="font-size: large">
              <b><i class="pi pi-id-card"></i> General</b>
            </div>
            <label for="name"
              >{{ $t("listmybusiness.businessname") }}
              <span class="required-star">*</span></label
            >
            <InputText
              id="name"
              type="text"
              v-model="businessToUpdate.BusName"
            />
          </div>
          <div class="field col-12">
            <label for="name"
              >{{ $t("listmybusiness.businesshandle") }}
              <span class="required-star">*</span></label
            >
            <div class="p-inputgroup">
              <InputText
                id="bushandle"
                type="text"
                v-model="businessToUpdate.BusHandle"
                @blur="validateHandle"
              />
              <span v-if="validatingHandle == true" class="p-inputgroup-addon">
                <i class="pi pi-spin pi-spinner"></i>
              </span>
              <span v-else-if="!handleverified" class="p-inputgroup-addon">
                <i class="pi pi-lock"></i>
              </span>
              <span
                v-else-if="handleverified && handlevalid"
                class="p-inputgroup-addon p-button-success"
              >
                <i class="pi pi-check"></i>
              </span>
              <span
                v-else-if="handleverified && !handlevalid"
                class="p-inputgroup-addon p-button-danger"
              >
                <i class="pi pi-times"></i>
              </span>
            </div>
            <small
              v-if="handleverified && !handlevalid"
              id="username2-help"
              class="p-error"
              >Handle is not available.</small
            >
          </div>
          <div class="field col-12">
            <label for="aboutus"
              >{{ $t("footer.aboutus") }}
              <span class="required-star">*</span></label
            >
            <Textarea
              id="aboutus"
              rows="4"
              v-model="businessToUpdate.BusDesc"
            />
          </div>

          <div class="field col-12">
            <div class="mb-2" style="font-size: large">
              <b><i class="pi pi-list"></i> Contact </b>
            </div>
            <label for="phone"
              >{{ $t("listmybusiness.phone") }}
              <span class="required-star">*</span></label
            >
            <!-- <InputText id="phone" type="tel" v-model="businessToUpdate.BusPhone" /> -->
            <InputMask
              id="phone"
              mask="(999) 999-9999"
              v-model="businessToUpdate.BusPhone"
              placeholder="(999) 999-9999"
            />
          </div>
          <div class="field col-12">
            <label for="email"
              >{{ $t("listmybusiness.email") }}
              <span class="required-star">*</span></label
            >
            <InputText
              id="email"
              v-model="businessToUpdate.BusEmail"
              placeholder="something@something.com"
            />
          </div>
          <div class="field col-12">
            <label for="weburl">{{ $t("details.webpage") }}</label>
            <InputText
              id="weburl"
              type="text"
              v-model="businessToUpdate.BusWebUrl"
              placeholder="https://yourwebsite.com"
            />
          </div>

          <div class="field col-12">
            <label style="font-size: large"
              ><i class="pi pi-at"></i> Social Media </label
            ><br />
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-facebook"></i> https://www.facebook.com/
              </span>
              <InputText
                id="fburl"
                type="text"
                v-model="businessToUpdate.BusFbUrl"
                placeholder="yourpage"
              />
            </div>
          </div>
          <div class="field col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-twitter"></i> https://twitter.com/
              </span>
              <InputText
                id="twiturl"
                type="text"
                v-model="businessToUpdate.BusTwitUrl"
                placeholder="handle"
              />
            </div>
          </div>
          <div class="field col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-instagram"></i> https://www.instagram.com/
              </span>
              <InputText
                id="instURL"
                type="text"
                v-model="businessToUpdate.BusInstUrl"
                placeholder="username"
              />
            </div>
          </div>
          <div class="field col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"> https://pinterest.com/ </span>
              <InputText
                id="pinurl"
                type="text"
                v-model="businessToUpdate.BusPinUrl"
                placeholder="username"
              />
            </div>
          </div>

          <div class="field col-12">
            <div class="mb-2" style="font-size: large">
              <b><i class="pi pi-map-marker"></i> Location</b>
            </div>

            <label for="address"
              >{{ $t("profile.address") }}
              <span class="required-star">*</span></label
            >
            <!-- <InputText id="address" v-model="businessToUpdate.BusAddress" /> -->
            <vue-google-autocomplete
              id="busaddress"
              classname="p-inputtext"
              v-on:placechanged="setBusinessAddress"
              :country="['us']"
              v-on:focus="busAddrValidated = false"
              v-on:no-results-found="invalidAddressEntered"
              :placeholder="businessToUpdate.BusAddress"
            >
            </vue-google-autocomplete>
            <div>
              <Checkbox
                v-model="businessToUpdate.BusHideAddress"
                :binary="true"
              />&nbsp;{{ $t("businessprofile.hideaddress") }}
            </div>
          </div>
          <div class="field col-4">
            <label for="city"
              >{{ $t("profile.city") }}
              <span class="required-star">*</span></label
            >
            <InputText
              id="city"
              type="text"
              v-model="businessToUpdate.BusCity"
            />
          </div>

          <div class="field col-4">
            <label for="state"
              >{{ $t("profile.state") }}
              <span class="required-star">*</span></label
            >
            <Dropdown
              inputId="state"
              v-model="businessToUpdate.BusState"
              :options="compStates"
              optionLabel="name"
              optionValue="code"
              :placeholder="$t('services.pleaseselect')"
            />
          </div>
          <div class="field col-4">
            <label for="zip"
              >{{ $t("profile.zip") }}
              <span class="required-star">*</span></label
            >
            <InputText id="zip" type="text" v-model="businessToUpdate.BusZip" />
          </div>

          <div class="field col-12 mb-2" style="font-size: large">
            <b><i class="pi pi-sliders-h"></i> Other Details</b>
          </div>
          <div class="field col-6">
            <label for="zip">{{ $t("details.main") }}</label>
            <Dropdown
              v-model="businessToUpdate.BusCategory"
              :options="serviceTypes"
              optionValue="ServTypeTitle"
              :placeholder="$t('services.selectone')"
            >
              <template #value="slotProps">
                <span v-if="slotProps.value == null">
                  {{ slotProps.placeholder }}
                </span>
                <span v-else-if="typeof slotProps.value !== 'String'">
                  {{ $t($getServiceType(slotProps.value)) }}
                </span>
                <span v-else>
                  {{ $t($getServiceType(slotProps.value.ServTypeTitle)) }}
                </span>
              </template>
              <template #option="slotProps">
                <span>{{
                  $t($getServiceType(slotProps.option.ServTypeTitle))
                }}</span>
              </template>
            </Dropdown>
          </div>
          <div class="field col-6">
            <label for="occupancy"
              >{{ $t("details.max") }}
              <span class="required-star">*</span></label
            >
            <InputText
              v-if="businessToUpdate.BusOccupancyDisabled != true"
              id="occupancy"
              type="number"
              v-model.number="businessToUpdate.BusOccupancy"
            />
            <Checkbox
              v-model="businessToUpdate.BusOccupancyDisabled"
              :binary="true"
            />&nbsp;{{ $t("businessprofile.doesnotapply") }}
          </div>
          
            <label for="eventtypes">{{ $t("details.eventtype") }}</label>
            <div class="p-inputgroup" v-if="BusCategory !='Venue'">
            <MultiSelect
              v-model="businessToUpdate.StringEventTypes"
              :options="eventTypes"
              optionLabel="EventTypeLabel"
              optionValue="EventTypeLabel"
              :placeholder="$t('services.selectall')"
            >
              <template #value="slotProps">
                <template v-for="option of slotProps.value" :key="option">
                  <span>
                    <Chip class="mr-1"> {{ getEventType(option) }}</Chip>
                  </span>
                </template>
              </template>
              <template #option="slotProps">
                <span>{{ getEventType(slotProps.option.EventTypeLabel) }}</span>
              </template>
            </MultiSelect>
          </div>
          <div  v-if="currentBusiness.BusCategory !='Venue'">
          <div class="field col-12">
            <label>{{$t("services.location")}} </label>
      <label for="location"> </label>
    <br/>
    <div class="p-inputgroup">
            <MultiSelect
              class="hidden md:inline-flex"
              v-model="businessToUpdate.ActiveLocations"
              :options="activeLocations"
              :filter="true"
              optionLabel="LocationCity"
              style="width: 40%; border-radius: 0; text-align: left"
             
            
        :placeholder="$t('services.selectone')"
            >
            <template #option="slotProps">
                <div style="text-align: left">
                  <div>
                    {{ slotProps.option.LocationCity }},
                    {{ slotProps.option.LocationStateCode }}
                  </div>
                </div>
              </template>
               <template #value="slotProps">
                <template v-for="option of slotProps.value" :key="option">
                  <span>
                    <Chip class="mr-1"> {{ option.LocationCity}}</Chip>
                  </span>
                </template>
              </template>
              
              </MultiSelect>
            </div>
    </div>
    </div>
    
          
            
            <label for="attendeecounts">{{ $t("details.attendees") }}</label>
            <br/>
            <div class="p-inputgroup">
            <MultiSelect
              v-model="businessToUpdate.StringAttendeeCounts"
              :options="attendeeCounts"
              optionLabel="AttendeeCountDefLabel"
              optionValue="AttendeeCountDefLabel"
              :placeholder="$t('services.selectall')"
            />
            
      
</div>
          <div class="field col-12">
            <div class="mb-2" style="font-size: large">
              <b><i class="pi pi-images"></i> Images</b>
            </div>
            <label>{{ $t("details.logo") }}</label
            ><br />
          </div>
          <div v-if="hideLogoUpload" class="ml-2 mb-3">
            <img
              v-if="businessToUpdate.BusLogo != null && cropimage == null"
              :src="imageHostingURL + businessToUpdate.BusLogo"
              :alt="businessToUpdate.BusName"
              width="100"
            />
            <img
              v-if="cropimage != null"
              :src="cropimage"
              :alt="businessToUpdate.BusLogo"
              width="100"
            /><br />
            <span class="p-buttonset">
              <Button
                class="p-button-info p-button-sm"
                :label="$t('menu.replacelogo')"
                @click.stop="replaceLogo()"
                icon="pi pi-image"
              /><br />
              <Button
                v-if="cropimage != null"
                class="p-button-info p-button-sm"
                :label="$t('profile.adjust')"
                @click.stop="showCropper = true"
                icon="pi pi-window-minimize"
              />
            </span>
          </div>
          <div class="field col-12">
            <FileUpload
              v-if="!hideLogoUpload"
              name="logo"
              :maxFileSize="9000000"
              :fileLimit="1"
              accept="image/*"
              :multiple="false"
              :auto="true"
              :customUpload="true"
              @uploader="uploadLogo"
            >
              <template #empty>
                <p>{{ $t("details.dragdrop") }}</p>
              </template>
            </FileUpload>
          </div>
          <div class="field col-12">
            <label>{{ $t("details.gallery") }}</label
            >&nbsp;
            <em>{{ galleryCountLeft }} {{ $t("details.images") }} 20 </em>
            <FileUpload
              v-if="galleryCountLeft > 0"
              name="gallery[]"
              :maxFileSize="9000000"
              accept="image/*"
              mode="basic"
              :multiple="true"
              :customUpload="true"
              :chooseLabel="$t('profile.choose')"
              :showUploadButton="true"
              @uploader="uploadGallery"
              :auto="true"
            >
              <!-- <template #empty>
                <p>{{ $t("details.dragdropgallery") }}</p>
              </template> -->
            </FileUpload>
            <Message
              v-if="showGalleryErrorMessage == true"
              severity="error"
              class="mt-2"
              >{{ galleryErrorMessage }}</Message
            >
            <div class="flex flex-wrap pt-3">
              <span
                v-for="img in businessToUpdate.NewBusinessImages"
                :key="img.name"
              >
                <Chip
                  class="mr-2 mb-2 modifyChip imageChip"
                  style="font-size: small"
                  :image="img.objectURL"
                  removable
                  v-on:remove="removeNewImage(img.name)"
                />
              </span>
              <br />
              <span
                v-for="img in businessToUpdate.BusinessImages"
                :key="img.name"
              >
                <Chip
                  class="mr-2 mb-2 modifyChip imageChip"
                  style="font-size: small"
                  :image="imageHostingURL + img.BusImage"
                  removable
                  v-on:remove="removeGalleryImage(img.BusImageId)"
                />
              </span>
            </div>
          </div>
          <div class="col-12">
            <label for="description">{{ $t("businessprofile.sla") }}</label>
            <Editor
              id="longdesc"
              v-model="businessToUpdate.BusPolicy"
              editorStyle="height: 200px"
            >
              <template #toolbar>
                <span class="ql-formats">
                  <select class="ql-font">
                    <option selected=""></option>
                    <option value="serif"></option>
                    <option value="monospace"></option>
                  </select>
                  <select class="ql-size">
                    <option value="small"></option>
                    <!-- Note a missing, thus falsy value, is used to reset to default -->
                    <option selected></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>
                <span class="ql-formats">
                  <select class="ql-color">
                    <option selected="selected"></option>
                    <option value="#e60000"></option>
                    <option value="#ff9900"></option>
                    <option value="#ffff00"></option>
                    <option value="#008a00"></option>
                    <option value="#0066cc"></option>
                    <option value="#9933ff"></option>
                    <option value="#ffffff"></option>
                    <option value="#facccc"></option>
                    <option value="#ffebcc"></option>
                    <option value="#ffffcc"></option>
                    <option value="#cce8cc"></option>
                    <option value="#cce0f5"></option>
                    <option value="#ebd6ff"></option>
                    <option value="#bbbbbb"></option>
                    <option value="#f06666"></option>
                    <option value="#ffc266"></option>
                    <option value="#ffff66"></option>
                    <option value="#66b966"></option>
                    <option value="#66a3e0"></option>
                    <option value="#c285ff"></option>
                    <option value="#888888"></option>
                    <option value="#a10000"></option>
                    <option value="#b26b00"></option>
                    <option value="#b2b200"></option>
                    <option value="#006100"></option>
                    <option value="#0047b2"></option>
                    <option value="#6b24b2"></option>
                    <option value="#444444"></option>
                    <option value="#5c0000"></option>
                    <option value="#663d00"></option>
                    <option value="#666600"></option>
                    <option value="#003700"></option>
                    <option value="#002966"></option>
                    <option value="#3d1466"></option>
                  </select>
                  <select class="ql-background">
                    <option value="#000000"></option>
                    <option value="#e60000"></option>
                    <option value="#ff9900"></option>
                    <option value="#ffff00"></option>
                    <option value="#008a00"></option>
                    <option value="#0066cc"></option>
                    <option value="#9933ff"></option>
                    <option selected="selected"></option>
                    <option value="#facccc"></option>
                    <option value="#ffebcc"></option>
                    <option value="#ffffcc"></option>
                    <option value="#cce8cc"></option>
                    <option value="#cce0f5"></option>
                    <option value="#ebd6ff"></option>
                    <option value="#bbbbbb"></option>
                    <option value="#f06666"></option>
                    <option value="#ffc266"></option>
                    <option value="#ffff66"></option>
                    <option value="#66b966"></option>
                    <option value="#66a3e0"></option>
                    <option value="#c285ff"></option>
                    <option value="#888888"></option>
                    <option value="#a10000"></option>
                    <option value="#b26b00"></option>
                    <option value="#b2b200"></option>
                    <option value="#006100"></option>
                    <option value="#0047b2"></option>
                    <option value="#6b24b2"></option>
                    <option value="#444444"></option>
                    <option value="#5c0000"></option>
                    <option value="#663d00"></option>
                    <option value="#666600"></option>
                    <option value="#003700"></option>
                    <option value="#002966"></option>
                    <option value="#3d1466"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <select class="ql-align">
                    <option selected=""></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-link" type="button"></button
                ></span>
              </template>
            </Editor>
          </div>
          <div class="col-12">
            <Message
              v-for="error of errors"
              severity="error"
              :key="error"
              :sticky="true"
              >{{ error }}</Message
            >
          </div>
        </div>

        <template #footer>
          <div class="flex justify-content-around mt-3">
            <Button
              :label="$t('profile.cancel')"
              icon="pi pi-times"
              @click.stop="
                resetBusinessUpdate();
                editing = false;
              "
              class="p-button-secondary"
            />
            <Button
              :label="$t('profile.save')"
              icon="pi pi-check"
              @click.stop="validateUpdateBusiness"
            />
          </div>
        </template>
      </Dialog>
      <!-- Complete Profile -->
      <Dialog
        v-model:visible="completingProfile"
        :modal="true"
        :dismissableMask="true"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
        @hide="resetBusinessUpdate"
      >
        <template #header>
          <h3>
            {{ businessToUpdate.BusName }},
            {{ $t("businessprofile.notyetcomplete") }}
          </h3>
        </template>

        <div class="p-fluid formgrid grid text-left">
          <div v-if="showBusDesc" class="field col-12">
            <label for="aboutus">{{ $t("footer.aboutus") }}</label>
            <div>{{ $t("businessprofile.aboutdesc") }}</div>
            <Textarea
              id="aboutus"
              rows="4"
              v-model="businessToUpdate.BusDesc"
            />
          </div>
          <div v-if="showSocialMedia">
            <div class="field col-12">
              <label> {{ $t("businessprofile.social") }}</label>
              <div class="mb-3">{{ $t("businessprofile.socialdesc") }}.</div>
              <div class="p-inputgroup mb-3">
                <span class="p-inputgroup-addon"
                  ><i class="pi pi-globe"></i>{{ $t("details.webpage") }}</span
                >
                <InputText
                  id="weburl"
                  type="text"
                  v-model="businessToUpdate.BusWebUrl"
                  placeholder="https://yourwebsite.com"
                />
              </div>
              <div class="p-inputgroup mb-3">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-facebook"></i> https://www.facebook.com/
                </span>
                <InputText
                  id="fburl"
                  type="text"
                  v-model="businessToUpdate.BusFbUrl"
                  placeholder="yourpage"
                />
              </div>

              <div class="p-inputgroup mb-3">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-twitter"></i> https://twitter.com/
                </span>
                <InputText
                  id="twiturl"
                  type="text"
                  v-model="businessToUpdate.BusTwitUrl"
                  placeholder="handle"
                />
              </div>

              <div class="p-inputgroup mb-3">
                <span class="p-inputgroup-addon">
                  <i class="pi pi-instagram"></i> https://www.instagram.com/
                </span>
                <InputText
                  id="instURL"
                  type="text"
                  v-model="businessToUpdate.BusInstUrl"
                  placeholder="username"
                />
              </div>

              <div class="p-inputgroup mb-3">
                <span class="p-inputgroup-addon"> https://pinterest.com/ </span>
                <InputText
                  id="pinurl"
                  type="text"
                  v-model="businessToUpdate.BusPinUrl"
                  placeholder="username"
                />
              </div>
            </div>
          </div>
          <div v-if="showBusType" class="field col-12">
            <label>{{ $t("details.main") }}</label>
            <div class="mb-3">{{ $t("businessprofile.buscatdesc") }}</div>
            <Dropdown
              v-model="businessToUpdate.BusCategory"
              :options="serviceTypes"
              optionValue="ServTypeTitle"
              :placeholder="$t('services.pleaseselect')"
            >
              <template #value="slotProps">
                <span v-if="slotProps.value == null">
                  {{ slotProps.placeholder }}
                </span>
                <span v-else-if="typeof slotProps.value !== 'String'">
                  {{ $t($getServiceType(slotProps.value)) }}
                </span>
                <span v-else>
                  {{ $t($getServiceType(slotProps.value.ServTypeTitle)) }}
                </span>
              </template>
              <template #option="slotProps">
                <span>{{
                  $t($getServiceType(slotProps.option.ServTypeTitle))
                }}</span>
              </template>
            </Dropdown>
          </div>
          <div v-if="showEventTypes" class="field col-12">
            <label for="eventtypes">{{ $t("details.eventtype") }}</label>
            <div class="mb-3">{{ $t("businessprofile.eventtypesdesc") }}</div>
            <MultiSelect
              v-model="businessToUpdate.StringEventTypes"
              :options="eventTypes"
              :placeholder="$t('services.selectall')"
              optionValue="EventTypeLabel"
            >
            
              <template #value="slotProps">
                <template v-for="option of slotProps.value" :key="option">
                  <span>
                    <Chip class="mr-1"> {{ getEventType(option) }}</Chip>
                  </span>
                </template>
              </template>
              <template #option="slotProps">
                <span>{{ getEventType(slotProps.option.EventTypeLabel) }}</span>
              </template>
            </MultiSelect>
          </div>
          <div v-if="showPartySizes" class="field col-12">
            <label for="attendeecounts">{{ $t("details.attendees") }}</label>
            <div class="mb-3">{{ $t("businessprofile.parysizesdesc") }}</div>
            <MultiSelect
              v-model="businessToUpdate.StringAttendeeCounts"
              :options="attendeeCounts"
              optionLabel="AttendeeCountDefLabel"
              optionValue="AttendeeCountDefLabel"
              :placeholder="$t('services.selectall')"
            />
          </div>
          <div v-if="showPartySizes" class="field col-12">
            <label for="attendeecounts">{{ $t("details.attendees") }}</label>
            <div class="mb-3">{{ $t("businessprofile.parysizesdesc") }}</div>
            <MultiSelect
              v-model="businessToUpdate.StringAttendeeCounts"
              :options="attendeeCounts"
              optionLabel="AttendeeCountDefLabel"
              optionValue="AttendeeCountDefLabel"
              :placeholder="$t('services.selectall')"
            />
          </div>
          <div v-if="showLogo" class="field col-12">
            <label>{{ $t("details.logo") }}</label>
            <br />
            {{ $t("businessprofile.logodes") }}
          </div>
          <div v-if="showLogo" class="mb-3 text-center">
            <div v-if="hideLogoUpload" class="">
              <img
                v-if="businessToUpdate.NewLogo != null && cropimage == null"
                :src="imageHostingURL + businessToUpdate.BusLogo"
                :alt="businessToUpdate.BusLogo"
                width="100"
              />
              <img
                v-if="cropimage != null"
                :src="cropimage"
                :alt="businessToUpdate.NewLogo"
                width="100"
              />
              <br />
              <span class="p-buttonset">
                <Button
                  class="p-button-info p-button-sm"
                  :label="$t('menu.replacelogo')"
                  @click.stop="replaceLogo()"
                  icon="pi pi-image"
                />
                <Button
                  v-if="cropimage != null"
                  class="p-button-info p-button-sm"
                  :label="$t('profile.adjust')"
                  @click.stop="showCropper = true"
                  icon="pi pi-window-minimize"
                />
              </span>
            </div>
            <FileUpload
              v-else
              name="logo"
              :maxFileSize="9000000"
              :fileLimit="1"
              accept="image/*"
              mode="basic"
              :multiple="false"
              :auto="true"
              :customUpload="true"
              :chooseLabel="$t('profile.choose')"
              :showUploadButton="true"
              @uploader="uploadLogo"
            >
              <template #empty>
                <p>{{ $t("details.dragdrop") }}</p>
              </template>
            </FileUpload>
          </div>

          <div v-if="showGallery" class="field col-12">
            <label>{{ $t("details.gallery") }}</label>
            <div class="mb-3">{{ $t("businessprofile.imagegallerydesc") }}</div>
            <em>{{ galleryCountLeft }} {{ $t("details.images") }} 20</em>
            <FileUpload
              v-if="galleryCountLeft > 0"
              name="gallery"
              :maxFileSize="9000000"
              accept="image/*"
              :multiple="true"
              mode="basic"
              :customUpload="true"
              :chooseLabel="$t('profile.choose')"
              :showUploadButton="true"
              @uploader="uploadGallery"
              :auto="true"
            >
              <template #empty>
                <p>{{ $t("details.dragdropgallery") }}</p>
              </template>
            </FileUpload>

            <Message
              v-if="showGalleryErrorMessage == true"
              severity="error"
              class="mt-2"
              >{{ galleryErrorMessage }}</Message
            >
            <div class="flex flex-wrap pt-3">
              <span
                v-for="img in businessToUpdate.NewBusinessImages"
                :key="img.name"
              >
                <Chip
                  class="mr-2 mb-2 modifyChip imageChip"
                  style="font-size: small"
                  :image="img.objectURL"
                  removable
                  v-on:remove="removeNewImage(img.name)"
                />
              </span>
              <br />
              <span
                v-for="img in businessToUpdate.BusinessImages"
                :key="img.name"
              >
                <Chip
                  class="mr-2 mb-2 modifyChip imageChip"
                  style="font-size: small"
                  :image="imageHostingURL + img.BusImage"
                  removable
                  v-on:remove="removeGalleryImage(img.BusImageId)"
                />
              </span>
            </div>
          </div>
          <!-- <div class="col-12">
            <Message
              v-for="error of errors"
              severity="error"
              :key="error"
              :sticky="true"
              >{{ error }}</Message
            >
          </div> -->
        </div>

        <template #footer>
          <div class="flex justify-content-around mt-3">
            <Button
              :label="$t('profile.cancel')"
              icon="pi pi-times"
              @click.stop="
                resetBusinessUpdate();
                completingProfile = false;
              "
              class="p-button-secondary"
            />
            <Button
              :label="$t('profile.save')"
              icon="pi pi-check"
              @click.stop="validateUpdateBusiness"
              autofocus
            />
          </div>
        </template>
      </Dialog>

      <Dialog
        v-model:visible="showCropper"
        :contentStyle="{ overflow: 'scroll' }"
        :header="$t('businessprofile.cropper')"
        :dismissableMask="true"
        style="z-index: 1030"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <cropper
          v-if="
            businessToUpdate.NewLogo != null &&
            businessToUpdate.NewLogo.length > 0 &&
            hideLogoUpload == true
          "
          class="cropper"
          :src="businessToUpdate.NewLogo[0].objectURL"
          :stencil-props="{
            aspectRatio: 1 / 1,
          }"
          @change="change"
        />
        <Button
          :label="$t('menu.done')"
          icon="pi pi-check"
          iconPos="right"
          @click.stop="showCropper = false"
          class="mr-2 mt-2 p-button-success p-button-sm p-button-raised"
        />
      </Dialog>
       <!-- show business policies -->
          <Dialog
            v-model:visible="showServicePolicy"
            :modal="true"
            :contentStyle="{ overflow: 'scroll' }"
            :header="$t('businessprofile.sla')"
            style="text-align: center"
            :dismissableMask="true"
            :style="{ width: '50vw' }"
            :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
          >
            <span
              class="ql-editor"
              v-html="currentBusiness.BusPolicy"
            ></span>
          </Dialog>
      <Galleria
        :value="currentBusiness.BusinessImages"
        :responsiveOptions="responsiveOptions2"
        :circular="true"
        :fullScreen="true"
        :numVisible="9"
        :showItemNavigators="true"
        v-model:visible="isFullScreen"
      >
        <template #item="slotProps">
          <img
            :src="imageHostingURL + slotProps.item.BusImage"
            :alt="slotProps.item.alt"
            style="max-width: 75%; display: block"
          />
        </template>
        <template #thumbnail="slotProps">
          <img
            :src="imageHostingURL + slotProps.item.BusImage"
            :alt="slotProps.item.alt"
            style="width: 50px; heigth: 50px; margin: 5px"
          />
        </template>
      </Galleria>
    </div>
    <div
      v-else-if="currentBusiness.BusActive == false && currentBusiness != null"
    >
      <!-- @refresh.stop="$router.go()" -->
      <inactive-subscription
        :busId="currentBusiness.BusId"
      ></inactive-subscription>
    </div>
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{ $t("message.error") }}</Message
      >
    </div>
  </div>
  
    <!-- <VideoPlayer
      class="videoplayer"
      src="https://res.cloudinary.com/demo/video/upload/q_auto,f_auto/dog.mp4"
      :muted="false"
      :autoplay="false"
      :controls="true"
      :loop="false"
      poster="imageHostingURL + slotProps.item.BusImage"
      @play="onPlayerPlay"
      @pause="onPlayerPause"
      @ended="onPlayerEnded"
      @loadeddata="onPlayerLoadeddata"
      @waiting="onPlayerWaiting"
      @playing="onPlayerPlaying"
      @timeupdate="onPlayerTimeupdate"
      @canplay="onPlayerCanplay"
      @canplaythrough="onPlayerCanplaythrough"
      @statechanged="playerStateChanged"
    >
      <template
        v-slot:controls="{
          togglePlay,
          playing,
          percentagePlayed,
          seekToPercentage,
          duration,
          convertTimeToDuration,
          videoMuted,
          toggleMute,
        }"
      >
        <div class="videoplayer-controls">
          <button @click="togglePlay()" class="videoplayer-controls-toggleplay">
            {{ playing ? "pause" : "play" }}
          </button>
          <div class="videoplayer-controls-time">
            {{ convertTimeToDuration(time) }} /
            {{ convertTimeToDuration(duration) }}
          </div>
          <VideoPlayertrack
            :percentage="percentagePlayed"
            @seek="seekToPercentage"
            class="videoplayer-controls-track"
          />
          <button @click="toggleMute()" class="videoplayer-controls-togglemute">
            {{ videoMuted ? "unmute" : "mute" }}
          </button>
        </div>
      </template>
    </videoplayer>
   -->
</template>
<script>
 
import { mapState } from "vuex";
import { mapActions } from "vuex";
import Business from "../store/Models/Business";
import EventTypeDef from "../store/Models/EventTypeDef";
import AttendeeCountDef from "../store/Models/AttendeeCountDef";
import ServiceTypeDef from "../store/Models/ServiceTypeDef";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import ActiveLocation from "../store/Models/ActiveLocation";

import ServiceTypeLabel from "../components/ServiceTypeLabel.vue";
import EventTypeLabel from "../components/EventTypeLabel.vue";
import InactiveSubscription from "../components/InactiveSubscription.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
let states = require("../data/states.js");
import { Cropper } from "vue-advanced-cropper";
//import VideoPlayer from "../components/VideoPlayer";
//import VideoPlayertrack from "../components/VideoPlayer-track";



export default {
  name: "BusinessProfile" ,
  components: {
    Cropper,
    LoadingOverlay,
    InactiveSubscription,
    ServiceTypeLabel,
    EventTypeLabel,
    VueGoogleAutocomplete,
   //VideoPlayer,
    //VideoPlayertrack,
  },
  data() {
    return {
      time:0,
      showCropper: false,
      businessOwner: false,
      cropimage: null,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      
      galleryCountLeft: 20,
      showServicePolicy:false,
      hideLogoUpload: false,
      logoFileName: null,
      currentBusiness: {},
      errors: [],
      eventTypes: [],
      attendeeCounts: [],
      serviceTypes: [],
      galleryErrorMessage: "",
      showGalleryErrorMessage: false,
      invalidAddress: true,
      busAddrValidated: false,
      businessToUpdate: {
        BusId: 0,
        BusPhone: "",
        BusName: "",
        BusAddress: "",
        BusHideAddress: false,
        BusCity: "",
        BusZip: "",
        BusState: "",
        BusEmail: "",
        BusWebUrl: "",
        BusFbUrl: "",
        BusTwitUrl: "",
        BusInstUrl: "",
        BusPinUrl: "",
        BusDesc: "",
        BusOccupancy: 0,
        BusOccupancyDisabled: false,
        BusPolicy: "",
        BusCategory: "",
        BusOwnerCustId: 0,
        BusLogo: "",
        BusVisible: "",
        BusEnrolledDate: "",
        BusQuoteExpiration: "",
        BusHandle: "",
        EventTypes: [],
        AttendeeCounts: [],
        BusinessImages: [],
        NewBusinessImages: [],
        NewLogo: [],
        StringEventTypes: [],
        StringAttendeeCounts: [],
        BusinessServiceLocations:[],
      
      },
      pageload: true,
      webHost: process.env.VUE_APP_MYRESERVS_URL,
      percentComplete: 100,
      percentOnPageLoad: 100,
      justCompleted: false,
      showBusDesc: false,
      showBusType: false,
      showEventTypes: false,
      showPartySizes: false,
      showGallery: false,
      showSocialMedia: false,
      showLogo: false,
      showVisible: false,
      completingProfile: false,
      editing: false,
      loading: true,
      selectedState: null,
      validatingHandle: false,
      handleverified: false,
      handlevalid: false,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 4,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      responsiveOptions2: [
        {
          breakpoint: "1500px",
          numVisible: 5,
        },
        {
          breakpoint: "1024px",
          numVisible: 3,
        },
        {
          breakpoint: "768px",
          numVisible: 2,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      isFullScreen: false,
    };
  },
  async mounted() {
    this.$log.info("business-profile-mounted");
    
    await this.$VerifyRole("Owner").then(async (response) => {
     //console.log(response);
      if (response == 0) {
       //console.log(this.$route.name);
       await this.$FetchRoles("Owner").then(async (response) => {
            console.log(response);
      if (response == 0) {
        this.$SignOutRedirect(this.$route.name);
      }
    }
       );
      }
    });
    if (this.currentBusinessId != 0) {
      this.loading = true;
      this.setSelectedBusiness();
      this.$log.debug(states);
    }
    
    this.eventTypes = await EventTypeDef.fetchAll();
    this.attendeeCounts = await AttendeeCountDef.fetchAll();
    this.serviceTypes = await ServiceTypeDef.fetchAll();
    this.activeLocations = await ActiveLocation.fetchAll();
    this.$log.debug(this.activeLocations);
    this.$log.debug(this.eventTypes);
    this.$log.debug(this.attendeeCounts);
    this.$log.debug(this.serviceTypes);
    
  },
  methods: {
    change({ coordinates, canvas }) {
      this.$log.debug(coordinates, canvas);
      this.coordinates = coordinates;
      this.cropimage = canvas.toDataURL();
    },
    ...mapActions(["refreshUserData"]),
    getEventType(eventtype) {
      this.$log.debug(eventtype);
      switch (eventtype) {
        case "Birthday Parties":
          return this.$t("eventtypes.birthdayparties");
        case "Baby Showers":
          return this.$t("eventtypes.babyshower");
        case "Bridal Showers":
          return this.$t("eventtypes.bridalshowers");
        case "Weddings":
          return this.$t("eventtypes.weddings");
        case "Kids Birthday Parties":
          return this.$t("eventtypes.kidsbirthdayparties");
        case "Quinceañeras":
          return this.$t("eventtypes.quinceaneras");
        default:
          return "";
      }
    },
  
    
    setBusinessAddress: function (addressData) {
      this.$log.debug(addressData);
      if (typeof addressData !== "undefined") {
        this.businessToUpdate.BusAddress =
          addressData.street_number + " " + addressData.route;
        this.businessToUpdate.BusCity = addressData.locality;
        this.businessToUpdate.BusState =
          addressData.administrative_area_level_1;
        this.businessToUpdate.BusZip = addressData.postal_code;
        this.busAddrValidated = true;
        this.invalidAddress = false;
      } else {
        this.invalidAddress = true;
        this.$log.debug("Invalid Address");
        this.$log.debug(this.invalidAddress);
      }
    },
    clearLogoUpload() {
      this.$log.debug("clear logo");
    },
    async removeNewImage(newImgName) {
      this.galleryCountLeft += 1;
      this.$log.debug("remove image" + newImgName);
      var imgToRemove = this.businessToUpdate.NewBusinessImages.find(
        (img) => img.name == newImgName
      );
      this.businessToUpdate.NewBusinessImages.splice(
        this.businessToUpdate.NewBusinessImages.indexOf(imgToRemove),
        1
      );
    },
    async removeGalleryImage(busImgId) {
      this.galleryCountLeft += 1;
      this.$log.debug("remove image" + busImgId);
      await Business.deleteBusinessImage(busImgId).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          this.currentBusiness = await Business.fetchBusinessProfileById(
            this.currentBusinessId
          );
          this.$log.debug(this.currentBusiness);
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.image"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.imagefailed"),
            life: 3000,
          });
        }
      });
    },
    replaceLogo() {
      this.hideLogoUpload = false;
      this.$log.debug(this.hideLogoUpload);
    },
    uploadLogo(event) {
      this.$log.debug("uploadLogo event");
      this.$log.debug(event.files);
      // if (event.files != null) {
      this.businessToUpdate.NewLogo = event.files;
      this.$log.debug(this.businessToUpdate.NewLogo);
      // this.logoFileName = event.files[0].name;
      this.hideLogoUpload = true;
      //this.hideProfImageUpload = true;
      this.showCropper = true;
      //}
    },
    /* Utility function to convert a canvas to a BLOB */
    dataURLToBlob(dataURL) {
      var BASE64_MARKER = ";base64,";
      var parts = "";
      var contentType = "";
      var raw = "";
      if (dataURL.indexOf(BASE64_MARKER) == -1) {
        parts = dataURL.split(",");
        contentType = parts[0].split(":")[1];
        raw = parts[1];

        const Data = new Blob([raw], { type: contentType });
        let file = new File([Data], "image.jpg", {type:contentType});
        return file;
      }

      parts = dataURL.split(BASE64_MARKER);
      contentType = parts[0].split(":")[1];
      raw = window.atob(parts[1]);
      var rawLength = raw.length;

      var uInt8Array = new Uint8Array(rawLength);

      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      const Data =  new Blob([uInt8Array], { type: contentType });
      let file = new File([Data], "image.jpg", {type:contentType});
        return file;
    },
    /* End Utility function to convert a canvas to a BLOB      */
    uploadGallery(event) {
      this.$log.debug("uploadGallery event");
      this.$log.debug(event);
      this.galleryCountLeft = this.galleryCountLeft - event.files.length;
      event.files.forEach((file) => {
        this.businessToUpdate.NewBusinessImages.push(file);
      });

      this.$log.debug(event.files.length);
      this.$log.debug(this.galleryCountLeft);
      this.$log.debug(this.businessToUpdate.NewBusinessImages);
      if (event.files.length > this.galleryCountLeft) {
        this.galleryErrorMessage =
          "You have exceeded the number of allowed images. Only the first " +
          this.galleryCountLeft +
          " images will be uploaded.";
        this.showGalleryErrorMessage = true;
      } else {
        this.showGalleryErrorMessage = false;
      }
    },
    setBusinessUpdate() {
      this.$log.info("setting business to update");
      this.$log.debug(this.currentBusiness);
      //set business to update
      this.businessToUpdate = {
        BusId: this.currentBusiness.BusId,
        BusPhone: this.currentBusiness.BusPhone,
        BusName: this.currentBusiness.BusName,
        BusAddress: this.currentBusiness.BusAddress,
        BusHideAddress:
          this.currentBusiness.BusHideAddress == true ? true : false,
        BusCity: this.currentBusiness.BusCity,
        BusZip: this.currentBusiness.BusZip,
        BusState: this.currentBusiness.BusState,
        BusEmail: this.currentBusiness.BusEmail,
        BusWebUrl: this.currentBusiness.BusWebUrl,
        BusFbUrl: this.currentBusiness.BusFbUrl,
        BusTwitUrl: this.currentBusiness.BusTwitUrl,
        BusInstUrl: this.currentBusiness.BusInstUrl,
        BusPinUrl: this.currentBusiness.BusPinUrl,
        BusDesc: this.currentBusiness.BusDesc,
        BusPolicy: this.currentBusiness.BusPolicy,
        BusOccupancy: this.currentBusiness.BusOccupancy,
        BusOccupancyDisabled:
          this.currentBusiness.BusOccupancyDisabled == true ? true : false,
        BusCategory:
          this.currentBusiness.BusCategory.length > 0
            ? this.currentBusiness.BusCategory
            : null,
        BusOwnerCustId: this.currentBusiness.BusOwnerCustId,
        BusLogo: this.currentBusiness.BusLogo,
        EventTypes: this.currentBusiness.EventTypes,
        AttendeeCounts: this.currentBusiness.AttendeeCounts,
        BusinessImages: this.currentBusiness.BusinessImages,
        BusVisible: this.currentBusiness.BusVisible,
        BusHandle: this.currentBusiness.BusHandle,
        NewBusinessImages: [],
        NewLogo: [],
        StringEventTypes: [],
        StringAttendeeCounts: [],
        BusinessServiceLocations:this.currentBusiness.BusinessServiceLocations,
        ActiveLocations:[]
      };

      this.$log.debug(this.businessToUpdate);

      if (this.currentBusiness.EventTypes != null) {
        let self = this;
        this.currentBusiness.EventTypes.forEach((eventType) =>
          self.businessToUpdate.StringEventTypes.push(eventType.EventTypeLabel)
        );
      }
      if (this.currentBusiness.BusinessServiceLocations != null) {
        let self = this;
        this.currentBusiness.BusinessServiceLocations.forEach((location) =>
          self.businessToUpdate.ActiveLocations.push(location.Location)
        );
      }

      if (this.currentBusiness.AttendeeCounts != null) {
        let self = this;
        this.currentBusiness.AttendeeCounts.forEach((count) =>
          self.businessToUpdate.StringAttendeeCounts.push(
            count.AttendeeCountLabel
          )
        );
      }

      if (
        this.currentBusiness.BusinessImages != null &&
        this.currentBusiness.BusinessImages.length > 0
      ) {
        this.galleryCountLeft =
          this.galleryCountLeft - this.currentBusiness.BusinessImages.length;
      }

      //set logo status
      if (this.currentBusiness.BusLogo != null) {
        this.hideLogoUpload = true;
      } else {
        this.hideLogoUpload = false;
      }
      //set editing to show dialog

      this.$log.debug(this.businessToUpdate);
    },
    validateHandle() {
      this.validatingHandle = true;
      if (
        this.currentBusiness.BusHandle.toUpperCase() !=
        this.businessToUpdate.BusHandle.toUpperCase()
      ) {
        Business.fetchHandleAvailability(this.businessToUpdate.BusHandle).then(
          async (response) => {
            this.$log.debug(response);
            this.handleverified = true;
            if (response == true) {
              document
                .getElementById("bushandle")
                .classList.remove("p-invalid");
              this.handlevalid = true;
            } else {
              document.getElementById("bushandle").classList.add("p-invalid");
              this.handlevalid = false;
            }
            this.validatingHandle = false;
          }
        );
      } else {
        this.handlevalid = true;
        this.handleverified = true;
        this.validatingHandle = false;
      }
    },
    validateUpdateBusiness() {
      this.$log.info("validating business profile update");
      this.$log.debug(this.businessToUpdate);
      //clear out errors
      this.errors = [];
      if (
        this.businessToUpdate.NewLogo != null &&
        this.businessToUpdate.NewLogo.length > 0
      ) {
        this.businessToUpdate.NewLogo[0] = this.dataURLToBlob(this.cropimage);
      }

      if (
        document.getElementById("busaddress") != null &&
        document.getElementById("busaddress").value.length > 0
      ) {
        this.businessToUpdate.BusAddress =
          document.getElementById("busaddress").value;
      }

      //verify required values
      if (this.businessToUpdate.BusDesc.length == 0) {
        this.errors.push(this.$t("listmybusiness.reabout"));
        document.getElementById("aboutus").classList.add("p-invalid");
      }
      if (this.businessToUpdate.BusName.length == 0) {
        this.errors.push(this.$t("listmybusiness.rebusname"));
        document.getElementById("name").classList.add("p-invalid");
      }
      if (this.businessToUpdate.BusHandle.length == 0) {
        this.errors.push(this.$t("listmybusiness.rebushandle"));
        document.getElementById("bushandle").classList.add("p-invalid");
      }
      if (this.businessToUpdate.BusPhone.length == 0) {
        this.errors.push(this.$t("listmybusiness.rephone"));
        document.getElementById("phone").classList.add("p-invalid");
      }
      if (this.businessToUpdate.BusAddress.length == 0) {
        this.errors.push(this.$t("listmybusiness.readdress"));
        document.getElementById("address").classList.add("p-invalid");
      }
      if (this.businessToUpdate.BusCity.length == 0) {
        this.errors.push(this.$t("listmybusiness.recity"));
        document.getElementById("city").classList.add("p-invalid");
      }
      if (this.businessToUpdate.BusZip.length == 0) {
        this.errors.push(this.$t("listmybusiness.zip"));
        document.getElementById("zip").classList.add("p-invalid");
      }
      if (this.businessToUpdate.BusState.length == 0) {
        this.errors.push(this.$t("listmybusiness.state"));
        document.getElementById("state").classList.add("p-invalid");
      }
      if (this.businessToUpdate.BusLogo == null) {
        this.businessToUpdate.BusLogo =
          "Bus" + this.businessToUpdate.BusId + "Logo";
        // document.getElementById("state").classList.add("p-invalid");
      }
      if (
        this.businessToUpdate.BusOccupancy < 1 &&
        this.businessToUpdate.BusOccupancyDisabled != true
      ) {
        this.errors.push(this.$t("listmybusiness.maxoccup"));
        document.getElementById("occupancy").classList.add("p-invalid");
      }
      //clear event types and reset using string event types
      this.businessToUpdate.EventTypes = [];
      this.businessToUpdate.StringEventTypes.forEach((eventType) => {
        this.businessToUpdate.EventTypes.push({
          EventTypeId: 0,
          EventTypeLabelDefId: 0,
          EventTypeLabel: eventType,
        });
      });

      //clear businessservicelocations and reset using string event types
      this.businessToUpdate.BusinessServiceLocations = [];
      this.businessToUpdate.ActiveLocations.forEach((Location) => {
        this.businessToUpdate.BusinessServiceLocations.push({
          BusServLocId: 0,
          LocationId: Location.LocationId,
          LocationCity: Location,
        });
      });
      //clear  and reset using string AttendeeCounts
      this.businessToUpdate.AttendeeCounts = [];
      this.businessToUpdate.StringAttendeeCounts.forEach((count) => {
        this.businessToUpdate.AttendeeCounts.push({
          AttendeeCountId: 0,
          AttendeeCountLabel: count,
        });
      });

      if (this.errors.length == 0) this.updateBusiness();
    },
    invalidAddressEntered() {
      document.getElementById("busaddress").classList.add("p-invalid");
      this.invalidAddress = true;
      this.busAddrValidated = false;
    },
    async updateBusiness() {
      this.$log.info("updating business profile");
      this.$log.debug("business before update");
      this.$log.debug(this.businessToUpdate);
      this.loading = true;
      await Business.updateBusiness(this.businessToUpdate).then(
        async (response) => {
          this.$log.debug(response);
          if (response == 1) {
            this.refreshUserData(this.currentCustomerId);
            this.currentBusiness = await Business.fetchBusinessProfileById(
              this.currentBusinessId
            );
            this.resetBusinessUpdate();
          }
        }
      );
      this.loading = false;
      this.editing = false;
      this.completingProfile = false;
      this.$log.debug(this.completingProfile);
      this.verifyProfileCompletness(false);
    },
    resetBusinessUpdate() {
      //hide update dialog

      this.hideLogoUpload = false;
      this.logoFileName = null;
      this.galleryCountLeft = 10;
      //reset businessToUpdate property
      this.businessToUpdate = {
        BusId: 0,
        BusPhone: "",
        BusName: "",
        BusAddress: "",
        BusHideAddress: false,
        BusCity: "",
        BusZip: "",
        BusState: "",
        BusEmail: "",
        BusWebUrl: "",
        BusFbUrl: "",
        BusTwitUrl: "",
        BusInstUrl: "",
        BusPinUrl: "",
        BusDesc: "",
        BusPolicy: "",
        BusOccupancy: 0,
        BusOccupancyDisabled: false,
       
        BusOwnerCustId: 0,
        BusLogo: "",
        BusVisible: "",
        BusEnrolledDate: "",
        BusQuoteExpiration: "",
        BusSalesTaxPercent: "",
        BusHandle: "",
        AttendeeCounts: [],
        BusinessImages: [],
        NewBusinessImages: [],
        NewLogo: [],
        StringEventTypes: [],
        StringAttendeeCounts: [],
        BusinessServiceLocations:[]
      };

      this.showCropper = false;
      this.cropimage = null;
      this.coordinates = {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      };
      this.validatingHandle = false;
      this.handleverified = false;
      this.handlevalid = false;
      // this.showBusDesc = false;
      // this.showBusType = false;
      // this.showEventTypes = false;
      // this.showPartySizes = false;
      // this.showGallery = false;
      // this.showSocialMedia = false;
      // this.showLogo = false;
      //clear errors
      this.errors = [];
    },
    setSelectedBusiness() {
      Business.fetchBusinessProfileById(this.currentBusinessId).then(
        (result) => {
         //console.log(result);
          this.loading = false;
          this.currentBusiness = result;
          //document.title = this.currentBusiness.BusName;
         //console.log(this.currentBusiness);

          this.verifyProfileCompletness(false);

          if (
            this.currentCustomerId != null &&
            this.currentBusiness != null &&
            this.currentCustomerId == this.currentBusiness.BusOwnerCustId
          ) {
            this.businessOwner = true;
            this.$log.debug(this.businessOwner);
          } else {
            this.businessOwner = false;
          }
        }
      );
    },
    enableVisibility() {
      this.setBusinessUpdate();
      this.$confirm.require({
        message: this.$t("businessprofile.goliveconfirm"),
        header: this.$t("businessprofile.golive"),
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.businessToUpdate.BusVisible = true;
          this.validateUpdateBusiness();
        },
        reject: () => {
          this.resetBusinessUpdate();
        },
      });
    },
    disableVisibility() {
      this.setBusinessUpdate();
      this.$confirm.require({
        message: this.$t("businessprofile.goofflineconfirm"),
        header: this.$t("businessprofile.gooffline"),
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.businessToUpdate.BusVisible = false;
          this.validateUpdateBusiness();
        },
        reject: () => {
          this.resetBusinessUpdate();
        },
      });
    },
    verifyProfileCompletness(force) {
      let missingDesc = false,
        missingBusCategory = false,
        missingEventTypes = false,
        missingAttendeeCounts = false,
        missingBusImages = false,
        missingSocialMedia = false,
        missingLogo = false,
        missingVisible = false;

      if (this.currentBusiness.BusIncompleteProfileDismissed != true) {
        this.showBusDesc = false;
        this.showBusType = false;
        this.showEventTypes = false;
        this.showPartySizes = false;
        this.showGallery = false;
        this.showSocialMedia = false;
        this.showLogo = false;
        this.completingProfile = false;

        this.percentComplete = 100;
        if (this.currentBusiness != null) {
          if (this.currentBusiness.BusDesc.length < 20) {
            this.percentComplete -= 10;
            missingDesc = true;
          }
          if (
            this.currentBusiness.BusCategory == null ||
            this.currentBusiness.BusCategory.length == 0
          ) {
            this.percentComplete -= 10;
            missingBusCategory = true;
          }
          if (this.currentBusiness.EventTypes.length == 0) {
            this.percentComplete -= 10;
            missingEventTypes = true;
          }
          if (this.currentBusiness.AttendeeCounts.length == 0) {
            this.percentComplete -= 10;
            missingAttendeeCounts = true;
          }
          if (this.currentBusiness.BusinessImages.length == 0) {
            this.percentComplete -= 10;
            missingBusImages = true;
          }
          if (
            this.currentBusiness.BusWebUrl.length == 0 &&
            this.currentBusiness.BusTwitUrl.length == 0 &&
            this.currentBusiness.BusInstUrl.length == 0 &&
            this.currentBusiness.BusFbUrl.length == 0
          ) {
            this.percentComplete -= 10;
            missingSocialMedia = true;
          }
          if (this.currentBusiness.BusLogo == "defaultBusLogo.png") {
            this.percentComplete -= 10;
            missingLogo = true;
          }
          // if (this.currentBusiness.BusVisible == false) {
          //   this.percentComplete -= 10;
          //   missingVisible = true;
          // }

          if (this.pageload == true) {
            this.pageload = false;
            this.percentOnPageLoad = this.percentComplete;
          } else {
            if (this.percentComplete == 100 && this.percentOnPageLoad != 100) {
              this.justCompleted = true;
            }
          }
        }
      }
      //Display one item at a time
      // if (missingBusCategory) {
      //    this.showBusType = true;
      //   this.$log.debug("buscat");
      //  } else if (missingEventTypes) {
      //    this.showEventTypes = true;
      //    this.$log.debug("eventtypes");
      // } else if (missingAttendeeCounts) {
      //  this.showPartySizes = true;
      // this.$log.debug("partysizes");
      // } else if (missingBusImages) {
      //  this.showGallery = true;
      //   this.$log.debug("busimages");
      //  } else if (missingSocialMedia) {
      //   this.showSocialMedia = true;
      //    this.$log.debug("social");
      //  } else if (missingLogo) {
      //   this.showLogo = true;
      //   this.$log.debug("logo");
      // } else if (missingDesc) {
      //   this.showBusDesc = true;
      //   this.$log.debug("desc");
      //  }
      //Display all at once item at a time
      if (missingBusCategory) {
        this.showBusType = true;
        this.$log.debug("buscat");
      }
      if (missingEventTypes) {
        this.showEventTypes = true;
        this.$log.debug("eventtypes");
      }
      if (missingAttendeeCounts) {
        this.showPartySizes = true;
        this.$log.debug("partysizes");
      }
      if (missingBusImages) {
        this.showGallery = true;
        this.$log.debug("busimages");
      }
      if (missingSocialMedia) {
        this.showSocialMedia = true;
        this.$log.debug("social");
      }
      if (missingLogo) {
        this.showLogo = true;
        this.$log.debug("logo");
      }
      if (missingDesc) {
        this.showBusDesc = true;
        this.$log.debug("desc");
      }
      // else if (missingVisible) {
      //   this.showVisible = true;
      //   this.$log.debug("visible");
      // }

      if (
        missingBusCategory ||
        missingEventTypes ||
        missingAttendeeCounts ||
        missingBusImages ||
        missingLogo ||
        missingVisible ||
        missingDesc ||
        force
      ) {
        this.setBusinessUpdate();
        this.completingProfile = true;
      }
      this.$log.debug(this.completingProfile);
    },
  },
  onPlayerPlay({ event, player }) {
     //console.log(event.type);
      player.setPlaying(true);
    },
    onPlayerPause({ event, player }) {
     //console.log(event.type);
      player.setPlaying(false);
    },
    onPlayerEnded({ event, player }) {
     //console.log(event.type);
      player.setPlaying(false);
    },
    onPlayerLoadeddata({ event }) {
     //console.log(event.type);
    },
    onPlayerWaiting({ event }) {
     //console.log(event.type);
    },
    onPlayerPlaying({ event }) {
     //console.log(event.type);
    },
    onPlayerTimeupdate({ event }) {
      this.time = event.target.currentTime;
     //console.log({ event: event.type, time: event.target.currentTime });
    },
    onPlayerCanplay({ event }) {
     //console.log(event.type);
    },
    onPlayerCanplaythrough({ event }) {
     //console.log(event.type);
    },
    playerStateChanged({ event }) {
     //console.log(event.type);
    },
  
  watch: {
    async currentBusinessId() {
      this.loading = true;
      this.setSelectedBusiness();
      this.pageload = true;
      this.justCompleted = false;
    },
  },
  computed: {
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
      currentCustomerId: (state) => state.currentCustomerId,
    }),
    compStates() {
      return states;
    },
  },
  
};

</script>
<style >
.p-fileupload-choose ~ .p-button {
  display: none;
}
.modifyChip.imageChip {
  border-radius: 0vw;
  font-size: medium;
}
.modifyChip.imageChip img {
  height: 3rem;
  width: auto;
}
label {
  font-weight: bold;
}
.p-fileupload .p-fileupload-row {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1px !important;
  margin: 10px;
  width: auto;
  text-align: left;
}

.info .p-progressbar-label {
  color: #044868 !important;
}

.panel-primary .p-panel-header {
  background: var(--mr-secondary-color) !important;
  color: var(--primary-color) !important;
}

.panel-info .p-panel-header {
  background: #b3e5fc !important;
  color: #044868 !important;
}



.info .p-progressbar-value {
  background-color: #0891cf !important;
  color: #044868 !important;
}

.success .p-progressbar-value {
  background-color: #439446 !important;
  color: #224a23 !important;
}

.panel-success .p-panel-header {
  background: #c8e6c9 !important;
  color: #224a23 !important;
}

.panel-danger .p-panel-header {
  background: #f8d7da !important;
  color: #721c24 !important;
}
.required-star {
  color: red;
}
.cropper {
  height: 550px;
  width: 550px;
  background: #ddd;
}
.videoplayer {
  width: 500px;
}

.videoplayer-controls {
  display: flex;
  font: 0.8em sans-serif;
  width: 100%;
}

.videoplayer-controls-toggleplay,
.videoplayer-controls-togglemute {
  flex: 1;
}

.videoplayer-controls-time {
  flex: 2;
  text-align: center;
  line-height: 2;
}

.videoplayer-controls-track {
  flex: 5;
  line-height: 2;
}
</style>