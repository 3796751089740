<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <div v-if="currentBusiness != null && currentBusiness.BusActive == true && currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode == 'PREMIUM'">
      <DataTable
        :value="customers"
        class="p-datatable-responsive-demo"
        :paginator="true"
        :rows="10"
        ref="dt"
        :resizableColumns="true"
        dataKey="CustId"
        :filters="filters"
        :loading="loading"
      >
        <template #empty> {{$t("message.customer")}} </template>
        <template #loading>{{$t("message.loading")}} </template>
        <template #header>
          <div style="text-align: left">
            <Button
              icon="pi pi-external-link"
              label="Export"
              @click="exportCSV($event)"
            />
          </div>
        </template>
        <Column field="Cust.CustLastName" :header="$t('message.last')" :sortable="true">
          <template #body="slotProps">
            <span v-if="slotProps.data.Cust.CustLastName != null">{{
              slotProps.data.Cust.CustLastName
            }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustLastName']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column field="Cust.CustFirstName" :header="$t('message.first')" :sortable="true">
          <template #body="slotProps">
            <span
              class="pointer"
              @click.stop="
                showCustomerDetails(slotProps.data.Cust.CustFirstName)
              "
              >{{ slotProps.data.Cust.CustFirstName }}</span
            >
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustFirstName']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
           <Column field="Cust.CustAddress" :header="$t('profile.address')" :sortable="true">
          <template #body="slotProps">
            <span v-if="slotProps.data.Cust != null">{{
              slotProps.data.Cust.CustAddress
            }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustAddress']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column field="Cust.CustCity" :header="$t('profile.city')" :sortable="true">
          <template #body="slotProps">
            <span v-if="slotProps.data.Cust != null">{{
              slotProps.data.Cust.CustCity
            }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustCity']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column field="Cust.CustState" :header="$t('profile.state')" :sortable="true">
          <template #body="slotProps">
            <span v-if="slotProps.data.Cust != null">{{
              slotProps.data.Cust.CustState
            }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustState']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column field="Cust.CustPhone" :header="$t('message.phone')" :sortable="true">
          <template #body="slotProps">
            <span v-if="slotProps.data.Cust != null">
               <a :href="'tel: ' + slotProps.data.Cust.CustPhone">{{
                 slotProps.data.Cust.CustPhone
                }}</a>
              <!-- {{
              slotProps.data.Cust.CustPhone
            }} -->
            </span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustPhone']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column field="Cust.CustEmail" :header="$t('message.email')" :sortable="true">
          <template #body="slotProps">
            <span v-if="slotProps.data.Cust != null">
               <a :href="'mailto:' +  slotProps.data.Cust.CustEmail">{{
                 slotProps.data.Cust.CustEmail
                }}</a>
              <!-- {{
              slotProps.data.Cust.CustEmail
            }} -->
            </span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.CustEmail']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
           <Column field="BusCustQuoteCount" :header="$t('businessprofile.quotes')" :sortable="true">
          <template #body="slotProps">
            <span v-if="slotProps.data.BusCustQuoteCount != null">{{
              slotProps.data.BusCustQuoteCount
            }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.BusCustQuote']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
                 <Column field="BusCustReservCount" :header="$t('businessprofile.reservations')" :sortable="true">
          <template #body="slotProps">
            <span v-if="slotProps.data.BusCustReservCount != null">{{
              slotProps.data.BusCustReservCount
            }}</span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['Cust.BusCustReservCount']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
      </DataTable>
      <Dialog
        v-model:visible="showCustDialog"
        :style="{ width: '90vw' }"
        :modal="true"
        :dismissableMask="true"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        {{ selectedCust.CustId }}
      </Dialog>
    </div>
    <div v-else-if="currentBusiness.BusActive == false">
        <inactive-subscription :busId="currentBusiness.BusId"></inactive-subscription>
    </div>
    <div v-else-if="currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode != 'PREMIUM'">
     <!-- <div v-else-if="true"> -->
      <upgrade-subscription
        :subId="currentSubscription.SubId"
      ></upgrade-subscription>
    </div>
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{$t("message.error")}}</Message
      >
    </div>
  </div>
</template>

<script>
import BusinessCustomer from "../store/Models/BusinessCustomer";
import Business from "../store/Models/Business";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import InactiveSubscription from '../components/InactiveSubscription.vue';
import UpgradeSubscription from "../components/UpgradeSubscription.vue";
import { mapState } from "vuex";

import Subscription from '../store/Models/Subscription';

export default {
  name: "BusinessClients",
  components: { LoadingOverlay, InactiveSubscription,UpgradeSubscription },
  data() {
    return {
      currentBusiness: {},
      showCustDialog: false,
      selectedCust: {},
      customers: [],
      filters: {},
      loading: false,
      currentSubscription: {},
      statuses: ["Processed", "Pending"],
    };
  },
  async mounted() {
    this.$log.debug("business-customers-mounted");
    this.$log.debug(this.currentBusinessId);
    if (this.currentBusinessId > 0) {
      await this.setBusinessCustomers();
    }
  },
  methods: {
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showCustDetails(cust) {
      this.selectedCust = cust;
      this.showCustDialog = true;
    },
    async setBusinessCustomers() {
      this.loading = true;
      Business.fetchBusinessProfileById(this.currentBusinessId).then((result) => {
        this.currentBusiness = result;
      });
      Subscription.fetchCurrentByBusiness(this.currentBusinessId).then((result) => {
          this.currentSubscription = result;
         //console.log(this.currentSubscription)
        });
      await BusinessCustomer.fetchByBusId(this.currentBusinessId).then(
        (result) => {
          this.$log.debug(result);
          this.customers = result;
        }
      );
      this.loading = false;
    },
  },
  watch: {
    async currentBusinessId() {
      this.setBusinessCustomers();
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
    }),
  },
};
</script>
<style scoped>
.p-column-filter {
  width: 100%;
  z-index: 1000;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 60em) {
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-thead
    > tr
    > th,
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}
</style>