export default {
    install: (app) => {
        app.config.globalProperties.$GetEventType = async () => {
       
        }
        app.config.globalProperties.$GetServiceType = async () => {
            
        }
    }
}
