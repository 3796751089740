import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Service from "./Service"

export default class QuotedService extends Model {
  static entity = 'quotedServices'
  
  static primaryKey = 'QuoteServId'
  
  static fields () {
    return {
      QuoteServId: this.uid(),
      QuoteId: this.attr(null),
      ServId: this.attr(null),
      ServPrice: this.attr(null),
      QuoteServQty:this.attr(null),
      QuoteServTotal:this.attr(null),
      QuoteServOrigId:this.attr(null),
      QuoteServEdited:this.attr(null),
      QuoteServEditedBy:this.attr(null),
      QuoteServRemoved:this.attr(null),

      Serv: this.belongsTo(Service, 'ServId')
    }
  }
  static async update(serv) {
    //console.log("update quoted service")
     const result =  await QuotedService.api().put('/api/quotedservices/update/' + serv.QuoteServId,
     serv).then((result) => {
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }

  static async remove(serv) {
    //console.log("remove quoted service")
     const result =  await QuotedService.api().put('/api/quotedservices/remove/' + serv.QuoteServId,
     serv).then((result) => {
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }


}

