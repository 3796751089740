import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Service from "./Service"

export default class OrderedService extends Model {
  static entity = 'orderedServices'
  
  static primaryKey = 'OrderServId'
  
  static fields () {
    return {
      OrderServId: this.uid(),
      OrderId: this.attr(null),
      ServId: this.attr(null),
      ServPrice: this.attr(null),
      OrderServTotal:this.attr(null),
      OrderServQty:this.attr(null),
      OrderServOrigId:this.attr(null),
      OrderServEdited:this.attr(null),
      OrderServEditedBy:this.attr(null),
      OrderServRemoved:this.attr(null),

      Serv: this.belongsTo(Service, 'ServId')
      
      
    }
  }
  static async update(serv) {
    //console.log("update quoted service")
     const result =  await QuotedService.api().put('/api/quotedservices/update/' + serv.QuoteServId,
     serv).then((result) => {
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }

  static async remove(serv) {
    //console.log("remove quoted service")
     const result =  await QuotedService.api().put('/api/quotedservices/remove/' + serv.QuoteServId,
     serv).then((result) => {
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }


}

