<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content mt-2">
    <business-details
      v-if="busFound"
      :currentBusiness="currentBusiness"
      :busFav="
        currentCustomer != null &&
        currentCustomer.BusinessFavorites != null &&
        currentCustomer.BusinessFavorites.filter(
          (fav) => fav.BusId == currentBusiness.BusId
        ).length > 0
      "
    ></business-details>
    <div
      v-else
      class="
        text-center
        flex
        align-content-center
        justify-content-center
        align-items-center
      "
      style="height: 30vh"
    >
      <h1>{{ $t("code.busnotfound") }}</h1>
    </div>
  </div>
</template>

<script>
 /* eslint-disable */ 
import BusinessDetails from "../components/BusinessDetails.vue";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import Business from "../store/Models/Business";
import Customer from "../store/Models/Customer";
import { mapState } from "vuex";

export default {
  name: "BusinessPage",
  components: { BusinessDetails, LoadingOverlay },
  props: ["busHandle"],
  data() {
    return {
      currentBusiness: {},
      currentCustomer: {},
      loading: true,
      busFound: false,
    };
  },
  async mounted() {
    this.$log.debug("business-page-mounted");
    this.$log.debug(this.$route.params.busHandle);
    await Business.fetchByHandle(this.$route.params.busHandle).then(
      (result) => {
        this.$log.debug(result);
       //console.log(result);
        if (result != null) {
          this.currentBusiness = result;
          document.title = this.currentBusiness.BusName;
          this.busFound = true;
          this.loading = false;
        } else {
          this.busFound = false;
          this.loading = false;
        }
        
      }
    );

    this.$log.debug(this.currentBusiness);

    if (this.currentCustomerId > 0) {
      this.setCurrentCustomer();
    }
  },
  methods: {
    async setCurrentCustomer() {
      await Customer.getCurrentCustomer().then((result) => {
        this.$log.debug(result);
        this.currentCustomer = result;
      });
    },
  },
  watch: {},
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
      userLoggedIn: (state) => state.userLoggedIn,
    }),
  },
};
</script>
<style >
.p-fileupload-choose ~ .p-button {
  display: none;
}
.modifyChip.imageChip {
  border-radius: 0vw;
  font-size: medium;
}
.modifyChip.imageChip img {
  height: 3rem;
  width: auto;
}
label {
  font-weight: bold;
}
.p-fileupload .p-fileupload-row {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1px !important;
  margin: 10px;
  width: auto;
  text-align: left;
}

/* .p-message-close.p-link {
      margin-left: auto !important;
      overflow: hidden;
      position: relative;
  } */
/* button.p-button {
	margin-right: .5rem;
}

.p-inputtext {
	margin-right: .25rem;
} */
</style>
