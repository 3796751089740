import { Model } from '@vuex-orm/core'


export default class AttendeeCount extends Model {
  static entity = 'attendeeCounts'
  
  static primaryKey = 'AttendeeCountId'
  
  static fields () {
    return {
      AttendeeCountId: this.uid(),
      BusId: this.attr(null),
      AttendeeCountLabel: this.attr(null)
    }
  }
}

