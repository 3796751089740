<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content mt-3">
    <Panel>
      <template #header> </template>

      <DataView :value="subscriptions" layout="list">
        <template #empty>
          {{ $t("message.notlisted") }}
        </template>
        <template #list="slotProps">
          <div class="col-12 md:col-6 lg:col-4">
            <Card
              class="mb-2 mr-1 ml-1"
              @click.stop="openSubDetails(slotProps.data)"
            >
              <template v-slot:content>
                <div class="grid">
                  <div class="col-10 align-self-center">
                    <div class="box">
                      <div
                        class="flex flex-wrap justify-content-between"
                        style="text-align: left"
                      >
                        <span
                          >#{{ slotProps.data.SubNum }}<br />
                          <span style="font-size: x-large; text-align: left"
                            ><b>
                              {{ slotProps.data.Bus.BusName }}
                            </b></span
                          ><span> </span><br />
                          <em
                            >{{ $t("subscriptions.enrolled") }}
                            {{
                              $formatDateTimeToDate(slotProps.data.SubStartDate)
                            }}</em
                          ><br />
                          <Tag
                            class="mt-2 mb-2 mr-2"
                            :severity="
                              getStatusTag(
                                slotProps.data.SubActive,
                                slotProps.data.SubCancelationScheduled
                              )
                            "
                            style="font-size: small"
                          >
                            {{
                              slotProps.data.SubActive == true &&
                              (slotProps.data.SubCancelationScheduled == null ||
                                slotProps.data.SubCancelationScheduled == false)
                                ? $t("services.active")
                                : slotProps.data.SubActive == true &&
                                  slotProps.data.SubCancelationScheduled == true
                                ? $t("services.cancelscheduled")
                                : $t("services.inactive")
                            }}
                          </Tag>
                          <Tag
                            v-if="
                              slotProps.data.SubInTrial &&
                              getDaysUntil(slotProps.data.SubTrialEnds) > 0
                            "
                            class="mt-2 mb-2"
                            severity="Info"
                            style="font-size: small"
                          >
                            {{ $t("subscriptions.trial") }}
                          </Tag>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-2 align-self-center"
                    style="text-align: right"
                  >
                    <Button
                      icon="pi pi-angle-right"
                      class="
                        p-button-rounded
                        p-button-text
                        p-button-lg
                        p-button-primary
                      "
                      @click.stop="openSubDetails(slotProps.data)"
                    />
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </template>
      </DataView>

      <Sidebar
        v-model:visible="showSubDetails"
        :baseZIndex="900"
        position="full"
        style="overflow: scroll"
      >
        <div class="grid" v-if="selectedSub">
          <div class="col-12">
            <div
              class="
                flex flex-wrap
                justify-content-center
                align-items-center
                text-center
              "
            >
              <img
                :src="imageHostingURL + selectedSub.Bus.BusLogo"
                :alt="selectedSub.Bus.BusName"
                width="75"
                class="mr-3"
              />
              <div>
                <span style="font-size: xx-large">
                  {{ selectedSub.Bus.BusName }}
                </span>
              </div>
            </div>
            <div
              class="col-12 mb-2"
              style="background-color: #e9ecef; text-align: center"
            >
              <span style="font-size: x-large">#{{ selectedSub.SubNum }}</span>
            </div>
            <div v-if="selectedSub.SubStatus != 'active'">
              <inactive-subscription
                :busId="selectedSub.Bus.BusId"
              ></inactive-subscription>
            </div>
            <div v-else>
              <TabView>
                <TabPanel>
                  <template #header>
                    <span> {{ $t("calendar.detail") }}</span>
                  </template>
                  <div
                    class="
                      flex flex-wrap
                      justify-content-around
                      align-items-stretch
                    "
                  >
                    <Card style="width: 25rem; margin-bottom: 2em">
                      <template #title>{{
                        $t("subscriptions.yourplan")
                      }}</template>
                      <template #content>
                        <div
                          class="
                            flex flex-wrap
                            justify-content-between
                            align-items-end
                            text-center
                          "
                        >
                          <div style="text-align: left">
                            <Tag
                              class="mt-2 mb-2"
                              :severity="
                                getStatusTag(
                                  selectedSub.SubActive,
                                  selectedSub.SubCancelationScheduled
                                )
                              "
                              style="font-size: small"
                            >
                              {{
                                selectedSub.SubActive == true &&
                                (selectedSub.SubCancelationScheduled == null ||
                                  selectedSub.SubCancelationScheduled == false)
                                  ? $t("services.active")
                                  : selectedSub.SubActive == true &&
                                    selectedSub.SubCancelationScheduled == true
                                  ? $t("services.cancelscheduled")
                                  : $t("services.inactive")
                              }} </Tag
                            ><br />
                            {{ $t("subscriptions.enrolled") }}
                            <b>{{
                              formatDateTimeToDate(selectedSub.SubStartDate)
                            }}</b
                            ><br />
                            <span v-if="selectedSub.SubEndDate != null">
                              {{ $t("subscriptions.subscriptionended") }}

                              <b>{{
                                formatDateTimeToDate(selectedSub.SubEndDate)
                              }}</b>
                            </span>
                            <div></div>
                          </div>

                          <div>
                            <span style="font-size: 300%"
                              ><strong>
                                ${{ selectedSub.SubPrice }}
                              </strong></span
                            ><small
                              >+tax <br />
                              {{ $t("subscriptions.monthly") }}
                            </small>
                          </div>
                        </div>
                        <Button
                          v-if="
                            selectedSub.SubActive == true &&
                            selectedSub.SubCancelationScheduled == true
                          "
                          icon="pi pi-refresh"
                          :label="$t('subscriptions.restoresubs')"
                          class="mt-3 p-button-raised p-button-primary"
                          @click.stop="restoreSubs()"
                        />
                      </template>
                    </Card>
                    <Card
                      v-if="
                        selectedSub.SubInTrial &&
                        getDaysUntil(selectedSub.SubTrialEnds) > 0
                      "
                      style="width: 25rem; margin-bottom: 2em"
                    >
                      <template #title>{{
                        $t("subscriptions.trial")
                      }}</template>
                      <template #content>
                        <div style="text-align: center">
                          <span style="font-size: 300%"
                            ><strong>
                              {{ getDaysUntil(selectedSub.SubTrialEnds) }}
                            </strong></span
                          ><br />
                          <b> {{ $t("subscriptions.daysleft") }}</b>
                        </div>
                        <div v-if="selectedSub.SubCancelationScheduled == false && selectedSub.SubActive == true">
                          <small>
                            {{ $t("subscriptions.trialexpires") }}
                            <b>{{
                              formatDateTimeToDate(selectedSub.SubTrialEnds)
                            }}</b
                            >.</small
                          >
                        </div>
                        <div v-else>
                          <Tag
                              class="mt-2 mb-2"
                              :severity="
                                getStatusTag(
                                  selectedSub.SubActive,
                                  selectedSub.SubCancelationScheduled
                                )
                              "
                              style="font-size: small"
                            >
                              {{
                                selectedSub.SubActive == true &&
                                (selectedSub.SubCancelationScheduled == null ||
                                  selectedSub.SubCancelationScheduled == false)
                                  ? $t("services.active")
                                  : selectedSub.SubActive == true &&
                                    selectedSub.SubCancelationScheduled == true
                                  ? $t("services.cancelscheduled")
                                  : $t("services.inactive")
                              }} </Tag
                            >
                        </div>
                      </template>
                    </Card>
                    <Card
                      v-if="
                        selectedSub.SubActive &&
                        !selectedSub.SubCancelationScheduled
                      "
                      style="width: 25rem; margin-bottom: 2em"
                    >
                      <template #title>{{
                        $t("subscriptions.nextpayment")
                      }}</template>
                      <template #content>
                        <div>
                          {{ $t("subscriptions.scheduledfor") }}
                          <b>
                            {{
                              formatDateTimeToDate(selectedSub.SubCurrentUntil)
                            }}</b
                          >
                        </div>
                        <div>
                          {{ $t("subscriptions.withcard") }}
                          <span v-if="selectedSub.Card != null"
                            ><b> {{ selectedSub.Card.CardNumber }}</b>
                            {{ $t("subscriptions.expireson") }}
                            <b
                              >{{ selectedSub.Card.CardExpMonth }}/{{
                                selectedSub.Card.CardExpYear
                              }}
                            </b></span
                          >
                        </div>
                        <div>
                          <Button
                            :label="$t('subscriptions.updatecard')"
                            class="mt-3 p-button-raised p-button-primary"
                            @click.stop="updateSubCard()"
                          />
                        </div>
                      </template>
                    </Card>
                    <Card
                      style="width: 25rem; margin-bottom: 2em"
                      class="text-center"
                    >
                      <template #title>
                        <span
                          v-if="
                            selectedSub.SubActive &&
                            !selectedSub.SubCancelationScheduled
                          "
                        >
                          {{ $t("subscriptions.nothappy") }}
                        </span>
                        <span
                          v-else-if="
                            selectedSub.SubActive &&
                            selectedSub.SubCancelationScheduled
                          "
                          >{{ $t("subscriptions.subrestore") }}</span
                        >
                      </template>
                      <template #content>
                        <Button
                          icon="pi pi-phone"
                          iconPos="top"
                          class="p-button-text p-button-help"
                          :label="$t('subscriptions.servicecall')"
                          @click.stop="
                            selectedFeedback = 1;
                            showSupportTicket = true;
                          "
                        /><br />
                        <Button
                          icon="pi pi-palette"
                          iconPos="top"
                          class="p-button-text p-button-success"
                          :label="$t('subscriptions.functionalityrequest')"
                          @click.stop="
                            selectedFeedback = 2;
                            showSupportTicket = true;
                          "
                        /><br />
                        <Button
                          icon="pi pi-briefcase"
                          iconPos="top"
                          class="p-button-text p-button-info"
                          :label="$t('subscriptions.requesttraining')"
                          @click.stop="
                            selectedFeedback = 3;
                            showSupportTicket = true;
                          "
                        /><br />
                        <Button
                          v-if="!selectedSub.SubCancelationScheduled"
                          icon="pi pi-times"
                          iconPos="top"
                          class="p-button-text p-button-plain"
                          :label="$t('subscriptions.cancelsubs')"
                          @click.stop="cancelSub()"
                        />
                        <Button
                          v-else
                          icon="pi pi-refresh"
                          iconPos="top"
                          class="p-button-text p-button-success"
                          :label="$t('subscriptions.restoresubs')"
                          @click.stop="restoreSubs()"
                        />
                      </template>
                    </Card>
                  </div>
                </TabPanel>
                <TabPanel>
                  <template #header>
                    <span>{{ $t("code.subpayments") }}</span>
                  </template>
                  <div class="grid text-center">
                    <div class="col-3" style="background-color: #f8f9fa">
                      <b>{{ $t("calendar.date") }}</b>
                    </div>
                    <div class="col-3" style="background-color: #f8f9fa">
                      <b>{{ $t("custreserv.custamount") }}</b>
                    </div>
                    <div class="col-3" style="background-color: #f8f9fa">
                      <b>{{ $t("calendar.status") }}</b>
                    </div>
                    <div class="col-3" style="background-color: #f8f9fa">
                      <b>{{ $t("payments.invoice") }}</b>
                    </div>

                    <template
                      v-for="payment in selectedSub.SubPayments"
                      :key="payment.SubPayId"
                    >
                      <div class="col-3">
                        <span style="vertical-align: -webkit-baseline-middle"
                          >{{ $formatDateTimeToDate(payment.PayDate) }}
                        </span>
                      </div>

                      <div
                        class="
                          col-3
                          flex flex-wrap
                          justify-content-center
                          align-items-center
                        "
                      >
                        <span style="vertical-align: -webkit-baseline-middle">
                          ${{ payment.PayAmount }}
                        </span>
                      </div>
                      <div
                        class="
                          col-3
                          flex flex-wrap
                          justify-content-center
                          align-items-center
                        "
                      >
                        <span style="vertical-align: -webkit-baseline-middle">
                          <Tag v-if="payment.PayStatus == 'paid'"
                            severity="success" 
                            >{{ $t("calendar.paid") }}</Tag
                          >
                          <Tag v-if="payment.PayStatus == 'failed'"
                            severity="danger" 
                            >{{ $t("calendar.failed") }}</Tag
                          >
                        </span>
                      </div>
                      <div
                        class="
                          col-3
                          flex flex-wrap
                          justify-content-center
                          align-items-center
                        "
                      >
                        <span style="vertical-align: -webkit-baseline-middle">
                          <a
                            v-if="
                              payment.PayInvoiceUrl != null &&
                              payment.PayInvoiceUrl.length > 0
                            "
                            :href="payment.PayInvoiceUrl"
                            target="_blank"
                          >
                            <i class="pi pi-file"></i>
                          </a>
                        </span>
                      </div>
                    </template>
                  </div>
                </TabPanel>
                <!-- <TabPanel>
                <template #header>
                  <span> {{ $t("services.owner") }}</span>
                </template>

               <span
                  ><b> {{ $t("custreserv.custname") }} </b>
                  {{ selectedSub.Bus.BusOwnerCust.CustFirstName }}
                  {{ selectedSub.Bus.BusOwnerCust.CustLastName }}</span
                ><br />
                <span
                  ><b>{{ $t("listmybusiness.email") }}</b>
                  {{ selectedSub.Bus.BusOwnerCust.CustEmail }} </span
                ><br /> 
              </TabPanel>-->
              </TabView>
            </div>
          </div>
        </div>
      </Sidebar>
    </Panel>
    <Dialog
      v-model:visible="showCardOptions"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
      :closeOnEscape="true"
      :dismissableMask="true"
      style="z-index: 1030"
      :header="$t('subscriptions.updatecard')"
    >
      <div
        v-if="customerCards != null && customerCards.length > 0"
        class="field col-12"
      >
        <label>{{ $t("custreserv.custcard") }} </label>
        <div v-if="showSavedCards" class="field-radiobutton">
          <RadioButton
            id="cardOption1"
            name="cardOption"
            value="savedCard"
            v-model="cardOption"
          />&nbsp;
          <Dropdown
            id="paymentCards"
            class="mt-2 mr-2"
            v-model="selectedCard"
            :options="customerCards"
            :placeholder="$t('custreserv.custsecard')"
            @change="cardOption = 'savedCard'"
          >
            <template #value="slotProps">
              <div class="" v-if="slotProps.value">
                <div>
                  x{{ slotProps.value.CardNumber }},
                  <small>
                    Exp. {{ slotProps.value.CardExpMonth }}/{{
                      slotProps.value.CardExpYear
                    }}</small
                  >
                </div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div>
                x{{ slotProps.option.CardNumber }},
                <small>
                  Exp. {{ slotProps.option.CardExpMonth }}/{{
                    slotProps.option.CardExpYear
                  }}</small
                >
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <div class="field-radiobutton col-12">
        <RadioButton
          id="cardOption2"
          name="cardOption"
          value="newCard"
          v-model="cardOption"
        />
        <span>&nbsp;{{ $t("custreserv.newcard") }}&nbsp;</span>
      </div>
      <div v-if="cardOption == 'newCard'" class="text-left">
        <stripe-card ref="stripeCard"></stripe-card>
      </div>
      <div class="flex justify-content-between">
        <Button
          v-if="!updatingCard"
          icon="pi pi-times"
          :label="$t('profile.cancel')"
          class="p-button-secondary p-button-sm m-1"
          @click.stop="resetCardOptions()"
        />

        <Button
          v-if="!updatingCard"
          icon="pi pi-check"
          :label="$t('menu.submit')"
          class="p-button-success p-button-raised p-button-sm m-1"
          @click.stop="selectCard()"
        />

        <Button
          v-else
          icon="pi pi-spin pi-spinner"
          disabled
          :label="$t('menu.submit')"
          class="p-button-primary p-button-outlined p-button-sm mt-2"
        />
      </div>
    </Dialog>

    <Dialog
      v-model:visible="showSupportTicket"
      :modal="true"
      :contentStyle="{ overflow: 'scroll' }"
      :header="ticketHeader"
      :dismissableMask="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <div style="text-align: left">
        <label>
          {{ $t("subscriptions.providedetails") }}
        </label>

        <Textarea
          class="mt-2"
          v-model="ticketMessage"
          :placeholder="$t('message.type')"
          :autoResize="true"
          rows="5"
          cols="30"
          style="width: 100%"
        />
      </div>
      <div class="flex justify-content-between">
        <Button
          :label="$t('profile.cancel')"
          icon="pi pi-times"
          iconPos="left"
          @click.stop="
            showSupportTicket = false;
            newTicketText = '';
          "
          class="mr-2 p-button-danger p-button-sm p-button-raised"
        />
        <Button
          v-if="!submitting"
          :label="$t('menu.send')"
          icon="pi pi-play"
          iconPos="left"
          @click.stop="validateFeedback"
          class="mr-2 p-button-success p-button-sm p-button-raised"
        />
        <Button
          v-else
          :label="$t('menu.send')"
          icon="pi pi-spin pi-spinner"
          iconPos="left"
          disabled
          class="mr-2 p-button-success p-button-sm p-button-raised"
        />
      </div>
    </Dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

import Subscription from "../store/Models/Subscription";
import Card from "../store/Models/Card";
import InactiveSubscription from "../components/InactiveSubscription.vue";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import StripeCard from "../components/StripeCard.vue";
import SupportTicket from "../store/Models/SupportTicket";

import moment from "moment";

export default {
  name: "BusinessSubscriptions",
  components: { InactiveSubscription, StripeCard, LoadingOverlay },
  data() {
    return {
      subscriptions: [],
      selectedSub: null,
      loading: false,
      filters: {},
      showSubDetails: false,
      showCardOptions: false,
      customerCards: [],
      showSavedCards: true,
      cardOption: null,
      updatingCard: false,
      errors: [],
      newCardId: 0,
      selectedCard: null,
      showSupportTicket: false,
      newTicketText: "",
      ticketHeader: "",
      ticketMessage: "",
      feedback: {},
      selectedFeedback: 0,
      ticketCat: "",
      submitting: false,
    };
  },
  async mounted() {
    this.$log.debug("business subscription mounted");
    this.$log.debug(this.currentCustomerId);

    if (this.currentCustomerId != 0) this.setCurrentCustomer();
  },
  methods: {
    validateFeedback() {
      this.$log.debug("validate customer ticket");
      this.submitting = true;
      let errors = 0;
      //verify required values

      this.feedback.TicketMessage = this.ticketMessage;

      if (errors == 0) {
        this.feedback.CustId = this.currentCustomerId;
        this.feedback.BusId = this.selectedSub.BusId;
        this.feedback.TicketCat = this.ticketCat;
        this.$log.debug(this.feedback);
        this.sendNewTicket();
      } else {
        this.submitting = false;
      }
    },
    async sendNewTicket() {
      this.$log.debug("submit ticket");
      this.$log.debug(this.feedback);
      await SupportTicket.createNewTicket(this.feedback, this.lang).then(
        async (response) => {
          this.$log.debug(response);
          if (response) {
            this.resetFeedback();
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("subscriptions.requestsuccess"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error!",
              detail: this.$t("subscriptions.requestfailed"),
              life: 3000,
            });
          }
          this.submitting = false;
        }
      );
    },
    resetFeedback() {
      this.feedback = {};
      this.showSupportTicket = false;
      this.ticketCat = "";
      this.ticketMessage = "";
      this.ticketHeader = "";
    },
    resetCardOptions() {
      this.cardOption = null;
      this.showCardOptions = false;
      this.updatingCard = false;
    },
    validateCardOptions() {
      this.$log.debug("validating card options");
      this.updatingCard = true;
      //clear out errors
      this.errors = [];

      if (this.cardOption == null) {
        this.errors.push(this.$t("custreserv.cardoption"));
      }

      if (this.cardOption == "savedCard") {
        if (this.selectedCard == null) {
          this.errors.push(this.$t("custreserv.nocardselected"));
          document.getElementById("paymentCards").classList.add("p-invalid");
        }
      }

      this.$log.debug(this.errors);
      if (this.errors.length == 0) {
        this.submitCardOptions();
      } else {
        this.updatingCard = false;
      }
    },
    async selectCard() {
      this.errors = [];
      if (this.cardOption == "newCard") {
        await this.$refs.stripeCard.createCard().then((response) => {
          this.$log.debug("New Card");
          this.$log.debug(response);
          if (response != null) {
            this.newCardId = response;
          }
          this.validateCardOptions();
        });
      } else {
        this.newCardId = this.selectedCard.CardId;
        this.validateCardOptions();
      }
    },

    async submitCardOptions() {
      Subscription.updateSubCard(this.newCardId, this.selectedSub.SubId).then(
        async (response) => {
          this.$log.debug(response);
          this.setCurrentCustomer();
          this.updatingCard = false;

          if (response == 2) {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("subscriptions.updatecardfailed"),
              life: 3000,
            });
          } else if (response == 1) {
            this.showCardOptions = false;
            this.resetCardOptions();
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("subscriptions.updatecardsuccess"),
              life: 3000,
            });
          }
        }
      );
    },
    async updateSubCard() {
      await Card.fetchByCustId(this.currentCustomerId).then((result) => {
        this.$log.debug(result);
        this.customerCards = result;
        if (!(this.customerCards != null && this.customerCards.length > 0)) {
          this.showSavedCards = false;
          this.cardOption = "newCard";
        }
        this.showCardOptions = true;
      });
    },

    async cancelSub() {
      this.$log.debug(this.selectedSub);
      this.showSubDetails = false;
      this.loading = true;
      this.$confirm.require({
        message: this.$t("subscriptions.cancelsubmsg"),
        header: this.$t("subscriptions.cancelsubheader"),
        icon: "pi pi-exclamation-triangle",
        acceptLabel: this.$t("code.continue"),
        rejectLabel: this.$t("code.cancel"),
        accept: async () => {
          this.loading = true;
          await Subscription.cancelSubscription(this.selectedSub.SubId).then(
            (result) => {
              this.$log.debug(result);
              this.setCurrentCustomer();
              this.showSubDetails = true;
              this.loading = false;
            }
          ); //todo ensure wait
        },
        reject: () => {
          this.$confirm.close();
          this.showSubDetails = true;
          this.loading = false;
        },
      });
    },
    async restoreSubs() {
      this.$log.debug(this.selectedSub);
      this.showSubDetails = false;
      this.loading = true;
      this.$confirm.require({
        message: this.$t("subscriptions.restoresubmsg"),
        header: this.$t("subscriptions.restoresubheader"),
        icon: "pi pi-exclamation-triangle",
        acceptLabel: this.$t("code.continue"),
        rejectLabel: this.$t("code.cancel"),
        accept: async () => {
          this.loading = true;
          await Subscription.restoreSubscription(this.selectedSub.SubId).then(
            (result) => {
              this.$log.debug(result);
              this.setCurrentCustomer();

              this.showSubDetails = true;
              this.loading = false;
            }
          ); //todo ensure wait
        },
        reject: () => {
          this.$confirm.close();
          this.showSubDetails = true;
          this.loading = false;
        },
      });
    },
    formatDateTimeToDate(datetime) {
      return moment(datetime).format("MM/DD/YY");
    },
    getDaysUntil(date) {
      return moment(date).diff(moment(), "days");
    },
    getStatusTag(status, cancelScheduled) {
      if (status && !cancelScheduled) return "success";
      else if (status && cancelScheduled) return "warning";
      else if (!status) return "danger";
      else return "info";
    },
    openSubDetails(data) {
      this.$log.debug("opening sub");
      this.$log.debug(data);
      this.selectedSub = data;
      this.showSubDetails = true;
    },
    async setCurrentCustomer() {
      this.loading = true;
      Subscription.fetchByOwnerId(this.currentCustomerId).then((result) => {
        this.subscriptions = result;

        this.$log.debug(this.subscriptions);
        this.$log.debug(this.selectedSub);
        if (this.selectedSub != null) {
          this.selectedSub = this.subscriptions.find(
            (sub) => sub.SubId == this.selectedSub.SubId
          );
        }
        this.$log.debug(this.selectedSub);
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
      currentBusinessId: (state) => state.currentBusinessId,
      lang: (state) => state.lang,
    }),
    ...mapGetters(["getCurrentADUser"]),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
  watch: {
    selectedFeedback() {
      if (this.selectedFeedback == 1) {
        this.ticketHeader = this.$t("subscriptions.requestingcall");
        this.ticketCat = "Support Call Request";
      } else if (this.selectedFeedback == 2) {
        this.ticketHeader = this.$t("subscriptions.requestingfunctionality");
        this.ticketCat = "Functionality Request";
      } else if (this.selectedFeedback == 3) {
        this.ticketHeader = this.$t("subscriptions.requestingtraining");
        this.ticketCat = "Training Request";
      } else {
        this.ticketHeader = "";
        this.ticketCat = "";
      }
    },
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCurrentCustomer();
    },
    async currentBusinessId() {
      this.setCurrentCustomer();
    },
  },
};
</script>
