import { Model } from '@vuex-orm/core'
 import ActiveLocation from './ActiveLocation'


export default class BusinessServiceLocation extends Model {
  static entity = 'businessservicelocations'
  
  static primaryKey = 'BusServLocId'
  
  static fields () {
    return {
      BusServLocId: this.uid(),
      LocationId: this.attr(null),
      BusId: this.attr(null),
       Location: this.belongsTo(ActiveLocation, 'LocationId')
    }
  }
  
}

