<template>
    <div
      v-if="subsInProgress.filter((s) => s == busId) == 0"
   
    >
    <div class="flex  align-content-center justify-content-center flex-column " style="height:100%">
  <div class="flex align-items-center justify-content-center" ><h1>{{ $t("payments.subscription") }}.</h1></div>

      <div class="flex flex-row flex-wrap justify-content-center pb-5">
<Card class="m-3 flex-grow-1 service-card" >
    <template #content >
      <div >
        <div style="color:var(--mr-primary-color);font-size: 2rem; font-weight:bold">FREE</div>
        <div class=mt-5>
        <span style="font-size:3em; font-weight: bold; ">
          ${{ currentFreeDef.SubDefPriceMo }}
        </span>
        {{ $t("listmybusiness.amonth") }} 
        </div>
  
      <div class="m-2" style="text-align:start">
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>Business Listing </div>

      
      </div>
    </div>
  
 <div>
      <Button
            v-if="currentFreeDef != null"
            :label="$t('listmybusiness.getstarted') "
            class="p-button-raised p-button-lg p-button-primary mb-2 mt-5"
            @click.stop="completeSubscription('FREE')"
          />
          <div style="font-size: 1rem">{{ $t("listmybusiness.alwaysfree") }}</div>
 </div>
      <!-- </div> -->
    </template>
</Card>
<Card class="m-3 flex-grow-1 service-card">
  <template #content >
      <div >
        <div style="color:var(--mr-primary-color);font-size: 2rem; font-weight:bold">BASIC</div>
        <div class=mt-5>
        <span style="font-size:3em; font-weight: bold; ">
          ${{ currentBasicDef.SubDefPriceMo }}
        </span>
        {{ $t("listmybusiness.amonth") }} 
        </div>
  
  
      <div class=" m-2" style="text-align:start">
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.businesslisting") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.serviceportfolio") }} </div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check  mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.availabilitycalendar") }}</div>
      </div>
  
      </div>
      <div>
      <Button
            v-if="currentBasicDef != null"
            :label="$t('listmybusiness.tryforfree') "
            class="p-button-raised p-button-lg p-button-primary mb-2 mt-5"
            @click.stop="completeSubscription('BASIC')"
          />
          <div style="font-size: 1rem">{{$t('listmybusiness.freetrialdays') }}</div>
      </div>
    </template>
</Card>
<Card class="m-3 flex-grow-1 service-card">
  <template #content >
      <div >
        <div style="color:var(--mr-primary-color);font-size: 2rem; font-weight:bold">PREMIUM</div>
        <div class=mt-5>
        <span style="font-size:3em; font-weight: bold; ">
          ${{ currentPremiumDef.SubDefPriceMo }}
        </span>
        {{ $t("listmybusiness.amonth") }} 
        </div>
      </div>
  
      <div class="m-2" style="text-align:start">
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.businesslisting") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.serviceportfolio") }} </div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check  mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.availabilitycalendar") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.directquotes") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.reservationsandappointments") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.onlinestore") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.onlinepay") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.directmessages") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.customerreviews") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.clientandstaffmgmt") }}</div>
      
      </div>
  
 <div>
      <Button
            v-if="currentPremiumDef != null"
            :label="$t('listmybusiness.tryforfree') "
            class="p-button-raised p-button-lg p-button-primary mb-2 mt-5"
            @click.stop="completeSubscription('PREMIUM')"
          />
          <div style="font-size: 1rem">{{$t('listmybusiness.freetrialdays') }}</div>
 </div>
    </template>
</Card>
    </div>
    </div>
    </div>
    <span v-else>
      <div class="flex  align-content-center justify-content-center flex-column " style="height:100%">
  <div class="flex align-items-center justify-content-center" ><h1>{{ $t("payments.subswait") }}  <i class="pi pi-spin pi-spinner"></i></h1>
     
  </div>
      </div>
    </span>
</template>
<script>
import { onMounted } from "vue";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import Business from "../store/Models/Business";
import SubscriptionDefs from "../store/Models/SubscriptionDefs";

export default {
  name: "InvalidSubscription",
  components: {},
  props: {
    busId: Number,
  },
  data() {
    return {
      redirecturl: process.env.VUE_APP_MYRESERVS_URL,
      completing: false,
      currentSubDef: {},
      currentFreeDef:{},
      currentBasicDef:{},
      currentPremiumDef:{}
      
    };
  },
  setup() {
    let stripe = null;

    onMounted(async () => {
      /* global Stripe */
     // this.$log.debug("onMounted");
      stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    });

    function redirect(sessionId) {
      stripe
        .redirectToCheckout({
          sessionId: sessionId,
        })
        .then(function (result) {
          this.$log.debug(result);
        });
    }
    return { redirect };
  },
  async mounted() {
    this.currentSubDef = await SubscriptionDefs.fetchCurrentSubDef();
    this.currentFreeDef = await SubscriptionDefs.fetchCurrentFree();
    this.currentBasicDef = await SubscriptionDefs.fetchCurrentBasic();
    this.currentPremiumDef = await SubscriptionDefs.fetchCurrentPremium();
    this.$log.debug(this.currentSubDef);

    this.$log.debug(this.subsInProgress);
    if (this.subsInProgress.filter((s) => s == this.busId).length > 0) {
      setTimeout(() => {
        this.clearSubsInProgress();
        this.$router.go();
      }, 10000);
    }
  },
  methods: {
    ...mapActions(["addSubInProgress", "clearSubsInProgress"]),
    completeSubscription(level) {
      this.$log.debug("complete-subscription");
      this.completing = true;
      Business.completeSubscription(this.busId, level).then(async (response) => {
        this.$log.debug(response);

        if (
          response != null &&
          response.sessionId != 0 &&
          response.busId != 0
        ) {
          this.addSubInProgress(response.busId);
          this.redirect(response.sessionId);
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("listmybusiness.suberror"),
            life: 3000,
          });
        }
        this.completing = false;
      });
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
      subsInProgress: (state) => state.subscriptionsInProgress,
    }),
  },
};
</script>


