<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
  
    <div class="mt-3" v-if="currentBusiness != null && currentBusiness.BusActive == true && currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode == 'PREMIUM'">
      <div v-if="currentBusiness.BusAcceptMessages == true" class="flex">
        <Button
          icon="pi pi-pencil"
          style="float: right"
          :label="$t('message.new')"
          class="p-button-raised p-button-primary ml-auto"
          @click.stop="displayNewMessage()"
        />
      </div>
      <div v-else>
        <Message severity="warn" :closable="false">{{
          $t("message.disabled")
        }}</Message>
      </div>
      <div class="grid">
        <div class="col-12 lg:col-6">
          <ScrollPanel
            style="width: 95%; height: 75vh; vertical-align: middle"
            class="mt-2 custom-scroll"
          >
            <DataView
              :value="conversations"
              layout="list"
              class="mt-2 service-grid"
            >
              <template #empty>
                {{ $t("message.notsent") }}
              </template>
              <template #list="slotProps">
                <div class="col-12">
                  <Card
                    :class="{
                      unread:
                        slotProps.data.Messages.filter(
                          (msg) =>
                            msg.MessageRead == false &&
                            msg.MessageSentBy == 'cust'
                        ).length > 0,
                    }"
                    class="mb-2 mr-1 ml-1"
                    @click.stop="viewConvo(slotProps.data)"
                  >
                    <template v-slot:content>
                      <div
                        class="flex align-items-center justify-content-between"
                      >
                        <img
                          v-if="slotProps.data.Cust.CustProfImage != null"
                          :src="
                            imageHostingURL + slotProps.data.Cust.CustProfImage
                          "
                          :alt="slotProps.data.Cust.CustProfImage"
                          width="75"
                          class="mr-3"
                        />
                        <Avatar
                          v-else
                          icon="pi pi-user"
                          class="mr-2"
                          width="75"
                        />
                        <div style="text-align: right">
                          <b>
                            {{ slotProps.data.Cust.CustFirstName }}
                            {{ slotProps.data.Cust.CustLastName }}</b
                          >
                          <br />
                          <span v-if="slotProps.data.Messages.length > 0">
                            <small>
                              <em>{{
                                truncateMsg(
                                  slotProps.data.Messages[
                                    slotProps.data.Messages.length - 1
                                  ].MessageText
                                )
                              }}</em></small
                            >
                          </span>
                          <span
                            v-if="
                              slotProps.data.Messages[
                                slotProps.data.Messages.length - 1
                              ].MessageText.length > 25
                            "
                          >
                            ...
                          </span>
                        </div>
                      </div>
                    </template>
                    <template #footer>
                      <div style="text-align: right">
                        <small>{{
                          $formatDateTime(
                            slotProps.data.Messages[
                              slotProps.data.Messages.length - 1
                            ].MessageDate
                          )
                        }}</small>
                        &nbsp;
                        <Badge
                          v-if="
                            slotProps.data.Messages.filter(
                              (msg) =>
                                msg.MessageRead == false &&
                                msg.MessageSentBy == 'cust'
                            ).length > 0
                          "
                          :value="
                            slotProps.data.Messages.filter(
                              (msg) =>
                                msg.MessageRead == false &&
                                msg.MessageSentBy == 'cust'
                            ).length
                          "
                        ></Badge>
                      </div>
                    </template>
                  </Card>
                </div>
              </template>
            </DataView>
          </ScrollPanel>
        </div>
        <div class="hidden lg:inline-block col-1 md:col-6">
          <Card v-if="tempConvo != null" class="mt-3" style="">
            <!-- height: 75vh -->
            <template #title>
              {{ $t("message.conversation") }}
              <b>
                {{ tempConvo.Cust.CustFirstName }}
                {{ tempConvo.Cust.CustLastName }}
              </b>
            </template>
            <template #content>
              <ScrollPanel
                v-if="tempConvo != null"
                style="width: 95%; height: 50vh; vertical-align: middle"
                class="custom-scroll"
              >
                <convo-display id="convo1" class="msgArea" :data="tempConvo" />
              </ScrollPanel>
              <div>
                <Textarea
                  class="mt-2"
                  v-model="messageText"
                  :placeholder="$t('message.type')"
                  :autoResize="true"
                  rows="2"
                  cols="30"
                  style="width: 100%"
                />

                <div class="flex justify-content-between">
                  <Button
                    :label="$t('profile.cancel')"
                    icon="pi pi-times"
                    iconPos="left"
                    @click.stop="cancelMessage"
                    class="ml-5 mr-2 p-button-danger p-button-sm"
                  />
                  <Button
                    v-if="!sendingMessage"
                    :label="$t('menu.send')"
                    icon="pi pi-play"
                    iconPos="left"
                    @click.stop="sendMessage"
                    class="mr-5 p-button-success p-button-sm p-button-raised"
                  />
                  <Button
                    v-else
                    :label="$t('menu.send')"
                    icon="pi pi-spin pi-spinner"
                    iconPos="left"
                    disabled
                    @click.stop="sendMessage"
                    class="mr-5 p-button-success p-button-sm p-button-raised"
                  />
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
      <Dialog
        v-model:visible="showNewMessage"
        :modal="true"
        :contentStyle="{ overflow: 'scroll' }"
        :header="$t('message.new')"
        :dismissableMask="true"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
      <div v-if="busCustomers.length>0">
        <div style="text-align: left">
          <Dropdown
            class="mt-2"
            v-model="selectedCustomer"
            :options="busCustomers"
            optionLabel="CustLastName"
            :placeholder="$t('message.filter')"
            :filter="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>
                  {{ slotProps.value.CustLastName }},
                  {{ slotProps.value.CustFirstName }}
                </div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div>
                {{ slotProps.option.CustLastName }},
                {{ slotProps.option.CustFirstName }}
              </div>
            </template>
          </Dropdown>
          <Textarea
            class="mt-2"
            v-model="newMessageText"
            :placeholder="$t('message.type')"
            :autoResize="true"
            rows="5"
            cols="30"
            style="width: 100%"
          />
        </div>
        <div class="flex justify-content-between">
          <Button
            :label="$t('profile.cancel')"
            icon="pi pi-times"
            iconPos="left"
            @click.stop="cancelNewMessage"
            class="mr-2 p-button-danger p-button-sm"
          />
          <Button
            :label="$t('menu.send')"
            icon="pi pi-play"
            iconPos="left"
            @click.stop="sendNewMessage"
            class="mr-2 p-button-success p-button-sm"
          />
        </div>
      </div>
      <div v-else>
        {{ $t("message.nocustomers") }}
      </div>
      </Dialog>

      <Sidebar
        v-model:visible="showConvo"
        position="full"
        class="full-sidebar lg:hidden"
        style="background-color: #f8f9fa; overflow: scroll;"
      >
     <div class="flex flex-column h-full justify-content-between">
        <div class="mb-3">
          {{ $t("message.conversation") }}
          <b>
            {{ tempConvo.Bus.BusName }}
          </b>
        </div>
       
        <!-- <ScrollPanel
          id="convoDisplay"
          style="width: 95%;vertical-align: middle"
          class="custom-scroll flex-grow-1"
        > -->
        <div class="overflow-y-scroll flex-grow-1">
          <convo-display id="convo2" class="msgArea" :data="tempConvo" />
        <!-- </ScrollPanel> -->
        </div>
        <div style="" class="bottom-0 mr-5 pl-5">
          <Textarea
            class="mt-2"
            v-model="messageText"
            :placeholder="$t('message.type')"
            :autoResize="true"
            rows="2"
            cols="30"
            style="width: 100%"
          />

          <div class="flex justify-content-between">
            <Button
              :label="$t('profile.cancel')"
              icon="pi pi-times"
              iconPos="left"
              @click.stop="cancelMessage"
              class="ml-5 mr-2 p-button-danger p-button-sm"
            />
            <Button
              v-if="!sendingMessage"
              :label="$t('menu.send')"
              icon="pi pi-play"
              iconPos="left"
              @click.stop="sendMessage"
              class="mr-5 p-button-success p-button-sm p-button-raised"
            />
            <Button
              v-else
              :label="$t('menu.send')"
              icon="pi pi-spin pi-spinner"
              iconPos="left"
              disabled
              @click.stop="sendMessage"
              class="mr-5 p-button-success p-button-sm p-button-raised"
            />
          </div>
        </div>
        </div>
   
        <!-- </ScrollPanel> -->
      </Sidebar>
    </div>
    <div v-else-if="currentBusiness.BusActive == false">
      <inactive-subscription
        :busId="currentBusiness.BusId"
      ></inactive-subscription>
    </div>
    <div v-else-if="currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode != 'PREMIUM'">
     <!-- <div v-else-if="true"> -->
      <upgrade-subscription
        :subId="currentSubscription.SubId"
      ></upgrade-subscription>
    </div>
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{ $t("payments.error") }}</Message
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InactiveSubscription from "../components/InactiveSubscription.vue";
import Conversation from "../store/Models/Conversation";
import Customer from "../store/Models/Customer";
import Message from "../store/Models/Message";
import Business from "../store/Models/Business";
import ConvoDisplay from "../components/ConvoDisplay.vue";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import UpgradeSubscription from '../components/UpgradeSubscription.vue';
import Subscription from '../store/Models/Subscription';

//
export default {
  name: "BusinessMessages",
  components: { ConvoDisplay, LoadingOverlay, InactiveSubscription, UpgradeSubscription},
  data() {
    return {
      currentBusiness: {},
      showConvo: false,
      tempConvo: null,
      conversations: [],
      showNewMessage: false,
      busCustomers: [],
      selectedCustomer: null,
      messageText: "",
      newMessageText: "",
      currentSubscription:{},
      loading: false,
      sendingMessage: false,
    };
  },
  async mounted() {
    this.$log.debug("messages-mounted");
    this.$log.debug(this.currentBusinessId);
    if (this.currentBusinessId > 0) {
      this.loading = true;
      await this.setBusConvos();
      this.loading = false;
    }
    this.timer = setInterval(this.refreshBusConvos, 50000);
  },
  methods: {
    truncateMsg(msg) {
      return msg.substring(0, 25);
    },

    viewConvo(conversation) {
      this.tempConvo = conversation;
      this.showConvo = true;
      Conversation.markAsBusRead(conversation.ConvoId);
      this.scrollConvos();
    },
    scrollConvos() {
      setTimeout(() => {
        var elem = document.getElementById("convo1");
        this.$log.debug(elem.scrollHeight);
        elem.scrollTop = elem.scrollHeight;
        this.$log.debug(elem);
        var elem2 = document.getElementById("convo2");
        this.$log.debug(elem2.scrollHeight);
        elem2.scrollTop = elem2.scrollHeight;
        this.$log.debug(elem2);
      }, 250);
    },
    async displayNewMessage() {
      this.busCustomers = [];
      await Customer.fetchByBusId(this.currentBusinessId).then((result) => {
        this.busCustomers = result;
      });
      this.showNewMessage = true;
    },

    async sendNewMessage() {
      let newConvo = {
        ConvoId: 0,
        BusId: this.currentBusinessId,
        CustId: this.selectedCustomer.CustId,
        Messages: [],
      };

      let newMessage = {
        MessageId: 0,
        ConvoId: 0,
        MessageSentBy: "bus", //"customer/business"
        MessageDate: new Date(),
        MessageText: this.newMessageText,
        MessageRead: false,
      };

      newConvo.Messages.push(newMessage);

      this.$log.debug(newConvo);
      await Conversation.newConvo(newConvo).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          Conversation.deleteAll();
          this.setBusConvos();
          this.cancelNewMessage();
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("message.messagesent"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("message.messagefailed"),
            life: 3000,
          });
        }
      });
    },
    async sendMessage() {
      this.$log.debug("sending new message");
      this.sendingMessage = true;
      let newMessage = {
        MessageId: 0,
        ConvoId: this.tempConvo.ConvoId,
        MessageSentBy: "bus", //"customer/business"
        MessageDate: new Date(),
        MessageText: this.messageText,
        MessageRead: false,
      };

      await Message.newMessage(newMessage).then(async (response) => {
        this.$log.debug(response);
        this.sendingMessage = false;
        if (response == 1) {
          Conversation.deleteAll();
          this.setBusConvos();
          this.cancelMessage();
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("message.messagefailed"),
            life: 3000,
          });
          this.resetSelectedQuote();
        }
      });
    },
    cancelNewMessage() {
      this.newMessageText = "";
      this.selectedCustomerId = 0;
      this.showNewMessage = false;
    },
    cancelMessage() {
      this.messageText = "";
      this.selectedCustomerId = 0;
    },
    async setBusConvos() {
      Business.fetchBusinessProfileById(this.currentBusinessId).then(
        (result) => {
          this.currentBusiness = result;
          this.loading = false;
        }
      );
      Subscription.fetchCurrentByBusiness(this.currentBusinessId).then((result) => {
          this.currentSubscription = result;
         //console.log(this.currentSubscription)
        });
      await Conversation.fetchByBusId(this.currentBusinessId).then((result) => {
        this.$log.debug(result);
        this.conversations = result;
        if (this.tempConvo != null && this.tempConvo.ConvoId != null) {
          this.$log.debug("Convo opened");
          this.tempConvo = Conversation.query()
            .withAllRecursive()
            .find(this.tempConvo.ConvoId);
          this.scrollConvos();
        }
      });
    },
    async refreshBusConvos() {
      this.$log.debug("refreshing bus convos");
      await Conversation.refreshByBusId(this.currentBusinessId).then(
        (result) => {
          this.$log.debug(result);
          this.conversations = result;
          if (this.tempConvo != null && this.tempConvo.ConvoId != null) {
            this.tempConvo = Conversation.query()
              .withAllRecursive()
              .find(this.tempConvo.ConvoId);
          }
        }
      );
    },
  },
  watch: {
    async currentBusinessId() {
      this.loading = true;
      this.setBusConvos();
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style scoped >
.unread {
  border-left: 5px solid var(--mr-primary-color) !important;
}
.msgArea {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.custom-scroll .p-scrollpanel-wrapper {
  border-right: 9px solid var(--mr-primary-color) !important;
}

.p-scrollpanel .p-scrollpanel-bar {
  visibility: visible !important;
  background-color: var(--mr-primary-color) !important;

  transition: background-color 0.3s !important;
}

/* NOT WORKING */
#convoDisplay.p-scrollpanel-wrapper.p-scrollpanel-content {
  padding-right: 0 !important;
}

.custom-scroll .p-scrollpanel-bar:hover {
  background-color: var(--mr-primary-color) !important;
}
/* NOT WORKING */
.custom-scroll .p-scrollpanel-wrapper .p-scrollpanel-content {
  padding: 0px !important;
}
.full-sidebar.p-sidebar-content{
  height: 100% !important;
}
</style>
