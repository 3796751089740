<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content pt-3">
    <div class="flex justify-content-end">
      <Button
        icon="pi pi-shopping-cart"
        :label="$t('quotes.startquote')"
        class="p-button-primary p-button-sm mb-2"
        @click.stop="
          this.$router.push({
            name: 'CustomerFavorites',
            params: {
              quoteStarted: true,
            },
          })
        "
      />
    </div>
    <DataView :value="quotes" layout="list">
      <template #empty> {{ $t("quotes.noquotes") }} </template>
      <template #list="slotProps">
        <div class="col-12 md:col-6 lg:col-4">
          <Card
            class="mb-2 mr-1 ml-1"
            @click.stop="openQuoteDetails(slotProps.data)"
          >
            <template v-slot:content>
              <div class="grid">
                <div class="col-10 align-self-center">
                  <div class="box">
                    <div
                      class="flex flex-wrap justify-content-between"
                      style="text-align: left"
                    >
                      <span
                        >#{{ slotProps.data.QuoteNum }}<br />
                        <span style="font-size: x-large; text-align: left"
                          ><b>
                            {{ slotProps.data.Bus.BusName }}
                          </b></span
                        ><span> </span><br />
                        <em
                          >{{ $t("message.requested") }}:
                          
                          {{
                            $formatDateTimeToDate(
                              slotProps.data.QuoteRequestDate
                            )
                          }}</em
                        ><br />
                        <span v-if="slotProps.data.QuoteStatus=='Processed'"><em
                          >{{ $t("code.validuntil") }}:
                          {{
                        $getQuoteExpirationDate( slotProps.data.QuoteRequestDate,  slotProps.data.Bus.BusQuoteExpiration)
                          }}</em
                        ><br />
                      </span>
                        <Tag
                          class="mt-2"
                          :severity="getStatusTag(slotProps.data.QuoteStatus)"
                          style="text-align: left; font-size: x- small"
                        >
                          {{ getStatusLabel(slotProps.data.QuoteStatus) }}
                        </Tag></span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-2 align-self-center" style="text-align: right">
                  <Button
                    icon="pi pi-angle-right"
                    class="
                      p-button-rounded
                      p-button-text
                      p-button-primary
                      p-button-lg
                    "
                    @click.stop="openQuoteDetails(slotProps.data)"
                  />
                </div>
              </div>
            </template>
          </Card>
        </div>
      </template>
    </DataView>

    <Sidebar
      v-model:visible="showQuoteDetails"
      :baseZIndex="999"
      position="full"
      style="overflow: scroll; z-index: 900"
    >
      <div class="grid">
        <div class="col-12">
          <div
            class="
              flex flex-wrap
              justify-content-center
              align-items-center
              text-center
            "
          >
            <img
              :src="imageHostingURL + selectedQuote.Bus.BusLogo"
              :alt="selectedQuote.Bus.BusName"
              width="75"
              class="mr-3"
            />
            <div>
              <span style="font-size: xx-large">
                <a
                  :href="webHost + selectedQuote.Bus.BusHandle"
                  target="_blank"
                  >{{ selectedQuote.Bus.BusName }}</a
                > </span
              ><br />

              <span>
                <small>
                  {{ selectedQuote.Bus.BusAddress }}
                  {{ selectedQuote.Bus.BusCity }},
                  {{ selectedQuote.Bus.BusState }}
                  {{ selectedQuote.Bus.BusZip }}</small
                ></span
              ><br />
              <span>
                <small>{{ selectedQuote.Bus.BusPhone }} </small></span
              >
            </div>
          </div>
          <div class="freeze" style="background-color: white">
            <div
              class="col-12 mb-2"
              style="background-color: #e9ecef; text-align: center"
            >
              <span style="font-size: x-large"
                >#{{ selectedQuote.QuoteNum }}</span
              >
            </div>
            <div class="flex justify-content-center">
              <Button
                :label="$t('menu.cancelquote')"
                icon="pi pi-times"
                iconPos="left"
                @click.stop="cancelQuote()"
                class="mr-2 mb-2 p-button-danger p-button-sm p-button-raised"
                v-if="
                  selectedQuote.QuoteStatus != 'Processed' &&
                  selectedQuote.QuoteStatus != 'Accepted' &&
                  selectedQuote.QuoteStatus != 'Canceled' &&
                  selectedQuote.QuoteStatus != 'Expired' &&
                  revisingQuote == false
                "
              />
              <Button
                :label="$t('menu.revise')"
                icon="pi pi-pencil"
                iconPos="left"
                @click.stop="reviseQuote()"
                class="mr-2 mb-2 p-button-info p-button-sm p-button-raised"
                v-if="
                  selectedQuote.QuoteStatus != 'Accepted' &&
                  selectedQuote.QuoteStatus != 'Expired' &&
                  selectedQuote.QuoteStatus != 'Canceled' &&
                  selectedQuote.QuoteStatus != 'Processed' &&
                  revisingQuote == false
                "
              />
              <div
                v-if="
                  selectedQuote.QuoteStatus == 'Processed' &&
                  revisingQuote == false
                "
                class="flex justify-content-center mb-2"
              >
                <Button
                  :label="$t('menu.decline')"
                  icon="pi pi-times"
                  iconPos="left"
                  @click.stop="declineQuote()"
                  class="mr-2 p-button-danger p-button-sm p-button-raised"
                />
                <Button
                  :label="$t('menu.revise')"
                  icon="pi pi-pencil"
                  iconPos="left"
                  @click.stop="reviseQuote()"
                  class="mr-2 p-button-info p-button-sm p-button-raised"
                />
                <Button
                  :label="$t('menu.accept')"
                  icon="pi pi-check"
                  iconPos="left"
                  @click.stop="startQuoteAccept()"
                  class="mr-2 p-button-success p-button-sm p-button-raised"
                />
              </div>
              <div
                v-if="revisingQuote == true"
                class="flex justify-content-center mb-2"
              >
                <Button
                  v-if="revisingQuote"
                  :label="$t('profile.cancel')"
                  icon="pi pi-times"
                  iconPos="left"
                  @click.stop="
                    revisingQuote = false;
                    resetSelectedQuote();
                  "
                  class="mr-2 p-button-danger p-button-sm p-button-raised"
                />
                <Button
                  v-if="!savingQuote"
                  :label="$t('profile.save')"
                  icon="pi pi-save"
                  iconPos="left"
                  @click.stop="saveQuote"
                  class="mr-2 p-button-success p-button-sm p-button-raised"
                />
                <Button
                  v-else
                  :label="$t('profile.saving')"
                  icon="pi pi-spin pi-spinner"
                  iconPos="left"
                  disabled
                  class="mr-2 p-button-success p-button-sm p-button-raised"
                />
              </div>
            </div>
          </div>
          <div style="background-color: #e9ecef">
            <span style="font-size: x-large">{{ $t("calendar.status") }}</span>
          </div>
          <div class="center-text">
            <Tag
              class="m-2"
              :severity="getStatusTag(selectedQuote.QuoteStatus)"
              style="text-align: center; font-size: small"
            >
              {{ getStatusLabel(selectedQuote.QuoteStatus) }}:
              {{ getStatusDesc(selectedQuote.QuoteStatus) }} </Tag
            ><br />
          </div>
          <div>
            <div
              class="col-12 mb-2 flex justify-content-between"
              style="background-color: #e9ecef"
            >
              <span style="font-size: x-large">{{
                $t("custreserv.custevent")
              }}</span>
              <Button
                id="reviseDate"
                v-if="revisingQuote && selectedQuote.Bus.BusDisplayCalendar"
                icon="pi pi-pencil"
                class="
                  ml-2
                  p-button-rounded p-button-info p-button-sm p-button-raised
                "
                aria:haspopup="true"
                aria-controls="overlay_panel"
                @click.stop="toggle($event)"
              />
              <OverlayPanel
                ref="op"
                appendTo="body"
                :showCloseIcon="true"
                id="overlay_panel"
                style="z-index: 4000; margin: 2vh; width: 90%; height: 90%"
                class="text-center"
              >
                <p
                  v-show="showAvailableCalendar"
                  style="font-size: x-large; text-align: center"
                >
                  {{ $t("quotes.availableslots") }}
                </p>
                <vue-cal
                  v-show="showAvailableCalendar"
                  :events="availableSlots"
                  small
                  :locale="lang"
                  style="height: 500px"
                  active-view="month"
                  :disable-views="['years', 'week', 'day']"
                  events-count-on-year-view
                  events-on-month-view="true"
                  @cell-click="dateClick($event)"
                  :twelve-hour="twelveHr"
                  @ready="fetchEvents(selectedQuote.BusId)"
                  @view-change="fetchEvents(selectedQuote.BusId)"
                  :on-event-click="onEventClick"
                />
                <div v-show="!showAvailableCalendar">
                  <h3>
                    <b> {{ $t("calendar.notimeslots") }}</b>
                  </h3>
                </div>
              </OverlayPanel>
            </div>
            <div class="center-text">
              <template v-if="dateUnavailable">
                <Message severity="warn">{{
                  $t("quotes.dateunavailable")
                }}</Message>
              </template>
              <template v-else-if="datePast">
                <Message severity="warn">{{ $t("quotes.datepast") }}</Message>
              </template>
              <template
                v-else-if="
                  selectedQuote.Slot == null && showOriginalDate == true
                "
              >
                {{ $t("quotes.notselected") }}
              </template>
              <template
                v-if="selectedQuote.Slot != null && showOriginalDate == true"
              >
                <i class="pi pi-calendar"></i
                >{{ $formatDateTimeToDate(selectedQuote.Slot.SlotDate) }}<br />
                <i class="pi pi-clock"></i
                >{{ $formatTime(selectedQuote.Slot.SlotStartTime) }} -
                {{ $formatTime(selectedQuote.Slot.SlotEndTime) }}
              </template>

              <template
                v-if="showOriginalDate == false && showTempDate == true"
              >
                <i class="pi pi-calendar"></i>
                {{ $formatDateTimeToDate(selectedSlot.start) }}<br />
                <i class="pi pi-clock"></i>
                {{ $formatDateTimeToTime(selectedSlot.start) }} -
                {{ $formatDateTimeToTime(selectedSlot.end) }}
              </template>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-6 mb-2">
          <div style="text-align: left">
            <div style="background-color: #e9ecef">
              <span style="font-size: x-large">{{
                $t("custreserv.custinfo")
              }}</span>
            </div>
            <b>{{ $t("custreserv.custname") }}: </b>
            {{ selectedQuote.Cust.CustFirstName }}
            {{ selectedQuote.Cust.CustLastName }}
            <br />
            <b>{{ $t("listmybusiness.address") }}: </b
            >{{ selectedQuote.Cust.CustPhone }}
            <br />
            <b>{{ $t("custreserv.custevent") }}: </b>
            {{ selectedQuote.Cust.CustAddress }}<br />
            {{ selectedQuote.Cust.CustCity }},
            {{ selectedQuote.Cust.CustState }}
            {{ selectedQuote.Cust.CustZip }}
            <br />
            <b>Email: </b> {{ selectedQuote.Cust.CustEmail }}
          </div>
        </div>
        <div class="col-12 md:col-6 mb-2">
          <div style="text-align: right">
            <div style="background-color: #e9ecef">
              <span style="font-size: x-large">{{
                $t("custreserv.custprocess")
              }}</span>
            </div>
            <b>{{ $t("custreserv.custdatere") }}: </b
            >{{ $formatDateTime(selectedQuote.QuoteRequestDate) }}
            <br />
            <b>{{ $t("custreserv.custdatepro") }}: </b
            ><span v-if="selectedQuote.QuoteProcessedDate">{{
              $formatDateTime(selectedQuote.QuoteProcessedDate)
            }}</span
            ><span v-else>{{ $t("custreserv.custdatenotpro") }}: </span>
            <br />
            <b>{{ $t("custreserv.custproby") }}: </b
            ><span v-if="selectedQuote.QuoteProcessedBy">{{
              selectedQuote.QuoteProcessedBy
            }}</span
            ><span v-else>{{ $t("custreserv.custdatenotpro") }}: </span><br />
            <b>{{ $t("custreserv.custresponded") }}: </b
            ><span v-if="selectedQuote.QuoteResponseDate">{{
              $formatDateTime(selectedQuote.QuoteResponseDate)
            }}</span
            ><span v-else>{{ $t("custreserv.custnotapp") }}</span>
          </div>
        </div>

        <div
          class="col-12 mb-2 flex justify-content-between"
          style="background-color: #e9ecef"
        >
          <span style="font-size: x-large">{{ $t("custreserv.custser") }}</span>
          <Button
            v-if="revisingQuote && selectedQuote.Bus.BusDisplayServices"
            icon="pi pi-plus"
            class="
              ml-2
              p-button-rounded p-button-info p-button-sm p-button-raised
            "
            @click.stop="showBusServices()"
          />
          <Sidebar
            v-model:visible="showServices"
            :baseZIndex="1001"
            position="full"
            style="overflow: scroll"
          >
            <div style="text-align: center">
              <span style="font-size: x-large">
                {{ $t("services.availablepackages") }} </span
              ><br />
              <Button
                icon="pi pi-check"
                label="Apply to Quote"
                class="m-2 p-button-success p-button-raised p-button-sm"
                @click.stop="showServices = false"
              />
            </div>

            <DataView :value="busServices" layout="grid" class="service-grid">
              <template #empty>{{ $t("services.nopackages") }} </template>
              <template #grid="slotProps">
                <div
                  v-if="
                    selectedQuote.QuotedServices.filter(
                      (val) =>
                        val.ServId == slotProps.data.ServId &&
                        val.QuoteServId != 0 &&
                        val.QuoteServRemoved != true
                    ).length == 0 &&
                    slotProps.data.ServActive == true &&
                    slotProps.data.ServDeleted == false
                  "
                  class="col-12 md:col-4 center-text"
                >
                  <div
                    v-if="slotProps.data != null"
                    class="product-grid-item card"
                    style="background-color: white !important; height: 100%"
                  >
                    <div class="product-grid-item-top flex"></div>
                    <div class="product-grid-item-content">
                      <img
                        v-if="slotProps.data.ServImage != null"
                        :src="imageHostingURL + slotProps.data.ServImage"
                        :alt="slotProps.data.ServTitle"
                        width="25vh"
                        class="mr-3"
                      />
                      <div class="product-name">
                        {{ slotProps.data.ServTitle }}
                      </div>
                      <div class="product-description">
                        {{ slotProps.data.ServShortDesc }} <br />
                        <div>
                          <i class="pi pi-tag product-category-icon"></i>
                          <span
                            v-if="slotProps.data.ServiceTypes != null"
                            class="product-category"
                          >
                            {{
                              slotProps.data.ServiceTypes.map(
                                (s) => s.ServTypeTitle
                              ).join(", ")
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        product-grid-item-bottom
                        flex flex-wrap
                        justify-content-evenly
                      "
                    >
                      <span class="product-price mb-2 mr-2"
                        >${{ slotProps.data.ServPrice
                        }}<span
                          v-if="slotProps.data.ServPriceCalculated == true"
                          style="font-size: small"
                        >
                          {{ $t("quotes.each") }} &nbsp;
                        </span></span
                      >

                      <Tag
                        class="mb-2 mr-2"
                        v-if="slotProps.data.ServPromotionExpires != null"
                        severity="danger"
                        value="Danger"
                        style="margin-top: 5px"
                      >
                        <em>{{ $t("services.special") }}</em>
                      </Tag>
                    </div>

                    <div v-if="slotProps.data.ServPriceCalculated">
                      <label>{{ $t("menu.qty") }} </label>
                      <InputNumber
                        v-if="
                          selectedQuote.QuotedServices.filter(
                            (val) =>
                              val.ServId == slotProps.data.ServId &&
                              val.QuoteServRemoved != true
                          ).length == 0
                        "
                        class="mb-3"
                        v-model="
                          servicesForQuoteQty[getIndex(slotProps.data.ServId)]
                            .Qty
                        "
                        inputStyle="width:50px"
                      />
                      <InputNumber
                        v-else
                        disabled
                        class="mb-3"
                        v-model="
                          servicesForQuoteQty[getIndex(slotProps.data.ServId)]
                            .Qty
                        "
                        inputStyle="width:50px"
                      />
                    </div>
                    <Button
                      v-if="
                        selectedQuote.QuotedServices.filter(
                          (val) =>
                            val.ServId == slotProps.data.ServId &&
                            val.QuoteServRemoved != true
                        ).length == 0
                      "
                      icon="pi pi-plus"
                      :label="$t('menu.addquote')"
                      class="p-button-raised p-button-info p-button-sm"
                      @click.stop="addToQuote(slotProps.data)"
                    />
                    <Button
                      v-else
                      icon="pi pi-plus"
                      :label="$t('menu.removequote')"
                      class="
                        p-button-raised
                        p-button-danger
                        p-button-text
                        p-button-sm
                      "
                      @click.stop="removeFromQuote(slotProps.data)"
                    />
                  </div>
                  <div
                    v-else
                    class="product-grid-item card"
                    style="background-color: white !important"
                  >
                    {{ $t("services.fail") }}
                  </div>
                </div></template
              >
            </DataView>
          </Sidebar>
        </div>
        <div class="col-4" style="background-color: #f8f9fa">
          <b>{{ $t("custreserv.custservice") }}</b>
        </div>
        <div
          class="col-3"
          style="background-color: #f8f9fa; text-align: center"
        >
          <b>{{ $t("custreserv.custprice") }}</b>
        </div>
        <div
          class="col-2"
          style="background-color: #f8f9fa; text-align: center"
        >
          <b>{{ $t("custreserv.custqty") }}</b>
        </div>
        <div class="col-3" style="background-color: #f8f9fa; text-align: right">
          <span v-if="!revisingQuote"
            ><b>{{ $t("custreserv.custamount") }}</b></span
          >
        </div>

        <template
          v-for="(serv, index) in selectedQuote.QuotedServices"
          :key="serv.QuoteServId"
        >
          <template v-if="serv.QuoteServOrigId == null">
            <!-- <div class="col-12 card"> -->
            <div class="col-4">
              <span
                :class="{ removed: serv.QuoteServRemoved }"
                style="vertical-align: -webkit-baseline-middle"
                >{{ serv.Serv.ServTitle }} </span
              ><br />
              <div
                v-if="
                  serv.QuoteServRemoved == false && serv.QuoteServEdited != null
                "
              >
                <small
                  ><em
                    >{{
                      serv.QuoteServEditedBy === "cust"
                        ? $t("quotes.editedbyyou")
                        : $t("quotes.editedbybusiness")
                    }}
                    {{ $formatDateTime(serv.QuoteServEdited) }}
                  </em></small
                >
              </div>
              <div
                v-if="
                  serv.QuoteServRemoved == true && serv.QuoteServEdited != null
                "
              >
                <small
                  ><em
                    >{{
                      serv.QuoteServEditedBy === "cust"
                        ? "*Removed by you on "
                        : "*Removed by business on "
                    }}
                    {{ $formatDateTime(serv.QuoteServEdited) }}
                  </em></small
                >
              </div>
              <div
                v-if="
                  revisingQuote &&
                  (serv.Serv.ServActive == false ||
                    serv.Serv.ServDeleted == true)
                "
              >
                <small
                  ><em>{{ $t("message.noavailable") }}</em></small
                >
              </div>
            </div>
            <div
              class="col-3 flex flex-wrap justify-content-center"
              :class="{ removed: serv.QuoteServRemoved }"
            >
              <span style="vertical-align: -webkit-baseline-middle">
                ${{ serv.ServPrice }}
              </span>
              <span
                style="
                  vertical-align: -webkit-baseline-middle;
                  font-size: small;
                "
                v-if="serv.Serv.ServPriceCalculated == true"
              >
                &nbsp;{{ $t("quotes.each") }}
              </span>
            </div>
            <div
              class="col-2"
              :class="{ removed: serv.QuoteServRemoved }"
              style="vertical-align: middle; text-align: center"
            >
              <span
                v-if="revisingQuote == false"
                style="vertical-align: -webkit-baseline-middle"
                >{{ serv.QuoteServQty }}</span
              >
              <span
                v-else-if="
                  serv.Serv.ServPriceCalculated == true &&
                  serv.QuoteServRemoved == false
                "
              >
                <InputNumber
                  v-model="serv.QuoteServQty"
                  inputStyle="width:100%"
                />
              </span>
              <span v-else> {{ serv.QuoteServQty }} &nbsp;</span>
            </div>
            <div
              class="col-3"
              style="text-align: right"
              :class="{ removed: serv.QuoteServRemoved }"
            >
              <span v-if="serv.QuoteServTotal != 0 && revisingQuote != true"
                ><b style="vertical-align: -webkit-baseline-middle"
                  >${{ serv.QuoteServTotal }}</b
                ></span
              >

              <span
                v-if="revisingQuote && !serv.QuoteServRemoved"
                style="float: right"
              >
                <Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
                  @click.stop="removeService(index)"
                />
              </span>
            </div>
            <div
              class="col-12"
              style="margin-top: 0; padding-top: 0; color: darkgray"
              v-if="
                serv.QuoteServRemoved == true && serv.QuoteServEdited == null
              "
            >
              <small
                ><em> {{ $t("message.remove") }}</em></small
              >
            </div>
            <!-- <div
              class="col-12"
              style="margin-top: 0; padding-top: 0; color: darkgray"
              
            > -->

            <!-- </div> -->
            <!-- </div> -->
          </template>
        </template>

        <div
          class="col-12 mt-5 grid grid-nogutter justify-content-end"
          style="text-align: right"
        >
          <div
            class="col-9"
            style="text-align: right; background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custsub") }}</b>
          </div>
          <div class="col-3">
            <span v-if="selectedQuote.QuoteSubTotal != 0"
              ><b>${{ selectedQuote.QuoteSubTotal }}</b></span
            >
          </div>
          <div
            v-if="selectedQuote.QuoteDiscount != 0"
            class="col-9"
            style="background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custdis") }} </b>
          </div>
          <div v-if="selectedQuote.QuoteDiscount != 0" class="col-3">
            <span
              ><b>${{ selectedQuote.QuoteDiscount }}</b></span
            >
          </div>
          <div
            class="col-9"
            style="text-align: right; background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custsales") }}</b
            ><br />
            {{ selectedQuote.Bus.BusSalesTaxPercent }}%
          </div>
          <div class="col-3">
            <span v-if="selectedQuote.QuoteSubTotal != 0">
              <b>${{ selectedQuote.QuoteTax }}</b></span
            >
          </div>
          <div
            class="col-9"
            style="text-align: right; background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custtotal") }} </b>
          </div>
          <div class="col-3">
            <span v-if="selectedQuote.QuoteSubTotal != 0">
              <b>${{ selectedQuote.QuoteTotal }}</b></span
            >
          </div>
        </div>
      </div>

      <div
        class="col-12 flex justify-content-between"
        style="background-color: #e9ecef"
      >
        <span style="font-size: x-large"
          >{{ $t("custreserv.custcomments") }} </span
        ><Button
          icon="pi pi-plus"
          class="
            mr-2
            p-button-rounded p-button-info p-button-sm p-button-raised
          "
          @click.stop="addingComment = true"
        />
      </div>
      <div v-if="addingComment" class="col-12" style="text-align: left">
        <Textarea
          v-model="newComment.CommentText"
          :placeholder="$t('quotes.enter')"
          :autoResize="true"
          rows="5"
          cols="30"
          style="width: 100%"
        />
        <div class="flex justify-content-between">
          <Button
            :label="$t('profile.cancel')"
            icon="pi pi-times"
            iconPos="left"
            @click.stop="cancelComment"
            class="mr-2 p-button-danger p-button-sm p-button-raised"
          />
          <Button
            v-if="!savingComment"
            :label="$t('quotes.savecomment')"
            icon="pi pi-check"
            iconPos="left"
            @click.stop="saveComment"
            class="mr-2 p-button-success p-button-sm p-button-raised"
          />
          <Button
            v-else
            :label="$t('profile.saving')"
            icon="pi pi-spin pi-spinner"
            iconPos="left"
            disabled
            class="mr-2 p-button-success p-button-sm p-button-raised"
          />
        </div>
      </div>
      <div class="col-12">
        <template
          v-for="comment in selectedQuote.QuoteComments.sort((a, b) =>
            a.CommentDate > b.CommentDate ? -1 : 1
          )"
          :key="comment.CommentId"
        >
          <div class="card" style="text-align: left">
            <div class="flex justify-content-between">
              <span>
                {{ comment.Cust.CustFirstName }}
                {{ comment.Cust.CustLastName }}</span
              >
              <span> {{ $formatDateTime(comment.CommentDate) }}</span>
            </div>

            <em>{{ comment.CommentText }}</em>
          </div>
        </template>
      </div>
    </Sidebar>

    <Dialog
      v-model:visible="showCalendar"
      :modal="true"
      :contentStyle="{ overflow: 'scroll' }"
      :header="'Please select an available date to proceed'"
      :dismissableMask="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <vue-cal
        :events="availableSlots"
        small
        :locale="lang"
        style="height: 500px"
        active-view="month"
        :disable-views="['years', 'week', 'day']"
        events-count-on-year-view
        events-on-month-view="true"
        @cell-click="dateClick($event)"
        :twelve-hour="twelveHr"
        @ready="fetchEvents(selectedQuote.BusId)"
        @view-change="fetchEvents(selectedQuote.BusId)"
        :on-event-click="onEventClick"
      />
    </Dialog>
    <!-- contract agreement -->
    <Dialog
      v-model:visible="displayContractAgreement"

      :contentStyle="{ overflow: 'scroll' }"
      :header="$t('quotes.reservationcontract')"
      :dismissableMask="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <div style="text-align: left">
        <p>
          {{ $t("quotes.reviewcontractterms") }}
        </p>
        <div
          v-if="
            selectedQuote.Bus.BusDisplayReservTerms == true &&
            selectedQuote.Bus.BusinessReservTerms.length > 0
          "
          v-html="currentTerms"
        ></div>
        <div class="col-12">
          <label>Agreement:</label><br />
          <Checkbox v-model="agreementChecked" :binary="true" />
          {{ $t("quotes.iagree") }}
        </div>
        <div class="col-12">
          <label>Signature:</label>
          <InputText
            type="text"
            :placeholder="$t('quotes.signatureplaceholder')"
            v-model="agreementSignature"
            style="width: 100%"
          />
        </div>
      </div>
      <div class="flex justify-content-between">
        <Button
          :label="$t('profile.cancel')"
          icon="pi pi-times"
          iconPos="left"
          @click.stop="
            confirmingQuote = false;
            resetSelectedQuote();
          "
          class="mr-2 p-button-danger p-button-sm p-button-outlined"
        />
        <Button
          v-if="!agreementChecked || agreementSignature.length < 5"
          :label="$t('menu.submit')"
          icon="pi pi-check"
          iconPos="left"
          disabled
          class="mr-2 p-button-success p-button-sm p-button-raised"
        />
        <Button
          v-if="
            agreementChecked &&
            agreementSignature.length >= 5 &&
            !savingAgreement
          "
          :label="$t('menu.submit')"
          icon="pi pi-check"
          iconPos="left"
          @click.stop="termsAgreed()"
          class="mr-2 p-button-success p-button-sm p-button-raised"
        />
        <Button
          v-else-if="agreedToTerms && savingAgreement"
          :label="$t('profile.saving')"
          icon="pi pi-spin pi-spinner"
          iconPos="left"
          disabled
          class="mr-2 p-button-success p-button-sm p-button-raised"
        />
      </div>
    </Dialog>
    <!-- payment and submit -->
    <Dialog
      v-model:visible="confirmingQuote"
      :modal="true"
      :contentStyle="{ overflow: 'scroll' }"
      :header="$t('quotes.confirmquote')"
      :dismissableMask="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <div style="text-align: left">
        <div v-if="selectedQuote.Bus.BusDownPmtReq">
          {{ selectedQuote.Bus.BusName }} {{ $t("message.requiredown")
          }}
           {{(selectedQuote.Bus.BusDownPmtAmt > selectedQuote.QuoteTotal)
                    ? selectedQuote.QuoteTotal
                    : selectedQuote.Bus.BusDownPmtAmt}}

          {{ $t("message.complete") }}

          <div
            v-if="customerCards != null && customerCards.length > 0"
            class="field col-12"
          >
            <label>{{ $t("custreserv.custcard") }} </label>
            <div v-if="showSavedCards" class="field-radiobutton">
              <RadioButton
                id="cardOption1"
                name="cardOption"
                value="savedCard"
                v-model="cardOption"
              />&nbsp;
              <Dropdown
                id="paymentCards"
                class="mt-2 mr-2"
                v-model="selectedCard"
                :options="customerCards"
                :placeholder="$t('custreserv.custsecard')"
                @change="cardOption = 'savedCard'"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <div>
                      x{{ slotProps.value.CardNumber }},
                      <small>
                        Exp. {{ slotProps.value.CardExpMonth }}/{{
                          slotProps.value.CardExpYear
                        }}</small
                      >
                    </div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div>
                    x{{ slotProps.option.CardNumber }},
                    <small>
                      Exp. {{ slotProps.option.CardExpMonth }}/{{
                        slotProps.option.CardExpYear
                      }}</small
                    >
                  </div>
                </template>
              </Dropdown>
              <div v-if="displayCardRequired && selectedCard == null">
                <Message severity="error" :key="error" :sticky="true"
                  >Card Required</Message
                >
              </div>
            </div>
          </div>
          <div class="field-radiobutton">
            <RadioButton
              id="cardOption2"
              name="cardOption"
              value="newCard"
              v-model="cardOption"
            />
            <span>&nbsp;New Card&nbsp;</span>
          </div>
          <div v-if="cardOption == 'newCard'" class="text-left">
            <stripe-card ref="stripeCard"></stripe-card>
          </div>
          <div class="col-12">
            <Message
              v-for="error of errors"
              severity="error"
              :key="error"
              :sticky="true"
              >{{ error }}</Message
            >
          </div>
        </div>
      </div>
      <p>{{ $t("quotes.confirmation") }}</p>
      <div v-if="selectedQuote.Bus.BusDownPmtReq">
        <p>
          {{ $t("message.paymentamount$")
          }} {{(selectedQuote.Bus.BusDownPmtAmt > selectedQuote.QuoteTotal)
                    ? selectedQuote.QuoteTotal
                    : selectedQuote.Bus.BusDownPmtAmt}}
          {{ $t("message.charged") }}
        </p>
      </div>
      <div class="flex justify-content-between">
        <Button
          :label="$t('profile.cancel')"
          icon="pi pi-times"
          iconPos="left"
          @click.stop="
            confirmingQuote = false;
            resetSelectedQuote();
          "
          class="mr-2 p-button-danger p-button-sm p-button-outlined"
        />
        <Button
          v-if="!savingQuote"
          :label="$t('menu.submit')"
          icon="pi pi-check"
          iconPos="left"
          @click.stop="acceptQuote()"
          class="mr-2 p-button-success p-button-sm p-button-raised"
        />
        <Button
          v-else
          :label="$t('profile.saving')"
          icon="pi pi-spin pi-spinner"
          iconPos="left"
          disabled
          class="mr-2 p-button-success p-button-sm p-button-raised"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Quote from "../store/Models/Quote";
import QuoteComment from "../store/Models/QuoteComment";
import CalendarEvent from "../store/Models/CalendarEvent";
import Service from "../store/Models/Service";
import QuotedService from "../store/Models/QuotedService";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import StripeCard from "../components/StripeCard.vue";
import Card from "../store/Models/Card";
//

import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";

export default {
  name: "CustomerQuotes",
  components: { VueCal, LoadingOverlay, StripeCard },
  props: {
    quoteId: { default: 0, type: Number },
  },
  data() {
    return {
      selectedQuote: {},
      statusTagStyle: "",
      showQuoteDetails: null,
      filters: {},
      loading: false,
      quotes: [],
      revisingQuote: false,
      addingComment: false,
      savingComment: false,
      newComment: {},
      availableSlots: [],
      selectedSlot: {},
      twelveHr: true,
      showOriginalDate: true,
      showTempDate: false,
      showServices: false,
      busServices: [],
      servicesForQuoteQty: [],
      webHost: process.env.VUE_APP_MYRESERVS_URL,
      confirmingQuote: false,
      showSavedCards: true,
      stripe: "",
      elements: "",
      card: "",
      customerCards: [],
      errors: [],
      cardOption: null,
      selectedCard: null,
      showCalendar: false,
      pendingDate: false,
      displayCardRequired: false,
      savingQuote: false,
      fetchingEvents: false,
      displayContractAgreement: false,
      agreedToTerms: false,
      savingAgreement: false,
      showAvailableCalendar: true,
      agreementChecked: false,
      agreementSignature: "",
      acceptingQuote: false,
      dateUnavailable: false,
      datePast: false,
    };
  },
  async mounted() {
    this.$log.debug("customer quotes mounted");
    
    this.$log.debug(this.currentCustomerId);

    if (this.currentCustomerId > 0) {
      this.loading = true;
      await this.setCustomerQuotes();
      this.loading = false;
    }
    if (this.$route.params.quoteId > 0) {
      this.onLoadWithQuote();
    }
  },
  methods: {
    toggle(event) {
      this.$log.debug(event);
      this.$refs.op.toggle(event);
    },

    fetchEvents(busId) {
      this.fetchingEvents = true;
      this.$log.debug("fetching events for: " + busId);
      this.$log.debug(busId);
      CalendarEvent.fetchAvailableByType(busId, "DATE").then((result) => {
        this.availableSlots = result;
        this.$log.debug(this.availableSlots);
        this.fetchingEvents = false;
        if (this.availableSlots.length == 0) {
          this.showAvailableCalendar = false;
          if (this.acceptingQuote == true) {
            this.onEventClick(null);
          }
        }
      });
    },
    onLoadWithQuote() {
      let foundQuote = this.quotes.find(
        (quote) => quote.QuoteId == this.$route.params.quoteId
      );
      this.$log.debug(foundQuote);
      if (foundQuote) {
        this.selectedQuote = foundQuote;
        this.openQuoteDetails(this.selectedQuote);
        this.$router.replace("/CustomerQuotes");
      }
      this.loading = false;
    },
    onEventClick(event) {
      this.$log.debug(event);
      if (event != null) {
        this.availableSlots = [];
        this.selectedEvent = event;
        this.$log.debug(event);
        this.selectedQuote.SlotId = event.slotId;
        this.selectedSlot = event;
        this.showOriginalDate = false;
        this.showTempDate = true;
        this.$refs.op.hide();
        if (this.pendingDate) {
          this.startQuoteAccept();
        }
      } else {
        this.$refs.op.hide();
        if (this.acceptingQuote == true) {
          this.showCalendar = false;
          this.confirmingQuote = true;
        }
      }
    },
    toggleServices(event) {
      this.$log.debug(event);
      this.$refs.ops.show(event);
    },
    async showBusServices() {
      this.busServices = await Service.fetchPublicBusinessServices(
        this.selectedQuote.BusId
      );
      this.busServices.forEach((serv) => {
        this.servicesForQuoteQty.push({ ServId: serv.ServId, Qty: 1 });
      });
      this.showServices = true;
    },
    async addToQuote(service) {
      this.$log.debug("adding new service to quote");
      this.selectedQuote.QuotedServices.push(
        new QuotedService({
          QuoteServId: 0,
          QuoteId: this.selectedQuote.QuoteId,
          ServId: service.ServId,
          ServPrice: service.ServPrice,
          QuoteServQty:
            this.servicesForQuoteQty[this.getIndex(service.ServId)].Qty,
          QuoteServTotal: 0,
          Serv: service,
        })
      );
      this.$log.debug(this.selectedQuote.QuotedServices);
    },
    removeFromQuote(service) {
      this.$log.debug("removing new service");
      this.selectedQuote.QuotedServices.splice(
        this.selectedQuote.QuotedServices.findIndex(
          (val) => val == service.ServId
        ),
        1
      );
    },
    getIndex(servId) {
      return this.servicesForQuoteQty.findIndex((i) => i.ServId == servId);
    },
    openQuoteDetails(quoteData) {
      this.$log.debug(quoteData);
      this.selectedQuote = quoteData;
      this.dateUnavailable = false;
      this.datePast = false;

      //for quotes not yet if date is selected, verify date is still available, if not, notify the user.

     //console.log(this.selectedQuote);
     //console.log(this.selectedQuote.QuoteId);
     //console.log(this.selectedQuote.SlotId);
     //console.log(this.selectedQuote.Slot);
      if (
        this.selectedQuote.QuoteStatus === "Processed" ||
        this.selectedQuote.QuoteStatus === "Requested"
      ) {
       //console.log(this.selectedQuote.QuoteStatus);
        if (this.selectedQuote.Slot) {
         //console.log("not null");
          //check for unavailable
          if (this.selectedQuote.Slot.SlotAvailable == false) {
           //console.log("date no longer available");
            this.dateUnavailable = true;
            this.selectedQuote.SlotId = null;
            this.selectedQuote.Slot = null;
          }
          //check for date past
          var currentDate = new Date();
         //console.log(currentDate);
          if (this.$isInThePast(this.selectedQuote.Slot.SlotDate)) {
           //console.log("date in the past");
            this.datePast = true;
            this.selectedQuote.SlotId = null;
            this.selectedQuote.Slot = null;
          } else {
           //console.log("date in the future");
          }
        }
      }
      this.revisingQuote = false;
      this.showQuoteDetails = true;
    },
    // resetSelectedQuote() {
    //   if (this.selectedQuote != null && this.selectedQuote.QuoteId != null) {
    //     this.selectedQuote = Quote.query()
    //       .withAllRecursive()
    //       .find(this.selectedQuote.QuoteId);
    //   }
    // },
    async setCustomerQuotes() {
     //console.log("set customer quotes");
      await Quote.fetchByCustId(this.currentCustomerId).then((result) => {
        this.$log.debug(result);
        this.quotes = result;
        this.resetSelectedQuote();
      });
    },
    getStatusTag(status) {
      switch (status) {
        case "Requested":
          return "info";
        case "Processed":
          return "warning";
        case "Accepted":
          return "primary";
        case "Declined":
          return "danger";
        case "Canceled":
          return "danger";
        case "Expired":
          return "danger";
        default:
          return "info";
      }
    },
    getStatusDesc(status) {
      switch (status) {
        case "Requested":
          return this.$t("quotes.requested");
        case "Processed":
          return this.$t("quotes.processed");
        case "Accepted":
          return this.$t("quotes.accepted");
        case "Declined":
          return this.$t("quotes.decline");
        case "Expired":
          return this.$t("quotes.expired");
        case "Canceled":
          return this.$t("quotes.canceled");
        default:
          return "";
      }
    },
    getStatusLabel(status) {
      switch (status) {
        case "Requested":
          return this.$t("quotes.requestedLabel");
        case "Processed":
          return this.$t("quotes.processedLabel");
        case "Accepted":
          return this.$t("quotes.acceptedLabel");
        case "Declined":
          return this.$t("quotes.declineLabel");
        case "Expired":
          return this.$t("quotes.expiredLabel");
        case "Canceled":
          return this.$t("quotes.canceledLabel");
        default:
          return "";
      }
    },
    async acceptQuote() {
      this.savingQuote = true;
      if (this.selectedQuote.Bus.BusDownPmtReq != true) {
        this.createReserv(0);
      } else {
        if (this.cardOption == "newCard") {
          await this.createCard().then(async (response) => {
            if (response != null) {
              //card created and attached succesfull
              this.createReserv(response);
            } else {
              //card did not save
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("code.errorcard"),
                life: 3000,
              });
              this.savingQuote = false;
            }
          });
        } else if (this.selectedCard != null) {
          this.createReserv(this.selectedCard.CardId);
        } else {
          this.displayCardRequired = true;
        }
      }
    },
    async createReserv(cardId) {
      this.$log.debug("create reserv", cardId);
      this.selectedQuote.QuoteResponseDate = new Date();
      this.selectedQuote.QuoteStatus = "Accepted";
      await Quote.respondToQuote(
        this.selectedQuote,
        cardId,
        this.agreementSignature
      ).then(async (response) => {
        this.$log.debug(response);
        this.setCustomerQuotes();
        this.confirmingQuote = false;
        this.savingQuote = false;
        if (response == 1) {
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.reservationcreate"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: "Quote failed to update.",
            life: 3000,
          });
          this.resetSelectedQuote();
        }
      });
    },
    declineQuote() {
      this.$confirm.require({
        message: this.$t("quotes.declineconfirmation"),
        header: this.$t("quotes.deconfirmation"),
        acceptLabel: this.$t("menu.yes"),
        icon: "pi pi-calendar-times",
        accept: async () => {
          //callback to execute when user confirms the action

          this.selectedQuote.QuoteStatus = "Rejected";
          await Quote.respondToQuote(this.selectedQuote).then(
            async (response) => {
              this.setCustomerQuotes();
              this.$log.debug(response);
              if (response == 1) {
                this.$toast.add({
                  severity: "success",
                  summary: this.$t("custreserv.success"),
                  detail: this.$t("code.quoteupdated"),
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: this.$t("code.errors"),
                  detail: this.$t("code.quotefailedupdate"),
                  life: 3000,
                });
              }
            }
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    reviseQuote() {
      this.revisingQuote = true;
      // this.selectedQuote.QuotedServices.forEach((serv) => {
      //   serv.ServTotal = 0;
      // });
      this.selectedQuote.QuoteSubTotal = 0;
      this.selectedQuote.QuoteTax = 0;
      this.selectedQuote.QuoteTotal = 0;
      this.selectedQuote.QuoteDiscount = 0;
    },

    resetSelectedQuote() {
      if (this.selectedQuote != null) {
        this.selectedQuote = Quote.query()
          .withAllRecursive()
          .find(this.selectedQuote.QuoteId);
      }
      this.showOriginalDate = true;
      this.showTempDate = false;
      this.selectedSlot = {};
      this.servicesForQuoteQty = [];
      this.busServices = [];
      this.showServices = false;
      this.acceptingQuote = false;
      this.dateUnavailable = false;
    },
    removeService(servIndex) {
      this.$log.debug(servIndex);
      this.selectedQuote.QuotedServices[servIndex].QuoteServRemoved = true;
    },
    saveQuote() {
      this.savingQuote = true;
      this.$confirm.require({
        message: this.$t("quotes.revise"),
        header: this.$t("quotes.revisequote"),
        icon: "pi pi-calendar-check",
        acceptLabel: this.$t("menu.yes"),
        accept: async () => {
          //callback to execute when user confirms the action
          this.selectedQuote.QuoteStatus = "Requested";
          await Quote.respondToQuote(
            this.selectedQuote,
            0,
            this.agreementSignature
          ).then(async (response) => {
            this.$log.debug(response);
            this.savingQuote = false;
            this.setCustomerQuotes();
            this.revisingQuote = false;
            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("code.quoteupdated"),
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("code.quotefailedupdate"),
                life: 3000,
              });
              this.resetSelectedQuote();
            }
          });
        },
        reject: () => {
          this.savingQuote = false;
          //callback to execute when user rejects the action
        },
      });
    },
    cancelQuote() {
      this.$confirm.require({
        message: this.$t("quotes.cancelconfirm"),
        header: this.$t("menu.cancelquote"),
        icon: "pi pi-calendar-check",
        accept: async () => {
          //callback to execute when user confirms the action

          this.selectedQuote.QuoteStatus = "Canceled";
          await Quote.respondToQuote(this.selectedQuote).then(
            async (response) => {
              this.$log.debug(response);
              this.setCustomerQuotes();
              this.revisingQuote = false;
              if (response == 1) {
                this.$toast.add({
                  severity: "success",
                  summary: this.$t("custreserv.success"),
                  detail: this.$t("code.quotecanceled"),
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: this.$t("code.errors"),
                  detail: this.$t("code.quotefailedcancel"),
                  life: 3000,
                });
                this.resetSelectedQuote();
              }
            }
          );
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    async saveComment() {
      this.savingComment = true;
      this.newComment.QuoteId = this.selectedQuote.QuoteId;
      this.newComment.CustId = this.currentCustomerId;
      this.newComment.CommentDate = new Date();
      await QuoteComment.addComment(this.newComment).then(async () => {
        this.setCustomerQuotes();
        this.newComment = {};
        this.addingComment = false;
        this.savingComment = false;
      });
    },
    cancelComment() {
      this.addingComment = false;
      this.newComment = {};
    },
    startQuoteAccept() {
      this.$log.debug(this.selectedQuote);
      this.acceptingQuote = true;
      if (this.selectedQuote.SlotId == null || !this.selectedQuote.SlotId > 0) {
        this.pendingDate = true;
        this.showCalendar = true;
      } else {
        this.showCalendar = false;
        this.pendingDate = false;
        if (this.selectedQuote.Bus.BusDisplayReservTerms) {
          this.displayContractAgreement = true;
        } else {
          this.confirmQuote();
        }
      }
    },
    termsAgreed() {
      //process terms agreement
      this.displayContractAgreement = false;
      this.confirmQuote();
    },
    confirmQuote() {
      if (this.selectedQuote.Bus.BusDownPmtReq == true) {
        this.$log.debug("Down Pmt Req");
        Card.fetchByCustId(this.currentCustomerId).then((result) => {
          this.$log.debug(result);
          this.customerCards = result;
          this.$log.debug("cards: " + this.customerCards.length);
        });
        if (this.customerCards.length > 0) {
          this.cardOption = "savedCard";
        } else {
          this.cardOption = "newCard";
        }
      }
      this.confirmingQuote = true;
    },

    async createCard() {
      let cardId = 0;
      await this.$refs.stripeCard.createCard().then((response) => {
        this.$log.debug("New Card");
        this.$log.debug(response);
        if (response != null) {
          this.addingCard = false;
          cardId = response;
          this.$log.debug("card attached");
        } else {
          this.$log.debug("card failed to attach");
        }
      });

      return cardId;
    },
  },
  watch: {
    async currentCustomerId() {
     //console.log("current cust id watch");
      if (this.currentCustomerId > 0) this.setCustomerQuotes();
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
      lang: (state) => state.lang,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
    currentTerms() {
      if (this.selectedQuote != null && this.selectedQuote.Bus != null) {
        return this.selectedQuote.Bus.BusinessReservTerms.find(
          (terms) => terms.TermsValid == true
        ).TermsText;
      } else {
        return "";
      }
    },
  },
};
</script>
<style>
.p-datatable-header {
  background-color: white !important;
}

.p-dataview .p-dataview-content {
  background-color: #f8f9fa !important;
}
</style>
