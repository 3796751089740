<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content">
    <TabView>
      <TabPanel :header="$t('businessprofile.packages')">
        <Message v-if="showDeletedServiceAlert" severity="error"
          >{{ $t("quotes.nolongeravailable") }}.</Message
        >
        <div class="flex">
          <Button
            v-if="!showAddToQuote"
            icon="pi pi-shopping-cart"
            :label="$t('quotes.startquote')"
            class="p-button-primary p-button-sm ml-auto mb-2"
            @click.stop="startQuote()"
          />
        </div>
        <div
          class="
            flex flex-column
            p-flex-md-row
            align-items-center
            justify-content-evenly
          "
        >
          <div v-if="showAddToQuote" class="">
            <b>{{ $t("quotes.startedrequest") }}</b
            ><br /><em>
              {{ servicesForQuote.length }} {{ $t("quotes.added") }} &nbsp;</em
            >
          </div>
          <div v-if="showAddToQuote" class="flex justify-content-between">
            <Button
              icon="pi pi-times"
              :label="$t('profile.cancel')"
              class="p-button-danger p-button-sm m-1"
              @click.stop="
                resetQuote();
                showAddToQuote = false;
              "
            />
            <Button
              icon="pi pi-refresh"
              :label="$t('menu.reset')"
              class="p-button-secondary p-button-sm m-1"
              @click.stop="resetQuote()"
            />
            <Button
              v-if="!servicesForQuote.length > 0"
              icon="pi pi-check"
              :label="$t('menu.submit')"
              disabled
              class="p-button-success p-button-sm m-1"
            />
            <Button
              v-if="servicesForQuote.length > 0"
              icon="pi pi-check"
              :label="$t('menu.submit')"
              class="p-button-success p-button-sm m-1"
              @click.stop="showDateDialog = true"
            />
          </div>
        </div>
        <Message v-if="showAddToQuote == true" severity="info" :sticky="true"
          ><small>{{ $t("quotes.addpackages") }}</small></Message
        >
        <DataView :value="servFavorites" layout="grid" class="service-grid">
          <template #empty>{{ $t("quotes.notaddedpackages") }} </template>
          <template #grid="slotProps">
            <div
              v-if="
                slotProps.data.ServId != null &&
                slotProps.data.Serv != null &&
                slotProps.data.Serv.ServDeleted == false &&
                slotProps.data.Serv.ServActive == true &&
                promotionValid(slotProps.data.Serv.ServPromotionExpires)
              "
              class="col-12 md:col-4 mb-2 center-text"
            >
              <Card
                class="mb-2 mr-2"
                v-if="slotProps.data.Serv != null"
                style="
                  background-color: white !important;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                "
              >
                <template v-slot:content>
                  <div class="product-grid-item-top">
                    <div
                      class="pointer"
                      v-tooltip.right="$t('quotes.viewbusiness')"
                      @click.stop="
                        hideFav = false;
                        viewDetails(slotProps.data.Serv.BusId);
                      "
                    >
                      <i class="pi pi-map-marker product-category-icon"></i>
                      <span
                        v-if="slotProps.data.Serv.Bus != null"
                        class="product-category"
                      >
                        {{ slotProps.data.Serv.Bus.BusName }}
                      </span>
                      <span v-else>{{ $t("quotes.viewbusiness") }}</span>
                    </div>

                    <Button
                      v-if="!showAddToQuote"
                      icon="pi pi-times"
                      v-tooltip.right="$t('menu.removfav')"
                      class="p-button-secondary p-button-text"
                      @click.stop="removeServFavorite(slotProps.data.FavId)"
                    ></Button>
                  </div>
                  <div class="product-grid-item-content">
                    <img
                      v-if="slotProps.data.Serv.ServImage != null"
                      :src="imageHostingURL + slotProps.data.Serv.ServImage"
                      :alt="slotProps.data.Serv.ServTitle"
                      width="120"
                      length="120"
                      class="mr-3"
                    />

                    <div class="product-name">
                      {{ slotProps.data.Serv.ServTitle }}
                    </div>
                    <div class="product-description">
                      {{ slotProps.data.Serv.ServShortDesc }} <br />

                      <div class="mt-2 mb-2">
                        <i class="pi pi-tag product-category-icon"></i>
                        <span
                          v-if="slotProps.data.Serv.ServiceTypes != null"
                          class="product-category"
                        >
                          <!-- {{
                            slotProps.data.Serv.ServiceTypes.map(
                              (s) => s.ServTypeTitle
                            ).join(", ")
                          }}     -->
                          <template
                            v-for="type in slotProps.data.Serv.ServiceTypes"
                            :key="type.ServTypeTitle"
                          >
                            <Chip class="mr-1">
                              <service-type-label
                                :serviceType="type.ServTypeTitle"
                            /></Chip>
                          </template>
                        </span>
                      </div>
                      <Button
                        :label="$t('menu.moredetails')"
                        class="
                          p-button-raised
                          p-button-rounded
                          p-button-primary
                          p-button-sm
                          m-2
                        "
                        style="margin-top: 2px"
                        @click="viewServDetails(slotProps.data.Serv)"
                      />
                    </div>
                  </div>
                  <div
                    class="
                      product-grid-item-bottom
                      flex flex-wrap
                      justify-content-evenly
                    "
                  >
                    <span class="product-price mb-2 mr-2"
                      >${{ slotProps.data.Serv.ServPrice
                      }}<span
                        v-if="slotProps.data.Serv.ServPriceCalculated == true"
                        style="font-size: small"
                      >
                        each &nbsp;
                      </span></span
                    >

                    <Tag
                      class="mb-2 mr-2"
                      v-if="slotProps.data.Serv.ServPromotionExpires != null"
                      severity="danger"
                      value="Danger"
                      style="margin-top: 5px"
                    >
                      <em>{{ $t("services.special") }}</em>
                    </Tag>
                  </div>

                  <div class="flex justify-content-center">
                    <div
                      v-if="
                        showAddToQuote &&
                        slotProps.data.Serv.Bus != null &&
                        slotProps.data.Serv.Bus.BusAcceptQuotes == true
                      "
                    >
                      <div v-if="slotProps.data.Serv.ServPriceCalculated">
                        <label>{{ $t("menu.qty") }}:&nbsp;</label>
                        <InputNumber
                          v-if="
                            servicesForQuote.filter(
                              (val) => val == slotProps.data.Serv.ServId
                            ).length == 0
                          "
                          class="mb-3"
                          v-model="
                            servicesForQuoteQty[
                              getIndex(slotProps.data.Serv.ServId)
                            ].Qty
                          "
                          inputStyle="width:50px"
                        />
                        <InputNumber
                          v-else
                          disabled
                          class="mb-3"
                          v-model="
                            servicesForQuoteQty[
                              getIndex(slotProps.data.Serv.ServId)
                            ].Qty
                          "
                          inputStyle="width:50px"
                        />
                      </div>
                      <Button
                        v-if="
                          slotProps.data.Serv.Bus != null &&
                          slotProps.data.Serv.Bus.BusAcceptQuotes == true &&
                          servicesForQuote.filter(
                            (val) => val == slotProps.data.Serv.ServId
                          ).length == 0
                        "
                        icon="pi pi-plus"
                        :label="$t('menu.addquote')"
                        class="p-button-raised p-button-info p-button-sm"
                        @click.stop="addToQuote(slotProps.data.Serv)"
                      />
                      <Button
                        v-if="
                          slotProps.data.Serv.Bus != null &&
                          slotProps.data.Serv.Bus.BusAcceptQuotes == true &&
                          servicesForQuote.filter(
                            (val) => val == slotProps.data.Serv.ServId
                          ).length > 0
                        "
                        icon="pi pi-minus"
                        :label="$t('quotes.remove')"
                        class="
                          p-button-raised
                          p-button-danger
                          p-button-text
                          p-button-sm
                        "
                        @click.stop="removeFromQuote(slotProps.data.Serv)"
                      />
                    </div>
                    <InlineMessage
                      v-if="
                        showAddToQuote &&
                        slotProps.data.Serv.Bus != null &&
                        slotProps.data.Serv.Bus.BusAcceptQuotes != true
                      "
                      severity="warn"
                      ><small
                        ><b>{{ $t("quotes.sorry") }}</b></small
                      ></InlineMessage
                    >
                  </div>
                </template>
              </Card>
              <div
                v-else
                class="product-grid-item card"
                style="background-color: white !important"
              >
                {{ $t("services.fail") }}
              </div>
            </div></template
          >
        </DataView>

        <!-- show calendar dialog -->
        <Dialog
          v-model:visible="showDateDialog"
          :modal="true"
          :contentStyle="{ overflow: 'scroll' }"
          :header="$t('quotes.eventdatetime')"
          style="text-align: center"
          :dismissableMask="true"
          :style="{ width: '50vw' }"
          :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
        >
          <p>
            {{ $t("quotes.date") }}
          </p>
          <br />
          <div class="grid justify-content-center">
            <template v-for="quote in quotes" :key="quote.BusId">
              <div
                class="col-12 md:col-4"
                v-if="getBusinessDisplayCalendar(quote.BusId)"
              >
                <label>{{ getBusinessName(quote.BusId) }}</label
                ><br />
                <div class="p-inputgroup justify-content-center">
                  <Button
                    type="button"
                    icon="pi pi-calendar-plus"
                    :label="
                      quote.SlotId
                        ? getVendorTime(quote.BusId)
                        : $t('quotes.timeslot')
                    "
                    @click.stop="
                      tempBusId = quote.BusId;
                      toggle($event);
                    "
                    aria:haspopup="true"
                    aria-controls="overlay_panel"
                  />
                  <Button
                    class="p-button-danger"
                    type="button"
                    icon="pi pi-times"
                    @click.stop="clearVendorTime(quote.BusId)"
                  />
                </div>
              </div>
              <div v-else>
                <Button
                  type="button"
                  icon="pi pi-calendar-plus"
                  :label="$t('quotes.calendarnotavailable')"
                  @click.stop="
                    tempBusId = quote.BusId;
                    toggle($event);
                  "
                  aria:haspopup="true"
                  aria-controls="overlay_panel"
                  disabled
                /><br />
                <InlineMessage class="mt-1" severity="warn"
                  ><small>{{ $t("quotes.submitquote") }}</small></InlineMessage
                >
              </div>
              <br />

              <OverlayPanel
                ref="op1"
                appendTo="body"
                :showCloseIcon="true"
                id="overlay_panel"
                style="margin: 2vh; width: 90%"
              >
                <div style="font-size: large; text-align: center">
                  {{ $t("quotes.availableslots") }}
                </div>
                <div style="font-size: x-large; text-align: center">
                  <b>{{ getBusinessName(quote.BusId) }}</b>
                </div>

                <vue-cal
                  :events="availableSlots"
                  small
                  style="height: 500px"
                  active-view="month"
                  :disable-views="['years', 'week', 'day']"
                  events-count-on-year-view
                  events-on-month-view="true"
                  @cell-click="dateClick($event)"
                  :twelve-hour="twelveHr"
                  @ready="fetchEvents()"
                  @view-change="fetchEvents()"
                  :on-event-click="onEventClick"
                >
                  <template v-slot:event="{ event, view }">
                    <!-- <v-icon>{{ event.icon }}</v-icon> -->

                    <!-- <div class="vuecal__event-title"> -->
                    <!-- <span
                  v-if="event.title != 'Available' && event.title != 'Hidden'"
                  >{{ event.title }}</span
                > -->
                    <!-- </div> -->
                    <!-- v-html="event.title" -->
                    <!-- Or if your events are editable: -->
                    <!-- <div
                class="vuecal__event-title vuecal__event-title--edit"
                contenteditable
                @blur="event.title = $event.target.innerHTML"
                v-html="event.title"
              /> -->

                    <span class="vuecal__event-time" v-if="view == 'month'">
                      <span class="show-on-desktop">
                        {{ event.start.formatTime("h:mm am") }} -
                        {{ event.end.formatTime("h:mm am") }}</span
                      >
                      <small class="show-on-mobile">
                        {{ event.start.formatTime("h:mm am") }}
                      </small>
                    </span>
                    <span class="vuecal__event-time" v-if="view == 'day'">
                      <small class="">
                        {{ event.title }}<br />
                        {{ event.start.formatTime("h:mm am") }} -
                        {{ event.end.formatTime("h:mm am") }}</small
                      >
                    </span>
                  </template>
                  <!-- <template v-slot:events-count="{ events }">
              <span v-if="customEventsCount(events)">
                {{ customEventsCount(events) }}
              </span>
            </template> -->
                </vue-cal>
              </OverlayPanel>
            </template>
          </div>
          <div class="flex justify-content-between">
            <Button
              :label="$t('profile.cancel')"
              class="p-button-raised p-button-danger mt-2"
              @click.stop="showDateDialog = false"
            />
            <!-- <Button
              :label="$t('menu.skip')"
              class="p-button-raised p-button-info mt-2"
              @click.stop="reviewQuoteSelection()"
            /> -->
            <Button
              :label="$t('menu.next')"
              class="p-button-raised p-button-success mt-2"
              @click.stop="reviewQuoteSelection()"
            />
          </div>
        </Dialog>

        <!-- show review quote  dialog -->
        <Dialog
          v-model:visible="showQuoteReviewDialog"
          :contentStyle="{ overflow: 'scroll' }"
          :modal="true"
          style="text-align: center"
          :dismissableMask="true"
          :style="{ width: '50vw' }"
          :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
        >
          <template #header>
            <b> {{ $t("businessprofile.reviewquote") }}</b>
          </template>

          <p>{{ $t("quotes.review") }}</p>

          <template v-for="quote in quotes" :key="quote.BusId">
            <div class="grid mb-3">
              <div class="col-12" style="background-color: #e9ecef">
                <b> {{ getBusinessName(quote.BusId) }}</b
                ><br />
                {{ getVendorTime(quote.BusId) }}
              </div>

              <div class="col-4" style="background-color: #f8f9fa">
                <b>Service</b>
              </div>
              <div class="col-4" style="background-color: #f8f9fa">
                <b>Price</b>
              </div>
              <div class="col-4" style="background-color: #f8f9fa">
                <b>Qty</b>
              </div>

              <template v-for="serv in quote.QuotedServices" :key="serv.ServId">
                <div class="col-4">{{ serv.Serv.ServTitle }}</div>
                <div class="col-4">
                  ${{ serv.ServPrice }}
                  <span
                    v-if="serv.Serv.ServPriceCalculated == true"
                    style="font-size: small"
                  >
                    each
                  </span>
                </div>
                <div class="col-4">
                  <span v-if="!serv.Serv.ServPriceCalculated">1</span>
                  <span v-else>{{
                    servicesForQuoteQty[getIndex(serv.ServId)].Qty
                  }}</span>
                </div>
              </template>
            </div>
          </template>

          <p>
            <em>{{ $t("quotes.differentvendors") }}</em>
          </p>

          <template #footer>
            <div class="flex justify-content-between">
              <Button
                :label="$t('profile.cancel')"
                class="p-button-raised p-button-danger mt-2"
                @click.stop="showQuoteReviewDialog = false"
              />
              <Button
                :label="$t('menu.back')"
                class="p-button-raised p-button-info mt-2"
                @click.stop="
                  showQuoteReviewDialog = false;
                  showDateDialog = true;
                "
              />

              <Button
                v-if="!submittingQuote"
                :label="$t('menu.submit')"
                class="p-button-raised p-button-success mt-2"
                autofocus
                @click.stop="submitQuote"
              />
              <Button
                v-else
                icon="pi pi-spin pi-spinner"
                :label="$t('menu.submit')"
                class="p-button-raised p-button-success mt-2"
                disabled
              />
            </div>
          </template>
        </Dialog>
      </TabPanel>
      <TabPanel :header="$t('quotes.vendor')">
        <DataView :value="busFavorites" layout="grid" class="service-grid">
          <template #empty> {{ $t("quotes.nofavorites") }} </template>
          <template #grid="slotProps">
            <div class="col-12 md:col-4 mb-2 center-text">
              <Card
                class="mb-2 mr-2 pointer"
                v-if="slotProps.data.Bus"
                style="
                  background-color: white !important;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                "
                @click="
                  hideFav = true;
                  viewDetails(slotProps.data.Bus.BusId);
                "
              >
                <template v-slot:content>
                  <div class="product-grid-item-top flex">
                    <div>
                      <i class="pi pi-tag product-category-icon"></i>
                      <span class="product-category">
                        {{ slotProps.data.Bus.BusCategory }}
                      </span>
                    </div>

                    <Button
                      icon="pi pi-times"
                      v-tooltip.right="$t('menu.removfav')"
                      class="p-button-secondary p-button-text"
                      @click.stop="removeBusFavorite(slotProps.data.FavId)"
                    ></Button>
                  </div>
                  <div class="product-grid-item-content">
                    <img
                      v-if="slotProps.data.Bus != null"
                      :src="imageHostingURL + slotProps.data.Bus.BusLogo"
                      :alt="slotProps.data.Bus.BusName"
                      width="120"
                      length="120"
                    />
                    <div class="product-name">
                      {{ slotProps.data.Bus.BusName }}
                    </div>
                    <div class="product-description">
                      {{ slotProps.data.Bus.BusCity }},
                      {{ slotProps.data.Bus.BusState }}
                    </div>
                    <Rating
                      style="display: inline"
                      :modelValue="slotProps.data.Bus.BusRating"
                      :readonly="true"
                      :cancel="false"
                    ></Rating>
                  </div>
                  <div
                    class="
                      product-grid-item-bottom
                      -d-flex
                      justify-content-center
                    "
                  >
                    <Button
                      :label="$t('menu.viewdetails')"
                      class="
                        p-button-raised
                        p-button-rounded
                        p-button-primary
                        p-button-sm
                        mt-2
                      "
                      style="margin-top: 2px"
                      @click="
                        hideFav = true;
                        viewDetails(slotProps.data.Bus.BusId);
                      "
                    />
                  </div>
                </template>
              </Card>

              <div
                v-else
                class="product-grid-item card"
                style="background-color: white !important"
              >
                {{ $t("services.favorite") }}
              </div>
            </div></template
          >
        </DataView>
      </TabPanel>
    </TabView>
  </div>

  <!-- Display Business Details -->
  <Sidebar
    v-model:visible="showBusinessDetails"
    position="full"
    style="z-index: 1010; overflow: scroll"
  >
    <!-- <ScrollPanel style="width: 92%; height: 95vh"> -->
    <div class="p-2">
      <business-details
        :currentBusiness="tempBusiness"
        :hideFav="hideFav"
      ></business-details>
    </div>
    <!-- </ScrollPanel> -->
  </Sidebar>

  <!-- Display Service Details -->
  <Sidebar
    v-model:visible="showServiceDetails"
    position="full"
    style="z-index: 1020; overflow: scroll"
  >
    <!-- <ScrollPanel style="width: 100%; height: 95vh; padding-top: 25px"> -->
    <div class="p-2 mt-5">
      <service-grid-layout
        :data="tempService"
        :mode="'view'"
        :view="'full'"
        :hideFav="true"
      />
    </div>
    <!-- </ScrollPanel> -->
  </Sidebar>

  <!-- <FullCalendar ref="calendar" :events="avaialbleSlots" :options="options" /> -->
</template>

<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";

import LoadingOverlay from "../components/LoadingOverlay.vue";
import BusinessDetails from "../components/BusinessDetails.vue";
import Business from "../store/Models/Business";
import Quote from "../store/Models/Quote";
import BusinessFavorite from "../store/Models/BusinessFavorite";
import ServiceGridLayout from "../components/ServiceGridLayout.vue";
import Service from "../store/Models/Service";
import ServiceFavorite from "../store/Models/ServiceFavorite";
import CalendarEvent from "../store/Models/CalendarEvent";
import QuotedService from "../store/Models/QuotedService";
import ServiceTypeLabel from "../components/ServiceTypeLabel.vue";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import moment from "moment";

// FullCalendar
export default {
  name: "CustomerFavorites",
  components: {
    BusinessDetails,
    ServiceGridLayout,
    VueCal,
    LoadingOverlay,
    ServiceTypeLabel,
  },
  props: {
    quoteStarted: { default: false, type: Boolean },
  },
  data() {
    return {
      twelveHr: true,
      showDeletedServiceAlert: false,
      showBusinessDetails: false,
      showServiceDetails: false,
      showAddToQuote: false,
      showDateDialog: false,
      showQuoteReviewDialog: false,
      dateForQuote: null,
      timeForQuote: null,
      quotes: [],
      servicesForQuote: [],
      vendorsForQuote: [],
      quoteRequests: [],
      tempService: {},
      //  currentCustomer: {},
      servicesForQuoteQty: [],
      availableSlots: [],
      tempBusId: 0,
      servFavorites: [],
      busFavorites: [],
      loading: false,
      submittingQuote: false,
    };
  },

  async mounted() {
    this.$log.debug(
      "customer favorites mounted",
      this.$route.params.quoteStarted
    );
    this.$log.debug(this.currentCustomerId);
    if (this.currentCustomerId > 0) {
      this.loading = true;
      await this.setCurrentCustomer();
      this.loading = false;
    }
    if (this.servFavorites != null) {
      this.showDeletedServiceAlert =
        this.servFavorites.filter((fav) => fav.ServId == null).length > 0
          ? true
          : false;
      if (this.showDeletedServiceAlert == true) {
        ServiceFavorite.delete(
          (fav) => fav.CustId == this.currentCustomerId && fav.ServId == null
        );
        ServiceFavorite.cleanServiceFavorites(this.currentCustomerId);
      }
    }
    if (this.$route.params.quoteStarted) {
      this.startQuote();
    }
  },
  methods: {
    getBusinessDisplayCalendar(busId) {
      this.$log.debug("get business display calendar for " + busId);
      return Business.fetchBusDisplayCalendar(busId);
    },
    getVendorTime(busId) {
      this.$log.debug("get vendor time");
      var vendorIndex = this.vendorsForQuote.findIndex(
        (vendor) => vendor.BusId == busId
      );
      this.$log.debug(this.vendorsForQuote[vendorIndex]);
      if (this.vendorsForQuote[vendorIndex].Slot != "") {
        return this.$formatDateTime(
          this.vendorsForQuote[vendorIndex].Slot.start
        );
      } else {
        return "";
      }
    },
    clearVendorTime(busId) {
      this.$log.debug("clear vendor time");
      var vendorIndex = this.vendorsForQuote.findIndex(
        (vendor) => vendor.BusId == busId
      );
      this.vendorsForQuote[vendorIndex].Slot = "";
      var busIndex = this.quotes.findIndex((quote) => quote.BusId == busId);
      var quote = this.quotes[busIndex];
      quote.SlotId = null;
      this.quotes[busIndex] = quote;
      this.$log.debug(this.quotes);
    },
    toggle(event) {
      //this.$log.debug(event);
      //this.$log.debug(this.$refs.op1);
      // this.tempBusId = event.target.id;
      this.$refs.op1[0].show(event);
    },
    onEventClick(event) {
      this.$log.debug("onEventClick");

      this.availableSlos = [];
      this.selectedEvent = event;
      this.$log.debug(event);
      var busIndex = this.quotes.findIndex(
        (quote) => quote.BusId == event.busId
      );
      var quote = this.quotes[busIndex];
      this.$log.debug(quote);
      quote.SlotId = event.slotId;
      this.quotes[busIndex] = quote;
      var vendIndex = this.vendorsForQuote.findIndex(
        (vendor) => vendor.BusId == event.busId
      );
      var vendor = this.vendorsForQuote[vendIndex];
      vendor.Slot = event;
      this.vendorsForQuote[vendIndex] = vendor;
      this.$log.debug(this.vendorsForQuote[vendIndex]);
      this.$refs.op1[0].hide();
    },
    dateClick(event) {
      this.$log.debug(event);
      this.$refs.op1[0].hide();
    },
    fetchEvents() {
      this.$log.debug("fetching events for: " + this.tempBusId);
      CalendarEvent.fetchAvailableByType(this.tempBusId, "DATE").then(
        (result) => {
          this.availableSlots = result;
          this.$log.debug(this.availableSlots);
        }
      );
    },
    ...mapActions(["getCurrentCustomerId"]),
    async setCurrentCustomer() {
      await BusinessFavorite.fetchByCustId(this.currentCustomerId).then(
        (result) => {
          this.$log.debug(result);
          this.busFavorites = result;
        }
      );

      await ServiceFavorite.fetchByCustId(this.currentCustomerId).then(
        (result) => {
          this.$log.debug(result);
          this.servFavorites = result;
        }
      );
    },

    getBusinessName(busId) {
      this.$log.debug("get business name for " + busId);
      return Business.fetchBusinessName(busId);
    },
    getSlotDateTime(busId) {
      this.$log.debug("get string");
      var busIndex = this.vendorsForQuote.findIndex(
        (vendor) => vendor.BusId == busId
      );
      var vendor = this.vendorsForQuote[busIndex];
      return moment(vendor.Slot.start).format("MM/DD/YY");
    },
    getSlots(busId) {
      var busIndex = this.vendorsForQuote.findIndex(
        (vendor) => vendor.BusId == busId
      );
      return this.vendorsForQuote[busIndex].Slots;
    },
    promotionValid(promotionExpirationDate) {
      if (promotionExpirationDate == null) {
        return true;
      } else {
        return moment().isSameOrBefore(promotionExpirationDate, "day");
      }
    },
    resetQuote() {
      CalendarEvent.deleteAll();
      this.availableSlots = [];
      this.vendorsForQuote = [];
      this.servicesForQuoteQty = [];
      this.quotes = [];
      this.servicesForQuote = [];
      this.servFavorites.forEach((serv) => {
        this.servicesForQuoteQty.push({ ServId: serv.ServId, Qty: 1 });
      });
    },
    startQuote() {
      CalendarEvent.deleteAll();
      this.availableSlots = [];
      this.vendorsForQuote = [];
      this.servicesForQuoteQty = [];
      this.quotes = [];
      this.servFavorites.forEach((serv) => {
        this.servicesForQuoteQty.push({ ServId: serv.ServId, Qty: 1 });
      });
      this.showAddToQuote = true;
    },
    getIndex(servId) {
      return this.servicesForQuoteQty.findIndex((i) => i.ServId == servId);
    },
    async addToQuote(service) {
      this.$log.debug("before adding service");
      this.$log.debug(this.quotes);
      this.$log.debug(this.availableSlots);
      this.servicesForQuote.push(service.ServId);
      // 1 quote per business
      if (
        this.quotes.filter((quote) => quote.BusId == service.BusId).length == 0
      ) {
        //start new business quote
        var busQuote = new Quote();
        busQuote.QuoteId = 0;
        busQuote.BusId = service.BusId;
        busQuote.CustId = this.currentCustomerId;
        busQuote.QuoteStatus = "Requested";
        busQuote.QuoteRequestedDate = new Date();
        busQuote.QuotedServices = [];
        busQuote.QuotedServices.push(
          new QuotedService({
            QuoteServId: 0,
            QuoteId: 0,
            ServId: service.ServId,
            ServPrice: service.ServPrice,
            QuoteServQty:
              this.servicesForQuoteQty[this.getIndex(service.ServId)].Qty,
            QuoteServTotal: 0,
            Serv: service,
          })
        );
        this.quotes.push(busQuote);
        this.vendorsForQuote.push({ BusId: service.BusId, Slot: "" });
      } else {
        var busIndex = this.quotes.findIndex(
          (quote) => quote.BusId == service.BusId
        );
        var quote = this.quotes[busIndex];
        quote.QuotedServices.push(
          new QuotedService({
            QuoteServId: 0,
            QuoteId: 0,
            ServId: service.ServId,
            ServPrice: service.ServPrice,
            QuoteServQty:
              this.servicesForQuoteQty[this.getIndex(service.ServId)].Qty,
            QuoteServTotal: 0,
            Serv: service,
          })
        );
        this.quotes[busIndex] = quote;
      }
      this.$log.debug("after adding service");
      this.$log.debug(this.quotes);
      this.$log.debug(this.availableSlots);
    },
    removeFromQuote(service) {
      this.$log.debug("before removing service");
      this.$log.debug(this.quotes);
      this.$log.debug(this.availableSlots);
      var busIndex = this.quotes.findIndex(
        (quote) => quote.BusId == service.BusId
      );
      var quote = this.quotes[busIndex];

      var deleteSlots = quote.QuotedServices.length > 1;

      const servIndex = (serv) => serv.ServId == service.ServId;
      quote.QuotedServices.splice(quote.QuotedServices.findIndex(servIndex), 1);
      this.$log.debug(this.servicesForQuote);
      this.servicesForQuote.splice(
        this.servicesForQuote.findIndex((val) => val == service.ServId),
        1
      );
      this.quotes[busIndex] = quote;

      if (deleteSlots) {
        this.vendorsForQuote = this.vendorsForQuote.filter(
          (vendor) => vendor.BusId != quote.BusId
        );
        this.availableSlots = this.availableSlots.filter(
          (slot) => slot.BusId != quote.BusId
        );
      }
      this.$log.debug("after removing service");
      this.$log.debug(this.quotes);
      this.$log.debug(this.availableSlots);
    },
    showIncludeDateConfirm(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: this.$t("code.dateforquote"),
        icon: "pi pi-calendar",
        accept: () => {
          this.showDateDialog = true;
        },
        reject: () => {
          this.showQuoteReviewDialog = true;
        },
      });
    },

    showQuoteDateSelection() {
      this.showDateDialog = true;
    },
    reviewQuoteSelection() {
      this.showDateDialog = false;
      this.showQuoteReviewDialog = true;
    },
    // checkForDownpaymentRequired() {
    //   if (this.selectedQuote.Bus.BusDownPmtReq != true) {
    //     this.submitAutoProcessedQuote();
    //   }
    //   else{
    //          if (this.cardOption == "newCard") {
    //       await this.createCard().then(async (response) => {
    //         if (response != null) {
    //           //card created and attached succesfull
    //           this.createReserv(response);
    //         } else {
    //           //card did not save
    //           this.$toast.add({
    //             severity: "error",
    //             summary: this.$t("code.errors"),
    //             detail: this.$t("code.errorcard"),
    //             life: 3000,
    //           });
    //           this.savingQuote = false;
    //         }
    //       });
    //     } else if (this.selectedCard != null) {
    //       this.createReserv(this.selectedCard.CardId);
    //     } else {
    //       this.displayCardRequired = true;
    //     }
    //   }
    // },

    async submitQuote() {
      this.$log.debug("submitting quote");
      this.submittingQuote = true;
     // var quotesReqPayment = [];
      var totalQuotes = this.quotes.length;
      this.quotes.forEach(async (quote) => {
        this.$log.debug(quote);
        quote.QuoteSubTotal = 0;
        quote.QuoteDiscount = 0;
        quote.QuoteTax = 0;
        quote.QuoteTotal = 0;
        quote.QuoteRequestDate = new Date();
        quote.QuoteLastUpdated = new Date();

        await Quote.createQuote(quote).then(async (response) => {
          this.$log.debug(response);
          if (response == 1) {
            //if this bus has disabled quote approvals and requires a downpayment, collect payment info
            // if (
            //   quote.Bus.BusReqReservApprovals == false &&
            //   quote.Bus.BusDownPmtReq == true
            // ) {
            //   quotesReqPayment.push(quote);
            // }

            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("code.quotesent"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.quotefailed"),
              life: 3000,
            });
          }
         //console.log(totalQuotes)
          if (--totalQuotes == 0) {
            this.resetQuote();
            this.showAddToQuote = false;
            this.showQuoteReviewDialog = false;
            this.submittingQuote = false;

            // if(quotesReqPayment.length>0){
            //  //console.log("collect payments for " + this.quotesReqPayment + "businesses")
            //   this.showCollectPayments = true;
            // }
          }
        });
      });
    },
    async viewDetails(busId) {
      this.$log.debug("fetching business: " + busId);
      this.tempBusiness = await Business.fetchById(busId);
      // this.tempBusiness = Business.query()
      //   .withAllRecursive()
      //   .find(currentBusiness.BusId);
      this.showBusinessDetails = true;
      this.$log.debug(this.tempBusiness);
    },

    async removeServFavorite(favId) {
      this.$log.debug("remove serv favorite " + favId);
      await ServiceFavorite.deleteFavorite(favId).then((response) => {
        this.$log.debug(response);
        if (response == 1) {
          this.setCurrentCustomer();
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.favoritedremove"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.failedtoadd"),
            life: 3000,
          });
        }
      });
    },
    async removeBusFavorite(favId) {
      this.$log.debug("remove bus favorite " + favId);
      await BusinessFavorite.deleteFavorite(favId).then((response) => {
        this.$log.debug(response);
        if (response == 1) {
          this.setCurrentCustomer();
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("code.favoritedremove"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.favoritefailed"),
            life: 3000,
          });
        }
      });
    },

    async viewServDetails(currentService) {
      this.$log.debug(currentService);
      await Service.fetchFullById(currentService.ServId).then((result) => {
        this.tempService = result;
        this.$log.debug(this.tempService);
      });
      this.showServiceDetails = true;
    },
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCurrentCustomer();
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style>
.p-tabview-panel .p-dataview .p-dataview-content {
  background-color: #f8f9fa !important;
}

.event-hidden {
  background-color: F2F2F0;
  border-color: #e9ecef;
  border: 1px solid hsla(0, 0%, 76.9%, 0.25);
  font-size: small;
}
.event-reserved {
  background-color: var(--mr-primary-color);
  border-color: #e9ecef;
  border: 1px solid hsla(0, 0%, 76.9%, 0.25);
  font-size: small;
  color: white;
  cursor:default imprtant!;
}
.event-quoted {
  background-color: #f2bd2c;
  border-color: #e9ecef;
  border: 1px solid hsla(0, 0%, 76.9%, 0.25);
  font-size: small;
}
.event-open {
  background-color: #689f38;
  border-color: #e9ecef;
  border: 1px solid hsla(0, 0%, 76.9%, 0.25);
  color: white;
  font-size: small;
}
</style>
