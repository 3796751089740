/* eslint-disable */
import { Model } from '@vuex-orm/core'
import ServiceFavorite from "./ServiceFavorite"
import BusinessFavorite from "./BusinessFavorite"
import BusinessStaff from "./BusinessStaff"
import Conversation from "./Conversation"
export default class Customer extends Model {
  static entity = 'customers'

  static primaryKey = 'CustId'

  static fields() {
    return {
      CustId: this.uid(),
      CustFirstName: this.attr(null),
      CustLastName: this.attr(null),
      CustAddress: this.attr(null),
      CustCity: this.attr(null),
      CustState: this.attr(null),
      CustZip: this.attr(null),
      CustPhone: this.attr(null),
      CustEmail: this.attr(null),
      CustActive: this.attr(null),
      CustEnrolled: this.attr(null),
      CustIdp: this.attr(null),
      CustProfImage: this.attr(null),
      CustInactiveSince: this.attr(null),
      CustLang: this.attr(null),

      //virtual
      ServiceFavorites: this.hasMany(ServiceFavorite, 'CustId'),
      BusinessFavorites: this.hasMany(BusinessFavorite, 'CustId'),
      CustConvos: this.hasMany(Conversation, 'CustId'),
      BusinessStaffs: this.hasMany(BusinessStaff, 'CustId')
    }
  }

  static fetchAll() {
    this.api().get('/api/customer/')
    return this.all();
  }

  static async fetchByBusId(BusId) {
    Customer.deleteAll();
    //console.log("fetch customers for " + BusId)
   var result = await this.api().get('/api/customers/getBusCustomers/' + BusId);
    //console.log(result)
    return  Customer.query().orderBy('CustLastName').orderBy('CustFirstName').all();
  }

  static async fetchFullById(CustId) {
    Customer.delete(CustId);
    //console.log("Fetching customer by id")
    const result = await this.api().get('/api/customers/' + CustId)
    //console.log(result)
    return this.query()
      .withAllRecursive()
      .find(CustId);
  }
  static async fetchCustomerContact(CustEmail) {
    //console.log("Fetching customer contact by email")
    const result = await this.api().get('/api/customers/getCustomerContact/' + CustEmail).then(async (result) => {
      if (result.response.status == 201) {
        //console.log("success")
        return 1;
      }
      if (result.response.status == 200) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      if (error.response.status == 404) {
        //console.log("not registered yet, create account");
        return 3;
      }

    });
    //console.log('get customer contact response')
    //console.log(result)

    if (result == 3) return null;
    else {
      return this.query()
        .withAllRecursive()
        .where('CustEmail', CustEmail).first();
    }

  }
  static async verifyAcct(CustEmail){
   //console.log("Verifying account", CustEmail)
    const result = await this.api().get('/api/customers/verifyAccount/' + CustEmail)
    return result;
  }
  static async fetchFullByEmail(CustEmail) {
    Customer.deleteAll();
    //console.log("Fetching customer by email")
    const result = await this.api().get('/api/customers/getCustomerByEmail/' + CustEmail).then(async (result) => {
      if (result.response.status == 201) {
        //console.log("success")
        return 1;
      }
      if (result.response.status == 200) {
        //console.log("success")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(async error => {
      //console.log(error.response);
      if (error.response.status == 404) {
        //console.log("not registered yet, create account");
        return 3;
      }
      // if (error.response.status == 403) {
      //   //console.log("verify if its an existing account with no role");
        
      //    await this.api().get('/api/customers/verifyAccount/' + CustEmail).then(async (result) => {
      //     if (result.response.status == 201 || result.response.status == 200 ) {
      //       //console.log("success")
      //       //TODO - TRIGGER FETCH A NEW TOKEN
      //       return 4;
      //     }
      //   }).catch(error => {
      //     //console.log(error.response);
      //     return 3;
      //   });
        
      // }

    });
    //console.log('getCustomerByEmail response')
    //console.log(result)

    if (result == 3) return null;
    else {
      return this.query()
        .withAllRecursive()
        .where('CustEmail', CustEmail).first();
    }

  }
  static async getFullById(CustId) {
    const cust = Customer.query()
      .withAllRecursive()
      .find(CustId);
    if (cust == null)
      return await this.fetchFullById(CustId);
    else
      return cust;

  }

  static async createCustomer(customer) {
    const result = await Customer.api().post('/api/customers/',
      customer).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if (result.response.status == 200) {
          //console.log("duplicate")
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    //console.log('create customer result: ');
    //console.log(result);
    return result;
  }
  static async updateCustomer(customer) {
    //console.log("at update customer")
    var data = new FormData();

    if (customer.NewProfPicture && customer.NewProfPicture.length > 0) {
      for (var i = 0; i < customer.NewProfPicture.length; i++) {
        data.append('image', customer.NewProfPicture[i]);
      }
    }

    if(customer != null){
      data.append("customerString", JSON.stringify(customer))
    }

    const result = await Customer.api().put('/api/customers/' + customer.CustId,
      data).then((result) => {
        //console.log(result)
        if (result.response.status == 204) {
          //console.log("success")
          return 1;
        }
        if (result.response.status == 200) {
          //console.log("duplicate")
          return 1;
        }
        else {
          //console.log("error");
          return 2;
        }
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
    //console.log('update customer result: ');
    //console.log(result);
    return result;
  }

  static async getCurrentCustomer() {
    //console.log('get current customer')
    let custId = localStorage.currentCustomerId;
    if(custId >0){

  
    await this.fetchFullById(custId);
    // //console.log(custId)
    const cust = Customer.query()
      .withAllRecursive(5)
      .find(custId);
    
      return cust;
    }else {
      return null;
    }
 

  }




}

