
/* eslint-disable */

import { Model } from '@vuex-orm/core'
import CustEvent from "./CustEvent"


export default class CustEventRegistry extends Model {
  static entity = 'custeventregistry'

  static primaryKey = 'RegistryId'

  static fields() {
    return {
      RegistryId: this.uid(),
      EventId: this.attr(null),
      RegistryStore: this.attr(null),
      RegistryUrl: this.attr(null),
      
      //VIRTUAL
      Event: this.belongsTo(CustEvent, 'EventId'),
    }
  }
  // static async createCustEventRegistry(custeventregistry) {

  //  //console.log("creating new cust event")
  //   const result = await CustEventRegistry.api().post('/api/CustEventRegistry/',
  //   custeventregistry).then((result) => {
  //       if (result.response.status == 201) {
  //         //console.log("success")
  //         return 1;
  //       }
  //       if (result.response.status == 200) {
  //         //console.log("duplicate")
  //         return 1;
  //       }
  //       else {
  //         //console.log("error");
  //         return 2;
  //       }
  //     }).catch(error => {
  //       //console.log(error.response);
  //       return 2;
  //     });
  //   return result;
  // }

  // static async updateCustEventRegistry(custeventregistry) {
  //   //console.log("updating customer event)
  //   const result = await CustEventRegistry.api().put('/api/CustEventRegistry/' + custeventregistry.EventId,
  //   custeventregistry).then((result) => {
  //       if (result.response.status == 204) {
  //         //console.log("success")
  //         return 1;
  //       }
  //       if (result.response.status == 200) {
  //         //console.log("saved")
  //         return 1;
  //       }
  //       else {
  //         //console.log("error");
  //         return 2;
  //       }
  //     }).catch(error => {
  //       //console.log(error.response);
  //       return 2;
  //     });
  //   return result;
  // }

  // static async fetchByCustId(CustId) {
  //   //CustEvent.deleteAll();
  //   //GuestParty.deleteAll();
  //  //SharedGuestList.deleteAll();
  //  //console.log("fetch guest lists for " + CustId)
  //   var result = await CustEvent.api().get('/api/CustEvent/GetCustomerCustEvents/' + CustId);
  //  //console.log(result)
  //   return CustEvent.query()
  //     .withAllRecursive()
  //     .orderBy('EventId', 'desc').all();
  // }

  // static async deleteCustEvent(id) {
  //   //console.log("delete guest list " + id)
  //   var result = await CustEvent.api().delete('/api/CustomerEvent/' + id).then((result) => {
  //     if (result.response.status == 204) {
  //       //console.log("success")
  //       return 1;
  //     }
  //     if (result.response.status == 200) {
  //       //console.log("duplicate")
  //       return 1;
  //     }
  //     else {
  //       //console.log("error");
  //       return 2;
  //     }
  //   }).catch(error => {
  //     //console.log(error.response);
  //     return 2;
  //   });
  //   return result;
  // }


}

