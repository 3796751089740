
<template>
  <div>
    <div class="col-12 center-text list-bg p-5">
      <div class="col-12 md:col-6 lg:col-6">
        <h1>
          {{ $t("listmybusiness.welcome") }} <br />
          <img
            alt="logo"
            src="@/assets/TextNoBack.png"
            height="70"
            class="mt-4"
          /><br />
          <a href="#pricing">
          <Button
            v-if="requestedLogin == false"
            :label="$t('menu.getstarted')"
            class="p-button-raised p-button-lg p-button-primary"
          />
          </a>
          <Button
            v-if="requestedLogin"
            icon="pi pi-spin pi-spinner"
            :label="$t('menu.getstarted')"
            class="p-button-raised p-button-lg p-button-primary"
            disabled
          />
        </h1>
      </div>
    </div>
    <div class="col-12 purple ">
      <h1>{{ $t("listmybusiness.businesspartner") }}</h1>
      <p style="font-size: large; padding-right: 2rem; padding-left: 2rem">
        {{ $t("listmybusiness.thankyou") }}
      </p>
    </div>
    <business-features></business-features>
    <div id="pricing" class="pt-2 purple">
      <h1>{{ $t("listmybusiness.pricing") }}</h1>
 <div class="flex flex-row flex-wrap justify-content-center pb-5">
<Card class="m-3 flex-grow-1 service-card" >
    <template #content >
      <div >
        <div style="color:var(--mr-primary-color);font-size: 2rem; font-weight:bold">FREE</div>
        <div class=mt-5>
        <span style="font-size:3em; font-weight: bold; ">
          ${{ currentFreeDef.SubDefPriceMo }}
        </span>
        {{ $t("listmybusiness.amonth") }} 
        </div>
  
      <div class="m-2" style="text-align:start">
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.businesslisting") }}</div>

      
      </div>
    </div>
  
 <div>
      <Button
            v-if="currentFreeDef != null"
            :label="$t('listmybusiness.getstarted') "
            class="p-button-raised p-button-lg p-button-primary mb-2 mt-5"
            @click.stop="startSignUp('FREE');
          $router.push({
            name: 'RequestedGetStarted',
            params: { requestedGetStarted: 'true' },
          });"
          />
          <div style="font-size: 1rem">{{ $t("listmybusiness.alwaysfree") }} </div>
 </div>
      <!-- </div> -->
    </template>
</Card>
<Card class="m-3 flex-grow-1 service-card">
  <template #content >
      <div >
        <div style="color:var(--mr-primary-color);font-size: 2rem; font-weight:bold">BASIC</div>
        <div class=mt-5>
        <span style="font-size:3em; font-weight: bold; ">
          ${{ currentBasicDef.SubDefPriceMo }}
        </span>
        {{ $t("listmybusiness.amonth") }} 
        </div>
  
  
      <div class=" m-2" style="text-align:start">
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.businesslisting") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.serviceportfolio") }} </div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check  mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.availabilitycalendar") }}</div>
      </div>
  
      </div>
      <div>
      <Button
            v-if="currentBasicDef != null"
            :label="$t('listmybusiness.tryforfree') "
            class="p-button-raised p-button-lg p-button-primary mb-2 mt-5"
            @click.stop="startSignUp('BASIC');
          $router.push({
            name: 'RequestedGetStarted',
            params: { requestedGetStarted: 'true' },
          });"
          />
          <div style="font-size: 1rem">{{$t('listmybusiness.freetrialdays') }}</div>
      </div>
    </template>
</Card>
<Card class="m-3 flex-grow-1 service-card">
  <template #content >
      <div >
        <div style="color:var(--mr-primary-color);font-size: 2rem; font-weight:bold">PREMIUM</div>
        <div class=mt-5>
        <span style="font-size:3em; font-weight: bold; ">
          ${{ currentPremiumDef.SubDefPriceMo }}
        </span>
        {{ $t("listmybusiness.amonth") }} 
        </div>
      </div>

        <div class="m-2" style="text-align:start">
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.businesslisting") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.serviceportfolio") }} </div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check  mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.availabilitycalendar") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.directquotes") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.reservationsandappointments") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.onlinestore") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.onlinepay") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.directmessages") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.customerreviews") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.clientandstaffmgmt") }}</div>
        </div>
  
 <div>
      <Button
            v-if="currentPremiumDef != null"
            :label="$t('listmybusiness.tryforfree') "
            class="p-button-raised p-button-lg p-button-primary mb-2 mt-5"
            @click.stop="startSignUp('PREMIUM');
          $router.push({
            name: 'RequestedGetStarted',
            params: { requestedGetStarted: 'true' },
          });"
          />
          <div style="font-size: 1rem">{{$t('listmybusiness.freetrialdays') }}</div>
 </div>
    </template>
</Card>
    </div>
    </div>
  
    <div class="col-12">
      <h2>{{ $t("listmybusiness.convince") }}</h2>
      <div class="grid">
        <div class="col-12 md:col-6">
          <a
            :href="'https://www.youtube.com/channel/UC0m6lRsUpkU58Oxpu2o6tuA'"
            target="_blank"
            style="text-decoration: none; color: #551a8b"
          >
            <h3>
              <i class="pi pi-video" style="font-size: 30px"></i> <br />
              {{ $t("listmybusiness.video") }}
            </h3>
          </a>
        </div>

        <div
          class="col-12 md:col-6 pointer"
          @click.stop="showSupportTicket = true"
        >
          <h3 style="color: #551a8b">
            <i class="pi pi-briefcase" style="font-size: 30px"></i><br />
            {{ $t("listmybusiness.demo") }}
          </h3>
        </div>
      </div>
    </div>
  </div>
  <!-- Show support dialog -->
  <Dialog
    v-model:visible="showSupportTicket"
    :modal="true"
    :contentStyle="{ overflow: 'scroll' }"
    :header="$t('subscriptions.requestingdemo')"
    :dismissableMask="true"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
  >
    <div style="text-align: left">
      <label>
        {{ $t("subscriptions.provideinfo") }}
      </label>
      <div class="p-fluid formgrid grid text-left mt-2">
        <div class="field col-12">
          <label for="busname"
            >{{ $t("listmybusiness.businessname") }}
            <span class="required-star">*</span></label
          >
          <InputText
            id="busname"
            type="text"
            v-model="supportTicket.TicketMessage"
            autofocus
          />
        </div>

        <div class="field col-12 md:col-6">
          <label for="firstName"
            >{{ $t("profile.firstname") }}:
            <span class="required-star">*</span></label
          >
          <InputText
            id="first"
            v-model="supportTicket.TicketFirstName"
          ></InputText>
        </div>
        <div class="field col-12 md:col-6">
          <label for="lastName"
            >{{ $t("profile.lastname") }}:
            <span class="required-star">*</span></label
          >
          <InputText
            id="lastName"
            v-model="supportTicket.TicketLastName"
          ></InputText>
        </div>
        <div class="field col-12 md:col-6">
          <label for="custphone"
            >{{ $t("profile.phonenumber") }}:
            <span class="required-star">*</span></label
          >
          <InputMask
            id="custphone"
            mask="(999) 999-9999"
            v-model="supportTicket.TicketPhone"
            placeholder="(999) 999-9999"
          />
        </div>
      </div>

      <!-- <Textarea
          class="mt-2"
          v-model="ticketMessage"
          :placeholder="$t('message.type')"
          :autoResize="true"
          rows="5"
          cols="30"
          style="width: 100%"
        />-->
    </div>
    <div class="flex justify-content-between">
      <Button
        :label="$t('profile.cancel')"
        icon="pi pi-times"
        iconPos="left"
        @click.stop="
          showSupportTicket = false;
          newTicketText = '';
          supportTicket = {};
        "
        class="mr-2 p-button-danger p-button-sm p-button-raised"
      />
      <Button
        v-if="!submitting"
        :label="$t('menu.send')"
        icon="pi pi-play"
        iconPos="left"
        @click.stop="validateFeedback"
        class="mr-2 p-button-success p-button-sm p-button-raised"
      />
      <Button
        v-else
        :label="$t('menu.send')"
        icon="pi pi-spin pi-spinner"
        iconPos="left"
        disabled
        class="mr-2 p-button-success p-button-sm p-button-raised"
      />
    </div>
  </Dialog>

  <!-- Sign up dialog -->
  <Sidebar
    class="signup-bg"
    v-model:visible="showSignUp"
    :baseZIndex="1000"
    position="full"
    @hide="
      resetBusinessSignUp();
      requestedLogin = false;
    "
  >
    <!-- <Dialog
    id="signUpDialog"
    v-model:visible="showSignUp"
    :style="{ width: '100vw' }"
    :modal="true"
    :maximizable="true"
  > -->
    <div class="p-3">
      <div style="text-align: center">
        <div style="font-size: x-large">
          {{ $t("listmybusiness.welcomer") }}
        </div>
      </div>
      <div v-if="showBusinessInformation">
        <div class="purple pb-2 pt-2 mb-2">
          {{ $t("listmybusiness.step2") }}
        </div>
        <div class="pb-2" style="font-size: small">
          {{ $t("listmybusiness.stepall") }}
        </div>

        <div class="p-fluid formgrid grid text-left">
          <div class="field col-12">
            <label for="busname"
              >{{ $t("listmybusiness.businessname") }}
              <span class="required-star">*</span></label
            >
            <InputText
              id="busname"
              type="text"
              v-model="businessToEnroll.BusName"
              autofocus
            />
          </div>
          <div class="field col-12">
            <label for="buscat"
              >{{ $t("listmybusiness.businesscategory") }}
              <span class="required-star">*</span></label
            >
            <Dropdown
              id="buscat"
              v-model="businessToEnroll.BusCategory"
              :options="serviceTypes"
              optionValue="ServTypeTitle"
              :placeholder="$t('services.selectone')"
            >
              <template #value="slotProps">
                <span v-if="slotProps.value == null">
                  {{ slotProps.placeholder }}
                </span>
                <span v-else-if="typeof slotProps.value !== 'String'">
                  {{ $t($getServiceType(slotProps.value)) }}
                </span>
                <span v-else>
                  {{ $t($getServiceType(slotProps.value.ServTypeTitle)) }}
                </span>
              </template>
              <template #option="slotProps">
                <span>{{
                  $t($getServiceType(slotProps.option.ServTypeTitle))
                }}</span>
              </template>
            </Dropdown>
          </div>
          <div class="field col-12">
            <label for="aboutus"
              >{{ $t("listmybusiness.busdescription") }}
              <span class="required-star">*</span></label
            >
            <Textarea
              id="aboutus"
              rows="4"
              v-model="businessToEnroll.BusDesc"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="busphone"
              >{{ $t("listmybusiness.phone") }}
              <span class="required-star">*</span></label
            >
            <!-- <InputText id="phone" type="tel" v-model="businessToEnroll.BusPhone" /> -->
            <InputMask
              id="busphone"
              mask="(999) 999-9999"
              v-model="businessToEnroll.BusPhone"
              placeholder="(999) 999-9999"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="busemail"
              >{{ $t("listmybusiness.email") }}
              <span class="required-star">*</span></label
            >
            <InputText
              id="busemail"
              type="email"
              v-model="businessToEnroll.BusEmail"
              placeholder="something@something.com"
            />
          </div>

          <div class="field col-12">
            <label
              >{{ $t("listmybusiness.address") }}
              <span class="required-star">*</span></label
            >
            <vue-google-autocomplete
              id="busaddress"
              classname="p-inputtext"
              v-on:placechanged="setBusinessAddress"
              :country="['us']"
              v-on:focus="busAddrValidated = false"
              v-on:no-results-found="invalidAddressEntered"
              :placeholder="$t('listmybusiness.addressplaceholder')"
            >
            </vue-google-autocomplete>
          </div>
          <div class="field col-12 md:col-6 lg:col-4">
            <label for="city"
              >{{ $t("profile.city") }}
              <span class="required-star">*</span></label
            >
            <InputText
              id="buscity"
              type="text"
              v-model="businessToEnroll.BusCity"
            />
          </div>
          <div class="field col-6 lg:col-4">
            <label for="state"
              >{{ $t("profile.state") }}
              <span class="required-star">*</span></label
            >
            <Dropdown
              id="busstate"
              :options="compStates"
              optionLabel="code"
              optionValue="code"
              v-model="businessToEnroll.BusState"
            />
          </div>
          <div class="field col-6 lg:col-4">
            <label for="zip"
              >{{ $t("profile.zip") }}
              <span class="required-star">*</span></label
            >
            <InputText
              id="buszip"
              type="text"
              v-model="businessToEnroll.BusZip"
            />
          </div>
          <div class="field col-6">
            <label for="salestax"
              >{{ $t("custreserv.custsales") }}
              <span class="required-star">*</span></label
            >
            <InputText
              id="salestax"
              type="number"
              v-model.number="businessToEnroll.BusSalesTaxPercent"
              placeholder="%"
            />
          </div>
          <div class="field col-6">
            <label for="occupancy"
              >{{ $t("listmybusiness.max") }}
              <span class="required-star">*</span></label
            >
            <InputText
              v-if="businessToEnroll.BusOccupancyDisabled != true"
              id="occupancy"
              type="number"
              v-model.number="businessToEnroll.BusOccupancy"
            /><br>
            <Checkbox
            
              v-model="businessToEnroll.BusOccupancyDisabled"
              :binary="true"
            />&nbsp;{{  $t("businessprofile.doesnotapply") }}
          </div>
          <!-- <div class="col-12" v-if="errors.length > 0">
            <Message severity="error" style="text-align: left">
              <ul>
                <li v-for="error of errors" :key="error">{{ error }}</li>
              </ul></Message
            >
          </div> -->
        </div>
        <div class="flex justify-content-between">
          <Button
            :label="$t('menu.back')"
            icon="pi pi-arrow-left"
            @click.stop="
              showBusinessInformation = false;
              showOwnerInformation = true;
            "
            class="p-button-secondary"
          />
          <Button
            v-if="!submittingBusiness"
            :label="$t('menu.next')"
            icon="pi pi-arrow-right"
            iconPos="right"
            @click.stop="validateBusinessInfo()"
          />
          <Button
            v-else
            :label="$t('menu.next')"
            icon="pi pi-spin pi-spinner"
            iconPos="right"
            disabled
          />
        </div>
      </div>
      <div v-else-if="showOwnerInformation" style="text-align: center">
        <div class="purple pb-2 pt-2 mb-2">
          {{ $t("listmybusiness.step1") }}
        </div>
        <!-- No user logged in, and no requested login -->
        <div v-if="!userLoggedIn && !requestedLogin">
          <p>{{ $t("listmybusiness.step1b") }}</p>
          <Button
            :label="$t('listmybusiness.setupAccount')"
            icon="pi pi-arrow-right"
            @click.stop="setUpAccount()"
            class="p-button-raised p-button-primary"
          />
        </div>

        <!-- User logged in, not requested login -->
        <div
          v-if="
            userLoggedIn &&
            currentCustomerId > 0 &&
            currentCustomer != null &&
            !requestedLogin
          "
        >
          <p>{{ $t("listmybusiness.step1a") }}</p>
          <div class="card">
            <h3>{{ $t("listmybusiness.signedin") }}</h3>
            <img
              v-if="currentCustomer.CustProfImage != null"
              :src="imageHostingURL + currentCustomer.CustProfImage"
              :alt="currentCustomer.CustProfImage"
              width="100"
              class="mr-3"
            /><br />
            <b>{{ $t("custreserv.custname") }}: </b>
            {{ currentCustomer.CustFirstName }}
            {{ currentCustomer.CustLastName }} <br />
            <b>{{ $t("message.email") }}: </b> {{ currentCustomer.CustEmail }}
            <p>
              {{ $t("listmybusiness.mes") }}
            </p>
          </div>
          <div class="flex justify-content-center mb-2">
            <Button
              :label="$t('listmybusiness.setupAccount')"
              icon="pi pi-arrow-right"
              @click.stop="setNewCustomer()"
              class="p-button-raised p-button-primary"
            />

            <!-- </div> -->
          </div>
          <div class="flex justify-content-between">
            <Button
              :label="$t('menu.back')"
              icon="pi pi-arrow-left"
              @click.stop="
                showBusinessInformation = false;
                showOwnerInformation = true;
              "
              class="p-button-secondary"
            />
            <Button
              :label="$t('menu.next')"
              icon="pi pi-arrow-right"
              iconPos="right"
              @click.stop="validateOwnerInfo()"
            />
          </div>
        </div>

        <!-- Requested Login, but no profile exists -->
        <div
          v-if="
            userMissingProfile == true &&
            currentCustomerId == 0 &&
            loading == false &&
            loadingCurrentCustomer == false
          "
        >
          <h3>{{ $t("listmybusiness.signedin") }}</h3>
          <b>{{ $t("message.email") }}: </b> {{ currentADUser.username }}

          <p>
            {{ $t("listmybusiness.stepall") }}
          </p>
          <div class="p-fluid formgrid grid text-left mt-3">
            <div class="field col-12 md:col-6">
              <label for="firstName"
                >{{ $t("profile.firstname") }}:
                <span class="required-star">*</span></label
              >
              <InputText
                id="first"
                v-model="businessToEnroll.BusOwnerCust.CustFirstName"
              ></InputText>
            </div>
            <div class="field col-12 md:col-6">
              <label for="lastName"
                >{{ $t("profile.lastname") }}:
                <span class="required-star">*</span></label
              >
              <InputText
                id="lastName"
                v-model="businessToEnroll.BusOwnerCust.CustLastName"
              ></InputText>
            </div>
            <div class="field col-12 md:col-6">
              <label for="custphone"
                >{{ $t("profile.phonenumber") }}:
                <span class="required-star">*</span></label
              >
              <InputMask
                id="custphone"
                mask="(999) 999-9999"
                v-model="businessToEnroll.BusOwnerCust.CustPhone"
                placeholder="(999) 999-9999"
              />
            </div>

            <div class="field col-12">
              <label
                >{{ $t("listmybusiness.address") }}:
                <span class="required-star">*</span></label
              >
              <vue-google-autocomplete
                id="custaddress"
                classname="p-inputtext"
                v-on:placechanged="setCustomerAddress"
                :placeholder="$t('listmybusiness.addressplaceholder')"
              >
              </vue-google-autocomplete>
            </div>
            <div class="field col-12 md:col-6">
              <label for="city"
                >{{ $t("profile.city") }}: <span class="required-star">*</span>
              </label>
              <InputText
                id="custcity"
                type="text"
                v-model="businessToEnroll.BusOwnerCust.CustCity"
              />
            </div>
            <div class="field col-6">
              <label for="state"
                >{{ $t("profile.state") }}: <span class="required-star">*</span>
              </label>
              <Dropdown
                :options="compStates"
                optionLabel="code"
                optionValue="code"
                id="custstate"
                v-model="businessToEnroll.BusOwnerCust.CustState"
              />
            </div>
            <div class="field col-6">
              <label for="zip"
                >{{ $t("profile.zip") }}: <span class="required-star">*</span>
              </label>
              <InputText
                id="custzip"
                type="text"
                v-model="businessToEnroll.BusOwnerCust.CustZip"
              />
            </div>
          </div>
          <div class="flex flex-wrap justify-content-around">
            <Button
              :label="$t('profile.cancel')"
              icon="pi pi-arrow-left"
              @click.stop="resetBusinessSignUp()"
              class="p-button-secondary p-button-sm"
            />

            <Button
              :label="$t('menu.next')"
              class="p-button-sm"
              icon="pi pi-arrow-right"
              iconPos="right"
              @click.stop="validateOwnerInfo()"
            />
          </div>
          <Button
            :label="$t('listmybusiness.anotheraccount')"
            icon="pi pi-user"
            @click.stop="setUpAccount()"
            class="mt-2 p-button-raised p-button-primary p-button-sm"
          />
        </div>
      </div>
      <div v-else-if="showPaymentInformation">
        <h3>{{ $t("listmybusiness.step3") }}</h3>
        <p>
          <small
            ><em>{{ $t("listmybussines.messa") }}</em></small
          >
        </p>

        <div></div>
        <div class="flex justify-content-between">
          <Button
            :label="$t('menu.back')"
            icon="pi pi-times"
            @click.stop="
              showOwnerInformation = true;
              showPaymentInformation = false;
            "
            class="p-button-text p-button-primary"
          />
          <Button label="Submit" icon="pi pi-check" @click.stop="submit()" />
        </div>
      </div>
      <div v-else-if="showConfiramtion"><p>Success</p></div>
      <div style="text-align: center">
        <i
          v-if="loading || loadingCurrentCustomer"
          class="pi pi-spin pi-spinner"
          style="font-size: 2rem"
        ></i>
      </div>
    </div>
    <!-- </Dialog> -->
  </Sidebar>
</template>
<script>
import Customer from "../store/Models/Customer";
import EventTypeDef from "../store/Models/EventTypeDef";
import ServiceTypeDef from "../store/Models/ServiceTypeDef";
import AttendeeCountDef from "../store/Models/AttendeeCountDef";
import SubscriptionDefs from "../store/Models/SubscriptionDefs";
import Business from "../store/Models/Business";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import BusinessFeatures from "../components/BusinessFeatures.vue";
import SupportTicket from "../store/Models/SupportTicket";

import { mapState } from "vuex";
import { mapActions } from "vuex";
import { onMounted } from "vue";
let states = require("../data/states.js");
export default {
  name: "ListMyBusiness",
  components: { VueGoogleAutocomplete, BusinessFeatures },
  props: {
    requestedLoginProp: { default: "false", type: String },
    requestedGetStarted: { default: "false", type: String },
  },
  data() {
    return {
      currentSubDef: {},
      currentFreeDef:{},
      currentBasicDef:{},
      currentPremiumDef:{},
      newSubLevel: null,
      loading: false,
      serviceTypes: [],
      loadingCurrentCustomer: false,
      accountCreated: false,
      redirecturl: process.env.VUE_APP_MYRESERVS_URL,
      currentCustomer: null,
      showNewUser: false,
      showSignUp: false,
      submittingBusiness: false,
      showBusinessInformation: false,
      showOwnerInformation: false,
      showPaymentInformation: false,
      showConfiramtion: false,
      galleryErrorMessage: "",
      showGalleryErrorMessage: false,
      galleryCountLeft: 10,
      hideLogoUpload: false,
      logoFileName: null,
      supportTicket: {},
      errors: [],
      requestedLogin: false,
      submitting: false,
      custAddrValidated: false,
      invalidAddress: true,
      busAddrValidated: false,
      showSupportTicket: false,
      businessToEnroll: {
        BusId: 0,
        BusPhone: "",
        BusName: "",
        BusAddress: "",
        BusCity: "",
        BusZip: "",
        BusState: "",
        BusEmail: "",
        BusWebUrl: "",
        BusFbUrl: "",
        BusTwitUrl: "",
        BusInstUrl: "",
        BusPinUrl: "",
        BusDesc: "",
        BusOccupancy: 0,
        BusCategory: null,
        BusOwnerCustId: 0,
        BusSalesTaxPercent: null,
        BusLogo: "",
        BusActive: false,
        BusVisible: false,
        EventTypes: [],
        AttendeeCounts: [],
        BusinessImages: [],
        NewBusinessImages: [],
        NewLogo: [],
        StringEventTypes: [],
        StringAttendeeCounts: [],

        BusOwnerCust: {
          CustId: 0,
          CustFirstName: "",
          CustLastName: "",
          CustEmail: "",
          CustAddress: "",
          CustCity: "",
          CustState: "",
          CustZip: "",
          CustPhone: "",
          CustActive: true,
          CustEnrolled: new Date(),
          CustIDP: null,
          CustProfImage: null,
          CustInactiveSince: null,
        },
        Subscription: {
          SubCardNumber: "",
          SubCardExp: "",
          SubCardCVV: "",
          SubCardZip: "",
        },
      },
    };
  },
  setup() {
    let stripe = null;

    onMounted(async () => {
      /* global Stripe */
      //this.$log.debug("onMounted");
      stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    });

    function redirect(sessionId) {
      stripe
        .redirectToCheckout({
          sessionId: sessionId,
        })
        .then(function (result) {
          this.$log.debug(result);
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
    }
    return { redirect };
  },
  async mounted() {
    this.$log.debug("list my business mounted");
    this.$log.debug(this.redirecturl);
    this.$log.debug(this.$route);
    this.$log.debug(this.$route.params.requestedLoginProp);
    this.$log.debug(this.requestedLogin);
    this.$log.debug(this.currentCustomerId);
    this.$log.debug(this.currentADUser);
    this.$log.debug(this.userMissingProfile);

    this.currentSubDef = await SubscriptionDefs.fetchCurrentSubDef();
    this.currentFreeDef = await SubscriptionDefs.fetchCurrentFree();
    this.currentBasicDef = await SubscriptionDefs.fetchCurrentBasic();
    this.currentPremiumDef = await SubscriptionDefs.fetchCurrentPremium();
    this.$log.debug(this.currentSubDef);

    if (
      this.$route.params.requestedLoginProp == "true" &&
      this.currentCustomerId > 0
    ) {
      this.$log.debug("Requested Login");
      this.newSubLevel = window.sessionStorage.getItem("requestedlevel");
      this.showSignUp = true;
      this.requestedLogin = true;
      this.loadingCurrentCustomer = true;
      this.loading = true;
      this.setCurrentCustomer();
    } else if (this.currentCustomerId > 0) {
      this.loadingCurrentCustomer = true;
      this.setCurrentCustomer();
    } else if (
      this.$route.params.requestedLoginProp == "true" &&
      this.userMissingProfile == true
    ) {
      this.newSubLevel = window.sessionStorage.getItem("requestedlevel");
      this.loading = false;
      this.loadingCurrentCustomer = false;
      this.showOwnerInformation = true;
      this.showSignUp = true;
    } else if (this.$route.params.requestedLoginProp == "true") {
      this.newSubLevel = window.sessionStorage.getItem("requestedlevel");
      this.showSignUp = true;
      this.requestedLogin = true;
      this.loading = true;
    }

    this.eventTypes = await EventTypeDef.fetchAll();
    this.attendeeCounts = await AttendeeCountDef.fetchAll();
    this.serviceTypes = await ServiceTypeDef.fetchAll();
  },
  methods: {
    ...mapActions(["setCurrentBusiness", "refreshUserData"]),
    validateFeedback() {
      this.$log.debug("validate customer ticket");
      this.submitting = true;
      let errors = 0;
      //verify required values

      if (this.supportTicket.TicketMessage == null) {
        this.errors.push("missing bus name");
      }
      if (this.supportTicket.TicketFirstName == null) {
        this.errors.push("missing first name");
      }
      if (this.supportTicket.TicketLastName == null) {
        this.errors.push("missing last name");
      }
      if (this.supportTicket.TicketPhone == null) {
        this.errors.push("missing phone number");
      }

      if (errors == 0) {
        this.supportTicket.TicketCat = "Demo Request";
        this.$log.debug(this.supportTicket);
        this.sendNewTicket();
      } else {
        this.submitting = false;
      }
    },
    async sendNewTicket() {
      this.$log.debug("submit ticket");
      this.$log.debug(this.supportTicket);
      await SupportTicket.createNewTicket(this.supportTicket, this.lang).then(
        async (response) => {
          this.$log.debug(response);
          if (response) {
            this.resetFeedback();
            this.$toast.add({
              severity: "success",
              summary: this.$t("custreserv.success"),
              detail: this.$t("subscriptions.requestsuccess"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error!",
              detail: this.$t("subscriptions.requestfailed"),
              life: 3000,
            });
          }
          this.submitting = false;
        }
      );
    },
    resetFeedback() {
      this.supportTicket = {};
      this.showSupportTicket = false;
      this.errors = [];
    },
    async startSignUp(level) {
      this.$log.debug("New business registration" + level);
      this.$log.debug("Reset new business fields");
      this.resetBusinessSignUp();

      this.newSubLevel = level;
     
      if (!this.currentCustomerId > 0) {
         this.$log.debug("Show own information, show business signup");
        this.showOwnerInformation = true;
      } else {
        this.$log.debug("User logged in, show bus information, show business signup");
        this.showBusinessInformation = true;
      }
      this.showSignUp = true;
    },
    setCustomerAddress: function (addressData) {
      this.$log.debug(addressData);
      this.businessToEnroll.BusOwnerCust.CustAddress =
        addressData.street_number + " " + addressData.route;
      this.businessToEnroll.BusOwnerCust.CustCity = addressData.locality;
      this.businessToEnroll.BusOwnerCust.CustState =
        addressData.administrative_area_level_1;
      this.businessToEnroll.BusOwnerCust.CustZip = addressData.postal_code;
      this.custAddrValidated = true;
    },
    setBusinessAddress: function (addressData) {
      this.$log.debug(addressData);
      if (typeof addressData !== "undefined") {
        this.businessToEnroll.BusAddress =
          addressData.street_number + " " + addressData.route;
        this.businessToEnroll.BusCity = addressData.locality;
        this.businessToEnroll.BusState =
          addressData.administrative_area_level_1;
        this.businessToEnroll.BusZip = addressData.postal_code;
        this.busAddrValidated = true;
        this.invalidAddress = false;
      } else {
        this.invalidAddress = true;
        this.$log.debug("Invalid Address");
        this.$log.debug(this.invalidAddress);
      }
    },
    setUpAccount() {
      window.sessionStorage.setItem("redirecturl", "ListMyBusiness/true");
      window.sessionStorage.setItem("requestedlogin", "true");
      window.sessionStorage.setItem("requestedlevel", this.newSubLevel);
      this.$router.push({
        name: "Login",
      });
    },
    async setNewCustomer() {
      await this.$SignOut().then(async () => {
        window.sessionStorage.setItem("redirecturl", "ListMyBusiness/true");
        window.sessionStorage.setItem("requestedlogin", "true");
        window.sessionStorage.setItem("requestedlevel", this.newSubLevel);
        this.$router.push({
          name: "Login",
        });
        //this.$RedirectSignIn(this.redirecturl + "ListMyBusiness/true");
      });
    },
    resetBusinessSignUp() {
      this.loading = false;
      this.newSubLevel = null;
      this.loadingCurrentCustomer = false;
      //this.requestedLogin = false;
      this.showSignUp = false;
      this.showBusinessInformation = false;
      this.submittingBusiness = false;
      this.showOwnerInformation = false;
      this.showPaymentInformation = false;
      this.invalidAddress = true;
      this.busAddrValidated = false;
      this.businessToEnroll = {
        BusId: 0,
        BusPhone: "",
        BusName: "",
        BusAddress: "",
        BusCity: "",
        BusZip: "",
        BusState: "",
        BusEmail: "",
        BusWebUrl: "",
        BusFbUrl: "",
        BusTwitUrl: "",
        BusInstUrl: "",
        BusPinUrl: "",
        BusDesc: "",
        BusOccupancy: 0,
        BusCategory: null,
        BusOwnerCustId: 0,
        BusSalesTaxPercent: null,
        BusLogo: "",
        BusActive: false,
        BusVisible: false,
        EventTypes: [],
        AttendeeCounts: [],
        BusinessImages: [],
        NewBusinessImages: [],
        NewLogo: [],
        StringEventTypes: [],
        StringAttendeeCounts: [],
        BusOwnerCust: {
          CustId: 0,
          CustFirstName: "",
          CustLastName: "",
          CustEmail: "",
          CustAddress: "",
          CustCity: "",
          CustState: "",
          CustZip: "",
          CustPhone: "",
          CustActive: true,
          CustEnrolled: new Date(),
          CustIDP: null,
          CustProfImage: null,
          CustInactiveSince: null,
        },
        Subscription: {
          SubCardNumber: "",
          SubCardExp: "",
          SubCardCVV: "",
          SubCardZip: "",
        },
      };
    },
    invalidAddressEntered() {
      document.getElementById("busaddress").classList.add("p-invalid");
      this.invalidAddress = true;
      this.busAddrValidated = false;
    },
    validateBusinessInfo() {
      this.submittingBusiness = true;
      this.errors = [];
      this.$log.debug(document.getElementById("busaddress").value);
      this.$log.debug(this.invalidAddress);
      if (this.invalidAddress == true) {
        this.businessToEnroll.BusAddress =
          document.getElementById("busaddress").value;
      }
      this.$log.debug(this.businessToEnroll);
      //verify required values
      if (this.businessToEnroll.BusName != null && this.businessToEnroll.BusName.length == 0) {
        this.errors.push(this.$t("listmybusiness.rebusname"));
        document.getElementById("busname").classList.add("p-invalid");
      }
      if (this.businessToEnroll.BusDesc != null && this.businessToEnroll.BusDesc.length == 0) {
        this.errors.push(this.$t("listmybusiness.reabout"));
        document.getElementById("aboutus").classList.add("p-invalid");
      }

      if (this.businessToEnroll.BusPhone != null && this.businessToEnroll.BusPhone.length == 0) {
        this.errors.push(this.$t("listmybusiness.rephone"));
        document.getElementById("busphone").classList.add("p-invalid");
      }
      if (this.businessToEnroll.BusCategory != null && this.businessToEnroll.BusCategory.length == 0) {
        this.errors.push(this.$t("listmybusiness.recat"));
        document.getElementById("buscat").classList.add("p-invalid");
      }
      if (this.businessToEnroll.BusEmail != null &&
        this.businessToEnroll.BusEmail.length == 0 ||
        !this.$validateEmail(this.businessToEnroll.BusEmail)
      ) {
        this.errors.push(this.$t("listmybusiness.reemail"));
        document.getElementById("busemail").classList.add("p-invalid");
      }
      if (this.businessToEnroll.BusAddress != null && this.businessToEnroll.BusAddress.length == 0) {
        this.errors.push(this.$t("listmybusiness.readdress"));
        document.getElementById("busaddress").classList.add("p-invalid");
      }
      if (this.businessToEnroll.BusCity != null && this.businessToEnroll.BusCity.length == 0) {
        this.errors.push(this.$t("listmybusiness.recity"));
        document.getElementById("buscity").classList.add("p-invalid");
      }
      if (this.businessToEnroll.BusState != null && this.businessToEnroll.BusState.length == 0) {
        this.errors.push(this.$t("listmybusiness.readdress"));
        document.getElementById("busstate").classList.add("p-invalid");
      }
      if (this.businessToEnroll.BusZip != null && this.businessToEnroll.BusZip.length == 0) {
        this.errors.push(this.$t("listmybusiness.zip"));
        document.getElementById("buszip").classList.add("p-invalid");
      }
      if (
        this.businessToEnroll.BusOccupancy < 1 &&
        this.businessToEnroll.BusOccupancyDisabled != true
      ) {
        this.errors.push(this.$t("listmybusiness.maxoccup"));
        document.getElementById("occupancy").classList.add("p-invalid");
      }
      if (this.businessToEnroll.BusSalesTaxPercent == null) {
        this.errors.push(this.$t("custreserv.custsales"));
        document.getElementById("salestax").classList.add("p-invalid");
      }

      if (
        this.businessToEnroll.BusOwnerCust == null ||
        !this.businessToEnroll.BusOwnerCustId > 0
      ) {
        this.businessToEnroll.BusOwnerCustId = this.currentCustomerId;
        this.businessToEnroll.BusOwnerCust = this.currentCustomer;
      }

      if (this.errors.length == 0) {
        this.businessSignUp();
      } else {
       //console.log("validation errors")
        this.submittingBusiness = false;
        this.$log.debug(this.errors);
      }
    },
    async validateOwnerInfo() {
      this.errors = [];
      //verify required values if new user

      if (this.currentCustomer == null) {
        this.businessToEnroll.BusOwnerCust.CustAddress =
          document.getElementById("custaddress").value;

        this.$log.debug(this.businessToEnroll.BusOwnerCust);
        if (this.businessToEnroll.BusOwnerCustId == 0) {
          if (this.businessToEnroll.BusOwnerCust.CustFirstName.length == 0) {
            this.errors.push(this.$t("listmybusiness.refname"));
            document.getElementById("first").classList.add("p-invalid");
          }
          if (this.businessToEnroll.BusOwnerCust.CustLastName.length == 0) {
            this.errors.push(this.$t("listmybusiness.relname"));
            document.getElementById("lastName").classList.add("p-invalid");
          }

          if (this.businessToEnroll.BusOwnerCust.CustPhone.length == 0) {
            this.errors.push(this.$t("listmybusiness.rephone"));
            document.getElementById("custphone").classList.add("p-invalid");
          }
          if (this.businessToEnroll.BusOwnerCust.CustAddress.length == 0) {
            this.errors.push(this.$t("listmybusiness.readdress"));
            document.getElementById("custaddress").classList.add("p-invalid");
          }
          if (this.businessToEnroll.BusOwnerCust.CustCity.length == 0) {
            this.errors.push(this.$t("listmybusiness.recity"));
            document.getElementById("custcity").classList.add("p-invalid");
          }
          if (this.businessToEnroll.BusOwnerCust.CustState.length == 0) {
            this.errors.push(this.$t("listmybusiness.restate"));
            document.getElementById("custstate").classList.add("p-invalid");
          }
          if (this.businessToEnroll.BusOwnerCust.CustZip.length == 0) {
            this.errors.push(this.$t("listmybusiness.readdress"));
            document.getElementById("custzip").classList.add("p-invalid");
          }

          this.businessToEnroll.BusOwnerCust.CustEmail =
            this.currentADUser.username;

          if (this.businessToEnroll.BusOwnerCust.CustEmail.length == 0) {
            this.errors.push(this.$t("listmybusiness.reemail"));
            document.getElementById("custEmail").classList.add("p-invalid");
          }
        }
        this.$log.debug(this.errors);
      }

      if (this.errors.length == 0) {
        this.$log.debug(this.businessToEnroll);
        this.showOwnerInformation = false;
        this.loading = true;
        if (this.currentCustomerId == 0) {
          this.businessToEnroll.BusOwnerCust.CustLang = this.$i18n.locale;
          await Customer.createCustomer(
            this.businessToEnroll.BusOwnerCust
          ).then(async (response) => {
            if (response == 1) {
              this.$VerifyAccount(
                this.businessToEnroll.BusOwnerCust.CustEmail
              ).then(async () => {
                this.accountCreated = true;
                await this.$FetchRoles("User").then(async () => {
                  await Customer.fetchFullByEmail(
                    this.businessToEnroll.BusOwnerCust.CustEmail
                  ).then(async (response) => {
                    this.businessToEnroll.BusOwnerCust = response;
                    this.$log.debug(this.businessToEnroll.BusOwnerCust);
                    this.businessToEnroll.BusOwnerCustId =
                      this.businessToEnroll.BusOwnerCust.CustId;
                    this.showBusinessInformation = true;
                    this.loading = false;
                  });
                });
              });
            }
          });
        } else {
          this.showBusinessInformation = true;
          this.loading = false;
        }
      }
    },
    businessSignUp() {
      var initBusCount = this.customerBusinesses.length;
      this.$log.debug(initBusCount);
      console.log(this.newSubLevel)
      Business.listNewBusiness(this.businessToEnroll, this.newSubLevel).then(async (response) => {
        this.$log.debug(response);
        //???

        //   if (response) {
        //     this.$log.debug(response);
        //   }
        // });
        //this.refreshUserData(this.currentCustomerId)
        //  .then(async () => {
        //   this.$log.debug(this.customerBusinesses.length);
        //  })  -->
        await this.$FetchRoles("Owner").then(async () => {
          this.$log.debug(response);

          if (
            response != null &&
            response != 0 &&
            response.sessionId != 0 &&
            response.busId != 0
          ) {
            //update currect business id first
            this.setCurrentBusiness(response.busId);
            this.redirect(response.sessionId);
          } else if (response != null && response != 0 && response.busId != 0) {
            this.$router.push(
              {
                name: "BusinessProfile",
              }
            );
          } else {
            if (initBusCount == this.customerBusinesses.length) {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("listmybusiness.suberror"),
                life: 3000,
              });
              this.submittingBusiness = false;
            } else {
              this.$router.push("BusinessProfile");
            }
          }
        });
      });
    },
    async setCurrentCustomer() {
      this.$log.debug("set current customer");
      this.$log.debug(this.currentCustomerId);
      this.$log.debug(this.requestedLogin);
      if (this.currentCustomerId > 0) {
        this.$log.debug("set user");
        this.loadingCurrentCustomer = true;
        await Customer.getCurrentCustomer().then(async (response) => {
          this.$log.debug(response);
          if (response != null) {
            this.currentCustomer = response;
            this.businessToEnroll.BusOwnerCustId = this.currentCustomerId;
            this.businessToEnroll.BusOwnerCust = this.currentCustomer;
            this.$log.debug(this.currentCustomer.CustomerId);
            if (
              this.requestedLogin == true &&
              this.currentCustomer.CustId > 0
            ) {
              this.$log.debug("show business info now");
              this.showNewUser = false;
              this.loadingCurrentCustomer = false;
              this.loading = false;
              this.showBusinessInformation = true;
            } else {
              this.loadingCurrentCustomer = false;
              this.loading = false;
            }
          } else {
            this.showOwnerInformation = true;
            this.loading = false;
            this.loadingCurrentCustomer = false;
          }
        });
      } else if (this.requestedLogin == true && this.userLoggedIn) {
        this.$log.debug("Show user info");
        this.showOwnerInformation = true;
        this.loading = false;
        this.loadingCurrentCustomer = false;
      } else {
        this.$log.debug("no valid login");
        this.resetBusinessSignUp();
        this.$router.push("/ListMyBusiness");
      }
    },
  },
  watch: {
    requestedGetStarted() {
     //console.log("requested get started");
      this.$log.debug(this.requestedGetStarted);
    },
    async currentCustomerId() {
      if (this.currentCustomerId > 0 && this.accountCreated == false) {
        this.setCurrentCustomer();
      }
    },
    showSignUp() {
      this.$log.debug(this.showSignUp);
    },
    userLoggedIn() {
      this.$log.debug("user logged in");
      if (this.userLoggedIn) {
        this.loading = false;
        this.loadingCurrentCustomer = false;
        if (!this.currentCustomerId > 0) {
          this.showOwnerInformation = true;
        }
      }
    },
    userMissingProfiel() {
      this.$log.debug("user missing profile");

      this.loading = false;
      this.loadingCurrentCustomer = false;
      if (this.userMissingProfile) {
        this.showOwnerInformation = true;
      }
    },
  },
  computed: {
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
    publishableKey() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    },

    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
      currentCustomerId: (state) => state.currentCustomerId,
      currentADUser: (state) => state.currentADUser,
      customerBusinesses: (state) => state.customerBusinesses,
      userLoggedIn: (state) => state.userLoggedIn,
      userMissingProfile: (state) => state.userMissingProfile,
    }),
    compStates() {
      return states;
    },
  },
};
</script>
<style>

.list-bg {
  background-image: url("~@/assets/handshake2.jpg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  /* position: -webkit-sticky;
  position: sticky; */

  top: 0;
  z-index: 1;
}

.signup-bg,
#signUpDialog {
  background-position: left !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  z-index: 901 !important;
}

.header {
  background-color: #e9ecef;
}

.purple {
  background-image: linear-gradient(
    134.6deg,
    rgba(201, 37, 107, 1) 0%,
    rgba(116, 16, 124, 1) 100%
  );

  color: white;

  top: 0;
  z-index: 100;
}

.dark-purple{
  background: #AA076B;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #61045F, #AA076B);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #61045F, #AA076B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
color: white;
top: 0;
z-index: 100;
}
</style>