import moment from "moment";

export default {
  install: (app,) => {

    app.config.globalProperties.$formatDateToTime = (data) => {
      return moment(data).format("LT");
    },
      app.config.globalProperties.$currentYear = () => {
        return moment(new Date()).format("YYYY");
      },

      app.config.globalProperties.$formatTime = (time) => {
        var date = Date.parse("2011-10-10T" + time);
        return moment(date).format("h:mm A");
      },

      app.config.globalProperties.$formatDateTime = (datetime) => {
        //console.log(datetime)
        //console.log(moment(datetime).format("MM/DD/YY hh:mm A"));
        return moment(datetime).format("MM/DD/YY hh:mm A");
      },

      app.config.globalProperties.$formatDateTimeToTime = (datetime) => {
        if (datetime != null) {
          return moment(datetime).format("hh:mm A");
        } else return null;

      }
    app.config.globalProperties.$formatDateTimeToCalendar = (datetime) => {
      return moment(datetime).format("MM/DD/YY hh:mm A");
    },

      app.config.globalProperties.$formatDateTimeToDate = (datetime) => {
        return moment(datetime).format("MM/DD/YY");
      }

      app.config.globalProperties.$isInThePast = (datetime) => {
        return moment(datetime).isBefore(moment());
      }

    app.config.globalProperties.$validateEmail = (email) => {
      //console.log(email)
      // eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true)
      }
      return (false)

    }
    app.config.globalProperties.$getQuoteExpirationDate = (requestedDate, expiresInDays) => {
      return moment(requestedDate).add(expiresInDays, "d").format("MM/DD/YY")
    }
    
    app.config.globalProperties.$getServiceType = (serviceType) => {
        switch (serviceType) {
          case "Venue":
            return 'servicetypes.venues'
          case "Catering":
            return 'servicetypes.catering' 
          case "Decorations":
            return 'servicetypes.decorations'
          case "Photography":
            return 'servicetypes.photography'
          case "Dessert Tables":
            return 'servicetypes.desserttables'
          case "Photobooth":
            return 'servicetypes.photobooth'
          case "Planning":
            return 'servicetypes.planning' 
          case "Hair":
            return 'servicetypes.hair'
          case "Makeup":
            return 'servicetypes.makeup'
          case "Attire":
            return 'servicetypes.attire'
          case "Florist":
            return 'servicetypes.florist'
          case "Music":
            return 'servicetypes.music' 
          case "Entertainment":
            return 'servicetypes.entertainment'
          case "Videographer":
            return 'servicetypes.videographer'
          case "Stationary":
            return 'servicetypes.stationary'
          case "Officiant":
            return 'servicetypes.officiant'
          case "Favors":
            return 'servicetypes.favors'
          case "Rentals":
            return 'servicetypes.rentals'
          default:
            return "Other";
        }
    }






  }
}


