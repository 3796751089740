<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content mt-3">
    <div class="flex">
      <Button
        icon="pi pi-pencil"
        style="float: right"
        v-tooltip.right="'New Message'"
        :label="$t('message.new')"
        class="p-button-primary ml-auto mb-2"
        @click.stop="displayNewMessage()"
      />
    </div>
    <div class="grid">
      <div class="col-12 md:col-6">
        <ScrollPanel
          style="width: 95%; height: 75vh; vertical-align: middle"
          class="custom-scroll"
        >
          <DataView
            :value="conversations"
            layout="list"
            class="mt-2 service-grid"
          >
            <template #empty>
              {{ $t("message.notsent") }}
            </template>
            <template #list="slotProps">
              <div class="col-12">
                <Card
                  :class="{
                    unread:
                      slotProps.data.Messages.filter(
                        (msg) =>
                          msg.MessageRead == false && msg.MessageSentBy == 'bus'
                      ).length > 0,
                  }"
                  class="mb-2 mr-1 ml-1 pointer"
                  @click.stop="viewConvo(slotProps.data)"
                >
                  <template v-slot:content>
                    <div
                      class="flex align-items-center justify-content-between"
                    >
                      <img
                        :src="imageHostingURL + slotProps.data.Bus.BusLogo"
                        :alt="slotProps.data.Bus.BusName"
                        class="mr-3"
                        style="
                          object-fit: cover;
                          max-height: 100px;
                          max-width: 100px;
                        "
                      />
                      <div style="text-align: right">
                        <b> {{ slotProps.data.Bus.BusName }}</b
                        ><br />
                        <span v-if="slotProps.data.Messages.length > 0">
                          <em>{{
                            truncateMsg(
                              slotProps.data.Messages[
                                slotProps.data.Messages.length - 1
                              ].MessageText
                            )
                          }}</em>
                        </span>
                        <span
                          v-if="
                            slotProps.data.Messages[
                              slotProps.data.Messages.length - 1
                            ].MessageText.length > 25
                          "
                        >
                        </span>
                      </div>
                    </div>
                  </template>
                  <template #footer>
                    <div style="text-align: right">
                      <small>{{
                        $formatDateTimeToCalendar(
                          slotProps.data.Messages[
                            slotProps.data.Messages.length - 1
                          ].MessageDate
                        )
                      }}</small>
                      &nbsp;
                      <Badge
                        v-if="
                          slotProps.data.Messages.filter(
                            (msg) =>
                              msg.MessageRead == false &&
                              msg.MessageSentBy == 'bus'
                          ).length > 0
                        "
                        :value="
                          slotProps.data.Messages.filter(
                            (msg) =>
                              msg.MessageRead == false &&
                              msg.MessageSentBy == 'bus'
                          ).length
                        "
                      ></Badge>
                    </div>
                  </template>
                </Card>
              </div>
            </template>
          </DataView>
        </ScrollPanel>
      </div>
      <div class="hidden md:inline-block col-1 md:col-6">
        <Card v-if="tempConvo != null" style="height: 100%">
          <template #title>
            {{ $t("message.conversation") }}
            <b>
              {{ tempConvo.Bus.BusName }}
            </b>
          </template>
          <template #content>
            <div
              v-if="tempConvo != null"
              style="width: 95%; height: 50vh; vertical-align: middle"
              class="custom-scroll"
            >
              <convo-display id="convo1" class="msgArea" :data="tempConvo" />
            </div>
            <div v-if="tempConvo.Bus.BusAcceptMessages == true">
              <Textarea
                class="mt-2"
                v-model="messageText"
                :placeholder="$t('message.type')"
                :autoResize="true"
                rows="2"
                cols="30"
                style="width: 100%"
              />

              <div class="flex justify-content-between mb-2">
                <Button
                  :label="$t('profile.cancel')"
                  v-tooltip.right="'Cancel'"
                  icon="pi pi-times"
                  iconPos="left"
                  @click.stop="cancelMessage"
                  class="ml-5 mr-2 p-button-danger p-button-sm p-button-raised"
                />
                <Button
                  v-if="!sendingMessage"
                  :label="$t('menu.send')"
                  v-tooltip.right="'Send'"
                  icon="pi pi-play"
                  iconPos="left"
                  @click.stop="sendMessage"
                  class="mr-5 p-button-success p-button-sm p-button-raised"
                />
                <Button
                  v-else
                  :label="$t('menu.send')"
                  v-tooltip.right="'Send'"
                  icon="pi pi-spin pi-spinner"
                  iconPos="left"
                  disabled
                  @click.stop="sendMessage"
                  class="mr-5 p-button-success p-button-sm p-button-raised"
                />
              </div>
            </div>
            <div v-else>
              <Message severity="warn" :closable="false"
                >{{ $t("calendar.messages") }}<br />
              </Message>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <Dialog
      v-model:visible="showNewMessage"
      :modal="true"
      :contentStyle="{ overflow: 'scroll' }"
      :header="$t('message.new')"
      :dismissableMask="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <div style="text-align: left">
        <Dropdown
          class="mt-2"
          v-model="selectedBusinessId"
          :options="businesses"
          optionLabel="BusName"
          optionValue="BusId"
          :placeholder="$t('businessprofile.selectbus')"
          :filter="true"
          @change="verifyBusiness($event)"
        />
        <Card v-if="selectedBusiness != null">
          <template #content>
            <div v-if="selectedBusiness.BusName != null">
              <a :href="webHost + selectedBusiness.BusHandle" target="_blank">{{
                selectedBusiness.BusName
              }}</a>
            </div>
            <div v-if="selectedBusiness.BusPhone != null">
              <i class="pi pi-mobile"></i>
              <a :href="'tel: ' + selectedBusiness.BusPhone">{{
                selectedBusiness.BusPhone
              }}</a>
            </div>
            <div v-if="selectedBusiness.BusEmail != null">
              <i class="pi pi-envelope"></i>
              <a :href="'mailto:' + selectedBusiness.BusEmail">{{
                selectedBusiness.BusEmail
              }}</a>
            </div>
            <div
              v-if="
                selectedBusiness.BusAddress != null &&
                selectedBusiness.BusCity != null &&
                selectedBusiness.BusZip != null &&
                selectedBusiness.BusState != null
              "
            >
              <i class="pi pi-map-marker"></i>
              <a
                :href="
                  'https://www.google.com/maps/search/?api=1&query=' +
                  selectedBusiness.BusAddress +
                  '+' +
                  selectedBusiness.BusCity +
                  '+' +
                  selectedBusiness.BusState +
                  '+' +
                  selectedBusiness.BusZip
                "
                target="_blank"
                >{{ selectedBusiness.BusAddress }},
                {{ selectedBusiness.BusCity }}, {{ selectedBusiness.BusState }},
                {{ selectedBusiness.BusZip }}</a
              >
            </div>
          </template>
        </Card>
        <div v-if="busMessagesEnabled != true">
          <Message severity="warn" :closable="false"
            >{{ $t("calendar.messages") }}<br />
          </Message>
        </div>

        <Textarea
          v-if="busMessagesEnabled"
          class="mt-2"
          v-model="newMessageText"
          :placeholder="$t('message.type')"
          :autoResize="true"
          rows="5"
          cols="30"
          style="width: 100%"
        />
      </div>
      <div class="flex justify-content-between">
        <Button
          :label="$t('profile.cancel')"
          v-tooltip.right="'Cancel'"
          icon="pi pi-times"
          iconPos="left"
          @click.stop="cancelNewMessage"
          class="mr-2 p-button-danger p-button-sm p-button-raised"
        />
        <Button
          v-if="busMessagesEnabled"
          :label="$t('menu.send')"
          v-tooltip.right="'Send'"
          icon="pi pi-play"
          iconPos="left"
          @click.stop="sendNewMessage"
          class="mr-2 p-button-success p-button-sm p-button-raised"
        />
      </div>
    </Dialog>

    <Sidebar
      v-model:visible="showConvo"
      position="full"
      class="md:hidden"
      style="background-color: #f8f9fa; overflow: scroll"
    >
      <div class="flex flex-column h-full justify-content-between">
        <div class="mb-3">
          {{ $t("message.conversation") }}
          <b>
            {{ tempConvo.Bus.BusName }}
          </b>
        </div>
        <div class="overflow-y-scroll flex-grow-1">
          <convo-display id="convo2" class="msgArea" :data="tempConvo" />
        </div>
        <div
          v-if="tempConvo.Bus.BusAcceptMessages == true"
          style="bottom: 5vh"
          class="mr-5 pl-5 bottom-0"
        >
          <Textarea
            class="mt-2"
            v-model="messageText"
            :placeholder="$t('message.type')"
            :autoResize="true"
            rows="2"
            cols="30"
            style="width: 100%"
          />

          <div class="flex justify-content-between">
            <Button
              :label="$t('profile.cancel')"
              v-tooltip.right="'Cancel'"
              icon="pi pi-times"
              iconPos="left"
              @click.stop="cancelMessage"
              class="ml-5 mr-2 p-button-danger p-button-sm p-button-raised"
            />
            <Button
              v-if="!sendingMessage"
              :label="$t('menu.send')"
              v-tooltip.right="'Send'"
              icon="pi pi-play"
              iconPos="left"
              @click.stop="sendMessage"
              class="mr-5 p-button-success p-button-sm p-button-raised"
            />
            <Button
              v-else
              :label="$t('menu.send')"
              v-tooltip.right="'Send'"
              icon="pi pi-spin pi-spinner"
              iconPos="left"
              disabled
              @click.stop="sendMessage"
              class="mr-5 p-button-success p-button-sm p-button-raised"
            />
          </div>
        </div>
        <div v-else>
          <Message severity="warn" :closable="false"
            >{{ $t("calendar.messages") }}<br />
          </Message>
        </div>
      </div>
      <!-- </ScrollPanel> -->
    </Sidebar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Conversation from "../store/Models/Conversation";
import Business from "../store/Models/Business";
import Message from "../store/Models/Message";
import ConvoDisplay from "../components/ConvoDisplay.vue";
import LoadingOverlay from "../components/LoadingOverlay.vue";
//
export default {
  name: "CustomerMessages",
  components: { ConvoDisplay, LoadingOverlay },
  data() {
    return {
      showConvo: false,
      tempConvo: null,
      conversations: [],
      showNewMessage: false,
      businesses: [],
      selectedBusinessId: 0,
      messageText: "",
      newMessageText: "",
      loading: false,
      busMessagesEnabled: true,
      selectedBusiness: null,
      webHost: process.env.VUE_APP_MYRESERVS_URL,
      sendingMessage: false,
    };
  },
  async mounted() {
    this.$log.debug("messages-mounted");
    this.$log.debug(this.currentCustomerId);
    if (this.currentCustomerId > 0) {
      this.loading = true;
      await this.setCustomerConvos();
      this.loading = false;
    }
    this.timer = setInterval(this.refreshCustConvos, 50000);
  },
  methods: {
    verifyBusiness(event) {
      this.$log.debug(event.value);
      this.selectedBusiness = this.businesses.find(
        (bus) => bus.BusId == event.value
      );
      this.$log.debug(this.selectedBusiness);

      this.busMessagesEnabled = this.selectedBusiness.BusAcceptMessages;
      this.$log.debug(this.busMessagesEnabled);
    },
    truncateMsg(msg) {
      return msg.substring(0, 25);
    },

    viewConvo(conversation) {
      this.tempConvo = conversation;
      this.showConvo = true;
      Conversation.markAsCustRead(conversation.ConvoId);
      this.scrollConvos();
    },
    scrollConvos() {
      setTimeout(() => {
        var elem = document.getElementById("convo1");
        this.$log.debug(elem.scrollHeight);
        elem.scrollTop = elem.scrollHeight;
        this.$log.debug(elem);
        var elem2 = document.getElementById("convo2");
        this.$log.debug(elem2.scrollHeight);
        elem2.scrollTop = elem2.scrollHeight;
        this.$log.debug(elem2);
      }, 250);
    },
    async displayNewMessage() {
      this.businesses = [];
      await Business.fetchBusinessContact().then((result) => {
        this.businesses = result;
      });
      this.showNewMessage = true;
    },

    async sendNewMessage() {
      let newConvo = {
        ConvoId: 0,
        BusId: this.selectedBusinessId,
        CustId: this.currentCustomerId,
        Messages: [],
      };

      let newMessage = {
        MessageId: 0,
        ConvoId: 0,
        MessageSentBy: "cust", //"customer/business"
        MessageDate: new Date(),
        MessageText: this.newMessageText,
        MessageRead: false,
      };

      newConvo.Messages.push(newMessage);

      this.$log.debug(newConvo);
      await Conversation.newConvo(newConvo).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          Conversation.deleteAll();
          this.setCustomerConvos();
          this.cancelNewMessage();
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.messagefailed"),
            life: 3000,
          });
        }
      });
    },
    async sendMessage() {
      this.$log.debug("sending new message");
      this.sendingMessage = true;
      let newMessage = {
        MessageId: 0,
        ConvoId: this.tempConvo.ConvoId,
        MessageSentBy: "cust", //"customer/business"
        MessageDate: new Date(),
        MessageText: this.messageText,
        MessageRead: false,
      };

      await Message.newMessage(newMessage).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          this.sendingMessage = false;
          Conversation.deleteAll();
          this.setCustomerConvos();
          this.cancelMessage();
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("code.messagefailed"),
            life: 3000,
          });
        }
      });
    },
    cancelNewMessage() {
      this.newMessageText = "";
      this.selectedBusinessId = 0;
      this.showNewMessage = false;
      this.selectedBusiness = null;
    },
    cancelMessage() {
      this.messageText = "";
      this.selectedBusinessId = 0;
      this.selectedBusiness = null;
    },
    async setCustomerConvos() {
      await Conversation.fetchByCustId(this.currentCustomerId).then(
        (result) => {
          this.$log.debug(result);
          this.conversations = result;
          if (this.tempConvo != null && this.tempConvo.ConvoId != null) {
            this.tempConvo = Conversation.query()
              .withAllRecursive()
              .find(this.tempConvo.ConvoId);
            this.scrollConvos();
          }
        }
      );
    },
    async refreshCustConvos() {
      this.$log.debug("refreshing cust convos");
      await Conversation.refreshByCustId(this.currentCustomerId).then(
        (result) => {
          this.$log.debug(result);
          this.conversations = result;
          if (this.tempConvo != null && this.tempConvo.ConvoId != null) {
            this.tempConvo = Conversation.query()
              .withAllRecursive()
              .find(this.tempConvo.ConvoId);
          }
        }
      );
    },
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCustomerConvos();
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style scoped>
.unread {
  border-left: 5px solid var(--mr-primary-color) !important;
}
.msgArea {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.custom-scroll .p-scrollpanel-wrapper {
  border-right: 9px solid var(--mr-primary-color) !important;
}

.p-scrollpanel .p-scrollpanel-bar {
  visibility: visible !important;
  background-color: var(--mr-primary-color) !important;

  transition: background-color 0.3s !important;
}

.custom-scroll .p-scrollpanel-bar:hover {
  background-color: var(--mr-primary-color) !important;
}

.custom-scroll.p-scrollpanel-wrapper.p-scrollpanel-content {
  padding: 0px !important;
}
</style>
