<template>
    <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
 <div v-else class="flex  align-content-center justify-content-center flex-column " style="height:100%">
  <div class="flex align-items-center justify-content-center" ><h1>{{ $t("subscriptions.mustupgrade") }}</h1></div>
  <div class="flex flex-row flex-wrap align-items-center justify-content-center" > 

    <Card class="m-3 flex-grow-1 service-card">
  <template #content >
      <div class="">
        <div class="flex align-items-end">
        <Chip
            v-if="currentSub != null && currentSub.SubDef != null && currentSub.SubDef.SubscriptionLevelCode == 'BASIC'"
            :label="'CURRENT'"
            class=" success-chip chip-sm"
          />
        </div>
        <div style="color:var(--mr-primary-color);font-size: 2rem; font-weight:bold">BASIC</div>
        <div class=mt-5>
        <span style="font-size:3em; font-weight: bold; ">
          ${{ currentBasicDef.SubDefPriceMo }}
        </span>
        {{ $t("listmybusiness.amonth") }} 
        </div>
  
  
      <div class=" m-2" style="text-align:start">
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.businesslisting") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.serviceportfolio") }} </div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check  mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.availabilitycalendar") }}</div>
      </div>
  
      </div>
      <div>
      <Button
            v-if="currentBasicDef != null && currentSub != null && currentSub.SubDef != null && currentSub.SubDef.SubscriptionLevelCode != 'BASIC'"
            :label="$t('listmybusiness.tryforfree') "
            class="p-button-raised p-button-lg p-button-primary mb-2 mt-5"
            @click.stop="upgrade('BASIC');"
          />
          <Button
            v-else
            :label="$t('listmybusiness.tryforfree') "
            class="p-button-raised p-button-lg p-button-primary mb-2 mt-5"
            disabled
          />
          <div style="font-size: 1rem">{{$t('listmybusiness.freetrialdays') }}</div>
      </div>
    </template>
</Card>
<Card class="m-3 flex-grow-1 service-card">
  <template #content >
      <div >
        <div class="flex align-items-end">
        <Chip
            v-if="currentSub != null && currentSub.SubDef != null && currentSub.SubDef.SubscriptionLevelCode == 'PREMIUM'"
            :label="'CURRENT'"
            class=" success-chip chip-sm"
          />
        </div>
        <div style="color:var(--mr-primary-color);font-size: 2rem; font-weight:bold">PREMIUM</div>
        <div class=mt-5>
        <span style="font-size:3em; font-weight: bold; ">
          ${{ currentPremiumDef.SubDefPriceMo }}
        </span>
        {{ $t("listmybusiness.amonth") }} 
        </div>
      </div>
  
      <div class="m-2" style="text-align:start">
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.businesslisting") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.serviceportfolio") }} </div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check  mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.availabilitycalendar") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.directquotes") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.reservationsandappointments") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.onlinestore") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.onlinepay") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.directmessages") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.customerreviews") }}</div>
        <div style="font-size: 1.5rem"> <i class="pi pi-check mr-2"  style="font-size: 1.5rem" ></i>{{ $t("listmybusiness.clientandstaffmgmt") }}</div>
      </div>
  
 <div>
      <Button
            v-if="currentPremiumDef != null"
            :label="$t('listmybusiness.tryforfree') "
            class="p-button-raised p-button-lg p-button-primary mb-2 mt-5"
            @click.stop="upgrade('PREMIUM');"
          />
          <div style="font-size: 1rem">{{$t('listmybusiness.freetrialdays') }}</div>
 </div>
    </template>
</Card>
      
            </div>
 </div>
</template>
<script>

import { mapState } from "vuex";
import SubscriptionDefs from "../store/Models/SubscriptionDefs";
import Subscription from '../store/Models/Subscription';
import LoadingOverlay from "../components/LoadingOverlay.vue";

export default {
  name: "UpgradeSubscription",
  components: {LoadingOverlay},
  props: {
    subId: Number
  },
  data() {
    return {
      redirecturl: process.env.VUE_APP_MYRESERVS_URL,
      completing: false,
      currentSubDef: {},
      currentBasicDef:{},
      currentPremiumDef:{},
      currentSub:null,
      currentBusiness:{},
      subscriptions:[],
      loading:true
    };
  },
  async mounted() {
    this.currentSubDef = await SubscriptionDefs.fetchCurrentSubDef();
        this.currentBasicDef = await SubscriptionDefs.fetchCurrentBasic();
    this.currentPremiumDef = await SubscriptionDefs.fetchCurrentPremium();
    this.$log.debug(this.currentSubDef);
    if (this.currentCustomerId != 0) this.setCurrentCustomer();
  },
  methods: {
    
    upgrade(level) {
      this.$log.debug("upgrade-subscription");
      this.completing = true;
      Subscription.upgardeSubscription(this.subId, level).then(async (response) => {
        this.$log.debug(response);
        if (
          response != null 
        ) {
         //console.log(response);
          this.setCurrentCustomer();
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("listmybusiness.suberror"),
            life: 3000,
          });
        }
        this.completing = false;
      });
    },
    async setCurrentCustomer() {
      this.loading = true;
      Subscription.fetchByOwnerId(this.currentCustomerId).then((result) => {
        this.subscriptions = result;

        this.$log.debug(this.subscriptions);
        if (this.currentSub != null) {
          this.currentSub = this.subscriptions.find(
            (sub) => sub.SubId == this.currentSub.SubId
          );
        } else if (this.currentBusinessId != 0) {
          this.currentSub = this.subscriptions.find(
            (sub) => sub.BusId == this.currentBusinessId
          );
        }
       //console.log(this.currentSub)
        this.loading = false;
      });
    }
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
      currentBusinessId: (state) => state.currentBusinessId
    }),
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCurrentCustomer();
    },
    async currentBusinessId() {
      this.setCurrentCustomer();
    }
  }
};
</script>


