
/* eslint-disable */

import { Model } from '@vuex-orm/core'
import CustEvent from "./CustEvent"
import Customer from './Customer'


export default class CustSharedEvent extends Model {
  static entity = 'custsharedevent'

  static primaryKey = 'ShareId'

  static fields() {
    return {
      ShareId: this.uid(),
      EventId: this.attr(null),
      CustId: this.attr(null),
          
      //VIRTUAL
      Event: this.belongsTo(CustEvent, 'EventId'),
      Cust: this.belongsTo(Customer, 'CustId'),
    }
  }
  static async remove(shareId) {
    //console.log("deleting share")
     const result =  await CustSharedEvent.api().post('/api/CustSharedEvents/RemoveAccess/'+
      shareId).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
      }

      static async share(share) {
       //console.log("creating share")
         const result =  await CustSharedEvent.api().post('/api/CustSharedEvents/',
          share).then((result) => {
            if (result.response.status == 201) {
             //console.log("success")
              return 1;
            }
            if(result.response.status == 200){
             //console.log("duplicate")
              return 1;
            }
            else{
             //console.log("error");
              return 2;
            } 
          }).catch(error => {
           //console.log(error.response);
            return 2;
          });
          return result;
          }

}

