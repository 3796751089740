import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Reservation from "./Reservation"
import Card from "./Card"
export default class ReservPayment extends Model {
  static entity = 'reservPayments'
  
  static primaryKey = 'ReservPayId'
  
  static fields () {
    return {
      ReservPayId: this.uid(),
      ReservId:this.attr(null),
      Reserv: this.belongsTo(Reservation, 'ReservId'),
      PayAmount: this.attr(null),
      PayDate: this.attr(null),
      PayNum: this.attr(null),
      PayStatus:  this.attr(null),
      PaySource:  this.attr(null),
      CardId: this.attr(null),
      ReservNum: this.attr(null),
      BusId: this.attr(null),
      PayInvoiceUrl: this.attr(null),
      Card: this.belongsTo(Card, 'CardId')
      
    }
  }

  static async fetchByBusId(BusId) {
    //console.log("fetch reserv payments for " + BusId)
    ReservPayment.deleteAll();
   var result = await ReservPayment.api().get('/api/reservpayments/GetBusinessPayments/' + BusId);
    //console.log(result)
    return  ReservPayment.query()
      .withAllRecursive()
      .orderBy('ReservPayId', 'desc').all();
  }

  static async newPayment(payment) {
    //console.log("at new reserv payment")
    //console.log(payment)

    const result = await ReservPayment.api().post('/api/reservpayments/newpayment/', payment).then((result) => {
      //console.log(result)
      if (result.response.status == 200 || result.response.status == 204 ) {
        //console.log("success")
        ReservPayment.deleteAll();
        return 1;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }

  static async newManualPayment(payment) {
    //console.log("at new manula reserv payment")
    //console.log(payment)

    const result = await ReservPayment.api().post('/api/reservpayments/newmanualpayment/', payment).then((result) => {
      //console.log(result)
      if (result.response.status == 204) {
        //console.log("success")
        return result.response.data;
      }
      else {
        //console.log("error");
        return 0;
      }
    }).catch(error => {
      //console.log(error.response);
      return 0;
    });
    return result;
  }
}

