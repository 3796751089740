<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content mt-3">
    <DataView :value="orders" layout="list" class="service-grid">
      <template #empty>
        {{ $t("store.custnoorders") }}
      </template>
      <template #list="slotProps">
        <div class="col-12 lg:col-6">
          <Card class="m-2">
            <template #title>
              <div class="flex flex-wrap justify-content-between">
                <b>#{{ slotProps.data.OrderNum }}</b>
                <!-- <Tag
                  :severity="getStatusTag(slotProps.data.ReservStatus)"
                  style="text-align: left; font-size: x-small"
                  rounded
                > -->
                <div>
                  <span style="font-size: small">
                    <Tag
                      :severity="getStatusTag(slotProps.data.OrderStatus)"
                      :value="getStatusDesc(slotProps.data.OrderStatus)"
                    ></Tag
                  ></span>
                </div>
                <!-- </Tag> -->
              </div>
            </template>
            <template v-slot:content>
              <div class="flex flex-wrap justify-content-around">
                <div class="mb-2" style="text-align: center">
                  <b
                    ><i class="pi pi-map-marker"></i
                    ><a
                      :href="webHost + slotProps.data.Bus.BusHandle"
                      target="_blank"
                      >{{ slotProps.data.Bus.BusName }}</a
                    ></b
                  ><br />

                  <span v-if="slotProps.data.Slot">
                    <b>
                      <i class="pi pi-calendar"></i>
                      {{ $formatDateTimeToDate(slotProps.data.Slot.SlotDate) }}
                      <br />
                      <i class="pi pi-clock"></i>
                      {{ $formatTime(slotProps.data.Slot.SlotStartTime) }}

                      -
                      {{ $formatTime(slotProps.data.Slot.SlotEndTime) }}
                    </b>
                  </span>
                  <span v-else>
                    <i class="pi pi-calendar"></i>{{ $t("calendar.dat") }}
                  </span>
                </div>
                <div>
                  <div class="flex flex-wrap justify-content-center">
                    <span class="mr-2">
                      <b>{{ $t("calendar.totalprice") }}: </b>
                    </span>
                    ${{ slotProps.data.OrderTotalCost }}
                  </div>
                  <div class="flex flex-wrap justify-content-center">
                    <span class="mr-2"
                      ><b>{{ $t("custreserv.custre") }}: </b>
                    </span>
                    ${{ slotProps.data.OrderTotalPaid }}
                  </div>
                  <div class="flex flex-wrap justify-content-center">
                    <span class="mr-2"
                      ><b>{{ $t("custreserv.custowe") }}: </b>
                    </span>
                    ${{ slotProps.data.OrderTotalOwed }}
                  </div>
                </div>
              </div>
            </template>
            <template #footer>
              <div class="flex flex-wrap justify-content-evenly mt-5">
                <Button
                  icon="pi pi-file"
                  :label="$t('store.order')"
                  class="p-button-primary p-button-sm mb-2 mr-1"
                  @click.stop="openOrder(slotProps.data)"
                />
                <Button
                  v-if="
                    slotProps.data.OrderTotalOwed > 0 &&
                    slotProps.data.Bus != null &&
                    slotProps.data.Bus.BusAcceptPayment == true &&
                    slotProps.data.Bus.BusProviderAcctStatus == 'Active' &&
                    slotProps.data.OrderStatus == 'Set' &&
                    slotProps.data.OrderTotalOwed > 0

                  "
                  icon="pi pi-dollar"
                  :label="$t('menu.makepayment')"
                  class="p-button-success p-button-sm mb-2 mr-1"
                  @click.stop="showNewPayment(slotProps.data)"
                />
                <Button
                  v-if="
                    slotProps.data.OrderTotalOwed > 0 &&
                    slotProps.data.Bus != null &&
                    slotProps.data.Bus.BusAcceptPayment == true &&
                    slotProps.data.OrderStatus == 'PendingDownpayment'
                  "
                  icon="pi pi-dollar"
                  :label="$t('menu.makedownpayment')"
                  class="p-button-warning p-button-sm mb-2 mr-1"
                  @click.stop="
                    initialPayment = true;
                    showNewPayment(slotProps.data);
                  "
                />
                <Button
                  icon="pi pi-money-bill"
                  :label="$t('store.payments')"
                  class="p-button-info p-button-sm mb-2 mr-1"
                  @click.stop="openPayments(slotProps.data)"
                />
                <Button
                  v-if="slotProps.data.OrderStatus != 'RequestCancel'"
                  icon="pi pi-calendar-times"
                  :label="$t('menu.requestCancelation')"
                  class="p-button-danger p-button-sm mb-2"
                  @click.stop="requestCancel(slotProps.data)"
                />
              </div>
            </template>
          </Card>
        </div>
      </template>
    </DataView>
    <Dialog
      v-model:visible="viewingPayments"
      :style="{ width: '90vw' }"
      :modal="true"
      :header="$t('menu.paymentHistory')"
      :dismissableMask="true"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <div
        v-if="selectedOrder != null"
        class="flex flex-wrap justify-content-around align-items-center mb-3"
        style="
          padding: 1px;
          border-color: #ced4da;
          border-top: 1px solid;
          border-bottom: 1px solid;
        "
      >
        <div class="mb-1 mr-1">
          <b>{{ $t("calendar.totalprice") }}: </b> ${{
            selectedOrder.OrderTotalCost
          }}
        </div>

        <div class="mb-1 mr-1">
          <b>{{ $t("calendar.totalpaid") }}: </b> ${{
            selectedOrder.OrderTotalPaid
          }}
        </div>
        <div class="mb-1 mr-1">
          <b>{{ $t("calendar.totalowed") }}: </b> ${{
            selectedOrder.OrderTotalOwed
          }}
        </div>
        <Button
          v-if="
            selectedOrder.OrderTotalOwed > 0 &&
            selectedOrder.Bus != null &&
            selectedOrder.Bus.BusAcceptPayment == true &&
            selectedOrder.Bus.BusProviderAcctStatus == 'Active' &&
            selectedOrder.OrderStatus == 'Set'
          "
          icon="pi pi-dollar"
          :label="$t('menu.makepayment')"
          class="p-button-success p-button-sm mb-1 mr-1"
          @click.stop="showNewPayment(selectedOrder)"
        />
      </div>

      <DataTable
        v-if="selectedOrder != null"
        :value="selectedOrder.OrderPayments"
        sortField="PayDate"
        :sortOrder="-1"
        responsiveLayout="stack"
        :resizableColumns="true"
        columnResizeMode="fit"
      >
        <template #empty> {{ "$t('payments.nopayments')" }} </template>
        <template #loading>{{ "$t('payments.loadingpayments')" }} </template>
        <Column
          field="PayDate"
          :header="$t('custreserv.custdate')"
          :sortable="true"
        >
          <template #body="slotProps">
            {{ $formatDateTime(slotProps.data.PayDate) }}
          </template>
        </Column>
        <Column field="PayNum" header="#" :sortable="true"></Column>
        <Column
          field="Card.CardNumber"
          :header="$t('custreserv.custcardd')"
          :sortable="true"
        ></Column>
        <Column
          field="PayStatus"
          :header="$t('calendar.status')"
          :sortable="true"
        ></Column>
        <Column
          field="PayAmount"
          :header="$t('custreserv.custamount')"
          :sortable="true"
        >
          <template #body="slotProps">
            ${{ slotProps.data.PayAmount }}
          </template></Column
        >
        <Column
          field="PayInvoiceUrl"
          :header="$t('payments.invoice')"
          :sortable="true"
        >
          <template #body="slotProps">
            <a
              v-if="
                slotProps.data.PayInvoiceUrl != null &&
                slotProps.data.PayInvoiceUrl.length > 0
              "
              :href="slotProps.data.PayInvoiceUrl"
              target="_blank"
            >
              <i class="pi pi-file"></i>
            </a>
          </template>
        </Column>
      </DataTable>
    </Dialog>

    <Dialog
      v-model:visible="showingNewPayment"
      :modal="true"
      :header="$t('custreserv.custnew')"
      @hide="resetNewPayment"
      :dismissableMask="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <div v-if="showingCardSelection">
        <div style="text-align: left">
          <div
            v-if="customerCards != null && customerCards.length > 0"
            class="field col-12"
          >
            <label>{{ $t("custreserv.custcard") }} </label>
            <div v-if="showSavedCards" class="field-radiobutton">
              <RadioButton
                id="cardOption1"
                name="cardOption"
                value="savedCard"
                v-model="cardOption"
              />&nbsp;
              <Dropdown
                id="paymentCards"
                class="mt-2 mr-2"
                v-model="selectedCard"
                :options="customerCards"
                :placeholder="$t('custreserv.custsecard')"
                @change="cardOption = 'savedCard'"
              >
                <template #value="slotProps">
                  <div class="" v-if="slotProps.value">
                    <div>
                      x{{ slotProps.value.CardNumber }},
                      <small>
                        Exp. {{ slotProps.value.CardExpMonth }}/{{
                          slotProps.value.CardExpYear
                        }}</small
                      >
                    </div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div>
                    x{{ slotProps.option.CardNumber }},
                    <small>
                      Exp. {{ slotProps.option.CardExpMonth }}/{{
                        slotProps.option.CardExpYear
                      }}</small
                    >
                  </div>
                </template>
              </Dropdown>
            </div>
          </div>
          <div class="field-radiobutton col-12">
            <RadioButton
              id="cardOption2"
              name="cardOption"
              value="newCard"
              v-model="cardOption"
            />
            <span>&nbsp;{{ $t("custreserv.newcard") }}&nbsp;</span>
          </div>
          <div v-if="cardOption == 'newCard'" class="text-left">
            <stripe-card ref="stripeCard"></stripe-card>
          </div>
          <div class="col-12">
            <Message
              v-for="error of errors"
              severity="error"
              :key="error"
              :sticky="true"
              >{{ error }}</Message
            >
          </div>
        </div>
        <div class="flex justify-content-between">
          <Button
            icon="pi pi-times"
            :label="$t('profile.cancel')"
            class="p-button-secondary p-button-sm m-1"
            @click.stop="
              showingNewPayment = false;
              resetNewPayment();
            "
          />

          <Button
            icon="pi pi-arrow-right"
            :label="$t('menu.next')"
            class="p-button-primary p-button-outlined p-button-sm m-1"
            @click.stop="selectCard"
          />
        </div>
      </div>
      <div v-if="showPaymentAmount">
        <div style="text-align: left">
          <div class="field col-12">
            <label>{{ $t("custreserv.custpay") }} </label>
            <div class="field-radiobutton">
              <RadioButton
                id="option1"
                name="payOption"
                value="totalOwed"
                v-model="payOption"
              />
              <span
                >&nbsp;{{ $t("custreserv.custowe") }}: ${{
                  selectedOrder.OrderTotalOwed
                }}
              </span>
            </div>
            <div class="field-radiobutton">
              <RadioButton
                id="option2"
                name="payOption"
                value="otherAmt"
                v-model="payOption"
              />
              <span>&nbsp;{{ $t("custreserv.custother") }}:&nbsp;</span>
              <InputNumber
                id="paymentAmount"
                placeholder="$"
                v-model="newPayment.PayAmount"
                mode="currency"
                currency="USD"
                locale="en-US"
                @focus="payOption = 'otherAmt'"
                style="font-size: small"
              />
            </div>
            <div v-if="initialPayment == true" class="col-12">
              <Message severity="info" :sticky="true"
                >Downpayment amount required is ${{
                  selectedOrder.Bus.BusDownPmtAmt
                }}. This order is not final until this amount is paid.
                <!-- todo -->
              </Message>
            </div>
            <div class="col-12">
              <Message
                v-for="error of errors"
                severity="error"
                :key="error"
                :sticky="true"
                >{{ error }}</Message
              >
            </div>
          </div>
          <div class="flex justify-content-between">
            <Button
              v-if="!processingPayment"
              icon="pi pi-times"
              :label="$t('profile.cancel')"
              class="p-button-secondary p-button-sm m-1"
              @click.stop="resetNewPayment()"
            />
            <Button
              v-else
              icon="pi pi-times"
              :label="$t('profile.cancel')"
              class="p-button-secondary p-button-sm m-1"
              disabled
            />
            <Button
              v-if="!processingPayment"
              icon="pi pi-arrow-left"
              :label="$t('menu.back')"
              class="p-button-info p-button-raised p-button-sm m-1"
              @click.stop="
                showPaymentAmount = false;
                showingCardSelection = true;
                errors = [];
              "
            />
            <Button
              v-else
              icon="pi pi-arrow-left"
              :label="$t('menu.back')"
              class="p-button-info p-button-raised p-button-sm m-1"
              disabled
            />
            <Button
              v-if="!processingPayment"
              icon="pi pi-check"
              :label="$t('menu.submit')"
              class="p-button-success p-button-raised p-button-sm m-1"
              @click.stop="validatePayment()"
            />

            <Button
              v-else
              icon="pi pi-spin pi-spinner"
              disabled
              :label="$t('menu.submit')"
              class="p-button-success p-button-raised p-button-sm mt-2"
            />
          </div>
        </div>
      </div>
    </Dialog>

    <Dialog
      v-model:visible="viewingOrder"
      :style="{ width: '90vw' }"
      :modal="true"
      :header="selectedOrder.OrderNum"
      :dismissableMask="true"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <div class="grid">
        <div class="col-12 grid-nogutter">
          <div
            class="
              flex flex-wrap
              justify-content-center
              align-items-center
              text-center
            "
          >
            <img
              :src="imageHostingURL + selectedOrder.Bus.BusLogo"
              :alt="selectedOrder.Bus.BusName"
              width="100"
              class="mr-3"
            />
            <div>
              <span style="font-size: xx-large">
                {{ selectedOrder.Bus.BusName }} </span
              ><br />
              <span>
                <small>
                  {{ selectedOrder.Bus.BusAddress }}
                  {{ selectedOrder.Bus.BusCity }},
                  {{ selectedOrder.Bus.BusState }}
                  {{ selectedOrder.Bus.BusZip }}</small
                ></span
              ><br />
              <span>
                <small>{{ selectedOrder.Bus.BusPhone }} </small></span
              >
            </div>
          </div>
        </div>
        <div class="col-12 grid-nogutter"></div>
        <div class="col-12 md:col-6 mb-2">
          <div style="text-align: left">
            <div style="background-color: #e9ecef">
              <span style="font-size: x-large">{{
                $t("custreserv.custinfo")
              }}</span>
            </div>
            <b>{{ $t("custreserv.custname") }}: </b>
            {{ selectedOrder.Cust.CustFirstName }}
            {{ selectedOrder.Cust.CustLastName }}
            <br />
            <b>{{ $t("custreserv.custphone") }}: </b
            >{{ selectedOrder.Cust.CustPhone }}
            <br />
            <b>{{ $t("listmybusiness.address") }}: </b>
            {{ selectedOrder.Cust.CustAddress }}<br />
            {{ selectedOrder.Cust.CustCity }},
            {{ selectedOrder.Cust.CustState }}
            {{ selectedOrder.Cust.CustZip }}
            <br />
            <b>{{ $t("listmybusiness.email") }}: </b>
            {{ selectedOrder.Cust.CustEmail }}
          </div>
        </div>
        <div class="col-12 md:col-6 mb-2">
          <div style="text-align: right">
            <div style="background-color: #e9ecef">
              <span style="font-size: x-large">{{
                $t("custreserv.custprocess")
              }}</span>
            </div>
            <b>{{ $t("custreserv.custdatere") }}: </b
            >{{ $formatDateTime(selectedOrder.OrderRequestDate) }}
            <br />
            <b>{{ $t("custreserv.custdatepro") }}: </b
            ><span v-if="selectedOrder.OrderApprovedDate">{{
              $formatDateTime(selectedOrder.OrderApprovedDate)
            }}</span
            ><span v-else>{{ $t("custreserv.custdatenotpro") }}: </span>
            <br />
            <b>{{ $t("custreserv.custproby") }}: </b
            ><span v-if="selectedOrder.OrderApprovedBy">{{
              selectedOrder.OrderApprovedBy
            }}</span
            ><span v-else>{{ $t("custreserv.custdatenotpro") }}: </span><br />
            <b>{{ $t("custreserv.custresponded") }}: </b>
            <!-- <span v-if="selectedOrder.QuoteResponseDate">{{
              $formatDateTime(selectedQuote.QuoteResponseDate)
            }}</span
            ><span v-else>{{ $t("custreserv.custnotapp") }} </span> -->
          </div>
        </div>

        <div
          class="col-12"
          style="background-color: #e9ecef; text-align: center"
        >
          <span style="font-size: x-large">{{
            $t("store.date")
          }}</span
          ><br />
        </div>
        <div class="col-12" style="text-align: center">
          <template v-if="selectedOrder.Slot != null">
            <i class="pi pi-calendar"></i>
            {{ $formatDateTimeToDate(selectedOrder.Slot.SlotDate) }}<br />
            <i class="pi pi-clock"></i
            >{{ $formatTime(selectedOrder.Slot.SlotStartTime) }} -
            {{ $formatTime(selectedOrder.Slot.SlotEndTime) }}
          </template>
        </div>
        <div class="col-12 mb-2" style="background-color: #e9ecef">
          <span style="font-size: x-large"
            >{{ $t("custreserv.custser") }}
          </span>
        </div>
        <div class="col-4" style="background-color: #f8f9fa">
          <b>{{ $t("custreserv.custservice") }}</b>
        </div>
        <div class="col-3" style="background-color: #f8f9fa">
          <b>{{ $t("custreserv.custprice") }} </b>
        </div>
        <div class="col-2" style="background-color: #f8f9fa">
          <b>{{ $t("custreserv.custqty") }}</b>
        </div>
        <div class="col-3" style="background-color: #f8f9fa; text-align: right">
          <b>{{ $t("custreserv.custamount") }} </b>
        </div>
        <template
          v-for="serv in selectedOrder.OrderedServices"
          :key="serv.OrderedServId"
        >
          <template
            v-if="serv.OrderServOrigId == null && serv.OrderServRemoved != true"
          >
            <div class="col-4">
              <span style=""
                >{{ serv.Serv.ServTitle }}
              </span>
            </div>
            <div class="col-3">
              <span >
                ${{ serv.ServPrice }}
              </span>
              <span
                style="
                  
                  font-size: small;
                "
                v-if="serv.Serv.ServPriceCalculated == true"
              >
                &nbsp;{{ $t("quotes.each") }}
              </span>
            </div>
            <div class="col-2">
              <span style="">{{
                serv.OrderServQty
              }}</span>
            </div>
            <div class="col-3" style="text-align: right">
              <span v-if="serv.OrderServTotal != 0"
                >${{ serv.OrderServTotal }}</span
              >
            </div>
          </template>
        </template>

        <div
          class="col-12 mt-5 grid grid-nogutter justify-content-end"
          style="text-align: right"
        >
          <div
            class="col-9"
            style="text-align: right; background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custsub") }}</b>
          </div>
          <div class="col-3">
            <span v-if="selectedOrder.OrderSubTotal != 0"
              ><b>${{ selectedOrder.OrderSubTotal }}</b></span
            >
          </div>
          <div
            v-if="selectedOrder.OrderDiscount != 0"
            class="col-9"
            style="text-align: right; background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custdis") }}</b>
          </div>
          <div v-if="selectedOrder.OrderDiscount != 0" class="col-3">
            <span
              ><b>${{ selectedOrder.OrderDiscount }}</b></span
            >
          </div>
          <div
            class="col-9"
            style="text-align: right; background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custsales") }} </b>
          </div>
          <div class="col-3">
            <span v-if="selectedOrder.OrderSubTotal != 0">
              <b>${{ selectedOrder.OrderTax }}</b></span
            >
          </div>
          <div
            class="col-9"
            style="text-align: right; background-color: #f8f9fa"
          >
            <b>{{ $t("custreserv.custtotal") }} </b>
          </div>
          <div class="col-3">
            <span v-if="selectedOrder.OrderSubTotal != 0">
              <b>${{ selectedOrder.OrderTotalCost }}</b></span
            >
          </div>
        </div>
        <div
          class="col-12 flex justify-content-between"
          style="background-color: #e9ecef"
        >
          <span style="font-size: x-large"
            >{{ $t("custreserv.custcomments") }} </span
          ><Button
            icon="pi pi-plus"
            class="
              mr-2
              p-button-rounded p-button-info p-button-sm p-button-raised
            "
            @click.stop="addingComment = true"
          />
        </div>
        <div v-if="addingComment" class="col-12" style="text-align: left">
          <Textarea
            v-model="newComment.CommentText"
            :placeholder="$t('quotes.enter')"
            :autoResize="true"
            rows="5"
            cols="30"
            style="width: 100%"
          />
          <div class="flex justify-content-between">
            <Button
              :label="$t('profile.cancel')"
              icon="pi pi-times"
              iconPos="left"
              @click.stop="cancelComment"
              class="mr-2 p-button-danger p-button-sm p-button-raised"
            />
            <Button
              v-if="!savingComment"
              :label="$t('quotes.savecomment')"
              icon="pi pi-check"
              iconPos="left"
              @click.stop="saveComment"
              class="mr-2 p-button-success p-button-sm p-button-raised"
            />
            <Button
              v-else
              :label="$t('profile.saving')"
              icon="pi pi-spin pi-spinner"
              iconPos="left"
              disabled
              class="mr-2 p-button-success p-button-sm p-button-raised"
            />
          </div>
        </div>
        <div class="col-12">
          <template
            v-for="comment in selectedOrder.StoreOrderComments.sort((a, b) =>
              a.CommentDate > b.CommentDate ? -1 : 1
            )"
            :key="comment.CommentId"
          >
            <div class="card" style="text-align: left">
              <div class="flex justify-content-between">
                <span>
                  {{ comment.Cust.CustFirstName }}
                  {{ comment.Cust.CustLastName }}</span
                >
                <span> {{ $formatDateTime(comment.CommentDate) }}</span>
              </div>

              <em>{{ comment.CommentText }}</em>
            </div>
          </template>
        </div>
        <!-- 
        <div class="col-12" style="background-color: #e9ecef">
          <span style="font-size: x-large">{{
            $t("custreserv.custcomments")
          }}</span>
        </div>
        <div class="col-12">
          <div v-if="selectedOrder.StoreOrderComments.length > 0">
            <template
              v-for="comment in selectedOrder.StoreOrderComments"
              :key="comment.CommentId"
            >
              <div class="card" style="text-align: left">
                <div class="flex justify-content-between">
                  <span>
                    {{ comment.Cust.CustFirstName }}
                    {{ comment.Cust.CustLastName }}</span
                  >
                  <span> {{ $formatDateTime(comment.CommentDate) }}</span>
                </div>
                <em>{{ comment.CommentText }}</em>
              </div>
            </template>
          </div>
          <div v-else>
            {{ $t("custreserv.custcom") }}
          </div>
        </div> -->
      </div>
    </Dialog>
  </div>
</template>

<script>
import OrderPayment from "../store/Models/OrderPayment";
import StoreOrderComment from "../store/Models/StoreOrderComment";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import StripeCard from "../components/StripeCard.vue";
import Card from "../store/Models/Card";
import { mapState } from "vuex";
import StoreOrder from "../store/Models/StoreOrder";
//

export default {
  name: "CustomerStoreOrders",
  components: { LoadingOverlay, StripeCard },
  props: {
    orderId: { default: 0, type: Number },
  },
  data() {
    return {
      selectedOrder: {},
      loading: false,
      viewingOrder: false,
      viewingPayments: false,
      showingNewPayment: false,
      showingCardSelection: false,
      payOption: null,
      cardOption: null,
      webHost: process.env.VUE_APP_MYRESERVS_URL,
      newPayment: {
        OrderId: 0,
        PayAmount: null,
        PayDate: new Date(),
        PayStatus: "",
        CardId: null,
        Card: {
          CardId: 0,
          CardCvv: "",
          CardNumber: "",
          CardExpMonth: "",
          CardExpYear: "",
        },
      },
      newCardId: 0,
      selectedCard: null,
      filters: {},
      orders: [],
      customerCards: [],
      processingPayment: false,
      showPaymentAmount: false,
      errors: [],
      showSavedCards: true,
      stripe: "",
      elements: "",
      card: "",
      initialPayment: false,
      addingComment: false,
      savingComment: false,
      newComment: {},
    };
  },

  async mounted() {
    this.$log.debug("customer store orders mounted");
    this.$log.debug(this.currentCustomerId);

    if (this.currentCustomerId > 0) {
      await this.setCurrentCustomer(this.currentCustomerId);
      await this.setCustomerOrders();
    }
   //console.log("loading with order")
 //console.log(this.$route.params.orderId)
    if (this.$route.params.orderId > 0) {
      this.onLoadWithOrder();
    }
  },
  methods: {
     onLoadWithOrder() {
      //console.log(this.$route.params.orderId)
      let foundOrder = this.orders.find(
        (order) => order.OrderId == this.$route.params.orderId
      );
     //console.log(foundOrder);
      if (foundOrder) {
        this.selectedOrder = foundOrder;
        this.openOrder(this.selectedOrder);
        this.$router.replace("/CustomerStoreOrders");
      }
      this.loading = false;
    },
    async saveComment() {
      this.savingComment = true;
      this.newComment.OrderId = this.selectedOrder.OrderId;
      this.newComment.CustId = this.currentCustomerId;
      this.newComment.CommentDate = new Date();
      await StoreOrderComment.addComment(this.newComment).then(async () => {
        this.setCustomerOrders();
        this.newComment = {};
        this.addingComment = false;
        this.savingComment = false;
      });
    },
    cancelComment() {
      this.addingComment = false;
      this.newComment = {};
    },
    getStatusDesc(status) {
      switch (status) {
        case "Submitted":
          return this.$t("store.statuscustrequested");
        case "Approved":
          return this.$t("store.statuscustaccepted");
        case "Completed":
          return this.$t("store.statuscustcompleted");
        case "RequestedCancel":
          return this.$t("store.statuscustrequestedcancel");
        case "Canceled":
          return this.$t("store.statuscustcanceled");
        default:
          return "";
      }
    },
    getStatusTag(status) {
      switch (status) {
        case "Submitted":
          return "primary";
        case "Approved":
          return "success";
        case "Canceled":
          return "danger";
        case "RequestedCancel":
          return "warning";
        case "Completed":
          return "info";
        default:
          return "info";
      }
    },
    openOrder(order) {
      this.$log.debug(order);
      this.selectedOrder = order;
      this.viewingOrder = true;
    },
    openPayments(order) {
      this.selectedOrder = order;
      this.viewingPayments = true;
    },
    resetNewPayment() {
      this.initialPayment = false;
      this.showingNewPayment = false;
      this.showPaymentAmount = false;
      this.showingCardSelection = false;
      this.cardOption = null;
      this.selectedCard = null;
      this.newCardId = null;
      this.newPayment = {
        OrderId: 0,
        PayAmount: null,
        PayDate: new Date(),
        PayStatus: "",
        CardId: null,
        Card: {
          CardCvv: "",
          CardNumber: "",
          CardExpMonth: "",
          CardExpYear: "",
        },
      };
    },
    async selectCard() {
      this.errors = [];
      if (this.cardOption == "newCard") {
        await this.$refs.stripeCard.createCard().then((response) => {
          this.$log.debug("New Card");
          this.$log.debug(response);
          if (response != null) {
            this.newCardId = response;
            this.showingCardSelection = false;
            this.showPaymentAmount = true;
          }
        });
      }

      if (this.cardOption == "savedCard") {
        if (this.selectedCard != null) {
          this.showingCardSelection = false;
          this.showPaymentAmount = true;
        } else {
          this.errors.push(this.$t("custreserv.selectacard"));
          document.getElementById("paymentCards").classList.add("p-invalid");
        }
      }
    },

    showNewPayment(order) {
      this.$log.debug("new payment" + this.initialPayment);
      this.$log.debug(order);
      Card.fetchByCustId(this.currentCustomerId).then((result) => {
        this.$log.debug(result);
        this.customerCards = result;
      });
      if (this.customerCards.length > 0) {
        this.cardOption = "savedCard";
      } else {
        this.cardOption = "newCard";
      }
      if (this.initialPayment) {
        if (order.OrderTotalOwed < order.Bus.BusDownPmtAmt) {
          this.newPayment.PayAmount = order.OrderTotalOwed;
        } else {
          this.newPayment.PayAmount = order.Bus.BusDownPmtAmt;
        }

        this.payOption = "otherAmt";
      }

      this.selectedOrder = order;
      this.showingCardSelection = true;
      this.showingNewPayment = true;
    },

    validatePayment() {
      this.$log.debug("validating payment");
      this.$log.debug(this.newPayment);
      this.processingPayment = true;
      //clear out errors
      this.errors = [];

      if (this.cardOption == null) {
        this.errors.push(this.$t("custreserv.cardoption"));
      }
      if (this.payOption == null) {
        this.errors.push(this.$t("custreserv.paymentoption"));
      }

      if (this.payOption == "otherAmt" && this.newPayment.PayAmount == null) {
        this.errors.push(this.$t("custreserv.paymentoption"));
        document.getElementById("paymentAmount").classList.add("p-invalid");
      }

      if (this.cardOption == "savedCard") {
        if (this.selectedCard == null) {
          this.errors.push(this.$t("custreserv.nocardselected"));
          document.getElementById("paymentCards").classList.add("p-invalid");
        }

        if (this.newPayment.PayAmount > this.selectedOrder.OrderTotalOwed) {
          this.errors.push(this.$t("custreserv.paymenttoobig"));
          document.getElementById("paymentAmount").classList.add("p-invalid");
        }
      }

      this.$log.debug(this.errors);
      if (this.errors.length == 0) {
        this.startPayment();
      } else {
        this.processingPayment = false;
      }
    },
    async startPayment() {
      //make payment
      if (this.payOption == "totalOwed") {
        this.newPayment.PayAmount = this.selectedOrder.OrderTotalOwed;
      }

      if (this.cardOption == "savedCard") {
        this.newPayment.CardId = this.selectedCard.CardId;
      }

      if (this.cardOption == "newCard") {
        this.newPayment.CardId = this.newCardId;
      }

      this.newPayment.OrderId = this.selectedOrder.OrderId;

      OrderPayment.newPayment(this.newPayment).then(async (response) => {
        this.$log.debug(response);
        this.setCustomerOrders();
        this.processingPayment = false;

        if (response == 0) {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("payments.failedpayment"),
            life: 3000,
          });
        } else if (response == 1) {
          this.$log.debug(this.selectedOrder);
          this.showingNewPayment = false;
          this.resetNewPayment();
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("payments.successfulpayment"),
           life: 3000,
          });
        }
      });
    },

    async setCustomerOrders() {
     //console.log("set customer orders");
      this.loading = true;
      await StoreOrder.fetchByCustId(this.currentCustomerId).then((result) => {
        this.$log.debug(result);
        this.orders = result;

        this.$log.debug(this.selectedOrder.OrderId);
        if (this.selectedOrder != null && this.selectedOrder.OrderId != null) {
          this.selectedOrder = StoreOrder.query()
            .withAllRecursive()
            .find(this.selectedOrder.OrderId);
          this.$log.debug(this.selectedOrder);
        }
      });
      this.loading = false;
    },
    async setCurrentCustomer() {
      this.loading = true;
      await Card.fetchByCustId(this.currentCustomerId).then((result) => {
        this.$log.debug(result);
        this.customerCards = result;
        if (!(this.customerCards != null && this.customerCards.length > 0)) {
          this.showSavedCards = false;
          this.cardOption = "newCard";
        }
      });
      this.loading = false;
    },
    async requestCancel(order) {
      this.$confirm.require({
        message: this.$t("store.cancelrequest"),
        header: this.$t("custreserv.requestcancelation"),
        icon: "pi pi-calendar-plus",
        acceptLabel: this.$t("menu.yes"),
        accept: async () => {
          await StoreOrder.requestCancel(order).then(async (response) => {
            this.$log.debug(response);
            this.setCustomerOrders();
            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("custreserv.cancelationrequested"),
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("custreserv.requestfailed"),
                life: 3000,
              });
            }
          });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCustomerOrders();
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
  },
};
</script>
<style>
.p-datatable-header {
  background-color: white !important;
}
</style>
