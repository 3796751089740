import { Model } from '@vuex-orm/core'
import Business from "./Business"
import Subscription from "./Subscription"
import Card from "./Card"

export default class SubPayment extends Model {
  static entity = 'subPayements'
  
  static primaryKey = 'SubPayId'
  
  static fields () {
    return {
      
      SubPayId: this.uid(),
      SubId: this.attr(null),
      Business: this.hasOne(Business, 'BusId'),
      PayAmount: this.attr(null),
      PayDate: this.attr(null),
      PayStatus: this.attr(null),
      CardId: this.attr(null),
      PayPeriodStart: this.attr(null),
      PayPeriodEnd: this.attr(null),
      PayProviderId: this.attr(null),
      PayInvoiceUrl: this.attr(null),
      PayInvoiceNum: this.attr(null),

      //virtual
      Subscription: this.hasOne(Subscription, 'SubId'),
      Card: this.belongsTo(Card, 'CardId')
    }
  }
}

