<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>

  <div v-else class="content mt-3">
    <div class="flex">
      <Button
        icon="pi pi-plus"
        style="float: right"
        :label="$t('guestlists.newguestlist')"
        class="p-button-primary ml-auto mb-2"
        @click.stop="creatingList = true"
      />
    </div>

    <DataView :value="guestLists" layout="list" class="service-grid">
      <template #empty>
        {{ $t("guestlists.noguestlists") }}
      </template>
      <template #list="slotProps">
        <div class="col-12 md:col-6 lg:col-4">
          <Card class="m-2">
            <template #title>
              <div
                class="flex flex-wrap justify-content-between align-content-end"
              >
                <div>
                  <Button
                    v-if="slotProps.data.CustId == currentCustomerId"
                    icon="pi pi-trash"
                    v-tooltip.right="$t('guestlists.delete')"
                    class="p-button-danger p-button-sm m-1"
                    @click.stop="deleteGuestList(slotProps.data.ListId)"
                  />
                   <Button
                    v-else
                    icon="pi pi-trash"
                        v-tooltip.right="$t('guestlists.notowner')"
                    class="p-button-danger p-button-sm m-1"
                    disabled
                  />
                </div>
                <div>
                  <Button
                    icon="pi pi-pencil"
                    v-tooltip.right="$t('menu.edit')"
                    class="p-button-info p-button-sm m-1"
                    @click.stop="openGuestListDetails(slotProps.data)"
                  />
                  <Button
                    v-if="slotProps.data.CustId == currentCustomerId"
                    icon="pi pi-share-alt"
                    v-tooltip.right="$t('guestlists.share')"
                 
                    class="p-button-success p-button-sm m-1"
                    @click.stop="startShare(slotProps.data)"
                  />
                  <Button
                    v-else
                    icon="pi pi-share-alt"
                    v-tooltip.right="$t('guestlists.notowner')"
                 
                    class="p-button-success p-button-sm m-1"
                    disabled
                  />
                </div>
              </div>

              {{ slotProps.data.ListName }}
            </template>

            <template v-slot:content>
              <div class="flex-grow-1">
                <div v-if="slotProps.data.ListDesc.length > 0" calss="mb-1">
                  {{ slotProps.data.ListDesc }}
                </div>
                <div class="flex flex-wrap justify-content-center">
                  <div class="m-1">
                    <Badge
                      size="large"
                      :value="slotProps.data.ListTotalParties"
                    ></Badge>
                    <br />
                    <b
                      ><small
                        >{{ $t("guestlists.listtotalparties") }}
                      </small></b
                    >
                  </div>
                  <div class="m-1">
                    <Badge
                      size="large"
                      :value="slotProps.data.ListTotalPeople"
                    ></Badge
                    ><br />
                    <b
                      ><small>{{ $t("guestlists.listtotalpeople") }}</small>
                    </b>
                  </div>
                  <div class="m-1">
                    <Badge
                      size="large"
                      :value="slotProps.data.ListTotalAdults"
                    ></Badge
                    ><br />
                    <b
                      ><small>{{ $t("guestlists.listadults") }}</small>
                    </b>
                  </div>
                  <div class="m-1">
                    <Badge
                      size="large"
                      :value="slotProps.data.ListTotalKids"
                    ></Badge
                    ><br />
                    <b
                      ><small>{{ $t("guestlists.listchildren") }} </small></b
                    >
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <Chip
                v-if="slotProps.data.CustId != currentCustomerId"
                :label="$t('guestlists.sharedwithyou')"
                class="chip-sm success-chip mt-1"
              />
                <Chip
                v-if="slotProps.data.CustId == currentCustomerId && slotProps.data.SharedGuestLists.length>0"
                :label="$t('guestlists.shared')"
                class="chip-sm success-chip mt-1"
              />
            </template>
          </Card>
        </div>
      </template>
    </DataView>
    <!-- SHARE DIALOG -->
    <Dialog
      v-model:visible="showShareGuestlist"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
     
    >
      <template #header>
        <label>{{ $t("guestlists.sharing") }} </label>
      </template>
      <div class="p-fluid formgrid grid">
        <div class="field col-12">
          <label>{{ $t("guestlists.sharewith") }}</label>
          <div class="p-inputgroup flex-1">
            <InputText
              type="text"
              v-model="share.EmailToShare"
              placeholder="something@something.com"
            />
            <Button
              v-if="!sharing"
              icon="pi pi-share-alt"
              @click.stop="shareGuestList()"
              :label="$t('custevent.share')"
            />
            <Button v-else icon="pi pi-spin pi-spinner "    :label="$t('custevent.share')" disabled />
          </div>
        </div>
        <div v-if="selectedList.SharedGuestLists.length>0" class="field col-12">
          <div class="card">
            <label>{{ $t("guestlists.sharedwith") }}</label>
            <hr />
            <template
              v-for="share in selectedList.SharedGuestLists"
              :key="share.ShareId"
            >
              <div v-if="share.Cust!=null" class="field col-12 flex justify-content-between">
                <div class="vertical-align-middle">
                  <span v-if="share.Cust.CustFirstName.length > 0"
                    >{{ share.Cust.CustFirstName }}
                    {{ share.Cust.CustLastName }} <br
                  /></span>
                  {{ share.Cust.CustEmail }}
                </div>
                <Button
                  icon="pi pi-times"
                  class="ml-2 p-button-text p-button-danger"
                  @click.stop="removeShare(share.ShareId)"
               
                />
              </div>
              <hr />
            </template>
          </div>
        </div>
      </div>
      <!-- <template #footer>
        <div class="flex justify-content-around mt-3">
          <Button
            :label="$t('profile.cancel')"
            icon="pi pi-times"
            @click.stop="resetSharing()"
            class="p-button-secondary"
          />
        
        </div>
      </template> -->
    </Dialog>

    <!-- EDIT LIST DIALOG -->
    <Dialog
      v-model:visible="editingList"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <template #header>
        <label> {{  $t("guestlists.editing") }}</label>
      </template>

      <div class="p-fluid formgrid grid">
         <div class="field col-12">
          <label 
            >{{ $t("guestlists.listowner") }}:
           </label
          >
         {{selectedList.Cust.CustFirstName}} {{selectedList.Cust.CustLastName}} ({{selectedList.Cust.CustEmail}})
        </div>
        <div class="field col-12">
          <label for="name"
            >{{ $t("guestlists.listName") }}
            <span class="required-star">*</span></label
          >
          <InputText id="name" type="text" v-model="selectedList.ListName" />
        </div>

        <div class="field col-12">
          <label>{{ $t("guestlists.listdesc") }} </label>
          <Textarea rows="2" v-model="selectedList.ListDesc" />
        </div>
        <div class="col-6 text-center">
          <label
            >{{ $t("guestlists.listparty") }}
            <span class="required-star">*</span></label
          >
        </div>

        <div class="col-2 text-center">
          <label>{{ $t("guestlists.listadults") }} </label>
        </div>
        <div class="col-2 text-center">
          <label>{{ $t("guestlists.listchildren") }} </label>
        </div>
        <template
          v-for="(party, index) in selectedList.GuestParties"
          :key="index"
        >
          <div class="field col-6">
            <InputText
              :id="'partyName' + index"
              type="text"
              v-model="party.PartyName"
            />
          </div>

          <div class="field col-2 text-center">
            <InputNumber v-model="party.PartyTotalAdults" />
          </div>
          <div class="field col-2 text-center">
            <InputNumber v-model="party.PartyTotalKids" />
          </div>
          <div class="field col-2 text-center">
            <Button
              icon="pi pi-trash"
              class="p-button-danger p-button-sm p-button-text"
              @click.stop="deletePartyFromSelected(index)"
            />
          </div>
        </template>

        <div class="field col-2">
          <Button
            icon="pi pi-plus ml"
            class="p-button-success p-button-sm"
            @click.stop="addPartyToSelected()"
          />
        </div>
      </div>

      <template #footer>
        <div class="grid">
          <div class="col-12 text-center">
            Total
            <hr />
          </div>
          <div class="col-6 text-center">
         {{ $t("guestlists.listtotalparties") }} <br /><Badge
              :value="selectedList.GuestParties.length"
              severity="warning"
            ></Badge>
          </div>
          <div class="col-2 text-center">
           {{ $t("guestlists.listadults") }}<br /><Badge
              :value="
                selectedList.GuestParties.reduce((total, party) => {
                  return total + party.PartyTotalAdults;
                }, 0)
              "
              severity="info"
            ></Badge>
          </div>
          <div class="col-2 text-center">
            {{ $t("guestlists.listchildren") }}<br /><Badge
              :value="
                selectedList.GuestParties.reduce((total, party) => {
                  return total + party.PartyTotalKids;
                }, 0)
              "
              severity="warning"
            ></Badge>
          </div>
          <div class="col-2"></div>
        </div>
        <div class="flex justify-content-around mt-3">
          <Button
            :label="$t('profile.cancel')"
            icon="pi pi-times"
            @click.stop="
              resetSelectedList();
              editingList = false;
            "
            class="p-button-secondary"
          />
          <Button
            v-if="!editing"
            :label="$t('profile.save')"
            icon="pi pi-check"
            @click.stop="editingSelectedList()"
          />
          <Button
            v-else
            :label="$t('profile.saving')"
            icon="pi pi-spin pi-spinner "
            disabled
          />
        </div>
      </template>
    </Dialog>

    <!-- create dialog -->
    <Dialog
      v-model:visible="creatingList"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <template #header>
        <label> <i class="pi pi-id-pencil"></i>{{ "New Guest List" }}</label>
      </template>

      <div class="p-fluid formgrid grid text-left">
        <div class="field col-12">
          <label
            >{{ $t("guestlists.listName") }}
            <span class="required-star">*</span></label
          >
          <InputText type="text" v-model="newGuestList.ListName" />
        </div>

        <div class="field col-12">
          <label>{{ $t("guestlists.listdesc") }} </label>
          <Textarea rows="2" v-model="newGuestList.ListDesc" />
        </div>
        <div class="field col-6 text-center">
          <label
            >{{ $t("guestlists.listparty") }}
            <span class="required-star">*</span></label
          >
        </div>

        <div class="field col-2 text-center">
          <label for="state">{{ $t("guestlists.listadults") }} </label>
        </div>
        <div class="field col-2 text-center">
          <label for="zip">{{ $t("guestlists.listchildren") }} </label>
        </div>
        <template
          v-for="(party, index) in newGuestList.GuestParties"
          :key="index"
        >
          <div class="field col-6">
            <InputText
              :id="'partyName' + index"
              type="text"
              v-model="party.PartyName"
            />
          </div>

          <div class="field col-2 text-center">
            <InputNumber v-model="party.PartyTotalAdults" />
          </div>
          <div class="field col-2 text-center">
            <InputNumber v-model="party.PartyTotalKids" />
          </div>
          <div class="field col-2">
            <Button
              icon="pi pi-trash ml"
              class="p-button-danger p-button-sm"
              @click.stop="deleteParty(index)"
            />
          </div>
        </template>
        <div class="field col-2">
          <Button
            icon="pi pi-plus ml"
            class="p-button-success p-button-sm"
            @click.stop="addParty()"
          />
        </div>
      </div>

      <template #footer>
        <div class="grid">
          <div class="col-12 text-center">
            Total
            <hr />
          </div>
          <div class="col-6 text-center">
            Invites <br /><Badge
              :value="newGuestList.GuestParties.length"
              severity="warning"
            ></Badge>
          </div>
          <div class="col-2 text-center">
            Adults<br /><Badge
              :value="
                newGuestList.GuestParties.reduce((total, party) => {
                  return total + party.PartyTotalAdults;
                }, 0)
              "
              severity="info"
            ></Badge>
          </div>
          <div class="col-2 text-center">
            Kids<br /><Badge
              :value="
                newGuestList.GuestParties.reduce((total, party) => {
                  return total + party.PartyTotalKids;
                }, 0)
              "
              severity="warning"
            ></Badge>
          </div>
          <div class="col-2"></div>
        </div>
        <div class="flex justify-content-around mt-3">
          <Button
            :label="$t('profile.cancel')"
            icon="pi pi-times"
            @click.stop="
              resetNewGuestList();
              creatingList = false;
            "
            class="p-button-secondary"
          />
          <Button
            v-if="saving == false"
            :label="$t('profile.save')"
            icon="pi pi-check"
            @click.stop="newGuestListCreate"
          />
          <Button
            v-else
            :label="$t('profile.save')"
            icon="pi pi-spin pi-spinner "
            disabled
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import GuestList from "../store/Models/GuestList";
//import ReservPayment from "../store/Models/ReservPayment";
import LoadingOverlay from "../components/LoadingOverlay.vue";
//import StripeCard from "../components/StripeCard.vue";
//import Card from "../store/Models/Card";
import { mapState } from "vuex";
import SharedGuestList from "../store/Models/SharedGuestList";
//

export default {
  name: "CustomerGuestList",
  components: { LoadingOverlay },
  data() {
    return {
      saving: false,
      editing: false,
      selectedList: {},
      loading: false,
      creatingList: false,
      editingList: false,
      guestLists: [],
      newGuestList: {
        ListId: 0,
        CustId: 0,
        ListName: "",
        ListDesc: "",
        ListTotalParties: 0,
        ListTotalPeople: 0,
        ListTotalAdults: 0,
        ListTotalKids: 0,
        GuestParties: [
          {
            PartyName: "",
            PartyTotalAdults: null,
            PartyTotalKids: null,
          },
        ],
      },

      webHost: process.env.VUE_APP_MYRESERVS_URL,

      errors: [],
      share: {
        ListId: 0,
        EmailToShare: "",
      },
      showShareGuestlist: false,
      sharing: false,
    };
  },

  async mounted() {
    this.$log.debug("customer guestlist mounted");
    this.$log.debug(this.currentCustomerId);

    if (this.currentCustomerId > 0) {
      await this.setCustomerGuestLists();
    }
    // if (this.$route.params.ListId > 0) { TODO
    //   this.onLoadWithGuestList();
    // }
  },
  methods: {
    startShare(list) {
      this.selectedList = list;
      this.share.ListId = list.ListId;
      this.showShareGuestlist = true;
    },
    removeShare(shareId){
      SharedGuestList.remove(shareId).then(async (response) => {
        this.$log.debug(response);
        if (response == 1) {
          this.setCustomerGuestLists();
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("guestlists.accessremoved"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("guestlists.accessfailedtoremove"),
            life: 3000,
          });
        }
      });
    },
    async shareGuestList() {
      this.sharing = true;
      await SharedGuestList.share(this.share).then(async (response) => {
        this.$log.debug(response);
        this.sharing = false;
     this.setCustomerGuestLists();
        if (response == 1) {
          this.$toast.add({
            severity: "success",
            summary: this.$t("custreserv.success"),
            detail: this.$t("guestlists.sharesuccess"),
            life: 3000,
          });
          this.resetSharing();
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("code.errors"),
            detail: this.$t("guestlists.sharefailed"),
            life: 3000,
          });
        }
      });
    },
    resetSharing() {
      this.share = {
        ListId: 0,
        EmailToShare: "",
      };
     
      this.sharing = false;
    },
    deleteGuestList(listId) {
     //console.log(listId);
      this.$confirm.require({
        message: this.$t("guestlists.deleteconfirmdesc"),
        header: this.$t("guestlists.deleteconfirm"),
        icon: "pi pi-exclamation-triangle",
        acceptLabel: this.$t("code.continue"),
        rejectLabel: this.$t("code.cancel"),
        accept: async () => {
          this.loading = true;
          await GuestList.deleteGuestList(listId).then((result) => {
            this.$log.debug(result);
            this.setCustomerGuestLists();
            this.loading = false;
          }); //todo ensure wait
        },
        reject: () => {
          this.$confirm.close();
          this.loading = false;
        },
      });
    },

    openGuestListDetails(guestListdata) {
      this.$log.debug(guestListdata);
      this.selectedList = guestListdata;
      this.editingList = true;

      //for quotes not yet if date is selected, verify date is still available, if not, notify the user.
    },
    resetSelectedList() {
      this.selectedList = GuestList.query()
        .withAllRecursive()
        .find(this.selectedList.ListId);
    },
    resetNewGuestList() {
      this.newGuestList = {
        ListId: 0,
        CustId: 0,
        ListName: "",
        ListDesc: "",
        ListTotalParties: 0,
        ListTotalPeople: 0,
        ListTotalAdults: 0,
        ListTotalKids: 0,
        GuestParties: [
          {
            PartyName: "",
            PartyTotalAdults: null,
            PartyTotalKids: null,
          },
        ],
      };
    },
    addParty() {
      this.newGuestList.GuestParties.push({
        PartyName: "",
        PartyTotalAdults: null,
        PartyTotalKids: null,
      });
    },
    addPartyToSelected() {
      this.selectedList.GuestParties.push({
        PartyName: "",
        PartyTotalAdults: null,
        PartyTotalKids: null,
      });
    },
    deleteParty(index) {
      this.newGuestList.GuestParties.splice(index, 1);
    },
    deletePartyFromSelected(index) {
      this.selectedList.GuestParties.splice(index, 1);
    },

    async setCustomerGuestLists() {
      this.loading = true;
      await GuestList.fetchByCustId(this.currentCustomerId).then((result) => {
        this.$log.debug(result);
        this.guestLists = result;

        this.$log.debug(this.selectedList);
        if (this.selectedList != null && this.selectedList.ListId != null) {
          this.selectedList = GuestList.query()
            .withAllRecursive()
            .find(this.selectedList.ListId);
        }
        this.loading = false;
      });
    },
    async newGuestListCreate() {
      this.saving = true;
      this.$log.debug("creating guestlist");

      this.errors = [];
      this.newGuestList.CustId = this.currentCustomerId;
      this.$log.debug(this.newGuestList);

      if (!this.newGuestList.ListName.length > 0) {
        document.getElementById("name").classList.add("p-invalid");
        this.errors.push("Valid Name Required");
      }
      this.newGuestList.GuestParties.forEach((party, index) => {
       //console.log(party);
       //console.log(index);
        if (!party.PartyName.length > 0) {
          document
            .getElementById("partyName" + index)
            .classList.add("p-invalid");
          this.errors.push("Valid Name Required");
        }
        party.PartyTotalAdults =
          party.PartyTotalAdults != null ? party.PartyTotalAdults : 0;
        party.PartyTotalKids =
          party.PartyTotalKids != null ? party.PartyTotalKids : 0;
      });
      if (this.errors.length == 0) {
        await GuestList.createGuestList(this.newGuestList).then(
          async (response) => {
            this.$log.debug(response);
            this.saving = false;

            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("guestlists.createsuccess"),
                life: 3000,
              });
              this.creatingList = false;
              this.resetNewGuestList();
              this.setCustomerGuestLists();
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("guestlists.createerror"),
                life: 3000,
              });
            }
            this.$log.debug("errors " + this.errors.length);
            this.$log.debug(this.errors);
            this.saving = false;
            return false;
          }
        );
      } else {
        this.saving = false;
      }
    },

    async editingSelectedList() {
      this.editing = true;
      this.$log.debug("creating guestlist");

      this.errors = [];
      this.selectedList.CustId = this.currentCustomerId;
      this.$log.debug(this.selectedList);

      if (!this.selectedList.ListName.length > 0) {
        document.getElementById("name").classList.add("p-invalid");
        this.errors.push("Valid Name Required");
      }
      this.selectedList.GuestParties.forEach((party, index) => {
       //console.log(party);
       //console.log(index);
        if (!party.PartyName.length > 0) {
          document
            .getElementById("partyName" + index)
            .classList.add("p-invalid");
          this.errors.push("Valid Name Required");
        }
        party.PartyTotalAdults =
          party.PartyTotalAdults != null ? party.PartyTotalAdults : 0;
        party.PartyTotalKids =
          party.PartyTotalKids != null ? party.PartyTotalKids : 0;
      });
      if (this.errors.length == 0) {
        await GuestList.updateGuestList(this.selectedList).then(
          async (response) => {
            this.$log.debug(response);
            this.editing = false;

            if (response == 1) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("custreserv.success"),
                detail: this.$t("guestlists.edit"),
                life: 3000,
              });
              this.editingList = false;
              this.resetSelectedList();
              this.setCustomerGuestLists();
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("code.errors"),
                detail: this.$t("guestlists.noedit"),
                life: 3000,
              });
            }
            this.$log.debug("errors " + this.errors.length);
            this.$log.debug(this.errors);
            this.editing = false;
            return false;
          }
        );
      } else {
        this.editing = false;
      }
    },
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) this.setCustomerGuestLists();
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: (state) => state.currentCustomerId,
    }),
    imageHostingURL() {
      return process.env.VUE_APP_IMAGE_HOSTING_URL;
    },
    selectedTotalAdults() {
      return this.selectedList.GuestParties.reduce((total, party) => {
        return total + party.PartyTotalAdults;
      }, 0);
    },
    selectedTotalKids() {
      return this.selectedList.GuestParties.reduce((total, party) => {
        return total + party.PartyTotalKids;
      }, 0);
    },
  },
};
</script>
<style>
.p-datatable-header {
  background-color: white !important;
}
</style>
