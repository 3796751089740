import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Business from "./Business"

export default class BusStats extends Model {
  static entity = 'busStats'
  
  static primaryKey = 'BusId'
  
  static fields () {
    return {
      BusId: this.uid(),
      DaysLength: this.attr(null),
      BusViews: this.attr(null),
      BusQuotes: this.attr(null),
      BusOrders:this.attr(null),
      BusPayments:this.attr(null),
      BusPayAmount:this.attr(null),
      BusReservs:this.attr(null),
      BusUnreadMessage:this.attr(null),
      BusPendingQuote:this.attr(null),
      BusPendingOrders:this.attr(null),
    }
  }

  static async fetch(busId, daysLength) {
    //console.log("fetching bus stats")
 
     const result =  await Business.api().get('/api/businesses/GetBusinessStats/busId/'+busId+'/daysLength/'+daysLength).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return result.response.data;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return result.response.data;
        }
        else{
          //console.log("error");
          return null;
        } 
      }).catch(error => {
        //console.log(error.response);
        return null;
      });
      return result;
      }
}

