  // TODO: USE THIS TEMPLATE FOR THE BLOCK STYLE MENUES
<template>
  <div
    id="navigation"
    v-if="userLoggedIn == true"
    class="col-12"
    style="font-size: small"
  >
    <div class="flex flex-wrap justify-content-center">
      <div
        class="m-2 white-menu-button"
        @click.stop="navigate('CustomerHome', 'home')"
      >
        <i class="pi pi-home" style="font-size: x-large; display: block"></i
        >{{ $t("menu.home") }}
      </div>
      <div
        class="m-2 white-menu-button"
        @click.stop="navigate('CustomerReservations', 'reserv')"
      >
        <i class="pi pi-ticket" style="font-size: x-large; display: block"></i
        >{{ $t("menu.reservs") }}
      </div>

      <div
        class="m-2 white-menu-button"
        @click.stop="navigate('CustomerQuotes', 'quotes')"
      >
        <i class="pi pi-dollar" style="font-size: x-large; display: block"></i
        >{{ $t("menu.quotes") }}
      </div>
        <!-- <div
        class="m-2 white-menu-button"
        @click.stop="navigate('CustomerStoreOrders', 'orders')"
      >
        <i class="pi pi-shopping-cart" style="font-size: x-large; display: block"></i
        >{{ $t("store.storeorders") }}
      </div> -->
      <div
        class="m-2 white-menu-button"
        @click.stop="navigate('CustomerFavorites', 'fav')"
      >
        <i class="pi pi-heart" style="font-size: x-large; display: block"></i
        >{{ $t("menu.favorites") }}
      </div>
      <div
        class="m-2 white-menu-button"
        @click.stop="
          unreadMessageCount = 0;
          navigate('CustomerMessages', 'mess');
        "
      >
        <i
          class="pi pi-comments"
          v-if="unreadMessageCount > 0"
          v-badge="unreadMessageCount"
          style="font-size: x-large; display: block"
        ></i>
        <i
          v-else
          class="pi pi-comments"
          style="font-size: x-large; display: block"
        ></i
        >{{ $t("menu.message") }}
      </div>
      <div
        :class="{ boxshadow: unreadNotifications > 0 }"
        class="m-2 white-menu-button"
        @click.stop="showingNotifications = true"
      >
        <i
          v-if="unreadNotifications > 0"
          class="pi pi-bell"
          style="font-size: x-large; display: block"
          v-badge="unreadNotifications"
        ></i>
        <i
          v-else
          class="pi pi-bell"
          style="font-size: x-large; display: block"
        ></i
        >{{ $t("menu.notifications") }}
      </div>
            <div
        class="m-2 white-menu-button"
        @click.stop="navigate('CustomerAppointments', 'appts')"
      >
        <i class="pi pi-calendar-plus" style="font-size: x-large; display: block"></i
        >{{ $t("menu.appts") }}
      </div>
      <div
        v-if="customerBusinesses != null && customerBusinesses.length > 0"
        class="m-2 white-menu-button"
        @click.stop="navigate('BusinessDashboard', 'bus')"
      >
        <i
          class="pi pi-briefcase"
          style="font-size: x-large; display: block"
        ></i
        >{{ $t("menu.mybusiness") }}
      </div>
      <!-- @click.stop="navigate('CustomerProfile', 'acct')" -->

      <div
        class="m-2 white-menu-button"
        @click.stop="showAccountMenu"
        aria-haspopup="true"
        aria-controls="overlay_menu"
      >
        <i class="pi pi-user" style="font-size: x-large; display: block"></i
        >{{
          currentCustomer != null && currentCustomer.CustFirstName != null
            ? currentCustomer.CustFirstName
            : $t("menu.account")
        }}
      </div>
    </div>
  </div>
  <Menu id="overlay_menu" ref="acctMenu" :model="accountMenu" :popup="true" />
    <OverlayPanel ref="mnuOverlay">
      <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerStoreOrders', '')"
    >
      <i class="pi pi-shopping-cart mr-2" style="font-size: x-large"></i
      >{{ this.$t("store.storeorders") }}
    </div>
       <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerAppointments', '')"
    >
      <i class="pi pi-calendar-plus mr-2" style="font-size: x-large"></i
      >{{ this.$t("menu.appts") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerProfile', '')"
    >
      <i class="pi pi-id-card mr-2" style="font-size: x-large"></i
      >{{ this.$t("businessprofile.profile") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerGuestList', '')"
    >
      <i class="pi pi-list mr-2" style="font-size: x-large"></i
      >{{ this.$t("guestlists.guestlists") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('CustomerPaymentCards', '')"
    >
      <i class="pi pi-money-bill mr-2" style="font-size: x-large"></i
      >{{ this.$t("payments.paymentcard") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="navigate('BusinessSubscriptions', '')"
    >
      <i class="pi pi-shield mr-2" style="font-size: x-large"></i
      >{{ this.$t("subscriptions.businesssubscriptions") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="switchLanguage()"
    >
      <i class="pi pi-sync mr-2" style="font-size: x-large"></i
      >{{ this.$t("profile.otherlanguage") }}
    </div>
    <div
      class="m-2 pt-1 pb-1 menu-button pointer flex align-content-center"
      @click.stop="$SignOutRedirect()"
    >
      <i class="pi pi-sign-out mr-2" style="font-size: x-large"></i
      >{{ this.$t("menu.signout") }}
    </div>
  </OverlayPanel>
  <Sidebar
    v-model:visible="showingNotifications"
    :baseZIndex="1000"
    position="right"
    @hide="markAsRead"
  >
    <notifications></notifications>
  </Sidebar>
</template>
<script>
import { mapState } from "vuex";
import Notifications from "../components/Notifications.vue";
import Notification from "../store/Models/Notification";
import Message from "../store/Models/Message";
import { mapActions } from "vuex";

export default {
  name: "BlockMenuBar",
  props: {
    styleClass: { default: "menu-button", type: String },
  },
  emits: [],
  components: { Notifications },
  data() {
    return {
      showingNotifications: false,
      unreadMessageCount: 0,
      notifications: [],
      accountMenu: [
            {
          label: this.$t("menu.appts"),
          icon: "pi pi-id-calendar-plus",
          to: "/CustomerAppointments",
        },
        {
          label: this.$t("businessprofile.profile"),
          icon: "pi pi-id-card",
          to: "/CustomerProfile",
        },
        {
          label: this.$t("payments.paymentcard"),
          icon: "pi pi-money-bill",
          to: "/CustomerPaymentCards",
        },
        {
          label: this.$t("subscriptions.businesssubscriptions"),
          icon: "pi pi-shield",
          to: "/BusinessSubscriptions",
        },
        {
          label: this.$t("menu.signout"),
          icon: "pi pi-sign-out",
          command: () => {
            this.$SignOutRedirect();
          },
        },
      ],
    };
  },
  mounted() {
    this.$log.debug("block menu mounted");
    if (this.currentCustomerId > 0 && this.userLoggedIn == true) {
      this.setNotifications();
      this.getUnreadMessageCount();
    }
  },
  methods: {
    ...mapActions(["switchLang"]),
    async getUnreadMessageCount() {
      await Message.unreadMessageCount(this.currentCustomerId).then(
        (result) => {
          this.$log.debug("unread count");
          this.$log.debug(result);
          this.unreadMessageCount = result;
        }
      );
    },
    setLang() {
      this.$log.debug(this.lang);
      if (this.lang == "en") {
        this.otherLang = "Español";
        this.$i18n.locale = "en";
      }
      if (this.lang == "es") {
        this.otherLang = "English";
        this.$i18n.locale = "es";
      }
      document.getElementsByTagName("html")[0].lang = this.lang;
    },
    switchLanguage() {
      var newLang = "en";
      if (this.lang == "en") {
        newLang = "es";
        this.otherLang = "English";
      } else if (this.lang == "es") {
        newLang = "en";
        this.otherLang = "Español";
      }

      this.switchLang(newLang);
      this.$i18n.locale = newLang;
    },
    navigate(toRoute, activeId) {
      this.$log.debug(toRoute);
      this.$log.debug(activeId);
      this.activeMenuId = activeId;
      this.$router.push({
        name: toRoute,
      });
    },
    async markAsRead() {
      this.$log.debug("marking as read");
      await Notification.markAsRead(this.currentCustomerId).then(() => {
        this.setNotifications();
      });
    },
    showAccountMenu(event) {
      this.$refs.mnuOverlay.toggle(event);
    },
    showNotifications(event) {
      this.$refs.notificationsOverlay.toggle(event);
    },
    setNotifications() {
      this.notifications = [];
      if (this.currentCustomerId > 0) {
        Notification.fetchByCustId(this.currentCustomerId).then((result) => {
          this.notifications = result;
        });
      }
    },
  },
  watch: {
    async currentCustomerId() {
      if (this.currentCustomerId > 0) {
        this.setNotifications();
      } else {
        this.notifications = [];
      }
    },
    lang(){
      this.$log.debug("lang changed");
      this.setLang();
    }
  },
  computed: {
    ...mapState({
      userLoggedIn: (state) => state.userLoggedIn,
      currentCustomerId: (state) => state.currentCustomerId,
      customerBusinesses: (state) => state.customerBusinesses,
      currentCustomer: (state) => state.currentCustomer,
      lang: (state) => state.lang,
    }),
    unreadNotifications() {
      return this.notifications.filter((notice) => notice.NoticeRead == false)
        .length;
    },
  },
};
</script>
<style>
.boxshadow {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--mr-focus-ring);
  border-color: var(--mr-primary-color);
  padding: 2px;
}
</style>