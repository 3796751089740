import { Model } from '@vuex-orm/core'


export default class ActiveLocation extends Model {
  static entity = 'activeLocations'
  
  static primaryKey = 'LocationId'
  
  static fields () {
    return {
      LocationId: this.uid(),
      LocationCity: this.attr(null),
      LocationState: this.attr(null),
      LocationStateCode: this.attr(null),
      
    }
  }

  static async fetchAll() {
    ActiveLocation.deleteAll();
    await this.api().get('/api/activelocations/')
    return this.all();
  }
  static fetchById(LocationId) {
    this.api().get('/api/activelocations/'+LocationId)
    return this.find(LocationId);
  }
}

