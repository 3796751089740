import { Model } from '@vuex-orm/core'


export default class EventType extends Model {
  static entity = 'eventTypes'
  
  static primaryKey = 'EventTypeId'
  
  static fields () {
    return {
      EventTypeId: this.uid(),
      EventTypeLabelDefId: this.attr(null),
      BusId: this.attr(null),
      EventTypeLabel: this.attr(null)
    }
  }
  
}

