<template>
  <div v-if="loading">
    <loading-overlay></loading-overlay>
  </div>
  <div v-else class="content mt-3">
   <div v-if="currentBusiness != null && currentBusiness.BusActive == true && currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode == 'PREMIUM'">
    <div
      v-if="
        providerStatus != null &&
        (providerStatus.Enrolled == false ||
          providerStatus.AccountAdded == false ||
          providerStatus.ServiceAgreement == false ||
          providerStatus.PayoutEnabled == false)
      "
    >

          <Message severity="warn" :closable="false">
            <div
              class="pointer"
              @click.stop="$router.push({ name: 'BusinessSettings' })"
            >
              {{ $t("payments.enable") }}
            </div>
          </Message>
          <!-- <span style="vertical-align: -webkit-baseline-middle">
            <i
              v-if="
                providerStatus.Enrolled == false ||
                providerStatus.ServiceAgreement == false
              "
              class="pi pi-circle-off"
              style="fontsize: 1rem"
            ></i
            ><i
              v-if="
                providerStatus.Enrolled == true &&
                providerStatus.ServiceAgreement == true
              "
              class="pi pi-check-circle"
              style="fontsize: 1rem"
            ></i
            >{{ $t("payments.accept") }}
            <Button
              v-if="providerStatus.ServiceAgreement == false"
              :label="$t('payments.started')"
              class="p-button-link p-button-sm"
              @click.stop="showServAgreement = true"
            />
            <Button
              v-if="providerStatus.ServiceAgreement == true"
              :label="$t('payments.completed')"
              disabled="disabled"
              class="p-button-link p-button-sm"
            /> </span
          ><br />
          <span style="vertical-align: -webkit-baseline-middle"
            ><i
              v-if="
                providerStatus.Enrolled == false ||
                providerStatus.DetailsPending == true
              "
              class="pi pi-circle-off"
              style="fontsize: 1rem"
            ></i
            ><i
              v-if="
                providerStatus.Enrolled == true &&
                providerStatus.DetailsPending == false
              "
              class="pi pi-check-circle"
              style="fontsize: 1rem"
            ></i
            >{{ $t("payments.fillout") }}
            <Button
              v-if="
                providerStatus.Enrolled == true &&
                providerStatus.DetailsPending == false &&
                providerStatus.ServiceAgreement == false
              "
              :label="$t('payments.started')"
              class="p-button-link p-button-sm"
              @click.stop="showServAgreement = true"
            />
            <Button
              v-if="
                providerStatus.Enrolled == true &&
                providerStatus.DetailsPending == false
              "
              :label="$t('payments.completed')"
              disabled="disabled"
              class="p-button-link p-button-sm"
            /> </span
          ><br />

          <span style="vertical-align: -webkit-baseline-middle">
            <i
              v-if="providerStatus.AccountAdded == false"
              class="pi pi-circle-off"
              style="fontsize: 1rem"
            ></i
            ><i
              v-if="providerStatus.AccountAdded == true"
              class="pi pi-check-circle"
              style="fontsize: 1rem"
            ></i
            >{{ $t("payments.bank") }}
            <Button
              v-if="
                providerStatus.Enrolled == true &&
                providerStatus.PayoutEnabled == true &&
                providerStatus.DetailsPending == false &&
                providerStatus.AccountAdded == false
              "
              :label="$t('payments.started')"
              class="p-button-link p-button-sm"
              @click.stop="showNewBankAccount = true"
            />
            <Button
              v-if="providerStatus.AccountAdded == true"
              :label="$t('payments.completed')"
              disabled="disabled"
              class="p-button-link p-button-sm"
            />
          </span> -->
 
    </div>

      <DataTable
        :value="payments"
        class="p-datatable-responsive-demo"
        :paginator="true"
        :rows="10"
        ref="dt"
        :resizableColumns="true"
        dataKey="ReservPayId"
        :filters="filters"
        :loading="loading"
      >
        <template #empty> {{ $t("payments.nopayments") }} </template>
        <template #loading> {{ $t("payments.loadingpayments") }} </template>
        <template #header>
          <div style="text-align: left">
            <Button
              icon="pi pi-external-link"
              :label="$t('menu.export')"
              @click="exportCSV($event)"
            />
          </div>
        </template>
        <Column field="PayNum" :header="$t('payments.payid')" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.PayNum }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['PayNum']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column
          field="ReservId"
          :header="$t('payments.reserv')"
          :sortable="true"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.Reserv != null &&
                slotProps.data.Reserv.ReservNum != null
              "
              class="pointer"
              @click.stop="showReservDetails(slotProps.data.ReservId)"
              >{{ slotProps.data.Reserv.ReservNum }}</span
            >
            <span
              v-else-if="slotProps.data.Reserv != null"
              class="pointer"
              @click.stop="showReservDetails(slotProps.data.ReservId)"
              >{{ slotProps.data.Reserv.ReservId }}</span
            >
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['ReservNum']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column
          field="PayAmount"
          :header="$t('custreserv.custamount')"
          :sortable="true"
        >
          <template #body="slotProps">
            ${{ slotProps.data.PayAmount }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['PayAmount']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column
          field="PaySource"
          :header="$t('payments.paysource')"
          :sortable="true"
        >
          <template #body="slotProps">
            {{ slotProps.data.PaySource }}
          </template>
          <template #filter>
             <InputText
              type="text"
              v-model="filters['PaySource']"
              class="p-column-filter"
              placeholder=""
            /> 
          </template>
        </Column>
        <Column field="PayDate" :header="$t('calendar.date')" :sortable="true">
          <template #body="slotProps">
            {{ $formatDateTime(slotProps.data.PayDate) }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['PayDate']"
              class="p-column-filter"
              placeholder=""
            />
          </template>
        </Column>
        <Column
          field="PayStatus"
          :header="$t('payments.status')"
          :sortable="true"
        >
          <template #body="slotProps">
            {{ slotProps.data.PayStatus }}
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['PayStatus']"
              :options="statuses"
              :placeholder="$t('services.pleaseselect')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span>{{ slotProps.option }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>
      </DataTable>
      <Dialog
        v-model:visible="showReservDialog"
        :style="{ width: '90vw' }"
        :modal="true"
        :dismissableMask="true"
        :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
      >
        <reservation-details :data="reservToView" />
      </Dialog>
    </div>
    <div v-else-if="currentBusiness.BusActive == false">
      <inactive-subscription
        :busId="currentBusiness.BusId"
      ></inactive-subscription>
    </div>
    <div v-else-if="currentSubscription != null && currentSubscription.SubDef != null && currentSubscription.SubDef.SubscriptionLevelCode != 'PREMIUM'">

      <upgrade-subscription
        :subId="currentSubscription.SubId"
      ></upgrade-subscription>
    </div>
 
    <div v-else-if="currentBusiness == null">
      <Message severity="danger" :closable="false">
        {{ $t("message.error") }}</Message
      >
    </div>
    <Dialog
      header="Business Country Base"
      v-model:visible="showCountryBase"
      :modal="true"
      :dismissableMask="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <p>
        {{ $t("payments.myreservs") }}
      </p>
      <template #footer>
        <Button
          :label="$t('payments.noqualify')"
          icon="pi pi-times"
          @click="showCountryBase = false"
          class="p-button-secondary"
        />
        <Button
          :label="$t('payments.qualifies')"
          icon="pi pi-check"
          @click="
            showCountryBase = false;
            showServAgreement = true;
          "
          autofocus
        />
      </template>
    </Dialog>

    <Dialog
      :header="$t('payments.serviceagreement')"
      v-model:visible="showServAgreement"
      :modal="true"
      :dismissableMask="true"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <p>
        {{ $t("payments.agree") }}
      </p>
      <template #footer>
        <div class="flex justify-content-between">
          <Button
            :label="$t('payments.donot')"
            icon="pi pi-times"
            @click="showServAgreement = false"
            class="p-button-secondary"
          />
          <Button
            :label="$t('payments.iagree')"
            icon="pi pi-check"
            @click="
              showServAgreement = false;
              startEnrollment();
            "
            autofocus
          />
        </div>
      </template>
    </Dialog>
    <Dialog
      v-model:visible="showNewBankAccount"
      :style="{ width: '90vw' }"
      :modal="true"
      :header="$t('payments.payout')"
      :dismissableMask="true"
      :breakpoints="{ '960px': '100vw', '640px': '100vw' }"
    >
      <div class="p-fluid formgrid grid text-left">
        <div class="field col-12">
          <label for="name">{{ $t("payments.acctname") }}</label>
          <InputText
            v-model="account.name"
            :placeholder="$t('payments.holdername')"
          />
        </div>
        <div class="field col-12">
          <label for="type">{{ $t("payments.accttype") }}</label>
          <Dropdown
            class="mt-2"
            v-model="account.type"
            :options="accountTypes"
            optionLabel="label"
            optionValue="value"
            :placeholder="$t('payments.accounttype')"
          />
        </div>
        <div class="field col-12">
          <label for="route">{{ $t("payments.routing") }}</label>
          <InputMask
            v-model="account.routing"
            mask="999999999"
            :placeholder="$t('payments.routingnumber')"
          />
        </div>
        <div class="field col-12">
          <label for="number">{{ $t("payments.number") }}</label>
          <InputText
            v-model="account.number"
            :placeholder="$t('payments.accountnumber')"
          />
        </div>

        <Button
          v-if="!submittingAccountInfo"
          label="Submit"
          icon="pi pi-check"
          class="p-button-raised p-button-success mt-2"
          autofocus
          @click.stop="validateAccountInfo()"
        />
        <Button
          v-else
          label="Submit"
          icon="pi pi-spin pi-spinner"
          class="p-button-raised p-button-success mt-2"
          disabled
        />

        <div class="col-12">
          <Message
            v-for="error of errors"
            severity="error"
            :key="error"
            :sticky="true"
            >{{ error }}</Message
          >
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import ReservPayment from "../store/Models/ReservPayment";
import Reservation from "../store/Models/Reservation";
import ReservationDetails from "../components/ReservationDetails.vue";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import InactiveSubscription from "../components/InactiveSubscription.vue";
import Business from "../store/Models/Business";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import Subscription from '../store/Models/Subscription';
import UpgradeSubscription from '../components/UpgradeSubscription.vue';
//

// import { onMounted } from "vue";

export default {
  name: "BusinessPayments",
  components: { ReservationDetails, LoadingOverlay, UpgradeSubscription, InactiveSubscription },
  props: {
    payId: { default: 0, type: Number },
    busId: { default: 0, type: Number },
  },
  data() {
    return {
      currentBusiness: {},
      payments: [],
      showReservDialog: false,
      reservToView: {},
      filters: {},
      loading: false,
      statuses: ["Processed", "Pending"],
      showCountryBase: false,
      showServAgreement: false,
      providerStatus: null,
      showNewBankAccount: false,
      currentSubscription:{},
      submittingAccountInfo: false,
      account: {
        name: "",
        type: null,
        routing: "",
        number: "",
      },
      errors: [],
      accountTypes: [
        { label: "Individual", value: "individual" },
        { label: "Company", value: "company" },
      ],
      waitingOnBusiness: false,
    };
  },
  async mounted() {
    this.$log.debug("business payments mounted");
    if (this.currentBusinessId > 0) {
      await this.setBusPayments();
    }
    if (this.$route.params.payId > 0) {
      this.loading = true;
      if (this.currentBusinessId != this.$route.params.busId) {
        this.waitingOnBusiness = true;
        await this.setCurrentBusiness(this.$route.params.busId);
      } else {
        this.onLoadWithPayment();
      }
    }
  },
  methods: {
    ...mapActions(["setCurrentBusiness"]),
    onLoadWithPayment() {
      this.$router.replace("/BusinessPayments");
      this.loading = false;
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async showReservDetails(reservId) {
      await Reservation.fetchById(reservId).then((result) => {
        this.$log.debug(result);
        this.reservToView = result;
      });
      this.showReservDialog = true;
    },
    async setBusPayments() {
      this.loading = true;
      Business.verifyProviderStatus(this.currentBusinessId).then((result) => {
        this.providerStatus = result;
        this.$log.debug("provider status ");
        this.$log.debug(this.providerStatus);
      });
      Subscription.fetchCurrentByBusiness(this.currentBusinessId).then((result) => {
          this.currentSubscription = result;
         //console.log(this.currentSubscription)
        });
       //console.log("loading gone")
      Business.fetchBusinessProfileById(this.currentBusinessId).then(
        (result) => {
          this.currentBusiness = result;
          this.$log.debug(this.currentBusiness);
        }
      );
      await ReservPayment.fetchByBusId(this.currentBusinessId).then(
        (result) => {
          this.$log.debug(result);
          this.payments = result;
          if (this.waitingOnBusiness) {
            this.onLoadWithPayment();
          }
        }
      );
      this.loading = false;
    },
    async startEnrollment() {
      Business.enrollInPayments(this.currentBusiness.BusId).then(
        async (response) => {
          if (response != null) {
            window.location.replace(response);
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.enrolled"),
              life: 3000,
            });
          }
        }
      );
    },

    async retrieveAccountLink() {
      Business.resolveEnrollment(this.currentBusiness.BusId).then(
        async (response) => {
          if (response != null) {
            window.location.replace(response);
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.enrolled"),
              life: 3000,
            });
          }
        }
      );
    },

    async updateAccount() {
      Business.resolveEnrollment(this.currentBusiness.BusId).then(
        async (response) => {
          if (response != null) {
            window.location.replace(response);
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("code.errors"),
              detail: this.$t("code.enrolled"),
              life: 3000,
            });
          }
        }
      );
    },

    validateAccountInfo() {
      this.$log.info("validating new account info");
      this.submittingAccountInfo = true;
      this.$log.debug(this.account);
      //clear out errors
      this.errors = [];

      //verify required values
      if (this.account.name.length == 0) {
        this.errors.push(this.$t("code.holdername"));
        document.getElementById("name").classList.add("p-invalid");
      }
      if (this.account.type == null) {
        this.errors.push(this.$t("code.holdertype"));
        document.getElementById("type").classList.add("p-invalid");
      }
      if (this.account.routing.length == 0) {
        this.errors.push(this.$t("code.routingnumber"));
        document.getElementById("route").classList.add("p-invalid");
      }
      if (this.account.number == null || this.account.number.length == 0) {
        this.errors.push(this.$t("code.accountnumber"));
        document.getElementById("number").classList.add("p-invalid");
      }
      if (this.errors.length == 0) {
        this.retrieveToken();
      } else {
        this.submittingAccountInfo = false;
      }
    },
    async retrieveToken() {
      var bankAccountParams = {
        country: "us",
        currency: "usd",
        account_number: this.account.number,
        routing_number: this.account.routing,
        account_holder_name: this.account.name,
        account_holder_type: this.account.type,
      };
      this.createAccount(bankAccountParams);
    },

    async createAccount(bankAccountParams) {
      // eslint-disable-next-line
      let stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: this.currentBusiness.BusProviderAccount,
      });
      this.errors = [];
      stripe.createToken("bank_account", bankAccountParams).then((result) => {
        this.$log.debug(result);
        if (result.error) {
          this.$log.debug("error");
          if (result.error.code == "routing_number_invalid")
            this.errors.push(this.$t("code.validrouting"));
          if (result.error.code == "account_number_invalid")
            this.errors.push(this.$t("code.validaccount"));
        }
        if (result.token) {
          this.$log.debug("token");
          Business.createExternalAccount(
            this.currentBusinessId,
            result.token.id
          ).then((response) => {
            this.$log.debug(response);

            this.setBusPayments();
          });
        }
        this.submittingAccountInfo = false;
      });

      // this.$log.debug(response);

      // this.Business.createExternalAccount(
      //     this.currentBusinessId,
      //     token
      //   ).then((response) => {
      //     this.$log.debug(response);
      //   });
    },
  },
  watch: {
    async currentBusinessId() {
      if (this.currentBusinessId > 0) {
        this.setBusPayments();
      }
    },
  },
  computed: {
    ...mapState({
      currentBusinessId: (state) => state.currentBusinessId,
    }),
  },
};
</script>
<style scoped>
.p-column-filter {
  width: 100%;
  z-index: 1000;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-thead
    > tr
    > th,
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }
  ::v-deep(.p-datatable).p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}
</style>