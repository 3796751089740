import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Customer from "./Customer"
import Business from "./Business"
import TimeSlot from "./TimeSlot"
import Quote from "./Quote"
import ReservPayment from "./ReservPayment"
import BusinessReservTerm from "./BusinessReservTerm"

export default class Reservation extends Model {
  static entity = 'reservations'

  static primaryKey = 'ReservId'
/* eslint-disable */
  static fields() {
    return {
      ReservId: this.uid(),
      CustId: this.attr(null),
      BusId: this.attr(null),
      QuoteId: this.attr(null),
      SlotId: this.attr(null),
      ReservTotalCost: this.attr(null),
      ReservTotalPaid: this.attr(null),
      ReservTotalOwed: this.attr(null),
      ReservStatus: this.attr(null),
      ReservManual: this.attr(null),
      ReservNum: this.attr(null),
      TermsId: this.attr(null),
      ReservTermsSignature: this.attr(null),

      Quote: this.belongsTo(Quote, 'QuoteId'),
      Slot: this.belongsTo(TimeSlot, 'SlotId'),
      Cust: this.belongsTo(Customer, 'CustId'),
      Bus: this.belongsTo(Business, 'BusId'),
      ReservPayments: this.hasMany(ReservPayment, 'ReservId'),
      BusinessReservTerm: this.belongsTo(BusinessReservTerm, 'TermsId'),

    }
  }

  static async fetchByCustId(CustId) {
    //console.log("fetch reservs for cust" + CustId)
    Reservation.deleteAll();
    var result = await Reservation.api().get('/api/reservations/GetCustomerReservs/' + CustId);
    //console.log(result)
    return Reservation.query()
      .withAllRecursive()
      .where(reserv => reserv.CustId == CustId).orderBy('ReservId', 'desc').all();
  }

  static async fetchById(ReservId) {
    //console.log("fetch reservs #" + ReservId)
    var result = await Reservation.api().get('/api/reservations/GetReservation/' + ReservId);
    //console.log(result)
    return Reservation.query().withAllRecursive().find(ReservId);
  }

  static async fetchBySlotId(SlotId) {
    //console.log("fetch reserv by slotid #" + SlotId)
     const result = await Reservation.api().get('/api/reservations/GetReservationByTimeSlot/' + SlotId).then((result) => {
      //console.log(result)
      //console.log(result.response.data.ReservId)
      if (result.response.status == 200) { 
        //console.log(result.response.data.ReservId)
        return Reservation.query().withAllRecursive().find(result.response.data.ReservId);
      }
      else
        return null;
    }).catch(error => {
      //console.log(error.response);
      return null;
    });
    return result;
  }

  static async fetchByBusId(BusId) {
    //console.log("fetch reservs for bus " + BusId)
    var result = await Reservation.api().get('/api/reservations/GetBusReservs/' + BusId);
    //console.log(result)
    return Reservation.query()
      .withAllRecursive()
      .where(reserv => reserv.BusId == BusId).orderBy('ReservId', 'desc').all();
  }

  static async requestCancel(reserv) {
    //console.log("request cancel reserv for " + reserv.ReservId)
    var result = await Reservation.api().put('/api/reservations/RequestCancelation/' + reserv.ReservId, reserv).then((result) => {
      if (result.response.status == 204) {
        //console.log("success")
        return 1;
      }
      if (result.response.status == 200) {
        //console.log("duplicate")
        return 1;
      }
      else {
        //console.log("error");
        return 2;
      }
    }).catch(error => {
      //console.log(error.response);
      return 2;
    });
    return result;
  }
}