import { Model } from '@vuex-orm/core'
/* eslint-disable */
import Business from "./Business"

export default class BusinessFavorite extends Model {
  static entity = 'businessFavorite'

  static primaryKey = 'FavId'

  static fields() {
    return {
      FavId: this.uid(),
      BusId: this.attr(null),
      CustId: this.attr(null),
      Bus: this.belongsTo(Business, 'BusId')
    }
  }
  static async addFavorite(custId, busId) {
    //console.log("cust id: " + custId)
    //console.log("bus id" + busId)
    let fav = { FavId: 0, CustId: custId, BusId: busId };
     const result =  await BusinessFavorite.api().post('/api/businessfavorites/',
      fav).then((result) => {
        if (result.response.status == 201) {
          //console.log("success")
          return 1;
        }
        if(result.response.status == 200){
          //console.log("duplicate")
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response);
        return 2;
      });
      return result;
  }
  static async deleteFavorite(favId) {
     const result =  await BusinessFavorite.api().delete('/api/businessfavorites/' + favId,
      {delete: favId}
     )
     .then((result) => {
       //console.log(result);
        if (result.response.status == 200) {
          //console.log("success")
          BusinessFavorite.delete(favId);
          return 1;
        }
        else{
          //console.log("error");
          return 2;
        } 
      }).catch(error => {
        //console.log(error.response)
        if(error.response.status==404){
          BusinessFavorite.delete(favId);
          return 1;
        }
        else{
        //console.log(error.response);
        return 2;
        }
      });
      return result;
  }
  static async fetchByCustId(CustId) {
    //console.log("fetch busfavorites for " + CustId)
   var result = await BusinessFavorite.api().get('/api/businessfavorites/GetCustomerFavorites/' + CustId);
    //console.log(result)
    return  BusinessFavorite.query()
      .withAllRecursive()
      .where(fav=>fav.CustId == CustId).orderBy('FavId', 'desc').all();
  }
}

